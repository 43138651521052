<div class="report-staff-container">

  <app-title-bar [title]="title" wikiPath="legacy-staff-report"></app-title-bar>

  <div class="search-fields-root">
    <div class="search-fields">
      <mat-form-field appearance="outline">
        <mat-label>Day or start date</mat-label>
        <input matInput [(ngModel)]="searchStartDate" [matDatepicker]="startPicker">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker [touchUi]="isMobile"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>End date</mat-label>
        <input matInput [(ngModel)]="searchEndDate" [matDatepicker]="endPicker">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker [touchUi]="isMobile"></mat-datepicker>
      </mat-form-field>
      <button mat-flat-button class="search-button" (click)="fetch()" color="primary">SÖK</button>
      <button mat-stroked-button class="dropdown-button" [matMenuTriggerFor]="menuHandleSection" *ngIf="true"><mat-icon>expand_more</mat-icon></button>
      <mat-menu #menuHandleSection="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="menuExport">Exportera</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="clearFilters()">Rensa sökning</button>
      </mat-menu>
      <mat-menu #menuExport="matMenu">
        <button mat-menu-item (click)="export('csv')">CSV-format</button>
      </mat-menu>
    </div>
  </div>

  <div class="generic-mastdet">
    <div class="generic-master">
      <table mat-table [dataSource]="dataSource" matSort class="clicktable table-cells" matSortActive="pay_date" matSortDirection="desc" matSortDisableClear="true">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Namn</th>
          <td mat-cell *matCellDef="let element" style="word-wrap: break-word; max-width: 100px; white-space: normal;">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="accountable_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Person-id</th>
          <td mat-cell *matCellDef="let element"> {{ element.accountable_id }} </td>
        </ng-container>

        <ng-container matColumnDef="worked_minutes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Arbetstid</th>
          <td mat-cell *matCellDef="let element"> {{ formatWorkTime(element.worked_minutes) }}</td>
        </ng-container>

        <ng-container matColumnDef="tip">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Dricks</th>
          <td mat-cell *matCellDef="let element">{{ element.tip | moneySuff : null : "kr" }}</td>
        </ng-container>

        <ng-container matColumnDef="amount_vat">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Ink moms</th>
          <td mat-cell *matCellDef="let element">{{ element.amount_vat | moneySuff : null : "kr" }} </td>
        </ng-container>

        <ng-container matColumnDef="amount_exvat">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Ex moms</th>
          <td mat-cell *matCellDef="let element">{{ element.amount_exvat | moneySuff : null : "kr" }} </td>
        </ng-container>

        <ng-container matColumnDef="discount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Rabatterat</th>
          <td mat-cell *matCellDef="let element">{{ element.discount | moneySuff : null : "kr" }} </td>
        </ng-container>

        <ng-container matColumnDef="item_count">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Artiklar</th>
          <td mat-cell *matCellDef="let element">{{ element.item_count | amount }} artiklar</td>
        </ng-container>

        <ng-container matColumnDef="receipt_count">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Kvitton</th>
          <td mat-cell *matCellDef="let element">{{ element.receipt_count | amount }} st</td>
        </ng-container>

        <ng-container matColumnDef="navigate_to">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button *ngIf="element.accountable_id" mat-button [matMenuTriggerFor]="navigation"><mat-icon>arrow_drop_down</mat-icon></button>
            <mat-menu #navigation>
              <button mat-menu-item (click)="redirect(element, 'events')">Händelser</button>
              <button mat-menu-item (click)="redirect(element, 'receipts')">Kvitton</button>
              <button mat-menu-item (click)="redirect(element, 'sreport')">S-Rapport</button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <div class="no-data" *ngIf="noData">Inget att rapportera för vald period</div>
      <div class="no-data" *ngIf="initData">Välj period ovan för att söka</div>
    </div>
  </div>
</div>
