<div class="heynow-logo-container">
    <img src="assets/images/heynow.png" alt="Heynow.ai" class="heynow-logo-image">
  </div>
<mat-card class="custom-mat-card">
    <div [hidden]="submitted"></div>
    <mat-card-title>HEYNOW ADMIN</mat-card-title>
    <mat-card-subtitle>{{model?.first_name}} {{model?.last_name}}<br>{{model?.email}}</mat-card-subtitle>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="link()" *ngIf="!(loadingService.visibility | async)">
            <p>
                <mat-form-field>
                    <input [type]="hideP ? 'password' : 'text'" matInput id="password"
                       formControlName="password" placeholder="Password" name="password">
                    <button mat-icon-button matSuffix type="button" (click)="hideP = !hideP" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hideP">
                        <mat-icon>{{hideP ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('password').hasError('minlength')">Password must have at least 6 characters</mat-error>
                    <mat-error *ngIf="form.get('password').hasError('required')">Required</mat-error>
                </mat-form-field>
                </p>
                <p>
                 <mat-form-field>
                    <input [type]="hideCP ? 'password' : 'text'" matInput id="confirmPassword"
                        formControlName="confirmPassword" placeholder="Confirm password" name="confirmPassword">
                    <button mat-icon-button matSuffix type="button" (click)="hideCP = !hideCP" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hideCP">
                        <mat-icon>{{hideCP ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('confirmPassword').hasError('required')">Required</mat-error>
                    <mat-error *ngIf="form.get('confirmPassword').hasError('minlength')">Password must have at least 6 characters</mat-error>
                    <mat-error *ngIf="form.hasError('noMatch')">Password don't match</mat-error>
                    
                </mat-form-field>
            </p>

            <div class="button">
                <button mat-flat-button color="primary" class="submit-btn" type="submit" mat-button [disabled]="!form.valid && form.touched">Link account</button>
            </div>
             <mat-error>
                {{error}}
            </mat-error>

        </form>
        <mat-spinner [diameter]="200" *ngIf="loadingService.visibility | async"></mat-spinner>
    </mat-card-content>
</mat-card>