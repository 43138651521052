export const pageSIE = {
  title: {sv: "SIE-inställningar"},
  page_settings: {
    root_path: "sie"
  },
  desc: {sv: "Ställ in SIE-filexport"},
  properties: [
    {key: "header", title: {sv: "SIE headers"}, desc: {sv: "Custom SIE headers"}, type: "table",
      columns: [
        {key: "field", title: {sv: "Fält"}, type: "string", width: 200 },
        {key: "value", title: {sv: "Värde"}, type: "string", width: 200 },
      ]
    },
    {header: "Övriga inställningar"},
    {key: "verification_name", title: {sv: "Verifikatnamn"}, desc: {sv: ""}, type: "string", optional: true},
    {key: "encoding", title: {sv: "Teckenkodning"}, desc: {sv: ""}, type: "string", optional: true},
    {key: "line_feed", title: {sv: "Postavslutstecken"}, desc: {sv: "LF eller CRLF"}, type: "string", optional: true},
  ]
};
