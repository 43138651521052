<div>
  <p>
    <mat-toolbar>
      <span>My account</span>
      <span class="spacer"></span>
    </mat-toolbar>
  </p>
</div>
<div *ngIf="user" class="container">
  <div class="profile">
    <table class="prop-table">
      <tr class="row">
        <td class="prop">ID</td>
        <td class="value">{{user.id}}</td>
      </tr>
      <tr class="row">
        <td class="prop">First name</td>
        <td class="value">{{user.first_name}}</td>
      </tr>
      <tr class="row">
        <td class="prop">Last name</td>
        <td class="value">{{user.last_name}}</td>
      </tr>
      <tr class="row">
        <td class="prop">Email</td>
        <td class="value">{{user.email}}</td>
      </tr>
    </table>
  </div>

<!--  <div style="margin-top: 32px;">-->
<!--    <div *ngIf="false" class="payment-button" mat-ripple (click)="editProfile()">-->
<!--      <div class="payment-button-text">REDIGERA PROFIL</div>-->
<!--    </div>-->
<!--    <div *ngIf="app.identity?.isSignin() && !app.order?.user_cfg?.connected_account" class="payment-button" mat-ripple (click)="connectMessengerAccount()">-->
<!--      <div class="payment-button-text">KOPPLA MESSENGER KONTO</div>-->
<!--    </div>-->
<!--    <div class="payment-button" mat-ripple (click)="logout()">-->
<!--      <div class="payment-button-text">LOGGA UT</div>-->
<!--    </div>-->
<!--  </div>-->
</div>

