import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {VenueService} from "../../../services/venue.service";
import {LoadingService} from "../../../services/loading.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-new-user-dialog',
  templateUrl: './new-user-dialog.component.html',
  styleUrls: ['./new-user-dialog.component.css']
})
export class NewUserDialogComponent implements OnInit {
  firstName = new UntypedFormControl('', [Validators.required]);
  lastName = new UntypedFormControl('', [Validators.required]);
  role = new UntypedFormControl('', [Validators.required]);
  email: string;
  venueId?: number;

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<NewUserDialogComponent>, private auth: AuthService) {
    this.email = data.email;
    this.venueId = data.venueId;
  }

  ngOnInit(): void {
  }

  addUser() {
    if (this.firstName.valid && this.lastName.valid) {
      const firstName = this.firstName.value;
      const lastName = this.lastName.value;
      this.venueService.createNewUser(firstName, lastName, this.email, [this.role.value], this.venueId).then( r => {
        console.log("Created new user..");
        this.dialogRef.close(true);
      });
    }
  }

  isHeynowAdmin() {
    return AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }
}
