<div class="sidebar-container">
  <mat-nav-list>
    <mat-list-item routerLink="/venue/{{venueId}}/dashboard" routerLinkActive="active-link">
      <mat-icon>arrow_back_ios</mat-icon>
      <div>To dashboard</div>
    </mat-list-item>
  </mat-nav-list>

  <div style="padding: 16px 16px 0 16px;">
    <div *ngIf="publishing" class="mid-spinner">
      <mat-spinner class="add-item-spinner" *ngIf="publishing" [diameter]="32"></mat-spinner>
    </div>
    <div class="pub-panel" style="color: #da2a2a;" *ngIf="errorMessage">
      <div class="pub-panel-title"><mat-icon style="font-size: 12px;width:12px;height:12px;">error</mat-icon><div style="font-size: 12px; margin-left: -6px;">Fel vid publicering</div></div>
      <div class="pub-panel-body"><div [innerHtml]="errorMessage"></div><hr><div style="color:black;">{{errorAt}}</div></div>
    </div>
<!--    <div class="panel" style="color: #317370;" *ngIf="message">-->
<!--      <div class="panel-title"><mat-icon style="font-size: 22px;">check_circle</mat-icon><div style="font-size: 16px; margin-left: 6px;">Publish successful</div></div>-->
<!--      <div class="panel-body">{{message}}</div>-->
<!--    </div>-->
    <button [disabled]="publishing" mat-flat-button (click)="publish()" color="primary" style="width: 100%;">Publish</button>
    <a *ngIf="publishDate" class="pub-date" [matMenuTriggerFor]="menuHistory">Published {{publishDate}}</a>
    <mat-menu #menuHistory="matMenu">
      <a mat-menu-item [href]="linkToCollins" target="_blank">Open guest menu</a>
      <button mat-menu-item routerLink="history">View history</button>
    </mat-menu>

  </div>

  <mat-nav-list>

    <h3 mat-subheader>Menu pages</h3>

    <mat-list-item routerLink="tab/food" routerLinkActive="active-link">
      <mat-icon>restaurant_menu</mat-icon>
      <div>Mat</div>
      <button mat-icon-button (click)="showPageSettings($event, 'food')" style="margin-left: auto;"><mat-icon style="padding: 0;">more_horiz</mat-icon></button>
    </mat-list-item>

    <mat-list-item routerLink="tab/drinks" routerLinkActive="active-link">
      <mat-icon>wine_bar</mat-icon>
      <div>Dryck</div>
      <button mat-icon-button (click)="showPageSettings($event, 'drinks')" style="margin-left: auto;"><mat-icon style="padding: 0;">more_horiz</mat-icon></button>
    </mat-list-item>

<!--    <mat-list-item routerLink="tab/shop" routerLinkActive="active-link">-->
<!--      <mat-icon>storefront</mat-icon>-->
<!--      <div>Shop</div>-->
<!--    </mat-list-item>-->

    <h3 mat-subheader>Item tabs</h3>

    <mat-list-item routerLink="tab/{{tab.id}}" routerLinkActive="active-link" *ngFor="let tab of tabs">
      <mat-icon>food_bank</mat-icon>
      <div>{{tab.name}}</div>
      <button mat-icon-button (click)="showPageSettings($event, tab.id)" style="margin-left: auto;"><mat-icon style="padding: 0;">more_horiz</mat-icon></button>
    </mat-list-item>

    <mat-list-item routerLinkActive="active-link" (click)="addTab()">
      <mat-icon>add</mat-icon>
      <div>Add tab</div>
    </mat-list-item>

    <mat-tab-group >
      <mat-tab label="Admin">
        <mat-list-item routerLink="options_tab" routerLinkActive="active-link" style="padding-top: 16px;">
          <mat-icon>toggle_on</mat-icon>
          <div>Tillval</div>
        </mat-list-item>

        <mat-list-item routerLink="pushpages_tab" routerLinkActive="active-link">
          <mat-icon>add_shopping_cart</mat-icon>
          <div>Push</div>
        </mat-list-item>

        <mat-list-item routerLink="images" routerLinkActive="active-link">
          <mat-icon>image</mat-icon>
          <div>Images</div>
        </mat-list-item>

        <mat-list-item routerLink="settings/menu_settings" routerLinkActive="active-link">
          <mat-icon>settings</mat-icon>
          <div>Settings</div>
        </mat-list-item>
      </mat-tab>
      <mat-tab label="Custom">
        <div class="chip_cont" style="padding-top: 16px;">
          <span *ngFor="let col of columns" [class]="{'chip_selected':col.selected,'chip':!col.selected}" (click)="toggleColumn(col.data)">{{col.title}}</span>
        </div>
      </mat-tab>
    </mat-tab-group>


  </mat-nav-list>

<!--  <div class="bottom-thingy">-->
<!--    <img src="https://storage.googleapis.com/heynow/media/heynow_sml_coral.png">-->
<!--  </div>-->
</div>
