import {Component, isDevMode, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {VenueService} from "../../../services/venue.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatTableDataSource} from "@angular/material/table";
import {Admin} from "../../../models/user";
import {MatSort, MatSortable} from "@angular/material/sort";
import {SubscriberModel} from "../../../models/subscriber";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-subscriber-list',
  templateUrl: './subscriber-list.component.html',
  styleUrls: ['./subscriber-list.component.css']
})
export class SubscriberListComponent {
  listName: string;

  constructor(public dialog: MatDialog, private route: ActivatedRoute, private venueService: VenueService, private snackBar: MatSnackBar) { }
  displayedColumns = ["id", "email", "venue", "created", "updated", "actions"];
  dataSource: MatTableDataSource<SubscriberModel>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  title: string;
  baseUrl: string;

  ngOnInit(): void {
    this.baseUrl = environment.james_url;
    this.sort.sort(({ id: 'first_name', start: 'asc'}) as MatSortable);
    this.route.paramMap.subscribe(data => {
      this.listName = data.get("listname");
      this.title = `Prenumeranter - ${this.listName}`;
      this.fetchSubscribers();
    });
  }

  fetchSubscribers() {
    this.venueService.fetchSubscribers(this.listName).then(r => {
      console.log(r);
      this.dataSource = new MatTableDataSource(r.subscribers as SubscriberModel[]);
      this.dataSource.sort = this.sort;
      console.log(r);
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

  addActiveAdmins() {
    this.venueService.addAdminsAsSubscribers(this.listName).then(r => {
      const count = r.subscribers_added;
      this.snackBar.open(`Lagt till ${count} användare`, "OK", {duration: 2000});
      this.fetchSubscribers()
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

  openSubLink() {
    window.open(`${this.baseUrl}/list/${this.listName}/add`, "_blank");
  }

  get numberOfSubscribers(): number {
    return this.dataSource ? this.dataSource.data.length : 0;
  }

  removeInvalid() {
    this.venueService.cleanupSubscribers(this.listName).then(r => {
      console.log(r.invalid_subscribers)
      const count = r.invalid_subscribers.length;
      this.snackBar.open(`${count} mail invalid`, "OK", {duration: 2000});
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));

  }
}
