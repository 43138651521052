<div class="report-customer-container">

    <app-title-bar [title]="title"></app-title-bar>
  
    <div class="search-fields-root">
      <div class="search-fields">
        <mat-form-field appearance="outline">
          <mat-label>Startdatum</mat-label>
          <input matInput [(ngModel)]="searchStartDate" [matDatepicker]="startPicker">
          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
          <mat-datepicker #startPicker [touchUi]="isMobile"></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Slutdatum</mat-label>
          <input matInput [(ngModel)]="searchEndDate" [matDatepicker]="endPicker">
          <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
          <mat-datepicker #endPicker [touchUi]="isMobile"></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Filtrera på IC</mat-label>
          <mat-select [(ngModel)]="selectedItem" (selectionChange)="onDropdownChange($event)">
            <mat-option *ngFor="let item of dropdownData" [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-flat-button class="search-button" (click)="fetch()" color="primary">SÖK</button>
        <div class="action-buttons" *ngIf="!isMobile">
          <button mat-stroked-button (click)="clearFilters()">RENSA</button>
        </div>
        <span class="spacer"></span> 
        <button mat-button (click)="export('csv')" *ngIf="!isMobile" [disabled]="!canExport"><mat-icon>download</mat-icon> Export (CSV)</button>
        <button mat-stroked-button *ngIf="isMobile" class="dropdown-button" #reportsMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="menuHandleSection"><mat-icon>expand_more</mat-icon></button>
        <mat-menu #menuHandleSection="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="menuExport">Exportera</button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="clearFilters()">Rensa sökning</button>
        </mat-menu>
        <mat-menu #menuExport="matMenu">
          <button mat-menu-item (click)="export('csv')">CSV-format</button>
        </mat-menu>
      </div>
    </div>
  
    <div>
      <div class="generic-mastdet">
        <div class="generic-master">
          <table mat-table [dataSource]="dataSource" matSort class="clicktable table-cells" matSortActive="item_count" matSortDirection="desc" matSortDisableClear="true">
  
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Artikel</th>
              <td mat-cell *matCellDef="let element" style="padding: 4px; width: 200px;">{{ element.name }}</td>
            </ng-container>
  
            <ng-container matColumnDef="item_count">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Antal sålda</th>
              <td mat-cell *matCellDef="let element" style="padding: 4px; width: 100px;"> {{ element.item_count }} </td>
            </ng-container>

            <ng-container matColumnDef="item_amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Summa (inkl moms)</th>
                <td mat-cell *matCellDef="let element" style="padding: 4px; width: 150px;"> {{ element.item_amount | number:'1.2-2' }}
            </ng-container>

            <ng-container matColumnDef="ic">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>IC-klass</th>
                <td mat-cell *matCellDef="let element" style="padding: 4px; width: 150px;"> {{ element.ic }} </td>
              </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></tr>
          </table>
  
          <div class="no-data" *ngIf="noData">Hittar inga försäljningar, försök med annan period eller IC</div>
          <div class="no-data" *ngIf="initData && !noData">Välj period ovan för att söka ut försäljningsdata</div>
  
        </div>
  
      </div>
    </div>
  </div>
  