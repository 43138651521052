export const pageVenueTransactionsSettings = {
  title: {sv: "Transaktioner Cash in/out"},
  page_settings: {
    root_path: "transaction",
  },
  desc: {sv: "Inställningar för cash in/out"},
  properties: [
    { key: "transaction_types", title: {sv: "Transaktioner cash in/out"}, desc: {sv: ""}, type: "table",
      columns: [
        { key: "name", title: {sv: "Namn"}, type: "string", width: 200 },
        { key: "ic", title: {sv: "Transaktioner (ic)"}, type: "enum", width: 120,
          source: ["transaction1", "transaction2", "transaction3", "transaction4"] },
        { key: "direction", title: {sv: "Riktning"}, type: "enum", source: ["IN", "OUT"] },
        { key: "payment_cash", title: {sv: "Kontant"}, type: "bool", optional: false, default: false },
      ]
    },
  ]
};
