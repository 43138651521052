import { Component, OnInit } from '@angular/core';
import {combineLatest, Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";
import {VenueService} from "../../../services/venue.service";

@Component({
  selector: 'app-slot-day',
  templateUrl: './slot-day.component.html',
  styleUrls: ['./slot-day.component.css']
})
export class SlotDayComponent implements OnInit {
  date: string;

  private paramSub: Subscription;
  private venueId: string;
  private namespace: string;
  dossier: any;

  constructor(private route: ActivatedRoute, private location: Location, private venueService: VenueService) { }

  ngOnInit(): void {
    this.paramSub = combineLatest([
        this.route.paramMap,
        this.route.queryParamMap
      ]
    ).subscribe(res => {
      const param = res[0];
      const query = res[1];
      this.venueId = param.get("venue_id");
      this.date = param.get("date");
      this.namespace = param.get("ns");
      this.fetchSummary();
    });
  }

  printBong() {

  }

  goBack() {
    this.location.back();
  }

  private fetchSummary() {
    this.venueService.fetchSlotdaySummary(Number(this.venueId), this.namespace, this.date).then(r => {
      console.log(r);
      this.dossier = r;
    });
  }
}
