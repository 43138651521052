import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { SilversmithApiError } from 'src/app/models/http';
import { ClientRecord } from 'src/app/models/client-record';
import { AuthorizedHttpService } from 'src/app/services/authorized-http-service';


@Component({
  selector: 'app-client-records',
  templateUrl: './client-records.component.html',
  styleUrls: ['./client-records.component.css']
})
export class ClientRecordsComponent implements OnInit {

  displayedColumns = ["created", "updated", "status", "amount", "settlement_fee", "commision", 'fee_formula'];
  dataSource: MatTableDataSource<ClientRecord>;

  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(public authApi: AuthorizedHttpService) {

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  ngOnInit(): void {
    this.authApi.getClientRecords().then(data => {
      this.dataSource = new MatTableDataSource(data as ClientRecord[]);
      this.dataSource.sort = this.sort;
    }).catch(e => {
      const error = e.error as SilversmithApiError;
      console.log("Error", error);
    });
  }
}
