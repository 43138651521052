import {Component, ViewChild} from '@angular/core';
import {VenueService} from "../../services/venue.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {VenueCustomer} from "../../models/venue-customers";
import {SimpleDialogComponent} from "../simple-dialog/simple-dialog.component";
import {Subscription} from "rxjs";
import {Location} from "@angular/common";
import {EditVenueCustomerComponent} from "../settings/venue-customers/edit-venue-customer/edit-venue-customer.component";
import {PendingListComponent} from "./pending-list/pending-list.component";
import {CoreReceiptListComponent} from "../receipt-list/core-receipt-list/core-receipt-list.component";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent {
  venueId: number;
  title: string;
  accountId: string;
  account: VenueCustomer;
  private sub: Subscription;
  selctedTabIndex= 0;
  editMode = false;
  pendingOrdersCount: number = 0;
  totalOrderAmount: number = 0;

  @ViewChild("editVenueCustomerComponent") editVenueCustomerComponent: EditVenueCustomerComponent;
  @ViewChild("pendingListComponent") pendingListComponent: PendingListComponent;
  @ViewChild("realizedListComponent") realizedListComponent: PendingListComponent;
  @ViewChild("receiptListComponent") receiptListComponent: CoreReceiptListComponent;

  constructor(private venueService: VenueService, private route: ActivatedRoute,
              private dialog: MatDialog, private router: Router, private snackBar: MatSnackBar,
              private location: Location) { }

  ngOnInit(): void {
    //this.sort.sort(({ id: 'name', start: 'asc' }) as MatSortable);
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.accountId = data.get("account_id");
      this.fetchAccount();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadPendingOrders();
    }, 0);
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  private fetchAccount() {
    this.venueService.fetchAccount(this.venueId, this.accountId).then(res => {
      console.log("fetchVenueCustomer", res);
      this.account = res;
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

  goBack() {
    this.location.back();
  }

  tabChanged($event: any) {
    this.selctedTabIndex = $event.index;
  }

  goToTab(index: number): void {
    this.selctedTabIndex = index;
  }

  saveInfo() {
    this.editVenueCustomerComponent.save();
  }

  createInvoice() {
    this.pendingListComponent.createInvoice();
  }

  refresh() {
    if (this.selctedTabIndex === 1) {
      this.pendingListComponent.showPending();
    } else if (this.selctedTabIndex === 2) {
      this.realizedListComponent.showPending();
    } else if (this.selctedTabIndex === 3) {
      this.receiptListComponent.refresh();
    }
  }

  duplicateCustomer() {
    this.router.navigateByUrl(`/venue/${this.venueId}/venue-customers/dup:${this.account.key}`);
  
  }

  loadPendingOrders(): void {
    if (this.pendingListComponent) {
      this.pendingListComponent.showPending();
    }
  }

  setPendingOrdersCount(count: number): void {
    this.pendingOrdersCount = count;
  }

  setTotalOrderAmount(amount: number): void {
    this.totalOrderAmount = amount;
  }

  
}
