<div>
  <p>
    <mat-toolbar>
      <span>Filter Editor (beta)</span>
      <span class="spacer"></span>
      <!--      <button mat-icon-button>-->
      <!--        <mat-icon (click)="refresh()">refresh</mat-icon>-->
      <!--      </button>-->
    </mat-toolbar>
  </p>
</div>

<div class="container">
  <div class="toprow">
    <div style="width: 100%;">
      <mat-form-field class="filter-text" appearance="outline">
        <mat-label>Filter (text)</mat-label>
        <input matInput [(ngModel)]="filterText" (keyup.enter)="updateFilter()">
      </mat-form-field>
    </div>
    <div style="margin-left: 16px;padding-bottom: 1.34375em;">
      <button mat-flat-button color="primary" (click)=copyFilter()>Kopiera</button>
    </div>
  </div>
  <div *ngFor="let exp of filterExps; let i = index">
    <div class="title-match" *ngIf="i==0">Filter:</div>
    <div class="title-match" *ngIf="i>0">eller:</div>
    <div class="filter-panel">
      <div class="floatbutton" (click)="removeExp(exp)"><mat-icon style="transform: scale(80%)">close</mat-icon></div>
      <div class="term" *ngFor="let term of exp.terms; let j = index">
        <div class="connector" *ngIf="j==0 && term.include" [matMenuTriggerFor]="menuMatch" (click)="selectTerm(term, exp)">matchar</div>
        <div class="connector" *ngIf="j==0 && !term.include" [matMenuTriggerFor]="menuMatch" (click)="selectTerm(term, exp)">matchar inte</div>
        <div class="connector" *ngIf="j>0 && term.include" [matMenuTriggerFor]="menuMatch" (click)="selectTerm(term, exp)">och</div>
        <div class="connector" *ngIf="j>0 && !term.include" [matMenuTriggerFor]="menuMatch" (click)="selectTerm(term, exp)">och inte</div>
        <div class="filter-value" [class]="{'filter-value-green':term.include, 'filter-value-red':!term.include}" (click)="editTerm(term, exp)">
          <div *ngFor="let v of FilterLogic.getFilterTermValues(term); let k = index"><span [class]="{'show':k==0,'hide':k!=0}">{{FilterLogic.getFilterTermKey(term)}} = </span><span>{{v}}</span></div>
        </div>
      </div>
      <div class="term">
        <div class="connector-inv" [matMenuTriggerFor]="menuTerm" (click)="selectExp(exp)">+</div>
      </div>
    </div>
  </div>
  <div style="margin-top: 16px;">
    <span class="connector-inv" (click)="addOrFilter()">Eller +</span>
  </div>

  <mat-menu #menuTerm="matMenu">
    <div class="menu-header">Artikelegenskaper</div>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="addNewTerm('IC')">Artikelklass (ic)</button>
    <button mat-menu-item (click)="addNewTerm('Tag')">Artikeltag (tag)</button>
    <button mat-menu-item (click)="addNewTerm('Phase')">Servering (phase)</button>
    <div class="menu-header">Plats</div>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="addNewTerm('Table')">Bordsnamn (table)</button>
    <button mat-menu-item (click)="addNewTerm('Area')">Areanamn (area)</button>
    <button mat-menu-item (click)="addNewTerm('Device')">Enhetsnamn (device)</button>
    <button mat-menu-item (click)="addNewTerm('DeliveryType')">Deliver-type (dt)</button>
    <div class="menu-header">Andra regler</div>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="addNewTerm('OrderTag')">Ordertag (ordertag)</button>
    <button mat-menu-item (click)="addNewTerm('Attribute')">Attribute (attribute)*</button>
  </mat-menu>

  <mat-menu #menuMatch="matMenu">
    <button mat-menu-item (click)="setSelectedTermInclude(true)">Matchar (+)</button>
    <button mat-menu-item (click)="setSelectedTermInclude(false)">Matchar inte (-)</button>
  </mat-menu>

  <div style="margin-top: 32px;">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Testa filter mot senaste beställningar
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="simpanel" *ngIf="simParams">
        <button mat-flat-button color="primary" (click)="runOrderFilter()">Test</button>
      </div>
      <div class="bonggrid">
        <div class="bong" *ngFor="let dos of dossiers">
          <app-dossier-viewer [printInstructions]="dos.instructions"></app-dossier-viewer>
        </div>
      </div>

    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Testa filter mot menyn
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="simpanel" *ngIf="simParams">
        <button mat-flat-button color="" (click)="selectSim('Table', 'table')">Bord: {{simParams["table"]}}</button>
        <button mat-flat-button color="" (click)="selectSim('Device', 'device')">Enhet: {{simParams["device"]}}</button>
        <button mat-flat-button color="" (click)="selectSim('DeliveryType', 'dt')">Delivertype: {{simParams["dt"]}}</button>
        <button mat-flat-button color="primary" (click)="runFilter()">Test</button>
      </div>
      <div>
        <table mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Artikel</th>
            <td mat-cell *matCellDef="let element">{{element.name}}</td>
          </ng-container>
          <ng-container matColumnDef="ic">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>IC</th>
            <td mat-cell *matCellDef="let element">{{element.ic}}</td>
          </ng-container>
          <ng-container matColumnDef="tags">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Tag(s)</th>
            <td mat-cell *matCellDef="let element">{{element.tags}}</td>
          </ng-container>
          <ng-container matColumnDef="phase">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Phase</th>
            <td mat-cell *matCellDef="let element">{{element.phaseid}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>

    </mat-expansion-panel>
  </div>


</div>
