<div>
  <p>
    <mat-toolbar>
      <button mat-icon-button (click)="goBack()"><mat-icon>arrow_back_ios</mat-icon></button>
      <span>Öppettider</span>
      <span class="spacer"></span>
    </mat-toolbar>
  </p>
</div>
<div *ngIf="openingHours.length">
  <div *ngFor="let day of openingHours" class="day-container">
    <div class="day-header">
      <div style="font-weight: bold;">{{ day.day }}</div>
      <!-- Får inte detta att se vettigt ut än
      <div class="time-labels">
        <span *ngFor="let time of timeLabels" class="time-label">{{ time }}</span>
      </div>-->
    </div>
    <div 
      class="current-time-marker" 
      [ngStyle]="{'left.%': getCurrentTimePosition()}" 
      *ngIf="day.day.toLowerCase() === currentDayName.toLowerCase()">
    </div>
    <div *ngFor="let section of day.sections" class="timeline-container">
      <div class="timeline-row">
        <div class="timeline-label">{{ section.name }}</div>
        <div class="timeline">
          <div 
            class="time-marker" 
            [ngStyle]="{
              'left.%': calculatePosition(section.start), 
              'right.%': 100 - calculatePosition(section.stop)
            }" 
            [class.closed]="section.isClosed"
            title="{{ section.start }} - {{ section.stop }}">
            <span *ngIf="!section.isClosed">{{ section.start }} - {{ section.stop }}</span>
            <span *ngIf="section.isClosed">{{ section.start }} - {{ section.stop }} (Stängt från app)</span>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</div>