<div>
  <p>
    <mat-toolbar>
      <button mat-icon-button routerLink="/venue/{{venueId}}/floorplan-editor"><mat-icon>arrow_back_ios</mat-icon></button>
      <span>Bordskarta Historik</span>
      <span class="spacer"></span>
    </mat-toolbar>
  </p>
</div>

<div style="padding: 16px;">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="user_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.user_name}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.date |date:'medium' }} </td>
    </ng-container>

    <ng-container matColumnDef="desc">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Desc </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.desc}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></tr>
  </table>
</div>

