export const pageTakeawaySettings = {
  title: { sv: "Takeaway" },
  page_settings: {
    root_path: "takeaway"
  },
  desc: { sv: "Ställ in hur takeaway ska fungera" },
  properties: [
    {key: "show_order_number", title: {sv: "Visa ordernummer på kvitto"}, desc: {sv: ""}, type: "bool", optional: false},
    { key: "anon_orderable", title: { sv: "Tillåt beställningar utan inlogg" }, desc: { sv: "Förenklar beställning för gäster, kan dock göra det lite svårare att hitta en beställning i t.ex. KDS:en" },
        type: "bool", optional: true
    },
    { key: "select_pickup_time", title: { sv: "Ange upphämtningstid" }, desc: { sv: " " }, type: "bool", optional: true },
    { key: "show_prep_time", title: { sv: "Visa tillagningstid" }, desc: { sv: " " }, type: "bool", optional: true },
    { key: "force_open_hours", title: { sv: "Tvinga öppettider" }, desc: { sv: " " }, type: "bool", optional: true },
    { key: "table_regex", title: { sv: "Bordsfilter (takeaway)" }, desc: { sv: " " }, default: "takeaway", type: "string", optional: false, hidden: true },
    { key: "areas", title: { sv: "Area matching" }, desc: { sv: "Anger vilka bord som skall var del av takeaway genom att matcha mot bordets area (går att kombinera med bordsfilter)" },  type: "string[]", optional: true, hidden: false },
    { key: "menu_filter_tag", title: { sv: "Artikelfiltreringstag (takeaway)" }, desc: { sv: " " }, default: "takeaway", type: "string", optional: false },
    { key: "cooking_time", title: { sv: "Tillagningstid" }, desc: { sv: "Obs! Ändringar görs i appen" }, type: "integer", suffix: "min", optional: false, hidden: true },
    { key: "pickup_locations", title: { sv: "Upphämtningsplatser" }, desc: { sv: "" }, type: "string[]", optional: true },
    { key: "added_pickup_minutes", title: { sv: "Tilläggstid för upphämtning" }, desc: { sv: "" }, type: "integer", suffix: "min", optional: true },
    { key: "open_hours", title: { sv: "" }, desc: { sv: "Öppettider. Tidformat exempel 12 = 12:00, 12.5 = 12:30, 12.75 = 12:45 etc. Dagformat mån = 0, tis = 1 ... sön = 6" }, type: "table", optional: false,
      columns: [
        { key: "day", title: { sv: "Dag" }, desc: { sv: "Dag" }, type: "integer[]", optional: false },
        { key: "start", title: { sv: "Öppning" }, desc: { sv: "" }, type: "float", optional: false },
        { key: "stop", title: { sv: "Stängning" }, desc: { sv: "" }, type: "float", optional: false },
      ]
    },

    { header: "Leveveransinformation" },
    { key: "delivery.delivery_time", title: { sv: "Leveranstid" }, desc: { sv: "" }, type: "integer", suffix: "min", optional: true },
    { key: "delivery.max_distance", title: { sv: "Max avstånd" }, desc: { sv: "" }, type: "integer", suffix: "Km", optional: true },
    { key: "delivery.min_order", title: { sv: "Minsta order" }, desc: { sv: "" }, type: "integer", suffix: "Kr", optional: true },
    { key: "delivery.price_id", title: { sv: "Leveranskostnads Id" }, desc: { sv: "Ange Id för leveranskosnad" }, type: "string", optional: true, hidden: true },


    { header: "Gästinformation för Takeaway" },
    { key: "text.title", title: { sv: "Titel för gäst, t.ex. Takeaway" }, type: "string", desc: { sv: "" }, optional: true },
    { key: "text.desc", title: { sv: "Beskrivning" }, type: "string", desc: { sv: "" }, optional: true },

    // { header: "Slottider" },
    // {key: "slotting.active", title: {sv: "Aktivt för gäster"}, desc: {sv: "Slår på/av bokingsmöjlighet för gäster, påverkar inte redan lagda bokningar"}, type: "bool"},
    // {key: "slotting.bong_prepare_minutes", title: {sv: "Förberedningstid"}, desc: {sv: "Hur långt i förväg skall bongen printas"}, default: 60, type: "integer", suffix: "min"},
    // {key: "slotting.pre_allocate_days", title: {sv: "Förallokering"}, desc: {sv: "Antal dagar framåt som skall förallokeras automatiskt"}, default: 5, type: "integer", suffix: "dagar"},
    // {key: "slotting.allocate", title: {sv: "Allokeringsmatris"}, desc: {sv: "Inställning av allokering av slottar"}, type: "table",
    //   columns: [
    //     {key: "when", title: {sv: "När"}, type: "string", width: 200 },
    //     {key: "capacity", title: {sv: "Kapacitet"}, type: "integer", width: 100 },
    //   ]
    // },
    // {key: "slotting.allocation_crons", title: {sv: "Allokeringstidpunkter"}, desc: {sv: "Ange vid vilka tider som allokering av nya slottar skall ske (ex mån-sön 23:30 eller fre 20:00)"}, type: "table",
    //   columns: [
    //     {key: "when", title: {sv: "När"}, type: "string", width: 200 },
    //   ]
    // },
    // {key: "slotting.items_capacity", title: {sv: "Kapacitetsförbrukning"}, desc: {sv: "Inställning av hur mycket kapacitet som förbrukas för produktsortimentet"}, type: "table",
    //   columns: [
    //     {key: "filter", title: {sv: "Filter"}, type: "string", width: 200 },
    //     {key: "capacity", title: {sv: "Kapacitet"}, type: "integer", width: 100 },
    //   ]
    // },
    // {key: "slotting.slot_length", title: {sv: "Slot-längd"}, desc: {sv: "Ange hur lång en slot är i minuter, t.ex. 15 min"}, default: 15, type: "integer", suffix: "min"},
    // {key: "slotting.over_booking_cap", title: {sv: "Överbokningskapacitet"}, desc: {sv: "Hur mycket kapacitet går det att överboka"}, default: 0, type: "integer", suffix: "cap"},
    // {key: "slotting.min_split_slot_allocation", title: {sv: "Minsta splitbokningskapacitet"}, desc: {sv: "Minsta kapacitet måste finnas kvar i en slot för att en splittad bokning skall bokas in"}, default: 0, type: "integer", suffix: "slots"},
    // {key: "slotting.namespaces", title: {sv: "Kalender partitioner"}, desc: {sv: "Ange namn och id för kalendrar"}, type: "table",
    //   columns: [
    //     {key: "id", title: {sv: "Id"}, type: "string", width: 100 },
    //     {key: "name", title: {sv: "Namn"}, type: "string", width: 300 },
    //   ]
    // },
    // {key: "slotting.bong_summaries", title: {sv: "Summeringsbongar"}, desc: {sv: "Ange när summeringsbongar skall skrivas ut och på vilken skrivare"}, type: "table",
    //   columns: [
    //     {key: "when", title: {sv: "När"}, type: "string", width: 200 },
    //     {key: "printer", title: {sv: "Skrivare"}, type: "string", width: 200 },
    //   ]
    // },

  ],
};
