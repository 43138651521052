import {Component, Inject, OnInit} from '@angular/core';
import {VenueService} from "../../../services/venue.service";
import {LoadingService} from "../../../services/loading.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormControl, Validators} from "@angular/forms";
import {HackUtils} from "../../../utils/utils";
import {NewUserDialogComponent} from "../new-user-dialog/new-user-dialog.component";
import {EditRoleDialogComponent} from "../edit-role-dialog/edit-role-dialog.component";

@Component({
  selector: 'app-query-user-dialog',
  templateUrl: './query-user-dialog.component.html',
  styleUrls: ['./query-user-dialog.component.css']
})
export class QueryUserDialogComponent implements OnInit {
  email = new UntypedFormControl('', [Validators.required, Validators.email]);
  private venueId?: number;

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<QueryUserDialogComponent>, public dialog: MatDialog) {
    this.venueId = data.venueId;
  }

  ngOnInit(): void {
  }

  checkUser() {
    if (this.email.valid) {
      const email = this.email.value;
      this.venueService.queryEmail(email, this.venueId).then( r => {
        console.log(`Exists ${r.exists}`);
        if (!r.exists) {
          this.openNewUserDialog();
        } else {
          this.openEditRoleDialog();
        }
      });
    }
  }

  openNewUserDialog() {
    const dialogRef = this.dialog.open(NewUserDialogComponent, HackUtils.DLG({
      data: { email: this.email.value, venueId: this.venueId }
    }));
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
      if (result) {
        this.dialogRef.close(result);
      }
    });
  }

  openEditRoleDialog() {
    const dialogRef = this.dialog.open(EditRoleDialogComponent, HackUtils.DLG({
      data: { email: this.email.value, venueId: this.venueId }
    }));
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
      if (result) {
        this.dialogRef.close(result);
      }
    });
  }

  getErrorMessage() {
    return this.email.hasError('email') ? 'Not a valid email' : '';
  }
}
