import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { catchError, Subject, switchMap, takeUntil } from 'rxjs';
import { FireService } from 'src/app/services/fire.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-opening-hours',
  templateUrl: './opening-hours.component.html',
  styleUrls: ['./opening-hours.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpeningHoursComponent implements OnInit, OnDestroy {
  configString: string;
  venueId: number;
  title: string;
  openingHours: any[] = [];
  editConfigUrl: string;
  currentDayName = new Date().toLocaleString('sv-SE', { weekday: 'long' });
  timeLabels: string[] = ['00', '03', '06', '09', '12', '15', '18', '21', '24', '03'];

  private destroy$ = new Subject<void>();

  constructor(private route: ActivatedRoute, private fire: FireService, private cdr: ChangeDetectorRef, private location: Location) { }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      takeUntil(this.destroy$),
      switchMap(params => {
        const venueIdStr = params.get("venue_id");
        const venueId = Number(venueIdStr);

        if (isNaN(venueId)) {
          console.error("Invalid venue_id:", venueIdStr);
          return [];
        }

        this.venueId = venueId;
        return this.fire.observeVenueConfig(this.venueId).pipe(
          catchError(err => {
            console.error("Error fetching venue config:", err);
            return [];
          })
        );
      })
    ).subscribe(cfg => {
      if (cfg) {
        this.extractOpeningHours(cfg);
        console.log("CONFIG: ", cfg);
        this.cdr.markForCheck();
      }
    });
  }

  extractOpeningHours(cfg: any): void {
    this.openingHours = [];

    if (cfg.official_open_hours) {
      const override = cfg.official_open_hours.override || '';
      this.mergeSections(this.transformOpeningHours(cfg.official_open_hours, 'Öppettider', override));
    }

    if (cfg.inhouse?.kitchen_time) {
      const override = cfg.inhouse.override || (cfg.inhouse.expected_delivery_time === 0 ? 'close' : '');
      this.mergeSections(this.transformOpeningHours(cfg.inhouse.kitchen_time, 'Inhouse', override));
    }

    if (cfg.takeaway?.open_hours) {
      const override = cfg.takeaway.override || '';
      this.mergeSections(this.transformOpeningHours(cfg.takeaway.open_hours, 'Takeaway', override));
    }

    if (cfg.roomservice?.open_hours) {
      const override = cfg.roomservice.override || '';
      this.mergeSections(this.transformOpeningHours(cfg.roomservice.open_hours, 'Roomservice', override));
    }

    if (cfg.booking?.open_hours) {
      const parsedBookingTimes = this.parseOpenHours(cfg.booking.open_hours);
      this.mergeSections(this.transformOpeningHours(parsedBookingTimes, 'Booking', ''));
    }
  }

  mergeSections(newSections: any[]): void {
    newSections.forEach(newSection => {
      const existingDay = this.openingHours.find(day => day.day === newSection.day);

      if (existingDay) {
        existingDay.sections.push(...newSection.sections);
      } else {
        this.openingHours.push(newSection);
      }
    });
  }

  transformOpeningHours(config: any[], sectionName: string, override: string): any[] {
    const daysOfWeek = ['Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag', 'Söndag'];
    const transformedHours: any[] = [];
    daysOfWeek.forEach(day => {
      transformedHours.push({
        day: day,
        sections: []
      });
    });

    config.forEach(timeSlot => {
      if (timeSlot.day && Array.isArray(timeSlot.day)) {
        const isClosed = override === "close";

        timeSlot.day.forEach((dayIndex: number) => {
          const dayName = daysOfWeek[dayIndex];
          const dayObj = transformedHours.find(d => d.day === dayName);

          if (dayObj) {
            dayObj.sections.push({
              name: sectionName,
              start: this.formatTime(timeSlot.start),
              stop: this.formatTime(timeSlot.stop),
              isClosed: isClosed
            });
          }
        });
      }
    });

    return transformedHours;
  }

  calculatePosition(time: string): number {
    let [hours, minutes] = time.split(':').map(Number);

    if (hours < 3) {
      hours += 24;
    }

    return (hours + minutes / 60) / 27 * 100;
  }

  getCurrentTimePosition(): number {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();

    const adjustedHours = hours < 3 ? hours + 24 : hours;

    const position = (adjustedHours + minutes / 60) / 27 * 100;
    const adjustment = (3 / 27) * 50;

    return position + adjustment;
}




  formatTime(time: number): string {
    const hours = Math.floor(time);
    const minutes = (time - hours) * 60;
    const formattedTime = new Date(0, 0, 0, hours % 24, minutes).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return formattedTime;
  }

  parseOpenHours(openHours: any[]): any[] {
    const daysOfWeek = {
      'måndag': 0, 'tisdag': 1, 'onsdag': 2, 'torsdag': 3, 'fredag': 4, 'lördag': 5, 'söndag': 6,
      'mån': 0, 'tis': 1, 'ons': 2, 'tors': 3, 'fre': 4, 'lör': 5, 'sön': 6
  };

    let parsedTimes: any[] = [];

    openHours.forEach(entry => {
      const [daysPart, timePart] = entry.when.split(' ');

      if (!timePart) {
        console.error("Invalid when format, missing time part:", entry.when);
        return;
      }

      const [startTime, endTime] = timePart.split('-').map(time => {
        const formattedTime = this.formatTimeToNumber(time);
        return formattedTime;
      });

      const days = this.expandDays(daysPart, daysOfWeek);

      days.forEach(day => {
        parsedTimes.push({
          day: [day],
          start: startTime,
          stop: endTime
        });
      });
    });

    return parsedTimes;
  }

  expandDays(daysPart: string, daysOfWeek: any): number[] {
    const days = [];
    const dayRanges = daysPart.split(',');
    dayRanges.forEach(range => {
        const [startDay, endDay] = range.split('-').map(day => day.trim().toLowerCase());

        const startIndex = daysOfWeek[startDay];
        const endIndex = endDay ? daysOfWeek[endDay] : null;

        if (endDay && startIndex !== undefined && endIndex !== undefined) {
            for (let i = startIndex; i <= endIndex; i++) {
                days.push(i);
            }
        } else if (startIndex !== undefined) {
            days.push(startIndex);
        } else {
            console.error("Invalid day range:", range);
        }
    });

    return days;
}



  formatTimeToNumber(time: string): number {
    if (!time) {
      console.error("Invalid time input:", time);
      return NaN;
    }

    const [hoursStr, minutesStr] = time.split(':');

    const hours = Number(hoursStr);
    const minutes = minutesStr ? Number(minutesStr) : 0;

    if (isNaN(hours) || isNaN(minutes)) {
      console.error("Invalid time format, cannot parse hours or minutes:", time);
      return NaN;
    }

    return hours + (minutes / 60);
  }

  goBack() {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}

