import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pnumber'
})
export class PnumberPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (!value) {
      return null;
    }
    else if (value.length > 10) {
      return `${value.substr(0, 8)}-${value.substr(8)}`;
    }
    return value;
    return null;
  }

}
