import {Component, Inject, NgZone, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MenuStateService} from "../../menu-utils/menu-state.service";

@Component({
  selector: 'app-menu-ic-dialog',
  templateUrl: './menu-ic-dialog.component.html',
  styleUrls: ['./menu-ic-dialog.component.css']
})
export class MenuIcDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {ic: string, name: string, venueId: number},
              public dialogRef: MatDialogRef<MenuIcDialogComponent>, private state: MenuStateService, private ngZone: NgZone) {}

  ngOnInit(): void {
  }

  private collectICString() {
    return "food";
  }

  okClicked() {
    const s = this.collectICString();
    this.dialogRef.close(s);
  }

}
