<h2 mat-dialog-title>Redigera: {{staff.identity | pnumber}}</h2>
<div mat-dialog-content style="position: relative;" *ngIf="roles">
  <div>
    <mat-form-field class="indialog">
      <mat-label>Förnamn</mat-label>
      <input matInput [formControl]="firstName" required>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="indialog">
      <mat-label>Efternamn</mat-label>
      <input matInput [formControl]="lastName" required>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="indialog">
      <mat-label>E-post</mat-label>
      <input matInput [formControl]="email">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="indialog">
      <mat-label>Telefon</mat-label>
      <input matInput [formControl]="phone">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="indialog">
      <mat-label>Roll</mat-label>
      <mat-select [formControl]="role">
        <mat-option *ngFor="let r of roles" value="{{r.role_id}}">{{r.role_name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="loadingService.visibility | async"></mat-spinner>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Avbryt</button>
  <button mat-button (click)="saveUser()">Spara</button>
</mat-dialog-actions>
