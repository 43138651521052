import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wiki-images',
  templateUrl: './wiki-images.component.html',
  styleUrls: ['./wiki-images.component.css']
})
export class WikiImagesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
