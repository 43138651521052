import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog} from "@angular/material/dialog";
import {VenueService} from "../../../services/venue.service";
import {Router} from "@angular/router";
import {RegUnit} from "../../../models/reg-unit";
import {MatSort} from "@angular/material/sort";
import {SelectionModel} from "@angular/cdk/collections";

@Component({
  selector: 'app-regunit-list',
  templateUrl: './regunit-list.component.html',
  styleUrls: ['./regunit-list.component.css']
})
export class RegunitListComponent implements OnInit {
  dataSource: MatTableDataSource<RegUnit>;
  displayedColumns = ["select", "reg_name", "state", "created", "updated"];

  constructor(public dialog: MatDialog, private venueService: VenueService, private router: Router) { }
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  regunits: RegUnit[];
  selection = new SelectionModel<RegUnit>(true, []);

  filters = [
    {title: "nya", value: "new"},
    {title: "alla", value: "all"},
    {title: "registrerade", value: "registered"},
    {title: "avregistrerade", value: "deregistered"},
    {title: "invalid", value: "invalid"}
  ];
  selectedFilter = "new";

  ngOnInit(): void {
    this.fetchRegunits();
  }

  private fetchRegunits() {
    this.venueService.fetchRegunits(this.selectedFilter).then(r => {
      this.regunits = r;
      this.dataSource = new MatTableDataSource(r as RegUnit[]);
      this.dataSource.sort = this.sort;
    });
  }

  select(row: any) {
  }

  createNew() {
    this.venueService.createNewRegUnits(this.selectedFilter).then(r => {
      this.regunits = r;
      this.dataSource = new MatTableDataSource(r as RegUnit[]);
      this.dataSource.sort = this.sort;
    });
  }

  update(state) {
    const regUnitKeys = this.selection.selected.map(regunit => regunit.key);
    if (regUnitKeys.length === 0) { return; }
    this.venueService.updateSelectedRegUnits(regUnitKeys, state, this.selectedFilter).then(r => {
      this.regunits = r;
      this.dataSource = new MatTableDataSource(r as RegUnit[]);
      this.dataSource.sort = this.sort;
    });
  }

  exportCSV() {
    const regUnitKeys = this.selection.selected.map(regunit => regunit.key);
    if (regUnitKeys.length === 0) { return; }
    this.venueService.exportSelectedRegUnits(regUnitKeys, "skv_clients.csv").subscribe(r => {
      console.log(r);
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  trackItem(index: number, item: RegUnit): string {
    return item.key;
  }

  masterToggle() {
    this.anySelected() ? this.selection.clear() : this.dataSource.data.filter(row => row.key).forEach(row => this.selection.select(row));
  }

  private anySelected() {
    const numSelected = this.selection.selected.length;
    return numSelected > 0;
  }

  isSelected(row) {
    const v = this.selection.selected.find(regunit => regunit.key === row.key);
    return v !== undefined;
  }

  changeFilter() {
    console.log(this.selectedFilter);
    this.fetchRegunits();
    this.selection.clear();
  }
}
