<div>
  <p>
    <mat-toolbar>
      <span>Workflow</span>
      <span class="spacer"></span>
      <button mat-button (click)="migrate()" *ngIf="migrateButton">Migrera workflow till james</button>
      <button mat-button (click)="toggleViewMode()">
        <mat-icon *ngIf="viewMode=='tabs'">list</mat-icon>
        <span *ngIf="viewMode=='tabs'"> Lista</span>
        
        <mat-icon *ngIf="viewMode=='list'">tabs</mat-icon>
        <span *ngIf="viewMode=='list'"> Flikar</span>
      </button>
      <button mat-button (click)="addNew()">
        <mat-icon>add</mat-icon> Nytt
      </button>
      <button mat-button routerLink="/venue/{{venueId}}/settings-history/workflow">
        <mat-icon>history</mat-icon> Historik
      </button>
      <button mat-button (click)="restoreVersion()" *ngIf="version!=null && !sameAsCurrent">
        <mat-icon>settings_backup_restore</mat-icon> Återställ
      </button>
      <button mat-button (click)="save()" *ngIf="version==null">
        <mat-icon>save</mat-icon> Spara
      </button>
    </mat-toolbar>
  </p>
</div>

<div *ngIf="errorMessage" class="error-panel" style="margin-bottom: 32px;">
  <pre>{{errorMessage}}</pre>
  <div class="error-close" (click)="closeError()"><mat-icon>close</mat-icon></div>
</div>

<div class="wf_body">
  <!-- Create app-wfs for all classes-->
  <mat-tab-group *ngIf="viewMode=='tabs'">
    <mat-tab [label]="wfClass.title" *ngFor="let wfClass of wfClasses">
      <div style="padding: 0 16px;">
        <app-wf-class [wfClass]="wfClass" (workflowCopy)="copyWorkflow($event)" (workflowDelete)="deleteWorkflow($event)"></app-wf-class>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div *ngIf="viewMode=='list'">
    <div style="padding: 0 16px;" *ngFor="let wfClass of wfClasses">
      <app-wf-class [wfClass]="wfClass" (workflowCopy)="copyWorkflow($event)" (workflowDelete)="deleteWorkflow($event)"></app-wf-class>
    </div>
  </div>
  <div>
    <app-wiki-display section="settings" path="workflow"></app-wiki-display>
  </div>
</div>
