import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {QueryReceipt} from "../../../models/query-receipt";
import {MatSort, MatSortable} from "@angular/material/sort";
import {VenueService} from "../../../services/venue.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Location} from "@angular/common";
import {SelectionModel} from "@angular/cdk/collections";
import {ReceiptDialogComponent} from "../../pos/receipt-view/receipt-dialog/receipt-dialog.component";
import {HackUtils} from "../../../utils/utils";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {InvoicePrepareDialogComponent} from "../../dialogs/invoice-prepare-dialog/invoice-prepare-dialog.component";

@Component({
  selector: 'app-pending-list',
  templateUrl: './pending-list.component.html',
  styleUrls: ['./pending-list.component.css']
})
export class PendingListComponent {
  dataSource: MatTableDataSource<QueryReceipt>;
  selectedRow?: QueryReceipt;
  receiptKey: string;
  selection = new SelectionModel<QueryReceipt>(true, []);

  @Input() venueId: string;
  @Input() accountId: string;
  @Input() state: string;

  @Output() ordersCount = new EventEmitter<number>();
  @Output() totalAmount = new EventEmitter<number>();

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  baseColumns = ["select", "pay_date", "serial_number", "amount", "sales", "tip", "table"]; //"staff_name",  "method"];
  displayedColumns = this.baseColumns;
  customColumns: { key: string, title: string }[] = [{key:"ref", title:"Ref"}, {key:"note", title:"Note"}];

  constructor(private venueService: VenueService, private route: ActivatedRoute,
              private dialog: MatDialog, private router: Router, private snackBar: MatSnackBar,
              private location: Location) { }

  ngOnInit(): void {
    this.sort.sort(({id: 'pay_date', start: 'desc'}) as MatSortable);
    this.showPending();
  }


  showPending() {
    this.venueService.fetchAccountEntires(this.venueId, this.accountId, this.state).then(res => {
      console.log("fetchAccountEntires", res);
      this.displayedColumns = [...this.baseColumns];
      // @ts-ignore
      this.customColumns = res.columns ?? [];
      for (var cc of this.customColumns) {
        this.displayedColumns.push(cc.key);
      }
      this.dataSource = new MatTableDataSource(res.receipts);
      this.dataSource.sort = this.sort;

      this.ordersCount.emit(res.receipts.length);
      const total = res.receipts.reduce((sum, receipt) => sum + receipt.amount, 0);
      this.totalAmount.emit(total);
    });
  }

  select(row: any) {
    console.log(row);
    this.openReceiptDialog(row);
  }

  isSelected(row) {
    const v = this.selection.selected.find(r => r.receipt_id === row.receipt_id);
    return v !== undefined;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.filteredData.forEach(row => {
        this.selection.select(row);
      });
    }
  }

  openReceiptDialog(row) {
    //this.receiptId = row.receipt_id;
    const dialogRef = this.dialog.open(ReceiptDialogComponent, HackUtils.DLG({
      data: {
        receiptKey: row.receipt_key,
        venueId: this.venueId,
        isSubComponent: true,
        refundReceiptKeys: row.refunded_keys
      }
    }));
  }

  openInvoiceDialog(references) {
    if (this.selection.selected.length === 0) {
      this.snackBar.open("Välj kvitton som skall ingå i fakturan", "", {duration: 5000});
      return;
    }

    const dialogRef = this.dialog.open(InvoicePrepareDialogComponent, {
      width: '500px',
      data: { references: references, selection: this.selection, venueId: this.venueId }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (result) {
        console.log(result);
        this.executeInvoiceCreation(result);
      }
    });
  }

  createInvoice() {
    const uniqueReferencesArray = this.getRefs();
    this.openInvoiceDialog(uniqueReferencesArray);
  }

  private executeInvoiceCreation(params) {
    const receiptKeys = this.selection.selected.map(r => r.receipt_key);
    this.venueService.processAccountEntries(this.venueId, receiptKeys, "fortnox_invoice", params).then((res) => {
      this.selection.clear();
      this.snackBar.open(res.message, "", {duration: 5000});
      this.showPending();
    }, error => SimpleDialogComponent.showErr(this.dialog, error));
  }

  private getRefs() {
    const uniqueReferences = new Set<string>();
    this.selection.selected.forEach(selectedItem => {
      // For each custom column, add its value to the Set
      this.customColumns.forEach(column => {
        const value = selectedItem[column.key];
        if (value) {
          uniqueReferences.add(value);
        }
      });
    });

    return Array.from(uniqueReferences);
  }
  toggleSelection(row: any) {
    this.selection.toggle(row);
  }
}
