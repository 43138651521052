import {Component, Input, OnInit} from '@angular/core';
import {VenueService} from "../../services/venue.service";
import {ActivatedRoute} from "@angular/router";
import {VenueDashboard} from "../../models/venue-dashboard";
import Utils from "../../common/utils";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private venueService: VenueService, private route: ActivatedRoute) {
  }

  @Input()
  venueId: string;
  venueDashboard: VenueDashboard;
  cashRegValues: any;
  infoValues: any;

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = data.get("venue_id");
      console.log(`venueId = ${this.venueId}`);
      this.beginObserving();
    });
  }

  private beginObserving() {
    this.venueService.getVenueDashboard(this.venueId).then(r => {
      this.venueDashboard = r;

      this.infoValues = [];
      this.infoValues.push({key: "Status", value: this.venueDashboard.status});
      this.infoValues.push({key: "Phone", value: this.venueDashboard.official_contact_phone});
      this.infoValues.push({key: "Messenger", value: `https://m.me/${this.venueDashboard.facebook_name}`, isLink: true});

      if (this.venueDashboard.admin_info) {
        for (const info of this.venueDashboard.admin_info) {
          this.infoValues.push({key: info.name, value: info.value, isLink: Utils.isStringLink(info.value)});
        }
      }

      this.cashRegValues = [];
      const cr = this.venueDashboard.cash_register;
      this.cashRegValues.push({key: "Bus name", value: cr.bus_name});
      this.cashRegValues.push({key: "Org number", value: cr.org_number});
      this.cashRegValues.push({key: "CCU", value: cr.ccu});
      this.cashRegValues.push({key: "Type", value: cr.type});
      this.cashRegValues.push({key: "Street", value: cr.street});
      this.cashRegValues.push({key: "Adress", value: cr.postal_code + ", " + cr.city});
      }
    );
  }

}
