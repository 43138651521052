import {Component, OnInit, ViewChild} from '@angular/core';
import {FireService} from "../../../services/fire.service";
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {VenueService} from "../../../services/venue.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {VenueMessage} from "../../../models/venue_message";
import {NewMessageDialogComponent} from "./new-message-dialog/new-message-dialog.component";
import {MenuIcDialogComponent} from "../../catalog/menu-tab/menu-ic-dialog/menu-ic-dialog.component";

@Component({
  selector: 'app-venue-message-list',
  templateUrl: './venue-message-list.component.html',
  styleUrls: ['./venue-message-list.component.css']
})
export class VenueMessageListComponent implements OnInit {

  venueId: string;
  private messages: VenueMessage[];

  constructor(private fire: FireService, private route: ActivatedRoute, public dialog: MatDialog, private venueService: VenueService) { }

  displayedColumns = ["date", "title", "message", "severity", "action"];
  dataSource: MatTableDataSource<VenueMessage>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit(): void {
    this.fetchData();
  }

  public refresh() {
    this.fetchData();
  }

  private fetchData() {
    this.venueService.fetchVenueMessages().then(messages => {
      this.messages = messages;
      this.dataSource = new MatTableDataSource(this.messages as VenueMessage[]);
      this.dataSource.sort = this.sort;
    });
  }

  newMessage() {
    const dialogRef = this.dialog.open(NewMessageDialogComponent, {});
    dialogRef.afterClosed().subscribe(result => {
      this.fetchData();
    });
  }

  unpublish(key) {
    this.venueService.unpublishVenueMessage(key).then(messages => {
    });
  }
}
