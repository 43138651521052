<div>
  <p>
    <mat-toolbar>
      <span>Wiki: {{section}}:{{path}}</span>
      <span class="spacer"></span>
      <button mat-button (click)="preview()"><mat-icon>visibility</mat-icon> Förhandsgranska</button>
      <button mat-button (click)="save()"><mat-icon>save</mat-icon> Spara</button>
      <button mat-button (click)="publish()"><mat-icon>publish</mat-icon> Publicera</button>
    </mat-toolbar>
  </p>
</div>

<div style="display: flex;flex-direction: column; align-items: center; justify-content: center; height: 300px;width: 100%" *ngIf="!wikiDoc">
  <mat-spinner class="add-item-spinner" [diameter]="32"></mat-spinner>
  <b style="padding-top: 16px;">Läser in wiki innehåll...</b>
</div>

<div style="color: #de4f4f;padding: 0 0 0 16px;" *ngIf="!isProd && wikiDoc">OBS! Tänk på att även om du inte är produktionsmiljö så kommer det som publiceras synas i produktion!</div>
<div class="wiki-content" *ngIf="wikiDoc">
  <div style="flex: 1;">
    <div style="margin: 0; padding:4px; background-color: #f0f0f0;"><span [class]="editor.color" *ngFor="let editor of editors">{{editor.first_name}}</span></div>

    <mat-form-field class="ta" appearance="outline">
      <mat-label>Innehåll</mat-label>
      <textarea style="font-family: 'Andale Mono'" matInput rows="30" [(ngModel)]="markdown"></textarea>
    </mat-form-field>
    <div *ngIf="wikiDoc?.editor_email" class="doc-info">
      <div><i>Senast redigerad av:</i> {{wikiDoc.editor_name}}</div>
      <div><i>Senast sparad:</i> {{wikiDoc.updated | date: "short"}}</div>
      <div><i>Senast publicerad:</i> {{wikiDoc.published | date: "short"}}</div>
    </div>
    <div>
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Redigeringshjälp
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-wiki-display [section]="'generic-help'" [path]="'wiki-editor-help'" [style]="'wiki-doc-no-mar'"></app-wiki-display>
      </mat-expansion-panel>
    </div>
  </div>
  <div style="flex: 1;">
    <div style="margin: 0; padding:4px 4px 4px 16px; background-color: #f0f0f0;">PREVIEW ( Wide preview: <mat-checkbox (click)="$event.stopPropagation()" (change)="changePreviewStyle()" [checked]="previewStyle=='wiki-doc'"></mat-checkbox> )</div>
    <app-wiki-display [html]="previewHtml" [style]="previewStyle"></app-wiki-display>
  </div>
</div>
