export function whenValidator(value, callback) {
  if (value) {
     callback(true);
    // if (/.+@.+/.test(value)) {
    //   callback(true);
    // } else {
    //   callback(false);
    // }
  } else {
    callback(true);
  }
}

export function positiveIntegerValidator(value, callback) {
  if (value) {
    if (/^\d+$/.test(value)) {
      callback(true);
    } else {
      callback(false);
    }
  } else {
    callback(true);
  }
}
