export const pageOrderTags = {
  title: {sv: "Order-taggar"},
  page_settings: {
    root_path: "order_tags"
  },
  desc: {sv: "Order-taggar för kassapunkt"},
  properties: [
    {key: "auto_close_tag_page", title: {sv: "Auto-stäng tag-listan"}, desc: {sv: "Anger om tag-listan skall stängas efter ett klick"}, type: "bool"},
    {key: "tags", title: {sv: "Order-taggar"}, desc: {sv: ""}, type: "table",
      columns: [
        {key: "tag", title: {sv: "Tag"}, type: "string", width: 100 },
        {key: "type", title: {sv: "Typ"}, type: "enum", width: 100, source: ["text", "number", "none"] },
        {key: "color", title: {sv: "Färg"}, type: "string", width: 100 },
        {key: "display_receipt", title: {sv: "Visa på kvitto"}, type: "bool", width: 100 },
        {key: "value_hint", title: {sv: "Ledtråd"}, type: "string", width: 100 },
        {key: "filter", title: {sv: "Filter"}, type: "string", width: 100 },
        {key: "value", title: {sv: "Värde"}, type: "string", width: 100 },
        {key: "inline", title: {sv: "Inline"}, type: "bool", width: 100 },
      ]
    },
  ]
};
