import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from "rxjs";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {MenuStateService} from "../menu-utils/menu-state.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MenuPublishDialogComponent} from "../menu-publish-dialog/menu-publish-dialog.component";
import {FireService} from "../../../services/fire.service";
import {VenueConfig} from "../../../models/venue-config";
import { AuthService } from 'src/app/services/auth.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-menu-nav',
  templateUrl: './menu-nav.component.html',
  styleUrls: ['./menu-nav.component.css']
})
export class MenuNavComponent implements OnInit, OnDestroy {
  @Input() isMobile: boolean;
  @Input() topNav: boolean;
  @Input() bottomNav: boolean;
  @Output() tog = new EventEmitter();
  columnFilter = "info";
  filterButtons: any[];
  config: VenueConfig;
  private sub: Subscription;
  private dialogRef: MatDialogRef<MenuPublishDialogComponent, any>;
  private venueId: number;
  private url = "";
  public isSuperAdmin = false;

  constructor(private router: Router, private state: MenuStateService, public dialog: MatDialog, private route: ActivatedRoute,
              private fire: FireService, private auth: AuthService, private titleService: Title, ) {
    this.subToRouterEvents();
  }

  ngOnInit(): void {
    this.venueId = Number(this.route.snapshot.paramMap.get("venue_id"));
    this.sub = this.fire.observeVenueConfig(this.venueId).subscribe(vc => {
      this.config = vc;
      this.setFilterButtons(this.getColumnFilters());
      this.titleService.setTitle(`${vc?.venue?.name ? vc.venue.name + ' - Heynow Admin' : 'Heynow Admin'}`);
    });
    this.isSuperAdmin = AuthService.isSuperAdmin(this.auth.authStateSnapshot);

  }

  subToRouterEvents() {
    this.router.events.subscribe(event => {
      if ( event instanceof NavigationEnd ) {
        this.url = event.urlAfterRedirects || event.url;
        this.setFilterButtons(this.getColumnFilters());
      }
    });
  }

  private setFilterButtons(fbs) {
    if (this.filterButtons?.length !== fbs.length) {
      this.filterButtons = fbs;

      // Reset column-filter after a small period "hack"
      const k = this.columnFilter;
      this.columnFilter = "";
      setTimeout(() => {
        this.columnFilter = k;
        console.log(`New filter buttons '${this.filterButtons.map(fb => fb.name)}'`);
      }, 30);
    }
  }

  private getColumnFilters() {
    let fbs;
    if (this.url.indexOf("/tab/") >= 0) {
      fbs = [{value: "info", name: "Info"}, {value: "details", name: "Details"}, {value: "prices", name: "Prices"}, {value: "images", name: "Images"}, {value: "allergenes", name: "Allergenes"},
        {value: "filters", name: "Filters"}, {value: "audit", name: "Audit"}, {value: "raw", name: "Raw"}];
      // if (this.config?.menu?.price_list) {
      //   fbs.splice(2, 0, {value: "prices", name: "Prices"});
      // }
    } else if (this.url.indexOf("/options_tab") >= 0) {
      fbs = [{value: "info", name: "Info"}, {value: "allergenes", name: "Allergenes"}];
    } else {
      fbs = [];
    }
    return fbs;
  }

  onFilterChange() {
    console.log("Selected filter: " + this.columnFilter);
    this.state.selectNewFilter(this.columnFilter);
  }

  publish() {
    if (this.dialogRef == null) {
      this.dialogRef = this.dialog.open(MenuPublishDialogComponent, {
        minWidth: "400px",
        data: { venueId: this.venueId },
        disableClose: true
      });
      this.dialogRef.afterClosed().subscribe(result => {
        console.log(result);
        this.dialogRef = null;
      });
    }
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  public toggle(): void {
    this.tog.emit("toggle");
  }
}
