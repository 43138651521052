import {Component, OnDestroy, OnInit} from '@angular/core';
import {FireService} from "../../services/fire.service";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {PrinterConfig} from "../../models/venue-config";
import {Bong, BongSummary} from "../../models/kds-bong";
import {BongService} from "../../services/bong.service";

@Component({
  selector: 'app-kds-bong-list',
  templateUrl: './kds-bong-list.component.html',
  styleUrls: ['./kds-bong-list.component.css']
})
export class KdsBongListComponent implements OnInit, OnDestroy {
  venueId: number;
  unfiltredBongs: Bong[];
  bongSummaries: BongSummary[];
  printers: PrinterConfig[];
  printerToggles = {};
  private pjsub: Subscription;
  showActive: boolean;

  constructor(private fire: FireService, private route: ActivatedRoute, private bongService: BongService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.showActive = true;
      this.beginObserve();
    });
  }

  ngOnDestroy(): void {
    if (this.pjsub) {
      this.pjsub.unsubscribe();
    }
  }

  private beginObserve() {
    if (this.pjsub) { this.pjsub.unsubscribe(); }
    this.pjsub = this.bongService.observeBongSummary(this.venueId, null, this.showActive).subscribe(res => {
        this.bongSummaries = res;
      }
    );
  }

  toggleActive() {
    this.beginObserve();
  }

}
