<div>
  <p>
    <mat-toolbar>
      <span>Kedjor</span>
      <span class="spacer"></span>
      <button mat-button (click)="addChain()">
        <mat-icon>add</mat-icon> Ny kedja
      </button>
    </mat-toolbar>
  </p>
</div>
<div style="padding: 16px;">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="path">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Path</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;max-width: 125px;font-size:12px;white-space: normal;word-wrap: break-word;"> {{element.path}} </td>
    </ng-container>

    <ng-container matColumnDef="chain_types">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Types</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.chain_types}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Namn</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.name}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></tr>
  </table>
  <div style="margin-top: 32px;">
    <app-wiki-display section="intern-support-wiki" path="chain" [style]="'wiki-doc'"></app-wiki-display>
  </div>
</div>

