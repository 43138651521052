import {Component, Input, NgZone, OnChanges, OnInit, Renderer2, SimpleChanges} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, NavigationBehaviorOptions, Router} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {environment} from "../../../../environments/environment";
import {HeywikiService} from "../../../services/heywiki.service";
import {VenueService} from "../../../services/venue.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Clipboard} from '@angular/cdk/clipboard';
import {HeyWikiDocument} from "../../../models/genji-models";

declare global {
  interface Window { Wiki: any; }
}

@Component({
  selector: 'app-wiki-display',
  templateUrl: './wiki-display.component.html',
  styleUrls: ['./wiki-display.component.css']
})
export class WikiDisplayComponent implements OnInit, OnChanges {

  _section: string;
  _path: string;
  _style = "wiki-panel-wide";
  _html: any;
  _title: string;

  wikiDocEmpty: boolean;
  isSuperAdmin: boolean;
  private wikiDoc: HeyWikiDocument;

  @Input() set section(sec: string) {
    this._section = sec;
  }
  @Input() set path(path: string) {
    this._path = path;
  }
  @Input() set html(htm: string) {
    this.setHTMLData(htm);
  }
  @Input() set style(s: string) {
    this._style = s;
  }
  @Input() set title(title: string) {
    this._title = title;
  }
  @Input() preview: boolean = false;
  @Input() target: string = "wiki";

  ngOnChanges(changes: SimpleChanges) {
    if (changes.path || changes.section) {
      console.log("WikiDisplayComponent: ngOnChanges: path or section changed");
      this.update();
    }
  }

  constructor(private sanitizer: DomSanitizer, protected http: HttpClient, private router: Router, private auth: AuthService,
              private ngZone: NgZone, private activatedRoute: ActivatedRoute, private dialog: MatDialog, private heywiki: HeywikiService,
              private venueService: VenueService, private snackBar: MatSnackBar, private clipboard: Clipboard) { }

  ngOnInit(): void {
    window.Wiki = window.Wiki || {};
    window.Wiki.link = (t) => {
      this.ngZone.run(() => {
        const href = t.href;
        let stripped = href.replace(/^.*\/\/[^\/]+/, '');
        console.log(href, stripped);
        // Handle all links that starts with # as relative to the current page
        if (stripped.startsWith("/#")) {
          stripped = stripped.substr(2);
          this.router.navigate([stripped], { relativeTo: this.activatedRoute });
        } else {
          this.router.navigateByUrl(stripped);
        }
        this._html = null;
      });
      return false;
    };
    this.isSuperAdmin = AuthService.isSuperAdmin(this.auth.authStateSnapshot);
    this.update();
  }

  editWiki() {
    this.router.navigateByUrl(`edit-wiki/${this._section}/${this._path}`);
    return false;
  }

  editInHeynowWiki() {
    this.venueService.createSSOToken().then(res => {
      var url = environment.base_url;
      window.open(`https://heynow-wiki.web.app/#/edit-wiki/${this._section}/${this._path}?t=${res.token}:${url}`, "_blank");
    });
    return false;
  }

  private update() {
    if (this._section && this._path) {
      if (!environment.is_live || this.preview) {
        this.updateWithPreview();
        return;
      }

      this.http.get(`https://storage.googleapis.com/heynowdocs/${this._section}/${this._path}.html`, { responseType: 'text' }).subscribe(
        data => {
          this.setHTMLData(data);
          this.wikiDocEmpty = false;
        },
        error => {
          this.wikiDocEmpty = true;
          this._html = undefined;
        }
      );
    }
  }

  private setHTMLData(data: string) {
    data = this.preProcess(data);
    //console.log(data);
    this._html = this.sanitizer.bypassSecurityTrustHtml(data);
  }

  private preProcess(data: string) {
    if (data) {
      //add class to img tags
      data = data.replace(/<img/g, '<img class="wiki-img"');

      //process links
      const regex = /<a\s+href="([^"]+)">([^<]+?)<\/a>/g;
      const matches = data.match(regex);
      if (matches == null) {
        return data;
      }
      console.log("Matches", matches.length);
      for (const match of matches) {
        const href = match.match(/href="([^"]+)"/)[1];
        const text = match.match(/>([^<]+?)<\/a>/)[1];
        if (href.startsWith("http")) {
          data = data.replace(match, `<a href="${href}" target="_blank">${text}</a>`);
        } else if (href.startsWith("loom:")) {
          const loomVideoId = href.substr(5);
          const vt =
            `<div style="padding: 0">
              <a href="https://www.loom.com/share/${loomVideoId}" target="_blank">
                <img style="width:100%;max-width: 400px;" src="https://cdn.loom.com/sessions/thumbnails/${loomVideoId}-with-play.gif">
              </a>
            </div>
          `;
          //console.log("VT", vt);
          data = data.replace(match, vt);
        } else {
          data = data.replace(match, `<a href="${href}" onclick="return Wiki.link(this);">${text}</a>`);
        }
      }
    }
    return data;
  }

  showLoom(loomVideoId, title= "Help") {
    SimpleDialogComponent.openLoomDialog(this.dialog, {title, loomVideoId});
  }

  private updateWithPreview() {
    console.log("updateWithPreview");
    this.heywiki.fetchDoc(this._section, this._path).subscribe(d => {
      this.wikiDoc = d;
      this.setHTMLData(d.html + (this.preview?"":"<pre>Preview av artikel synlig i dev/beta</pre>"));
      this.wikiDocEmpty = false;
    }, e => {
      this.wikiDocEmpty = true;
      this._html = undefined;
    });
  }

  copyInternalLink() {
    const markdown = `[${this._path}](/doc/${this._section}/${this._path})`;
    console.log(markdown);
    this.clipboard.copy(markdown);
    this.snackBar.open(`Kopierade markdown till wiki dokument`, "", {duration: 3000});
    return false;
  }

  _copyInternalLink() {
  }

}
