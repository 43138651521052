import {Component, Inject} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {LoadingService} from "../../../services/loading.service";
import { PrepaidData } from 'src/app/models/prepaid-data';
@Component({
  selector: 'app-edit-prepaid-no',
  templateUrl: './edit-prepaid-no-dialog.component.html',
  styleUrls: ['./edit-prepaid-no-dialog.component.css']
})
export class EditPrepaidNoDialogComponent {
  dialogTitle?: string = "";

  form: FormGroup = new FormGroup({
    prepaid_id: new FormControl('', [Validators.required]),
  });
  prepaidData: PrepaidData;

  constructor(@Inject(MAT_DIALOG_DATA) data, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<EditPrepaidNoDialogComponent>) {
    this.dialogTitle = data.dialogTitle ?? this.dialogTitle;
    this.prepaidData = data.prepaidData;
  }

  confirmButtonClicked() {
    if (this.form.invalid) { return; }
    this.dialogRef.close(String(this.form.get('prepaid_id').value));
  }

}
