import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { InvoiceBasis } from 'src/app/models/billing';
import { InvoicesService } from 'src/app/services/invoices.service';


@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {

  displayedColumns = [
    "venue",
    "period",
    "amount",
    "checked"
  ];
  dataSource: MatTableDataSource<InvoiceBasis>;
  selection = new SelectionModel<InvoiceBasis>(true, []);

  constructor(private invoicesService: InvoicesService) { }

  ngOnInit(): void {
    this.invoicesService.fetchInvoicingBasis().then(b => {
      this.dataSource = new MatTableDataSource(b as InvoiceBasis[]);
    });
  }

  createInvoices(): void {
    if (this.selection.selected && this.selection.selected.length !== 0) {
      const venueIds = this.selection.selected.map(s => s.venue_id);
      this.invoicesService.createInvoices(venueIds);
    }
  }

}
