import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {combineLatest, Subscription} from "rxjs";
import {VenueService} from "../../../services/venue.service";
import {ActivatedRoute, ParamMap, Params, Router} from "@angular/router";
import {QuerySalesDataRequest, QuerySalesTreeNode} from "../../../models/reporting";
import * as moment from "moment";
import Utils from "../../../common/utils";
import {LocalFileUtils, NodeUtils, HackUtils} from "../../../utils/utils";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatLegacyMenuTrigger as MatMenuTrigger} from "@angular/material/legacy-menu";
import {FireService} from "../../../services/fire.service";
import {first, switchMap, take, tap} from "rxjs/operators";
import { SimpleDialogComponent } from '../../simple-dialog/simple-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-report-tree',
  templateUrl: './report-tree.component.html',
  styleUrls: ['./report-tree.component.scss']
})
export class ReportTreeComponent implements OnInit, OnDestroy {

  @ViewChild('reportsMenuTrigger') reportsMenuTrigger: MatMenuTrigger;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateIsMobile();
  }

  title = "Försäljningsrapport";
  searchStartDate: Date;
  searchEndDate: Date;
  filter: string;
  filterList: string[] = [];
  showAttributes = false;
  includeCogs = false;
  groupBy: string;
  showSalesType: string;
  showCount = true;
  usingOldDate: boolean;
  private RTI: any;
  reportTreeOptions = [];

  nodes: QuerySalesTreeNode[] = [];
  queryResultParams: any;
  options: any = {
    displayField: 'name',
    useVirtualScroll: false,
    nodeHeight: 25,
    allowDrag: false,
    allowDrop: false
  };
  state: any;

  columns = [];
  columnsSwe = [];
  venueId: number;
  noData = false;
  isMobile: boolean;
  isVault: boolean;
  cfg: any;
  selectedNode: any;

  private paramSub: Subscription;
  private q: string;
  private prevExpandedNodeIds: any;

  tempReportTreeOptions: any[] = [];

  constructor(private venueService: VenueService, private route: ActivatedRoute, private router: Router, private snackBar: MatSnackBar,
              private fire: FireService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.updateIsMobile();
    this.paramSub = combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
    ]).pipe(
      switchMap(([param, query]) => {
        this.venueId = Number(param.get("venue_id"));
        return this.fire.observeVenueConfig(this.venueId).pipe(
          take(1),
          tap(cfg => {
            this.cfg = cfg;
            this.isVault = cfg.chain != null;
            console.log("isVault? ", this.isVault);
            this.buildRTI();
            this.buildReportTreeOptions();
            this.parseQueryParams(query);
            this.initTempReportTreeOptions();
            if (this.q !== "search") {
              this.fetch();
            }
          })
        );
      })
    ).subscribe();
  }

  initTempReportTreeOptions() {
    this.tempReportTreeOptions = this.reportTreeOptions.map(option => ({...option}));
  }

  private updateReportTreeOptionsFromParams() {
    if (this.reportTreeOptions[this.RTI.SHOW_ATR]) {
      this.reportTreeOptions[this.RTI.SHOW_ATR].active = this.showAttributes;
    }
    if (this.reportTreeOptions[this.RTI.GROUP_AREA]) {
      this.reportTreeOptions[this.RTI.GROUP_AREA].active = this.groupBy?.includes("area");
    }
    if (this.reportTreeOptions[this.RTI.GROUP_SECTION]) {
      this.reportTreeOptions[this.RTI.GROUP_SECTION].active = this.groupBy?.includes("section");
    }
    if (this.reportTreeOptions[this.RTI.GROUP_ONL]) {
      this.reportTreeOptions[this.RTI.GROUP_ONL].active = this.groupBy?.includes("online");
    }
    if (this.reportTreeOptions[this.RTI.GROUP_TIME]) {
      this.reportTreeOptions[this.RTI.GROUP_TIME].active = this.groupBy?.includes("time");
    }
    if (this.reportTreeOptions[this.RTI.GROUP_SELLER]) {
      this.reportTreeOptions[this.RTI.GROUP_SELLER].active = this.groupBy?.includes("seller");
    }
    if (this.reportTreeOptions[this.RTI.GROUP_COST_CENTER]) {
      this.reportTreeOptions[this.RTI.GROUP_COST_CENTER].active = this.groupBy?.includes("cost_center");
    }
    if (this.isVault && this.reportTreeOptions[this.RTI.GROUP_BY_VENUE]) {
      this.reportTreeOptions[this.RTI.GROUP_BY_VENUE].active = this.groupBy?.includes("venue");
    }
    if (this.isMobile && this.reportTreeOptions[this.RTI.SHOW_VAT]) {
      this.reportTreeOptions[this.RTI.SHOW_VAT].active = this.showSalesType === "inc_vat";
    }
    if (this.reportTreeOptions[this.RTI.SHOW_COGS]) {
      this.reportTreeOptions[this.RTI.SHOW_COGS].active = this.includeCogs;
    }
  }

  ngOnDestroy(): void {
    this.paramSub?.unsubscribe();
  }

  updateIsMobile() {
    this.isMobile = HackUtils.isMobile() && window.innerWidth <= 768;
  }

  onRowSelect(node: any) {
    console.log("selected node", node);
    this.selectedNode = node;
  }

  private buildRTI() {
    this.RTI = {
      GROUP_AREA: 0,
      GROUP_SECTION: 1,
      GROUP_ONL: 2,
      GROUP_TIME: 3,
      GROUP_SELLER: 4,
      GROUP_COST_CENTER: 5,
      ...(this.isVault ? { GROUP_BY_VENUE: 6, SHOW_ATR: 7, SHOW_COGS: 8, SHOW_VAT: 9 } : { SHOW_ATR: 6, SHOW_COGS: 7, SHOW_VAT: 8 }),
    };
  }

  private buildReportTreeOptions() {
    this.reportTreeOptions = [
      {title: "Gruppera på area", active: false},
      {title: "Gruppera på sektion", active: false},
      {title: "Gruppera på online (självservice)", active: false},
      {title: "Gruppera på tid", active: false},
      {title: "Gruppera på säljare", active: false},
      {title: "Gruppera på kostnadsställe", active: false},
      ...(this.isVault ? [{title: "Gruppera på venue (kedja)", active: false}] : []),
      {title: "Visa attribut", active: false},
      {title: "Visa och filtrera på COGS", active: false},
      ...(this.isMobile ? [{title: "Visa inkl.moms", active: false}] : [])
    ];
  }

  private buildQueryRequest(): QuerySalesDataRequest {
    const query = new QuerySalesDataRequest();
    query.venue_id = this.venueId.toString();
    if (this.searchStartDate != null) {
      query.start_date = Utils.dateToDateKey(this.searchStartDate);
    }
    if (this.searchEndDate != null) {
      query.end_date = Utils.dateToDateKey(this.searchEndDate);
    }
    if (this.showAttributes) {
      query.show_priced_atr = true;
    }
    if (this.includeCogs) {
      query.include_cogs = true;
    }
    if (this.groupBy) {
      query.group_by = this.groupBy;
    }
    return query;
  }

  private fetch() {
    const query = this.buildQueryRequest();
    this.noData = false;
    this.usingOldDate = this.usingOldDateInQuery(query);
    this.prevExpandedNodeIds = this.state?.expandedNodeIds;
    if (NodeUtils.isNullOrEmpty(this.prevExpandedNodeIds)) {
      this.prevExpandedNodeIds = { total__: true, ic_drink: true, ic_food: true};
    }
    this.venueService.querySalesTreeData(query).then(r => {
      console.log("query", r);
      // @ts-ignore
      this.nodes = r.nodes;
      this.queryResultParams = r.params;
      this.noData = r.nodes.length === 0;
      this.state = {
        ...this.state,
        expandedNodeIds: this.prevExpandedNodeIds
      };
    }).catch(e => {
      SimpleDialogComponent.showErr(this.dialog, e);
      console.error(e);
    });
  }

  updateParams() {
    const queryParams: Params = {r: Utils.getRandomInt(500)};
    if (this.searchStartDate != null) {
      queryParams.start_date = Utils.dateToDateKey(this.searchStartDate);
    }
    if (this.searchEndDate != null) {
      queryParams.end_date = Utils.dateToDateKey(this.searchEndDate);
    }
    if (this.showAttributes) {
      queryParams.priced_atr = 1;
    }
    if (this.includeCogs) {
      queryParams.cogs = 1;
    }
    queryParams.sales_type = this.showSalesType;
    queryParams.group_by = this.groupBy;
    this.router.navigate([], { relativeTo: this.route, queryParams });

    // After updating URL parameters, update reportTreeOptions and tempReportTreeOptions
    this.updateReportTreeOptionsFromParams();
    this.initTempReportTreeOptions();
  }

  addFilter() {
  }

  clearFilters() {
    this.clearParams();
  }

  private clearParams() {
    this.searchStartDate = null;
    this.searchEndDate = null;
  }

  async export(csv: string) {
    const data = this.buildDataArrayWithHeader();
    console.log(data);
    const config = await this.fire.observeVenueConfig(this.venueId).pipe(first()).toPromise();
    const dates = LocalFileUtils.createDateFileName(this.queryResultParams.start_date, this.queryResultParams.end_date);
    const filename = `report_${config.venue.name.replace(/\\s/g, "_")}_${dates}.csv`;
    LocalFileUtils.createFileWithBOM(data, filename);
  }

  private markAsExpanded(node: any) {
    node.isExpanded = true;
    const children = node.children;
    if (children) {
      for (const n of children) {
        this.markAsExpanded(n);
      }
    }
  }

  private setupInitialParams(q: string) {
    switch (q) {
      case "today":
        this.searchStartDate = moment().toDate();
        break;
      case "yesterday":
        this.searchStartDate = moment().subtract(1, "day").toDate();
        break;
      case "all":
        this.searchEndDate = moment().toDate();
        break;
      case "search":
        break;
    }
  }

  private setupColumns() {
    const columns = [];
    const columnsSwe = [];
    if (this.isMobile) {
      if (this.showSalesType === "ex_vat") {
        columns.push("sales");
        columnsSwe.push("ex.moms");
      } else {
        columns.push("sales_inc_vat");
        columnsSwe.push("inkl.moms");
      }
    } else {
      columns.push("sales");
      columnsSwe.push("ex.moms");
      if (!this.includeCogs) {
        columns.push("sales_inc_vat");
        columnsSwe.push("inkl.moms");
      }
    }
    if (this.includeCogs) {
      columns.push("cogs");
      columnsSwe.push("cogs");
      if (!this.isMobile) {
        columns.push("profit");
        columnsSwe.push("vinst");
        columns.push("margin");
        columnsSwe.push("marginal %");
      }
    }

    if (this.showCount && (!this.includeCogs || !this.isMobile)) {
      columns.push("item_count");
      columnsSwe.push("antal");
    }
    if (this.showAttributes) {
      columns.push("priced_atr");
      columnsSwe.push("attribut");
    }
    if (!this.isMobile && !this.includeCogs) {
      columns.push("actions");
      columnsSwe.push("");
    }

    this.columns = columns;
    this.columnsSwe = columnsSwe;
  }

  private parseQueryParams(query: ParamMap) {
    this.clearParams();
    this.resetDefaultProps();
    this.q = query.get("q");
    if (this.q) {
      this.setupInitialParams(this.q);
    } else {
      const sd = query.get("start_date");
      const ed = query.get("end_date");
      if (sd) {
        this.searchStartDate = Utils.dateKeyToMoment(sd).toDate();
      }
      if (ed) {
        this.searchEndDate = Utils.dateKeyToMoment(ed).toDate();
      }
      const pricedAtr = query.get("priced_atr");
      this.showAttributes = pricedAtr !== null;
      const cogs = query.get("cogs");
      this.includeCogs = cogs !== null;
      const d = query.get("sales_type");
      const salesType = d ?? "ex_vat";
      this.showSalesType = salesType;
      this.groupBy = query.get("group_by");
    }
    this.setupColumns();

    this.updateReportTreeOptionsFromParams();
  }

  private resetDefaultProps() {
    this.showAttributes = false;
    this.groupBy = undefined;
    this.showSalesType = "ex_vat";
  }

  private usingOldDateInQuery(query: QuerySalesDataRequest) {
    if (!query.start_date) {
      return true;
    }
    const m = moment(query.start_date);
    return m.isBefore(moment("2022-01-01"));
  }

  private serializeTree(node: QuerySalesTreeNode, data: any[], level: string) {
    const g = node.children != null ? "Grupp: " : "";
    const name = `${level}${g}${node.name}`;
    data.push({name, sales: node.sales, sales_inc_vat: node.sales_inc_vat, vat_amount: node.vat_amount, item_count: node.item_count, priced_atr: node.priced_atr});
    if (node.children != null) {
      for (const child of node.children) {
        this.serializeTree(child, data, level + "..");
      }
    }
  }

  private buildDataArrayWithHeader() {
    const data = [];
    this.serializeTree(this.nodes[0], data, "");
    const header = [
      {col: "name", title: "namn"},
      {col: "sales", title: "ex.moms"},
      {col: "sales_inc_vat", title: "inkl.moms"},
      {col: "vat_amount", title: "moms"},
      {col: "item_count", title: "antal", type: "quantity"},
    ];
    if (!this.isMobile) {
      header.push({col: "actions", title: ""});
    }
    if (this.showAttributes) {
      header.push({col: "priced_atr", title: "attribut"});
    }
    if (this.includeCogs) {
      header.push({col: "cogs", title: "COGS"});
    }
    return LocalFileUtils.compileDataIntoCSV(header, data);
  }

  public queryDailySalesForItem(item: string) {
    const queryParams: Params = {};
      if (this.searchStartDate != null) {
        queryParams.start_date = Utils.dateToDateKey(this.searchStartDate);
      }
      if (this.searchEndDate != null) {
        queryParams.end_date = Utils.dateToDateKey(this.searchEndDate);
      }
      if (item) {
        queryParams.item = item;
      }
    this.router.navigate([`/venue/${this.venueId}/reports/daily`], {queryParams});
  }

  applyFilters() {
    this.reportTreeOptions = this.tempReportTreeOptions.map(option => ({...option}));

    this.showAttributes = this.reportTreeOptions[this.RTI.SHOW_ATR]?.active;
    this.includeCogs = this.reportTreeOptions[this.RTI.SHOW_COGS]?.active;

    const a = [];
    if (this.reportTreeOptions[this.RTI.GROUP_AREA]?.active) { a.push("area"); }
    if (this.reportTreeOptions[this.RTI.GROUP_SECTION]?.active) { a.push("section"); }
    if (this.reportTreeOptions[this.RTI.GROUP_ONL]?.active) { a.push("online"); }
    if (this.reportTreeOptions[this.RTI.GROUP_SELLER]?.active) {
      if (a.length > 0) {
        this.snackBar.open("Det går inte att gruppera på säljare och area/sektion/online/tid samtidigt", "Stäng", {duration: 3000});
        this.initTempReportTreeOptions(); // Reset temp options if there's an error
        return;
      }
      a.push("seller");
    }

    if (this.reportTreeOptions[this.RTI.GROUP_TIME]?.active) {
      if (a.length > 0 && a[0] !== "area") {
        this.snackBar.open("Det går inte att gruppera per tid och sektion/online", "Stäng", {duration: 3000});
        return;
      }
      a.push("time");
    }
    if (this.reportTreeOptions[this.RTI.GROUP_COST_CENTER]?.active) {
      if (a.length > 0) {
        this.snackBar.open("Det går inte att gruppera kostnadsställe med andra grupperingera", "Stäng", {duration: 3000});
        return;
      }
      a.push("cost_center");
    }
    if (this.isVault && this.reportTreeOptions[this.RTI.GROUP_BY_VENUE]?.active) {
      if (a.length > 0) {
        this.snackBar.open("Det går inte att gruppera venue med andra grupperingar", "Stäng", {duration: 3000});
        return;
      }
      a.push("venue");
    }

    const gb = a.join(",");
    this.groupBy = gb !== "" ? gb : undefined;
    if (this.isMobile) {
      this.showSalesType = this.reportTreeOptions[this.RTI.SHOW_VAT]?.active ? "inc_vat" : "ex_vat";
    }

    this.updateParams();
    this.reportsMenuTrigger.closeMenu();
  }
}
