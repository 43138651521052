import {Component, OnDestroy, OnInit} from '@angular/core';
import {VenueService} from "../../../services/venue.service";
import {ActivatedRoute} from "@angular/router";
import {FireService} from "../../../services/fire.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {VenueConfig} from "../../../models/venue-config";
import {VenueCustomer} from "../../../models/venue-customers";
import {NodeUtils} from "../../../utils/utils";
import Utils from "../../../common/utils";
import {HotTableRegisterer} from "@handsontable/angular";
import {ValidationError} from "../../catalog/menu-models/PublishModels";
import {HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-loyalty-card-import',
  templateUrl: './loyalty-card-import.component.html',
  styleUrls: ['./loyalty-card-import.component.css']
})
export class LoyaltyCardImportComponent implements OnInit, OnDestroy{
  venueId: number;
  venueCustomers: VenueCustomer[];
  hotSettings = {};
  hotId = "venue-customers-hot-id";
  errorMessage: string;

  private hotRegisterer = new HotTableRegisterer();
  private sub: Subscription;
  private config: VenueConfig;
  private columnList = [
    {data: "program_id", title: "Lojalitetsprogram", width: 150},
    {data: "first_name", title: "Förnamn", width: 200},
    {data: "last_name", title: "Efternamn", width: 200},
    {data: "email", title: "E-post", width: 200},
    {data: "phone", title: "Tel", width: 150},
    {data: "birthday", title: "Födelsedag", width: 150},
    {data: "valid_to", title: "Giltlig till", width: 150},
    {data: "identity", title: "Kortnummer", width: 200},
  ];

  constructor(private venueService: VenueService, private route: ActivatedRoute, private fire: FireService, private snackBar: MatSnackBar, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
    });
    this.sub = this.fire.observeVenueConfig(this.venueId).subscribe(cfg => {
      this.config = cfg;
    });
    this.setupHandsontableSettings([]);
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  private setupHandsontableSettings(data: any) {
    const that: LoyaltyCardImportComponent = this;
    const settings = {
      licenseKey: environment.handson_key,
      startRows: 20,
      autoWrapCol: false,
      autoWrapRow: false,
      colHeaders: true,
      rowHeaders: true,
      manualColumnResize: true,
      columns: this.columnList,
      contextMenu: ["row_above", "row_below", "---------", "remove_row"],
      stretchH: 'all',
      width: '100%',
      height: '100%',
    };
    this.hotSettings = settings;
  }

  private prepareDataForHOT() {
    // Prepare rows (values)
    let data;
    if (this.venueCustomers?.length > 0) {
      data = [];
      for (const row of this.venueCustomers) {
        data.push(NodeUtils.deepcopyWithJSON(row));
      }
      for (let i = 0; i < 20; i++) {
        data.push({});
      }
    }
    return data;
  }

  save() {
    if (!this.hasChanges()) {
      this.snackBar.open("No changes. Nothing saved.", "", {duration: 3000});
      return;
    }
    this.errorMessage = null;
    const cards = this.collectTableData();

    for (const card of cards) {
      if (card.program_id == null) {
        this.errorMessage = `Kort saknar program (rad ${card.rowIndex}).`;
        return;
      }
      if (card.identity == null) {
        this.errorMessage = `Kort saknar kortnummer (rad ${card.rowIndex})`;
        return;
      }
    }

    console.log(cards);
    this.venueService.importLoyaltyCards(this.venueId, cards).then(res => {
      this.snackBar.open(res.result, "", {duration: 3000});
    }).catch(error => this.updateError(error));
  }

  private collectTableData() {
    const hotInstance = this.hotRegisterer.getInstance(this.hotId);
    const rows = [];
    for (let i = 0; i < hotInstance.countRows(); i++) {
      const row = {};
      for (const col of this.columnList) {
        const v = hotInstance.getDataAtRowProp(i, col.data);
        if (!Utils.isStringNullOrWhitespace(v)) {
          row[col.data] = v;
        }
      }
      if (!NodeUtils.isNullOrEmpty(row)) {
        // @ts-ignore
        row.rowIndex = i + 1;
        rows.push(row);
      }
    }

    return rows;
  }

  closeError() {
    this.errorMessage = null;
  }

  private updateError(error: Error) {
    if (error instanceof ValidationError) {
      this.errorMessage = error.message;
    } else if (error instanceof HttpErrorResponse) {
      this.errorMessage = error.error.message;
    }
  }

  public hasChanges(): boolean {
    const cards = NodeUtils.cleanList(this.collectTableData());
    return cards.length > 0;
  }

  private getPricelistSource() {
    return (query, process) => {
      process( this.config?.menu?.price_list?.map(pl => pl.name) ?? [] );
    };
  }

}
