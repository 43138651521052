<div>
  <p>
    <mat-toolbar>
      <span>Vouchers</span>
      <span class="spacer"></span>
      <button mat-button (click)="consume()">CONSUME SELECTED</button>
    </mat-toolbar>
  </p>
</div>

<div class="vouchers-container">
  <div >
    <table mat-table [dataSource]="dataSource" matSort [trackBy]="trackItem" >

      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        color="primary"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        color="primary"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>


      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;" [ngClass]="'d'+element.active">{{element.active}}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;" [ngClass]="'d'+element.active">{{element.name}}</td>
      </ng-container>

      <ng-container matColumnDef="price_cent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>price_cent</th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;" [ngClass]="'d'+element.active">{{element.price_cent}}</td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>state</th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;" [ngClass]="'d'+element.active">{{element.state}}</td>
      </ng-container>

      <ng-container matColumnDef="voucher_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>voucher_id</th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;" [ngClass]="'d'+element.active">{{element.voucher_id}}</td>
      </ng-container>

      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>created</th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;" [ngClass]="'d'+element.active">{{element.created.toDate() |date:'medium'}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></tr>
    </table>
  </div>
</div>

