import { Injectable } from '@angular/core';
import { VenueTicket } from '../models/venue-ticket';
import { AuthService } from './auth.service';
import { FireService } from './fire.service';
import {flatMap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  states = {
    OPEN: 'open',
    INFO: 'info',
    RESOLVED: 'resolved',
    ARCHIVED: 'archived'
  };

  constructor(private fire: FireService, private auth: AuthService) { }

  getStates() {
    return this.states;
  }

  getStatesList() {
    const states = [];
    Object.keys(this.states).forEach( key => {
      states.push(this.states[key]);
    });
    return states;
  }

  createTicket(ticket: VenueTicket) {
    if (!ticket.title) { return; }

    this.auth.getLoggedInAuthState().pipe(
      flatMap( state => {
        const t = {
          venueId: ticket.venueId,
          userName: state.user.first_name,
          userId: state.user.id,
          title: ticket.title,
          content: ticket.content ? ticket.content : "",
          state: ticket.state ? ticket.state : this.states.OPEN,
        } as VenueTicket;
        console.log("createTicket", t);
        return this.fire.createVenueTicket(t);
      })
      ).subscribe( state => {
        ticket.title = "";
        ticket.content = "";
      });
  }

  updateTicket(ticket: VenueTicket) {
    // TODO: Refactor duplicate code DRY!
    this.auth.getLoggedInAuthState().pipe(
      flatMap( state => {
        const t = {
          venueId: ticket.venueId,
          userId: state.user.id,
          title: ticket.title,
          content: ticket.content ? ticket.content : "",
          state: ticket.state ? ticket.state : this.states.OPEN,
          lastUpdateUserName: state.user.first_name,
          docId: ticket.docId
        } as VenueTicket;
        console.log("Update ticket", t);
        return this.fire.updateVenueTicket(t);
      })
      ).subscribe( state => {
        ticket.title = "";
        ticket.content = "";
      });
  }

  showState(state: string) {
    switch (state) {
      case this.states.OPEN:
        return {name: "Öppen", symbol: "⚠️"};
      case this.states.INFO:
        return {name: "Info", symbol: "💬"};
      case this.states.RESOLVED:
        return {name: "Stängd", symbol: "✅"};
      case this.states.ARCHIVED:
        return {name: "Arkiverad", symbol: "🗑️"};
    }
  }

}
