export const pageForwardingSettings = {
  title: { sv: "Forwarding" },
  page_settings: {
    root_anchor: "forwarding"
  },
  desc: { sv: "Inställningar för forwarding" },
  properties: [
    { key: "forwarding", title: { sv: "Forwarding" }, desc: { sv: "" }, type: "table", optional: true,
      columns: [
        { key: "disabled", title: { sv: "Inaktiverad" }, type: "bool" },
        { key: "printer", title: { sv: "Printer" }, type: "string", width: 150 },
        { key: "filter", title: { sv: "Filter" }, type: "string", width: 250 },
        { key: "exclude_items", title: { sv: "Exclude items" }, type: "bool" },
      ],
    },
  ],
};
