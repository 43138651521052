import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import Handsontable from 'handsontable';
import {HotTableRegisterer} from '@handsontable/angular';
import {ActivatedRoute} from '@angular/router';
import {combineLatest, from, Observable, Subscription} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {whenValidator, positiveIntegerValidator} from "../menu-utils/menu-validators";
import {
  categoryRowRenderer, disabledItemRowRenderer, includeRowRenderer,
  itemRowRenderer, onlyPOSItemRowRenderer, sectionRowRenderer, imageRenderer, setStorageBucketUrl
} from "../menu-utils/row-renderers";
import {MenuStateService} from "../menu-utils/menu-state.service";
import {MenuTab, MenuTabSettings, parseMenuTabSettings} from "../menu-models/MenuChange";
import {FireService} from "../../../services/fire.service";
import {AuthService} from "../../../services/auth.service";
import {AuthState} from "../../../models/signin";
import {HotDB} from "../menu-utils/HotDB";
import {MenuAttributeDialogComponent} from "../menu-attribute-dialog/menu-attribute-dialog.component";
import {MenuPushSelectDialogComponent} from "../menu-push-select-dialog/menu-push-select-dialog.component";
import {MenuIcDialogComponent} from "./menu-ic-dialog/menu-ic-dialog.component";
import {ArrayUtils} from "../../../utils/utils";
import {VenueConfig} from "../../../models/venue-config";
import ChangeSource = Handsontable.ChangeSource;
import CellChange = Handsontable.CellChange;
import CellMeta = Handsontable.CellMeta;
import {environment} from "../../../../environments/environment";
import Utils from "../../../common/utils";
import {MatSnackBar} from "@angular/material/snack-bar";
import CellValue = Handsontable.CellValue;
import {MenuPackageDialogComponent} from "../menu-package-dialog/menu-package-dialog.component";
import {flatMap, map, toArray} from "rxjs/operators";
import {Item} from "../menu-models/PublishModels";

@Component({
  selector: 'app-menu-tab',
  templateUrl: './menu-tab.component.html',
  styleUrls: ['./menu-tab.component.css']
})
export class MenuTabComponent implements OnInit, OnDestroy {
  private authState: AuthState;
  private venueId: number;
  private columnFilter: string;
  private columnIds: Set<string>;
  private hotRegisterer = new HotTableRegisterer();
  private tabSub: Subscription;
  private cellSub: Subscription;
  private filterSub: Subscription;
  private sub1: Subscription;
  private sub2: Subscription;
  private hotDB: HotDB;
  private config: VenueConfig;
  private tabSettings: MenuTabSettings;

  hotSettings: Handsontable.GridSettings = {};
  hotId = "hotId";
  tabId: string;
  hotInstance: Handsontable;
  columns = [];

  constructor(public dialog: MatDialog, private fire: FireService, private state: MenuStateService, private route: ActivatedRoute,
              private authService: AuthService, private zone: NgZone, private snackBar: MatSnackBar) {
  }

  columnsAll = [
    {data: "type", title: "Type", width: 20, type: "dropdown", source: ['', 'SEC', 'CAT', 'INC', 'OFF', 'POS', 'OPN', 'KG']},
    {data: "id", title: "Id", width: 100},
    {data: "name", title: "Name", width: 200, type: "text" },
    {data: "price", title: "Price", width: 50, type: "numeric" },
    {data: "cogs", title: "COGS", width: 50, type: "numeric" },
    {data: "desc", title: "Description", width: 300, type: "text" },
    {data: "phase", title: "Phase", width: 30, type: "dropdown", source: this.getPhaseAliases() },
    {data: "ic", title: "IC", width: 50, type: "dropdown", source: this.getICListSource(), validator: this.customIcValidator(), },
    {data: "cc", title: "Cost center", width: 50, type: "text"},
    {data: "attributes", title: "Attributes", width: 200, type: "text" },

    {data: "adjust", title: "Adjust", width: 100, type: "text" },
    {data: "push", title: "Push", width: 100, type: "text" },
    {data: "package", title: "Package", width: 100, type: "text" },
    {data: "when", title: "When", width: 100, validator: whenValidator },
    {data: "image", title: "Image", width: 150, type: "text" },

    // {data: "details", title: "details", width: 100, type: "text" },
    {data: "mp", title: "mjölkprot", width: 20, type: "text" },
    {data: "la", title: "laktos", width: 20, type: "text" },
    {data: "eg", title: "ägg", width: 20, type: "text" },
    {data: "gl", title: "gluten", width: 20, type: "text" },
    {data: "wh", title: "vete", width: 20, type: "text" },
    {data: "pe", title: "jordnöt", width: 20, type: "text" },
    {data: "cr", title: "kräftdjur", width: 20, type: "text" },
    {data: "ml", title: "blötdjur", width: 20, type: "text" },
    {data: "sf", title: "skaldjur", width: 20, type: "text" },
    {data: "fi", title: "fisk", width: 20, type: "text" },
    {data: "nu", title: "nötter", width: 20, type: "text" },
    {data: "se", title: "sesam", width: 20, type: "text" },
    {data: "ce", title: "selleri", width: 20, type: "text" },
    {data: "mu", title: "senap", width: 20, type: "text" },
    {data: "lu", title: "lupin", width: 20, type: "text" },
    {data: "so", title: "soya", width: 20, type: "text" },
    {data: "sul", title: "sulfit", width: 20, type: "text" },
    {data: "pork", title: "fläsk", width: 20, type: "text" },
    {data: "pia", title: "ananas", width: 20, type: "text" },
    {data: "stf", title: "stenfrukt", width: 20, type: "text" },
    {data: "gel", title: "gelatin", width: 20, type: "text" },
    {data: "soybean", title: "soyaböna", width: 20, type: "text" },
    {data: "ci", title: "citrus", width: 20, type: "text" },
    {data: "on", title: "onion", width: 20, type: "text" },
    {data: "ga", title: "garlic", width: 20, type: "text" },

    {data: "takeaway", title: "takeaway", width: 40, type: "checkbox"},
    {data: "pickup", title: "pickup", width: 40, type: "checkbox"},

    {data: "prop", title: "Prop", width: 40, type: "text"},

    {data: "glas", title: "Glas", width: 50, type: "numeric"},
    {data: "bong_name", title: "Bongnamn", width: 50, type: "text"},
    {data: "bong_order", title: "Bongordning", width: 50, type: "numeric", validator: positiveIntegerValidator},
    {data: "deadline", title: "Deadline", width: 50, type: "text"},
    {data: "pos_name", title: "Internnamn", width: 50, type: "text"},
    {data: "ean", title: "EAN", width: 50, type: "text"},
    {data: "resource_type", title: "Resource", width: 40, type: "dropdown", source: this.getResources()},
  ];

  columnTemplates = {
    info: {show: ["type", "name", "price", "desc"]},
    prices: {show: ["name", "price"]},
    details: {show: ["name", "phase", "attributes", "adjust", "push", "ic", "package"]},
    images: {show: ["name", "image"]},
    allergenes: {show: ["name", "mp", "la", "eg", "gl", "wh", "pe", "cr", "ml", "sf", "fi", "nu", "se", "ce", "mu", "lu", "so", "sul", "pork", "pia", "stf", "gel", "soybean", "ci", "on", "ga"]},
    filters: {show: ["name", "takeaway", "pickup", "when"]},
    all: {show: ["type", "id", "name", "price", "desc", "adjust", "attributes", "push", "when", "ic", "phase", "prop"]},
  };

  // This must contain any column that should be shown, it decides the order of the columns presented to the user
  masterColumnOrder = [
    "type", "id", "name", "price", "glas", "cogs", "desc", "pos_name", "bong_name", "bong_order", "phase", "ic", "cc", "ean", "resource_type", "adjust", "attributes", "push", "package", "when", "deadline", "image",
    "takeaway", "pickup", "prop",
    "mp", "la", "eg", "gl", "wh", "pe", "cr", "ml", "sf", "fi", "nu", "se", "ce", "mu", "lu", "so", "sul", "pork", "pia", "stf", "gel", "soybean", "ci", "on", "ga"
  ];

  dialogRef = null;

  ngOnInit(): void {
    console.log("ngOnInit...");

    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.tabId = data.get("tab_id");
      console.log(`this.venueId = ${this.venueId}, this.tabId = ${this.tabId}`);

      this.hotDB = new HotDB(this.venueId, this.tabId, this.state.menuId, true);
      this.authState = null;

      this.sub1?.unsubscribe();
      this.sub1 = this.authService.currentAuthState().subscribe(auth => {
        const firstTime = this.authState == null;
        this.authState = auth;
        if (firstTime) {
          this.setupHandsontableSettings();
          this.beginObserving();
        }
      });

      this.sub2?.unsubscribe();
      this.sub2 = this.state.toggleColumnSubject.subscribe(colId => {
        if (colId == null || this.columnIds == null) { return; }
        console.log("Toggle column", colId);
        if (this.columnIds.has(colId)) {
          this.columnIds.delete(colId);
        } else {
          this.columnIds.add(colId);
        }
        console.log("columnIds", this.columnIds);
        this.refreshColumns();
      });
    });
  }

  setupHandsontableSettings() {
    const that: MenuTabComponent = this;
    this.hotSettings = {
      licenseKey: environment.handson_key,
      startRows: 10,
      autoWrapCol: false,
      autoWrapRow: false,
      colHeaders: true,
      rowHeaders: true,
      stretchH: 'all',
      width: '100%',
      height: '100%',
      manualColumnResize: true,
      columns: this.columns,
      trimDropdown: false,
      contextMenu: {
        items: {
          insert_row_above: {
            name() {
              return '<b>Infoga rad(er) ovanför ↑↑</b>';
            },
            callback(key, selection, clickEvent) {
              const row = selection[0].start.row;
              const hot: Handsontable = this;
              that.insertRow(row, this, true, () => {});
            }
          },
          insert_row_below: {
            name() {
              return '<b>Infoga rad(er) nedanför ↓↓</b>';
            },
            callback(key, selection, clickEvent) {
              const row = selection[0].end.row + 1;
              const hot: Handsontable = this;
              that.insertRow(row, this, false, () => {});
            }
          },
          separator1: Handsontable.plugins.ContextMenu.SEPARATOR,
          copy_product: {
            name() {
              return '<b>Kopiera rad(er)</b>';
            },
            callback(key, selection, clickEvent) {
              that.copyProducts(selection[0].start.row, selection[0].end.row, this);
            },
            hidden: () => false
          },
          paste_product: {
            name() {
              return '<b>Klistra in rad(er)</b>';
            },
            callback(key, selection, clickEvent) {
              that.pasteProducts(selection[0].start.row, selection[0].end.row, this);
            },
            hidden: () => !this.hotDB.hasDataInClipboard()
          },
          separator2: Handsontable.plugins.ContextMenu.SEPARATOR,
          new_section: {
            name() {
              return '<b style="background-color:#ffe599">Lägg till ny sektion</b>';
            },
            callback(key, selection, clickEvent) {
              const row = selection[0].end.row;
              that.insertTypeRow("SEC", row, this);
            }
          },
          new_cat: {
            name() {
              return '<b style="background-color:#b7e1cd">Lägg till ny kategori</b>';
            },
            callback(key, selection, clickEvent) {
              const row = selection[0].end.row;
              that.insertTypeRow("CAT", row, this);
            }
          },
          separator3: Handsontable.plugins.ContextMenu.SEPARATOR,
          clear_row: {
            name() {
              return 'Rensa rad(er)';
            },
            callback(key, selection, clickEvent) {
              const row = selection[0].start.row;
              that.clearRow(row, this);
            }
          },
          delete_row: {
            name() {
              return 'Ta bort rad(er)';
            },
            callback(key, selection, clickEvent) {
              const row = selection[0].start.row;
              that.deleteRow(row, this);
            }
          },

          // row_above: {},
          // row_below: {},
          // separator2: Handsontable.plugins.ContextMenu.SEPARATOR,
          // remove_row: {},
          // clear_custom: {
          //   name: 'Clear all cells (custom)',
          //   callback() {
          //     this.clear();
          //   }
          // }
        }
      },
      cells(row, col) {
        const cellProperties: CellMeta = {};
        if (that.columnFilter === "raw") {
          return cellProperties;
        }
        const v = that.hotDB.readFromDBAndCache(row, "type");
        const k = that.columns[col];
        cellProperties.cellType = k.type;
        cellProperties.rowType = v;
        if (k.data === "image") {
          cellProperties.renderer = imageRenderer;
        } else if ( v === "CAT") {
          cellProperties.renderer = categoryRowRenderer;
        } else if (v === "SEC") {
          cellProperties.renderer = sectionRowRenderer;
        } else if (v === "INC") {
          cellProperties.renderer = includeRowRenderer;
        } else if (v === "OFF") {
          cellProperties.renderer = disabledItemRowRenderer;
        } else if (v === "POS" || v === "OPN" || v === "KG") {
          cellProperties.renderer = onlyPOSItemRowRenderer;
        } else {
          cellProperties.renderer = itemRowRenderer;
        }

        return cellProperties;
      }
    };
  }

  private releaseSubs() {
    this.tabSub?.unsubscribe();
    this.cellSub?.unsubscribe();
    this.filterSub?.unsubscribe();
    this.sub1?.unsubscribe();
    this.sub2?.unsubscribe();
  }

  ngOnDestroy(): void {
    this.releaseSubs();
  }

  private beginObserving() {
    // release subs, "beginObserving" is called after each route update (change of menu tab)
    this.tabSub?.unsubscribe();
    this.cellSub?.unsubscribe();

    this.checkThatTabExists(this.venueId, this.state.menuId, this.tabId);

    this.tabSub = this.fire.observeTab(this.venueId, this.state.menuId, this.tabId).subscribe(tab => {
      if (!tab) { console.log("NO tab data...."); return;
      } else if (tab.updated == null) { console.log("Local change skip..."); return; }
      console.log("Observed tab:", tab);
      this.updateTab(tab);

      if (this.columnFilter) {
        this.changeFilterLogic(this.columnFilter);
      }

      if (!this.hotInstance) {
        // TODO find better place to register the table, cant do in onNgInit, since table is not initialized yet
        const hotInstance = this.hotRegisterer.getInstance(this.hotId);
        this.hotInstance = hotInstance;
        const that = this;
        Handsontable.hooks.add('afterChange', (changes: CellChange[] | null, source: ChangeSource) => {
            that.afterChange(changes, source);
          }, hotInstance);
        Handsontable.hooks.add('beforeChange', (changes: CellChange[] | null, source: ChangeSource) => {
          that.beforeChange(changes, source);
          }, hotInstance);
        Handsontable.hooks.add('afterBeginEditing', (row: number, column: number) => {
            that.afterBeginEditing(row, column);
          }, hotInstance);
        // eslint-disable-next-line max-len
        Handsontable.hooks.add('afterSelection', (row: number, column: number, row2: number, column2: number, preventScrolling: { value: boolean }, selectionLayerLevel: number) => { that.afterSelection(row, column, row2, column2); }, hotInstance);
        Handsontable.hooks.add('afterCreateRow', (index: number, amount: number, source?: ChangeSource) => {
            that.afterCreateRow(index, amount, source);
          }, hotInstance);
        Handsontable.hooks.add('beforeCopy', (data: CellValue[][], coords: { startRow: number; startCol: number; endRow: number; endCol: number }[]) => {
            // @ts-ignore
            if (this.columnFilter === "raw") {
              // TODO clear?
            } else if (this.hotDB.hasSelectedAllVisibleColumns(that.columns, coords)) {
              // @ts-ignore
              that.copyProducts(coords[0].startRow, coords[0].endRow, this);
              return false;
            }
        }, hotInstance);
        Handsontable.hooks.add('beforePaste', (data: CellValue[][], coords: { startRow: number; startCol: number; endRow: number; endCol: number }[]) => {
          // @ts-ignore
          if (this.columnFilter === "raw") {
            // TODO clear?
          } else if (this.hotDB.hasDataInClipboard() && this.hotDB.hasSelectedAllVisibleColumns(that.columns, coords)) {
            // @ts-ignore
            that.pasteProducts(coords[0].startRow, coords[0].endRow, this);
            return false;
          }
        }, hotInstance);
        Handsontable.hooks.add('beforeKeyDown', (event: KeyboardEvent) => {
          const isDeleteButton = event.key === "Delete" || event.key === "Backspace";
          if (isDeleteButton && that.hotDB.hasSelectedAllVisibleColumns(that.columns)) {
            that.clearRow(that.hotDB.currentSelection.row, that.hotInstance);
            event.stopImmediatePropagation();
          }
        }, hotInstance);

        this.filterSub = combineLatest([
            this.fire.observeVenueConfig(this.venueId),
            this.state.currentFilterSubject,
          ]
        ).subscribe(res => {
            this.config = res[0];
            const filter = res[1];
            if (this.columnFilter !== filter) {
              this.changeFilterLogic(filter);
            }
            setStorageBucketUrl(this.config.bucket);
          }
        );
      }
    });

    this.cellSub = this.fire.observeCells(this.venueId, this.state.menuId, this.tabId).subscribe(cellChanges => {
      const hotInstance = this.hotRegisterer.getInstance(this.hotId);
      this.hotDB.cellChangesObserved(cellChanges, this.hotInstance, this.columns);
    });
  }

  private addCols(set, cols: string[]) {
    for (const col of cols) {
      set.add(col);
    }
  }

  // Get a list of all columns ids for the current filter and settings
  private getColumnIdsForFilter(filter: string): Set<string> {
    const columnIds: Set<string> = new Set();
    if (filter === "info" || filter === "raw") {
      this.addCols(columnIds, this.columnTemplates.info.show);
      if (this.tabSettings?.show_glas_col) {
        columnIds.add("glas");
      }
      if (this.tabSettings?.show_pos_name_col) {
        columnIds.add("pos_name");
      }
      if (this.tabSettings?.show_bong_name_col) {
        columnIds.add("bong_name");
      }
      if (this.tabSettings?.show_bong_order_col) {
        columnIds.add("bong_order");
      }
    }
    if (filter === "prices" || filter === "raw") {
      this.addCols(columnIds, this.columnTemplates.prices.show);
      if (this.tabSettings?.show_glas_col) {
        columnIds.add("glas");
      }
      if (this.tabSettings?.show_cogs_col) {
        columnIds.add("cogs");
      }
      for (const pl of this.config?.menu?.price_list ?? []) {
        columnIds.add(pl.name);
      }
    }
    if ( filter === "filters" || filter === "raw" ) {
      this.addCols(columnIds, this.columnTemplates.filters.show);
      if (this.tabSettings?.show_deadline_col) {
        columnIds.add("deadline");
      }
      for (const et of this.config?.menu?.editor_tags ?? []) {
        columnIds.add(et.tag);
      }
    }
    if (filter === "details" || filter === "raw") {
      this.addCols(columnIds, this.columnTemplates.details.show);
      if (this.tabSettings?.show_ean_col) {
        columnIds.add("ean");
      }
      if (this.tabSettings?.show_resource_col) {
        columnIds.add("resource_type");
      }
      if (this.tabSettings?.show_cc_col) {
        columnIds.add("cc");
      }
    }
    if (filter === "allergenes" || filter === "raw") {
      this.addCols(columnIds, this.columnTemplates.allergenes.show);
    }
    if (filter === "images" || filter === "raw") {
      this.addCols(columnIds, this.columnTemplates.images.show);
    }
    if (filter === "audit" || filter === "raw") {
      this.addCols(columnIds, this.columnTemplates.all.show);
    }

    return columnIds;
  }

  private refreshColumns() {
    const columns = [];
    const columnSideBarData = [];
    const usedColumnsIds = this.getColumnIdsForFilter("raw");
    for (const mc of this.masterColumnOrder) {
      const selected = this.columnIds.has(mc);
      const ct = this.columnsAll.find(c => c.data === mc);
      if (selected) {
        columns.push(ct);
      }
      const isUsed = usedColumnsIds.has(mc);
      if (isUsed) {
        console.log("Adding to sidebar: ", ct);
        columnSideBarData.push({title: ct.title, data: ct.data, selected});
      }
    }

    this.columns = columns;
    console.log("refreshColumns:", this.columns);
    this.hotDB.showHideColumns(this.columns, this.hotSettings, this.hotInstance);
    this.state.setColumns(columnSideBarData);
  }

  private pushColumn(columns: any[], colId: any, rightOf: string) {
    const f = columns.find(c => c === colId);
    if (!f) {
      if (rightOf) {
        const roi = columns.findIndex(c => c === rightOf);
        columns.splice(roi >= 0 ? roi + 1 : columns.length, 0, colId);
      } else {
        columns.push(colId);
      }
    }
  }

  private setupMasterColumnOrder(filter: string) {
    //Custom menu tags
    for (const et of this.config?.menu?.editor_tags?.reverse() ?? []) {
      const f = this.masterColumnOrder.find(c => c === et.tag);
      if (!f) {
        this.pushColumn(this.masterColumnOrder, et.tag, "pickup");
        this.columnsAll.push({data: et.tag, title: et.tag, width: 40, type: "checkbox"});
      }
    }

    //Custom price lists
    for (const pl of this.config?.menu?.price_list?.reverse() ?? []) {
      const f = this.masterColumnOrder.find(c => c === pl.name);
      if (!f) {
        this.pushColumn(this.masterColumnOrder, pl.name, "price");
        this.columnsAll.push({data: pl.name, title: pl.name, width: 50, type: "numeric"});
      }
    }

  }

  private changeFilterLogic(filter: string) {
    console.log("filter:", filter);
    this.setupMasterColumnOrder(filter);
    this.columnFilter = filter;
    this.columnIds = this.getColumnIdsForFilter(filter);
    this.refreshColumns();
  }

  private getGlasColData() {
    return {data: "glas", title: "Glas", width: 50, type: "numeric"};
  }
  private getDeadlineColData() {
    return {data: "deadline", title: "Deadline", width: 50, type: "text"};
  }

  private getBongColData() {
    return {data: "bong_name", title: "Bongnamn", width: 50, type: "text"};
  }

  private getPosNameColData() {
    return {data: "pos_name", title: "Internnamn", width: 50, type: "text"};
  }

  private getEANColData() {
    return {data: "ean", title: "EAN", width: 50, type: "text"};
  }

  private getResourceColData() {
    return {data: "resource_type", title: "Resource", width: 40, type: "dropdown", source: this.getResources()};
  }

  private getBongOrderColData() {
    return {data: "bong_order", title: "Bongordning", width: 50, type: "numeric", validator: positiveIntegerValidator};
  }

  private afterBeginEditing(rowIndex: number, columnIndex: number) {
    const col = this.columns[columnIndex];
    if (col.data === 'attributes') {
      const hotInstance = this.hotRegisterer.getInstance(this.hotId);
      hotInstance.selectCell(rowIndex, columnIndex);
      setTimeout( () => this.openAttributeDialog(hotInstance, rowIndex, columnIndex), 100 );
    } else if (col.data === 'push') {
      const hotInstance = this.hotRegisterer.getInstance(this.hotId);
      hotInstance.selectCell(rowIndex, columnIndex);
      setTimeout( () => this.openSelectPushDialog(hotInstance, rowIndex, columnIndex), 100 );
    } else if (col.data === 'package') {
      const hotInstance = this.hotRegisterer.getInstance(this.hotId);
      hotInstance.selectCell(rowIndex, columnIndex);
      setTimeout( () => this.openPackageDialog(hotInstance, rowIndex, columnIndex), 100 );
    }
    // else if (col.data === 'ic') {
    //   const hotInstance =  this.hotRegisterer.getInstance(this.hotId);
    //   hotInstance.selectCell(rowIndex, columnIndex);
    //   setTimeout( () => this.openICSelectDialog(hotInstance, rowIndex, columnIndex), 100 );
    // }
  }

  private beforeKeyDown(event: KeyboardEvent) {
    console.log("beforeKeyDown");
    console.log(event);
  }

  private beforeChange(changes: CellChange[] | null, source: ChangeSource) {
    this.hotDB.beforeChange(changes, source);
  }

  private afterChange(changes: CellChange[] | null, source: ChangeSource) {
    const mcs = this.hotDB.afterChange(changes, source, this.authState);
    if ( mcs?.length > 0 ) {
      this.fire.updateMenuCells(mcs);
    }

    // remind stuff
    if (this.columnFilter !== "raw") {
      const remind = this.hotDB.hasDeletedAllVisibleValues(changes, source, this.columns);
      if (remind) {
        this.zone.run(() => {
          this.snackBar.open(`Du rensade bort information för en eller fler produkter. Om tanken var att ta bort hela produkten markera hela raden och tryck på "Rensa rad" eller "Ta bort rad"`, "stäng", {duration: 20000});
        });
      }
    }
  }

  private afterSelection(row: number, column: number, row2: number, column2: number) {
    this.hotDB.currentSelection = {row, row2, column, column2};
  }

  private afterCreateRow(index: number, amount: number, source?: ChangeSource) {
    console.log("afterCreateRow: ", {index, amount, source});
    if (source === "CopyPaste.paste") {
      this.hotDB.insertRowsCount(index, amount);
      this.fire.updateMenuTab(this.hotDB.currentTab).then( () => {});
    }
  }

  private updateTab(tab: MenuTab) {
    console.log("Setting/updataing current tab: ", tab);
    this.hotDB.currentTab = tab;
    this.tabSettings = parseMenuTabSettings(tab.settingsJson);
    const hotInstance = this.hotRegisterer.getInstance(this.hotId);
    this.hotDB.rebuildAndLoad(hotInstance, this.columns);
  }

  private insertRow(rowIndex: number, hot: Handsontable, above: boolean, callback: (rowIndex: number) => void) {
    if (!this.hotDB.currentTab) { return; }
    this.hotDB.insertRowsBySelection(rowIndex, hot);
    this.fire.updateMenuTab(this.hotDB.currentTab).then( () => {
      //hot.alter('insert_row', rowIndex, count);
      callback(rowIndex);
    });
  }

  private insertTypeRow(type: string, rowIndex: number, hot: Handsontable) {
    this.insertRow(rowIndex, hot, true, (ri: number) => {
      if ((this.columns?.length > 0) && this.columns[0].data === "type") {
        hot.setDataAtRowProp(rowIndex, "type", type);
        hot.selectCell(rowIndex, 1);
      } else {
        this.hotDB.updateDB(this.hotDB.indexToRow(rowIndex), "type", type);
        hot.selectCell(rowIndex, 0);
      }
    });
  }

  private deleteRow(rowIndex: number, hot: Handsontable) {
    if (!this.hotDB.currentTab) { return; }
    const menuCells = this.hotDB.clearRows(rowIndex, hot, this.authState);
    this.fire.updateMenuCells(menuCells).then( r => {
      console.log("deleted menu cells: ", menuCells.length);
      this.hotDB.deleteRows(rowIndex, hot);
      this.fire.updateMenuTab(this.hotDB.currentTab).then( () => {
        console.log("Rows deleted in FS");
      });
    });
  }

  private clearRow(rowIndex: number, hot: Handsontable) {
    if (!this.hotDB.currentTab) { return; }
    const menuCells = this.hotDB.clearRows(rowIndex, hot, this.authState);
    console.log(menuCells);
    this.fire.updateMenuCells(menuCells).then( r => {
      console.log("updated menu cells: ", menuCells.length);
      this.zone.run(() => {
        this.snackBar.open(`Rensade ${this.hotDB.currentSelection.row2 - this.hotDB.currentSelection.row + 1} rad(er)`, "", {duration: 3000});
      });
    });
  }

  private copyProducts(startRow: number, endRow: number, hot: Handsontable) {
    if (!this.hotDB.currentTab) { return; }
    const copiedRows = this.hotDB.copyRows(startRow, endRow);
    this.zone.run(() => {
      this.snackBar.open(`Kopierade ${copiedRows.length} rad(er)`, "", {duration: 3000});
    });
  }

  private pasteProducts(startRow: number, endRow: number, hot: Handsontable) {
    if (!this.hotDB.currentTab) { return; }
    const menuCells = this.hotDB.getMenuChangesForCopiedRows(startRow, this.authState);
    this.fire.updateMenuCells(menuCells).then( r => {
      console.log("updated menu cells: ", menuCells.length);
      this.zone.run(() => {
        this.snackBar.open(`Klistrade in ${this.hotDB.getDataInClipboard().length} rad(er)`, "", {duration: 3000});
      });
    });
  }

  private checkThatTabExists(venueId: number, menuId: string, tabId: string) {
    this.state.createTab(venueId, menuId, tabId, tabId, "{}").subscribe( d => {});
  }

  openAttributeDialog(hotInstance, rowIndex, columnIndex) {
    if (this.dialogRef == null) {
      this.zone.run(() => {
        const row = this.hotDB.indexToRow(rowIndex);
        const name = this.hotDB.readFromDB(row, "name");
        const atr = this.hotDB.readFromDB(row, "attributes");
        this.dialogRef = this.dialog.open(MenuAttributeDialogComponent, {
          minWidth: "800px",
          minHeight: "650px",
          //position: {top: "8px"},
          data: { attributes: atr, name, venueId: this.venueId}
        });
        this.dialogRef.afterClosed().subscribe(result => {
          console.log(result);
          if ( result ) {
            hotInstance.setDataAtCell(rowIndex, columnIndex, result);
          }
          this.dialogRef = null;
        });
      });
    }
  }

  openSelectPushDialog(hotInstance, rowIndex, columnIndex) {
    if (this.dialogRef == null) {
      this.zone.run(() => {
        const row = this.hotDB.indexToRow(rowIndex);
        const name = this.hotDB.readFromDB(row, "name");
        const push = this.hotDB.readFromDB(row, "push");
        this.dialogRef = this.dialog.open(MenuPushSelectDialogComponent, {
          minWidth: "400px",
          minHeight: "650px",
          data: { push, name, venueId: this.venueId }
        });
        this.dialogRef.afterClosed().subscribe(result => {
          console.log(result);
          if ( result ) {
            hotInstance.setDataAtCell(rowIndex, columnIndex, result);
          }
          this.dialogRef = null;
        });
      });
    }
  }

  openICSelectDialog(hotInstance, rowIndex, columnIndex) {
    if (this.dialogRef == null) {
      this.zone.run(() => {
        const row = this.hotDB.indexToRow(rowIndex);
        const name = this.hotDB.readFromDB(row, "name");
        const ic = this.hotDB.readFromDB(row, "ic");
        this.dialogRef = this.dialog.open(MenuIcDialogComponent, {
          minWidth: "400px",
          minHeight: "650px",
          data: { ic, name, venueId: this.venueId }
        });
        this.dialogRef.afterClosed().subscribe(result => {
          console.log(result);
          if ( result ) {
            hotInstance.setDataAtCell(rowIndex, columnIndex, result);
          }
          this.dialogRef = null;
        });
      });
    }
  }

  openPackageDialog(hotInstance, rowIndex, columnIndex) {
    if (this.dialogRef == null) {
      this.collectAllItems().subscribe(itemsList => {
        console.log("itemsList", itemsList.length);
        this.zone.run(() => {
          const row = this.hotDB.indexToRow(rowIndex);
          const name = this.hotDB.readFromDB(row, "name");
          const packdata = this.hotDB.readFromDB(row, "package");
          this.dialogRef = this.dialog.open(MenuPackageDialogComponent, {
            minWidth: "800px",
            minHeight: "650px",
            data: { packdata, name, venueId: this.venueId, itemsList }
          });
          this.dialogRef.afterClosed().subscribe(result => {
            console.log(result);
            if ( result ) {
              hotInstance.setDataAtCell(rowIndex, columnIndex, result);
            }
            this.dialogRef = null;
          });
        });
      });
    }
  }

  private getICListSource() {
    return (query, process) => {
      const icList = ArrayUtils.flattenSortIndentICTree(this.config.ic, []);
      process( icList.filter( ic => ic.trim() ) );
    };
  }

  private customIcValidator() {
    return (value, callback) => {
      console.log("IC validator", this.getICListSource());
      if (value === null || value === undefined || value === '') {
        callback(true);
      } else {
        callback(ArrayUtils.flattenICTree(this.config.ic, []).includes(value));
      }
    };
  }

  private getPhaseAliases() {
    return (query, process) => {
      const phases = this.config.serving?.phases;
      let res;
      if (phases) {
        res = phases.map( p => p.alias );
      } else {
        res = ['', 'FR', 'HR', 'DS'];
      }
      process(res);
    };
  }

  private getResources() {
    return (query, process) => {
      const res = this.config.resources.types.map( r => r.name );
      process(res);
    };
  }

  private collectAllItems(): Observable<Item[]> {
    return this.fire.getTabs(this.venueId, this.state.menuId).pipe(
      flatMap(tabs => from(tabs.filter( t => !["options", "pushpages"].includes(t.id))) ),
      flatMap( tab => this.state.getItems(this.venueId, this.state.menuId, tab) ),
      toArray(),
      map(k => {
        const itemsList: Item[] = [];
        for (const t of k) {
          for (const i of t) {
            if (i.name && i.id) {
              itemsList.push(i);
            }
          }
        }
        itemsList.sort((a, b) => a.id.localeCompare(b.id));
        return itemsList;
      })
    );
  }

}
