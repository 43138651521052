import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { HackUtils } from 'src/app/utils/utils';
import { EditRoleDialogComponent } from '../../user-list/edit-role-dialog/edit-role-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { AccountUser } from 'src/app/models/user';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { VenueService } from 'src/app/services/venue.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditAdminUserDialogComponent } from '../../user-list/edit-admin-user-dialog/edit-admin-user-dialog.component';
import { Subscription, combineLatest } from 'rxjs';
import { Location } from '@angular/common';
import { VenuePickerDialogComponent } from '../../dialogs/venue-picker-dialog/venue-picker-dialog.component';
import { VenueMapService } from 'src/app/services/venue-map.service';

@Component({
  selector: 'app-admin-accounts-list',
  templateUrl: './admin-accounts-list.component.html',
  styleUrls: ['./admin-accounts-list.component.css']
})
export class AdminAccountsListComponent implements OnInit, OnDestroy {
  private paramSub: Subscription;

  constructor(public dialog: MatDialog, private route: ActivatedRoute, private venueService: VenueService,
              private snackBar: MatSnackBar, private router: Router, private location: Location,
              private venueMapService: VenueMapService) { }
  displayedColumns = ["created", "email", "first_name", "last_name"];
  admin: any;
  dataSource: MatTableDataSource<AccountUser>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  filteredDataSource: MatTableDataSource<AccountUser>;
  title: string;
  selectedRowKey: string;
  searchString ='';
  searchVenue: number;
  searchVenueName = '';
  showInactive = false;

  ngOnInit(): void {
    this.sort.sort(({ id: 'created', start: 'desc'}) as MatSortable);
    this.fetchAndUpdateUsers();
  }

  private fetchAndUpdateUsers(doSelect: boolean = true) {
    this.paramSub = combineLatest([
      this.route.queryParamMap,
      this.fetchUsers()
    ]).subscribe(([param, adminUsers]) => {
      console.log("Admin users", adminUsers);
      this.dataSource = new MatTableDataSource(adminUsers as AccountUser[]);
      this.filteredDataSource = new MatTableDataSource(adminUsers as AccountUser[]);
      this.filteredDataSource.sort = this.sort;
      this.title = "Venue admins";
      const ai = param.get("admin_id");
      if (ai || this.admin) {
        const id = ai ?? this.admin.id;
        this.admin = adminUsers.find(a => a.id == id);
        if (doSelect) {
          this.select(this.admin);
        }
      }
      this.applyFilter();
    });
  }

  public onRefreshUsers(): void {
    this.fetchAndUpdateUsers(false);
  }

  public fetchUsers() {
    return this.venueService.fetchAllAdminUsers();
  }

  ngOnDestroy(): void {
    if (this.paramSub) {
      this.paramSub.unsubscribe();
    }
  }

  public select(row: any) {
    this.selectedRowKey = this.selectedRowKey === row.id ? null : row.id;
    console.log("Selected row", row);
    this.admin = row;
    this.updateUrl(this.selectedRowKey);
  }

  private updateUrl(selectedRowKey: string): void {
    const url = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: { admin_id: selectedRowKey },
      queryParamsHandling: "merge"
    }).toString();
    this.location.go(url);
  }

  applyFilter() {
    const filterValue = this.searchString.toLowerCase();
    const venueId = this.searchVenue;
    this.filteredDataSource.data = this.dataSource.data.filter(element => {
      const matchesSearchString =
        element.first_name.toLowerCase().includes(filterValue) ||
        element.last_name.toLowerCase().includes(filterValue) ||
        element.email.toLowerCase().includes(filterValue) ||
        element.id.toString().includes(filterValue);

      const matchesVenueId = venueId == null ||
        element.roles_cache.some(role => role.venue_id === venueId);
      const matchesInactive = this.showInactive || element.roles_cache?.length > 0;

      return matchesSearchString && matchesVenueId && matchesInactive;
    });
  }

  openVenuePickerDialog() {
    const dialogRef = this.dialog.open(VenuePickerDialogComponent, HackUtils.DLG({
      data: {
        title: "Välj Venue",
        cancelButton: "Avbryt",
        positiveButton: "Välj",
      }
    }));
    dialogRef.afterClosed().subscribe(venueId => {
      this.venueMapService.getVenueConfigs().subscribe(venueConfigs => {
        if (venueId) {
          this.searchVenue = venueId;
          this.searchVenueName = venueConfigs.filter(v => v.venueId === venueId)[0].venue.name;
          this.applyFilter();
        }
      });
    });
  }

  clearSearch() {
    this.searchString = '';
    this.applyFilter();
  }

  clearVenueSearch() {
    this.searchVenue = null;
    this.searchVenueName = '';
    this.applyFilter();
  }

  private openEditRoleDialog(email: string, venueId: number) {
    const dialogRef = this.dialog.open(EditRoleDialogComponent, HackUtils.DLG({
      data: { email, venueId }
    }));
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
    });
  }

  public editRoles(row: any) {
    this.openEditRoleDialog(row.email, row.venue_id);
  }

  public editAdminUser(row: any) {
    const dialogRef = this.dialog.open(EditAdminUserDialogComponent, HackUtils.DLG({
      data: { email: row.email, venueId: row.venue_id, firstName: row.first_name, lastName: row.last_name}
    }));
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
      this.fetchUsers();
    });
  }

  public resendInvite(row: any) {
    this.venueService.resendInvite(row.email, row.venue_id).then(r => {
      this.snackBar.open("Invite mail sent", "", {duration: 3000});
    });
  }

  public closeSidePanel() {
    this.selectedRowKey = null;
    this.router.navigate([], { relativeTo: this.route, queryParams: { admin_id: null }});
  }

  public toggleInactive() {
    this.fetchAndUpdateUsers();
  }

}
