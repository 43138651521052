<div class="admin-accounts-container">
  <div>
    <p>
      <mat-toolbar>
        <span>{{title}}</span>
        <span class="spacer"></span>
        <app-wiki-info-link title="Lär dig mer" section="support" path="admins"></app-wiki-info-link>
      </mat-toolbar>
    </p>
  </div>
  <div class="search-fields">
    <mat-form-field appearance="outline">
      <mat-label>Filter</mat-label>
      <input matInput [(ngModel)]="searchString" (ngModelChange)="applyFilter()">
      <button matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
        <mat-icon>clear</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Venue</mat-label>
      <input matInput [value]="searchVenueName" [disabled]="true">
      <button matSuffix mat-icon-button aria-label="Venue" (click)="openVenuePickerDialog()">
        <mat-icon>search</mat-icon>
      </button>
      <button matSuffix mat-icon-button aria-label="Clear" (click)="clearVenueSearch()">
        <mat-icon>clear</mat-icon>
      </button>
    </mat-form-field>
    <div>
      <mat-slide-toggle [(ngModel)]="showInactive" color="primary" (change)="toggleInactive()" matTooltip="Visar admins utan koppling till venue" style="margin-left: 16px;">Inaktiva</mat-slide-toggle>
    </div>
  </div>
  <div class="generic-mastdet">
    <div class="generic-master">
      <table class="clicktable table-cells" mat-table [dataSource]="filteredDataSource" matSort matSortDisableClear="true">

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <ng-container matColumnDef="first_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Namn </th>
          <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
        </ng-container>

        <ng-container matColumnDef="last_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Efternamn </th>
          <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Skapad </th>
          <td mat-cell *matCellDef="let element" style="white-space:nowrap;"> {{element.created | utcDate: 'yyyy-MM-dd'}} </td>
        </ng-container>

        <ng-container matColumnDef="updated">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Updaterad </th>
          <td mat-cell *matCellDef="let element" style="white-space: nowrap;"> {{element.updated | utcDate: 'yyyy-MM-dd'}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let element">
            <button mat-button [matMenuTriggerFor]="menuReports" (click)="$event.stopPropagation()">Edit <mat-icon>arrow_drop_down</mat-icon></button>
            <mat-menu #menuReports="matMenu">
              <button mat-menu-item (click)="editAdminUser(element)">Redigera profil</button>
              <button mat-menu-item (click)="editRoles(element)">Redigera roll</button>
              <mat-divider></mat-divider>
              <button mat-menu-item (click)="resendInvite(element)">Skicka inbjudan igen</button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"
          [class.selected-row]="selectedRowKey === row.id"></tr>
      </table>
    </div>
    <div *ngIf="selectedRowKey" class="generic-detail">
      <div class="lr-detail-container">
        <div class="toolbar" style="margin-bottom: 8px;">
          <span><mat-icon>person</mat-icon></span>
          <span style="font-weight: bold;">{{admin.first_name ?? "Admin"}}</span>
          <button mat-button (click)="closeSidePanel()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <app-admin-account-details [adminId]="selectedRowKey" [admin]="admin" (refreshUsers)="onRefreshUsers()"></app-admin-account-details>
      </div>
    </div>
  </div>
</div>
