<mat-sidenav-container [style.height]="'calc( 100% - 56px )'" [style.margin-top]="'56px'" class="mat-typography outer-container">

  <mat-sidenav [mode]="navMode" [(opened)]="opened">
    <app-menu-sidebar></app-menu-sidebar>
  </mat-sidenav>

  <mat-sidenav-content class="content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>

</mat-sidenav-container>

<app-menu-nav [isMobile]="false" (tog)="opened = !opened"></app-menu-nav>
