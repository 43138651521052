import { Pipe, PipeTransform } from '@angular/core';
import {HackUtils} from "../utils/utils";

@Pipe({
  name: 'moneySuff'
})
export class MoneySuffPipe implements PipeTransform {

  transform(input: any, args?: any, currency = "SEK", smallFracDigs= 0): any {
    const suffixes = ['k', 'M', 'G', 'T', 'P', 'E'];
    if (input == null || Number.isNaN(input)) { return null; }
    const decimals = args === "cent" ? 1 : args;
    const money = ["cent", "fullcent"].includes(args) ? input / 100 : input;
    let val;
    if (args === "fullcent") {
      val = money.toFixed(2);
      val = HackUtils.thousandsSepNumber(val).replace(".", ",");
      return `${val} ${currency}`;
    }
    let suf;
    // if (money > 1000000) {
    //   const exp = Math.floor(Math.log(money) / Math.log(1000));
    //   if (exp > 6) { return money; }
    //   val = (money / Math.pow(1000, exp)).toFixed(decimals).replace(".", ",");
    //   suf = suffixes[exp - 1];
    // } else {
      val = HackUtils.thousandsSepNumber(money.toFixed(smallFracDigs)).replace(".", ",");
      suf = "";
    // }
    return `${val} ${suf}${currency}`;
  }

}
