<h2 mat-dialog-title>{{title}}</h2>
<div mat-dialog-content style="position: relative;">
  <div [formGroup]="address">
    <mat-form-field class="indialog" appearance="outline">
      <mat-label>Email/ telefonnummer</mat-label>
      <input matInput formControlName="validAddress" pattern required>
      <mat-hint>Ange email eller telefonnummer dit kvittot skall skickas</mat-hint>
    </mat-form-field>
  </div>
  <!-- <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="loadingService.visibility | async"></mat-spinner> -->
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Avbryt</button>
  <button mat-button (click)="sendReceipt()" [disabled]="!address.valid">Skicka</button>
</mat-dialog-actions>
