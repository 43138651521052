<div *ngFor="let inst of printInstructions" [ngClass]="inst.tclass">
  <div *ngIf="inst.temp == 'div'">
    <hr>
  </div>
  <div *ngIf="inst.temp == 'center'" class="dos-center">
    {{inst.text.center}}
  </div>
  <div *ngIf="inst.temp == 'row'" class="dos-row">
    <div [innerHTML]="inst.text.left" style="margin-right: auto;"></div>
    <div [innerHTML]="inst.text.center"></div>
    <div [innerHTML]="inst.text.right" style="margin-left: auto;"></div>
  </div>
  <div *ngIf="inst.temp == 'row3'" class="dos-row">
    <div [innerHTML]="inst.text.left" style="flex-grow: 1;max-width: 20px;"></div>
    <div [innerHTML]="inst.text.center" style="flex-grow: 2;"></div>
    <div [innerHTML]="inst.text.right" *ngIf="inst.text.right" style="flex-grow: 0;"></div>
  </div>
  <div *ngIf="inst.temp == 'row5-5-8'" class="dos-row">
    <div [innerHTML]="inst.text.left" style="flex: 10;"></div>
    <div [innerHTML]="inst.text.center" style="flex: 10;text-align: end;"></div>
    <div [innerHTML]="inst.text.right" *ngIf="inst.text.right" style="flex: 16;text-align: end;"></div>
  </div>
  <div *ngIf="inst.feed" [ngStyle]="{'height':inst.feed+'px'}"></div>
  <div *ngIf="inst.qr" class="dos-center">
    <img src="https://steward.heynows.com/qr?content={{inst.qr.data}}&size=100&margin=1" style="max-width: 220px;">
  </div>
  <div *ngIf="inst.qr" class="dos-center" style="border: 1px solid black;word-break: break-all;margin-top: 8px;background-color: white">
    <a [href]="inst.qr.data" target="_blank" style="width: 260px; padding: 8px;">{{inst.qr.data}}</a>
  </div>

</div>
