import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {VenueService} from "../../../services/venue.service";
import {LoadingService} from "../../../services/loading.service";
import {AuthService} from "../../../services/auth.service";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {StaffRoleSchema} from 'src/app/models/user';

@Component({
  selector: 'app-new-staff-dialog',
  templateUrl: './new-staff-dialog.component.html',
  styleUrls: ['./new-staff-dialog.component.css']
})
export class NewStaffDialogComponent implements OnInit {
  firstName = new UntypedFormControl('', [Validators.required]);
  lastName = new UntypedFormControl('', [Validators.required]);
  email = new UntypedFormControl('', [Validators.email]);
  phone = new UntypedFormControl('', []);
  pinCode = new UntypedFormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]);
  role = new UntypedFormControl('', []);
  roles: StaffRoleSchema[];
  identity: string;
  venueId: number;

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<NewStaffDialogComponent>, private auth: AuthService, private dialog: MatDialog) {
    this.identity = data.identity;
    this.venueId = data.venueId;
  }

  ngOnInit(): void {
    this.venueService.fetchRoleSchema(0).then( r => {
      this.roles = r;
    });
  }

  addUser() {
    if (this.firstName.valid && this.lastName.valid) {
      const firstName = this.firstName.value;
      const lastName = this.lastName.value;
      const pin = this.pinCode.value;
      const email = this.email.value;
      const phone = this.phone.value;
      const role = this.role.value;
      const roles = role == null || role === "" ? undefined : [role];
      this.venueService.createNewStaff(firstName, lastName, this.identity, this.venueId, pin, email, phone).then( r => {
        console.log("Created new staff:", r);
        this.venueService.editStaff(firstName, lastName, this.identity, this.venueId, email, phone, roles).then( es => {
          console.log("Edit staff result:", es);
          this.dialogRef.close(true);
        }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
      }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
    }
  }

  isHeynowAdmin() {
    return AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }
}
