<div style="margin-bottom: 16px;">
  <img src="https://storage.googleapis.com/heynow/media/Logo_Horz_Coral_rgb_200.png" width="100px">
</div>
<div class="landing-start">
  <div class="left">
    <h1>Kom igång!</h1>
    <div>
      <div><mat-icon>auto_awesome</mat-icon>Modern och lättanvänd</div>
      <div><mat-icon>insights</mat-icon>Rapporter och analys för dig</div>
      <div><mat-icon>sentiment_very_satisfied</mat-icon>Självservice för gäster på ditt sätt</div>
      <div><mat-icon>plumbing</mat-icon>Presentkort, rabattkoder, faktura m.m.</div>
      <div><mat-icon>tablet</mat-icon>Använd egen utrustning</div>
      <div><mat-icon>menu_book</mat-icon>Hantera din meny online</div>
      <div><mat-icon>verified</mat-icon>Kontrollbox i molnet</div>
      <div><mat-icon>savings</mat-icon>Ingen bindningstid</div>
    </div>
  </div>
  <div class="right"*ngIf="!success">
    <div class="cto">Ange email för att skapa ditt konto</div>
    <div>
      <mat-form-field appearance="outline" style="margin: 0">
        <mat-label>Email</mat-label>
        <input matInput [formControl]="email" required>
      </mat-form-field>
    </div>
    <div style="margin-bottom: 32px;">
      <mat-checkbox class="terms" [(ngModel)]="checked" color="primary">Jag godkänner <a href="https://storage.googleapis.com/heynow/media/heynow-allma%CC%88nna-avtalsvillkor-2022-01-01.pdf" target="_blank">Heynows allmänna villkor</a></mat-checkbox>
    </div>
    <div style="display: flex; flex-direction: row; justify-content: flex-end;">
      <mat-spinner [diameter]="32" *ngIf="sending" style="margin-right: auto;"></mat-spinner>
      <button mat-flat-button color="primary" (click)="createAccount()" [disabled]="sending">Skapa konto</button>
    </div>
  </div>
  <div class="right" *ngIf="success">
    <div style="font-weight: 300;">Tack!<br><br>Vi har skickat ett mail till {{email.value}}.<br><br>Öppna länken i mailet för att komma vidare 👍</div>
  </div>

</div>
