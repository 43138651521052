<div class="report-staff-container">

  <app-title-bar [title]="title" wikiPath="impact-report"></app-title-bar>

	<div class="search-fields-root">
	  <div class="search-fields">
		<mat-form-field appearance="outline">
		  <mat-label>Day or start date</mat-label>
		  <input matInput [(ngModel)]="searchStartDate" [matDatepicker]="startPicker">
		  <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
		  <mat-datepicker #startPicker [touchUi]="isMobile"></mat-datepicker>
		</mat-form-field>
		<mat-form-field appearance="outline">
		  <mat-label>End date</mat-label>
		  <input matInput [(ngModel)]="searchEndDate" [matDatepicker]="endPicker">
		  <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
		  <mat-datepicker #endPicker [touchUi]="isMobile"></mat-datepicker>
		</mat-form-field>
		<button mat-flat-button class="search-button" (click)="fetch()" color="primary">SÖK</button>
		<button mat-stroked-button class="dropdown-button" [matMenuTriggerFor]="menuHandleSection" *ngIf="true"><mat-icon>expand_more</mat-icon></button>
		<mat-menu #menuHandleSection="matMenu">
		  <button mat-menu-item [matMenuTriggerFor]="menuExport">Exportera</button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="clearFilters()">Rensa sökning</button>
		</mat-menu>
		<mat-menu #menuExport="matMenu">
		  <button mat-menu-item (click)="export('csv')">CSV-format</button>
		  <mat-divider></mat-divider>
		</mat-menu>
	  </div>
	</div>

  <div>
    <div class="generic-mastdet">
      <div class="generic-master">
        <table mat-table [dataSource]="dataSource" matSort class="clicktable table-cells" matSortActive="pay_date" matSortDirection="desc" matSortDisableClear="true">

          <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Namn</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <ng-container matColumnDef="crew_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Person-id</th>
          <td mat-cell *matCellDef="let element"> {{ element.crew_id }} </td>
          </ng-container>

          <ng-container matColumnDef="tip">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Dricks</th>
            <td mat-cell *matCellDef="let element">{{ element.tip | moneySuff : null : "kr" }}</td>
          </ng-container>

          <ng-container matColumnDef="direct_sell_count">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sälj antal</th>
            <td mat-cell *matCellDef="let element"><a style="color:black;" [routerLink]="" (click)="openArticleList()">{{ element.direct_sell_count | amount }} artiklar</a></td>
          </ng-container>

          <ng-container matColumnDef="direct_sell_count_mobile">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sälj antal</th>
            <td mat-cell *matCellDef="let element">{{ element.direct_sell_count | amount }}</td>
          </ng-container>

          <ng-container matColumnDef="direct_sell_amount">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Sälj summa</th>
            <td mat-cell *matCellDef="let element">{{ element.direct_sell_amount |  moneySuff : null : "kr" }}</td>
          </ng-container>

          <ng-container matColumnDef="sales">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ex moms</th>
            <td mat-cell *matCellDef="let element"> {{ element.sales}} </td>
          </ng-container>

          <ng-container matColumnDef="sales_vat">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Impact kr</th>
            <td mat-cell *matCellDef="let element"><b>{{ element.sales_vat | moneySuff : null : "kr"  }}</b></td>
          </ng-container>

          <ng-container matColumnDef="navigate_to">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button *ngIf="element.crew_id" mat-button [matMenuTriggerFor]="navigation"><mat-icon>arrow_drop_down</mat-icon></button>
              <mat-menu #navigation>
                <button mat-menu-item (click)="redirect(element, 'events')">Händelser</button>
                <button mat-menu-item (click)="redirect(element, 'receipts')">Kvitton</button>
                <button mat-menu-item (click)="redirect(element, 'sreport')">S-Rapport</button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></tr>
        </table>
        <div class="no-data" *ngIf="noData">Perioden innehåller inga försäljningar</div>
        <div class="no-data" *ngIf="initData">Välj period ovan för att söka</div>

      </div>
    </div>
  </div>
</div>
