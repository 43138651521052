import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {VenueService} from "../../../services/venue.service";
import {MatTableDataSource} from "@angular/material/table";
import {DatePipe} from "@angular/common";
import * as moment from 'moment';
import {combineLatest, first, Subscription} from "rxjs";
import { CombinedDayTimeArticleListData, QueryDayTimeArticleListRequest, QueryDayTimeArticleListResponse, dayTimeArticleHeader } from 'src/app/models/reporting';
import { HackUtils, LocalFileUtils } from 'src/app/utils/utils';
import { FireService } from 'src/app/services/fire.service';
import { SimpleDialogComponent } from '../../simple-dialog/simple-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-report-sales-datetime',
  templateUrl: './report-sales-datetime.component.html',
  styleUrls: ['./report-sales-datetime.component.css']
})
export class ReportSalesDatetimeComponent implements OnInit, OnDestroy {
  private paramSub: Subscription;
  private query: string;

  constructor(private venueService: VenueService, private route: ActivatedRoute, public datePipe: DatePipe, private fire: FireService,
              private dialog: MatDialog
  ) {
  }

  // @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns = [];

  title = "Försäljning per artikel (dag och tid)";
  venueId: string;
  selectedRowIndex = -1;
  noData = false;
  searchStartDate: Date;
  searchEndDate: Date;
  dataSource = new MatTableDataSource<CombinedDayTimeArticleListData>([]);
  customHeaders: dayTimeArticleHeader[];
  showSpinner = false;
  response: QueryDayTimeArticleListResponse;
  isMobile: boolean;

  ngOnInit(): void {
    this.isMobile = this.isMobile = HackUtils.isMobile() && window.innerWidth <= 768;
    // this.sort.sort(({ id: 'datekey', start: 'asc'}) as MatSortable);
    this.paramSub = combineLatest([
        this.route.paramMap,
        this.route.queryParamMap
      ]
    ).subscribe(([param, query]) => {
      const q = query.get("q");
      this.venueId = param.get("venue_id");
      const shouldFetch = (this.query !== q && q != null) || this.venueId == null;
      this.query = q;
      if (shouldFetch && this.query !== "search") {
        this.setupInitialSort(q);
        this.fetchDayTimeArticleList();
      }
      this.selectedRowIndex = undefined;
    });
  }

  ngOnDestroy(): void {
    this.paramSub?.unsubscribe();
  }

  fetchDayTimeArticleList() {
    const request: QueryDayTimeArticleListRequest = this.buildRequest();
    this.noData = false;
    this.venueService.queryDayTimeArticleList(this.venueId, request).then(r => {
      console.log("QueryDayTimeArticleListResponse", r);
      if (r != null) {
        this.response = r;
        this.noData = r.data.rows.length === 0;
        this.customHeaders = this.getHeadersWithTitles(r.data.headers);
        this.displayedColumns = [ 'datekey', 'time_group', 'group1', 'group2', 'group3', 'group4', 'name', 'sales', 'sales_inc_vat', 'vat', 'item_count', 'attribute'];
        this.dataSource = new MatTableDataSource(this.combineHeadersAndRows(r.data.headers, r.data.rows));

        // Sorting
        // this.dataSource.sort = this.sort;
        // this.dataSource.sortingDataAccessor = (item, property) => {
        //   switch (property) {
        //     case 'datekey':
        //       return new Date(item.datekey);
        //     default:
        //       return item[property];
        //   }
        // };
        // this.sort.sort(({ id: 'datekey', start: 'asc'}) as MatSortable);
      } else {
        this.dataSource = new MatTableDataSource([]);
      }
    }).catch(e => {
      SimpleDialogComponent.showErr(this.dialog, e);
      console.error(e);
    });
  }

  private buildRequest() {
    const request = new QueryDayTimeArticleListRequest();
    request.venue_id = this.venueId;

    if (this.searchStartDate != null) {
      request.start_date = this.datePipe.transform(this.searchStartDate, 'yyyy-MM-dd');
    }
    if (this.searchEndDate != null) {
      request.end_date = this.datePipe.transform(this.searchEndDate, 'yyyy-MM-dd');
    }
    return request;
  }

  clearFilters() {
    this.searchStartDate = undefined;
    this.searchEndDate = undefined;
  }

  private setupInitialSort(q: string) {
    this.clearFilters();
    switch (q) {
      case "week":
        this.searchStartDate = moment().subtract(1, "week").toDate();
        this.searchEndDate = moment().toDate();
        break;
      case "month":
        this.searchStartDate = moment().subtract(1, "month").toDate();
        this.searchEndDate = moment().toDate();
        break;
      case "search":
        break;
    }
  }

  selectRow(row: any[], index: number) {
    console.log(row);
    // this.selectedRowIndex = index;
  }

  get canExport(): boolean {
    return this.response && this.response.data.rows && this.response.data.rows.length > 0;
  }

  private getHeadersWithTitles(headers: dayTimeArticleHeader[]): dayTimeArticleHeader[] {
    const getHeaderTitle = (header: dayTimeArticleHeader) => {
      switch (header.id) {
        case 'datekey':
          return 'Datum';
        case 'time_group':
          return 'Tid';
        case 'group1':
          return 'Grupp1';
        case 'group2':
          return 'Grupp2';
        case 'group3':
          return 'Grupp3';
        case 'group4':
          return 'Grupp4';
        case 'name':
          return 'Artikel';
        case 'sales':
          return 'Ex.moms';
        case 'sales_inc_vat':
          return 'Inkl.moms';
        case 'vat':
          return 'Moms';
        case 'item_count':
          return 'Antal';
        case 'attribute':
          return 'Attribut';
        default:
          return header.name;
      }
    };

    for (const header of headers) {
      header.title = getHeaderTitle(header);
    }

    return headers;
  }

  async export(format: string) {
    const data = this.buildDataArrayWithHeader();
    const config = await this.fire.observeVenueConfig(Number(this.venueId)).pipe(first()).toPromise();
    const dates = LocalFileUtils.createDateFileName(this.response.params.start_date, this.response.params.end_date);
    const filename = `day_time_sales_report_${config.venue.name.replace(/\\s/g, "_")}_${dates}.csv`;
    LocalFileUtils.createFileWithBOM(data, filename);
  }

  private buildDataArrayWithHeader() {
    const headers = this.displayedColumns
        .map(colId => {
            const customHeader = this.customHeaders.find(h => h.id === colId);
            return customHeader ? { col: customHeader.id, title: customHeader.title } : null;
        })
        .filter(h => h !== null);
    const data = this.combineHeadersAndRows(this.response.data.headers, this.response.data.rows);
    // const sortedData = this.dataSource.sortData(this.dataSource.data, this.dataSource.sort);
    console.log("Header: ", headers);
    console.log("Rows: ", data);
    return LocalFileUtils.compileDataIntoCSV(headers, data);
  }

  combineHeadersAndRows(headers: any[], rows: any[]): CombinedDayTimeArticleListData[] {
    const data = [];
    rows.forEach(row => {
      const r = {};
      headers.forEach((header, index) => {
        r[header.id] = row[index];
      });
      data.push(r);
    });
    return data;
  }
}
