import {Component, OnDestroy, OnInit} from '@angular/core';
import {Slot, SlotDay} from "../../../models/slot";
import {HotTableRegisterer} from "@handsontable/angular";
import {Subscription} from "rxjs";
import {NamespaceInfo, VenueConfig} from "../../../models/venue-config";
import {VenueService} from "../../../services/venue.service";
import {ActivatedRoute} from "@angular/router";
import {FireService} from "../../../services/fire.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {NodeUtils, TimeUtils} from "../../../utils/utils";
import {SlotUtils} from "../../../utils/slot-utils";
import {environment} from "../../../../environments/environment";
import * as _ from "lodash";
import Utils from "../../../common/utils";
import Handsontable from "handsontable";
import {ValidationError} from "../../catalog/menu-models/PublishModels";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-edit-slots',
  templateUrl: './edit-slots.component.html',
  styleUrls: ['./edit-slots.component.css']
})
export class EditSlotsComponent implements OnInit, OnDestroy {
  venueId: number;
  errorMessage: string;
  // One object per "row"
  slotData: any[];
  hotSettings = {};
  hotId = "venue-customers-hot-id";
  loadedWithoutErrors = false;
  namespace: string;
  namespaces: NamespaceInfo[];

  private hotRegisterer = new HotTableRegisterer();
  private sub: Subscription;
  private config: VenueConfig;
  private columnList = [];
  private slotDays: SlotDay[];

  constructor(private venueService: VenueService, private route: ActivatedRoute, private fire: FireService, private snackBar: MatSnackBar, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.beginObserving();
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  private beginObserving() {
    this.sub = this.fire.observeVenueConfig(this.venueId).subscribe(cfg => {
      this.namespaces = cfg.takeaway?.slotting?.namespaces;
      if (this.namespaces != null && this.namespaces.length > 0) {
        this.namespace = this.namespaces[0].id;
      } else {
        this.namespace = "dns";
      }
      this.fetchSlots();
    });
  }

  private fetchSlots() {
    this.slotData = undefined;
    this.venueService.fetchSlots(this.venueId, this.namespace).then( slotDays => {
      this.loadedWithoutErrors = true;
      this.buildSlotDayMatrix(slotDays);
      this.setupHandsontableSettings();
      this.slotDays = slotDays;
    });
  }

  private setupHandsontableSettings() {
    const that: EditSlotsComponent = this;
    const set = {
      licenseKey: environment.handson_key,
      data: this.slotData,
      startRows: 20,
      autoWrapCol: false,
      autoWrapRow: false,
      colHeaders: true,
      rowHeaders: true,
      manualColumnResize: true,
      columns: this.columnList,
      contextMenu: {
        items: {
          row_above: {},
          row_below: {},
          separator1: Handsontable.plugins.ContextMenu.SEPARATOR,
          remove_row: {},
        }
      },
      stretchH: 'all',
      width: '100%',
      height: '100%',
    };
    this.hotSettings = set;
  }

  private buildSlotDayMatrix(slotDays: SlotDay[]) {
    if (slotDays == null || slotDays.length === 0) {
      this.buildEmptySlotDayMatrix();
      return;
    }

    const bounds = SlotUtils.findEarliestAndLatestSlot(slotDays);
    const slotLength = slotDays[0].slot_length;

    const dates = [];
    const cols = [];
    for (const slotDay of slotDays) {
      dates.push(slotDay.date_key);
      const allSlots = [];
      if (slotDay.slots != null && slotDay.slots.length > 0 ) {
        const first = slotDay.slots[0];
        const last = slotDay.slots[slotDay.slots.length - 1];
        const preDummySlots = (TimeUtils.getDayMinute(first.time) - TimeUtils.getDayMinute(bounds.first.time)) / slotLength;
        const postDummySlots = (TimeUtils.getDayMinute(bounds.last.time) - TimeUtils.getDayMinute(last.time)) / slotLength;
        this.addDummySlots(allSlots, preDummySlots);
        allSlots.push(...slotDay.slots);
        this.addDummySlots(allSlots, postDummySlots);
      }
      cols.push({allSlots, slotDay});
    }

    // Convert columns to rows
    const firstMinute = TimeUtils.getDayMinute(bounds.first.time);
    const rows = [];
    const slotCount = ((TimeUtils.getDayMinute(bounds.last.time) - TimeUtils.getDayMinute(bounds.first.time)) / slotLength) + 1;
    for (let i = 0; i < slotCount; i++) {
      const cells: any = {};
      for (const [index, col] of cols.entries()) {
        const colName = `day${index}`;
        const slot = col.allSlots[i] as Slot;
        cells[colName] = slot?.left;
      }
      cells.time = TimeUtils.formatTimeFromMinutes(firstMinute + i * slotLength);
      rows.push(cells);
    }

    this.prepareData(dates, rows);
  }

  private buildEmptySlotDayMatrix() {
    const dates = ["2022-MM-DD"];
    const rows = [{time: "12:00"}, {time: "12:15"}, {time: "12:30"}];
    this.prepareData(dates, rows);
  }

  private addEmptyCols() {
    let index = this.columnList.length - 1;
    for (let i = 0; i < 5; i++) {
      const colName = `day${index}`;
      this.columnList.push({data: colName, title: `Dag ${index + 1}`, width: 120});
      index++;
    }
  }

  private prepareData(dates: any[], rows: any[]) {
    console.log(dates);
    console.log(rows);

    this.columnList = [];
    const data = [];
    const colDates = {};
    this.columnList.push({data: "time", title: "Tid", width: 120});
    for (const [index, date] of dates.entries()) {
      const colName = `day${index}`;
      this.columnList.push({data: colName, title: `Dag ${index + 1}`, width: 120});
      colDates[colName] = date;
    }
    this.addEmptyCols();

    data.push(colDates);
    for (const row of rows) {
      data.push(row);
    }
    for (let i = 0; i < 20; i++) {
      data.push({});
    }
    this.slotData = data;
  }

  private addDummySlots(into: any[], count: number) {
    for (let i = 0; i < count; i++) {
      into.push({});
    }
  }

  private collectTableData(): any[] {
    const hotInstance = this.hotRegisterer.getInstance(this.hotId);
    const rows = [];
    for (let i = 0; i < hotInstance.countRows(); i++) {
      const row = {};
      for (const col of this.columnList) {
        const v = hotInstance.getDataAtRowProp(i, col.data);
        if (!Utils.isStringNullOrWhitespace(v)) {
          row[col.data] = v;
        }
      }
      if (!NodeUtils.isNullOrEmpty(row)) {
        // @ts-ignore
        row.rowIndex = i + 1;
        rows.push(row);
      }
    }
    return rows;
  }


  save() {
    if (!this.loadedWithoutErrors) {
      //Make sure we do not try to save if we didnt loaded the data correctly
      console.error("failed to save (load error)");
      return;
    }
    if (!this.hasChanges()) {
      this.snackBar.open("No changes. Nothing saved.", "", {duration: 3000});
      return;
    }
    this.errorMessage = null;
    const rows = this.collectTableData();

    // Convert to days
    const dateHeader = rows[0];
    const matchDate = /^\d\d\d\d-\d\d-\d\d$/;
    const matchSlot = /^\d+$/;
    const days = [];
    _.forOwn(dateHeader, (date, key) => {
      if (key.startsWith("day")) {
        if (!matchDate.test(date)) {
          this.errorMessage = `Felaktigt datum "${date}" (rad ${dateHeader.rowIndex})`;
          return;
        }
        console.log(date);
        const slots = [];
        for (const row of rows) {
          const cellv = row[key];
          if (row.time != null && cellv != null) {
            if (!matchSlot.test(cellv)) {
              this.errorMessage = `Slot värde "${cellv}" är inte ett nummer (rad ${row.rowIndex})`;
              return;
            }
            slots.push({time: row.time, left: Number(cellv)});
          }
        }
        days.push({date, slots});
      }
    });
    console.log("days:", days);

    this.venueService.updateSlots(this.venueId, days, this.namespace).then(slotDays => {
      console.log(slotDays);
      this.slotDays = slotDays;
      this.buildSlotDayMatrix(slotDays);
      this.setupHandsontableSettings();
    }).catch(error => this.updateError(error));

  }

  public hasChanges(): boolean {
    if (!this.loadedWithoutErrors) {
      return false;
    }
    return true;
  }

  private updateError(error: Error) {
    if (error instanceof ValidationError) {
      this.errorMessage = error.message;
    } else if (error instanceof HttpErrorResponse) {
      this.errorMessage = error.error.message;
    }
  }

  closeError() {
    this.errorMessage = null;
  }

  changeNS(ns: string) {
    this.namespace = ns;
    this.fetchSlots();
  }
}
