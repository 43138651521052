import { Injectable } from '@angular/core';
import {from, Observable, of} from "rxjs";
import {FSMenu, FSVenueConfig, VenueConfig} from "../models/venue-config";
import {filter, flatMap, map, tap} from "rxjs/operators";
import {TerminalStatus} from "../models/terminal-status";
import {PrinterStatus} from "../models/printer-status";
import {ClientStatus} from "../models/client-status";
import {AngularFirestore, DocumentChangeAction} from "@angular/fire/compat/firestore";
import {TerminalTx} from "../models/terminal-tx";
import {FSPrintJob} from "../models/print-job";
import {Floorplan, FSFloorplan} from "../models/floorplan";
import {Session} from "../models/Session";
import {Order, Receipt} from "../models/order";
import {Bong} from "../models/kds-bong";
import {VenueEvent} from "../models/venue-event";
import {VenueService} from "./venue.service";
import {QueryFn} from "@angular/fire/compat/firestore/interfaces";
import {MenuCell, MenuTab, MenuTabVersion, MenuVersion, TabVersionMenuCell} from "../components/catalog/menu-models/MenuChange";
import {VenueTicket} from "../models/venue-ticket";
import * as moment from 'moment';
import firebase from "firebase/compat/app";
import {CustomPOSMenuPage, MenuStructure} from "../models/menu-structure";
import {Voucher} from "../models/voucher";
import {WikiEditor} from "../models/genji-models";
import { ObservableUtils } from '../utils/utils';


@Injectable({
  providedIn: 'root'
})
export class FireService {

  constructor(private afs: AngularFirestore, private venueService: VenueService) { }

  /* Check that user has rights to the venueId. "not optimal security but better than nothing" */
  private venutIdCache = [];
  private check(venueId: number): Observable<any> {
    if (this.venutIdCache.includes(venueId)) {
      return of(true);
    } else {
      return from(this.venueService.checkVenueId(venueId)).pipe(
        tap(r => { this.venutIdCache.push(venueId); } ),
      );
    }
  }

  private doc<T>(collection: string, venueId: number): Observable<T | undefined> {
    return this.check(venueId).pipe( flatMap(r => this.afs.doc<T>(collection + "/" + venueId.toString()).valueChanges() ));
  }

  private docPath<T>(collection: string, venueId: number, path: string): Observable<T | undefined> {
    return this.check(venueId).pipe( flatMap(r => this.afs.doc<T>(collection + "/" + path).valueChanges() ));
  }

  private collection<T>(venueId: number, path: string, queryFn?: QueryFn): Observable<T[]> {
    return this.check(venueId).pipe( flatMap(r => this.afs.collection<T>(path, queryFn).valueChanges() ));
  }

  private getByQuery<T>(venueId: number, path: string, queryFn?: QueryFn): Observable<T> {
    return this.check(venueId).pipe(
      flatMap(r => this.afs.collection<T>(path, queryFn).get()),
      map<firebase.firestore.QuerySnapshot<T>, T>( querySnapshot => {
        if (!querySnapshot.empty) {
          return querySnapshot.docs[0].data();
        }
        return undefined;
      })
    );
  }

  observeVenueConfig(venueId: number): Observable<VenueConfig> {
    return this.doc<FSVenueConfig>("appconfig", venueId).pipe(
      map<FSVenueConfig, VenueConfig>(res => {
        const v = JSON.parse(res.data) as VenueConfig;
        return v;
      })
    );
  }

  observeRawConfigs(): Observable<FSVenueConfig[]> {
    return this.afs.collection<FSVenueConfig>("appconfig").valueChanges();
  }

  observeVenueConfigChanges(): Observable<DocumentChangeAction<FSVenueConfig>[]> {
    return this.afs.collection<FSVenueConfig>("appconfig", ref => ref.orderBy('venue_id')).stateChanges();
  }

  observeFloorplan(venueId: number): Observable<Floorplan> {
    return this.doc<FSFloorplan>("floormaps", venueId).pipe(
      map<FSFloorplan, Floorplan>(res => {
        return JSON.parse(res.data);
      })
    );
  }

  observeFSFloorplan(venueId: number): Observable<FSFloorplan> {
    return this.doc<FSFloorplan>("floormaps", venueId);
  }

  observeActiveSessions(venueId: number): Observable<Session[]> {
    return this.collection<Session>(venueId, "sessions", ref => ref.where("venue_id", "==", venueId).where("active", "==", true));
  }

  // observeActiveSession(venueId: number, table: string): Observable<Session> {
  //   return this.observeActiveSessions(venueId).pipe(
  //     // tap( ss => console.log("Found sessions", ss) ),
  //     mergeAll(),
  //     // tap( s => console.log(s) ),
  //     filter( s => s.table === table)
  //     );
  // }

  observeActiveSession(venueId: number, table: string): Observable<Session[]> {
    return this.observeActiveSessions(venueId).pipe(
      // tap( ss => console.log("Found sessions", ss) ),
      // tap( s => console.log(s) ),
      map( ss => ss.filter(s => s.table === table) )
    );
  }

  observeOrders(venueId: number): Observable<Order[]> {
    return this.collection<Order>(venueId, "orders", ref => ref.where("venue_id", "==", venueId));
  }

  observeTableOrders(venueId: number, table: string): Observable<Order[]> {
    if (table) {
      return this.collection<Order>(venueId, "orders", ref => ref.where("venue_id", "==", venueId).where("table", "==", table));
    }
    return this.observeOrders(venueId);
  }

  observePaidReceipts(venueId: number): Observable<Receipt[]> {
    return this.collection<Receipt>(venueId, "receipts", ref => ref.where("venue_id", "==", venueId)
      .orderBy("created", "desc").orderBy("paid_date", "desc"));
  }

  observeTodaysTotalSales(): Observable<[Map<string, number>,number, number]> {
    let startingPoint = moment().startOf('day').add(6, 'hour');

    if(moment().isBefore(startingPoint)) {
      startingPoint = startingPoint.subtract(1, 'day');
    }

    const cutoff = startingPoint.toDate();

    let total = 0;
    let count = 0;
    const tot = new Map<string, number>();
    return this.afs.collection<Receipt>('receipts', ref => ref
    .where("paid_date", ">=", cutoff))
    .stateChanges().pipe(
      map(actions => {
        const newTotals = actions.reduce((totals, action) => {
          const receipt = action.payload.doc.data();
            const id = receipt.venue_id.toString();
            const sales = totals.get(id) ?? 0;
            const amount = receipt.is_refund ? -receipt.total_amount : receipt.total_amount || 0;

          switch (action.type) {
            case 'added':
              total += amount;
              count += 1;
              totals.set(id, sales + amount);
              break;
            case 'removed':
              total -= amount;
              count -= 1;
              totals.set(id, sales - amount);
              break;
            case 'modified':
              const oldData = action.payload.doc.metadata.fromCache ? receipt : action.payload.doc.data();
              const oldAmount = oldData.total_amount || 0;
              total += amount - oldAmount;
              totals.set(id, amount - oldAmount);
              break;
          }
          //console.log("Total sales", total, count)
          return totals;
        }, tot);
        return [newTotals, total, count];
      })
    );
  }

  observeTerminalStatus(venueId: number): Observable<TerminalStatus[]> {
    return this.collection<TerminalStatus>( venueId, "termstatus", ref => ref.where("venueId", "==", venueId));
  }

  observePrinterStatus(venueId: number): Observable<PrinterStatus[]> {
    return this.collection<PrinterStatus>( venueId, "printer_info", ref => ref.where("venueId", "==", venueId));
  }

  observeClientStatus(venueId: number): Observable<ClientStatus[]> {
    return this.collection<ClientStatus>( venueId, "client_info", ref => ref.where("venueId", "==", venueId));
  }

  observeAllClientStatuses(hideOld: boolean, projection: (cs: ClientStatus) => ClientStatus): Observable<ClientStatus[]> {
    const query = this.afs.collection<ClientStatus>("client_info", ref => {
      let q: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      if (hideOld) {
        const oldThreshold = new Date();
        oldThreshold.setHours(oldThreshold.getHours() - 24); // 24 hours ago
        q = q.where('updated', '>=', oldThreshold);
      }
      return q;
    });
    return ObservableUtils.projectedChanges(query.stateChanges(), projection);
  }

  observeTerminalTxs(venueId: number): Observable<TerminalTx[]> {
    return this.collection<TerminalTx>( venueId, "termtxs", ref => ref.where("venueId", "==", venueId));
  }

  observeVenueTableEvents(venueId: number, table: string, date: Date): Observable<VenueEvent[]> {
    return this.collection<VenueEvent>( venueId, "events", ref => ref
      .where("venue_id", "==", venueId)
      .where("table", "==", table)
      .where("date", ">", date)
    );
  }

  observeVenueEvents(venueId: number, date: Date): Observable<VenueEvent[]> {
    return this.collection<VenueEvent>( venueId, "events", ref => ref
      .where("venue_id", "==", venueId)
      .where("date", ">", date)
      .orderBy("date", "desc")
    );
  }

  observePrintJobs(venueId: number): Observable<FSPrintJob[]> {
    return this.collection<FSPrintJob>( venueId, "print_job", ref => ref
      .where("venue_id", "==", venueId)
      .where("job_type", "==", "bong")
      .orderBy("created", "desc")
    );
  }

  observePrintJobChanges(venueId: number): Observable<DocumentChangeAction<FSPrintJob>[]> {
    return this.afs.collection<FSPrintJob>("print_job", ref => ref
      .where("venue_id", "==", venueId)
      .where("job_type", "==", "bong")
      .orderBy("created", "desc")
    ).stateChanges();
  }

  observeKDSBongs(venueId: number): Observable<Bong[]> {
    return this.collection<Bong>(venueId, "bongs", ref => ref.where("venue_id", "==", venueId)).pipe(
      tap<Bong[]>( bongs => {
        for (const b of bongs) {
          b.items = JSON.parse(b.items_json, (key, value) => {
            if (key === "updated" && value !== undefined) {
              //console.log("JSON updated", b.order_numbers, value, new Date(value + "z"))
              return firebase.firestore.Timestamp.fromDate(new Date(value + "z"));
            }
            return value;
          });
          if (b.order_tags) {
            b.orderTags = JSON.parse(b.order_tags);
          }
        }
      })
    );
  }

  observeMenuStructure(venueId: number): Observable<MenuStructure> {
    return this.observeFSMenu(venueId).pipe(
      filter( m => m != null),
      map<FSMenu, MenuStructure>(res => {
        return JSON.parse(res.data);
      })
    );
  }

  observeCustomMenuPages(venueId: number): Observable<CustomPOSMenuPage[]> {
    return this.collection<CustomPOSMenuPage>( venueId, "menu_pages", ref => ref.where("venueId", "==", venueId)).pipe(
      tap<CustomPOSMenuPage[]>( pages => {
        for (const page of pages) {
          page.items = JSON.parse(page.menuPageData);
        }
      })
    );
  }

  observeVouchers(venueId: number): Observable<Voucher[]> {
    return this.collection<Voucher>(venueId, "vouchers", ref => ref.where("venue_id", "==", venueId));
  }

  /*----------------------------------------
    -------------- MENU -------------------
   -----------------------------------------*/

  observeFSMenu(venueId: number): Observable<FSMenu> {
    return this.docPath<FSMenu>("menus", venueId, `${venueId}:alacarte` );
  }

  getTabs(venueId: number, menuId: string): Observable<MenuTab[]> {
    return this.afs.collection<MenuTab>("catalog_tabs", ref => ref
      .where("venueId", "==", venueId)
      .where("menuId", "==", menuId)
    ).get().pipe(
      map<firebase.firestore.QuerySnapshot, MenuTab[]>( snapshot => {
        return snapshot.docs.map( doc => doc.data() as MenuTab );
      })
    );
  }

  observeTabs(venueId: number, menuId: string): Observable<MenuTab[]> {
    return this.collection<MenuTab>(venueId, "catalog_tabs", ref => ref
      .where("venueId", "==", venueId)
      .where("menuId", "==", menuId)
    );
  }

  deleteMenuTabs(tabs: MenuTab[]): Promise<void> {
    const batch = this.afs.firestore.batch();
    for (const tab of tabs) {
      const path = `catalog_tabs/${tab.venueId}:${tab.menuId}:${tab.id}`;
      const ref = this.afs.doc<MenuTab>(path).ref;
      batch.delete(ref);
    }
    console.log(`Deleting ${tabs.length} tabs`);
    return batch.commit();
  }

  observeTab(venueId: number, menuId: string, tabId: string): Observable<MenuTab> {
    return this.afs.doc<MenuTab>(`catalog_tabs/${venueId}:${menuId}:${tabId}`).valueChanges();
  }

  getTab(venueId: number, menuId: string, tabId: string): Observable<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>> {
    return this.afs.doc<MenuTab>(`catalog_tabs/${venueId}:${menuId}:${tabId}`).get();
  }

  getMenuTab(venueId: number, menuId: string, tabId: string): Observable<MenuTab> {
    return this.afs.doc<MenuTab>(`catalog_tabs/${venueId}:${menuId}:${tabId}`).get().pipe(
      map<firebase.firestore.DocumentSnapshot, MenuTab>( snapshot => {
        return snapshot.data() as MenuTab;
      })
    );
  }

  observeCells(venueId: number, menuId: string, tabId: string): Observable<DocumentChangeAction<MenuCell>[]> {
    return this.afs.collection<MenuCell>("catalog_cells", ref => ref
      .where("venueId", "==", venueId)
      .where("menuId", "==", menuId)
      .where("tabId", "==", tabId)).stateChanges();
  }

  getCells(venueId: number, menuId: string, tabId: string): Observable<MenuCell[]> {
    return this.afs.collection<MenuTab>("catalog_cells", ref => ref
      .where("venueId", "==", venueId)
      .where("menuId", "==", menuId)
      .where("tabId", "==", tabId)
    ).get().pipe(
      map<firebase.firestore.QuerySnapshot, MenuCell[]>( snapshot => {
        return snapshot.docs.map( doc => doc.data() as MenuCell );
      })
    );
  }

  getNamedCells(venueId: number, menuId: string, name: string): Observable<MenuCell[]> {
    return this.afs.collection<MenuTab>("catalog_cells", ref => ref
      .where("venueId", "==", venueId)
      .where("menuId", "==", menuId)
      .where("col", "==", name)
    ).get().pipe(
      map<firebase.firestore.QuerySnapshot, MenuCell[]>( snapshot => {
        return snapshot.docs.map( doc => doc.data() as MenuCell );
      })
    );
  }

  getNamedCellsForTab(venueId: number, menuId: string, tabId: string, name: string): Observable<MenuCell[]> {
    return this.afs.collection<MenuTab>("catalog_cells", ref => ref
      .where("venueId", "==", venueId)
      .where("menuId", "==", menuId)
      .where("tabId", "==", tabId)
      .where("col", "==", name)
    ).get().pipe(
      map<firebase.firestore.QuerySnapshot, MenuCell[]>( snapshot => {
        return snapshot.docs.map( doc => doc.data() as MenuCell );
      })
    );
  }

  // observeMenuChanges(venueId: number): Observable<MenuChange> {
  //   return this.afs.doc<MenuChange>(`menus/${venueId}:menu`).valueChanges().pipe(
  //     map<FSMenu, MenuStructure>(res => {
  //       return JSON.parse(res.data);
  //     })
  //   );
  // }

  // createMenu(menu: VenueMenu): Promise<void> {
  //   // @ts-ignore
  //   menu.created = firebase.firestore.FieldValue.serverTimestamp();
  //   return this.afs.doc<VenueMenu>(`catalog_menus/${menu.venueId}:${menu.id}`).set(menu);
  // }
  //

  updateMenuTab(menuTab: MenuTab): Promise<void> {
    // @ts-ignore
    menuTab.updated = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.doc<MenuTab>(`catalog_tabs/${menuTab.venueId}:${menuTab.menuId}:${menuTab.id}`).set(menuTab);
  }

  // updateMenuCell(menuCell: MenuCell): Promise<void> {
  //   // @ts-ignore
  //   //menuCell.updated = firebase.firestore.FieldValue.serverTimestamp();
  //   menuCell.updated = firebase.firestore.Timestamp.now();
  //   return this.afs.doc<MenuCell>(`catalog_cells/${menuCell.venueId}:${menuCell.menuId}:${menuCell.tabId}:${menuCell.col}:${menuCell.row}`).set(menuCell);
  // }

  updateMenuCells(menuCells: MenuCell[]): Promise<void> {
    const batch = this.afs.firestore.batch();
    for (const menuCell of menuCells) {
      menuCell.updated = firebase.firestore.Timestamp.now();
      const path = `catalog_cells/${menuCell.venueId}:${menuCell.menuId}:${menuCell.tabId}:${menuCell.col}:${menuCell.row}`;
      const ref = this.afs.doc<MenuCell>(path).ref;
      if (menuCell.value && menuCell.value !== "null" ) {
        batch.set(ref, menuCell);
      } else {
        batch.delete(ref);
      }
    }
    return batch.commit();
  }

  deleteMenuCells(menuCells: MenuCell[]): Promise<void> {
    const batch = this.afs.firestore.batch();
    for (const menuCell of menuCells) {
      const path = `catalog_cells/${menuCell.venueId}:${menuCell.menuId}:${menuCell.tabId}:${menuCell.col}:${menuCell.row}`;
      const ref = this.afs.doc<MenuCell>(path).ref;
      batch.delete(ref);
    }
    console.log(`Deleting ${menuCells.length} cells`);
    return batch.commit();
  }

  restoreMenuCells(venueId: number, menuId: string, tabId: string, tvMenuCells: TabVersionMenuCell[]): Promise<void> {
    const batch = this.afs.firestore.batch();
    for (const tvMenuCell of tvMenuCells) {
      const menuCell: MenuCell = tvMenuCell as MenuCell;
      menuCell.updated = tvMenuCell.updated ? new firebase.firestore.Timestamp(tvMenuCell.updated.seconds, tvMenuCell.updated.nanoseconds) : firebase.firestore.Timestamp.now();
      menuCell.venueId = venueId;
      menuCell.menuId = menuId;
      menuCell.tabId = tabId;
      const path = `catalog_cells/${menuCell.venueId}:${menuCell.menuId}:${menuCell.tabId}:${menuCell.col}:${menuCell.row}`;
      const ref = this.afs.doc<MenuCell>(path).ref;
      batch.set(ref, menuCell);
    }
    console.log(`Restoring ${tvMenuCells.length} cells`);
    return batch.commit();
  }

  getMenuVersions(venueId: number, menuId: string): Observable<MenuVersion[]> {
    return this.afs.collection<MenuVersion>("catalog_menu_version", ref => ref
      .where("venueId", "==", venueId)
      .where("menuId", "==", menuId)
      .orderBy("created", "desc")
    ).get().pipe(
      map<firebase.firestore.QuerySnapshot, MenuVersion[]>( snapshot => {
        return snapshot.docs.map( doc => doc.data() as MenuVersion );
      })
    );
  }

  getLatestMenuVersion(venueId: number, menuId: string): Observable<MenuVersion> {
    return this.getByQuery<MenuVersion>( venueId, "catalog_menu_version", ref => ref
      .where("venueId", "==", venueId)
      .where("menuId", "==", menuId)
      .orderBy("created", "desc")
    );
  }

  getMenuVersion(venueId: number, menuId: string, hash: string): Observable<MenuVersion> {
    return this.afs.doc<MenuVersion>(`catalog_menu_version/${venueId}:${menuId}:${hash}`).get().pipe(
      map<firebase.firestore.DocumentSnapshot, MenuVersion>( snapshot => {
        if (snapshot.exists) {
          return snapshot.data() as MenuVersion;
        }
        return undefined;
      })
    );
  }

  getTabVersion(venueId: number, menuId: string, tabId: string, hash: string): Observable<MenuTabVersion> {
    return this.afs.doc<MenuTabVersion>(`catalog_tab_version/${venueId}:${menuId}:${tabId}:${hash}`).get().pipe(
      map<firebase.firestore.DocumentSnapshot, MenuTabVersion>( snapshot => {
        if (snapshot.exists) {
          return snapshot.data() as MenuTabVersion;
        }
        return undefined;
      })
    );
  }

  createMenuVersion(menuVersion: MenuVersion): Promise<void> {
    console.log(`Creating new menu version... ${menuVersion.hash}`);
    // @ts-ignore
    menuVersion.created = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.doc<MenuVersion>(`catalog_menu_version/${menuVersion.venueId}:${menuVersion.menuId}:${menuVersion.hash}`).set(menuVersion);
  }

  createTabVersion(tabVersion: MenuTabVersion): Promise<void> {
    console.log(`Creating new tab version... ${tabVersion.id}:${tabVersion.hash}`);
    // Uncomment this to fix column undefined issue with bad data in firestore
    // if (tabVersion.columns == null) {
    //   tabVersion.columns = [];
    // }
    // console.log(tabVersion);
    // @ts-ignore
    tabVersion.created = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.doc<MenuTabVersion>(`catalog_tab_version/${tabVersion.venueId}:${tabVersion.menuId}:${tabVersion.id}:${tabVersion.hash}`).set(tabVersion);
  }

  observeVenueTickets(venueId: number): Observable<VenueTicket[]> {
    return this.collection<VenueTicket>(venueId, "venue_tickets", ref => ref.where("venueId", "==", venueId).orderBy("updated", "desc"));
  }

  observeMiniTickets(venueId: number, selectedStates: string[]): Observable<VenueTicket[]> {
    return this.collection<VenueTicket>(venueId, "mini_tickets",
      venueId ?
      ref => ref.where("venueId", "==", venueId).where("state", "in", selectedStates).orderBy("created", "desc") :
      ref => ref.where("state", "in", selectedStates).orderBy("created", "desc")
    );
  }

  createVenueTicket(venueTicket: VenueTicket): Promise<void> {
    // @ts-ignore
    venueTicket.created = firebase.firestore.FieldValue.serverTimestamp();
    // @ts-ignore
    venueTicket.docId = `${venueTicket.venueId}:${moment().unix()}`;
    return this.afs.doc<VenueTicket>(`mini_tickets/${venueTicket.venueId}:${moment().unix()}`).set(venueTicket);
  }

  updateVenueTicket(venueTicket: VenueTicket): Promise<void> {
    // @ts-ignore
    venueTicket.updated = firebase.firestore.FieldValue.serverTimestamp();
    return this.afs.doc<VenueTicket>(`mini_tickets/${venueTicket.docId}`).update(venueTicket);
  }

  updateWikiEditor(editor: WikiEditor): Promise<void> {
    const documentKey = `${editor.email}:${editor.compound_key}`;
    return this.afs.doc<WikiEditor>(`wiki_editors/${documentKey}`).set(editor);
  }

  deleteEditor(email: string, compoundKey: string): Promise<void> {
    const documentKey = `${email}:${compoundKey}`;
    const docRef = this.afs.collection('wiki_editors').doc(documentKey);
    return docRef.delete();
  }

  observeWikiEditors(compoundKey: string): Observable<WikiEditor[]> {
    return this.afs.collection<WikiEditor>("wiki_editors", ref => ref.where("compound_key", "==", compoundKey)).valueChanges();
  }

}
