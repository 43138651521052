import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FloorplanTable} from "../../models/floorplan";
import {FireService} from "../../services/fire.service";
import {OrderService} from "../../services/order.service";
import {VenueService} from "../../services/venue.service";
import {Subscription} from "rxjs";
import {OrderSummary, OrderSummaryRow} from "../../models/order";
import {LocationCode} from "../../models/venue-dashboard";
import {environment} from "../../../environments/environment";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SimpleDialogComponent} from "../simple-dialog/simple-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.css']
})
export class OrderSummaryComponent implements OnInit, OnDestroy {

  private orderSub: Subscription;
  summary: OrderSummary;

  /* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
  _locationCode: LocationCode;
  table: string;
  venueId: number;
  passeUrl = environment.passe_url;
  @Input() showActions = true;

  @Input() set data(d: {table: string, venueId: number}) {
    if (d == null) { return; }
    this.table = d.table;
    this.venueId = d.venueId;
    this.beginObservingTable();
  }
  @Input() set locationCode(lc: LocationCode) {
    this._locationCode = lc;
  }

  constructor(private fire: FireService, private orderService: OrderService, private venueService: VenueService,
              private snackBar: MatSnackBar, private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.orderSub?.unsubscribe();
  }

  private beginObservingTable() {
    this.orderSub?.unsubscribe();
    this.orderSub = this.orderService.observeOrderSummary(this.venueId, this.table).subscribe(summary => {
      this.summary = summary;
    });
  }

  forward(row: OrderSummaryRow) {
    const div = row.divider;
    const mess = div.itemRefs.map(it => it.name).join(", ");
    const ds = {title: `Framma ${div.text}?`, message: mess, cancelButton: "Avbryt", positiveButton: "Framma"};
    SimpleDialogComponent.observeSimpleDialog(this.dialog, ds).subscribe( r => {
      if (r) {
        this.venueService.forwardTable(this.venueId, this.table, div.phase, div.itemRefs).then( res => {
        }).catch(error => this.snackBar.open(`Failed to forward!`, "close"));
      }
    });
  }
}
