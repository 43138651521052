export const pagePaymentTerminals = {
  title: {sv: "Terminaler"},
  page_settings: {
    root_path: "payment",
    root_anchor: "terminals"
  },
  desc: {sv: "Inställningar för Terminaler"},
  properties: [
    {key: "terminals", title: {sv: "Terminaler"}, type: "table",
      columns: [
        {key: "disabled", title: {sv: "Inaktiverad"}, type: "bool", optional: true },
        {key: "name", title: {sv: "Namn"}, type: "string" },
        {key: "alias", title: {sv: "Kortnamn"}, type: "string" },
        {key: "port", title: {sv: "Port"}, type: "integer" },
        {key: "ip", title: {sv: "IP-adress"}, type: "string" },
        {key: "email_filter", title: {sv: "E-post"}, type: "string" },
        {key: "print_receipts", title: {sv: "Kvittoutskrift"}, type: "bool" },
        {key: "remote", title: {sv: "Remote"}, type: "string" },
        {key: "model", title: {sv: "Modell"}, type: "enum", width: 100, source: ["IWL250", "ICT250", "IPP350", "LANE3000", "MOVE5000", "S1F2", "V400M", "DUMMY"] },
        {key: "no_print", title: {sv: "Ingen utskrift"}, type: "bool" },
        {key: "comment", title: {sv: "Kommentar"}, type: "string" },
      ],
      additional_rows: 6,
    },
  ]
};
