import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {VenueService} from "../../../services/venue.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, MatSortable} from "@angular/material/sort";
import {LoyaltyCard} from 'src/app/models/loyalty';

@Component({
  selector: 'app-loyalty-list',
  templateUrl: './loyalty-list.component.html',
  styleUrls: ['./loyalty-list.component.css']
})
export class LoyaltyListComponent implements OnInit {

  constructor(public dialog: MatDialog, private route: ActivatedRoute, private venueService: VenueService, private router: Router) { }

  venueId: number;
  displayedColumns = ["identity", "program_name", "first_name", "last_name", "email", "phone", "valid_to", "birthday", "updated", "action"];
  dataSource: MatTableDataSource<LoyaltyCard>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit(): void {
    this.sort.sort(({ id: 'identity', start: 'asc' }) as MatSortable);
    this.route.paramMap.subscribe(data => {
      const vid = data.get("venue_id");
      this.venueId = Number(vid);
      this.fetchLoyaltyCards();
    });
  }

  private fetchLoyaltyCards() {
    this.venueService.fetchLoyaltyCards(this.venueId).then(r => {
      this.dataSource = new MatTableDataSource(r as LoyaltyCard[]);
      this.dataSource.sort = this.sort;
    });
  }

  addCard() {
    this.router.navigateByUrl(`venue/${this.venueId}/loyalty/new:`);
  }

  update(row: any) {
    this.router.navigateByUrl(`venue/${this.venueId}/loyalty/${row.key}`);
  }

  gotoImport() {
    this.router.navigateByUrl(`venue/${this.venueId}/loyalty-import`);
  }
}
