import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HackUtils} from "../../../utils/utils";
import {combineLatest, Subscription} from "rxjs";
import {VenueService} from "../../../services/venue.service";
import {ActivatedRoute, ParamMap, Params, Router} from "@angular/router";
import Utils from "../../../common/utils";
import * as moment from "moment";
import {QuerySalesDataRequest, QueryTimeSeriesDataResponse} from "../../../models/reporting";
import * as _ from "lodash";
import {MatLegacyMenuTrigger as MatMenuTrigger} from "@angular/material/legacy-menu";
import { SimpleDialogComponent } from '../../simple-dialog/simple-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-report-timeseries',
  templateUrl: './report-timeseries.component.html',
  styleUrls: ['./report-timeseries.component.css']
})
export class ReportTimeseriesComponent implements OnInit, OnDestroy {

  @ViewChild('reportsMenuTrigger') reportsMenuTrigger: MatMenuTrigger;

  title = "Försäljning grupperat per tidsintervall";
  searchStartDate: Date;
  searchEndDate: Date;
  chartOptions: any;
  venueId: number;
  isMobile: boolean;
  noData = false;
  missingData = false;
  startTime: string;
  endTime: string;
  interval: number;
  hourlyOptions = [
    {title: "Visa linje diagram", active: false},
    {title: "Visa inkl.moms", active: false},
    {title: "Visa beställningstidpunkt", active: false},
  ];
  private RTI = {SHOW_LINE: 0, SHOW_VAT: 1, SHOW_ORDER_TIME: 2};
  private paramSub: Subscription;
  private q: string;
  private chartType = "bar";
  private showSalesType;
  private dateType = "pay";

  constructor(private venueService: VenueService, private route: ActivatedRoute, private router: Router, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.isMobile = HackUtils.isMobile() && window.innerWidth <= 768;
    this.paramSub = combineLatest([
        this.route.paramMap,
        this.route.queryParamMap
      ]
    ).subscribe(([param, query]) => {
      this.parseQueryParams(query);
      this.venueId = Number(param.get("venue_id"));
      if (this.q !== "search") {
        this.fetch();
      }
    });
  }

  ngOnDestroy(): void {
    this.paramSub?.unsubscribe();
  }

  private fetch() {
    const query = this.buildQueryRequest();
    this.noData = false;
    this.missingData = false;
    this.venueService.queryTimeSeriesData(query).then(r => {
      //this.missingData = r.query_result.length > 99;
      this.noData = r.query_result.length === 0;
      console.log(r);
      this.buildChartOptions(r);
    }).catch(e => {
      SimpleDialogComponent.showErr(this.dialog, e);
      console.error(e);
    });
  }

  private buildChartOptions(r: QueryTimeSeriesDataResponse) {
    const categories = r.query_result.map(x => x.tumble);
    const series = [];
    const incVat = this.showSalesType === "inc_vat";
    series.push({name: "Sales", data: r.query_result.map(x => Math.round(incVat ? x.sales_inc_vat : x.sales))});
    // if (r.group_by_datekey) {
    //   const datekeyGroups = _.groupBy(r.hours, (row) => row.datekey);
    //   _.forOwn(datekeyGroups, (value, key) => {
    //     const serieSales = this.createSerie(categories, value, incVat);
    //     series.push({name: key, data: serieSales});
    //   });
    // } else {
    //   const serieSales = this.createSerie(categories, r.hours, incVat);
    //   series.push({name: "Sales", data: serieSales});
    // }

    const vatType = incVat ? "inkl. moms" : "ex. moms";
    const ot = this.dateType === "pay" ? "Försäljning" : "Beställning";
    const title = `${ot} per intervall (${vatType})`;

    this.chartOptions = {
      colors: ["#F89A81", "#F8C381", "#56839E", "#5CB181", "#FFDAD1", "#FFEAD1", "#BAD2E1", "#BDE7CF", "#FFBCAB", "#FFD9AB", "#84AAC0", "#8ACDA7", "#D26B50", "#D29850", "#386986", "#399661", "#AF482C", "#AF742C", "#215270", "#207D48"],
      xaxis: {categories},
      yaxis: {
        show: true,
        // title: {
        //   text: '$ (thousands)'
        // },
        labels: {
          formatter(val, index) {
            const v = HackUtils.thousandsSepNumber(val);
            return v;
          }
        }
      },
      series,
      chart: {
        height: 350,
        type: this.chartType,
        zoom: {
          enabled: false
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 200,
          animateGradually: {
            enabled: true,
            delay: 50
          },
          dynamicAnimation: {
            enabled: false,
            speed: 350
          }
        }

      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },
      title: { text: title, align: "left"},
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
    };
  }

  private buildQueryRequest(): QuerySalesDataRequest {
    const query = new QuerySalesDataRequest();
    query.venue_id = this.venueId.toString();
    if (this.searchStartDate != null) {
      query.start_date = Utils.dateToDateKey(this.searchStartDate);
    }
    if (this.searchEndDate != null) {
      query.end_date = Utils.dateToDateKey(this.searchEndDate);
    }
    if (this.startTime != null) {
      query.start_time = this.startTime;
    }
    if (this.endTime != null) {
      query.end_time = this.endTime;
    }
    if (this.interval != null) {
      query.interval = this.interval;
    }
    if (this.dateType != null) {
      query.date_type = this.dateType;
    }
    return query;
  }

  updateParams() {
    const queryParams: Params = {r: Utils.getRandomInt(500)};
    if (this.searchStartDate != null) {
      queryParams.start_date = Utils.dateToDateKey(this.searchStartDate);
    }
    if (this.searchEndDate != null) {
      queryParams.end_date = Utils.dateToDateKey(this.searchEndDate);
    }
    if (this.startTime != null) {
      queryParams.start_time = this.startTime;
    }
    if (this.endTime != null) {
      queryParams.end_time = this.endTime;
    }
    if (this.interval != null) {
      queryParams.interval = this.interval;
    }
    queryParams.sales_type = this.showSalesType;
    queryParams.chart_type = this.chartType;
    queryParams.date_type = this.dateType;
    this.router.navigate([], { relativeTo: this.route, queryParams });
  }

  private resetDefaultProps() {
    this.searchStartDate = null;
    this.searchEndDate = null;
    this.startTime = "06:00";
    this.endTime = "06:00";
    this.interval = 1800;
    this.showSalesType = "ex_vat";
  }

  private setupInitialParams(q: string) {
    switch (q) {
      case "today":
        this.searchStartDate = moment().toDate();
        break;
      case "yesterday":
        this.searchStartDate = moment().subtract(1, "day").toDate();
        break;
      case "search":
        break;
    }
  }

  private parseQueryParams(query: ParamMap) {
    this.resetDefaultProps();
    this.q = query.get("q");
    if (this.q) {
      this.setupInitialParams(this.q);
    } else {
      const sd = query.get("start_date");
      const ed = query.get("end_date");
      if (sd) {
        this.searchStartDate = Utils.dateKeyToMoment(sd).toDate();
      }
      if (ed) {
        this.searchEndDate = Utils.dateKeyToMoment(ed).toDate();
      }
      const d = query.get("sales_type");
      const salesType = d ?? "ex_vat";
      this.showSalesType = salesType;
      const ct = query.get("chart_type");
      this.chartType = ct === "line" ? "line" : "bar";
      const dt = query.get("date_type");
      this.dateType = dt === "order" ? "order" : "pay";
      const st = query.get("start_time");
      if (st) {
        this.startTime = st;
      }
      const et = query.get("end_time");
      if (et) {
        this.endTime = et;
      }
      const interval = query.get("interval");
      if (interval) {
        this.interval = interval != null ? parseInt(interval, 10) : undefined;
      }
    }

    this.hourlyOptions[this.RTI.SHOW_VAT].active = this.showSalesType === "inc_vat";
    this.hourlyOptions[this.RTI.SHOW_LINE].active = this.chartType === "line";
    this.hourlyOptions[this.RTI.SHOW_ORDER_TIME].active = this.dateType === "order";
  }

  selectedOption(item: any) {
    const ct = this.hourlyOptions[this.RTI.SHOW_LINE].active ? "line" : "bar";
    if (ct !== this.chartType) {
      this.chartType = ct;
      const copy = _.cloneDeep(this.chartOptions);
      copy.chart.type = ct;
      this.chartOptions = copy;
    } else {
      this.showSalesType = this.hourlyOptions[this.RTI.SHOW_VAT].active ? "inc_vat" : "ex_vat";
      this.dateType = this.hourlyOptions[this.RTI.SHOW_ORDER_TIME].active ? "order" : "pay";
      this.updateParams();
    }
    this.reportsMenuTrigger.closeMenu();
  }

}
