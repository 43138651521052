<div class="report-container">

  <app-title-bar [title]="title"></app-title-bar>

  <div class="search-fields-root">
    <div class="search-fields">
      <mat-form-field appearance="outline">
        <mat-label>Day or start date</mat-label>
        <input matInput [(ngModel)]="searchStartDate" [matDatepicker]="startPicker">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker [touchUi]="isMobile"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>End date</mat-label>
        <input matInput [(ngModel)]="searchEndDate" [matDatepicker]="endPicker">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker [touchUi]="isMobile"></mat-datepicker>
      </mat-form-field>
      <button mat-flat-button class="search-button" (click)="updateParams()" color="primary">SÖK</button>
      <button mat-stroked-button class="dropdown-button" [matMenuTriggerFor]="menuHandleSection" *ngIf="true"><mat-icon>expand_more</mat-icon></button>
      <mat-menu #menuHandleSection="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="menuExport">Exportera</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="clearFilters()">Rensa sökning</button>
      </mat-menu>
      <mat-menu #menuExport="matMenu">
        <button mat-menu-item (click)="export('csv')">CSV-format</button>
      </mat-menu>
    </div>
  </div>

  <div class="generic-mastdet">
    <div class="generic-master">
      <table mat-table [dataSource]="dataSource" matSort class="clicktable table-cells" matSortActive="pay_date" matSortDisableClear="true">

        <ng-container matColumnDef="rid">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>rid</th>
          <td mat-cell *matCellDef="let element">{{ element.rid }}</td>
        </ng-container>

        <ng-container matColumnDef="item">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>item</th>
          <td mat-cell *matCellDef="let element">{{ element.item }}</td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Artikel</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="price_vat">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Ink moms</th>
          <td mat-cell *matCellDef="let element"><b>{{ element.price_vat | moneySuff : null : "kr"  }}</b></td>
        </ng-container>

        <ng-container matColumnDef="discount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Rabatt</th>
          <td mat-cell *matCellDef="let element"><b>{{ element.discount | moneySuff : null : "kr"  }}</b></td>
        </ng-container>

        <ng-container matColumnDef="datekey">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>datum</th>
          <td mat-cell *matCellDef="let element">{{ element.datekey }}</td>
        </ng-container>

        <ng-container matColumnDef="device">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Enhet</th>
          <td mat-cell *matCellDef="let element">{{ element.device }}</td>
        </ng-container>

        <ng-container matColumnDef="discounter_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Rabatterad av</th>
          <td mat-cell *matCellDef="let element">{{ element.discounter_id }}</td>
        </ng-container>

        <ng-container matColumnDef="order_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Order</th>
          <td mat-cell *matCellDef="let element">{{ element.order_date | date:'medium'}}</td>
        </ng-container>

        <ng-container matColumnDef="pay_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Betalt</th>
          <td mat-cell *matCellDef="let element">{{ element.pay_date | date:'medium'}}</td>
        </ng-container>

        <ng-container matColumnDef="ic">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>ic</th>
          <td mat-cell *matCellDef="let element">{{ element.ic }}</td>
        </ng-container>

        <ng-container matColumnDef="receipt_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Kvitto</th>
          <td mat-cell *matCellDef="let element">{{ element.receipt_id }}</td>
        </ng-container>



        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)" [class.selected-row]="rid === row.rid"></tr>
      </table>

      <div class="no-data" *ngIf="noData">Inget att rapportera för vald period</div>
      <div class="no-data" *ngIf="initData">Välj period ovan för att söka</div>
    </div>
  </div>
</div>
