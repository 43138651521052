import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {VenueService} from "../../../services/venue.service";
import {LoadingService} from "../../../services/loading.service";
import {AuthService} from "../../../services/auth.service";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import { CreateVenueRequest, CreateVenueResponse } from 'src/app/models/venue';

@Component({
  selector: 'app-create-venue-dialog',
  templateUrl: './create-venue-dialog.component.html',
  styleUrls: ['./create-venue-dialog.component.css']
})
export class CreateVenueDialogComponent implements OnInit {
  dialogTitle?: string = "Skapa ny venue";

  form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email])
  });
  isCreatingVenue = false;

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<CreateVenueDialogComponent>, private auth: AuthService, private dialog: MatDialog) {
    this.dialogTitle = data.dialogTitle ?? this.dialogTitle;
  }

  ngOnInit(): void {
  }

  createNewVenue() {
    if (this.isCreatingVenue) {
      return;
    }

    this.isCreatingVenue = true;

    if (this.form.valid) {
      this.venueService.createVenue(this.form.value as CreateVenueRequest)
        .then(r => {
          console.log("Created new venue:", r as CreateVenueResponse);
          this.dialogRef.close({ venue_id: r.venue_id });
        })
        .catch(e => {
          SimpleDialogComponent.showErr(this.dialog, e);
        })
        .finally(() => {
          this.isCreatingVenue = false;
        });
    } else {
      this.isCreatingVenue = false;
    }
  }

  isHeynowAdmin() {
    return AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }

}
