<div>
  <p>
    <mat-toolbar>
      <span>Enheter</span>
      <span class="spacer"></span>
      <app-wiki-info-link title="Lär dig mer. Anmälan SKV." section="support" path="device"></app-wiki-info-link>
      <button mat-button (click)="addDevice()">
        <mat-icon>add_to_queue</mat-icon> Ny enhet
      </button>
    </mat-toolbar>
  </p>
  <mat-slide-toggle
  *ngIf="isSuperAdmin"
  style="margin-left: 20px"
  [checked]="showInactive | async"
  color="primary"
  (change)="showInactive.next($event.checked)">Visa inaktiva <mat-icon>admin_panel_settings</mat-icon>
</mat-slide-toggle>
</div>
<div style="padding: 16px;">
  <table mat-table [dataSource]="dataSource" matSort class="clicktable">

    <ng-container matColumnDef="identity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;max-width: 125px;font-size:12px;white-space: normal;word-wrap: break-word;"> {{element.identity | pnumber}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Namn </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Gruppnamn </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.last_name}} </td>
    </ng-container>

    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Skapad</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.created}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>E-post</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="regunit_date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Angiven<br>SKV-status</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.reg_unit?.state_sv}} </td>
    </ng-container>

    <ng-container matColumnDef="regunit_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tillverkningsnummer</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.reg_unit?.reg_name}} </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Roll <mat-icon style="transform: scale(0.75);">admin_panel_settings</mat-icon></th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.role}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef style="width:50px"></th>
      <td mat-cell *matCellDef="let element">
        <button mat-button [matMenuTriggerFor]="navigation">Åtgärd <mat-icon>arrow_drop_down</mat-icon></button>
        <mat-menu #navigation>
          <button mat-menu-item (click)="select(element)" *ngIf="hasPerm('admins')">Redigera</button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="register(element)">Anmäl enhet (SKV)</button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="duplicateDevice(element)">Skapa kopia</button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="revokeAccess(element)">Upphäv åtkomst</button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
