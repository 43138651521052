<div class="dash-container">
  <div class="d1">
    <div *ngIf="unitsNotRegistered.length > 0 && !isVault" class="open-panel">
      <div class="panel-title">Enheter</div>
      <div class="panel-body">
        <div>
          <p>⚠️ OBS! Skatteverket har förändrat sina registreringsrutiner för kassaregister och det är därför inte
            längre möjligt för tredjepartsleverantörer att registrera åt er.</p>
          <p>Det finns enheter som inte är anmälda till skatteverket!</p>
        </div>
        <div class="prow"><mat-icon>info</mat-icon>
          <div class="link">
            <div><a href="/venue/{{venueId}}/devices">Anmäl här</a></div>
          </div>
        </div>
      </div>
    </div>

    <canvas #canvas *ngIf="animateCoins && !isVault" style="position:absolute;background-color: #00000000;"></canvas>
    <div *ngIf="stats.loaded" class="open-panel">
      <div class="panel-title">Live info</div>
      <div class="panel-body">
        <div class="prow" [ngClass]="{'flash': flash}" *ngIf="!isVault"><mat-icon>paid</mat-icon>
          <div class="info">
            <div>{{stats.order | number}} kr</div>
            <div>försäljning idag</div>
          </div>
          <div class="info" style="margin-left: auto;font-weight: normal;color: #6a6a6a;margin-right: 32px;">
            <div> {{stats.order_exvat | number}} kr</div>
            <div style="color:#f0f0f0;">ex. moms</div>
          </div>
        </div>
        <div class="prow" [ngClass]="{'flash': flash}" *ngIf="!isVault"><mat-icon>point_of_sale</mat-icon>
          <div class="info">
            <div>{{stats.total_unpaid | number}} kr</div>
            <div><a style="color:grey;" href="#" (click)="showInfoAboutUnpaid($event);">Obetalt idag</a></div>
          </div>
          <div class="info" style="margin-left: auto;font-weight: normal;color: #6a6a6a;margin-right: 32px;">
            <div> {{stats.total_unpaid_exvat | number}} kr</div>
            <div style="color:#f0f0f0;">ex. moms</div>
          </div>
        </div>
        <hr>
        <div class="prow" [ngClass]="{'flash': flash}" *ngIf="!isVault"><mat-icon style="color:#f0f0f0;">point_of_sale</mat-icon>
          <div class="info">
            <div>{{stats.expected | number}} kr</div>
            <div>&nbsp;</div>
          </div>
          <div class="info" style="margin-left: auto;font-weight: normal;color: #6a6a6a;margin-right: 32px;">
            <div> {{stats.expected_exvat | number}} kr</div>
            <div>ex. moms</div>
          </div>
        </div>
        <div class="prow" [ngClass]="{'flash': flash}" *ngIf="!isVault"><mat-icon>mood</mat-icon>
          <div class="info">
            <div>{{stats.tip | number}} kr</div>
            <div>dricks idag</div>
          </div>
        </div>
        <div *ngIf="hasPerm('reports')" class="prow"><mat-icon>equalizer</mat-icon>
          <div class="link">
            <div><a style="color: rgb(49, 115, 112); font-weight: bold;" routerLink="/venue/{{venueId}}/xreport">Hämta
                x-rapport</a></div>
          </div>
        </div>
        <div class="prow" *ngIf="!isVault"><mat-icon>storefront</mat-icon>
          <div *ngIf="config.inhouse?.opened" class="open">{{config.venue.name}} öppet</div>
          <div *ngIf="!config.inhouse?.opened" class="closed">{{config.venue.name}} stängt</div>
        </div>
        <div *ngIf="config.takeaway && !isVault" class="prow"><mat-icon>takeout_dining</mat-icon>
          <div *ngIf="config.takeaway.opened && config.inhouse?.opened; else taclo" class="open">Takeaway öppet</div>
          <ng-template #taclo>
            <div class="closed">Takeaway stängt</div>
          </ng-template>
        </div>
        <div *ngIf="config.roomservice && !isVault" class="prow"><mat-icon>room_service</mat-icon>
          <div *ngIf="config.roomservice.opened && config.inhouse?.opened; else roclo" class="open">Roomservice öppet
          </div><ng-template #roclo>
            <div class="closed">Roomservice stängt</div>
          </ng-template>
        </div>
      </div>
    </div>

    <div *ngIf="stats.loaded && isVault" class="open-panel">
      <div class="panel-title">Kedja</div>
      <div class="panel-body">
        <div *ngFor="let v of config.chain.venues" class="prow"><mat-icon>storefront</mat-icon>
          <div class="link">
            <div><a href="/venue/{{v.venue_id}}/dashboard" *ngIf="isSuperAdmin; else noLinkName">{{v.name}}</a>
              <ng-template #noLinkName>{{v.name}}</ng-template></div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="stats.loaded && !isVault" class="open-panel">
      <div class="panel-title">Links</div>
      <div class="panel-body">
        <div *ngIf="config.facebook_name" class="prow"><mat-icon>chat_bubble_outline</mat-icon>
          <div class="link">
            <div><a href="https://m.me/{{config.facebook_name}}" target="_blank">Messenger</a></div>
          </div>
        </div>
        <div *ngFor="let ai of config.admin_info" class="prow"><mat-icon>description</mat-icon>
          <div class="link">
            <div><a href="{{ai.value}}" target="_blank">{{ai.name}}</a></div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="stats.loaded && !isVault" class="open-panel">
      <div class="panel-title">Contact/info</div>
      <div class="panel-body">
        <div *ngIf="config.official_contact_phone" class="prow"><mat-icon>phone</mat-icon>
          <div class="info">
            <div>{{config.official_contact_phone}}</div>
          </div>
        </div>
        <div *ngIf="config.official_contact_email" class="prow"><mat-icon>email</mat-icon>
          <div class="info">
            <div>{{config.official_contact_email}}</div>
          </div>
        </div>
        <hr>
        <div *ngIf="config.cash_register" class="prow"><mat-icon>business</mat-icon>
          <div class="info">
            <div>{{config.cash_register.bus_name}}</div>
            <div>{{config.cash_register.org_number}}</div>
          </div>
        </div>
        <div *ngIf="config.cash_register" class="prow"><mat-icon>public</mat-icon>
          <div class="info">
            <div>{{config.cash_register.street}}</div>
            <div>{{config.cash_register.postal_code}} {{config.cash_register.city}}</div>
          </div>
        </div>
        <div *ngIf="config.cash_register" class="prow"><mat-icon>local_police</mat-icon>
          <div class="info">
            <div>{{config.cash_register.ccu}}</div>
            <div>{{config.cash_register.type}}</div>
          </div>
        </div>
        <div class="prow"><mat-icon>logout</mat-icon>
          <div class="info">
            <div>201901-{{bamboraCode()}}</div>
            <div>Terminalprocessid</div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="stats.loaded && !isVault" class="tool-bar">
      <mat-slide-toggle [(ngModel)]="animateCoins" (ngModelChange)="toggleActive()" color="primary">Animate coins</mat-slide-toggle>
    </div>
    <div *ngIf="isSuperAdmin" class="tool-bar">
      <mat-slide-toggle [(ngModel)]="showNewsForSuperAdmin" (ngModelChange)="toggleShowNews()" color="primary">Show news<mat-icon>admin_panel_settings</mat-icon></mat-slide-toggle>
    </div>
  </div>

  <div class="d2news" *ngIf="stats.loaded && (!isSuperAdmin || showNewsForSuperAdmin)">
    <app-wiki-display [section]="'news'" [path]="'dashboard'" [style]="'wiki-doc'" [title]="'Nyheter'"></app-wiki-display>
  </div>

  <div class="d2" *ngIf="isSuperAdmin">
    <app-ticket></app-ticket>
  </div>

  <div class="d3" *ngIf="isSuperAdmin && !isVault">
    <div class="open-panel">
      <div class="panel-title">Inställningar</div>
      <div class="panel-body">
        <div *ngFor="let service of foundServices">
        <b>{{ formatText(service.name) }}: </b>{{ formatText(service.status) }}
        </div>
      </div>
    </div>
    <app-wiki-display [section]="'notes'" [path]="venueId?.toString()" [style]="'wiki-doc'" [preview]="true" [target]="'heynow-wiki'" [title]="'Anteckningar'"></app-wiki-display>
  </div>


</div>
