export const pageVoucherSettings = {
  title: { sv: "Voucher" },
  page_settings: {
    root_path: "voucher"
  },
  desc: { sv: "Inställningar för Voucher" },
  properties: [
    { key: "station.stations", title: { sv: "Stationer" }, desc: { sv: "" }, type: "table", convert_from_tree: "station.stations",
      columns: [
        { key: "name", title: { sv: "Namn" }, type: "string", width: 150 },
        { key: "id", title: { sv: "ID" }, type: "string", width: 150 },
        { key: "type", title: { sv: "Typ" }, type: "enum", width: 150, optional: true, source: ["print", "entrance"] },
      ]
    },
    { key: "station.active_hours", title: { sv: "Öppettider för stationer" }, desc: { sv: "Tidformat exempel 12 = 12:00, 12.5 = 12:30, 12.75 = 12:45 etc. Dagformat mån = 0, tis = 1 ... sön = 6" }, type: "table", convert_from_tree: "station.active_hours",
      columns: [
        { key: "day", title: { sv: "Dag" }, type: "integer[]", width: 150, },
        { key: "start", title: { sv: "Öppning" }, type: "integer", width: 150, },
        { key: "stop", title: { sv: "Stängning" }, type: "integer", width: 150, },
      ]
    },
    { key: "closed.filter", title: { sv: "Voucher filter" }, desc: { sv: "Ex. tag=voucher" }, type: "string" },
    { key: "type", title: { sv: "Skapa typ/ filter" }, desc: { sv: "" }, type: "table", optional: true,
      columns: [
        { key: "filter", title: { sv: "Filter" }, type: "string", width: 200 },
        { key: "type", title: { sv: "Typ" }, type: "string", width: 200 },
      ]
    },
    { key: "mobile.active", title: { sv: "Mobil aktiv" }, desc: { sv: "" }, type: "bool", optional: true },
    { key: "presentation", title: { sv: "Presentation" }, desc: { sv: "" }, type: "table", optional: true,
      columns: [
        { key: "filter", title: { sv: "Filter" }, type: "string", width: 200 },
        { key: "match", title: { sv: "Matchning" }, type: "enum", width: 150, source: ["max_one","*"] },
        { key: "type", title: { sv: "Typ" }, type: "enum", width: 150, source: ["mobile","qr","bump"] },
      ]
    },
    { key: "layout", title: { sv: "Layout" }, desc: { sv: "" }, type: "table", optional: true,
      columns: [
        { key: "filter", title: { sv: "Filter" }, type: "string", width: 200 },
        { key: "logo", title: { sv: "Logo" }, type: "string", width: 300 },
      ]
    },
    { key: "item_group_filter", title: { sv: "Artikelgrupp filter" }, desc: { sv: "" }, type: "string", optional: true, },
    { key: "voucher_group_by", title: { sv: "Gruppera vouchers" }, desc: { sv: "Ex. Filter: tag=kött" }, type: "table", optional: true,
      columns: [
        { key: "filter", title: { sv: "Filter" }, type: "string", width: 200 },
        { key: "text", title: { sv: "Text" }, type: "string", width: 300 },
      ]
    },
  ]
};
