<h2 mat-dialog-title>Add: {{email}}</h2>
<div mat-dialog-content style="position: relative;">
  <div>
    <mat-form-field class="indialog">
      <mat-label>First name</mat-label>
      <input matInput [formControl]="firstName" required>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="indialog">
      <mat-label>Last name</mat-label>
      <input matInput [formControl]="lastName" required>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="indialog">
      <mat-label>Role</mat-label>
      <mat-select [formControl]="role" required>
        <mat-option value="owner" *ngIf="isHeynowAdmin()">Owner</mat-option>
        <mat-option value="admin">Admin</mat-option>
        <mat-option value="reader">Reader</mat-option>
        <mat-option value="editor">Editor</mat-option>
        <mat-option value="restricted_editor">Restricted editor</mat-option>
        <mat-option value="finance">Finance</mat-option>
        <mat-option value="student">Student</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="loadingService.visibility | async"></mat-spinner>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="addUser()">Add</button>
</mat-dialog-actions>
