export class PublishMenuRow {
  tcs: string;
  id: string;
  ic: string;
  hidden: boolean;
  name: string;
  price: any;
  desc: string;
  adjust: string;
  attributes: string;
  push: string;
  // mjölkprot	laktos	ägg	gluten	vete	jordnöt	kräftdjur	blötdjur	skaldjur	fisk	nötter	sesam	selleri	senap	lupin	soya	sulfit	fläsk	ananas	stenfrukt	gelatin	soyaböna	citrus	onion	garlic
  allergenes: PublishMenuAllergene[];
  printer: string;
  //roomservice	takeaway	pickup	main	sides	event	bongorder	use_scale
  //
  bong_order: number;

  prop: string;

  index: number;

  prices?: PublishMenuRowPrice[];
  tags?: string[];
}

export class PublishMenuAllergene {
  constructor(key: string, value: any) {
    this.key = key;
    this.value = value;
  }

  key: string;
  value: string;
}

export class PublishMenuRowPrice {
  constructor(name: string, price: number) {
    this.name = name;
    this.price = price;
  }
  name: string;
  price: number;
}

export class PublishMenuTab {
  name: string;
  id: string;
  rows: PublishMenuRow[];
  image?: string;
}

export class PublishRequest {
  name: string;
  tabs: PublishMenuTab[];
  options: PublishMenuTab;
  pushpages: PublishMenuTab;
}

export interface PublishResponse {


  tabs: PublishMenuTab[];
}

export class AlreadyPublishedError extends Error {}
export class HasChangesError extends Error {}

export class ValidationError extends Error {
  public tab: string;
  public row: string;

  constructor(message, tab, row) {
    super(message);
    this.tab = tab;
    this.row = row;
    this.name = `ValidationError at tab: ${tab} row: ${row}`;
    //console.log(this.tab);
  }
}

export interface Option {
  id: string;
  name: string;
  desc: string;
  relPrice: string;
  fixPrice: number;
  allergenes: any[];
}

export interface PushPage {
  id: string;
  name: string;
}

export interface Item {
  id: string;
  name: string;
}
