export const pageCaspecoSettings = {
  title: { sv: "Caspeco" },
  page_settings: {
    root_path: "caspeco",
  },
  desc: "Inställningar för Caspeco",
  properties: [
    { key: "system_name", title: { sv: "Systemnamn" }, desc: { sv: "" }, type: "string" },
    { key: "computer_name", title: { sv: "Datornamn" }, desc: { sv: "" }, type: "string" },
    { key: "guid", title: { sv: "GUID" }, desc: { sv: "Ange GUID" }, type: "string" },
    { key: "vendor_name", title: { sv: "Leverantörsnamn" }, desc: { sv: "Ange leverantörsnamn" }, type: "string" },
    { key: "secret", title: { sv: "Hemlig nyckel" }, desc: { sv: "" }, type: "string" },
    { key: "secret_rev", title: { sv: "Dold revision" }, desc: { sv: "" }, type: "integer" },
    { key: "report_classes", title: { sv: "Rapportklasser" }, desc: { sv: "" }, type: "table",
      columns: [
        { key: "ic", title: { sv: "IC" }, type: "string", width: 200 },
        { key: "name", title: { sv: "Namn" }, type: "string", width: 200 },
      ]
    },
  ]
};
