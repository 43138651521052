export const pageMenuSettings = {
  title: {sv: "Menyinställningar"},
  page_settings: {
    root_path: "menu"
  },
  desc: {sv: "Ställ in kontoplan"},
  properties: [
    // {key: "allow_reorder", title: {sv: "Tillåt gäster att byta fas"}, desc: {sv: "(Fungerar ej)"}, type: "bool"},
    {key: "use_translations", title: {sv: "Auto-översättning för selfservice"}, desc: {sv: "Ger gäster möjlighet välja språk i appen, automatiskt översättning av menyn."}, type: "bool", optional: true, hiddenForChain: true},
    {key: "show_overview", title: {sv: "Visa veckoöversikt"}, desc: {sv: "Ger gäster möjlighet att få upp en veckoöversikt"}, type: "bool", hiddenForChain: true},
    {key: "start_tab", title: {sv: "Start tab"}, desc: {sv: "Vilken tab i menyn skall gästerna få upp först"}, type: "string", source: ["food", "drinks", "shop"], optional: true, hiddenForChain: true},
    {key: "editor_tags", title: {sv: "Taggar för menyredigering"}, desc: {sv: "Dessa taggar visas när du redigerar menyn, och medför att det går att tagga artiklar i menyn. Taggar kan användas för att styra workflow, rabatter och andra filtreringar."}, type: "table", hiddenForChain: true, optional: true,
      columns: [
        {key: "tag", title: {sv: "Tag"}, type: "string", width: 100 },
        {key: "desc", title: {sv: "Beskrivning"}, type: "string", width: 300 },
      ]
    },
    {key: "menu_filter_tags", title: {sv: "Filtrera artiklar"}, desc: {sv: "Filtrera vilka artiklar som skall synas utifrån var gästen är lokerad, ange vilken tag som skall visas utifrån bord eller area."}, type: "table", hiddenForChain: true,
      columns: [
        {key: "area", title: {sv: "Area"}, type: "string", width: 200 },
        {key: "table", title: {sv: "Bord"}, type: "string", width: 200 },
        {key: "tag", title: {sv: "Tag"}, type: "string", width: 200 },
      ]
    },
    {key: "price_list", title: {sv: "Prislistor"}, desc: {sv: "Prislistorna visas i menyredigeringen och går att koppla till specifika kunder."}, type: "table",
      columns: [
        {key: "name", title: {sv: "Namn"}, type: "string", width: 200 },
        {key: "desc", title: {sv: "Beskrivning"}, type: "string", width: 300 },
      ]
    },
    {key: "price_schedule", title: {sv: "Prisschema"}, type: "table", optional: true, hiddenForChain: true,
      columns: [
        {key: "active", title: {sv: "Aktiverad"}, type: "bool" },
        {key: "name", title: {sv: "Namn"}, type: "string" },
        {key: "when", title: {sv: "Tidsperiod"}, type: "string" },
        {key: "discount_program", title: {sv: "Rabattprogram"}, type: "config_enum", optional: true, config_path: "discount_programs", config_key: "id" },
        // TODO: Uncomment when price_list implementation is done on backend
        // {key: "price_list", title: {sv: "Prislista"}, type: "config_enum", optional: true, config_path: "menu.price_list", config_key: "name" },
        {key: "desc", title: {sv: "Beskrivning"}, type: "string", optional: true },
      ],
    },
  ]
};
