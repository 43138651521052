import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {MediaObserver} from '@angular/flex-layout';
import {AuthService} from 'src/app/services/auth.service';
import {LoadingService} from 'src/app/services/loading.service';
import {AuthState} from "../../models/signin";
import {HttpClient} from "@angular/common/http";
import * as _ from "lodash";
import {environment} from "../../../environments/environment";
import {HeywikiService} from "../../services/heywiki.service";
import {from, of} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.css']
})
export class MainContentComponent implements OnInit {
  private authState: AuthState;

  constructor(private observableMedia: MediaObserver, public authService: AuthService, public loadingService: LoadingService,
              protected http: HttpClient, private ngZone: NgZone, private heywiki: HeywikiService) {
  }

  @ViewChild('sideNav', {read: MatSidenav}) sideNav: MatSidenav;
  //public nav: MatSidenav = this.sideNav;
  navMode = 'side';

  public appitems: Array<any> = [];

  config = {
    paddingAtStart: true,
    interfaceWithRoute: true,
    selectedListFontColor: "#317370",
    highlightOnSelect: true,
    collapseOnSelect: true,
    useDividers: true,
    rtlLayout: false
  };

  ngOnInit() {
    //this.setupNavigation();

    this.authService.currentAuthState().subscribe(auth => {
      this.authState = auth;
      this.setupNavigation();
    });

    if (this.observableMedia.isActive('xs') || this.observableMedia.isActive('sm')) {
      this.navMode = 'over';
    }

    this.observableMedia.asObservable()
      .subscribe(change => {
        if (change.some(c => c.mqAlias === "xs" || c.mqAlias === "sm")) {
          this.navMode = 'over';
          this.sideNav.close();
        } else {
          this.navMode = 'side';
          this.sideNav.open();
        }
      });
  }

  private async setupNavigation() {
    const menu = [];

    const isSuperAdmin = AuthService.isSuperAdmin(this.authState);
    if (isSuperAdmin) {
      menu.push(
        {
          label: "Support",
          icon: 'support_agent',
          items: [
          {
            label: 'Venues',
            link: '/venues',
            icon: 'corporate_fare',
            activeIcon: 'corporate_fare'
          },
          {
            label: 'Clients',
            link: '/clients',
            icon: 'tablet_mac',
            activeIcon: 'tablet_mac'
          },
          {
            label: 'Venue admins',
            link: '/admin-accounts',
            icon: 'people',
            activeIcon: 'people'
          },
        ]
        }
      );

      menu.push(
        {
          label: "Admin",
          icon: 'settings',
          items: [
          {
            label: 'Superadmins',
            link: '/admins',
            icon: 'people',
            activeIcon: 'people'
          },
          {
            label: 'Superenheter',
            link: '/admin-devices',
            icon: 'important_devices',
            activeIcon: 'important_devices'
          },
          {
            label: 'Ärenden',
            link: '/mini-tickets',
            icon: 'task',
            activeIcon: 'task'
          },
          {
            label: 'Tickets',
            link: '/tickets',
            icon: 'email',
            activeIcon: 'email'
          },
          {
            label: 'Receipt explorer',
            link: '/receipt-explorer',
            icon: 'explore',
            activeIcon: 'explore'
          },
          {
            label: "Billing",
            icon: 'local_atm',
            items: [
              {
                label: 'Create invoices',
                link: '/billing',
                icon: 'add_circle',
                activeIcon: 'add_circle'
              },
              {
                label: 'Invoices',
                link: '/invoices',
                icon: 'money',
                activeIcon: 'money'
              },
            ]
          },
          {
            label: 'KPI',
            link: '/kpi/dashboard',
            icon: 'insights',
            activeIcon: 'insights'
          },
          {
            label: 'API Tokens',
            link: '/tokens',
            icon: 'token',
            activeIcon: 'token'
          },
          {
            label: 'Venue messages',
            link: '/venue-messages',
            icon: 'report',
            activeIcon: 'report'
          },
          {
            label: 'Registreringsenheter',
            link: '/regunit-list',
            icon: 'military_tech',
            activeIcon: 'military_tech'
          },
          {
            label: 'Fb bots',
            link: '/fb-bots',
            icon: 'smart_toy',
            activeIcon: 'smart_toy'
          },
          {
            label: 'Kedjor',
            link: '/chains',
            icon: 'business',
            activeIcon: 'business'
          },
          {
            label: 'Email editor',
            link: '/email-editor',
            icon: 'send',
            activeIcon: 'send'
          },
          {
            label: 'Subscribers',
            link: '/subscriber-list/newsletter',
            icon: 'contacts',
            activeIcon: 'contacts'
          },
          {
            label: 'Wiki DB',
            link: '/wiki-db',
            icon: 'folder',
            activeIcon: 'folder'
          },
        ]
        }
      );
    } else {
      const venues = AuthService.getUserVenues(this.authState);
      for (const venue of venues) {
        const icon = venue.chain_key == null ? 'storefront' : 'business';
        menu.push(
          {
            label: venue.name,
            icon,
            link: `/venue/${venue.id}/dashboard`,
            activeIcon: icon
          }
        );
      }
    }

    // Add KPI for investors
    if (AuthService.isInvestor(this.authState)) {
      menu.push(
        {
          label: 'KPI',
          link: '/kpi/dashboard',
          icon: 'insights',
          activeIcon: 'insights'
        }
      );
    }

    // Add logout
    menu.push(
      {
        label: "Account",
        icon: 'account_circle',
        items: [
          {
            label: "My account",
            icon: 'account_box',
            link: `/account`,
          },
          {
            label: "Sign out",
            icon: 'exit_to_app',
            onSelected: () => {
              this.authService.logout();
            }
          }
        ]
      }
    );

    const support_items = await this.fetchHTMLAndParse("support", "menu");
    if (AuthService.isSuperAdmin(this.authState)) {
      const items = await this.fetchHTMLAndParse("intern-support-wiki", "menu");
      const d = {
        label: "Hjälp",
        icon: 'help',
        items: [
          {
            label: "Support",
            icon: 'article',
            items: support_items,
          },
          {
            label: "Intern Support",
            icon: 'article',
            items,
          },
          {
            label: "Intern bildbank",
            icon: 'collections',
            link: `/wiki-images`,
          },
        ]
      };
      menu.push(d);
    } else {
      const d = {
        label: "Support",
        icon: 'help',
        items: support_items,
      };
      menu.push(d);
    }

    this.appitems = menu;
  }

  private loadHTML(section, path) {
    if (!environment.is_live) {
      return this.heywiki.fetchDoc(section, path).pipe(map(doc => doc?.html), catchError(err => of(""))).toPromise();
    }
    return this.http.get(`https://storage.googleapis.com/heynowdocs/${section}/${path}.html`, { responseType: 'text' }).toPromise().catch(error => null);
  }

  private async fetchHTMLAndParse(section, path) {
    const html = await this.loadHTML(section, path);
    if (html == null) { return; }
    const parts = html.split("<p>");
    const items = [];
    for (const part of parts) {
      if (part.trim().length === 0) {
        continue;
      }
      const subParts = part.split("\n");
      const title = subParts[0];
      const links = subParts.slice(1);

      const linkItems = [];
      for (const link of links) {
        const match = link.match(/<a href="([^"]+)">([^<]+)<\/a>/);
        if (match) {
          const href = match[1];
          const text = match[2];
          linkItems.push({
            label: text,
            icon: 'article',
            link: href,
          });
        }
      }
      const partItem = {
        label: title,
        icon: 'article',
        items: linkItems
      };
      items.push(partItem);
    }

    return items;
  }

  private async expand(section, path, index) {
    const items = await this.fetchHTMLAndParse(section, path);
    this.ngZone.run(() => {
      const t = _.cloneDeep(this.appitems);
      t[index].items[0].items = items;
      t[index].items[0].expanded = true;
      t[index].expanded = true;
      this.appitems = t;
    });
  }
}
