<div>
  <p>
    <mat-toolbar>
      <span>Superenheter (byta venue på enhet)</span>
      <span class="spacer"></span>
      <!-- <app-wiki-info-link title="Lär dig mer. Superdevice." section="support" path="super-device"></app-wiki-info-link> -->
      <button mat-button (click)="addDevice()">
        <mat-icon>add</mat-icon> Ny device
      </button>
    </mat-toolbar>
  </p>
</div>
<div style="padding: 16px;">
  <table mat-table [dataSource]="dataSource" matSort matSortDisableClear="true" class="clicktable">

    <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Key </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.key}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Namn </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Efternamn </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.last_name}} </td>
    </ng-container>

    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Skapad</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px; white-space: nowrap;"> {{element.created | date: "yyyy-MM-dd hh:mm"}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>E-post</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Roll</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.role}} </td>
    </ng-container>

    <ng-container matColumnDef="venue_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Venue</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.venue_id}} </td>
    </ng-container>

    <ng-container matColumnDef="venue_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Venue</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.venue_name}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef style="width:50px"></th>
      <td mat-cell *matCellDef="let element">
        <button mat-button [matMenuTriggerFor]="navigation">Åtgärd <mat-icon>arrow_drop_down</mat-icon></button>
        <mat-menu #navigation>
          <button mat-menu-item (click)="changeVenue(element)">Byt Venue</button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="editDevice(element)">Redigera</button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="duplicateDevice(element)">Skapa kopia</button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="revokeAccess(element)">Upphäv åtkomst</button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
