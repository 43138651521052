<h2 mat-dialog-title>Välj roll: {{email}}</h2>
<div mat-dialog-content style="position: relative;">
  <div>
    <mat-form-field class="indialog">
      <mat-label>Roll</mat-label>
      <mat-select [formControl]="role" required>
        <mat-option value="owner" *ngIf="isHeynowAdmin()">Owner</mat-option>
        <mat-option value="admin">Admin</mat-option>
        <mat-option value="reader">Reader</mat-option>
        <mat-option value="editor">Editor</mat-option>
        <mat-option value="restricted_editor">Restricted editor</mat-option>
        <mat-option value="finance">Finance</mat-option>
        <mat-option value="student">Student</mat-option>
        <mat-option value="-remove-">[Ta bort roll]</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-spinner style="position: absolute; top: calc(50% - 15px); left: calc(50% - 15px);" [diameter]="30" *ngIf="loadingService.visibility | async"></mat-spinner>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Avbryt</button>
  <button mat-button (click)="update()">Spara</button>
</mat-dialog-actions>
