import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, map, switchMap, tap } from 'rxjs';
import { VenueConfig } from 'src/app/models/venue-config';
import { FireService } from 'src/app/services/fire.service';
import { MultilevelMenuService, ExpandCollapseStatusEnum, MultilevelNodes } from 'ng-material-multilevel-menu';

@Component({
  selector: 'app-ic-tree-view',
  templateUrl: './ic-tree-view.component.html',
  styleUrls: ['./ic-tree-view.component.css']
})
export class IcTreeViewComponent implements OnInit, OnDestroy {

  private venueId: number;
  private venueConfig: VenueConfig;
  private sub: Subscription;

  icTree: MultilevelNodes[] = [];
  config = {
    paddingAtStart: true,
    interfaceWithRoute: false,
    highlightOnSelect: true,
    useDividers: false,
    collapseOnSelect: false,
    rtlLayout: false,
    classname: 'multilevel-tree',
    selectedListFontColor: "#317370",
  };
  expandCollapseStatus: ExpandCollapseStatusEnum = ExpandCollapseStatusEnum.collapse;
  reporting: any[];

  constructor(private route: ActivatedRoute, protected http: HttpClient, private fire: FireService, private multilevelMenuService: MultilevelMenuService) { }

  ngOnInit() {
    this.sub = this.route.paramMap.pipe(
      map(params => Number(params.get('venue_id'))),
      tap(venueId => this.venueId = venueId),
      switchMap(venueId => this.fire.observeVenueConfig(venueId)),
    ).subscribe(vc => {
      this.venueConfig = vc as VenueConfig;
      this.reporting = this.venueConfig.reporting?.groups?.map(r => ({ class: r.class, account: r.account })) || [];
      this.icTree = this.transformIcToTree(this.venueConfig.ic);
      console.log("Transformed ic-tree", this.icTree);
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private transformIcToTree(ic: any, inheritedAccount?: string): MultilevelNodes[] {
    if (!ic) { return []; }
    const priorityOrder = ['drink', 'food'];

    const res: MultilevelNodes[] = [];
    for (const [key, value] of Object.entries(ic)) {
      const match = this.reporting?.find(r => r.class === key);
      const account = match?.account || inheritedAccount;
      const combinedLabel = account ? `${key} (${account})`.toLowerCase() : key.toLowerCase();
      const item: MultilevelNodes = {
        label: combinedLabel,
        icon: match ? 'done' : 'no-icon',
        items: []
      };

      if (typeof value === 'object') {
        item.items = this.transformIcToTree(value, account);
      }
      res.push(item);
    }

    return res.sort((a, b) => {
      const priorityA = priorityOrder.indexOf(a.label.split(' ')[0]);
      const priorityB = priorityOrder.indexOf(b.label.split(' ')[0]);
      if (priorityA === priorityB) {
        return a.label.localeCompare(b.label);
      }
      return (priorityA !== -1 ? priorityA : priorityOrder.length) - (priorityB !== -1 ? priorityB : priorityOrder.length);
    });
  }

  toggleExpandCollapse() {
    console.log("Toggling expand/collapse status", this.expandCollapseStatus);
    if (this.expandCollapseStatus === ExpandCollapseStatusEnum.expand) {
      this.expandCollapseStatus = ExpandCollapseStatusEnum.collapse;
      this.setExpandCollapseStatus(ExpandCollapseStatusEnum.expand);
      this.setExpandCollapseStatus(ExpandCollapseStatusEnum.collapse);
    } else if (this.expandCollapseStatus === ExpandCollapseStatusEnum.collapse) {
      this.expandCollapseStatus = ExpandCollapseStatusEnum.expand;
      this.setExpandCollapseStatus(ExpandCollapseStatusEnum.collapse);
      this.setExpandCollapseStatus(ExpandCollapseStatusEnum.expand);
    }

  }

  private setExpandCollapseStatus(type: ExpandCollapseStatusEnum) {
    console.log("Setting expand/collapse status", type);
    this.multilevelMenuService.setMenuExapandCollpaseStatus(type);
}

  selectedItem(event) {
    console.log("Selected item", event);
  }

  menuIsReady(event) {
    console.log("Menu is ready", event);
  }

  selectedLabel(event) {
    console.log("Selected label", event);
  }

}
