export const pagePOSSettings = {
  title: { sv: "POS" },
  page_settings: {
    root_path: "pos"
  },
  desc: { sv: "Inställningar för POS" },
  properties: [
    { key: "pos_mode", title: { sv: "POS-läge" }, desc: { sv: "" }, type: "string", optional: true },
    { key: "pos_list", title: { sv: "POS-lista" }, desc: { sv: "" }, type: "table",
      columns: [
        { key: "table", title: { sv: "Bord" }, type: "string" },
        { key: "area", title: { sv: "Area" }, type: "string", optional: true },
        { key: "display_name", title: { sv: "Visningsnamn" }, type: "string" },
        { key: "email_filter", title: { sv: "E-postfilter" }, type: "string" },
        { key: "stations", title: { sv: "Stationer" }, type: "string", optional: true },
      ]
    },
  ]
};
