<div [class]="{'step-panel':step.active, 'step-panel-inactive':!step.active}">
  <div [class]="{'step-header':step.active, 'step-header-inactive':!step.active}">{{step.index+1}}. {{titleMap[step.action]}} {{step.active?"":"(Körs ej - inaktivt steg)"}}
    <span class="active-panel">
      <button mat-icon-button [matMenuTriggerFor]="menuSave" class="header-but"><mat-icon class="header-icon">menu</mat-icon></button>
    </span>
  </div>
  <div class="step-body">
    <app-data-form #dataform [schema]="dataFormSchema" [root]="step"></app-data-form>
  </div>

  <mat-menu #menuSave="matMenu">
    <button mat-menu-item (click)="setActive(true)" *ngIf="!step.active">Aktivera</button>
    <button mat-menu-item (click)="setActive(false)" *ngIf="step.active">Inaktivera</button>
    <button mat-menu-item (click)="move(true)">Flytta upp</button>
    <button mat-menu-item (click)="move(false)">Flytta ner</button>
    <button mat-menu-item (click)="copy()">Skapa kopia</button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="delete()">Ta bort</button>
  </mat-menu>

</div>

<!--<mat-checkbox color="primary" [(ngModel)]="active">Aktiverad</mat-checkbox>-->
