<div class="events-container">

  <app-title-bar [title]="title"></app-title-bar>

  <div class="search-fields">
    <mat-form-field appearance="outline">
      <mat-label>Day or start date</mat-label>
      <input matInput [(ngModel)]="searchStartDate" [matDatepicker]="startPicker">
      <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
      <mat-datepicker #startPicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>End date</mat-label>
      <input matInput [(ngModel)]="searchEndDate" [matDatepicker]="endPicker">
      <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
      <mat-datepicker #endPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Personal</mat-label>
      <mat-select [(value)]="staffId">
        <mat-option [value]="null">Ingen</mat-option>
        <mat-divider></mat-divider>
        <mat-option *ngFor="let s of staff" [value]="s.identity">
          {{s.first_name}} {{s.last_name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button mat-flat-button class="search-button" (click)="newQuery()" color="primary">SÖK</button>
    <button mat-stroked-button class="dropdown-button" [matMenuTriggerFor]="menuHandleSection"
      *ngIf="true"><mat-icon>expand_more</mat-icon></button>
    <mat-menu #menuHandleSection="matMenu">
      <button mat-menu-item (click)="clearFilters()">Rensa sökning</button>
    </mat-menu>
  </div>

  <div class="generic-mastdet">
    <div class="generic-master">
      <table mat-table [dataSource]="dataSource" matSort class="clicktable table-cells" (matSortChange)="sortChanged()"
        matSortActive="date" matSortDirection="desc" matSortDisableClear="true">

        <ng-container matColumnDef="staff_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Personal</th>
          <td mat-cell *matCellDef="let element">{{ element.staff_name }}</td>
        </ng-container>

        <ng-container matColumnDef="staff_identity">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Personal Id</th>
          <td mat-cell *matCellDef="let element">{{ element.staff_identity }}</td>
        </ng-container>

        <ng-container matColumnDef="device_user_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Enhet</th>
          <td mat-cell *matCellDef="let element">{{ element.device_user_name }}</td>
        </ng-container>

        <ng-container matColumnDef="data_json">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Order</th>
          <td mat-cell *matCellDef="let element" [ngStyle]="{'max-width' : '100px', 'word-wrap':'break-word'}">{{ element.data_json }}</td>
        </ng-container>

        <ng-container matColumnDef="table">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Bord</th>
          <td mat-cell *matCellDef="let element">{{ element.table }}</td>
        </ng-container>

        <ng-container matColumnDef="device_user_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Enhet Id</th>
          <td mat-cell *matCellDef="let element">{{ element.device_user_id }}</td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Händelse</th>
          <td mat-cell *matCellDef="let element">{{ element.action }}</td>
        </ng-container>

        <ng-container matColumnDef="desc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Beskrivning</th>
          <td mat-cell *matCellDef="let element">{{ element.desc }}</td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
          <td mat-cell *matCellDef="let element">{{ element.date |date:'medium' }} </td>
        </ng-container>

        <ng-container matColumnDef="amount_cent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Belopp</th>
          <td mat-cell *matCellDef="let element">{{ element.amount_cent | moneySuff: 'cent'}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; let i = index columns: displayedColumns;" (click)="select(row, i)"
            [class.selected-row]="i === rowIndex"></tr>
      </table>

      <mat-paginator *ngIf="dataSource" [length]="getTotalSize()" [pageSize]="30"
        [pageSizeOptions]="[15, 30, 50, 100, 500, 2000]" (page)="updateEvents()">
      </mat-paginator>
    </div>

    <div *ngIf="jsonData">
      <div class="side-panel">
        <div class="toolbar">
          <span><mat-icon>event</mat-icon></span>
          <span style="font-weight: bold;">{{selectedRowData.staff_name}}</span>
          <button mat-button (click)="closeDetailsPanel()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
        <div style="margin-bottom: 8px; word-wrap: break-word;">
          <ngx-json-viewer *ngIf="jsonData" [json]="jsonData"></ngx-json-viewer>
        </div>
      </div>
    </div>

  </div>
</div>
