import { Component, OnInit } from '@angular/core';
import {environment} from "../../../../environments/environment";
import {User} from "../../../models/user";
import {ActivatedRoute} from "@angular/router";
import {HeywikiService} from "../../../services/heywiki.service";
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-wiki-doc-display',
  templateUrl: './wiki-doc-display.component.html',
  styleUrls: ['./wiki-doc-display.component.css']
})
export class WikiDocDisplayComponent implements OnInit {
  section: string;
  path: string;
  private user: User;
  isProd: boolean;
  title: string;

  constructor(private route: ActivatedRoute, private heywiki: HeywikiService, public authService: AuthService) { }

  ngOnInit(): void {
    this.isProd = environment.is_live;
    this.route.paramMap.subscribe(data => {
      this.section = data.get("section");
      this.path = data.get("path");
      // Capitalize the first letter of the title
      this.title = this.section.charAt(0).toUpperCase() + this.section.slice(1);
    });
    this.authService.getLoggedInAuthState().subscribe(auth => {
      this.user = auth.user;
    });
  }

}
