import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { VenueService } from "../../../services/venue.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, MatSortable } from "@angular/material/sort";
import { Device } from "../../../models/reg-unit";
import { SimpleDialogComponent } from "../../simple-dialog/simple-dialog.component";
import { VenuePickerDialogComponent } from '../../dialogs/venue-picker-dialog/venue-picker-dialog.component';
import { HackUtils } from 'src/app/utils/utils';
import { VenueMapService } from 'src/app/services/venue-map.service';

@Component({
  selector: 'app-super-device-list',
  templateUrl: './super-device-list.component.html',
  styleUrls: ['./super-device-list.component.css']
})
export class SuperDeviceListComponent implements OnInit, OnDestroy {

  private deviceSub: Subscription;

  constructor(public dialog: MatDialog, private venueService: VenueService, private snackBar: MatSnackBar,
    private router: Router, private venueMapService: VenueMapService) { }

  displayedColumns = ["email", "name", "last_name", "created", "role", "venue_name", "action"];
  customColumns: string[] = [];
  dataSource: MatTableDataSource<Device>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit(): void {
    this.sort.sort(({ id: 'email', start: 'asc' }) as MatSortable);
    this.fetchUsers();
  }

  ngOnDestroy(): void {
    if (this.deviceSub) {
      this.deviceSub.unsubscribe();
    }
  }

  private fetchUsers() {
    this.deviceSub = combineLatest([
      this.venueService.fetchDevices(),
      this.venueMapService.getVenueConfigs(),
    ]).subscribe(([devices, venueConfigs]) => {
      devices.forEach(t => {
        const vcfgs = venueConfigs.find(c => c.venueId === t.venue_id);
        t.venue_name = vcfgs ? vcfgs.venue.name : "Unknown";
      });
      this.dataSource = new MatTableDataSource(devices as Device[]);
      this.dataSource.sort = this.sort;
    });
  }

  openVenuePickerDialog() {
    const dialogRef = this.dialog.open(VenuePickerDialogComponent, HackUtils.DLG({
      data: {
        title: "Välj Venue",
        cancelButton: "Avbryt",
        positiveButton: "Välj",
      }
    }));
    return dialogRef;
  }

  addDevice() {
    const dialogRef = this.openVenuePickerDialog();
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.router.navigate(["admin-devices", res, "devices", "new:"], { queryParams: { role: 100 } });
      }
    });
  }

  editDevice(device: Device) {
    this.router.navigateByUrl(`admin-devices/${device.venue_id}/devices/${device.key}`);
  }

  duplicateDevice(device: Device) {
    this.router.navigateByUrl(`admin-devices/${device.venue_id}/devices/dup:${device.key}`);
  }

  revokeAccess(device: Device) {
    console.log("revokeAccess", device);
    const ds = { title: `Upphäv åtkomst: ${device.email}`, message: `Upphäv åtkomst till systemet och kräv ny inloggning. Detta kan behöva göras t.ex. om enhet blivit stulen eller man på annat sätt anser att enheten inte längre skall ha tillgång. Efter åtkomst är borttagen, behöver man byta lösenord på enheten och logga in igen. (Det kan ta upp till 1 timma innan enheten garanterat förlorat åtkomst)`, cancelButton: "Avbryt", positiveButton: "Ta bort åtkomst" };
    SimpleDialogComponent.observeSimpleDialog(this.dialog, ds).subscribe(r => {
      if (r) {
        this.venueService.revokeDeviceAccess(device.venue_id, device.key).then(r2 => {
          this.snackBar.open("Enheten har förlorat åtkomst, var god och byt lösenord och logga in på nytt.", "OK", {});
        });
      }
    });
  }

  changeVenue(device: Device) {
    const dialogRef = this.openVenuePickerDialog();
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.venueService.updateDeviceVenue(device.key, Number(res)).then(r => {
          this.fetchUsers();
        });
      }
    });
  }

}
