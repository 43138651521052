import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {combineLatest, fromEvent, ReplaySubject, Subscription} from "rxjs";
import {VenueService} from "../../services/venue.service";
import {ActivatedRoute} from "@angular/router";
import {FireService} from "../../services/fire.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import Konva from 'konva';
import Stage = Konva.Stage;
import {
  FPData,
  FPSectionData,
  FPTableData,
  SNAP_SIZE,
  FPLine,
  FPVersion,
  Floorplan, INIT_SCALE, FLOORPLAN_SCENE_WIDTH, FLOORPLAN_SCENE_HEIGHT
} from "../../models/floorplan";
import {
  FP2Line,
  FP2Section,
  FP2Table,
} from "../../models/floorplan-editor-models";
import Layer = Konva.Layer;
import Shape = Konva.Shape;
import Group = Konva.Group;
import * as _ from "lodash";
import {Vector2d} from "konva/lib/types";
import Utils from "../../common/utils";
import {SimpleDialogComponent} from "../simple-dialog/simple-dialog.component";
import {detailedDiff, diff} from "deep-object-diff";
import {HackUtils, NodeUtils} from "../../utils/utils";
import {debounceTime} from "rxjs/operators";
import {EditSectionDialogComponent} from "./edit-section-dialog/edit-section-dialog.component";
import {OldFloorplanConverter} from "../floorplan/floorplan-common/old-floorplan-converter";
import {extractAliasPrefix, fitStageIntoParentContainer, splitIntoNameAndAddress} from "../floorplan/floorplan-common/floorplan-misc";
import {DataFormSchema} from "../../models/data-form";
import {DataFormComponent} from "../data-form/data-form.component";
import {EditSectionOrderDialogComponent} from './edit-section-order-dialog/edit-section-order-dialog.component';
import {LocationCode} from 'src/app/models/venue-dashboard';
import {first} from "rxjs/operators";
import {VenueConfig} from 'src/app/models/venue-config';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-floorplan-editor',
  templateUrl: './floorplan-editor.component.html',
  styleUrls: ['./floorplan-editor.component.css']
})
export class FloorplanEditorComponent implements OnInit, OnDestroy, AfterViewInit {
  public readonly HAS_LOC_CODE_WARNING_TEXT = "Observera: Detta bord är länkat till en specifik QR-kod. Om du ändrar bordsnamnet kommer QR-koden inte längre att leda gäster till rätt bord.";

  venueId: string;
  version: string;
  sameAsCurrent = false;
  info: { name: string, address: string, area: string, stations: string, aliasPrefix: string, resourceType: string };
  showAlias = false;
  errorMessage: string;
  selectedSection: FP2Section;
  currentData: FPData = { sections: [] };
  activeLineMode: string;
  floorplanVersion: FPVersion;
  detailedDiffToCurrent: any;
  restored = false;
  subDetailMenu = "none";
  isRect: boolean;
  isCircle: boolean;
  hasLocCode: boolean;
  locCode: string;
  coloringList: { name: string; color: string }[];
  stationList: any;
  dfsStations: DataFormSchema = {
    title: {sv: ""},
    desc: {sv: ""},
    properties: [
      {key: "stations", title: {sv: "Stationslista"}, desc: {sv: ""}, type: "table",
        columns: [
          {key: "id", title: {sv: "id"}, type: "string", width: 30 },
          {key: "name", title: {sv: "namn"}, type: "string", width: 130 },
        ]
      },
    ]
  };
  resourceTypes: string[];
  tableName = new UntypedFormControl('', [Validators.pattern("^[^/]*$")]);
  private config: VenueConfig;

  @ViewChild('container', { static: true })
  container: ElementRef<HTMLDivElement>;
  @ViewChild("dfstations") dataFormStations: DataFormComponent;
  private stage: Stage;
  private layer: Layer;
  private gridLayer: Layer;
  private lastAddedTable: FP2Table;
  private transformer: Konva.Transformer;
  private sub: Subscription;
  private paramSub: Subscription;
  private resizeSub: Subscription;
  private undoSub: Subscription;
  private focus = false;
  private mode: "line" | null;
  private currentLine: FP2Line;
  private baseData: FPData;
  private undoPointsIndex: number;
  private undoPoints: FPSectionData[];
  private undoPointsQueue: ReplaySubject<number> = new ReplaySubject(1);
  private locationCodes: LocationCode[];

  constructor(private venueService: VenueService, private route: ActivatedRoute, private fire: FireService, private snackBar: MatSnackBar,
              private dialog: MatDialog) { }

  ngOnInit(): void {
    this.paramSub = combineLatest([
        this.route.paramMap,
        this.route.queryParamMap
      ]
    ).subscribe(res => {
      const param = res[0];
      const query = res[1];
      this.venueId = param.get("venue_id");
      this.version = query.get("version");
      this.sub?.unsubscribe();

      this.beginObserving();
    });

    this.venueService.fetchAllLocationCodes(Number(this.venueId)).then(lc => {
      this.locationCodes = lc;
    });
    this.fire.observeVenueConfig(Number(this.venueId)).pipe(first()).subscribe(cfg => {
      this.config = cfg;
      this.resourceTypes = cfg.resources?.types.map(rt => rt.name) ?? [];
    });
  }

  ngAfterViewInit(): void {
    this.setupStage();

    setTimeout(() => {fitStageIntoParentContainer(this.container, this.stage); });
    if (!this.resizeSub) {
      this.resizeSub?.unsubscribe();
      this.resizeSub = fromEvent(window, 'resize').subscribe( evt => {
        fitStageIntoParentContainer(this.container, this.stage);
      });
    }
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.paramSub?.unsubscribe();
    this.resizeSub?.unsubscribe();
    this.undoSub?.unsubscribe();
  }

  private beginObserving() {
    this.sub = this.fire.observeFSFloorplan(Number(this.venueId)).subscribe(fsfp => {
      let floorplan = null;
      if (fsfp != null) {
        if (fsfp.data_version === 2) {
          floorplan = JSON.parse(fsfp.data) as FPData;
        } else if (fsfp?.data != null){
          floorplan = OldFloorplanConverter.convertOldFormatDataToNew(fsfp.data);
        }
      }

      if (this.version) {
        this.venueService.fetchFloorplanVersion(Number(this.venueId), this.version).then( fpv => {
          this.floorplanVersion = fpv;
          this.sameAsCurrent = NodeUtils.isNullOrEmptyObject(diff(floorplan, fpv.data));
          if (!this.sameAsCurrent) {
            this.detailedDiffToCurrent = detailedDiff(floorplan, fpv.data);
          }
          this.load(fpv.data);
        });
      } else {
        this.floorplanVersion = null;
        this.version = null;
        this.detailedDiffToCurrent = null;
        this.sameAsCurrent = false;
        if (floorplan == null) {
          this.initWithEmpty();
        } else {
          this.load(floorplan);
        }
      }

      this.transformer.resizeEnabled(this.version == null);
      this.transformer.rotateEnabled(this.version == null);
    });
  }

  private setupStage() {
    this.stage = new Konva.Stage({
      container: this.container.nativeElement,
      width: FLOORPLAN_SCENE_WIDTH,
      height: FLOORPLAN_SCENE_HEIGHT
    });
    this.gridLayer = this.createGridLayer();
    this.stage.add(this.gridLayer);

    this.layer = new Konva.Layer();
    this.stage.add(this.layer);

    this.setupTransformers();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.focus && this.version == null) {
      const size = this.getStepSize(event);
      //console.log(`${event.key} ${event.altKey} ${event.ctrlKey} ${event.metaKey}`);
      if (event.key === "Delete" || event.key === "Backspace") {
        this.deleteSelectedObjects();
      } else if (event.key === "c" && (event.metaKey || event.ctrlKey)) {
        this.copySelection();
      } else if (event.key === "ArrowLeft") {
        this.moveSelection( {x: -size, y: 0});
      } else if (event.key === "ArrowRight") {
        this.moveSelection( {x: size, y: 0});
      } else if (event.key === "ArrowUp") {
        this.moveSelection( {x: 0, y: -size});
      } else if (event.key === "ArrowDown") {
        this.moveSelection( {x: 0, y: size});
      } else if (event.key === "z" && (event.metaKey || event.ctrlKey) && !event.shiftKey) {
        this.undo();
      } else if (event.key === "z" && (event.metaKey || event.ctrlKey) && event.shiftKey) {
        this.redo();
      } else if (this.mode === "line" && (event.key === "Escape" || event.key === "Enter") ) {
        this.toggleLine();
      }
      /*
      else if (event.key === "i") {
        this.addTable("rect");
      } else if (event.key === "o") {
        this.addTable("circle");
      } else if (event.key === "p") {
        this.toggleLine();
      */
    }
  }

  private getStepSize(event: KeyboardEvent) {
    return event.shiftKey ? 1 : SNAP_SIZE;
  }

  collectCurrentSectionData(): FPSectionData {
    const tables = this.selectedSection.objects.map(obj => obj.collectShapeData());
    const lines = this.selectedSection.lines.map(obj => obj.collectShapeData());
    const data: FPSectionData = {id: this.selectedSection.id, name: this.selectedSection.name, tables, lines};
    return data;
  }

  private collectAndUpdateCurrentData() {
    const sectionData = this.collectCurrentSectionData();
    const i = this.currentData.sections.findIndex(se => se.id === sectionData.id);
    if (i >= 0) {
      this.currentData.sections[i] = sectionData;
    } else {
      this.currentData.sections.push(sectionData);
    }
    this.collectStationData();
  }

  private buildNewSection(id: string, name: string) {
    return { id, name, lines: [], tables: []};
  }

  private getInitialSection() {
    return this.buildNewSection("main", "Matsal");
  }

  private initWithEmpty() {
    const startSection: FPSectionData = this.getInitialSection();
    this.baseData = {sections: [startSection]};
    this.setupFromBaseData();
  }

  private load(data: FPData) {
    console.log("Loading data", data);
    this.baseData = data;
    this.setupFromBaseData();
  }

  private setupFromBaseData() {
    this.currentData = _.cloneDeep(this.baseData);
    const firstSection = this.currentData.sections[0];
    this.loadSection(firstSection);
    this.addUndoPoint();
  }

  private setupSection(section: FPSectionData): FP2Section {
    this.removeShapes();
    this.selectedSection = new FP2Section(section.id, section.name);
    for (const table of section.tables) {
      const aliasPrefix = extractAliasPrefix(table.name, table.alias);
      const fpt = new FP2Table(table.name, table.alias, aliasPrefix, table.area, table.stations,
        table.resource_type,
        table.type, table, this.showAlias,
        this.undoPointsQueue, this.version != null);
      this.doAddTable(fpt);
    }
    for (const line of section.lines) {
      this.addCompleteLine(line);
    }
    return this.selectedSection;
  }

  private removeShapes() {
    const shapes = this.stage.find(node => _.includes(["movable", "vertex", "line", "tail"], node.name()));
    for (const shape of shapes) {
      shape.remove();
    }
  }

  private loadSection(section: FPSectionData) {
    this.undoPointsIndex = 0;
    this.undoPoints = [];
    this.lastAddedTable = null;
    this.clearSelection();
    this.setupSection(section);
  }

  save() {
    this.collectAndUpdateCurrentData();
    this.persistNewVersion(this.currentData);
  }

  restoreVersion() {
    const dialogRef = SimpleDialogComponent.openSimpleDialog(this.dialog, {title: "Restoring version...", showProgress: true, cancelButton: "Close"});
    this.venueService.restoreFloorplanVersion(Number(this.venueId), this.version).then( res => {
      console.log(res);
      this.restored = true;
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e)).finally( () => dialogRef.close());
  }

  private getGoodPrefilledValues(table: FP2Table | null, type: string) {
    const names = this.getSimilarName(table?.name ?? "Bord 0", table?.aliasPrefix);
    const alias = names.alias;
    const area = table?.area;
    const stations = table?.stations;
    const resourceType = table?.resourceType;
    const data = table?.collectShapeData() ?? {x: 0, y: 0, scaleX: INIT_SCALE, scaleY: INIT_SCALE};
    const x = ((table?.x ?? 6 * SNAP_SIZE) + 4 * SNAP_SIZE) % FLOORPLAN_SCENE_WIDTH;
    const y = ((table?.y ?? 6 * SNAP_SIZE) + 4 * SNAP_SIZE) % FLOORPLAN_SCENE_HEIGHT;
    data.x = x;
    data.y = y;

    if (type === "circle") {
      let sx = data.scaleX ?? INIT_SCALE;
      let sy = data.scaleY ?? INIT_SCALE;
      if (sx > sy) { sx = sy; } else { sy = sx; }
      data.scaleX = sx;
      data.scaleY = sy;
    }

    return {name: names.name, alias, aliasPrefix: table?.aliasPrefix, area, stations, resourceType, data};
  }

  private doAddTable(table: FP2Table) {
    //console.log("Adding table", table);
    this.selectedSection.objects.push(table);
    table.addToLayer(this.layer);
    this.layer.draw();
    this.lastAddedTable = table;
  }

  private getSelected() {
    if (this.transformer.nodes().length === 1) {
      const node = this.transformer.nodes()[0];
      if (node.name() === "movable") {
        return node;
      }
    }
    return null;
  }

  private collectSelectedObjects() {
    const nodes: FP2Table[] = [];
    for (const node of this.transformer.nodes()) {
      const obj = this.findObject(node.id());
      if (obj) {
        nodes.push(obj);
      }
    }
    return nodes;
  }

  checkLocationCodes() {
    const selObj = this.collectSelectedObjects();
    this.hasLocCode = false;
    this.tableName.enable();
    selObj.forEach(node => {
      const obj = this.locationCodes.find(lc => lc.name === node.name);
      if (obj) {
        this.hasLocCode = true;
        this.tableName.disable();
      }
      if (obj && selObj.length === 1) {
        this.locCode = obj.code;
      } else {
        this.locCode = "";
      }
    });
  }

  addTable(type: string) {
    this.endLineDrawing();
    const selected = this.getSelected();
    const lastSelectedOrAdded = selected ? this.findObject(selected.id()) : this.lastAddedTable;
    const pre = this.getGoodPrefilledValues(lastSelectedOrAdded, type);
    // @ts-ignore
    const nt = new FP2Table(pre.name, pre.alias, pre.aliasPrefix, pre.area, pre.stations, pre.resourceType, type, pre.data, this.showAlias,
      this.undoPointsQueue, this.version != null);
    this.doAddTable(nt);
    this.selectNodes([nt.shape]);
    this.addUndoPoint();
  }

  changeShape(type: string) {
    // collect node data
    const nodes = this.collectSelectedObjects();
    // store the shapes in a temporary array and select them
    let shapes = [];
    nodes.forEach(n => {
      shapes.push(n.shape);
    });
    this.clearSelection();
    this.selectNodes(shapes);
    // delete the shapes
    this.deleteSelectedObjects();
    // recreate the shapes with the new type
    shapes = [];
    for (const table of nodes) {
      const nt = new FP2Table(
        table.name,
        table.alias,
        table.aliasPrefix,
        table.area,
        table.stations,
        table.resourceType,
        type,
        table.collectShapeData(),
        this.showAlias,
        this.undoPointsQueue,
        this.version != null);
      this.doAddTable(nt);
      shapes.push(nt.shape);
    }
    this.selectNodes(shapes);
    this.addUndoPoint();
  }

  private setupTransformers() {
    this.transformer = new Konva.Transformer({
      rotationSnaps: [0, 90, 180, 270],
      flipEnabled: false,
      resizeEnabled: this.version == null,
      rotateEnabled: this.version == null
    });
    this.layer.add(this.transformer);

    // add a new feature, lets add ability to draw selection rectangle
    const selectionRectangle = new Konva.Rect({
      fill: 'rgba(0,0,255,0.5)',
      visible: false,
    });
    this.layer.add(selectionRectangle);

    this.stage.on('dragstart', (e) => {
      const metaPressed = e.evt.ctrlKey || e.evt.metaKey;
      //Clicked on a child?
      const target = e.target;
      const isSelected = this.transformer.nodes().indexOf(target) >= 0;
      if (metaPressed && isSelected) {
        this.copyObject(target as Shape, {x: 0, y: 0}, true);
        this.updateInfo();
      }
    });

    this.stage.on('dragend', (e) => {
      console.log("dragend");
      this.addUndoPoint();
    });
    this.stage.on('transformend', (e) => {
      console.log("transformend");
      this.addUndoPoint();
    });

    // eslint-disable-next-line one-var
    let x1, y1, x2, y2;
    this.stage.on('mousedown touchstart', (e) => {
      if (this.mode === "line") {
      } else if (e.target === this.stage) {
        const vec = this.getPointerPosition();
        x1 = vec.x;
        y1 = vec.y;
        x2 = vec.x;
        y2 = vec.y;
        selectionRectangle.visible(true);
        selectionRectangle.width(0);
        selectionRectangle.height(0);
      }
    });

    this.stage.on('mousemove touchmove', e => {
      const vec = this.getPointerPosition();
      x2 = vec.x;
      y2 = vec.y;
      if (this.mode === "line") {
        //console.log(`${x2} ${y2}`);
        this.drawPenBox({x: x2, y: y2});
      } else if (selectionRectangle.visible()) {
        selectionRectangle.setAttrs({
          x: Math.min(x1, x2),
          y: Math.min(y1, y2),
          width: Math.abs(x2 - x1),
          height: Math.abs(y2 - y1),
        });
      }
    });

    this.stage.on('mouseup touchend', () => {
      if (this.mode === "line") {
      } else if (selectionRectangle.visible()){
        setTimeout(() => { selectionRectangle.visible(false); });
        //const shapes = this.stage.find('.movable');
        const shapes = this.stage.find(node => node.name() === "movable" || node.name() === "vertex" );

        const box = selectionRectangle.getClientRect();
        const selected = shapes.filter((shape) =>
          Konva.Util.haveIntersection(box, shape.getClientRect())
        );
        this.selectNodes(selected);
      }
    });

    // clicks should select/deselect shapes
    this.stage.on('click tap', e => {
      const vec = this.getPointerPosition();
      x2 = vec.x;
      y2 = vec.y;
      if (this.mode === "line") {
        this.addPointToLine(Utils.snapVec({x: x2, y: y2}));
      } else if (!selectionRectangle.visible()) {
        // if click on empty area - remove all selections
        if (e.target === this.stage) {
          this.clearSelection();
          return;
        }

        const target = e.target.parent ?? e.target;
        console.log(`target: ${target.name()}`);
        if (!target.hasName('movable')) {
          return;
        }

        const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
        const isSelected = this.transformer.nodes().indexOf(target) >= 0;
        if (!metaPressed && !isSelected) {
          // if no key pressed and the node is not selected
          // select just one
          console.log(`select target: ${target.name()}`);
          this.selectNodes([target]);
        } else if (metaPressed && isSelected) {
          // if we pressed keys and node was selected
          // we need to remove it from selection:
          const nodes = this.transformer.nodes().slice(); // use slice to have new copy of array
          // remove node from array
          nodes.splice(nodes.indexOf(target), 1);
          this.selectNodes(nodes);
        } else if (metaPressed && !isSelected) {
          // add the node into selection
          const nodes = this.transformer.nodes().concat([target]);
          this.selectNodes(nodes);
        }
      }
    });
  }

  private clearSelection() {
    this.selectNodes([]);
  }

  private copyObject(target: Shape, offsetXY?: { x: number, y: number }, swap = false): FP2Table {
    console.log("copy object...");
    const obj = this.findObject(target.id()) as FP2Table;
    if (obj) {
      const orgName = obj.name;
      const orgAlias = obj.alias;
      const newName = this.getSimilarName(orgName, obj.aliasPrefix);
      if (swap) {
        obj.changeName(newName.name, newName.alias);
      }
      const shapeData = obj.collectShapeData(offsetXY);
      console.log(shapeData);
      const cpy = new FP2Table(swap ? orgName : newName.name,
        swap ? orgAlias : newName.alias,
        swap ? obj.aliasPrefix : newName.aliasPrefix,
        obj.area, obj.stations,
        obj.resourceType,
        obj.type, shapeData, this.showAlias, this.undoPointsQueue, this.version != null);
      this.doAddTable(cpy);
      return cpy;
    }
    return null;
  }

  private findObject(id: string): FP2Table {
    return this.selectedSection.objects.find(obj => obj.shapeId === id) as FP2Table;
  }

  private findObjectByName(name: string): FPTableData | FP2Table {
    const fp2 = this.selectedSection.objects.find(obj => obj.name === name) as FP2Table;
    if (fp2 == null) {
      for (const sec of this.currentData.sections) {
        const obj = sec.tables.find( tab => tab.name === name);
        if (obj) {
          return obj;
        }
      }
    }
    return fp2;
  }

  private getSimilarName(name: string, aliasPrefix: string): {name: string, alias: string, aliasPrefix: string} {
    const na = splitIntoNameAndAddress(name);
    let nextAddress: string | number;
    if (typeof na.address === "number") {
      nextAddress = na.address + 1;
    } else if (typeof na.address === "string") {
      nextAddress = String.fromCharCode(na.address.charCodeAt(0) + 1);
      if (!/^[a-zA-Z]$/.test(nextAddress) || na.address.length > 1) {
        nextAddress = 1;
      }
    }
    this.venueService.fetchAllLocationCodes(Number(this.venueId)).then(lc => {
      this.locationCodes = lc;
    });
    while (true) {
      let hasLocCode = false;
      const suggestion = `${na.name} ${nextAddress}`;
      const obj = this.findObjectByName(suggestion);
      if (this.locationCodes.find(lc => lc.name === suggestion)) {
        hasLocCode = true;
      }
      if (obj == null && !hasLocCode) {
        const alias = `${aliasPrefix ?? ""}${nextAddress}`;
        return {name: suggestion, alias, aliasPrefix};
      }
      if (typeof nextAddress === 'number') {
        nextAddress++;
      } else if (typeof nextAddress === 'string') {
        nextAddress = String.fromCharCode(nextAddress.charCodeAt(0) + 1);
        if (!/^[a-zA-Z]$/.test(nextAddress)) {
          nextAddress = 1;
        }
      }
    }
  }

  private deleteSelectedObjects() {
    for (const node of this.transformer.nodes()) {
      const obj = this.findObject(node.id());
      if (obj) {
        _.remove(this.selectedSection.objects, o => o === obj);
        obj.removeFromLayer(this.layer);
        if (obj === this.lastAddedTable) {
          this.lastAddedTable = undefined;
        }
      } else if (node.name() === "vertex"){
        const line = this.findLineWithVertex(node.id());
        if (line) {
          const stillExists = line.removeVertex(node.id());
          if (!stillExists) {
            _.remove(this.selectedSection.lines, o => o === line);
            console.log(`Line count after delete ${this.selectedSection.lines.length}`);
          }
        }
      }
    }
    this.clearSelection();
    this.addUndoPoint();
  }

  private copySelection() {
    //this.copiedNodes = this.transformer.nodes() as Shape[];
    const newShapes = [];
    for (const node of this.getSelectedTableNodes()) {
      const cpy = this.copyObject(node as Shape, {x: SNAP_SIZE, y: SNAP_SIZE});
      newShapes.push(cpy.shape);
    }
    this.selectNodes(newShapes);
    this.addUndoPoint();
  }

  private createGridLayer() {
    const width = FLOORPLAN_SCENE_WIDTH;
    const height = FLOORPLAN_SCENE_HEIGHT;

    const gridLayer = new Konva.Layer();
    const padding = SNAP_SIZE;
    const cols = width / padding;
    for (let i = 0; i < cols + 1; i++) {
      const x = Math.round(i * padding) + 0.5 - (i === cols ? 1 : 0);
      gridLayer.add(new Konva.Line({
        points: [x, 0, x, height],
        stroke: '#dbdbdb',
        strokeWidth: 1,
      }));
    }

    const rows = height / padding;
    for (let j = 0; j < rows + 1; j++) {
      const y = Math.round(j * padding) + 0.5 - (j === rows ? 1 : 0);
      gridLayer.add(new Konva.Line({
        points: [0, y, width, y],
        stroke: '#dbdbdb',
        strokeWidth: 1,
      }));
    }

    return gridLayer;
  }

  private getSelectedTableNodes() {
    return this.transformer.nodes().filter(node => node.name() === "movable");
  }

  changeName() {
    this.tableName.markAsTouched();
    const names = this.info.name.split(",");
    if (names.length === 1) {
      for (const node of this.getSelectedTableNodes()) {
        const obj = this.findObject(node.id());
        const na = splitIntoNameAndAddress(obj.name);
        obj.changeName(`${this.info.name} ${na.address}`, `${obj.aliasPrefix ?? ""}${na.address}`);
      }
    }
  }

  changeAddress() {
    const addrs = this.info.address.split(",");
    const tables = this.getSelectedTableNodes();
    if (addrs.length === tables.length) {
      for (let i = 0; i < addrs.length; i++) {
        const addr = addrs[i].replace(/\s/g, "");
        const node = tables[i];
        const obj = this.findObject(node.id());
        const na = splitIntoNameAndAddress(obj.name);
        obj.changeName(`${na.name} ${addr}`, `${obj.aliasPrefix ?? ""}${addr}`);
      }
    }
  }

  changeArea() {
    const area = this.info.area.split(",");
    if (area.length === 1) {
      for (const node of this.getSelectedTableNodes()) {
        const obj = this.findObject(node.id());
        obj.area = this.info.area;
      }
    }
  }

  changeStation() {
    const section = this.info.stations.split(";");
    if (section.length === 1) {
      for (const node of this.getSelectedTableNodes()) {
        const obj = this.findObject(node.id());
        obj.stations = this.info.stations.split(",");
      }
    }
  }

  changeAlias() {
    const alias = this.info.aliasPrefix.split(",");
    if (alias.length === 1) {
      for (const node of this.getSelectedTableNodes()) {
        const obj = this.findObject(node.id());
        obj.aliasPrefix = this.info.aliasPrefix;
        const na = splitIntoNameAndAddress(obj.name);
        obj.changeName(obj.name, `${obj.aliasPrefix ?? ""}${na.address}`);
      }
    }
  }

  changeResource() {
    const resource = this.info.resourceType.split(",");
    if (resource.length === 1) {
      for (const node of this.getSelectedTableNodes()) {
        const obj = this.findObject(node.id());
        obj.resourceType = this.info.resourceType;
      }
    }
  }

  getResourceDropdownValue(resources: any[]): string {
    if (resources.length === 0) {return "";}
    const firstValue = resources[0];
    if (resources.every(o => o === firstValue)) {
      return firstValue;
    } else {
      return null;
    }
  }

  private selectNodes(selected: any[]) {
    const sorted = _.sortBy(selected, [o => o.x() + o.y() * 1000]);
    this.transformer.nodes(sorted);
    this.updateInfo();
    this.checkLocationCodes();
  }

  private updateInfo() {
    const nameSet = new Set();
    const aliasPrefixSet = new Set();
    const areaSet = new Set();
    const stationsSet = new Set();
    const addrs = [];
    const types = {};
    const resourceSet = new Set();
    for (const node of this.transformer.nodes()) {
      if (node.name() === "movable") {
        const obj = this.findObject(node.id());
        types[obj.type] = true;
        const na = splitIntoNameAndAddress(obj.name);
        nameSet.add(na.name);
        addrs.push(na.address);
        if (obj.aliasPrefix) {
          aliasPrefixSet.add(obj.aliasPrefix);
        }
        if (obj.area) {
          areaSet.add(obj.area);
        }
        if (obj.stations) {
          stationsSet.add(obj.stations.join(","));
        }
        if (obj.resourceType) {
          resourceSet.add(obj.resourceType);
        } else {
          resourceSet.add("");
        }
      }

      this.isRect = 'rect' in types;
      this.isCircle = 'circle' in types;
    }
    if (nameSet.size > 0) {
      const names = [...nameSet];
      const aliasPrefixes = [...aliasPrefixSet];
      const areas = [...areaSet];
      const stations = [...stationsSet];
      const resources = [...resourceSet];
      this.info = {name: names.join(","), address: addrs.join(","), stations: stations.join(";"), aliasPrefix: aliasPrefixes.join(","), area: areas.join(","), resourceType: this.getResourceDropdownValue(resources)};
    } else {
      this.info = null;
    }
  }

  focusIn() {
    this.focus = true;
  }

  focusOut() {
    this.focus = false;
    this.addUndoPoint();
  }

  private moveSelection(change: Vector2d) {
    for (const node of this.transformer.nodes()) {
      node.move(change);
    }
    this.addUndoPoint();
  }

  toggleLine() {
    this.clearSelection();
    if (this.mode == null) {
      this.mode = "line";
      this.activeLineMode = "primary";
    } else {
      this.endLineDrawing();
    }
  }

  private endLineDrawing() {
    this.endCurrentLine();
    this.mode = null;
    this.activeLineMode = null;
    this.currentLine = null;
  }

  private drawPenBox(vec: Vector2d) {
    // this.penBox.setPosition({x, y});
    if (this.currentLine != null) {
      this.currentLine.setTempPoint(vec);
    }
  }

  private addPointToLine(vec: Vector2d) {
    console.log(`Add node to line ${vec.x} ${vec.y}`);
    if (this.currentLine == null) {
      this.currentLine = new FP2Line("wall", this.undoPointsQueue, this.version != null);
      this.currentLine.addToLayer(this.layer);
      this.selectedSection.lines.push(this.currentLine);
    }
    this.currentLine.addPoint(vec);
    this.addUndoPoint();
  }

  private addCompleteLine(fpl: FPLine) {
    const fp2l = new FP2Line("wall", this.undoPointsQueue, this.version != null);
    fp2l.addToLayer(this.layer);
    for (const vec of fpl.vertices) {
      fp2l.addPoint(vec);
    }
    this.selectedSection.lines.push(fp2l);
  }

  private endCurrentLine() {
    if (this.currentLine) {
      this.currentLine.removeTempPoint();
      if (this.currentLine.vertices.length < 2) {
        this.currentLine.removeFromLayer();
        _.remove(this.selectedSection.lines, this.currentLine);
      }
    }
  }

  private findLineWithVertex(vertexId: string): FP2Line {
    console.log(this.selectedSection.lines);
    return this.selectedSection.lines.find(line => line.findVertex(vertexId) != null );
  }

  toggleShowAlias() {
    for (const obj of this.selectedSection.objects) {
      obj.showAlias = this.showAlias;
      obj.textUpdated();
    }
  }

  private persistNewVersion(data: FPData) {
    const d = diff(this.baseData, this.currentData);
    const duplicateTables: Array<{sectionData: FPSectionData, duplicates: Array<string>}> = this.getDuplicateTableNames();
    if (NodeUtils.isNullOrEmptyObject(d)) {
      this.snackBar.open("No changes. Nothing saved.", "", {duration: 3000});
    } else if (!this.validateChanges(this.currentData)) {
      this.snackBar.open("Invalid floorplan! Nothing saved.", "", {duration: 3000});
      const invalidData = this.currentData.sections.filter(sec =>
        sec.tables.some(table => table.name.includes("/"))
      );
      if (invalidData.length > 0) {
        this.selectInvalidTableNode(invalidData);
      }
    } else if (duplicateTables.length > 0) {
      this.snackBar.open("Duplicate tables found! Nothing saved.", "", {duration: 3000});
      console.log("Duplicate tables", duplicateTables);
      this.selectFaultyTable(duplicateTables);
    } else {
      const dialogRef = SimpleDialogComponent.openSimpleDialog(this.dialog, {title: "Saving...", showProgress: true, cancelButton: "Close"});
      const dd = detailedDiff(this.baseData, this.currentData);
      console.log("Found diff saving...", dd);
      this.venueService.saveFloorplan(Number(this.venueId), data, dd).then( res => {
        console.log(res);
        dialogRef.close();
        this.errorMessage = null;
      }).catch(err => {
        console.log(err);
        dialogRef.close();
        //this.hasBeenBuilt = true;
        this.errorMessage = err.error.message;
      });
    }
  }

  private validateChanges(changes: FPData): boolean {
    for (const section of changes.sections) {
      for (const table of section.tables) {
          if (table.name.indexOf("/") !== -1) {
              return false;
          }
      }
    }
    return true;
  }

  private getDuplicateTableNames(): Array<{sectionData: FPSectionData, duplicates: Array<string>}> {
    const duplicates: Array<{sectionData: FPSectionData, duplicates: Array<string>}> = [];
    const nameMapping = {};

    for (const section of this.currentData.sections) {
        for (const table of section.tables) {
            const lowerCaseName = table.name.toLowerCase();
            if (!nameMapping[lowerCaseName]) {
                nameMapping[lowerCaseName] = [];
            }
            nameMapping[lowerCaseName].push({ name: table.name, section });
        }
    }

    for (const lowerCaseName in nameMapping) {
      if (lowerCaseName.startsWith("$")) {continue;}
        if (nameMapping[lowerCaseName].length > 1) {
            const sectionGroups = {};
            nameMapping[lowerCaseName].forEach(entry => {
                const sectionId = entry.section.id;
                if (!sectionGroups[sectionId]) {
                    sectionGroups[sectionId] = [];
                }
                sectionGroups[sectionId].push(entry.name);
            });

            for (const sectionId in sectionGroups) {
              if (sectionGroups[sectionId].length <= 1) {continue;}
              const section = nameMapping[lowerCaseName].find(entry => entry.section.id === sectionId).section;
              duplicates.push({ sectionData: section, duplicates: sectionGroups[sectionId] });
            }
        }
    }
    return duplicates;
  }

  private selectInvalidTableNode(data: FPSectionData[]) {
    for (const sec of data) {
      this.loadSection(sec);
      for (const table of sec.tables) {
        if (table.name.indexOf("/") !== -1) {
          const obj = this.findObjectByName(table.name) as FP2Table;
          this.selectNodes([obj.shape]);
        }
      }
    }
  }

  private selectFaultyTable(data: Array<{sectionData: FPSectionData, duplicates: Array<string>}>) {
    for (const duplicate of data) {
      this.loadSection(duplicate.sectionData);
      for (const name of duplicate.duplicates) {
        const obj = this.findObjectByName(name) as FP2Table;
        this.selectNodes([obj.shape]);
      }
    }
  }

  closeError() {
    this.errorMessage = null;
  }

  private getPointerPosition(): Vector2d {
    const vec = this.stage.getRelativePointerPosition();
    return vec;
  }

  private setupUndoPointsObserver() {
    this.undoSub = this.undoPointsQueue.pipe(
      debounceTime(100)
    ).subscribe( i => {
      const sectionData = this.collectCurrentSectionData();

      //Skip same undo points
      if (this.undoPointsIndex < this.undoPoints.length) {
        const current = this.undoPoints[this.undoPointsIndex];
        const ise = _.isEqual(current, sectionData);
        // console.log(current);
        if (ise) {
          console.log("Undopoint is equal to current...");
          return;
        }
      }

      if (this.undoPointsIndex < this.undoPoints.length) {
        this.undoPoints.splice(this.undoPointsIndex + 1, this.undoPoints.length - this.undoPointsIndex);
      }
      this.undoPoints.push(sectionData);
      this.undoPointsIndex = this.undoPoints.length - 1;
      console.log(`Adding undo point: index = ${this.undoPointsIndex} len = (${this.undoPoints.length})`);
    });
  }

  private addUndoPoint() {
    this.undoPointsQueue.next(1);
    if (this.undoSub == null) {
      this.setupUndoPointsObserver();
    }
  }

  private getPrevUndoPoint(): FPSectionData | null {
    if (this.undoPointsIndex > 0) {
      this.undoPointsIndex--;
      return this.undoPoints[this.undoPointsIndex];
    }
    return null;
  }

  private getNextUndoPoint(): FPSectionData | null {
    if (this.undoPointsIndex < this.undoPoints.length - 1) {
      this.undoPointsIndex++;
      return this.undoPoints[this.undoPointsIndex];
    }
    return null;
  }

  undo() {
    console.log(`Undo index = ${this.undoPointsIndex} len = (${this.undoPoints.length})`);
    this.clearSelection();
    const undoPointSectionData = this.getPrevUndoPoint();
    if (undoPointSectionData) {
      this.setupSection(undoPointSectionData);
      if (this.currentLine) {
        this.currentLine = _.last(this.selectedSection.lines);
      }
    }
  }

  redo() {
    console.log(`Undo index = ${this.undoPointsIndex} len = (${this.undoPoints.length})`);
    this.clearSelection();
    const undoPointSectionData = this.getNextUndoPoint();
    if (undoPointSectionData) {
      this.setupSection(undoPointSectionData);
    }
  }

  addSection() {
    const dialogRef = this.dialog.open(EditSectionDialogComponent, HackUtils.DLG({
      data: { title: "Lägg till ny sektion", button: "Lägg till", venueId: this.venueId }
    }));
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Dialog result:', result);
        this.doAddNewSection(result);
      }
    });
  }

  private doAddNewSection(name: string) {
    const id = Utils.buildNiceId(name);
    const section = this.buildNewSection(id, name);
    this.currentData.sections.push(section);
    this.selectSection(section);
  }

  selectSection(section: FPSectionData, collectCurrent = true) {
    this.endLineDrawing();
    if (collectCurrent) {
      this.collectAndUpdateCurrentData();
    }
    this.loadSection(section);
    this.addUndoPoint();
  }

  selectableSections(sections: FPSectionData[]) {
    return sections?.filter(sec => sec.id !== this.selectedSection.id);
  }

  renameSection() {
    const dialogRef = this.dialog.open(EditSectionDialogComponent, HackUtils.DLG({
      data: { name: this.selectedSection.name, title: "Byt namn sektion", button: "Ok", venueId: this.venueId }
    }));
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Dialog result:', result);
        this.selectedSection.name = result;
      }
    });
  }

  reorderSections() {
    const dialogRef = this.dialog.open(EditSectionOrderDialogComponent, HackUtils.DLG({
      data: {
              id: this.selectedSection.id,
              title: "Byt ordning sektion",
              button: "Ok",
              sections: this.currentData.sections,
              minWidth: '460px',
              maxWidth: 'fit-content'
            }
    }));
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Dialog result:', result);
        this.selectedSection.name = result;
      }
    });
  }

  deleteSection() {
    const ds = {title: "Ta bort sektion", message: `Vill du ta bort sektion ${this.selectedSection.name}?`, cancelButton: "Avbryt", positiveButton: "Ta bort sektion"};
    SimpleDialogComponent.observeSimpleDialog(this.dialog, ds).subscribe( r => {
      if (r) {
        this.doDeleteCurrentSection();
      }
    });
  }

  private doDeleteCurrentSection() {
    const removeId = this.selectedSection.id;
    _.remove(this.currentData.sections, sec => sec.id === removeId);
    console.log(this.currentData.sections);
    if (this.currentData.sections.length === 0) {
      const startSection: FPSectionData = this.getInitialSection();
      this.currentData = {sections: [startSection]};
    }
    this.selectSection(this.currentData.sections[0], false);
  }

  public hasChanges(): boolean {
    this.collectAndUpdateCurrentData();
    const d = diff(this.baseData, this.currentData);
    return !NodeUtils.isNullOrEmptyObject(d);
  }

  public collectStationData() {
    const data = this.dataFormStations?.collectData();
    if (data) {
      console.log("Collecting station data", data);
      this.currentData.stations = data.stations;
      this.stationList = {stations: this.currentData.stations ?? []};
    }
    this.addUndoPoint();
  }

  subDetailMenuChanged() {
    const coloringColors = ["#ff0000", "#00ff00", "#0000ff", "#ff00ff", "#00ffff", "#ffff00"];
    console.log("subDetailMenuChanged");
    if (this.subDetailMenu === "area") {
      const nset = new Set<string>();
      for (const sec of this.currentData.sections) {
        for (const table of sec.tables) {
          nset.add(table.area);
        }
      }
      const colNames = [];
      let i = 0;
      for (const name of [...nset]){
        colNames.push({name, color: coloringColors[i]});
        i = (i + 1) % coloringColors.length;
      }
      this.coloringList = colNames;
    } else if (this.subDetailMenu === "station") {
      this.stationList = {stations: this.currentData.stations ?? []};
    }
  }

}
