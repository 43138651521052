<div>
  <p>
    <mat-toolbar>
      <button mat-icon-button (click)="goBack()"><mat-icon>arrow_back_ios</mat-icon></button>
      <span>Sammanställning {{date}}</span>
      <span class="spacer"></span>
<!--      <button mat-button [matMenuTriggerFor]="menuAction">Action</button>-->
<!--      <mat-menu #menuAction="matMenu">-->
<!--        <button mat-menu-item (click)="printBong()">Skriv ut bong</button>-->
<!--      </mat-menu>-->
    </mat-toolbar>
  </p>
</div>

<div style="width: 400px;margin: 0 16px;padding: 16px;border: 1px solid #888;">
  <app-dossier-viewer *ngIf="dossier" [printInstructions]="dossier"></app-dossier-viewer>
</div>


