<div class="detail-container">
  <div style="position: relative;">
    <mat-card>
      <div class="sub-heading" style="padding-bottom: 8px;">Profil</div>
      <div><span style="font-weight: bold;">Namn: </span>{{admin.first_name}} {{admin.last_name}}</div>
      <div><span style="font-weight: bold;">Email: </span>{{admin.email}}</div>
      <div><span style="font-weight: bold;">Id: </span>{{admin.id}}</div>
      <div><span style="font-weight: bold;">Skapad: </span>{{admin.created | utcDate:'short'}}</div>
      <div><span style="font-weight: bold;">Uppdaterad: </span>{{admin.updated | utcDate:'short'}}</div>
      <div class="action-buttons" style="padding-top: 16px;">
        <button mat-raised-button (click)="editAdminUser()">Redigera profil</button>
        <button mat-raised-button (click)="resendInvite()">Skicka inbjudan igen</button>
      </div>
    </mat-card>
    <mat-card *ngIf="venues && !showSpinner">
      <div class="sub-heading">Venues</div>
      <table mat-table [dataSource]="venues" class="clicktable">
        <ng-container matColumnDef="venue_id">
          <th mat-header-cell *matHeaderCellDef> Id</th>
          <td mat-cell *matCellDef="let venue"> {{ venue.venue_id }} </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Venue</th>
          <td mat-cell *matCellDef="let venue"> {{ venue.name }} </td>
        </ng-container>

        <ng-container matColumnDef="roles">
          <th mat-header-cell *matHeaderCellDef> Roll</th>
          <td mat-cell *matCellDef="let venue"> {{ venue.roles }} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef><mat-icon class="icon-header">edit</mat-icon></th>
          <td mat-cell *matCellDef="let element">
            <button mat-button [matMenuTriggerFor]="menuReports" (click)="$event.stopPropagation()"><mat-icon>arrow_drop_down</mat-icon></button>
            <mat-menu #menuReports="matMenu">
              <button mat-menu-item (click)="editRoles(element?.venue_id)">Redigera roll</button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div style="padding-top:8px;">
        <button mat-raised-button (click)="addVenue()">
          <mat-icon>add_business</mat-icon> Lägg till
        </button>
      </div>
    </mat-card>
    <mat-card *ngIf="showSpinner" style="min-height: 50px;">
      <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="showSpinner"></mat-spinner>
    </mat-card>
  </div>
</div>
