<div class="dialog">
	<h2 mat-dialog-title>Kvitto</h2>
	<mat-dialog-content>
		<div class="receipt" *ngIf="data.receiptKey">
			<app-receipt-view [venueId]="data.venueId" [receiptKey]="data.receiptKey" [isSubComponent]="data.isSubComponent"></app-receipt-view>
		</div>
    <div class="receipt" *ngIf="data.refundReceiptKeys">
      <div style="margin-top: 16px; font-weight: bold;margin-bottom: 8px;">Returnerade artiklar:</div>
      <div *ngFor="let rrk of data.refundReceiptKeys" style="margin-bottom: 16px;">
        <app-receipt-view [venueId]="data.venueId" [receiptKey]="rrk" [isSubComponent]="data.isSubComponent"></app-receipt-view>
      </div>
    </div>
	</mat-dialog-content>
	<mat-dialog-actions align="end">
		<button mat-flat-button mat-dialog-close color="primary">Ok</button>
	</mat-dialog-actions>
</div>
