import {DocumentChangeAction} from "@angular/fire/compat/firestore";
import {MenuCell, MenuTab} from "../menu-models/MenuChange";
import Handsontable from "handsontable";
import {AuthState} from "../../../models/signin";
import * as _ from "lodash";
import {NodeUtils} from "../../../utils/utils";
import plugins = Handsontable.plugins;

let globalCopiedRows: any[];
let recentlyCopiedIds: string[];

export class HotDB {
  dbMatrix = {};
  changesCache? : Handsontable.CellChange[]; // contains changes that are not yet saved to the db
  dataset = [];
  isDatasetEmpty = true;
  currentTab: MenuTab;
  currentSelection: {row: number, column: number, row2: number, column2: number};
  hasAutoIdColumn = false;
  private venueId: number;
  private tabId: string;
  private menuId: string;
  private hasData: boolean;

  constructor(venueId: number, tabId: string, menuId: string, hasAutoIdColumn: boolean) {
    this.venueId = venueId;
    this.tabId = tabId;
    this.menuId = menuId;
    this.isDatasetEmpty = true;
    this.hasAutoIdColumn = hasAutoIdColumn;
    this.dbMatrix = {};
    this.dataset = [{}, {}, {}, {}, {}, {}];
    this.hasData = false;
  }

  public updateDB(row: number, col: string, value: string) {
    if (!(row in this.dbMatrix)) {
      this.dbMatrix[row] = {};
    }
    if (this.dbMatrix[row][col] !== value) {
      // console.log(`Setting ${row}[${col}] to ${value}`);
      this.dbMatrix[row][col] = value;
      this.hasData = true;
      return true;
    }
    return false;
  }

  public readFromDB(row: number, col: string) {
    if (!(row in this.dbMatrix)) {
      return undefined;
    }
    const dbRow = this.dbMatrix[row];
    if (!(col in dbRow)) {
      return undefined;
    }
    return dbRow[col];
  }

  public readFromDBAndCache(rowIndex: number, col: string) {
    if (this.changesCache?.length > 0) {
      const cached = this.changesCache.find(c => c[0] === rowIndex && c[1] === col);
      if (cached) {
        console.log(`Found in cache... ${cached[0]} ${cached[1]} ${cached[2]} ${cached[3]}`);
        return cached[3];
      }
    }
    const row = this.indexToRow(rowIndex)
    return this.readFromDB(row, col);
  }

  public readRowFromDB(row: number) {
    if (!(row in this.dbMatrix)) {
      return undefined;
    }
    const dbRow = this.dbMatrix[row];
    return dbRow;
  }

  // public rebuildDataSet(rows: number[]) {
  //   console.log("Rebuilding dataset...");
  //   this.isDatasetEmpty = false;
  //   const ds = [];
  //   for (const row of rows) {
  //     let v = this.readRowFromDB(row);
  //     if (!v) {
  //       v = {};
  //     }
  //     ds.push(v);
  //   }
  //   this.dataset = ds;
  // }

  private rebuildDataSet(rows: number[], columns: any[]) {
    if (rows.length === 0 || columns.length === 0) { return; }
    console.log("Rebuilding dataset...");
    // Mark data set as non empty if we have data => meaning that there will be iterative updates after the first rebuild
    this.isDatasetEmpty = !this.hasData;
    const ds = [];
    for (const row of rows) {
      const r = {};
      for (const colTemp of columns) {
        const col = colTemp.data;
        let v = this.readFromDB(row, col);
        if ( v === null || v === "null" ) {
          v = undefined;
        }
        r[col] = v;
      }
      ds.push(r);
    }
    this.dataset = ds;
  }


  cellChangesObserved(cellChanges: DocumentChangeAction<MenuCell>[], hotInstance: Handsontable, columns: any[]) {
    if (cellChanges.length < 4) {
      console.log(`Got ${cellChanges.length} cell change(s)`, cellChanges);
    } else {
      console.log(`Got ${cellChanges.length} cell change(s)`);
    }

    if (hotInstance) {
      for (const change of cellChanges) {
        const cell = change.payload.doc.data();
        let value;
        if (change.type === "removed") {
          value = undefined;
        } else {
          if ( cell.value === null || cell.value === "null" ) {
            value = undefined;
          } else {
            value = cell.value;
          }
        }
        const rowIndex = this.rowToIndex(cell.row);
        if (rowIndex >= 0 && !this.isDatasetEmpty) {
          const colExists = columns.find(col => col.data === cell.col);
          if (colExists) {
            const data = hotInstance.getDataAtRowProp(rowIndex, cell.col) + "";
            if (data !== value) {
              console.log(`New value: ${value} !== ${data}`);
              hotInstance.setDataAtRowProp(rowIndex, cell.col, value, "observe");
            }
          }
        }
        this.updateDB(cell.row, cell.col, value);
      }
      if (this.isDatasetEmpty) {
        this.rebuildAndLoad(hotInstance, columns);
      }
    }
  }

  public rebuildAndLoad(hotInstance: Handsontable, columns) {
    if (this.currentTab?.rows) {
      console.log('Rebuild and load data (start)');
      this.rebuildDataSet(this.currentTab.rows, columns);
      hotInstance.loadData(this.dataset);
      console.log('Rebuild and load end (end)');
    }
  }

  public indexToRow(index: number): number {
    if (!this.currentTab) {
      return -1;
    }

    if (index < 0 || index >= this.currentTab.rows.length) {
      console.log(`Index $i out of bounds`);
      return -1;
    }
    return this.currentTab.rows[index];
  }

  public rowToIndex(row: number): number {
    if (!this.currentTab) {
      return -1;
    }
    const index = this.currentTab.rows.indexOf(row);
    if (index < 0) {
      console.log(`Row ${index} not found in tab`);
    }
    return index;
  }

  reselectCurrentRow(hotInstance: Handsontable) {
    if (this.currentSelection) {
      hotInstance.selectCell(this.currentSelection.row, 0);
      if (this.currentSelection.row > 30) {
        hotInstance.scrollViewportTo(this.currentSelection.row - 10, 0);
      }
      console.log("Selecting row", this.currentSelection.row);
    }
  }

  private postChanges(changes: Handsontable.CellChange[], authState: AuthState): MenuCell[] {
    const mcs: MenuCell[] = [];
    const rowIndexesWhereValuesWasRemoved = new Set<number>();
    for (const change of changes) {
      const rowIndex = change[0];
      const row = this.indexToRow(rowIndex);
      if (row < 0) {
        // TODO
        return [];
      }
      const col: string = change[1] + "";
      const ov: string = change[2] != null ? change[2] + "" : undefined;
      const nv: string = change[3] != null ? change[3] + "" : undefined;
      if (ov !== nv) {
        const mc: MenuCell = {
          venueId: this.venueId,
          menuId: this.menuId,
          tabId: this.tabId,
          row,
          col,
          dataType: '',
          value: nv,
          userId: authState.user.id,
        };
        mcs.push(mc);
        this.updateDB(row, col, nv);
        if (this.hasAutoIdColumn) {
          this.updateIDCol(mcs, row, col, nv, authState);
          if (nv === undefined) {
            rowIndexesWhereValuesWasRemoved.add(row);
          }
        }
      } else {
        console.log("No change...");
      }
    }

    if (this.hasAutoIdColumn) {
      for (const row of rowIndexesWhereValuesWasRemoved) {
        this.removeIDCol(mcs, row, authState);
      }
    }
    return mcs;
  }

  public hasSelectedAllVisibleColumns(columns: any[], coords?: { startRow: number; startCol: number; endRow: number; endCol: number }[]): boolean {
    let selectedColumnCount;
    if (coords) {
      selectedColumnCount = coords[0].endCol - coords[0].startCol + 1;
    } else if (this.currentSelection) {
      selectedColumnCount = this.currentSelection.column2 - this.currentSelection.column;
    }
    console.log("selectedColumnCount", selectedColumnCount);
    console.log("visible count", columns.length);
    return columns.length === selectedColumnCount;
  }

  public hasDeletedAllVisibleValues(changes: Handsontable.CellChange[], source: Handsontable.ChangeSource, columns): boolean {
    // @ts-ignore
    if (source === "observe") { return false; }
    if (changes) {
      const rowIndexSetWithDeletedValue = new Set<number>();
      for (const change of changes) {
        const ov: string = change[2];
        const nv: string = change[3];
        if (ov != null && nv == null) {
          console.log("deleted value", ov);
          const rowIndex = change[0];
          rowIndexSetWithDeletedValue.add(rowIndex);
        }
      }

      if (rowIndexSetWithDeletedValue.size > 0) {
        for (const rowIndex of rowIndexSetWithDeletedValue.values()) {
          const row = this.indexToRow(rowIndex);
          if (row < 0) { continue; }
          const r = this.readRowFromDB(row);
          let rowHasAtLeastOneValue = false;
          let rowHasAtLeastOneVisibleValue = false;
          for (const v of Object.entries(r)) {
            const col = v[0];
            const cellVal = v[1];
            if (cellVal == null || col === "id") {
              continue;
            }
            rowHasAtLeastOneValue = true;
            const foundAValueInAVisibleColumn = columns.find(c => c.data === col);
            if (foundAValueInAVisibleColumn) {
              console.log("foundAValueInAVisibleColumn", v);
              rowHasAtLeastOneVisibleValue = true;
            }
          }
          if (rowHasAtLeastOneValue && !rowHasAtLeastOneVisibleValue) {
            console.log("Deleted a value and all values for the row is empty, remind that the product");
            return true;
          }
        }
      }
    }
    return false;
  }

  private updateIDCol(mcs, row: number, col: string, value: string, authState: AuthState) {
    if (col === 'name') {
      const notEmpty = typeof value !== 'undefined' && value;
      if (notEmpty) {
        const currId = this.readFromDB(row, "id");
        if (!currId || this.checkOnceAndRemoveFromRecentlyCopiedId(currId)) {
          value = value.replace(/ /g, "-");
          const newId = value.startsWith("@") ? "@" : value.replace(/[^0-9a-z-A-ZåäöÅÄÖ]/g, "").toLowerCase();
          const trimmed = newId.length > 20 ? newId.substring(0, 20) : newId;
          console.log(`Updating id to: ${trimmed}`);
          const mcId: MenuCell = {
            venueId: this.venueId,
            menuId: this.menuId,
            tabId: this.tabId,
            row,
            col: "id",
            dataType: '',
            value: trimmed,
            userId: authState.user.id,
          };
          mcs.push(mcId);
        }
      }
    }
  }

  private removeIDCol(mcs, row: number, authState: AuthState) {
    // Delete id row if empty
    const r = this.readRowFromDB(row);
    const found = Object.entries(r).find(k => k[0] !== "id" && k[1]);
    if (!found) {
      this.removeFromRecentlyCopiedIds(r.id);
      const mcId: MenuCell = {
        venueId: this.venueId,
        menuId: this.menuId,
        tabId: this.tabId,
        row,
        col: "id",
        dataType: '',
        value: undefined,
        userId: authState.user.id,
      };
      mcs.push(mcId);
    }
  }

  beforeChange(changes: Handsontable.CellChange[], source: Handsontable.ChangeSource): void {
    this.changesCache = changes;
  }

  afterChange(changes: Handsontable.CellChange[], source: Handsontable.ChangeSource, authState: AuthState): MenuCell[] {
    this.changesCache = null;
    // @ts-ignore
    if (source === "observe") {
      return [];
    }

    if (changes) {
      console.log("afterChange", changes);
      //console.log(changes);
      //console.log(source);
      return this.postChanges(changes, authState);
    }
    return [];
  }

  filterColumns(columnFilter: string, columnsAll, columnTemplates): any[] {
    if (!columnFilter) {
      return [];
    }

    console.log(`New filter: ${columnFilter}`);

    let columns = [];
    let showList;
    if (columnFilter === "raw") {
      showList = columnsAll.map(c => c.data);
    } else {
      showList = columnTemplates[columnFilter].show;
    }
    columns = columnsAll.filter( c => showList.indexOf(c.data) >= 0 );

    // @ts-ignore
    console.log(`show columns: ${showList}`);
    console.log(columns);
    return columns;
  }

  showHideColumns(columns, hotSettings, hotInstance: Handsontable) {
    hotSettings.columns = columns;
    hotInstance.updateSettings(hotSettings);
    this.rebuildAndLoad(hotInstance, columns);
    this.reselectCurrentRow(hotInstance);
  }

  private getNewRowNumber(count: number): number {
    let newRowNumber;
    if (this.currentTab?.rowIndexHead) {
      // Use next
      newRowNumber = this.currentTab.rowIndexHead + 1;
    } else {
      // Find largest index
      newRowNumber = Math.max(...this.currentTab.rows) + 1;
    }
    this.currentTab.rowIndexHead = newRowNumber + count - 1;
    return newRowNumber;
  }

  insertRowsToDB(newRowNumber: number, rowIndex: number, rowCount: number) {
    let c = rowIndex;
    console.log(this.currentTab.rows);
    for (let i = 0; i < rowCount; i++) {
      this.currentTab.rows.splice( c, 0, newRowNumber);
      console.log(`inserting rowIndex ${c} rowNumber ${newRowNumber}`);
      newRowNumber++;
      c++;
    }
    console.log(this.currentTab.rows);
  }

  private deleteRowsFromDB(rowIndex: number, rowCount: number) {
    console.log(this.currentTab.rows);
    const deleted = this.currentTab.rows.splice( rowIndex, rowCount);
    console.log("Deleted", deleted);
    console.log(this.currentTab.rows);
  }

  private getSelectedRowCount(hot: Handsontable): number {
    const r = hot.getSelected();
    let count = 1;
    if (r) {
      count = Math.min(Math.abs(r[0][2] - r[0][0]) + 1, 100);
    }
    return count;
  }

  insertRowsCount(rowIndex: number, count: number) {
    const newRowNumber = this.getNewRowNumber(count);
    this.insertRowsToDB(newRowNumber, rowIndex, count);
  }

  insertRowsBySelection(rowIndex: number, hot: Handsontable) {
    const count = this.getSelectedRowCount(hot);
    this.insertRowsCount(rowIndex, count);
  }

  deleteRows(rowIndex: number, hot: Handsontable) {
    const count = this.getSelectedRowCount(hot);
    this.deleteRowsFromDB(rowIndex, count);
  }

  clearRows(rowIndex: number, hot: Handsontable, authState: AuthState): MenuCell[] {
    const menuCells: MenuCell[] = [];
    const count = this.getSelectedRowCount(hot);
    for (let i = 0; i < count; i++ ) {
      const rowCells = this.getMenuChangesForClearedRow(rowIndex + i, authState);
      menuCells.push(...rowCells);
    }
    return menuCells;
  }

  copyRows(startRow: number, endRow: number) {
    const copiedRows = [];
    for (let i = startRow; i <= endRow; i++) {
      const row = this.indexToRow(i);
      const r = this.readRowFromDB(row);
      const cr = _.cloneDeep(r);
      copiedRows.push(cr);
      console.log(cr);
    }
    globalCopiedRows = copiedRows;
    return globalCopiedRows;
  }

  public getMenuChangesForCopiedRows(startRow: number, authState: AuthState): MenuCell[] {
    const mcs: MenuCell[] = [];
    let i = 0;
    for (const rowCopy of globalCopiedRows) {
      if (!NodeUtils.isNullOrEmpty(rowCopy)) {
        const row = this.indexToRow(startRow + i);
        for (const [key, value] of Object.entries(rowCopy)) {
          console.log(key, value);
          const mc: MenuCell = {
            venueId: this.venueId,
            menuId: this.menuId,
            tabId: this.tabId,
            row,
            col: key,
            dataType: '',
            value: value?.toString(),
            userId: authState.user.id,
          };
          mcs.push(mc);
          this.addToRecentlyCopiedIds(key, value);
          this.updateDB(row, key, value.toString());
        }
      }
      i++;
    }
    return mcs;
  }

  public getMenuChangesForClearedRow(rowIndex: number, authState: AuthState): MenuCell[] {
    const row = this.indexToRow(rowIndex);
    const r = this.readRowFromDB(row);
    if (NodeUtils.isNullOrEmpty(r)) { return []; }
    const mcs: MenuCell[] = [];
    for (const [key, value] of Object.entries(r)) {
      console.log(key, value);
      const mc: MenuCell = {
        venueId: this.venueId,
        menuId: this.menuId,
        tabId: this.tabId,
        row,
        col: key,
        dataType: '',
        value: null,
        userId: authState.user.id,
      };
      mcs.push(mc);
      if (key === "id") { this.removeFromRecentlyCopiedIds(value as string); }
      //this.updateDB(row, key, null);
    }
    return mcs;
  }

  hasDataInClipboard() {
    return !NodeUtils.isNullOrEmpty(globalCopiedRows);
  }

  getDataInClipboard() {
    return globalCopiedRows;
  }

  private addToRecentlyCopiedIds(key, value) {
    if (key !== "id") { return; }
    if (!recentlyCopiedIds) { recentlyCopiedIds = []; }
    recentlyCopiedIds.push(value);
    console.log(`>>>>>> Added ${value} to recently copied ids (${recentlyCopiedIds})`);
  }

  private removeFromRecentlyCopiedIds(itemId: string) {
    if (itemId == null) { return; }
    if (recentlyCopiedIds) {
      const was = recentlyCopiedIds.includes(itemId);
      if (was) {
        const i = _.findIndex(recentlyCopiedIds, x => x === itemId);
        console.log(`index ${i}`);
        recentlyCopiedIds.splice(i, 1);
        console.log(`>>>>>> Removed ${itemId} from recently copied ids (${recentlyCopiedIds})`);
      }
      return was;
    }
    return false;
  }

  private checkOnceAndRemoveFromRecentlyCopiedId(itemId: string) {
    return this.removeFromRecentlyCopiedIds(itemId);
  }
}
