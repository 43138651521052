import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent implements OnInit {

  constructor(
    public auth: AuthService,
  ) { }

  @Output() tog = new EventEmitter();
  public isSuperAdmin = false;
  public toggle(): void {
    this.tog.emit("toggle");
  }
  ngOnInit(): void {
    this.isSuperAdmin = AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }

}
