import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {Receipt} from "../../models/order";
import {MatSort, MatSortable} from "@angular/material/sort";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {FireService} from "../../services/fire.service";
import {AuthService} from "../../services/auth.service";
import {Voucher} from "../../models/voucher";
import {SelectionModel} from "@angular/cdk/collections";
import {MatSnackBar} from "@angular/material/snack-bar";
import {VenueService} from "../../services/venue.service";
import { throttleTime } from 'rxjs/operators';
@Component({
  selector: 'app-voucher-list',
  templateUrl: './voucher-list.component.html',
  styleUrls: ['./voucher-list.component.css']
})
export class VoucherListComponent implements OnInit, OnDestroy {

  displayedColumns = ["select", "active", "name", "price_cent", "state", "voucher_id", "created"];
  dataSource: MatTableDataSource<Voucher> = new MatTableDataSource([]);

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  private obs: Subscription;
  private venueId: number;
  vouchers: Voucher[];
  selection = new SelectionModel<Voucher>(true, []);

  constructor(private route: ActivatedRoute, private fire: FireService, private venueService: VenueService, public authService: AuthService, private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.sort.sort(({id: 'created', start: 'desc'}) as MatSortable);
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.beginObserve();
    });
  }

  ngOnDestroy(): void {
    this.obs?.unsubscribe();
  }

  private beginObserve() {
    this.obs = this.fire.observeVouchers(this.venueId).pipe(throttleTime(4000)).subscribe(vouchers => {
      this.vouchers = vouchers;
      this.refresh();
    });
  }

  private refresh() {
    this.dataSource.sort = this.sort;
    // TODO, add a toggle and filter stuff out here
    const filtered = this.vouchers; //.filter(cl => (cl.clientStatus !== DeviceStateStatus.Offline || !this.hideOffline) && (!DeviceStateIsOld(cl.updated, 24 * 60) || !this.hideOld));
    this.dataSource.data = filtered;
  }

  select(receipt: Receipt) {
    // console.log(receipt);
    // this.selected.emit(receipt);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.filter(row => row.active).length;
    return numSelected === numRows;
  }

  trackItem(index: number, item: Voucher): string {
    return item.voucher_id;
  }

  masterToggle() {
    this.anySelected() ? this.selection.clear() : this.dataSource.data.filter(row => row.active).forEach(row => this.selection.select(row));
  }

  private anySelected() {
    const numSelected = this.selection.selected.length;
    return numSelected > 0;
  }

  consume() {
    const voucherIds = this.selection.selected.map(voucher => parseInt(voucher.voucher_id, 10));
    this.venueService.consumeVouchers(this.venueId, voucherIds).then(() => {
      this.selection.clear();
      this.snackBar.open(`Konsumera ${voucherIds.length}st vouchers...`, "", {duration: 5000});
    }, error => { this.snackBar.open(error, "Dismiss", {duration: 5000}); });
  }

  isSelected(row) {
    const v = this.selection.selected.find(voucher => voucher.voucher_id === row.voucher_id);
    return v !== undefined;
  }
}
