export const pageSwishSettings = {
  title: {sv: "Swish"},
  page_settings: {
    root_path: "swish"
  },
  desc: {sv: "Justera inställningar för Swish"},
  properties: [
    {key: "active", title: {sv: "Swish aktivt"}, desc: {sv: "Aktivera Swish-betalning"}, type: "bool"},
    {key: "alias", title: {sv: "Swishnummer"}, desc: {sv: "Ange ditt swishnummer"}, type: "string", optional: false},
    {key: "dpp_alias", title: {sv: "Kortnamn"}, desc: {sv: "Kortnamn som visas i kassan"}, type: "string", optional: true},
    {key: "setup", title: {sv: "Swish certifikat"}, desc: {sv: "Vilket namngivet certifikat ska användas?"}, type: "string", optional: true},
  ]
};
