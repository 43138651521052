import Handsontable from 'handsontable';

const rcCol = {/*"3_3":"#ff0000"*/};

let storageBucketBaseUrl = "";

export function setStorageBucketUrl(url: string) {
  storageBucketBaseUrl = url;
}

function getRenderer(cellType) {
  //console.log("cellType", cellType);
  if (cellType === "checkbox") {
    return Handsontable.renderers.CheckboxRenderer;
  } else {
    return Handsontable.renderers.TextRenderer;
  }
}

export function categoryRowRenderer(instance, td, row, col, prop, value, cellProperties) {
  getRenderer(cellProperties.cellType).apply(this, arguments);
  td.style.fontWeight = 'bold';
  td.style.color = '#000';
  td.style.background = '#b7e1cd';
}

export function sectionRowRenderer(instance, td, row, col, prop, value, cellProperties) {
  getRenderer(cellProperties.cellType).apply(this, arguments);
  td.style.fontWeight = 'bold';
  td.style.fontSize = '20px';
  td.style.color = '#000';
  td.style.background = '#ffe599';
}

export function includeRowRenderer(instance, td, row, col, prop, value, cellProperties) {
  getRenderer(cellProperties.cellType).apply(this, arguments);
  td.style.fontWeight = 'bold';
  td.style.color = '#000';
  td.style.background = '#b5d2ff';
}

export function itemRowRenderer(instance, td, row, col, prop, value, cellProperties) {
  getRenderer(cellProperties.cellType).apply(this, arguments);
}

export function disabledItemRowRenderer(instance, td, row, col, prop, value, cellProperties) {
  getRenderer(cellProperties.cellType).apply(this, arguments);
  const c = rcCol[`${row}_${col}`];
  td.style.background = '#f1f1f1';
  td.style.color = "#9b9b9b";
}

export function onlyPOSItemRowRenderer(instance, td, row, col, prop, value, cellProperties) {
  getRenderer(cellProperties.cellType).apply(this, arguments);
  const c = rcCol[`${row}_${col}`];
  td.style.color = "#2196f3";
}

function dividerRowRenderer(instance, td, row, col, prop, value, cellProperties) {
  getRenderer(cellProperties.cellType).apply(this, arguments);
  td.style.background = '#f1f1f1';
}

export function imageRenderer(instance, td, row, col, prop, value, cellProperties) {
  const img = document.createElement('IMG');
  if (value) {
    // @ts-ignore
    img.src = storageBucketBaseUrl + "/" + value;
    img.className = "ht-cell-image";
    Handsontable.dom.addEvent(img, 'mousedown', event => {
      event.preventDefault(); // prevent selection quirk
    });
    Handsontable.dom.empty(td);
    td.appendChild(img);
  } else {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
  }

  if (cellProperties.rowType === "CAT") {
    td.style.background = '#b7e1cd';
  } else if (cellProperties.rowType === "SEC") {
    td.style.background = '#ffe599';
  } else if (cellProperties.rowType === "INC") {
    td.style.background = '#b5d2ff';
  } else if (cellProperties.rowType === "OFF") {
    td.style.background = '#f1f1f1';
  }

}
