<!-- Mitigate screen burn in -->
<div *ngIf="venueClosed || !config" class="black-screen">
  <div style="display: flex; flex-direction: row; justify-content: center; height: 100%; align-items: center;z-index: 200;">
    <mat-spinner color="primary" [diameter]="50" *ngIf="!config"></mat-spinner>
  </div>
</div>

<div class="title-bar" style="height: 11vh" *ngIf="display?.display_name">
  <div class="title" style="flex: 1;">{{display?.display_name}}</div>
</div>

<div class="title-bar" style="height: 11vh" *ngIf="config">
  <div class="section" style="flex: 1;">{{leftTitle}}</div>
  <div class="section" style="flex: 1;">{{rightTitle}}</div>
</div>

<div class="screen-container" style="height: 85vh" *ngIf="config">
  <div style="flex: 1">
    <div class="order-container">
      <div class="order-box" id="inactiveOrderBox" *ngFor="let bong of activeOrders">
        <div class="order-number">{{bong.getOrderNumber()}}</div>
      </div>
      <div class="hack-box"></div>
      <div class="hack-box"></div>
    </div>
  </div>

  <div class="border"></div>

  <div style="flex: 1">
    <div class="order-container">
      <div class="order-box" id="activeOrderBox" *ngFor="let bong of inactiveOrders">
        <div class="order-number">{{bong.getOrderNumber()}}</div>
      </div>
      <div class="hack-box"></div>
      <div class="hack-box"></div>
    </div>
  </div>
</div>

<img class="logo" [src]="logoUrl" alt="logo" *ngIf="config && !display?.hide_logo">
