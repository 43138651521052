<div *ngIf="miniTickets" class="ticket-panel">
  <div class="open-panel message-cont">
    <div class="panel-title">Ärenden</div>
    <div class="quick-add" *ngIf="!quickAddActive">
      <button class="icon-text" mat-button (click)="openEmptyTicketDialog()"><mat-icon>add</mat-icon>Nytt ärende</button>
      <mat-checkbox [(ngModel)]="showArchived" (change)="toggleArchived()" color="primary">Visa arkiverat</mat-checkbox>
    </div>

    <div *ngIf="quickAddActive" class="ticket-form">
      <div style="display: flex; justify-content: space-between; align-items: center;">
        <mat-form-field class="ta" appearance="outline" style="margin-bottom: -1.25em">
          <mat-label>Titel</mat-label>
          <input matInput [(ngModel)]="title" (keyup.enter)="send()">
          <mat-icon matSuffix (click)="toggleQuickAdd()" style="cursor: pointer;">close</mat-icon>
        </mat-form-field>
      </div>
        <div class="icon-text" style="justify-content: flex-end;">
        <button mat-flat-button (click)="send()">Lägg till</button>
      </div>
    </div>

    <div class="panel-body">
      <div class="messages">
        <div *ngFor="let ticket of miniTickets" class="bubble" (click)="openTicketDialog(ticket)">
          <div [innerHTML]="ticket.title" style="font-weight: 500;"></div>
          <pre [innerHTML]="ticket.content" class="ticket-content"></pre>
          <div class="bottom-bar">
            <span>{{ticket.ticket_info}}</span>
            <span>{{showStateSymbol(ticket.state)}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
