<mat-card>
    <mat-card-title>CHANGE PASSWORD</mat-card-title>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="submit()" *ngIf="!(loadingService.visibility | async)">
            <p>
                <mat-form-field>
                    <input [type]="hideP ? 'password' : 'text'" matInput id="password"
                       formControlName="password" placeholder="Password" name="password">
                    <button mat-icon-button matSuffix type="button" (click)="hideP = !hideP" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hideP">
                        <mat-icon>{{hideP ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('password').hasError('minlength')">Password must have at least 6 characters</mat-error>
                    <mat-error *ngIf="form.get('password').hasError('required')">Required</mat-error>
                </mat-form-field>
                </p>
                <p>
                 <mat-form-field>
                    <input [type]="hideCP ? 'password' : 'text'" matInput id="confirmPassword"
                        formControlName="confirmPassword" placeholder="Confirm password" name="confirmPassword">
                    <button mat-icon-button matSuffix type="button" (click)="hideCP = !hideCP" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hideCP">
                        <mat-icon>{{hideCP ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('confirmPassword').hasError('required')">Required</mat-error>
                    <mat-error *ngIf="form.get('confirmPassword').hasError('minlength')">Password must have at least 6 characters</mat-error>
                    <mat-error *ngIf="form.hasError('noMatch')">Password don't match</mat-error>
                    
                </mat-form-field>
            </p>

            <div class="button">
                <button mat-flat-button color="primary" class="submit-btn" type="submit" mat-button [disabled]="!form.valid && form.touched">Reset password</button>
            </div>
             <mat-error class="error">
                {{error}}
            </mat-error>

        </form>
        <mat-spinner [diameter]="200" *ngIf="loadingService.visibility | async"></mat-spinner>
    </mat-card-content>
</mat-card>
