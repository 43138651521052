import {Component, OnInit, ViewChild} from '@angular/core';
import {DataFormSchema} from "../../../../models/data-form";
import {DataFormComponent} from "../../../data-form/data-form.component";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../../services/auth.service";
import {FireService} from "../../../../services/fire.service";
import {MenuStateService} from "../../../catalog/menu-utils/menu-state.service";
import {MatDialog} from "@angular/material/dialog";
import {PublishService} from "../../../../services/publish.service";
import {ApiToken} from "../../../../models/api-token";
import {MatTableDataSource} from "@angular/material/table";
import {VenueService} from "../../../../services/venue.service";
import { VenueMapService } from 'src/app/services/venue-map.service';
import { firstValueFrom, take } from 'rxjs';

@Component({
  selector: 'app-token-endpoint-settings',
  templateUrl: './token-endpoint-settings.component.html',
  styleUrls: ['./token-endpoint-settings.component.css']
})
export class TokenEndpointSettingsComponent implements OnInit {
  tokenName: string;

  dataFormSchema: DataFormSchema = {
    title: {sv: "Api Token settings"},
    desc: {sv: "Ställ in tab"},
    properties: [
      {key: "name", title: {sv: "Namn"}, type: "string", optional: false, visible: true},
      {key: "email", title: {sv: "E-post"}, type: "string", optional: false, visible: true},
      {key: "state", title: {sv: "Status"}, desc: {sv: "'valid' or other state"}, type: "string", optional: false},
      {key: "roles", title: {sv: "Rättigheter"}, desc: {sv: ""}, type: "table",
        columns: [
          {key: "venue_name", title: {sv: "venue_name (readonly)"}, type: "string", width: 200, optional: true },
          {key: "venue_id", title: {sv: "venue_id"}, type: "integer", width: 200 },
          {key: "endpoint", title: {sv: "endpoint"}, type: "string", width: 200 }
        ]
      }
    ]
  };
  @ViewChild("dataform") dataForm: DataFormComponent;
  apiToken: ApiToken;
  venues: any[] = [];

  constructor(private route: ActivatedRoute, private authService: AuthService, private fire: FireService, private state: MenuStateService,
              private router: Router, private dialog: MatDialog, private publish: PublishService, private venueService: VenueService, private venueMapService: VenueMapService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      const tokenId = data.get("token");
      this.fetchData(tokenId);
    });
  }

  private async fetchData(tokenId: string) {
    try {
      const [token, venueConfigs] = await Promise.all([
        this.venueService.fetchApiToken(tokenId),
        firstValueFrom(this.venueMapService.getVenueConfigs().pipe(take(1)))
      ]);
      this.apiToken = token;
      this.venues = venueConfigs.map(config => ({
        venueId: config.venueId,
        name: config.venue?.name
      }));
      this.populateVenueNames();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  private populateVenueNames() {
    if (this.apiToken) {
      this.apiToken.roles.forEach(role => {
        const matchedVenue = this.venues.find(v => {
          return v.venueId.toString() === role.venue_id.toString();
        });
        if (matchedVenue) {
          role.venue_name = matchedVenue.name;
        } else {
          role.venue_name = 'Namnlös';
        }
      });
    }
  }

  async save() {
    const data = this.dataForm.collectData();
    this.apiToken.name = data.name;
    this.apiToken.email = data.email;
    this.apiToken.state = data.state;
    this.apiToken.roles = data.roles.map(role => {
      const { venue_name, ...rest } = role;
      return rest;
    });
    console.log(this.apiToken);
    this.venueService.updateApiToken(this.apiToken).then(r => {
    });
  }
}
