<h2 mat-dialog-title>Redigera '{{term.type}}'</h2>
<div class="container">
  <div class="content">
    <div class="scroll-panel">
      <div class="titl">Valda</div>
      <div class="selected-panel">
        <div *ngFor="let val of values" class="value" (click)="removeValue(val)">{{val}}</div>
      </div>
    </div>
    <div class="scroll-panel">
      <div class="titl">Valbara</div>
      <div class="available-panel">
        <div *ngFor="let val of filteredValues" class="value" (click)="addValue(val)">{{val}}</div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button (click)="cancelClicked()">Avbryt</button>
    <button mat-button (click)="deleteClicked()">Ta bort</button>
    <button mat-button (click)="okClicked()">OK</button>
  </div>
</div>
