<div class="detail-container">
  <div class="toolbar">
    <span><mat-icon>event</mat-icon></span>
    <span style="font-weight: bold;">{{booking?.booking.user_name ?? "Bokning"}}</span>
    <button mat-button (click)="closeSidePanel()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="detail-body" style="position: relative; padding-bottom: 60px;">
    <app-dossier-viewer *ngIf="booking" [printInstructions]="booking.dossier.instructions"></app-dossier-viewer>
    <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="showSpinner"></mat-spinner>
  </div>
</div>
