import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from '@angular/router';
import { DataFormComponent } from 'src/app/components/data-form/data-form.component';
import { SimpleDialogComponent } from 'src/app/components/simple-dialog/simple-dialog.component';
import { DataFormSchema } from 'src/app/models/data-form';
import { FbBot } from 'src/app/models/fb-bots';
import { VenueService } from 'src/app/services/venue.service';

@Component({
  selector: 'app-edit-fb-bot',
  templateUrl: './edit-fb-bot.component.html',
  styleUrls: ['./edit-fb-bot.component.css']
})
export class EditFbBotComponent implements OnInit, OnDestroy {
  WHITELIST_DOMAINS = "whitelist_domains";
  CONFIG_START_BUTTON = "config_start_button";
  SET_WELCOME_MESSAGE = "set_welcome_message";
  CONFIG_PERSISTENT_MENU = "config_persistent_menu";

  dataFormSchema: DataFormSchema = {
    title: { sv: "Tab settings" },
    desc: { sv: "Ställ in tab" },
    properties: [
      { key: "token", title: { sv: "Token" }, desc: { sv: "" }, type: "string", optional: false },
      { key: "app_id", title: { sv: "App ID" }, desc: { sv: "" }, type: "string", optional: false },
      { key: "page_id", title: { sv: "Page ID" }, desc: { sv: "" }, type: "string", optional: false },
      { key: "venue_id", title: { sv: "Venue ID" }, desc: { sv: "" }, type: "venue_enum", optional: false, custom_button: "venue_picker", readonly: true },
    ]
  };
  botData: FbBot;

  @ViewChild("dataform") dataForm: DataFormComponent;
  private botId: number;

  public pageTitle = "Edit bot";
  public welcomeMessage: string;
  public actionResponseMessage: string;
  public persistentMenuName: string;
  public persistentMenuType: string;
  public newBot = false;

  constructor(private route: ActivatedRoute, private dialog: MatDialog,
              private venueService: VenueService, private snackBar: MatSnackBar,
              private router: Router,) { }

  ngOnInit(): void {
    this.persistentMenuType = "type1b";
    this.route.paramMap.subscribe(data => {
      const id = data.get("id");
      if (id.startsWith("dup:")) {
        this.newBot = true;
        this.botId = null;
        const dupKey = Number(id.split(":")[1]);
        this.venueService.getFbBot(dupKey).then(r => {
          this.botData = r;
        });
      } else if (id.startsWith("new:")) {
        this.newBot = true;
        this.pageTitle = "Create bot";
        this.botId = null;
        this.botData = {
          app_id: "",
          display_name: "",
          page_id: "",
          token: "",
          venue_id: null,
        } as FbBot;
      } else {
        this.botId = Number(id);
        this.beginObserving();
      }
    });
  }

  ngOnDestroy(): void {
  }

  private beginObserving() {
    this.venueService.getFbBot(this.botId).then(r => {
      this.botData = r;
    });
  }

  executeBotAction(bot_action: string, value?: string, type?: string) {
    this.venueService.executeBotAction(this.botId, bot_action, value, type).then(r => {
      this.actionResponseMessage = bot_action + ': ' + JSON.stringify(r);
      this.snackBar.open(`Action ${bot_action} executed`, "", { duration: 2000 });
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

  save() {
    const data = this.dataForm.collectData();
    if (this.botId) {
      data.id = this.botId;
    }
    this.venueService.editFbBot(data).then(r => {
      this.botData = r;
      this.snackBar.open("Bot saved", "", { duration: 2000 });
      if (this.newBot) {
        this.newBot = false;
        this.botId = r.id;
        this.pageTitle = "Edit bot";
        this.router.navigate(["/fb-bots/bot", this.botId]);
      }
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

}
