<div *ngIf="chain">
  <p>
    <mat-toolbar>
      <button mat-icon-button routerLink="/chains"><mat-icon>arrow_back_ios</mat-icon></button>
      <span>Chain > {{chain.name}}</span>
      <span class="spacer"></span>
      <button mat-button [matMenuTriggerFor]="menuSave"><mat-icon>save</mat-icon></button>
      <mat-menu #menuSave="matMenu">
        <button mat-menu-item (click)="save()">Save</button>
      </mat-menu>
    </mat-toolbar>
  </p>
</div>

<div *ngIf="chain" style="margin:16px;padding: 16px; border: 1px solid grey; font-family: 'Lucida Console', Courier, monospace;">
  Path: {{chain.path}}
</div>

<app-data-form #dataform [schema]="dataFormSchema" [root]="chain" *ngIf="chain" (save)="save()"></app-data-form>
