import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {HotDB} from "../menu-utils/HotDB";
import {AuthState} from "../../../models/signin";
import {MatDialog} from "@angular/material/dialog";
import {FireService} from "../../../services/fire.service";
import {MenuStateService} from "../menu-utils/menu-state.service";
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import {HotTableRegisterer} from "@handsontable/angular";
import Handsontable from "handsontable";
import {from, Subscription} from "rxjs";
import {MenuTab} from "../menu-models/MenuChange";
import CellChange = Handsontable.CellChange;
import ChangeSource = Handsontable.ChangeSource;
import {MenuSelectItemsDialogComponent} from "./menu-select-items-dialog/menu-select-items-dialog.component";
import {Item} from "../menu-models/PublishModels";
import {flatMap, toArray} from "rxjs/operators";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-menu-pushpages-tab',
  templateUrl: './menu-pushpages-tab.component.html',
  styleUrls: ['./menu-pushpages-tab.component.css']
})
export class MenuPushpagesTabComponent implements OnInit, OnDestroy {
  private venueId: number;
  private hotDB: HotDB;
  private authState: AuthState;
  private dialogRef = null;
  private itemsPerTab: Item[][];
  private itemsList: Item[];

  constructor(public dialog: MatDialog, private fire: FireService, private state: MenuStateService, private route: ActivatedRoute,
              private authService: AuthService, private zone: NgZone) { }
  private hotRegisterer = new HotTableRegisterer();

  hotSettings: Handsontable.GridSettings = {};
  hotId = "hotOptionsId";
  tabId = "pushpages";
  hotInstance: Handsontable;
  private tabSub: Subscription;
  private cellSub: Subscription;
  private filterSub: Subscription;

  columns = [
    {data: "id", title: "Id", width: 50},
    {data: "title", title: "Title", width: 200, type: "text" },
    {data: "title1", title: "Title 1", width: 80, type: "text" },
    {data: "items1", title: "Items 1", width: 200, type: "text" },
    {data: "title2", title: "Title 2", width: 80, type: "text" },
    {data: "items2", title: "Items 2", width: 200, type: "text" },
    {data: "title3", title: "Title 3", width: 40, type: "text" },
    {data: "items3", title: "Items 3", width: 100, type: "text" },
    {data: "title4", title: "Title 4", width: 40, type: "text" },
    {data: "items4", title: "Items 4", width: 100, type: "text" },
  ];

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      console.log(`this.venueId = ${this.venueId}, this.tabId = ${this.tabId}`);

      this.hotDB = new HotDB(this.venueId, this.tabId, this.state.menuId, true);
      this.authState = null;

      this.authService.currentAuthState().subscribe(auth => {
        const first = this.authState == null;
        this.authState = auth;
        if (first) {
          this.setupHandsontableSettings();
          this.beginObserving();
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.tabSub?.unsubscribe();
    this.cellSub?.unsubscribe();
    this.filterSub?.unsubscribe();
  }

  private setupHandsontableSettings() {
    const that: MenuPushpagesTabComponent = this;
    this.hotSettings = {
      licenseKey: environment.handson_key,
      startRows: 10,
      autoWrapCol: false,
      autoWrapRow: false,
      colHeaders: true,
      rowHeaders: true,
      stretchH: 'all',
      width: '100%',
      height: '100%',
      manualColumnResize: true,
      columns: this.columns,
      nestedHeaders: [
        // eslint-disable-next-line max-len
        [{label: '', colspan: 2}, {label: 'Group 1', colspan: 2}, {label: 'Group 2', colspan: 2}, {label: 'Group 3', colspan: 2}, {label: 'Group 4', colspan: 2}],
        ['Push Id', 'Page title', 'Header', 'Items', 'Header', 'Items', 'Header', 'Items', 'Header', 'Items'],
      ],
      contextMenu: {
        items: {
          insert_row_above: {
            name() {
              return '<b>Insert row above</b>';
            },
            callback(key, selection, clickEvent) {
              const row = selection[0].start.row;
              const hot: Handsontable = this;
              that.insertRow(row, this, () => {});
            }
          },
          delete_row: {
            name() {
              return '<b>Delete row(s)</b>';
            },
            callback(key, selection, clickEvent) {
              const row = selection[0].start.row;
              const hot: Handsontable = this;
              that.deleteRow(row, this);
            }
          },
        }
      },
    };
  }

  private beginObserving() {
    this.checkThatTabExists(this.venueId, this.state.menuId, this.tabId);

    this.tabSub = this.fire.observeTab(this.venueId, this.state.menuId, this.tabId).subscribe(tab => {
      this.updateTab(tab);

      if (!this.hotInstance) {
        // TODO find better place to register the table, cant do in onNgInit, since table is not initialized yet
        const hotInstance = this.hotRegisterer.getInstance(this.hotId);
        this.hotInstance = hotInstance;
        const that = this;
        // eslint-disable-next-line max-len
        Handsontable.hooks.add('afterChange', (changes: CellChange[] | null, source: ChangeSource) => { that.afterChange(changes, source); }, hotInstance);
        Handsontable.hooks.add('afterBeginEditing', (row: number, column: number) => { that.afterBeginEditing(row, column); }, hotInstance);
      }
    });

    this.cellSub = this.fire.observeCells(this.venueId, this.state.menuId, this.tabId).subscribe(cellChanges => {
      const hotInstance = this.hotRegisterer.getInstance(this.hotId);
      this.hotDB.cellChangesObserved(cellChanges, this.hotInstance, this.columns);
    });

    this.collectAllItems();
  }

  private afterChange(changes: CellChange[] | null, source: ChangeSource) {
    const mcs = this.hotDB.afterChange(changes, source, this.authState);
    if ( mcs?.length > 0 ) {
      this.fire.updateMenuCells(mcs);
    }
  }

  private afterBeginEditing(rowIndex: number, columnIndex: number) {
    const col = this.columns[columnIndex];
    if ( ["items1", "items2", "items3", "items4"].includes(col.data) ) {
      const hotInstance = this.hotRegisterer.getInstance(this.hotId);
      hotInstance.selectCell(rowIndex, columnIndex);
      setTimeout( () => this.openSelectItemsDialog(hotInstance, rowIndex, columnIndex), 100 );
    }
  }


  private updateTab(tab: MenuTab) {
    console.log("Setting/updataing current tab: ", tab);
    this.hotDB.currentTab = tab;
    const hotInstance = this.hotRegisterer.getInstance(this.hotId);
    this.hotDB.rebuildAndLoad(hotInstance, this.columns);
  }

  private insertRow(rowIndex: number, hot: Handsontable, callback: (rowIndex: number) => void) {
    if (!this.hotDB.currentTab) { return; }
    this.hotDB.insertRowsBySelection(rowIndex, hot);
    this.fire.updateMenuTab(this.hotDB.currentTab).then( () => {
      callback(rowIndex);
    });
  }

  private deleteRow(rowIndex: number, hot: Handsontable) {
    if (!this.hotDB.currentTab) { return; }
    this.hotDB.deleteRows(rowIndex, hot);
    this.fire.updateMenuTab(this.hotDB.currentTab).then( () => {});
  }

  private checkThatTabExists(venueId: number, menuId: string, tabId: string) {
    this.state.createTab(venueId, menuId, tabId, tabId).subscribe( d => {});
  }

  openSelectItemsDialog(hotInstance, rowIndex, columnIndex) {
    if (this.dialogRef == null) {
      this.zone.run(() => {
        const row = this.hotDB.indexToRow(rowIndex);
        const title = this.hotDB.readFromDB(row, "title");
        const items = hotInstance.getDataAtCell(rowIndex, columnIndex);
        this.dialogRef = this.dialog.open(MenuSelectItemsDialogComponent, {
          minWidth: "400px",
          minHeight: "650px",
          data: { items, title, itemsList: this.itemsList }
        });
        this.dialogRef.afterClosed().subscribe(result => {
          console.log(result);
          if (result) {
            hotInstance.setDataAtCell(rowIndex, columnIndex, result);
          }
          this.dialogRef = null;
        });
      });
    }
  }

  private collectAllItems() {
    this.fire.getTabs(this.venueId, this.state.menuId).pipe(
      flatMap(tabs => from(tabs.filter( t => !["options", "pushpages"].includes(t.id))) ),
      flatMap( tab => this.state.getItems(this.venueId, this.state.menuId, tab) ),
      toArray()
    ).subscribe( k => {
      // A list of lists of items
      this.itemsPerTab = k;
      this.itemsList = [];
      for (const t of k) {
        for (const i of t) {
          if (i.name && i.id) {
            this.itemsList.push(i);
          }
        }
      }
      this.itemsList.sort((a, b) => a.id.localeCompare(b.id) );
    });
  }


}
