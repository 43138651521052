import {Component, OnDestroy, OnInit} from '@angular/core';
import {FilterExpression, FilterLogic, FilterTerm} from "../../utils/filter-logic";
import {EditFilterTermDialogComponent} from "./edit-filter-term-dialog/edit-filter-term-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {FireService} from "../../services/fire.service";
import {VenueConfig} from "../../models/venue-config";
import {ArrayUtils} from "../../utils/utils";
import {combineLatest, Subscription} from "rxjs";
import {FPData} from "../../models/floorplan";
import {OrderService} from "../../services/order.service";
import {MatTableDataSource} from "@angular/material/table";
import {Device} from "../../models/reg-unit";
import {VenueService} from "../../services/venue.service";
import {Clipboard} from "@angular/cdk/clipboard";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MenuItem} from "../../models/menu-structure";
import {PrintDossier} from "../../models/print-job";

@Component({
  selector: 'app-filter-editor',
  templateUrl: './filter-editor.component.html',
  styleUrls: ['./filter-editor.component.css']
})
export class FilterEditorComponent implements OnInit, OnDestroy{
  filterText = "";
  filterExps: FilterExpression[];
  private selectedTerm: FilterTerm;
  private selectedExp: FilterExpression;
  private venueId: number;
  private config: VenueConfig;
  private sub: Subscription;
  private floorplan: FPData;
  private availableDevices: string[];
  simParams= {};

  displayedColumns = ["name", "ic", "tags", "phase"];
  dataSource: MatTableDataSource<MenuItem>;
  dossiers: PrintDossier[];

  constructor(private route: ActivatedRoute, private dialog: MatDialog, private fire: FireService, private orderService: OrderService,
              private venueService: VenueService, private clipboard: Clipboard, private snackBar: MatSnackBar) {
    this.updateFilter();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.sub = combineLatest([
          this.fire.observeVenueConfig(this.venueId),
          this.fire.observeFSFloorplan(this.venueId),
        ]
      ).subscribe(res => {
        const config = res[0];
        const fsfp = res[1];
        this.config = config;
        if (fsfp.data_version === 2) {
          this.floorplan = JSON.parse(fsfp.data) as FPData;
        }
      });
    });
    this.route.queryParamMap.subscribe(data => {
      this.filterText = data.get("filter") ?? "";
      this.updateFilter();
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  updateFilter() {
    this.filterExps = FilterLogic.parseFilterExpressions(this.filterText);
    console.log(this.filterExps);
  }

  addTerm() {

  }

  toggleConnect() {

  }

  addNewTerm(type: string) {
    // @ts-ignore
    const term: FilterTerm = {type, "include": true, "values": []};
    this.showEditTermDialog(term, [], true);
  }

  async showEditTermDialog(term: FilterTerm, values: string[], addTerm: boolean) {
    if (term.type === "Device") {
      await this.fetchUsers();
    }
    const dialogRef = this.dialog.open(EditFilterTermDialogComponent, {
      minWidth: "800px",
      minHeight: "650px",
      data: {term, values, availableValues: this.getAvailableValues(term)}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        if (result == "--delete--") {
          this.selectedExp.terms = this.selectedExp.terms.filter(t => t !== term);
        } else {
          FilterLogic.setFilterTermValues(term, result);
          if (addTerm) {
            this.selectedExp.terms.push(term);
          }
        }
        this.updateTextFilter();
      }
    });
  }

  selectExp(exp: FilterExpression) {
    this.selectedExp = exp;
    console.log(exp);
  }

  selectTerm(term: FilterTerm, exp: FilterExpression) {
    this.selectedExp = exp;
    this.selectedTerm = term;
    console.log(term);
  }

  setSelectedTermInclude(include: boolean) {
    this.selectedTerm.include = include;
    this.updateTextFilter();
  }

  private updateTextFilter() {
    this.filterText = FilterLogic.serializeFilterExpressions(this.filterExps);
  }

  protected readonly FilterLogic = FilterLogic;

  private getAvailableValues(term: {type: string}) {
    switch (term.type) {
      case "IC":
        return this.getAvailableICValues();
      case "Tag":
        return this.getAvailableTagValues();
      case "Phase":
        return this.getAvailablePhaseValues();
      case "Area":
        return this.getAvailableAreaValues();
      case "Table":
        return this.getAvailableTableValues();
      case "Attribute":
        return this.getAvailableAttributeValues();
      case "OrderTag":
        return this.getAvailableOrderTagValues();
      case "Device":
        return this.getAvailableDeviceValues();
      case "DeliveryType":
        return this.getAvailableDeliveryTypeValues();
    }
  }

  editTerm(term: FilterTerm, exp: FilterExpression) {
    this.selectedExp = exp;
    const values = FilterLogic.getFilterTermValues(term);
    this.showEditTermDialog(term, values, false);
  }

  private getAvailableICValues() {
    return ArrayUtils.flattenSortIndentICTree(this.config.ic, [], 0, "\u00A0\u00A0", ["food", "drink"]);
  }

  private getAvailableTagValues() {
    return this.config.menu.editor_tags.map(t => t.tag);
  }

  private getAvailableAreaValues() {
    const s = new Set();
    for (const section of this.floorplan.sections) {
      for (const table of section.tables) {
        if (table.area) {
          s.add(table.area);
        }
      }
    }
    return Array.from(s).sort();
  }

  private getAvailableTableValues() {
    const s = new Set();
    for (const section of this.floorplan.sections) {
      for (const table of section.tables) {
        s.add(table.name);
      }
    }
    return Array.from(s).sort();
  }

  private getAvailableAttributeValues() {
    return ["attribute1", "attribute2", "attribute3"];
  }

  private getAvailableOrderTagValues() {
    return this.config.order_tags?.tags.map(t => t.tag) ?? [];
  }

  private getAvailableDeviceValues() {
    return this.availableDevices;
  }

  private async fetchUsers() {
    const data = await this.venueService.fetchDevices(this.venueId);
    this.availableDevices = data.map((d: Device) => d.email);
  }

  private getAvailableDeliveryTypeValues() {
    return ["inhouse", "takeaway", "pickup", "roomservice", "foodcourt"];
  }

  private getAvailablePhaseValues() {
    return this.orderService.getServingPhasesWithFallback(this.config).map(p => p.alias);
  }

  addOrFilter() {
    // @ts-ignore
    const fe = FilterLogic.buildFilterExpression("")
    this.filterExps.push(fe);
    this.updateTextFilter();
  }

  copyFilter() {
    this.clipboard.copy(this.filterText);
    this.snackBar.open(`Kopierade filter.`, "", {duration: 3000});
  }

  runFilter() {
    this.venueService.simulateFilter(this.venueId, this.filterText, this.simParams).then((result) => {
      this.dataSource = new MatTableDataSource(result);
    });
  }

  async selectSim(type: string, param: string) {
    if (type === "Device") {
      await this.fetchUsers();
    }

    const term = {type, include: true, values: []};
    const dialogRef = this.dialog.open(EditFilterTermDialogComponent, {
      minWidth: "800px",
      minHeight: "650px",
      data: {term, values: [], availableValues: this.getAvailableValues(term)}
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result) {
        if (result == "--delete--") {
          delete this.simParams[param];
        } else {
          this.simParams[param] = result[0];
        }
      }
    });

  }

  removeExp(exp: FilterExpression) {
    this.filterExps = this.filterExps.filter(e => e !== exp);
    this.updateTextFilter();
  }

  runOrderFilter() {
    this.venueService.simulateFilterOrderDossiers(this.venueId, this.filterText).then((result) => {
      this.dossiers = result;
    });

  }
}
