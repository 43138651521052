import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {catchError, flatMap} from "rxjs/operators";
import {of} from "rxjs";
import {AuthService} from "../../../services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {VenueService} from "../../../services/venue.service";
import {ApiService} from "../../../services/api.service";
import {Lead} from "../../../models/user";

@Component({
  selector: 'app-landing-go',
  templateUrl: './landing-go.component.html',
  styleUrls: ['./landing-go.component.css']
})
export class LandingGoComponent implements OnInit {
  hideP = true;
  sending = false;
  error: string;
  pw1 = new UntypedFormControl('', [Validators.required, Validators.min(6)]);
  private leadKey: string;
  private lead: Lead;
  leadIsNewUser: boolean;

  constructor(private authService: AuthService, private route: ActivatedRoute, private api: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.leadKey = data.get("lead_key");
      this.api.fetchLead(this.leadKey).then(lead => {
        this.lead = lead;
        console.log(lead);

        // Lead already set up => redirect somewhere
        // if (lead.venue_id && lead.user_key) {
        //   this.redirectToVenue();
        //   return;
        // }

        this.authService.getSignInMethods(lead.email).then(sim => {
          console.log(sim);
          // Check if account already created in firestore but not signed in
          this.leadIsNewUser = sim.length === 0;
          if (this.leadIsNewUser) {
            console.log("This lead has no firebase account, set password, and create...");
          } else {
            console.log("This lead already has a firebase account, login...");
          }
        });
      }).catch(error => {
        console.log(error);
        this.error = "Något fel har inträffat, har du internet kontakt?";
      });
    });

  }

  next() {
    if (this.leadIsNewUser) {
      this.setPassword();
    } else {
      this.login();
    }
  }

  setPassword() {
    if (this.pw1.valid) {
      this.error = null;
      this.link();
    } else {
      this.error = "Lösenordet behöver innehålla minst 6 tecken";
    }
  }

  private login() {
    this.error = null;
    const email = this.lead.email;
    const pw = this.pw1.value;
    this.authService.signInAndLinkFirebaseAccount(this.leadKey, email, pw).then(r => {
      console.log("User linked...");
      this.redirectToVenue();
    }).catch(error => {
      if (error.code === "auth/wrong-password") {
        this.error = "Fel lösenord";
      }
    });
  }

  public link(): void {
    const email = this.lead.email;
    const pw = this.pw1.value;
    this.authService.createAndLinkFirebaseAccount(this.leadKey, email, pw).then(r => {
      console.log("User linked...");
      this.redirectToVenue();
    });
  }

  private redirectToVenue() {
    console.log("Redirecting to venue...");
    this.router.navigateByUrl(`venue/${this.lead.venue_id}/dashboard`);
  }
}
