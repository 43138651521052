import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {VenueService} from "../../../services/venue.service";
import {LoadingService} from "../../../services/loading.service";
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-edit-section-dialog',
  templateUrl: './edit-section-dialog.component.html',
  styleUrls: ['./edit-section-dialog.component.css']
})
export class EditSectionDialogComponent implements OnInit {
  name = new UntypedFormControl('', [Validators.required]);
  title: string;
  button: string;

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<EditSectionDialogComponent>, private auth: AuthService) {
    this.name.setValue(data.name);
    this.title = data.title;
    this.button = data.button;
  }

  ngOnInit(): void {
  }

  ok() {
    if (this.name.valid) {
      this.dialogRef.close(this.name.value);
    }
  }

}
