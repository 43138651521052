import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {VenueService} from "../../../services/venue.service";
import {LoadingService} from "../../../services/loading.service";
import {NewUserDialogComponent} from "../../user-list/new-user-dialog/new-user-dialog.component";
import {HackUtils} from "../../../utils/utils";
import {NewStaffDialogComponent} from "../new-staff-dialog/new-staff-dialog.component";
import {EditRoleDialogComponent} from "../../user-list/edit-role-dialog/edit-role-dialog.component";
import {EditPinDialogComponent} from "../edit-pin-dialog/edit-pin-dialog.component";

@Component({
  selector: 'app-query-staff-dialog',
  templateUrl: './query-staff-dialog.component.html',
  styleUrls: ['./query-staff-dialog.component.css']
})
export class QueryStaffDialogComponent implements OnInit {
  // 8001011010 or 198001011010 or 800101-1010 or 19800101-1010 or 19801101-123
  pnumber = new UntypedFormControl('', [Validators.required, Validators.pattern("^(([0-9][0-9])?[0-9]{10}|([0-9]{6}|[0-9]{8})-[0-9]{3,4})$")]);
  indentity: string;
  venueId: number;

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<QueryStaffDialogComponent>, public dialog: MatDialog) {
    this.venueId = data.venueId;
  }

  ngOnInit(): void {
  }

  checkUser() {
    if (this.pnumber.valid) {
      let ident = this.pnumber.value.replace("-", "");
      if (ident.length < 11) {
        if (ident.startsWith("0")) {
          ident = "20" + ident;
        } else {
          ident = "19" + ident;
        }
      }
      this.indentity = ident;
      this.venueService.queryStaffIdent(ident, this.venueId).then( r => {
        console.log(`Exists ${r.exists}`);
        if (!r.exists) {
          this.openNewStaffDialog();
        } else {
          this.openEditPinDialog();
        }
      });
    }
  }

  getErrorMessage() {
    return "Ange personnummer 10 eller 12 siffror. Eller ÅÅÅÅMMDD-XXX om pnummer saknas.";
  }

  private openNewStaffDialog() {
    const dialogRef = this.dialog.open(NewStaffDialogComponent, HackUtils.DLG({
      data: { identity: this.indentity, venueId: this.venueId }
    }));
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
      if (result) {
        this.dialogRef.close(result);
      }
    });
  }

  openEditPinDialog() {
    const dialogRef = this.dialog.open(EditPinDialogComponent, HackUtils.DLG({
      data: { identity: this.indentity, venueId: this.venueId }
    }));
    dialogRef.afterClosed().subscribe(result => {
      console.log('Edit pin dialog result:', result);
      if (result) {
        this.dialogRef.close(result);
      }
    });
  }

}
