export const pageStaffSettings = {
  title: { sv: "Personal" },
  page_settings: {
    root_path: "staff",
  },
  desc: "Inställningar för personal",
  properties: [
    { key: "default_screensaver_delay_phone", title: { sv: "Skärmsläckare telefon" }, desc: { sv: "Förvald tid för skärmsläckare (telefon)" }, type: "integer", suffix: "sekunder", optional: true },
    { key: "default_screensaver_delay_tablet", title: { sv: "Skärmsläckare platta" }, desc: { sv: "Förvald tid för skärmsläckare (platta)" }, type: "integer", suffix: "sekunder", optional: true },
    { key: "pincode_required", title: { sv: "PIN-kod" }, desc: { sv: "Kräv PIN-kod vid inloggning" }, type: "bool", optional: true },
    { key: "use_old_staff_list", title: { sv: "Gamla personallistan" }, desc: { sv: "Använd gamla personallistan" }, type: "bool", optional: true },
  ]
};
