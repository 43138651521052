<div class="report-tree-container">

  <app-title-bar [title]="title"></app-title-bar>

  <div class="search-fields-root">
    <div class="search-fields">
      <mat-form-field appearance="outline">
        <mat-label>Day or start date</mat-label>
        <input matInput [(ngModel)]="searchStartDate" [matDatepicker]="startPicker">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker [touchUi]="isMobile"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>End date</mat-label>
        <input matInput [(ngModel)]="searchEndDate" [matDatepicker]="endPicker">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker [touchUi]="isMobile"></mat-datepicker>
      </mat-form-field>
      <button mat-flat-button class="search-button" (click)="updateParams()" color="primary">SÖK</button>
      <div class="action-buttons" *ngIf="!isMobile">
        <button mat-stroked-button #reportsMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="menuOptions">Filter</button>
        <button mat-stroked-button (click)="clearFilters()">RENSA</button>
      </div>
      <span class="spacer"></span>
      <button mat-button (click)="export('csv')" *ngIf="!isMobile"><mat-icon>download</mat-icon> Export (CSV)</button>
      <button mat-stroked-button *ngIf="isMobile" class="dropdown-button" #reportsMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="menuHandleSection"><mat-icon>expand_more</mat-icon></button>
      <mat-menu #menuHandleSection="matMenu">
        <button mat-menu-item #optionsMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="menuOptions">Filter</button>
        <mat-divider></mat-divider>
        <button mat-menu-item [matMenuTriggerFor]="menuExport">Exportera</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="clearFilters()">Rensa sökning</button>
      </mat-menu>
      <mat-menu #menuOptions="matMenu">
        <div style="padding: 8px;">
          <div *ngFor="let item of tempReportTreeOptions; let i = index;" style="display: flex;flex-direction: column;">
            <mat-checkbox style="padding: 8px 12px;" color="primary" #menuItems
                          [(ngModel)]="item.active"
                          (click)="$event.stopPropagation()">
              {{ item.title }}
            </mat-checkbox>
            <mat-divider *ngIf="[2, 5].includes(i)"></mat-divider>
          </div>
          <button mat-raised-button color="primary" (click)="applyFilters()" style="margin-top: 16px; width: 100%;">
            Använd filter
          </button>
        </div>
      </mat-menu>
      <mat-menu #menuExport="matMenu">
        <button mat-menu-item (click)="export('csv')">CSV-format</button>
<!--        <button mat-menu-item (click)="export('hogia')">PDF-format</button>-->
      </mat-menu>

    </div>
  </div>
  <div style="margin: 16px;" *ngIf="includeCogs">
    <div style="border-radius: 8px; background: #f4f4f4;padding: 16px;">
      COGS (Cost of Goods Sold) visas nu i rapporten. Denna funktion är i betaversion och visar inköpspriset för produkter vid försäljningstillfället. Observera att endast produkter med registrerat inköpspris vid försäljningen inkluderas i beräkningen. Detta ger en mer precis bild av din faktiska vinstmarginal per produkt och kategori.
    </div>
  </div>

  <div>
    <div class="base-tree-container">
      <div #treeHeader class="tree-header">
        <div class="tree-column tree-header-column"><span>namn</span></div>
        <div *ngFor="let columnName of columnsSwe" class="tree-column tree-header-column" [ngClass]="{'max-width': ['antal', ''].includes(columnName), 'small-col':!isMobile}">
          <span>{{ columnName }}</span>
        </div>
      </div>
      <div class="tree-wrapper">
        <tree-root #tree [options]="options" [nodes]="nodes" [(state)]="state">
          <!-- Node wrapper template to control wrapper classes and cursor behaviour -->
          <ng-template #treeNodeWrapperTemplate let-node let-index="index">
            <div class="tree-node-wrapper no-pointer">
              <tree-node-expander [node]="node"></tree-node-expander>
              <div class="node-content-wrapper pointer"
                   [class.node-content-wrapper-active]="node.isActive"
                   [class.node-content-wrapper-focused]="node.isFocused"
                   (click)="onRowSelect(node); node.mouseAction('click', $event)"
                   (dblclick)="node.mouseAction('dblClick', $event)"
                   (contextmenu)="node.mouseAction('contextMenu', $event)"
                   (treeDrop)="node.onDrop($event)"
                   [treeAllowDrop]="node.allowDrop"
                   [treeDrag]="node"
                   [treeDragEnabled]="node.allowDrag()">

                <div class="tree-column-wrapper">
                  <div class="tree-column" [title]="node.data.name">
                    <span>{{ node.data.name }}</span>
                  </div>
                  <div *ngFor="let columnName of columns" class="tree-column" [ngSwitch]="columnName" [ngClass]="{'max-width': ['item_count', 'actions'].includes(columnName), 'small-col':!isMobile}">
                    <span *ngSwitchCase="'priced_atr'">{{node.data[columnName]}}</span>
                    <span *ngSwitchCase="'item_count'">{{node.data[columnName] | amount}}</span>
                    <span *ngSwitchCase="'actions'">
                      <button mat-button style="min-width: unset; line-height:unset;" *ngIf="!node.children" [matMenuTriggerFor]="navigation">
                        <mat-icon style="transform: scale(0.7)">more_vert</mat-icon>
                      </button>
                      <mat-menu #navigation>
                        <button mat-menu-item (click)="queryDailySalesForItem(node.data.id)">Se försäljning per dag</button>
                      </mat-menu>
                    </span>
                    <span *ngSwitchDefault>{{node.data[columnName] | moneySuff:2:"":2}}</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </tree-root>
      </div>
    </div>
    <div class="no-data" *ngIf="noData">Perioden innehåller ingen försäljning</div>
  </div>

</div>
