import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {User} from "../../models/user";

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {
  user: User;

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
    this.authService.getLoggedInAuthState().subscribe(auth => {
      this.user = auth.user;
    });
  }

}
