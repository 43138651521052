import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {VenueService} from "../../../services/venue.service";
import {LoadingService} from "../../../services/loading.service";
import {AuthService} from "../../../services/auth.service";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {Admin, StaffRoleSchema} from "../../../models/user";

@Component({
  selector: 'app-edit-staff-dialog',
  templateUrl: './edit-staff-dialog.component.html',
  styleUrls: ['./edit-staff-dialog.component.css']
})
export class EditStaffDialogComponent implements OnInit {
  firstName = new UntypedFormControl('', [Validators.required]);
  lastName = new UntypedFormControl('', [Validators.required]);
  email = new UntypedFormControl('', [Validators.email]);
  phone = new UntypedFormControl('', []);
  role = new UntypedFormControl('', []);
  venueId: number;
  staff: Admin;
  roles: StaffRoleSchema[];

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<EditStaffDialogComponent>, private auth: AuthService, private dialog: MatDialog) {
    this.staff = data.staff;
    this.venueId = data.venueId;
    this.firstName.setValue(this.staff.first_name);
    this.lastName.setValue(this.staff.last_name);
    this.email.setValue(this.staff.email);
    this.phone.setValue(this.staff.phone);
    if (this.staff.roles != null && this.staff.roles.length > 0) {
      this.role.setValue(this.staff.roles[0]);
    }
  }

  ngOnInit(): void {
    this.venueService.fetchRoleSchema(0).then( r => {
      this.roles = r;
    });
  }

  saveUser() {
    if (this.firstName.valid && this.lastName.valid) {
      const firstName = this.firstName.value;
      const lastName = this.lastName.value;
      const email = this.email.value;
      const phone = this.phone.value;
      const role = this.role.value;
      const roles = role == null || role === "" ? undefined : [role];
      this.venueService.editStaff(firstName, lastName, this.staff.identity, this.venueId, email, phone, roles).then( r => {
        console.log("Edit staff result:", r);
        this.dialogRef.close(true);
      }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
    }
  }

  isHeynowAdmin() {
    return AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }
}
