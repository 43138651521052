import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute} from '@angular/router';
import {HotTableRegisterer} from '@handsontable/angular';
import Handsontable from 'handsontable';
import {Subscription} from 'rxjs';
import CellChange = Handsontable.CellChange;
import ChangeSource = Handsontable.ChangeSource;
import {MenuTab} from "../menu-models/MenuChange";
import {FireService} from "../../../services/fire.service";
import {MenuStateService} from "../menu-utils/menu-state.service";
import {HotDB} from "../menu-utils/HotDB";
import {AuthService} from "../../../services/auth.service";
import {AuthState} from "../../../models/signin";
import {whenValidator} from "../menu-utils/menu-validators";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-menu-options-tab',
  templateUrl: './menu-options-tab.component.html',
  styleUrls: ['./menu-options-tab.component.css']
})
export class MenuOptionsTabComponent implements OnInit, OnDestroy {
  private venueId: number;
  private hotDB: HotDB;
  private authState: AuthState;
  private columnFilter: string;

  constructor(public dialog: MatDialog, private fire: FireService, private state: MenuStateService, private route: ActivatedRoute,
              private authService: AuthService, private zone: NgZone) { }
  private hotRegisterer = new HotTableRegisterer();

  hotSettings: Handsontable.GridSettings = {};
  hotId = "hotOptionsId";
  tabId = "options";
  hotInstance: Handsontable;
  private tabSub: Subscription;
  private cellSub: Subscription;
  private filterSub: Subscription;

  columns = [
  ];

  columnsAll = [
    {data: "id", title: "Id", width: 100},
    {data: "name", title: "Name", width: 200, type: "text" },
    {data: "desc", title: "Description", width: 100, type: "text" },
    {data: "relPrice", title: "+/- price", width: 50, type: "numeric" },
    {data: "fixPrice", title: "Base price", width: 50, type: "numeric" },

    {data: "mp", title: "mjölkprot", width: 20, type: "text" },
    {data: "la", title: "laktos", width: 20, type: "text" },
    {data: "eg", title: "ägg", width: 20, type: "text" },
    {data: "gl", title: "gluten", width: 20, type: "text" },
    {data: "wh", title: "vete", width: 20, type: "text" },
    {data: "pe", title: "jordnöt", width: 20, type: "text" },
    {data: "cr", title: "kräftdjur", width: 20, type: "text" },
    {data: "ml", title: "blötdjur", width: 20, type: "text" },
    {data: "sf", title: "skaldjur", width: 20, type: "text" },
    {data: "fi", title: "fisk", width: 20, type: "text" },
    {data: "nu", title: "nötter", width: 20, type: "text" },
    {data: "se", title: "sesam", width: 20, type: "text" },
    {data: "ce", title: "selleri", width: 20, type: "text" },
    {data: "mu", title: "senap", width: 20, type: "text" },
    {data: "lu", title: "lupin", width: 20, type: "text" },
    {data: "so", title: "soya", width: 20, type: "text" },
    {data: "sul", title: "sulfit", width: 20, type: "text" },
    {data: "pork", title: "fläsk", width: 20, type: "text" },
    {data: "pia", title: "ananas", width: 20, type: "text" },
    {data: "stf", title: "stenfrukt", width: 20, type: "text" },
    {data: "gel", title: "gelatin", width: 20, type: "text" },
    {data: "soybean", title: "soyaböna", width: 20, type: "text" },
    {data: "ci", title: "citrus", width: 20, type: "text" },
    {data: "on", title: "onion", width: 20, type: "text" },
    {data: "ga", title: "garlic", width: 20, type: "text" },
  ];

  columnTemplates = {
    info: {show: ["id", "name", "desc", "relPrice", "fixPrice"]},
    allergenes: {show: ["name", "mp", "la", "eg", "gl", "wh", "pe", "cr", "ml", "sf", "fi", "nu", "se", "ce", "mu", "lu", "so",
        "sul", "pork", "pia", "stf", "gel", "soybean", "ci", "on", "ga"]},
  };

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      console.log(`this.venueId = ${this.venueId}, this.tabId = ${this.tabId}`);

      this.hotDB = new HotDB(this.venueId, this.tabId, this.state.menuId, true);
      this.authState = null;

      this.authService.currentAuthState().subscribe(auth => {
        const first = this.authState == null;
        this.authState = auth;
        if (first) {
          this.setupHandsontableSettings();
          this.beginObserving();
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.tabSub?.unsubscribe();
    this.cellSub?.unsubscribe();
    this.filterSub?.unsubscribe();
  }

  private setupHandsontableSettings() {
    const that: MenuOptionsTabComponent = this;
    this.hotSettings = {
      licenseKey: environment.handson_key,
      startRows: 10,
      autoWrapCol: false,
      autoWrapRow: false,
      colHeaders: true,
      rowHeaders: true,
      stretchH: 'all',
      width: '100%',
      height: '100%',
      manualColumnResize: true,
      columns: this.columns,
      // nestedHeaders: [
      //   [{label: '', colspan: 3}, {label: 'Price', colspan: 2}],
      //   ['ID', 'Name', 'Desc', '+/-', 'base'],
      // ],
      contextMenu: {
        items: {
          insert_row_above: {
            name() {
              return '<b>Insert row above</b>';
            },
            callback(key, selection, clickEvent) {
              const row = selection[0].start.row;
              const hot: Handsontable = this;
              that.insertRow(row, this, () => {});
            }
          },
          delete_row: {
            name() {
              return '<b>Delete row(s)</b>';
            },
            callback(key, selection, clickEvent) {
              const row = selection[0].start.row;
              const hot: Handsontable = this;
              that.deleteRow(row, this);
            }
          },
        }
      },
    };
  }

  private beginObserving() {
    this.checkThatTabExists(this.venueId, this.state.menuId, this.tabId);

    this.tabSub = this.fire.observeTab(this.venueId, this.state.menuId, this.tabId).subscribe(tab => {
      this.updateTab(tab);

      if (!this.hotInstance) {
        // TODO find better place to register the table, cant do in onNgInit, since table is not initialized yet
        const hotInstance = this.hotRegisterer.getInstance(this.hotId);
        this.hotInstance = hotInstance;
        const that = this;
        Handsontable.hooks.add('afterChange', (changes: CellChange[] | null, source: ChangeSource) => {
            that.afterChange(changes, source);
          }, hotInstance);

        this.filterSub = this.state.currentFilterSubject.subscribe( filter => {
          if (!(filter in this.columnTemplates)) {
            filter = "info";
          }
          this.columnFilter = filter;
          this.columns = this.hotDB.filterColumns(filter, this.columnsAll, this.columnTemplates);
          this.hotDB.showHideColumns(this.columns, this.hotSettings, this.hotInstance);
        });

      }
    });

    this.cellSub = this.fire.observeCells(this.venueId, this.state.menuId, this.tabId).subscribe(cellChanges => {
      const hotInstance = this.hotRegisterer.getInstance(this.hotId);
      this.hotDB.cellChangesObserved(cellChanges, this.hotInstance, this.columns);
    });

  }

  private afterChange(changes: CellChange[] | null, source: ChangeSource) {
    const mcs = this.hotDB.afterChange(changes, source, this.authState);
    if ( mcs?.length > 0 ) {
      this.fire.updateMenuCells(mcs);
    }
  }

  private updateTab(tab: MenuTab) {
    console.log("Setting/updataing current tab: ", tab);
    this.hotDB.currentTab = tab;
    const hotInstance = this.hotRegisterer.getInstance(this.hotId);
    this.hotDB.rebuildAndLoad(hotInstance, this.columns);
  }

  private insertRow(rowIndex: number, hot: Handsontable, callback: (rowIndex: number) => void) {
    if (!this.hotDB.currentTab) { return; }
    this.hotDB.insertRowsBySelection(rowIndex, hot);
    this.fire.updateMenuTab(this.hotDB.currentTab).then( () => {
      callback(rowIndex);
    });
  }

  private deleteRow(rowIndex: number, hot: Handsontable) {
    if (!this.hotDB.currentTab) { return; }
    this.hotDB.deleteRows(rowIndex, hot);
    this.fire.updateMenuTab(this.hotDB.currentTab).then( () => {});
  }

  private checkThatTabExists(venueId: number, menuId: string, tabId: string) {
    this.state.createTab(venueId, menuId, tabId, tabId).subscribe( d => {});
  }

}
