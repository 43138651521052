import {ItemAdjustment, ItemAttribute, OrderItem, OrderSummaryRowDivider, OrderSummaryRowItem, OrderSummaryRowTitle} from "./order";
import * as moment from 'moment';
import Utils from "../common/utils";
import firebase from "firebase/compat/app";
import Timestamp = firebase.firestore.Timestamp;
export interface Bong {
  bong_id: string;
  type: string;
  state: string;
  active: boolean;
  venue_id: number;
  venue_name: string;
  floor: string;
  area: string;
  table: string;
  device: string;
  deliver_type: string;
  names: string;
  phone: string;
  pickup_location: string;
  address: string;
  roomnumber: string;
  order_numbers: string;
  items_json: string;
  order_tags: string;

  pickup_date: firebase.firestore.Timestamp;
  deliver_date: firebase.firestore.Timestamp;
  created: firebase.firestore.Timestamp;
  activated: firebase.firestore.Timestamp;
  updated: firebase.firestore.Timestamp;

  // transient
  items: BongItem[];
  orderTags: BongOrderTag[];
  color: string;
}

export interface BongItem {
  state: string;
  sort_order: number;
  bong_order: string;
  group_name: string;
  group_index: number;
  was_done: boolean;
  updated: Timestamp;
  rid: string;
  id: string;
  name: string;
  user_id: string;
  price: number;
  count: number;
  vat_amount: number;
  vat: number;
  item: string;
  oprice: number;
  attributes: ItemAttribute[];
  comment: string;
  adjustments: ItemAdjustment[];
  wait: number;
  phase: number;
  form: number;
  type: number;
  bong_name: string;
  cat: string;
  sec: string;
  anonName: string;
  fixed_price: number;
  parts: number;
  share: number;
  orch: string;
  ic: string;
  tags: string[];
  customized: string;
  pos_name: string;
}

export class BongSummary {
  rows: BongSummaryRow[];
  inProcess: boolean;
  bongs: Bong[];
  orderTags: BongOrderTag[];
  lastUpdated: firebase.firestore.Timestamp;
  color() {
    return this.bongs[0].active ? '#317370' : '#444';
  }

  orderStatusColor() {
    return this.bongs[0].active ? '#DCDCDC' : '#f99b82';
  }

  title() {
    return this.bongs[0].table;
  }

  subTitle() {
    return Utils.formatAppDate(moment(this.bongs[0].created.toDate()));
  }

  compareValue() {
    return this.bongs[0].created.seconds;
  }

  getOrderNumber() {
    return this.bongs[0].order_numbers;
  }

}

export class BongSummaryRow {
  item: GroupedBongItems;
  title: string;
  divider: string;
  constructor(item?: GroupedBongItems, title?: string, divider?: string) {
    this.item = item;
    this.title = title;
    this.divider = divider;
  }
}

export class GroupedBongItems {
  key: string;
  bongItems: BongItem[];

  constructor(key: string, bongItems: BongItem[]) {
    this.key = key;
    this.bongItems = bongItems;
  }

  name() {
    if (this.bongItems[0].bong_name) {
      return this.bongItems[0].bong_name;
    }
    if(this.bongItems[0].pos_name) {
      return this.bongItems[0].pos_name;
    }
    return this.bongItems[0].name;
  }

  groupIndex() {
    return this.bongItems[0].group_index;
  }

  groupName() {
    return this.bongItems[0].group_name;
  }

  customized() {
    return this.bongItems[0].customized;
  }

  isDone() {
    return this.bongItems[0].state === "done";
  }

  count() {
    return this.bongItems.reduce((v, it) => v + it.count ?? 1, 0);
  }

}

export interface BongOrderTag {
  tag: string;
  value: string;
}
