import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {VenueService} from "../../../services/venue.service";

export interface DialogData {
  references: string[];
  selection: any;
  venueId: string;
}

@Component({
  selector: 'app-invoice-prepare-dialog',
  templateUrl: './invoice-prepare-dialog.component.html',
  styleUrls: ['./invoice-prepare-dialog.component.css']
})
export class InvoicePrepareDialogComponent implements OnInit {
  form: FormGroup;
  filteredInvoiceReferences: Observable<string[]>;
  filteredElectronicReferences: Observable<string[]>;

  constructor(
    public dialogRef: MatDialogRef<InvoicePrepareDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private venueService: VenueService,
    private dialog: MatDialog
  ) {
    this.form = this.fb.group({
      invoice_reference: [''],
      electronic_reference: [''],
      group_by: ['order']
    });
  }

  ngOnInit() {
    this.filteredInvoiceReferences = this.form.get('invoice_reference')!.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.data.references))
    );

    this.filteredElectronicReferences = this.form.get('electronic_reference')!.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '', this.data.references))
    );
  }

  private _filter(value: string, options: string[]): string[] {
    const filterValue = value.toLowerCase();
    return options.filter(option => option.toLowerCase().includes(filterValue));
  }

  private previewInvoiceCreation(params) {
    const receiptKeys = this.data.selection.selected.map(r => r.receipt_key);
    this.venueService.processAccountEntriesDownloadPDF(this.data.venueId, receiptKeys, "fortnox_invoice", params, "preview.pdf").subscribe((res) => {
    }, error => SimpleDialogComponent.showErr(this.dialog, error));
  }

  preview() {
    var result = this.form.value;
    console.log('Preview clicked', result);
    this.previewInvoiceCreation(result);
  }

  createInvoice() {
    this.dialogRef.close(this.form.value);
  }
}
