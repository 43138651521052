<h2 mat-dialog-title>{{data.title}}</h2>
<div mat-dialog-content style="position: relative;">
  <!--  <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="loadingService.visibility | async"></mat-spinner>-->
  <div>{{data.message}}</div>

  <mat-form-field class="filter-box" appearance="outline">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" (keyup.enter)="selectTopResult()" placeholder="Ex. ium">
  </mat-form-field>

  <div class="element-container">
    <table mat-table [dataSource]="dataSource" [trackBy]="trackById" matSort class="clicktable">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element"><a mat-button color="primary">{{element.name }}</a></td>
      </ng-container>
      <ng-container matColumnDef="venue_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
        <td mat-cell *matCellDef="let element"> {{element.venue_id}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"
        [ngClass]="{'highlight': venueKey === row.venue_key}"></tr>
    </table>
  </div>

  <!-- <div *ngIf="data.htmlBody" [innerHTML]="data.htmlBody"></div> -->
  <div *ngIf="data.showProgress" class="spinner-panel">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</div>
<mat-dialog-actions align="end">
  <button *ngIf="data.cancelButton" mat-button mat-dialog-close (click)="negClicked()">{{data.cancelButton}}</button>
  <button *ngIf="data.positiveButton" mat-button (click)="posClicked()"
    [disabled]="!venueId">{{data.positiveButton}}</button>
</mat-dialog-actions>
