import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { VenueService } from "../../../services/venue.service";
import { ActivatedRoute } from "@angular/router";
import { combineLatest, Subscription } from "rxjs";

@Component({
  selector: 'app-report-dash',
  templateUrl: './report-dash.component.html',
  styleUrls: ['./report-dash.component.scss']
})
export class ReportDashComponent implements OnInit, OnDestroy {

  //public chartOptions: Partial<ChartOptions>;

  public chartOptions = {
    series: [
      {
        name: "Desktops",
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148]
      }
    ],
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "straight"
    },
    title: {
      text: "Product Trends by Month",
      align: "left"
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep"
      ]
    }
  };
  venueId: number;

  private paramSub: Subscription;

  constructor(private venueService: VenueService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.paramSub = combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
    ]
    ).subscribe(([param, query]) => {
      const q = query.get("q");
      this.venueId = Number(param.get("venue_id"));
      this.fetch();
    });
  }

  fetch() {
  }

  ngOnDestroy(): void {
    this.paramSub?.unsubscribe();
  }
}
