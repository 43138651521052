<div>
  <p>
    <mat-toolbar>
      <span>{{title}}</span>
      <span class="spacer"></span>
      <app-wiki-info-link title="Lär dig mer" section="support" path="admins"></app-wiki-info-link>
      <button mat-flat-button color="primary">Prenumeranter: {{numberOfSubscribers}}</button>
      <button mat-button (click)="fetchSubscribers()">
        <mat-icon>refresh</mat-icon> Reload
      </button>
      <button mat-button (click)="removeInvalid()">
        <mat-icon>delete</mat-icon> Remove invalid
      </button>
      <button mat-button (click)="openSubLink()">
        <mat-icon>person_add</mat-icon> Add subscriber
      </button>
      <button mat-button (click)="addActiveAdmins()">
        <mat-icon>person_add</mat-icon> Add all active admins
      </button>
    </mat-toolbar>
  </p>
</div>
<div style="padding: 16px;">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub-id</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;max-width: 125px;font-size:12px;white-space: normal;word-wrap: break-word;"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>address</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.address}} </td>
    </ng-container>

    <ng-container matColumnDef="venue">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>venue</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.venue_name}} </td>
    </ng-container>

    <ng-container matColumnDef="updated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>updated</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.updated | utcDate}} </td>
    </ng-container>

    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>created</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.created | utcDate}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;">
        <a href="{{baseUrl}}/list/{{listName}}/remove?t={{element.unsubscribe_token}}" target="_blank">unsubscribe</a>
<!--        <button mat-button [matMenuTriggerFor]="menuReports">Edit</button>-->
<!--        <mat-menu #menuReports="matMenu">-->
<!--          <button mat-menu-item (click)="editRoles(element)">Redigera roll</button>-->
<!--          <mat-divider></mat-divider>-->
<!--          <button mat-menu-item (click)="resendInvite(element)">Skicka inbjudan igen</button>-->
<!--        </mat-menu>-->
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

