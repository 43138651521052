import {Component, OnDestroy, OnInit} from '@angular/core';
import {VenueTicket} from "../../models/venue-ticket";
import {FireService} from "../../services/fire.service";
import {ActivatedRoute} from "@angular/router";
import * as moment from 'moment';
import {Subscription} from "rxjs";
import { MatDialog } from '@angular/material/dialog';
import { SingleTicketDialogComponent } from '../admin/single-ticket-dialog/single-ticket-dialog.component';
import { HackUtils, TimeUtils } from 'src/app/utils/utils';
import { TicketService } from 'src/app/services/ticket.service';
import firebase from "firebase/compat/app";
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css']
})
export class TicketComponent implements OnInit, OnDestroy {

  private venueId: number;
  private sub: Subscription;
  miniTickets: VenueTicket[];
  title: string;
  content: string;
  state: string;
  resolved: boolean;
  quickAddActive = false;
  showArchived = false;

  constructor(private route: ActivatedRoute, private fire: FireService,
              public dialog: MatDialog, private ticketService: TicketService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.observeMiniTickets(['open', 'info', 'resolved']);
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  private observeMiniTickets(states) {
    if (!states) { states = ['open', 'info', 'resolved']; }
    this.sub = this.fire.observeMiniTickets(this.venueId, states).subscribe( tickets => {
      console.log("miniTickets", tickets);
      tickets.forEach( mt => {
        const ts = this.getTime(mt.created);
        mt.ticket_info = `${ts}  (${mt.userName})`;
      });
      this.miniTickets = tickets;
    });
  }

  openEmptyTicketDialog() {
    const ticket = {
      venueId: this.venueId,
    } as VenueTicket;
    this.openTicketDialog(ticket);
  }

  openTicketDialog(ticket: VenueTicket) {
    console.log("Ticket", ticket);
    const dialogRef = this.dialog.open(SingleTicketDialogComponent, HackUtils.DLG({
      data: ticket,
      disableClose: true
    }));
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  send() {
    const ticket = {
      venueId: this.venueId,
      title: this.title,
      content: this.content,
      state: 'open',
    } as VenueTicket;
    this.ticketService.createTicket(ticket);
    this.quickAddActive = false;
  }

  toggleQuickAdd() {
    this.quickAddActive = !this.quickAddActive;
  }

  toggleArchived() {
    if (this.showArchived) {
      this.observeMiniTickets(['archived']);
    } else {
      this.observeMiniTickets(['open', 'info', 'resolved']);
    }
  }

  showStateSymbol(state: string) {
    return this.ticketService.showState(state).symbol;
  }

  getTime(date: Timestamp) {
    if (!date) { return ""; }
    const timestamp = date;
    const unixTimestamp = timestamp.seconds + timestamp.nanoseconds / 1000000000;
    const momentObj = moment.unix(unixTimestamp);
    return TimeUtils.getRelativeDayStringTimeDate(momentObj);
  }

}
