<div style="margin-bottom: 16px;">
  <img src="https://storage.googleapis.com/heynow/media/Logo_Horz_Coral_rgb_200.png" width="100px">
</div>
<div class="landing-start">
  <div class="left" *ngIf="!leadNotValid">
    <h1>Välkommen till Heynow!</h1>
    <div class="cto" style="margin-bottom: 8px;">Fyll i ditt namn och telefonnummer</div>
    <div>
      <mat-form-field appearance="outline" style="margin: 0; width: 300px;">
        <mat-label>Ditt namn</mat-label>
        <input matInput [formControl]="userName" required>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" style="margin: 0; width: 300px;">
        <mat-label>Telefon</mat-label>
        <input matInput [formControl]="phone" required>
      </mat-form-field>
    </div>
    <div class="cto" style="margin-bottom: 8px;">Fyll i namn på din restaurang</div>
    <div>
      <mat-form-field appearance="outline" style="margin: 0; width: 300px;">
        <mat-label>Namn på din restaurang</mat-label>
        <input matInput [formControl]="name" required>
      </mat-form-field>
    </div>
    <div class="cto" style="margin-bottom: 8px;">Välj startmall (du kan ändra detta senare)</div>
    <div style="display: flex; flex-direction: row; justify-content: flex-start;">
      <div class="card" *ngFor="let c of cards" [ngClass]="{'selected': c.id == selectedTemplate}" (click)="select(c)">
        <div style="text-align: center;">{{c.name}}</div>
      </div>
    </div>
    <div style="display: flex; flex-direction: row; justify-content: flex-end;margin-top: 32px;" *ngIf="!success">
      <mat-spinner [diameter]="32" *ngIf="sending" style="margin-right: auto;"></mat-spinner>
      <button mat-flat-button color="primary" (click)="start()" [disabled]="sending">Kom igång!</button>
    </div>

    <div class="right" *ngIf="success" style="margin-top: 32px;">
      <div style="font-weight: 300;">Tack {{name.value}}!<br><br>Nästa steg är att ditt konto skapas.<br><br>Vi skickar ett mail till {{lead.email}} när det är klart 👍</div>
    </div>

  </div>
  <div class="left" *ngIf="leadNotValid">
    <h1 style="color: #de4f4f">Inbjudan är gammal, redan använd eller så saknas interntuppkoppling</h1>
  </div>

</div>

