import { Injectable } from '@angular/core';
import { AuthorizedHttpService } from './authorized-http-service';
import { SettlementData, Settlement } from "../models/settlement";
import Utils from '../common/utils';
import { SilversmithApiActionResult } from '../models/http';


@Injectable({
  providedIn: 'root'
})
export class SettlementService extends AuthorizedHttpService {

  public async getSettlements(date: Date): Promise<Settlement[]> {
    const dateKey = Utils.dateToDateKey(date);
    const params = new URLSearchParams();
    params.append("date_key", dateKey);
    return this.get<Settlement[]>("api/silversmith/admin/fetch_settlements", params);
  }

  public async getPO3File(date: Date): Promise<any> {
    const dateKey = Utils.dateToDateKey(date);
    const params = new URLSearchParams();
    params.append("date_key", dateKey);
    return this.downloadFile("api/silversmith/admin/get_po3_file", params);
  }

  public async createSettlements(date: Date, settlements: SettlementData[]): Promise<SilversmithApiActionResult> {
    const dateKey = Utils.dateToDateKey(date);
    const params = new URLSearchParams();
    params.append("date_key", dateKey);
    return this.put<SilversmithApiActionResult>("api/silversmith/admin/create_settlements", params);
  }

  public async refreshSettlementState(date: Date): Promise<SilversmithApiActionResult> {
    const dateKey = Utils.dateToDateKey(date);
    const params = new URLSearchParams();
    params.append("date_key", dateKey);
    return this.put<SilversmithApiActionResult>("api/silversmith/admin/refresh_settlements", params);
  }

  public async getNewSettlements(date: Date): Promise<SettlementData[]> {
    const dateKey = Utils.dateToDateKey(date);
    const params = new URLSearchParams();
    params.append("date_key", dateKey);
    return this.get<SettlementData[]>("api/silversmith/admin/fetch_new_settlements", params);
  }
}
