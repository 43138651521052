import { Component, OnInit } from '@angular/core';
import {VenueService} from "../../../services/venue.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {FortnoxAccount, FortnoxSummary} from "../../../models/fortnox";

@Component({
  selector: 'app-fortnox',
  templateUrl: './fortnox.component.html',
  styleUrls: ['./fortnox.component.css']
})
export class FortnoxComponent implements OnInit {
  private venueId: string;
  private code: string;

  displayedColumns = ["account", "included", "active", "cost_center", "transaction_info"];
  dataSource: MatTableDataSource<FortnoxAccount>;
  summary: FortnoxSummary;
  okIcon = "check_circle";
  badIcon = "warning";
  okStyle = "color: #0f9d58";
  badStyle = "color: #de4f4f";
  loading = true;

  constructor(private venueService: VenueService, private route: ActivatedRoute, private dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      // Customer opens page
      this.venueId = data.get("venue_id");
      this.fetchSummary();
    });
    this.route.queryParamMap.subscribe(data => {
      console.log(data);
      this.code = data.get("code");
      if (this.code) {
        console.log(this.code);
        this.processAuthCode(this.code);
      }
    });
  }

  processAuthCode(code: string) {
    this.venueService.processAuthCode(Number(this.venueId), code).then(r => {
      console.log(r);
      // Redirect to same page but without code query param
      this.loading = true;
      this.summary = undefined;
      this.router.navigateByUrl(`venue/${this.venueId}/fortnox`);
      this.fetchSummary();
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

  auth() {
    const clientId = "U7ZrdAD6jJye"; //Heynow App
    //const clientId = "2LwAlN1tyTOJ"; //Heynow Test App
    const redirectUri = `${environment.james_url}/venue/${this.venueId}/fortnox`;
    const scope = encodeURIComponent("invoice bookkeeping order inbox connectfile costcenter customer");
    const url = `https://apps.fortnox.se/oauth-v1/auth?client_id=${clientId}&response_type=code&state=${this.venueId}&scope=${scope}&access_type=offline&redirect_uri=${encodeURIComponent(redirectUri)}`;
    window.location.href = url;
  }

  private fetchSummary() {
    console.log("fetchSummary...");
    this.venueService.fetchFortnoxSummary(Number(this.venueId)).then(r => {
      console.log(r);
      this.summary = r;

      const usesCC = r.cost_center != null;
      const usesTI = r.transaction_information != null;
      for (const k of r.accounts) {
        const ccOk = k.cost_center === "ALLOWED" || (usesCC && k.cost_center === "MANDATORY") || (!usesCC && k.cost_center === "NOTALLOWED");
        const ccNotOk = (!usesCC && k.cost_center === "MANDATORY") || (usesCC && k.cost_center === "NOTALLOWED");
        k.cc_icon = ccOk ? this.okIcon : (ccNotOk ? this.badIcon : null);
        k.cc_style = ccOk ? this.okStyle : (ccNotOk ? this.badStyle : "");

        const tiOk = k.transaction_information === "ALLOWED" || (usesTI && k.transaction_information === "MANDATORY") || (!usesTI && k.transaction_information === "NOTALLOWED");
        const tiNotOk = (!usesTI && k.transaction_information === "MANDATORY") || (usesTI && k.transaction_information === "NOTALLOWED");
        k.ti_icon = tiOk ? this.okIcon : (tiNotOk ? this.badIcon : null);
        k.ti_style = tiOk ? this.okStyle : (tiNotOk ? this.badStyle : "");

        k.found_icon = k.found ? this.okIcon : this.badIcon;
        k.found_style = k.found ? this.okStyle : this.badStyle;
        k.active_icon = k.active ? this.okIcon : this.badIcon;
        k.active_style = k.active ? this.okStyle : this.badStyle;
      }

      this.dataSource = new MatTableDataSource(r.accounts as FortnoxAccount[]);

      // Redirect to same page but without code query param
      this.router.navigateByUrl(`venue/${this.venueId}/fortnox`);
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e)).finally(() => this.loading = false );
  }
}
