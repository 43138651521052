import { Component, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface ReceiptDialogData {
  receiptKey: string;
  venueId: number;
  isSubComponent: boolean;
  refundReceiptKeys: string[];
}

@Component({
  selector: 'app-receipt-dialog',
  templateUrl: './receipt-dialog.component.html',
  styleUrls: ['./receipt-dialog.component.css']
})
export class ReceiptDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ReceiptDialogData) { }

}
