<h2 mat-dialog-title>Edit: {{email}}</h2>
<div mat-dialog-content style="position: relative;">
  <div>
    <mat-form-field class="indialog">
      <mat-label>First name</mat-label>
      <input matInput [formControl]="firstName" required>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="indialog">
      <mat-label>Last name</mat-label>
      <input matInput [formControl]="lastName" required>
    </mat-form-field>
  </div>
  <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="loadingService.visibility | async"></mat-spinner>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Avbryt</button>
  <button mat-button (click)="editUser()">Bekräfta</button>
</mat-dialog-actions>
