import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Invoice } from 'src/app/models/billing';
import { InvoicesService } from 'src/app/services/invoices.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
  // displayedColumns = [
  //   "exported", "created", "paid",
  //   "expired", "status", "fortnox_invoice_ref",
  //   "updated", "is_credit", "fortnox_export_status"
  // ];
  displayedColumns = [
    "created",
    "updated",
    "exported",
    "status",
    "is_credit"
  ];
  dataSource: MatTableDataSource<Invoice>;


  constructor(private invoicesService: InvoicesService, private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe(data => {
      const vn = data.get("venue_id");
      this.fetchInvoices();
    });


  }

  public createInvoices() {
    this.invoicesService.createInvoices().then(i => {
      console.log(i);
    });
  }

  public fetchInvoices() {
    this.invoicesService.fetchInvoices().then(i => {
      this.dataSource = new MatTableDataSource(i as Invoice[]);
      console.log(i);
    });
  }

}
// private fetchUsers() {
//   this.venueService.fetchStaff(this.venueId).then(r => {
//     this.dataSource = new MatTableDataSource(r as Admin[]);
//     this.dataSource.sort = this.sort;
//   });
// }
