import Konva from "konva";
import {ReplaySubject} from "rxjs";
import Utils from "../common/utils";
import * as _ from "lodash";
import {FPLine, FPTableData, INIT_SCALE, SNAP_SIZE, FLOORPLAN_SCENE_WIDTH, FLOORPLAN_SCENE_HEIGHT} from "./floorplan";
import Shape = Konva.Shape;
import Line = Konva.Line;
import Vector2d = Konva.Vector2d;
import { getConstrainedPosition } from "../components/floorplan/floorplan-common/floorplan-misc";

export class FP2Section {
  id: string;
  name: string;
  objects: FP2Table[];
  lines: FP2Line[];
  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
    this.objects = [];
    this.lines = [];
  }
}

export class FP2Table {
  name: string;
  shapeId: string;

  alias: string;
  aliasPrefix: string;
  floor: string;
  type: string;
  chairs: number;
  stations: string[];
  area: string;
  shape: Shape | Konva.Group;
  showAlias = false;
  resourceType: string;
  private text: Konva.Text;
  private undoPointSubject: ReplaySubject<number>;
  private disabled: boolean;

  constructor( name: string, alias: string, aliasPrefix: string, area: string, stations: string[],
               resourceType: string, type: string, data: FPTableData, showAlias: boolean,
               undoPointSubject: ReplaySubject<number>, disabled: boolean
               ) {
    this.name = name;
    this.alias = alias;
    this.disabled = disabled;
    this.aliasPrefix = aliasPrefix;
    this.showAlias = showAlias;
    this.type = type;
    this.area = area;
    this.stations = stations;
    this.resourceType = resourceType;
    this.shapeId = Utils.randomString(10);
    this.shape = this.createTableGroup(data);
    this.undoPointSubject = undoPointSubject;
  }

  public get x() {
    return this.shape.x();
  }

  public get y() {
    return this.shape.y();
  }

  public changeName(name: string, alias: string) {
    this.name = name;
    this.alias = alias;
    this.text.fontSize(this.getFontSize());
    this.text.text(this.getObjectText());
  }

  public textUpdated() {
    this.text.fontSize(this.getFontSize());
    this.text.text(this.getObjectText());
  }

  collectShapeData(offsetXY?: { x: number, y: number }): FPTableData {
    // if collecting shapes that are dragged, make sure to snap
    const x = Math.round((this.shape.x() + (offsetXY?.x ?? 0)) / SNAP_SIZE) * SNAP_SIZE;
    const y = Math.round((this.shape.y() + (offsetXY?.y ?? 0)) / SNAP_SIZE) * SNAP_SIZE;
    const sd: FPTableData = {
      x, y,
      rotation: this.shape.rotation(),
      scaleX: this.shape.scaleX(), scaleY: this.shape.scaleY(),
      size: SNAP_SIZE,
      name: this.name, alias: this.alias, type: this.type,
    };

    if (this.area) {
      sd.area = this.area;
    }
    if (this.stations) {
      sd.stations = this.stations;
    }
    if (this.resourceType) {
      sd.resource_type = this.resourceType;
    }
    return sd;
  }

  createTableGroup(data: FPTableData): Konva.Group {
    const sx = data.scaleX ?? INIT_SCALE;
    const sy = data.scaleY ?? INIT_SCALE;
    const group = new Konva.Group({
      x: data.x, y: data.y,
      width: SNAP_SIZE,
      height: SNAP_SIZE,
      rotation: data.rotation ?? 0,
      scaleX: sx,
      scaleY: sy,
      draggable: !this.disabled,
      name: "movable",
      id: this.shapeId
    });

    if (this.type === "circle") {
      const circle = new Konva.Circle({
        width: group.width(),
        height: group.height(),
        fill: "#8B572A",
        name: "gr_circ",
        offsetX: -group.width() / 2,
        offsetY: -group.height() / 2
      });
      group.add(circle);
    } else {
      const rect = new Konva.Rect({
        width: group.width(),
        height: group.height(),
        fill: "#8B572A",
        name: "gr_rect",
      });
      group.add(rect);
    }

    this.text = new Konva.Text({
      text: this.getObjectText(),
      fontSize: this.getFontSize(),
      fontFamily: 'Roboto',
      fill: '#fff',
      width: group.width() * group.scaleX(),
      height: group.height() * group.scaleY(),
      verticalAlign: 'middle',
      align: 'center',
      name: "gr_text",
      offsetY: -2,
      scaleX: 1 / group.scaleX(),
      scaleY: 1 / group.scaleY(),
    });

    group.on('transform', () => {
      this.text.setAttrs({
        width: group.width() * group.scaleX(),
        height: group.height() * group.scaleY(),
        // y: rect.height() * group.scaleY() / 2,
        scaleX: 1.0 / group.scaleX(),
        scaleY: 1.0 / group.scaleY(),
      });
    });

    group.on('transformend', () => {
      console.log('transform end');
      const cw = (SNAP_SIZE) * group.scaleX();
      const ch = (SNAP_SIZE) * group.scaleY();
      const w = Math.max(Math.round(cw / SNAP_SIZE) * SNAP_SIZE, 48);
      const h = Math.max(Math.round(ch / SNAP_SIZE) * SNAP_SIZE, 48);
      const gsx = w / (SNAP_SIZE);
      const gsy = h / (SNAP_SIZE);
      const x = Math.round(group.x() / SNAP_SIZE) * SNAP_SIZE;
      const y = Math.round(group.y() / SNAP_SIZE) * SNAP_SIZE;
      console.log(`${w},${gsx}x${gsy} (${x},${y})`);

      group.setAttrs({
        scaleX: gsx,
        scaleY: gsy,
        x, y
      });
      this.text.setAttrs({
        width: group.width() * group.scaleX(),
        height: group.height() * group.scaleY(),
        scaleX: 1.0 / group.scaleX(),
        scaleY: 1.0 / group.scaleY(),
      });
      this.undoPointSubject.next(2);
    });

    group.add(this.text);
    return group;
  }

  addToLayer(layer: Konva.Layer) {
    const g = this.shape as Konva.Group;
    g.on('dragend', (e) => {
      g.position({
        x: Math.round(g.x() / SNAP_SIZE) * SNAP_SIZE,
        y: Math.round(g.y() / SNAP_SIZE) * SNAP_SIZE
      });
    });

    g.dragBoundFunc((pos) => {
      return getConstrainedPosition(pos, g, layer);
    });
    layer.add(this.shape);
  }

  removeFromLayer(layer: Konva.Layer) {
    this.shape.remove();
  }

  private getObjectText() {
    if (this.showAlias) {
      return this.alias;
    } else {
      return this.name;
    }
  }

  private getFontSize() {
    if (this.showAlias) {
      return 28;
    } else {
      return 18;
    }
  }
}

export class FP2Line {
  shapeId: string;
  type: string;
  shape: Line;
  vertices: Shape[] = [];
  private layer: Konva.Layer;
  private tailShape: Line;
  private undoPointSubject: ReplaySubject<number>;
  private disabled: boolean;

  constructor(type: string, undoPointSubject: ReplaySubject<number>, disabled: boolean) {
    this.type = type;
    this.shapeId = Utils.randomString(10);
    this.undoPointSubject = undoPointSubject;
    this.disabled = disabled;
  }

  public get x() {
    return this.shape.x();
  }

  public get y() {
    return this.shape.y();
  }

  collectShapeData(): FPLine {
    const vertices = this.vertices.map( vert => ({x: Math.round(vert.x()), y: Math.round(vert.y())}) );
    return {vertices};
  }

  createShape(vec1: Vector2d, vec2: Vector2d): Konva.Line {
    const line = new Konva.Line({
      points: [vec1.x, vec1.y, vec2.x, vec2.y],
      stroke: "#999999",
      strokeWidth: 7,
      lineCap: 'round',
      lineJoin: 'round',
      name: "line",
    });
    return line;
  }

  createTail(vec1: Vector2d, vec2: Vector2d) {
    const line = new Konva.Line({
      points: [vec1.x, vec1.y, vec2.x, vec2.y],
      stroke: "#000000",
      strokeWidth: 4,
      lineCap: 'round',
      lineJoin: 'round',
      name: "tail",
    });
    this.tailShape = line;
    this.layer.add(this.tailShape);
  }

  private addVertex(vec: Vector2d) {
    const box = new Konva.Circle({
      x: vec.x, y: vec.y,
      width: SNAP_SIZE,
      height: SNAP_SIZE,
      fill: "#6e6e6e",
      stroke: "#b5b5b5",
      strokeWidth: 4,
      visible: true,
      name: "vertex",
      draggable: !this.disabled,
      id: Utils.randomString(10)
    });
    this.vertices.push(box);
    this.layer.add(box);

    box.on('dragmove', (e) => {
      this.updateBox(box);
    });

    box.on('transform', () => {
      box.setAttrs({
        scaleX: 1.0,
        scaleY: 1.0,
      });
      this.updateBox(box);
    });

    box.on('transformend', () => {
      console.log("transformend vertex");
      this.undoPointSubject.next(3);
    });

    box.dragBoundFunc((pos) => {
      return getConstrainedPosition(pos, box, this.layer);
    });
  }

  addToLayer(layer: Konva.Layer) {
    this.layer = layer;
  }

  removeVertex(vertexId: string): boolean {
    const vertex = this.findVertex(vertexId);
    if (vertex) {
      if (this.vertices.length > 2) {
        const vertIndex = _.indexOf(this.vertices, vertex);
        vertex.remove();
        _.remove(this.vertices, o => o === vertex);
        const points = this.shape.points();
        points.splice(vertIndex * 2, 2);
        this.shape.points(points);
        return true;
      } else {
        this.removeFromLayer();
        return false;
      }
    }
  }

  removeFromLayer() {
    if (this.shape) {
      this.shape.remove();
    }
    if (this.tailShape) {
      this.tailShape.remove();
    }
    for (const vertex of this.vertices) {
      vertex.remove();
    }
  }

  addPoint(vec: Vector2d) {
    this.addVertex(vec);
    if (this.vertices.length === 2) {
      this.shape = this.createShape(this.vertices[0].position(), this.vertices[1].position());
      this.layer.add(this.shape);
      this.shape.moveToBottom();
    } else if (this.vertices.length > 2) {
      const points = this.shape.points();
      points.push(vec.x);
      points.push(vec.y);
      this.shape.points(points);
    }
    this.updateTail(vec, vec);
  }

  private updateTail(vec1: Vector2d, vec2: Vector2d) {
    if (this.tailShape) {
      this.tailShape.points([vec1.x, vec1.y, vec2.x, vec2.y]);
    } else {
      this.createTail(vec1, vec2);
    }
  }

  setTempPoint(vec: Vector2d) {
    if (this.vertices.length > 0) {
      const last = _.last(this.vertices);
      this.updateTail({x: last.x(), y: last.y()}, vec);

    }
  }

  removeTempPoint() {
    this.tailShape.remove();
  }

  private updateBox(box: Shape) {
    const nv = Utils.snapVec(box.position());
    box.position(nv);
    const vertIndex = _.indexOf(this.vertices, box);
    const points = this.shape.points();
    points[vertIndex * 2] = nv.x;
    points[vertIndex * 2 + 1] = nv.y;
    this.shape.points(points);
  }

  findVertex(vertexId: string): Shape {
    return this.vertices.find(vert => vert.id() === vertexId);
  }
}
