import {Component, OnInit, ViewChild} from '@angular/core';
import {DataFormSchema} from "../../../../models/data-form";
import {DataFormComponent} from "../../../data-form/data-form.component";
import {ApiToken} from "../../../../models/api-token";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../../services/auth.service";
import {FireService} from "../../../../services/fire.service";
import {MenuStateService} from "../../../catalog/menu-utils/menu-state.service";
import {MatDialog} from "@angular/material/dialog";
import {PublishService} from "../../../../services/publish.service";
import {VenueService} from "../../../../services/venue.service";
import {HNChain} from "../../../../models/chain";
import {SimpleDialogComponent} from "../../../simple-dialog/simple-dialog.component";

@Component({
  selector: 'app-chain-settings',
  templateUrl: './chain-settings.component.html',
  styleUrls: ['./chain-settings.component.css']
})
export class ChainSettingsComponent implements OnInit {

  dataFormSchema: DataFormSchema = {
    title: {sv: "Inställningar för kedja"},
    desc: {sv: "Ställ in kedja"},
    properties: [
      {key: "name", title: {sv: "Namn"}, type: "string", optional: false, visible: true},
      {key: "chain_types", title: {sv: "Typ(er)"}, desc: {sv: "Kommaseparerad lista av typer: menu (giftcard, loyalty, reports)"}, type: "string", optional: false},
      {key: "venues", title: {sv: "Venues"}, desc: {sv: "Lägg till venue-id för det venues som skall ingå i kedjan (namn hämtas), skall venue tas bort rensa hela raden."}, type: "table",
        columns: [
          {key: "venue_id", title: {sv: "venue_id"}, type: "integer", width: 200 },
          {key: "name", title: {sv: "namn"}, type: "string", width: 200 },
        ]
      }
    ]
  };
  @ViewChild("dataform") dataForm: DataFormComponent;
  chain: HNChain;

  constructor(private route: ActivatedRoute, private authService: AuthService, private fire: FireService, private state: MenuStateService,
              private router: Router, private dialog: MatDialog, private publish: PublishService, private venueService: VenueService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      const chainKey = data.get("chain_key");
      this.fetchChain(chainKey);
    });
  }

  private fetchChain(chainKey: string) {
    this.venueService.fetchChain(chainKey).then(r => {
      this.chain = r;
    });
  }


  async save() {
    const data = this.dataForm.collectData();
    this.chain.name = data.name;
    this.chain.venues = data.venues;
    this.chain.chain_types = data.chain_types;
    this.venueService.updateChain(this.chain).then(r => {
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }
}
