<div style="margin-bottom: 16px;">
  <img src="https://storage.googleapis.com/heynow/media/Logo_Horz_Coral_rgb_200.png" width="100px">
</div>
<div class="landing-start">
  <div class="left">
    <h1 *ngIf="leadIsNewUser">Ange ett lösenord för ditt konto</h1>
    <h1 *ngIf="!leadIsNewUser">Logga in</h1>
    <div class="cto" style="margin-bottom: 8px;" *ngIf="leadIsNewUser">Fyll i önskat lösenord (behöver innehålla minst 6 tecken)</div>
    <div>
      <mat-form-field appearance="outline" style="margin: 0; width: 300px;">
        <mat-label>Lösenord</mat-label>
        <input matInput [type]="hideP ? 'password' : 'text'" [formControl]="pw1" required>
        <button mat-icon-button matSuffix type="button" (click)="hideP = !hideP">
          <mat-icon>{{hideP ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
      <div *ngIf="error" style="color:red;">{{error}}</div>
      <div style="display: flex; flex-direction: row; justify-content: flex-end;margin-top: 32px;">
        <mat-spinner [diameter]="32" *ngIf="sending" style="margin-right: auto;"></mat-spinner>
        <button mat-flat-button color="primary" (click)="next()" [disabled]="sending">Nästa</button>
      </div>
    </div>
  </div>

</div>

