<div class="detail-container">
  <div class="detail-toolbar payment-toolbar"></div>
  <div class="detail-body">
    <div *ngIf="activeBill" class="payment-section">
      <div class="small-header">Nota {{table}} - {{activeBill.amount.priceFormatted()}}</div>
      <div class="actions">
        <div *ngFor="let action of activeBill.buttons" class="action-button" (click)="actionSelected(action)" [ngClass]="'action'+action.color">{{action.title}}</div>
      </div>
    </div>

    <div class="small-header">{{table}}</div>
    <div class="actions">
      <div *ngFor="let action of tableButtons" class="action-button" (click)="actionSelected(action)" [ngClass]="'action'+action.color">{{action.title}}</div>
    </div>
    <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="showPaymentSpinner"></mat-spinner>
  </div>

</div>
