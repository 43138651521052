import {Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {VenueCustomer} from "../../../models/venue-customers";
import {HotTableRegisterer} from "@handsontable/angular";
import {combineLatest, Subscription} from "rxjs";
import {VenueConfig} from "../../../models/venue-config";
import {VenueService} from "../../../services/venue.service";
import {ActivatedRoute} from "@angular/router";
import {FireService} from "../../../services/fire.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {NodeUtils} from "../../../utils/utils";
import * as _ from "lodash";
import {detailedDiff, diff} from "deep-object-diff";
import {WfClassComponent} from "./wf-class/wf-class.component";
import {WorkflowClass} from "../../../models/workflow";
import {WfStepComponent} from "./wf-step/wf-step.component";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {getPagePaths, getSettingsPage} from "../pages/setting-pages";
import {AuthService} from "../../../services/auth.service";
import {MatTabGroup} from "@angular/material/tabs";

@Component({
  selector: 'app-workflow-editor',
  templateUrl: './workflow-editor.component.html',
  styleUrls: ['./workflow-editor.component.css']
})
export class WorkflowEditorComponent implements OnInit, OnDestroy {

  venueId: number;
  errorMessage: string;
  wfClasses: WorkflowClass[];
  version: string;
  sameAsCurrent = false;
  migrateButton = false;
  private sub: Subscription;
  private paramSub: Subscription;
  private config: VenueConfig;
  private loadedWithoutErrors: boolean;
  @ViewChildren(WfClassComponent) wfClassComponents: QueryList<WfClassComponent>;
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup;
  viewMode = "tabs";

  constructor(private venueService: VenueService, private route: ActivatedRoute, private fire: FireService, private snackBar: MatSnackBar, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.paramSub = combineLatest([
        this.route.paramMap,
        this.route.queryParamMap
      ]
    ).subscribe(res => {
      const param = res[0];
      const query = res[1];
      this.venueId = Number(param.get("venue_id"));
      this.version = query.get("version");
    });
    this.sub = this.fire.observeVenueConfig(this.venueId).subscribe(cfg => {
      if (this.config == null) {
        this.config = cfg;
        if (this.config && this.isNewWorkflow(this.config.workflow)) {
          this.buildWorkflowSteps(this.config.workflow);
        } else {
          this.migrateButton = true;
        }
        this.loadedWithoutErrors = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.paramSub?.unsubscribe();
  }

  save() {
    if (!this.loadedWithoutErrors) {
      console.error("failed to save (load error)");
      return;
    }
    if (!this.hasChanges()) {
      this.snackBar.open("No changes. Nothing saved.", "", {duration: 3000});
      return;
    }
    this.errorMessage = null;

    this.saveWorkflow();
  }

  closeError() {
    this.errorMessage = null;
  }

  public hasChanges(): boolean {
    if (!this.loadedWithoutErrors) {
      return false;
    }
    const data = this.collectData();
    console.log("New", data);
    console.log("Org", this.config.workflow);
    const d = diff(this.config.workflow, data);
    console.log("Workflow diff", d);
    return !NodeUtils.isNullOrEmptyObject(d);
  }

  private buildWorkflowSteps(wf: any) {
    // Convert dictionary of the workflow config to a list of classes
    const classes = [];
    if (wf != null) {
      for (const key of Object.keys(wf)) {
        if (key === "setup" || key === "class" ) {
          continue;
        }

        const wfClass = _.cloneDeep(wf[key]);

        //Check if wfClass has a steps property
        if (wfClass.steps == null) {
          continue;
        }

        wfClass.name = key;
        wfClass.title = key + (wfClass.active ? "" : " (INAKTIVT) ");

        //Convert steps into a list
        const steps = [];
        for (const stepKey of Object.keys(wfClass.steps)) {
            const step = wfClass.steps[stepKey];
            step.name = stepKey;
            steps.push(step);
        }
        steps.sort((a, b) => a.index - b.index);
        console.log("Steps", steps);
        wfClass.steps = steps;
        classes.push(wfClass);
      }
      this.wfClasses = classes;
    }
    //this.standardClass.steps = [];
  }

  private collectData() {
    const data = {
      setup: {
        version: 3
      }
    };
    for (const cc of this.wfClassComponents) {
      const d = cc.collectData();
      const name = d.name;
      delete d.name;
      if (name in data) {
        console.error("Duplicate workflow name", name);
        this.errorMessage = "Duplicate workflow name: " + name;
        return null;
      }
      data[name] = NodeUtils.clean(d);
    }
    return data;
  }

  private saveWorkflow() {
    const dialogRef = SimpleDialogComponent.openSimpleDialog(this.dialog, {title: "Saving...", showProgress: true, cancelButton: "Close"});
    const data = this.collectData();
    const dd = detailedDiff(this.config.workflow, data);
    const cc = this.config;
    this.config = null;
    this.venueService.patchSettings(Number(this.venueId), "workflow", undefined, data, dd).then( res => {
      console.log(res);
      dialogRef.close();
      this.errorMessage = null;
    }).catch(err => {
      this.config = cc;
      console.log(err);
      dialogRef.close();
      this.errorMessage = err.error.message;
    });
  }

  restoreVersion() {

  }

  addNew() {
    const data: any = this.collectData();
    const n = {active: true, steps: []};
    let name = "new_wf_1";
    let index = 2;
    while (name in data) {
      name = "new_wf_" + index;
      index++;
    }
    data[name] = n;
    console.log("addNew", data);
    this.buildWorkflowSteps(data);
    this.tabGroup.selectedIndex = this.wfClasses.length - 1;
  }

  copyWorkflow(name: string) {
    const data: any = this.collectData();
    const n = _.cloneDeep(data[name]);
    let newName = name + "_copy";
    let index = 1;
    while (newName in data) {
      newName = name + "_copy_" + index;
      index++;
    }
    data[newName] = n;
    console.log("copyWorkflow", data);
    this.buildWorkflowSteps(data);
    this.tabGroup.selectedIndex = this.wfClasses.length - 1;
  }

  deleteWorkflow(name: string) {
    const data: any = this.collectData();
    delete data[name];
    console.log("deleteWorkflow", data);
    this.buildWorkflowSteps(data);
  }

  migrate() {
    const mess = "Efter att du migrearat, kolla igenom så att workflow stegen är i rätt ordning. Du har möjlighet att titta igenom och göra ändringar. Det migrerade workflowet kommer sparas först när du trycker spara.";
    const settings = { title: "Migrera workflow till james?", message: mess, positiveButton: "Påbörja migrering", cancelButton: "Avbryt" };
    return SimpleDialogComponent.observeSimpleDialog(this.dialog, settings).subscribe(res => {
      if (res) {
        this.doMigrate();
      }
    });
  }

  doMigrate() {
    console.log(this.config.workflow);
    const oldWf = this.config.workflow;
    if (oldWf == null) {
      return;
    }

    const cfg: any = {
      setup: {
        version: 3
      }
    };
    const newWfInhouse = this.convertOldWf(oldWf.inhouse, "single_inhouse", "party=single", "inhouse");
    if (newWfInhouse) {
      cfg.single_inhouse = newWfInhouse;
    }
    const newWfGuest = this.convertOldWf(oldWf.guest, "multi_guest", "party=multi", "guest");
    if (newWfGuest) {
      cfg.multi_guest = newWfGuest;
    }
    console.log("NEW WORKFLOW", cfg);
    this.buildWorkflowSteps(cfg);
    this.migrateButton = false;
  }

  private convertOldWf(oldWfCfg: any, stepName: string, orderFilter: string, title: string): any {
    if (oldWfCfg == null) {
      return null;
    }
    const newWf = {
      active: true,
      name: stepName,
      desc: `Migrerat ${title} workflow`,
      order_filter: orderFilter,
      steps: {}
    };
    const steps = {};
    let i = 0;
    for (const key of Object.keys(oldWfCfg)) {
      const content = oldWfCfg[key];
      content.index = i++;
      content.active = true;
      steps[key] = content;
    }
    newWf.steps = steps;
    return newWf;
  }

  private isNewWorkflow(workflow: any) {
    return workflow.setup != null;
  }

  toggleViewMode() {
    this.viewMode = this.viewMode === "list" ? "tabs" : "list";
  }
}
