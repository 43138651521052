<div class="probe-panel">
  <div>
    <a routerLink="/venue/{{venueId}}/pos/pay/{{table}}" class="linkBtn">< BACK TO POS</a>
    <app-order-summary [data]="summaryData" [showActions]="false"></app-order-summary>
  </div>
  <div>
    <div class="mini-title">Orders {{table}}</div>
    <app-order-list [table]="table"></app-order-list>
  </div>
  <div>
    <div class="mini-title">Events {{table}}</div>
    <app-event-list [table]="table"></app-event-list>
  </div>
</div>
<div class="probe-panel">
  <div>
    <div class="mini-title">Print bongs {{table}}</div>
    <app-print-job-list [table]="table"></app-print-job-list>
  </div>
</div>
