import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FireService} from "../../../services/fire.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {TerminalTx} from "../../../models/terminal-tx";
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import { BehaviorSubject, combineLatest, Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-termtxs',
  templateUrl: './termtxs.component.html',
  styleUrls: ['./termtxs.component.css']
})
export class TermtxsComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  private termtxs: TerminalTx[];
  venueId: string;

  constructor(private fire: FireService, private route: ActivatedRoute, public dialog: MatDialog) { }

  displayedColumns = ["terminalName", "amountCent", "bamboraReference", "externalReference", "internalReference",
    "serialNumber", "status", "type", "date"];
  dataSource: MatTableDataSource<TerminalTx>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit(): void {
    this.sub = this.route.paramMap.pipe(
      switchMap((data) => {
        this.venueId = data.get("venue_id");
        return this.fire.observeTerminalTxs(Number(this.venueId));
      })
    ).subscribe(termtxs => {
      this.termtxs = termtxs;
      this.refresh();
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    console.log("TermtxsComponent destroyed");
  }

  private refresh() {
    // for (const termtx of this.termtxs) {
    //   const clientStatus = DeviceStateClient.extractStatus(cl.status, cl.lastOk);
    //   const iac = DeviceStatusIconAndColor(clientStatus);
    //   termtx.icon = "";
    //   termtx.iconColor = "red";
    // }
    // eslint-disable-next-line max-len
    // const filtered = this.clients.filter(cl => (cl.clientStatus !== DeviceStateStatus.Offline || !this.hideOffline) && (!DeviceStateIsOld(cl.updated, 24 * 60) || !this.hideOld));
    this.dataSource = new MatTableDataSource( this.termtxs as TerminalTx[]);
    this.dataSource.sort = this.sort;
  }

  nameClick(termtx: TerminalTx) {
    alert(`Terminal TX info. ref: ${termtx.ref} key: ${termtx.receiptKey} `);
  }
}
