<div class="events-container">
  <div >
    <!--    <div>-->
    <!--      <mat-slide-toggle [(ngModel)]="hideOffline" color="primary">Hide offline</mat-slide-toggle>-->
    <!--      <mat-slide-toggle style="margin-left: 20px" [(ngModel)]="hideOld" color="primary">Hide older than 24h</mat-slide-toggle>-->
    <!--    </div>-->

    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;max-width: 125px;font-size:12px;white-space: normal;word-wrap: break-word;"> {{element.action}} </td>
      </ng-container>

      <ng-container matColumnDef="desc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Desc </th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;max-width: 125px;font-size:12px;white-space: normal;word-wrap: break-word;"> {{element.desc}} </td>
      </ng-container>

      <ng-container matColumnDef="table">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Table </th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.table}} </td>
      </ng-container>

      <ng-container matColumnDef="amount_cent">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.amount_cent/100 | number}} </td>
      </ng-container>

      <ng-container matColumnDef="device_user_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Device </th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.device_user_name}} </td>
      </ng-container>

      <ng-container matColumnDef="staff_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Personal </th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.staff_name}} </td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.date.toDate() |date:'medium'}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></tr>
    </table>
  </div>
  <div *ngIf="summary" class="order-detail-container">
    <div *ngIf="summary">
      <div *ngFor="let row of summary.rows">
        <div *ngIf="row.title" class="summary-title">{{row.title.text}}</div>
        <div *ngIf="row.divider" class="summary-divider"><div class="summary-divider-line"><div></div></div><div>{{row.divider.text}}</div><div class="summary-divider-line"><div></div></div></div>
        <div *ngIf="row.item" class="summary-item">
          <div style="margin-right: 16px;font-weight: bold;">{{row.item.groupedItems.orderItems.length}}</div>
          <div>
            <div>{{row.item.name()}}</div>
            <div *ngIf="row.item.customized()" class="tweak">{{row.item.customized()}}</div>
            <div *ngIf="row.item.comment()" class="tweak">{{row.item.comment()}}</div>
          </div>
          <div style="margin-left: auto;" [ngStyle]="{'color':row.item.priceColor(),'text-decoration': row.item.textDecoration()}">{{row.item.priceFormatted()}}</div>
        </div>
      </div>
      <div class="fp-footer">
        <div class="total">{{summary.price.priceFormatted()}}</div>
        <div class="vat">moms: {{summary.price.vatFormatted()}}</div>
      </div>
    </div>
  </div>
</div>


