<app-header-card [venueId]="venueId"></app-header-card>

<div>
  <p>
    <mat-toolbar>
      <button mat-icon-button routerLink="/catalog/{{venueId}}/history"><mat-icon>arrow_back_ios</mat-icon></button>
      <span>Meny version</span>
      <span class="spacer"></span>
      <button mat-button [matMenuTriggerFor]="menuSave"><mat-icon>save</mat-icon></button>
      <mat-menu #menuSave="matMenu">
        <button mat-menu-item (click)="restoreVersion()">Restore version</button>
        <button mat-menu-item routerLink="/catalog/{{venueId}}/history">View history</button>
      </mat-menu>

    </mat-toolbar>
  </p>
</div>

<div class="version-panel" *ngIf="menuVersion">
  <div>{{menuVersion.hash}}</div>
  <div>{{menuVersion.created.toDate() | date:'medium' }}</div>
  <div>Published by {{menuVersion.user_name}} (right click on changed cells to see who edited the value)</div>
</div>

<div style="padding: 16px;">
  <div>
    <mat-toolbar>
      <button mat-flat-button *ngFor="let ref of menuVersion?.menuTabVersionRefs" (click)="showTab(ref)" [color]="tabVersion?.id===ref.tabId?'primary':''" class="vbarb">{{ref.name}} {{ref.changes?"*":""}}</button>
    </mat-toolbar>
    <div *ngIf="dbMatrix">
      <table>
        <tr>
          <th *ngFor="let col of columns">{{col}}</th>
        </tr>
        <tr *ngFor="let row of this.tabVersion.rows">
          <td *ngFor="let col of columns" [ngClass]="{'changed-cell':selectedRef.changes && readFromDB(row,col)?.changed}" (contextmenu)="showCellInfo(row, col)">{{readFromDB(row,col)?.value}}</td>
        </tr>
      </table>
    </div>
    <div *ngIf="tabSettings" class="diff-panel">
      <div>Tab settings</div>
      <ngx-json-viewer  [json]="tabSettings"></ngx-json-viewer>
    </div>

  </div>
</div>
