<div class="detail-container">
  <div class="detail-toolbar icon-toolbar">
    <div class="user-icon" *ngFor="let u of summary?.activity" (click)="userSelected(u)" [ngClass]="{'selected': u.user_id === selectedUser?.id || u.anon_id === selectedUser?.id}">
      <img *ngIf="u.profile_pic" [src]="u.profile_pic" onerror="this.src=''" width="48px" height="48px">
      <mat-icon *ngIf="!u.profile_pic" style="font-size: 32px;padding: 8px;">face</mat-icon>
      <div class="user-alias">{{u.alias}}</div>
    </div>
    <div *ngIf="summary" class="user-icon" style="display: flex; justify-content: center; align-items: center;" (click)="addNewUser()">
      <mat-icon>add</mat-icon>
    </div>
  </div>
  <div style="height:calc(100% - 0px)" (click)="clickOutside()">
    <div class="detail-body" style="height:calc(100% - 72px);background-color: #f2f9e6;">
      <div class="small-header">Ny beställning {{table}} *</div>
      <div *ngIf="summary">
        <div *ngFor="let row of summary.rows">
          <div *ngIf="row.title" class="summary-title">{{row.title.text}}</div>
          <div *ngIf="row.divider" class="summary-divider"><div class="summary-divider-line"><div></div></div><div>{{row.divider.text}}</div><div class="summary-divider-line"><div></div></div></div>
          <!-- [Id] = Set id for last affected item, will scroll to this -->
          <div *ngIf="row.item" class="summary-item" (click)="itemClicked($event, row)"
               [id]="row.item.groupedItems.orderItems[0].id === affectedItem?.id && row.item.groupedItems.listItemUniqueKey !== selectedItem? 'affectedItem' : undefined"
               [ngClass]="{'selected-summary-item':row.item.groupedItems.listItemUniqueKey === selectedItem,
                  'highlight': row.item.groupedItems.orderItems[0].id === affectedItem?.id && row.item.groupedItems.orderItems[0].userId === affectedItem?.userId}">
            <div style="margin-right: 16px;font-weight: bold;">{{row.item.groupedItems.count()}}</div>
            <div class="edit-buttons" *ngIf="row.item.groupedItems.listItemUniqueKey === selectedItem">
              <div (click)="editAddItem($event, row)">+</div>
              <div (click)="editSubItem($event, row)">-</div>
              <div (click)="editItem($event, row)">...</div>
            </div>
            <div>
              <div>{{row.item.name()}}</div>
              <div *ngIf="row.item.groupedItems.listItemUniqueKey !== selectedItem">
                <div *ngIf="row.item.customized()" class="tweak">{{row.item.customized()}}</div>
                <div *ngIf="row.item.comment()" class="tweak">{{row.item.comment()}}</div>
              </div>
            </div>
            <div *ngIf="row.item.groupedItems.listItemUniqueKey !== selectedItem" style="margin-left: auto;white-space: nowrap;" [ngStyle]="{'color':row.item.priceColor(),'text-decoration': row.item.textDecoration()}">{{row.item.priceFormatted()}}</div>
            <div *ngIf="row.item.groupedItems.listItemUniqueKey === selectedItem" style="margin-left: auto;"></div>
            <div class="item-user-alias">{{row.item.userName}}</div>
          </div>
        </div>
      </div>

    </div>
    <div class="amount-panel" (click)="clickOutside()">
      <div class="total">{{summary?.price.priceFormatted()}}</div>
      <div class="vat">moms: {{summary?.price.vatFormatted()}}</div>
    </div>
  </div>
</div>

