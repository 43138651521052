export const pageReportSettings = {
  title: {sv: "Rapporter"},
  page_settings: {
    root_path: "reports"
  },
  desc: {sv: "Justera inställningar för rapporter"},
  properties: [
    {key: "day_partitions", title: {sv: "Timintervall"}, desc: {sv: "Anger intervall försäljningen för en dag skall delas in i (i start- och sluttid)"}, type: "table",
      columns: [
        {key: "name", title: {sv: "Namn"}, type: "string", width: 200 },
        {key: "start_hour", title: {sv: "Starttid (HH:MM)"}, type: "string", width: 200, optional: true },
        {key: "end_hour", title: {sv: "Sluttid (HH:MM)"}, type: "string", width: 200, optional: true },
      ]
    },
  ]
};
