import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-floorplan-activity',
  templateUrl: './floorplan-activity.component.html',
  styleUrls: ['./floorplan-activity.component.css']
})
export class FloorplanActivityComponent implements OnInit {
  venueId: number;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
    });
  }

}
