<div class="tree-view-container">
  <mat-toolbar>
    <span>IC-klasser</span>
    <span class="spacer"></span>
    <!-- <button mat-button *ngIf="expandCollapseStatus === 'collapse'" (click)="toggleExpandCollapse()">
          <mat-icon>unfold_more</mat-icon> Öppna alla
        </button>

        <button mat-button *ngIf="expandCollapseStatus === 'expand'" (click)="toggleExpandCollapse()">
          <mat-icon>unfold_less</mat-icon> Stäng alla
        </button> -->
  </mat-toolbar>

  <div class="content-container">
    <div class="menu-and-wiki-display">
      <div class="tree-container">
        <ng-material-multilevel-menu [configuration]='config' [items]='icTree' (selectedItem)="selectedItem($event)"
          (menuIsReady)="menuIsReady($event)" (selectedLabel)="selectedLabel($event)">
        </ng-material-multilevel-menu>
      </div>

      <div class="wiki-display-container">
        <app-wiki-display section="support" path="icclasses" [style]="'wiki-doc'" title="IC-klasser"></app-wiki-display>
      </div>
    </div>
  </div>
</div>