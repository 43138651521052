import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, MatSortable } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { VenueTicket } from 'src/app/models/venue-ticket';
import { HackUtils } from 'src/app/utils/utils';
import { FireService } from 'src/app/services/fire.service';
import firebase from "firebase/compat/app";
import Timestamp = firebase.firestore.Timestamp;
import { TicketService } from 'src/app/services/ticket.service';
import { SingleTicketDialogComponent } from '../single-ticket-dialog/single-ticket-dialog.component';
import { VenueService } from 'src/app/services/venue.service';
import { Venue } from 'src/app/models/venue-list';
import { combineLatest, Subscription } from 'rxjs';
import { VenueConfig } from 'src/app/models/venue-config';
import { VenueMapService } from 'src/app/services/venue-map.service';

@Component({
  selector: 'app-mini-tickets',
  templateUrl: './mini-tickets.component.html',
  styleUrls: ['./mini-tickets.component.css']
})
export class MiniTicketsComponent implements OnInit, OnDestroy {

  venueId?: number;
  displayedColumns = ["created", "venueName", "title", "content", "state", "userName", ];
  private paramSub: Subscription;
  dataSource: MatTableDataSource<VenueTicket>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  filterStates: string[];
  selectedStates: string[] = [];
  venues: Venue[];
  ticketFilter: string;

  constructor(public dialog: MatDialog, private fire: FireService, private venueMapService: VenueMapService,
              private ticketService: TicketService, private venueService: VenueService) {}

  ngOnInit(): void {
    this.ticketFilter = "";
    this.filterStates = this.ticketService.getStatesList();
    this.sort.sort(({ id: 'created', start: 'desc'}) as MatSortable);
    // this.fetchVenues();
    this.fetchTickets();
  }

  ngOnDestroy(): void {
    if (this.paramSub) {
      this.paramSub.unsubscribe();
    }
  }

  fetchTickets() {
    if (this.paramSub) {
      this.paramSub.unsubscribe();
    }
    const selectedStates = this.selectedStates.length > 0 ? this.selectedStates : this.filterStates;
    const venueId = this.venueId ? this.venueId : 0;

    this.paramSub = combineLatest([
      this.fire.observeMiniTickets(venueId, selectedStates),
      this.venueMapService.getVenueConfigs(),
    ]
    ).subscribe(res => {
      console.log("res", res);
      const tickets = res[0] as VenueTicket[];
      const cfgs = res[1] as VenueConfig[];
      tickets.forEach(t => {
        const cfg = cfgs.find(c => c.venueId === t.venueId);
        t.venueName = cfg ? cfg.venue.name : "Unknown";
      });
      this.dataSource = new MatTableDataSource(tickets);
      this.dataSource.sort = this.sort;
      this.applyFilter();
    });
  }

  fetchVenues() {
    this.venueService.getVenueList([], false).then(venues => {
      console.log("Venues", venues);
      this.venues = venues;
    });
  }

  applyFilter() {
    this.dataSource.filter = this.ticketFilter.trim().toLowerCase();
  }


  clearFilters() {
    this.selectedStates = [];
    this.ticketFilter = "";
    if (this.paramSub) {
      this.paramSub.unsubscribe();
    }
    this.fetchTickets();
  }

  newTicket() {

  }

  selectTicket(ticket: VenueTicket) {
    console.log("selectTicket", ticket);
    this.openTicketDialog(ticket);
  }

  openTicketDialog(ticket: VenueTicket) {
    console.log("Ticket", ticket);
    const dialogRef = this.dialog.open(SingleTicketDialogComponent, HackUtils.DLG({
      data: ticket,
      disableClose: true
    }));
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  showState(state: string) {
    return this.ticketService.showState(state);
  }

  transformDate(date: Timestamp) {
    if (!date || isNaN(date.seconds)) { return ""; }
    return date.toDate();
  }

}
