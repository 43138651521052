import firebase from "firebase/compat/app";
import {POSTerminalConfig, PrinterConfig} from "./venue-config";
import {TerminalStatus} from "./terminal-status";
import {ClientStatus} from "./client-status";
import {PrinterStatus} from "./printer-status";
import * as moment from 'moment';

export const enum DeviceStateStatus {
  Online = "Online",
  Busy = "Busy",
  Offline = "Offline",
  Warning = "Warning",
  Standby = "Standby",
  Unknown = "Unknown",
}

function DeviceStateStatusFromString(s: string): DeviceStateStatus {
  switch (s) {
    case "Online":
    case "OK":
      return DeviceStateStatus.Online;
    case "Busy":
      return DeviceStateStatus.Busy;
    case "Offline":
    case "Error":
      return DeviceStateStatus.Offline;
    case "Warning":
      return DeviceStateStatus.Warning;
    default:
      return DeviceStateStatus.Unknown;
  }
}

export function DeviceStateIsOld(s: firebase.firestore.Timestamp, oldMinutes: number = 2): boolean {
  const minutes = moment().diff(s.toDate()) / (60 * 1000);
  return minutes > oldMinutes;
}

function DeviceStateSuperNew(s: firebase.firestore.Timestamp): boolean {
  const millis = moment().diff(s.toDate());
  return millis < 1000;
}

export interface IconAndColor {
  icon: string;
  color: string;
}

export function DeviceStatusIconAndColor(status: DeviceStateStatus): IconAndColor {
  let icon: string;
  let color: string;
  switch (status) {
    case DeviceStateStatus.Online:
      icon = "check_circle";
      color = "primary";
      break;
    case DeviceStateStatus.Offline:
      icon = "error";
      color = "warn";
      break;
    case DeviceStateStatus.Busy:
      icon = "cached";
      color = "primary";
      break;
    case DeviceStateStatus.Warning:
      icon = "warning";
      color = "accent";
      break;
    case DeviceStateStatus.Standby:
      icon = "snooze";
      color = "accent";
      break;
    case DeviceStateStatus.Unknown:
      icon = "warning";
      color = "accent";
      break;
    default:
      icon = null;
  }
  return {icon, color};
}


export class DeviceState {
  clients: DeviceStateClient[];
  agents: DeviceStateClient[];
  sharedDevices: DeviceStatePrinter[];
}

export class DeviceStateClient {

  constructor(name: string, clientStatus: ClientStatus) {
    this.name = name;
    this.clientStatus = clientStatus;
  }

  name: string;
  terminals: DeviceStateTerminal[];
  printers: DeviceStatePrinter[];
  clientStatus: ClientStatus;

  static extractStatus(clientStatusString: string, date: firebase.firestore.Timestamp): DeviceStateStatus {
    if (clientStatusString == null) {
      return DeviceStateStatus.Unknown;
    } else if (DeviceStateIsOld(date)) {
      return DeviceStateStatus.Offline;
    }
    return DeviceStateStatusFromString(clientStatusString);
  }

  getStatus(): DeviceStateStatus {
    return DeviceStateClient.extractStatus(this.clientStatus?.status, this.clientStatus?.lastOk);
  }

  recentlyUpdated(): boolean {
    if (this.clientStatus != null) {
      return DeviceStateSuperNew(this.clientStatus.updated);
    }
    return false;
  }
}

export class DeviceStateTerminal {

  constructor(config: POSTerminalConfig, terminalStatus: TerminalStatus) {
    this.name = config.name;
    this.config = config;
    this.terminalStatus = terminalStatus;
  }

  name: string;
  config: POSTerminalConfig;
  terminalStatus: TerminalStatus;

  getStatus(): DeviceStateStatus {
    if (this.terminalStatus == null) {
      return DeviceStateStatus.Unknown;
    } else if (DeviceStateIsOld(this.terminalStatus.updatedDate, 300)) {
      if (this.terminalStatus.status === "Online") {
        // Older than 5h but online
        return DeviceStateStatus.Standby;
      }
      return DeviceStateStatus.Offline;
    }

    return DeviceStateStatusFromString(this.terminalStatus.status);
  }

  recentlyUpdated(): boolean {
    if (this.terminalStatus != null) {
      return DeviceStateSuperNew(this.terminalStatus.updatedDate);
    }
    return false;
  }

}

export class DeviceStatePrinter {
  constructor(t: PrinterConfig, printerStatus: PrinterStatus) {
    this.name = t.name;
    this.config = t;
    this.printerStatus = printerStatus;
  }

  name: string;
  config: PrinterConfig;
  printerStatus: PrinterStatus;

  getStatus(): DeviceStateStatus {
    if (this.printerStatus == null) {
      return DeviceStateStatus.Unknown;
    } else if (DeviceStateIsOld(this.printerStatus.updated, 5)) {
      if (this.printerStatus.status === "OK") {
        // Older than 5 min but probably online
        return DeviceStateStatus.Standby;
      }
      return DeviceStateStatus.Offline;
    }

    return DeviceStateStatusFromString(this.printerStatus.status);
  }

  recentlyUpdated(): boolean {
    if (this.printerStatus != null) {
      return DeviceStateSuperNew(this.printerStatus.updated);
    }
    return false;
  }
}
