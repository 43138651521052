import {ItemAttribute, OrderItem} from "./order";
import {BehaviorSubject} from "rxjs";
import firebase from "firebase/compat/app";
import {MenuItem, MenuItemOption} from "./menu-structure";
import * as uuid from 'uuid';
import {Md5} from 'ts-md5/dist/md5';
import {MoneyUtils, NodeUtils} from "../utils/utils";
import Utils from "../common/utils";

export interface AddMenuItemParams {
  comment?: string;
  attributes?: ItemAttribute[];
  price?: number;
  editedOrderItem?: OrderItem;
}

export class EditableOrder {
  items: OrderItem[];
  table: string;
  subject: BehaviorSubject<{items: OrderItem[], added?: OrderItem}>;
  finalized: firebase.firestore.Timestamp;
  clientId: string;

  constructor(table: string) {
    this.table = table;
    this.items = [];
    this.subject = new BehaviorSubject({items: this.items});
    this.finalized = firebase.firestore.Timestamp.fromDate(new Date());
    this.clientId = uuid.v4();
  }

  addMenuItem(menuItem: MenuItem, count: number, user: { name: string; id: any }, params?: AddMenuItemParams) {
    // @ts-ignore
    let orderItem: OrderItem = {};
    if (params.editedOrderItem == null) {
      const rid = Utils.randomString(10);
      orderItem.rid = rid;
      orderItem.count = count;
      orderItem.userId = user.id;
      orderItem.user_id = `${user.id}`;
      orderItem.anonName = user.name;
      orderItem.item = menuItem.id;
      orderItem.name = menuItem.name;
      orderItem.pos_name = menuItem.pos_name;
      orderItem.bong_name = menuItem.bong_name;
      orderItem.phase = menuItem.phase;
      orderItem.form = menuItem.form;
      orderItem.type = menuItem.form === "d" ? 1 : 0;
    } else {
      orderItem = params.editedOrderItem;
    }

    const price = params.price != null ? params.price : priceIncludingAttributes(menuItem, params.attributes);
    orderItem.id = buildMenuId(menuItem, params);
    orderItem.price = price;
    orderItem.vat_amount = MoneyUtils.calcVAT(price, menuItem.vat);
    orderItem.customized = buildLocalCustomizedRepresentation(params.attributes);
    orderItem.wait = 0;

    if (params?.comment) {
      // @ts-ignore
      orderItem.comment = params.comment;
    }

    if (params?.attributes) {
      // @ts-ignore
      orderItem.attributes = params.attributes;
    }

    if (params?.price) {
      // @ts-ignore
      orderItem.fixed_price = params.price;
    }

    const oi = orderItem as unknown as OrderItem;
    if (params.editedOrderItem == null) {
      console.log(`Adding item:`, oi);
      this.items.push(oi);
    } else {
      console.log(`Edited item:`, oi);
    }
    this.subject.next({items: this.items, added: oi});
    return oi;
  }

  addMenuItemCopy(originalOrderItem: OrderItem) {
    const rid = Utils.randomString(10);
    const orderItem = NodeUtils.deepcopyWithJSON(originalOrderItem);
    orderItem.rid = rid;
    console.log(`Adding item copy:`, orderItem);
    this.items.push(orderItem);
    this.subject.next({items: this.items, added: orderItem});
  }

  removeItem(itemId: string) {
    // Remove last item to keep sort order
    for (let i = this.items.length - 1; i >= 0; i--) {
      if (this.items[i].id === itemId) {
        const deletedItems = this.items.splice(i, 1);
        this.subject.next({items: this.items, added: deletedItems[0]});
        return;
      }
    }
  }

  getItemCount(itemId: string) {
    let count = 0;
    for (const item of this.items) {
      if (item.item === itemId) {
        count += item.count;
      }
    }
    if (count > 0) {
      return count;
    }
    return undefined;
  }

  observeItemsChanges() {
    return this.subject;
  }

  notifyItemChanges() {
    const v = this.subject.value;
    if (v != null) {
      this.subject.next({items: v.items});
    }
  }

}

export function buildMenuId(menuItem: MenuItem, params?: AddMenuItemParams): string {
  if (params == null) {
    return menuItem.id;
  }
  let id = menuItem.id;

  const attrValues = [];
  const attributes = params?.attributes;
  if (attributes) {
    for (const atr of attributes) {
      attrValues.push(atr.id + "|" + atr.value);
    }
  }
  if (attrValues.length > 0) {
    id += "%%||" + attrValues.join("%%");
  }

  let preMd5 = "";
  if (params.comment) {
    preMd5 += params.comment;
  }
  if (params.price) {
    preMd5 += MoneyUtils.toCent(params.price).toString();
  }
  if (preMd5 !== "") {
    id += "%%||" + Md5.hashStr(preMd5);
  }
  return id;
}

export function priceIncludingAttributes(menuItem: MenuItem, attributes: ItemAttribute[]): number | null {
  if (attributes == null) { return null; }

  const itemPrice = Number(menuItem.price);
  console.log("itemPrice", itemPrice);
  const options = attributes.filter( a => a.value );
  const optionWithAbsolutePrice = options.find( o => o.price > 0);
  const price = optionWithAbsolutePrice == null ? itemPrice : optionWithAbsolutePrice.price;
  console.log("optionWithAbsolutePrice", optionWithAbsolutePrice);

  const optionsWithRelPrice = options.filter(o => o.priceDiff != null);
  let totRel = 0;
  if (optionsWithRelPrice.length > 0) {
    for (const r of optionsWithRelPrice) {
      const sign = r.priceDiff.startsWith("-") ? -1 : 1;
      const pd = sign * Number(r.priceDiff.replace("+", "").replace("-", ""));
      totRel += pd;
    }
  }
  console.log("totRel", totRel);

  return price + totRel;
}

export function buildLocalCustomizedRepresentation(attributes: ItemAttribute[]) {
  const s = [];
  for (const attr of attributes) {
    s.push(attr.title);
  }
  return s.join(", ");
}

export function collectAttributesList(menuItem: MenuItem, attributeValues: {}): ItemAttribute[] {
  const attributes = [];
  if (menuItem.attributes) {
    for (const attr of menuItem.attributes) {
      const key = attributeValues[attr.id];
      if (key == null) { continue; }
      const option = attr.options.find( o => o.key === key) as MenuItemOption;
      attributes.push({
        id: attr.id,
        value: key,
        price: option.price,
        title: option.value.toString(),
        priceDiff: option.price_diff
      });
    }
  }
  return attributes;
}

export function prepareDefaultAttributeValues(menuItem: MenuItem, orderItem?: OrderItem): {} {
  const attributeValues = {};
  if (menuItem.attributes) {
    for (const attr of menuItem.attributes) {
      const ca = orderItem?.attributes?.find(oa => oa.id === attr.id);
      if (ca) {
        attributeValues[attr.id] = ca.value;
      } else {
        // Find default option
        const defOpt = attr.options.find(o => o.default) ?? (attr.options.length > 1 ? attr.options[0] : undefined);
        if (defOpt) {
          attributeValues[attr.id] = defOpt.key;
        }
      }
    }
  }
  return attributeValues;
}

/*
fun priceForAttributes(attributes: List<ItemAttribute>?): Double {
  if (attributes != null) {
    val options = attributes.mapNotNull {
      getAttributeOption(it.id, it.value)
    }
    val optionWithAbsolutePrice = options.firstOrNull { it.hasAbsolutePrice() }
    val price = optionWithAbsolutePrice?.price ?: priceAsDouble
    val optionsWithRelPrice = options.filter { it.hasRelativePrice() }
    return if (optionsWithRelPrice.isNotEmpty())
      price + optionsWithRelPrice.sumOf { it.priceDiffDouble ?: 0.0 }
  else
    price
  }
  return priceAsDouble
}
*/


/*
private fun buildMenuId(menuItem: MenuItem, fixedPrice: Double?, attributes: List<ItemAttribute>?, comment: String?, phase: Int?, adjustments: List<ItemAdjustment>?): String {
  var id = menuItem.id
  attributes?.let {
    if(it.isNotEmpty())
      id += "%%%%" + it.map { atr -> String.format("%s|%s", atr.id, atr.value) }.joinToString(separator = "%%")
  }

  val cent = fixedPrice?.let { LocalPriceCalculator.toCent(it).toString() }
  val phase = null
  val ajds = adjustments?.joinToString(",") { it.id }
  val list = listOf(comment, cent, phase, ajds)
  val md5String = list.filterNotNull().joinToString("")
  if (md5String.isNotEmpty())
    md5String.getMD5Hash()?.let { id += "%%||" + it }

  return id
}
*/
