import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Order, OrderSummary} from "../../models/order";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, MatSortable} from "@angular/material/sort";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {FireService} from "../../services/fire.service";
import {OrderService} from "../../services/order.service";
import * as moment from 'moment';
import {VenueEvent} from "../../models/venue-event";

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css']
})
export class EventListComponent implements OnInit, OnDestroy {
  @Input() table: string;
  private events: VenueEvent[];
  hideOffline = false;
  hideOld = true;
  venueId: number;
  selectedOrder: Order;
  summary: OrderSummary;

  displayedColumns = ["action", "desc", "table", "amount_cent", "device_user_name", "staff_name", "date"];
  dataSource: MatTableDataSource<VenueEvent>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private eventObs: Subscription;

  constructor(private route: ActivatedRoute, private fire: FireService, private orderService: OrderService) { }

  ngOnInit(): void {
    this.sort.sort(({ id: 'updated', start: 'desc'}) as MatSortable);
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.beginObserve();
    });

  }

  ngOnDestroy(): void {
    if (this.eventObs) {
      this.eventObs.unsubscribe();
    }
  }

  private beginObserve() {
    const date = moment().subtract(7, "day").toDate();
    this.eventObs = this.fire.observeVenueEvents(this.venueId, date).subscribe(events => {
      if (this.table) {
        this.events = events.filter(ev => ev.table === this.table);
      } else {
        this.events = events;
      }
      this.refresh();
    });
  }

  private refresh() {
    this.dataSource = new MatTableDataSource( this.events as VenueEvent[]);
    this.dataSource.sort = this.sort;
  }

  select(order: Order) {
    console.log(order);
    this.orderService.parseItems(order);
    this.summary = this.orderService.summarizeOrdersByTimeAndPhase([order], "", null);
    // this.selectedOrder = order;
  }

}
