<div>
  <p>
    <mat-toolbar>
      <span>Konton</span>
      <span class="spacer"></span>
      <button mat-button (click)="save()"><mat-icon>save</mat-icon> Spara</button>
    </mat-toolbar>
  </p>
</div>

<div *ngIf="errorMessage" class="error-panel" style="margin-bottom: 32px;">
  <pre>{{errorMessage}}</pre>
  <div class="error-close" (click)="closeError()"><mat-icon>close</mat-icon></div>
</div>

<div class="vc-container" *ngIf="venueCustomers">
  <hot-table
    [hotId]="hotId"
    [settings]="hotSettings">
  </hot-table>
</div>
