import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {QueryReceiptRequest, VenueService} from "../../services/venue.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Zreport, ZreportRequest} from "../../models/zreport";
import {AuthService} from "../../services/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {SimpleDialogComponent} from "../simple-dialog/simple-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import {HackUtils} from "../../utils/utils";
import {SelectDateDialogComponent} from "./select-date-dialog/select-date-dialog.component";

@Component({
  selector: 'app-zreports',
  templateUrl: './zreports.component.html',
  styleUrls: ['./zreports.component.css']
})
export class ZreportsComponent implements OnInit, OnDestroy {
  private paramSub: Subscription;
  displayedColumns = ['serial_number', 'date_key', 'total_sales', "total_tip", 'receipt_count', "goods_sold_count"];
  dataSource: MatTableDataSource<Zreport>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  venueId: string;
  zreport: Zreport;
  zreportKey: string;
  filter: string;
  cursor: string;
  cursors: string[];
  count: number;
  pageSize = 30;
  currentPageIndex = 0;

  constructor(private venueService: VenueService,
              private route: ActivatedRoute, private router: Router,
              private auth: AuthService, private dialog: MatDialog, private snackBar: MatSnackBar,
              public datePipe: DatePipe) {

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    this.cursors = [];
    this.sort.sort(({ id: 'serial_number', start: 'desc'}) as MatSortable);
    this.paramSub = this.route.paramMap.subscribe(data => {
      this.venueId = data.get("venue_id");
      this.zreportKey = data.get("key");
      this.fetchZReports(this.currentPageIndex);
      if (this.zreportKey && this.zreportKey !== "0") {
        this.fetchZReport();
      }
    });
  }

  ngOnDestroy(): void {
    this.paramSub?.unsubscribe();
  }

  pageChanged($event: PageEvent) {
    const pageIndex = $event.pageIndex;
    const canFetch: boolean = Math.abs(this.currentPageIndex - pageIndex) === 1;

    if (this.currentPageIndex > pageIndex && canFetch) {
      this.cursors.pop();
      this.cursors.pop();
    }
    if (this.pageSize !== $event.pageSize) {
      this.pageSize = $event.pageSize;
      this.resetPage();
    } else {
      this.cursor = this.cursors[this.cursors.length - 1];
    }
    if (canFetch) {
      this.fetchZReports(pageIndex);
    }
  }

  resetPage() {
    this.cursor = undefined;
    this.cursors = [];
    this.currentPageIndex = 0;
    this.paginator.firstPage();
    this.fetchZReports(this.currentPageIndex);
  }

  fetchZReport() {
    this.venueService.fetchZReport(Number(this.venueId), this.zreportKey)
    .then(zr => {
      this.zreport = zr;
    });
  }

  fetchZReports(pageIndex: number) {
    const request = new ZreportRequest();
    request.venue_id = this.venueId;
    request.page_size = this.paginator != null ? this.paginator.pageSize : this.pageSize;
    request.cursor = this.cursor;
    this.venueService.paginateZReports(request).then(r => {
      this.currentPageIndex = pageIndex;
      this.count = r.count;
      if (this.cursors.length === 0 || this.cursors[this.cursors.length - 1] !== r.cursor) {
        this.cursors.push(r.cursor);
      }
      if (r != null && r.zreports != null && r.zreports[0] != null) {
        //this.fullCount = r.receipts[0].full_count != null ? r.receipts[0].full_count : 0;
        // console.log(r);
        this.dataSource = new MatTableDataSource(r.zreports as Zreport[]);
        this.dataSource.sort = this.sort;
      } else {
        this.dataSource = new MatTableDataSource([]);
      }
    });
  }

  isHeynowAdmin() {
    return AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }

  createZReport() {
    const ds = {title: `Skapa nästa z-rapport?`, message: "Normalt skapas automatiskt z-rapporter på morgonen. Att skapa en z-rapport manuellt kan resultera i KONSTIGHETER! (OBS! Denna knapp är bara tillgänlig för Heynow admins)", cancelButton: "Avbryt", positiveButton: "Skapa nästa Z:a"};
    SimpleDialogComponent.observeSimpleDialog(this.dialog, ds).subscribe( r => {
      if (r) {
        this.venueService.createNextZReport(Number(this.venueId), undefined).then( res => {
          this.resetPage();
        }).catch(error => this.snackBar.open(`Misslyckades med att skapa z-rapport`, "close"));
      }
    });
  }

  select(zreport: Zreport) {
    this.zreportKey = zreport.key;
    this.router.navigateByUrl(`venue/${this.venueId}/zreports/${this.zreportKey}`);
  }

  createZReportDate() {
    this.openSelectDate();
  }

  openSelectDate() {
    const dialogRef = this.dialog.open(SelectDateDialogComponent, HackUtils.DLG({
      data: { venueId: this.venueId }
    }));
    dialogRef.afterClosed().subscribe(dateKey => {
      if (dateKey) {
        console.log('Valt datum:', dateKey);
        this.venueService.createNextZReport(Number(this.venueId), dateKey).then( res => {
          this.resetPage();
        }).catch(error => this.snackBar.open(`Misslyckades med att skapa z-rapport`, "close"));
      }
    });
  }
}

