import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, ReplaySubject, Subscription} from "rxjs";
import {findMenuItem, MenuItem, MenuStructure} from "../models/menu-structure";
import {filter, first, map} from "rxjs/operators";
import {FSMenu} from "../models/venue-config";
import {FireService} from "./fire.service";
import {OrderItem} from "../models/order";

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  menuSubject: ReplaySubject<MenuStructure> = new ReplaySubject(1);
  menu: MenuStructure;
  private menuObsSubject: Subscription;
  private venueId: number;

  constructor(private fire: FireService) {}

  beginObserveMenu(venueId: number) {
    if (this.venueId !== venueId || this.menuObsSubject == null ) {
      this.venueId = venueId;
      this.menuObsSubject?.unsubscribe();
      this.menuObsSubject = this.fire.observeMenuStructure(venueId).subscribe( menu => {
        this.menu = menu;
        this.menuSubject.next(menu);
      });
    }
  }

  observeMenuStructure(venueId: number): Observable<MenuStructure> {
    this.beginObserveMenu(venueId);
    return this.menuSubject;
  }

  observeMenuItem(venueId: number, itemId: string): Observable<MenuItem> {
    this.beginObserveMenu(venueId);
    return this.menuSubject.pipe(
      map( menu => findMenuItem(itemId, menu)),
      first()
    );
  }

  getMenuTreeData(venueId: number): Observable<any[]> {
    return this.observeMenuStructure(venueId).pipe(
      filter(menu => menu != null),
      map(menu => {
        const flatData = [];
        this.parseData(menu, flatData);
        return flatData.filter(item => item !== undefined);
      })
    );
  }

  private parseData(data, flatData, indent = 1) {
    if (data.drinks) {
      this.parseCategory(data.drinks, flatData, indent);
    }

    if (data.food) {
      this.parseCategory(data.food, flatData, indent);
    }

    if (data.donation_items) {
      this.parseCategory(data.donation_items, flatData, indent);
    }
  }

  private parseCategory(categories, flatData, indent) {
    categories.forEach(category => {
      if (category && category.id && category.name) {
        const subCategories = this.parseSubCategory(category.categories, flatData, indent + 2);
        if (subCategories.length > 0 || (category.items && category.items.length > 0)) {
          flatData.push({ id: category.id, name: category.name, indent, isLeaf: false });
          subCategories.forEach(subCategory => flatData.push(subCategory));
          const items = this.parseItems(category.items, flatData, indent + 3);
          items.forEach(item => flatData.push(item));
        }
      }
    });
  }

  private parseSubCategory(subCategories, flatData, indent) {
    const result = [];
    if (subCategories) {
      subCategories.forEach(subCategory => {
        if (subCategory && subCategory.id && subCategory.name) {
          const items = this.parseItems(subCategory.items, flatData, indent + 3);
          if (items.length > 0) {
            result.push({ id: subCategory.id, name: ` ${subCategory.name}`, indent, isLeaf: false });
            items.forEach(item => result.push(item));
          }
        }
      });
    }
    return result;
  }

  private parseItems(items, flatData, indent) {
    const result = [];
    if (items) {
      items.forEach(item => {
        if (item && item.id && item.name) {
          result.push({ id: item.id, name: ` ${item.name}`, indent, isLeaf: true });
        }
      });
    }
    return result;
  }
}
