<div>
  <mat-toolbar>
    <span>Ärenden</span>
    <span class="spacer"></span>
  </mat-toolbar>
</div>

<div class="filters-bar">
  <mat-form-field appearance="outline">
    <mat-label>Fritext</mat-label>
    <input matInput type="text" [(ngModel)]="ticketFilter" (keyup)="applyFilter()" placeholder="Sök i listan">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Status</mat-label>
    <mat-select [(ngModel)]="selectedStates" multiple>
      <mat-option *ngFor="let state of filterStates" [value]="state">{{showState(state).name}} {{showState(state).symbol}}</mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-flat-button class="search-button" (click)="fetchTickets()" color="primary">SEARCH</button>
  <button mat-stroked-button class="dropdown-button" [matMenuTriggerFor]="menuHandleSection"><mat-icon>expand_more</mat-icon></button>
  <mat-menu #menuHandleSection="matMenu">
    <button mat-menu-item (click)="clearFilters()">Rensa sökning</button>
  </mat-menu>
</div>

<div class="container master-detail">
  <div class="master">
    <div>
      <table class="table-cells" mat-table [dataSource]="dataSource" matSort matSortDisableClear="true">
        <ng-container matColumnDef="venueName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Venue </th>
          <td mat-cell *matCellDef="let element" style="white-space: nowrap;"> <a routerLink="/venue/{{element.venueId}}/dashboard">{{element.venueName}}</a> </td>
        </ng-container>

        <ng-container matColumnDef="userName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Användare </th>
          <td mat-cell *matCellDef="let element" style="max-width: 125px;font-size:12px;white-space: normal;word-wrap: break-word;"> {{element.userName}} </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Titel </th>
          <td mat-cell *matCellDef="let element" style="font-weight: 500;"> {{element.title}} </td>
        </ng-container>

        <ng-container matColumnDef="content">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Beskrivning </th>
          <td mat-cell *matCellDef="let element"><pre class="content-cell">{{element.content}}</pre></td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let element" style=" white-space: nowrap;"> {{showState(element.state).symbol}} {{showState(element.state).name}} </td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 85px;"> Skapad </th>
          <td mat-cell *matCellDef="let element"> {{transformDate(element.created) | date:"medium"}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectTicket(row)" class="mat-table-clicky"></tr>
      </table>
    </div>
  </div>
</div>
