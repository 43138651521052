import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-embedded-pdfs',
  templateUrl: './embedded-pdfs.component.html',
  styleUrls: ['./embedded-pdfs.component.css']
})
export class EmbeddedPdfsComponent implements OnInit {
  venueId: number;
  iframeUrl: SafeUrl;

  constructor(private route: ActivatedRoute, public dialog: MatDialog, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      // Dev hack
      // this.venueId = 5654524815474688;
      const url = `${environment.genji_url}/${this.venueId}`;
      console.log(url);
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }

}
