export const pagePickup = {
  title: {sv: "Pickup"},
  page_settings: {
    root_path: "pickup"
  },
  desc: {sv: "Inställningar för pickup. Pickup används när gästen är på plats, beställer online och hämtar upp sin beställning."},
  properties: [
    {key: "show_order_number", title: {sv: "Visa ordernummer på kvitto"}, desc: {sv: ""}, type: "bool", optional: false},
    {key: "anon_orderable", title: {sv: "Tillåt beställningar utan inlogg"}, desc: {sv: "Förenklar beställning för gäster, kan dock göra det lite svårare att hitta en beställning i t.ex. KDS:en"},
        type: "bool", optional: true
    },
    {key: "table_regex", title: {sv: "Bordsfilter (pickup)"}, desc: {sv: ""}, default: "pickup", type: "string", optional: false, hidden: true},
    {key: "menu_filter_tag", title: {sv: "Artikelfiltreringstag (pickup)"}, desc: {sv: ""}, default: "pickup", type: "string", optional: false},

    {header: "KDS"},
    {key: "kds_calling.active", title: {sv: "Skicka upphämtningsinstruktioner"}, desc: {sv: "Skicka upphämtningsinstruktioner till gäst när artikeln markeras som klar i KDS"}, type: "bool", optional: false},
    {key: "kds_calling.message", title: {sv: "Upphämtningsinstruktioner"}, desc: {sv: ""}, type: "table", convert_from_tree: "kds_calling.message",
      columns: [
        {key: "message", title: {sv: "Meddelande"}, type: "string", width: 200 },
        {key: "filter", title: {sv: "Filter"}, type: "string", width: 200 },
        {key: "kds_devices", title: {sv: "KDS Enhet(er)"}, type: "string", width: 200 },
      ]
    },
    {header: "Gästinformation om Pickup"},
    {key: "text.title", title: {sv: "Titel för gäst, t.ex. Pickup"}, desc: {sv: ""}, type: "string", optional: true},
    {key: "text.desc", title: {sv: "Beskrivning"}, desc: {sv: ""}, type: "string", optional: true},

    {key: "hide_in_select", title: {sv: "Åtkomst endast via QR-kod"}, desc: {sv: "Göm så pickup bara går att nås via QR-kod"}, type: "bool", optional: true},
  ]
};
