import { Injectable } from '@angular/core';
import {combineLatest, Observable} from "rxjs";
import {getMenuActivity, parseMenuActivity, Session, SessionMenuActivity} from "../models/Session";
import {
  CalculatedPrice,
  GroupedOrderItems, itemForwarded, ItemRef,
  Order, OrderItem,
  OrderSummary,
  OrderSummaryRow, OrderSummaryRowDivider,
  OrderSummaryRowItem,
  OrderSummaryRowTitle
} from "../models/order";
import {FireService} from "./fire.service";
import {filter, map} from "rxjs/operators";
import Utils from "../common/utils";
import * as moment from 'moment';
import {VenueConfig} from "../models/venue-config";

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private fire: FireService) { }

  observeOrderSummary(venueId: number, table: string): Observable<OrderSummary> {
    return combineLatest([
        this.fire.observeActiveSessions(venueId),
        this.fire.observeOrders(venueId),
        this.fire.observeVenueConfig(venueId)
      ]
    ).pipe(
      map<[Session[], Order[], VenueConfig], OrderSummary>( res => {
        const sessions = res[0];
        const orders = res[1];
        const config = res[2];
        return this.summarize(orders, sessions, table, config);
      })
    );
  }

  private summarize(orders: Order[], sessions: Session[], table: string, config?: VenueConfig): OrderSummary {
    const activeTableSession = sessions.find(ses => ses.active && ses.table === table);
    const sid = activeTableSession?.id?.split(':')[1];
    console.log("activeTableSession:", activeTableSession);
    // filter all orders at table that are: not canceled or not paid but if paid in the current table session
    const filteredOrders = orders.filter(ord => ord.table === table && !ord.canceled && (!ord.paid || ord.session_id === sid) );
    for (const fo of filteredOrders) {
      fo.items = JSON.parse(fo.items_json);
    }
    return this.summarizeOrders(filteredOrders, table, activeTableSession, false, config);
  }

  public summarizeOrders(filteredOrders: Order[], table: string, activeTableSession?: Session, isNewOrder = false, config?: VenueConfig): OrderSummary {
    const activity = getMenuActivity(activeTableSession);
    this.buildAliases(activity);
    const summary = this.summarizeOrdersByTimeAndPhase(filteredOrders, table, activity, isNewOrder, config);
    summary.activity = activity;
    return summary;
  }

  public summarizeOrdersByTimeAndPhase(orders: Order[], table: string, activity: SessionMenuActivity[], isNewOrder = false, config?: VenueConfig): OrderSummary {
    console.log("summarizeOrdersByTimeAndPhase:", table);
    const summary = new OrderSummary();
    summary.table = table;
    summary.rows = [];
    const groupedByTime = this.groupByTime(orders);
    //console.log("groupedByTime:", groupedByTime);
    for (const groupKey of Object.keys(groupedByTime)) {
      const group = groupedByTime[groupKey];
      //console.log("group:", group);
      if (!isNewOrder) {
        const timeRow = new OrderSummaryRow(null, new OrderSummaryRowTitle(groupKey), null);
        summary.rows.push(timeRow);
      }

      let allItems;
      if (isNewOrder) {
        allItems = group.flatMap( og => { og.order.items.forEach(it => this.prepNewItem(it)); return og.order.items; } );
      } else {
        allItems = group.flatMap( og => { og.order.items.forEach(it => this.prepItem(it, og.order)); return og.order.items; } );
      }
      console.log("allItems:", allItems);
      let skipFirstDivider = !isNewOrder;
      let foundFirstForward = false;
      const itemsGroupedByPhase = this.groupByPhase(allItems);
      const sortedNumKey = Object.keys(itemsGroupedByPhase).map(Number).sort((a, b) => a - b);
      for (const phaseIndex of sortedNumKey) {
        const items = itemsGroupedByPhase[phaseIndex];
        const firstItem = items[0];
        const forwarded = itemForwarded(firstItem);
        if (!skipFirstDivider || !forwarded) {
          const isNextForward = !forwarded && !foundFirstForward;
          if (isNextForward) { foundFirstForward = true; }
          const str = this.getDividerName(items[0], config);

          const itemRefs = items.map(it => ({order_key: it.orderKey, name: it.name, rid: it.rid} as ItemRef) );
          const div = new OrderSummaryRowDivider(str, isNextForward, firstItem.phase, itemRefs);
          const divider = new OrderSummaryRow(null, null, div);
          summary.rows.push(divider);
        }
        skipFirstDivider = false;

        const groupedItems = this.groupItemsByOrderKey(items);
        for (const grpItemKey of Object.keys(groupedItems)) {
          const itms = groupedItems[grpItemKey];
          const row = new OrderSummaryRow();
          const groupedItem = new GroupedOrderItems(grpItemKey, itms);
          const firstItem = itms[0];
          // console.log("FirstItem", firstItem);
          const act = activity?.find(a => a.user_id === Number(firstItem.userId) || a.anon_id === firstItem.userId);
          const un = act?.alias ?? "-";
          row.item = new OrderSummaryRowItem(groupedItem, un, false);
          summary.rows.push(row);
        }
      }
    }

    summary.price = this.calculatePrice(orders);
    return summary;
  }

  // Group orders by time gap
  private groupByTime(orders: Order[]): {string: [{ix: string, order: Order}]} {
    const ordersByDate = orders.sort( (a, b) => a.finalized.seconds - b.finalized.seconds);
    const timeIndexList = []; // mutableListOf<Pair<Int, Order>>()
    let ix = 1;
    let last: Order = null;
    // console.log(ordersByDate);

    let title = null;
    for (const order of ordersByDate) {
      if (last) {
        const diff = order.finalized.seconds - last.finalized.seconds;
        if (diff > 20 * 60 ) {
          ix++;
          title = null;
        }
      }
      last = order;
      if (title == null) { title = `${ix}: ${Utils.formatAppDate(moment(order.finalized.toDate()))}`; }
      timeIndexList.push({ ix: title, order});
    }

    return Utils.groupBy(timeIndexList, it => it.ix);
  }

  private groupByPhase(items: OrderItem[]): {number: OrderItem[]} {
    const gs = Utils.groupBy(items, it => {
      const f = itemForwarded(it) ? 0 : it.orch?.length;
      return (1 - it.type) * 1000 + it.phase * 100 + (it.wait ? 10 : 0) + f;
    });
    return gs;
  }

  private getDividerName(item: OrderItem, config?: VenueConfig) {
    let phaseName;
    const phases = config?.serving?.phases;
    if (phases) {
      const phase = phases.find(p => p.phase === item.phase);
      if (phase) {
        phaseName = phase.name;
      } else {
        phaseName = `Servering ${item.phase}`;
      }
    } else {
      if (item.type === 0) {
        phaseName = this.getPhaseName(item.phase);
      } else {
        phaseName = "Dryck";
      }
    }
    return item.wait > 0 ? `${phaseName} (vänta)` : phaseName;
  }

  private getPhaseName(phase: number) {
    switch (phase) {
      case -1:
        return "Förrätt";
      case 0:
        return "Huvudrätt";
      case 1:
        return "Efterrätt";
    }
  }

  private groupItemsByOrderKey(items: OrderItem[]): {string: OrderItem[]} {
    const gs = Utils.groupBy(items, it => `${it.id}:${it.userId}:${this.paidStr(it)}:${it.phase}`);
    console.log("groupItemsByOrderKey:", gs);
    return gs;
  }

  private paidStr(item: OrderItem): string {
    return item.cachedIsPaid ? "t" : "f";
  }

  public prepItem(it: OrderItem, order: Order) {
    it.userId = order.user_id;
    it.cachedIsPaid = this.isItemPaid(it, order);
    it.orderKey = order.id;
  }

  private prepNewItem(it: OrderItem) {
    it.cachedIsPaid = false;
    it.orderKey = it.userId;
  }

  private isItemPaid(it: OrderItem, order: Order) {
    if (order.paid != null) { return true; }
    if (!order.paidRefs) {
      order.paidRefs = order.paid_refs_json ? JSON.parse(order.paid_refs_json) : [];
    }
    const pr = order.paidRefs?.find(r => r.rid === it.rid);
    return pr != null;
  }

  private calculateOrderPrice(order: Order) {
    let amount = 0;
    let vatAmount = 0;
    let discount = 0;
    if ( order.paid == null ) {
      for (const item of order.items) {
        if (!this.isItemPaid(item, order)) {
          const count = item.count ?? 1;
          const sumPrice = count * item.price;
          const sumVat = count * item.vat_amount;
          amount += sumPrice;
          vatAmount += sumVat;
          if (item.oprice != null) {
            const sumOprice = count * item.oprice;
            const da = sumPrice - sumOprice;
            discount += da;
          }
        }
      }
    }
    return {amount, vatAmount, discount};
  }

  public calculatePrice(orders: Order[]) {
    const cp = new CalculatedPrice(0, 0, 0);
    for (const order of orders) {
      const k = this.calculateOrderPrice(order);
      cp.price += k.amount;
      cp.vatAmount += k.vatAmount;
      cp.discount += k.discount;
    }
    return cp;
  }

  public parseItems(order: Order) {
    order.items = JSON.parse(order.items_json);
  }

  private buildAliases(activity: SessionMenuActivity[]) {
    if (activity == null) { return; }
    for (const act of activity) {
      const parts = act.name?.split(" ") ?? ["X", "X"];
      if (parts.length > 1) {
        if (parts[0] === "Gäst") {
          act.alias = parts[1][0].toUpperCase();
        } else {
          act.alias = parts[0][0].toUpperCase() + parts[1][0].toUpperCase();
        }
      } else if (parts.length === 1) {
        act.alias = parts[0][0].toUpperCase();
      } else {
        act.alias = "-";
      }
    }
  }

  getServingPhasesWithFallback(config: VenueConfig) {
    return config?.serving?.phases ?? [
      { phase: -100, name: "Dryck", alias: "DR" },
      { phase: -1, name: "Förrätt", alias: "FR" },
      { phase: 0, name: "Huvudrätt", alias: "HR" },
      { phase: 1, name: "Efterrätt", alias: "DS" }
    ];
  }
}
