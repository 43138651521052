export interface QueryZReportResult {
  zreports: Zreport[];
  cursor: string;
  count: number;
}

export interface Zreport {
  key: string;
  goods_sold_count: number;
  prepaid_procure: number;
  start_date: string;
  return_count: number;
  vat_groups: Vatgroups;
  sales_areas?: any;
  non_finished_count: number;
  receipt_count: number;
  register_designation: string;
  refund_categories: any[];
  end_date: string;
  total_prepaid: number;
  grand_total_sales: number;
  grand_total_return: number;
  total_sales: number;
  serial_number: number;
  prepaid_spend: number;
  fortnox_booking_status: string;
  total_tip: number;
  payment_methods: Paymentmethods;
  sales_categories: Salescategory[];
  date_key: string;
  org_number: string;
  fortnox_booking_date: string;
  grand_total_net: number;
  bus_name: string;
  creator_id: number;
  non_finished_total: number;
  return_total: number;
  service_sold_count: number;
}

export interface Salescategory {
  account: number;
  name: string;
  vat: number;
  cat_vat: number;
  sales: number;
}

export interface Paymentmethods {
  fortnox_order_roots: number;
  invoice: number;
  bambora: number;
}

export interface Vatgroups {
  '0.25': number;
  '0.12': number;
  '0.6': number;
}

export class ZreportRequest {
  venue_id: string;
  page_size: number;
  cursor?: string;
}
