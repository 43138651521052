import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-breeze-frame',
  templateUrl: './breeze-frame.component.html',
  styleUrls: ['./breeze-frame.component.css']
})
export class BreezeFrameComponent implements OnInit {
  iframeUrl: SafeResourceUrl;
  fullScreen: boolean;
  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe( data => {
      const venueId = data.get("venue_id");
      this.fullScreen = this.route.snapshot.url[0].path === 'pos';
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`assets/breeze/index.html?venueId=${venueId}`);
      console.log(`BreezeFrameComponent venueId = ${venueId}`);
    });
  }

}
