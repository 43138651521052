import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FireService } from "../../services/fire.service";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { FSPrintJob, PrintInstruction } from "../../models/print-job";
import { combineLatest, Subscription } from "rxjs";
import { PrinterConfig } from "../../models/venue-config";
import * as moment from 'moment';
import { ObservableUtils } from 'src/app/utils/utils';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-print-job-list',
  templateUrl: './print-job-list.component.html',
  styleUrls: ['./print-job-list.component.css']
})
export class PrintJobListComponent implements OnInit, OnDestroy {
  @Input() table: string;
  venueId: number;
  unfiltredPrintJobs: FSPrintJob[];
  printJobs: FSPrintJob[];
  printers: PrinterConfig[];
  printerToggles = {};
  isSuperAdmin = false;
  private pjsub: Subscription;
  constructor(private fire: FireService, private route: ActivatedRoute, public dialog: MatDialog, private auth: AuthService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.beginObserve();
    });
  }

  ngOnDestroy(): void {
    if (this.pjsub) {
      this.pjsub.unsubscribe();
    }
  }

  materialize(pj: FSPrintJob) {
    if (pj.data && !pj.printJobData) {
      pj.printJobData = JSON.parse(pj.data);
    } else if (pj.dossier && !pj.printInstructions) {
      pj.printInstructions = JSON.parse(pj.dossier);
      this.prepareInstructions(pj.printInstructions);
    }

  }
  identify(index, item) {
    return (item as FSPrintJob).job_id;
  }

  printTime(created: any, printed: any, skipped: any): string {
    if (skipped) {
      return ''; 
    }
  
    if (!created || !printed) {
      return ''; 
    }
  
    const createdTime = moment.unix(created.seconds).add(created.nanoseconds / 1000000, 'milliseconds');
    const printedTime = moment.unix(printed.seconds).add(printed.nanoseconds / 1000000, 'milliseconds');
    const differenceInMilliseconds = printedTime.diff(createdTime, 'milliseconds');
    const differenceInSeconds = (differenceInMilliseconds / 1000).toFixed(2);
  
    return `⏱️ ${differenceInSeconds}s`;
  }
  

  private beginObserve() {
    this.pjsub = combineLatest([
      this.fire.observeVenueConfig(this.venueId),
      ObservableUtils.projectedChanges(this.fire.observePrintJobChanges(this.venueId), pj => {
        if (pj.data) {
          pj.printJobData = JSON.parse(pj.data);
        } else if (pj.dossier) {
          pj.printInstructions = JSON.parse(pj.dossier);
          this.prepareInstructions(pj.printInstructions);
        }
        return pj;
      })
    ]
    ).subscribe(res => {
      const config = res[0];
      this.printers = config.epson_printers;
      this.isSuperAdmin = AuthService.isSuperAdmin(this.auth.authStateSnapshot);

      const altPrinters: PrinterConfig[] = [];
      for (const printer of this.printers) {
        if (printer.alt_names == null || printer.alt_names === "") { continue; }
        for (const altName of printer.alt_names.split(",").map(n => n.trim())) {
          const altp = this.printers.find(pr => pr.name === altName);
          if (altp == null) {
            // @ts-ignore
            altPrinters.push({ name: altName });
          }
        }
      }
      for (const a of altPrinters) {
        this.printers.push(a);
      }

      for (const printer of this.printers) {
        if (!(printer.name in this.printerToggles)) {
          this.printerToggles[printer.name] = true;
        }
      }
      console.log(this.printerToggles);

      this.unfiltredPrintJobs = res[1];
      this.updateAndFilterPrintJobs();
    }
    );
  }

  private updateAndFilterPrintJobs() {
    console.log(`Unfiltered job count: ${this.unfiltredPrintJobs.length}`);
    // eslint-disable-next-line max-len
    const printJobs = this.unfiltredPrintJobs.filter(r => this.printerToggles[r.printer_name] && (this.table == null || r.table === this.table));
    console.log(printJobs);
    for (const pj of printJobs) {
      // if (pj.data) {
      //   pj.printJobData = JSON.parse(pj.data);
      // } else if (pj.dossier) {
      //   pj.printInstructions = JSON.parse(pj.dossier);
      //   this.prepareInstructions(pj.printInstructions);
      // }
      const minutes = moment().diff(pj.created.toDate()) / (60 * 1000);
      
      if (pj.skipped) {
        pj.color = '#f44336'; 
      } else if (pj.printed) {
        pj.color = '#317370';
      } else {
        pj.color = minutes < 60 ? '#888' : '#ff9800'; 
      }
      //pj.color = pj.state === 'completed' ? '#317370' : (minutes < 60 ? '#f44336' : '#888');
    }
    this.printJobs = printJobs;
  }

  togglePrinter() {
    this.updateAndFilterPrintJobs();
  }

  getAveragePrintTimeForPrinter(printer_name: string): string {
    const filteredJobs = this.printJobs.filter(pj => pj.printer_name === printer_name && pj.printed && pj.created);
    const printTimes = filteredJobs.map(pj => {
      const createdTime = moment.unix(pj.created.seconds).add(pj.created.nanoseconds / 1000000, 'milliseconds');
      const printedTime = moment.unix(pj.printed.seconds).add(pj.printed.nanoseconds / 1000000, 'milliseconds');
      return printedTime.diff(createdTime, 'milliseconds');
    });
  
    if (printTimes.length === 0) return '';
  
    printTimes.sort((a, b) => a - b);
    const middle = Math.floor(printTimes.length / 2);
    const median = printTimes.length % 2 === 0
      ? (printTimes[middle - 1] + printTimes[middle]) / 2
      : printTimes[middle];
  
    const medianInSeconds = (median / 1000).toFixed(2);

    return `(⏱️${medianInSeconds}s)`;
  }
  

  private prepareInstructions(printInstructions: PrintInstruction[]) {
    for (const pi of printInstructions) {
      const t = pi.text;
      if (t) {
        if (t.style === "bold") {
          pi.tclass = "dos-bold";
        }
        if (t.center && (!t.layout || t.layout === "0,1,0")) {
          if (t.center.startsWith("----")) {
            pi.temp = "div";
          } else {
            pi.temp = "center";
          }
        } else {
          pi.temp = "row";
        }
      }
    }
  }
}
