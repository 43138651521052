import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {VenueService} from "../../../services/venue.service";
import {LoadingService} from "../../../services/loading.service";
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-edit-pin-dialog',
  templateUrl: './enter-guest-address-dialog.component.html',
  styleUrls: ['./enter-guest-address-dialog.component.css']
})
export class EnterGuestAddressDialogComponent implements OnInit {
  data = new UntypedFormControl('', [Validators.required, Validators.pattern("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$")]);
  title: string;
  label: string;
  placeholder: string;
  venueId?: number;

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<EnterGuestAddressDialogComponent>, private auth: AuthService) {
    this.title = data.title;
    this.placeholder = data.placeholder;
    this.label = data.label;
    this.venueId = data.venueId;
  }

  ngOnInit(): void {
  }

  send() {
    if (this.data.valid) {
      this.dialogRef.close(this.data);
      // const pin = this.pinCode.value;
      // this.venueService.updateStaffPin(this.identity, this.venueId, pin).then( r => {
      //   console.log("Updated role");
      // });
    }
  }

  isHeynowAdmin() {
    return AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }
}
