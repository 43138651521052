import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  error: string;
  form: UntypedFormGroup = new UntypedFormGroup({});
  sub: Subscription;
  emailSent: boolean;
  constructor(public authService: AuthService, public loadingService: LoadingService, private formBuilder: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.authService.disableSigninPipe();
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  public resetPassword() {
    this.sub = this.authService.resetPassword(this.form.controls.email?.value)
    .pipe(catchError(err => {
      console.log(err.message);
      if (err.message.includes('auth/user-not-found')) {
        this.error = 'Användaren kan inte hittas eller är inte verifierad. Kontrollera angiven e-mailadress eller be ansvarig Skicka inbjudan igen';
      } else if (err.message.includes('auth/missing-email')) {
        this.error = 'Du måste ange en e-postadress';
      } else {
        this.error = `Något har gått fel ${err.message}`;
      }
      return throwError(err);
    }), tap(_ => this.emailSent = true)).subscribe();
  }

}
