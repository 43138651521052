import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute} from "@angular/router";
import {VenueService} from "../../../services/venue.service";
import {ApiService} from "../../../services/api.service";
import {Lead} from "../../../models/user";

@Component({
  selector: 'app-landing-next',
  templateUrl: './landing-next.component.html',
  styleUrls: ['./landing-next.component.css']
})
export class LandingNextComponent implements OnInit {
  name = new UntypedFormControl('', [Validators.required]);
  userName = new UntypedFormControl('', [Validators.required]);
  phone = new UntypedFormControl('', [Validators.required]);
  sending = false;
  cards = [{name: "Lunch", id: "lunch"}, {name: "Takeaway", id: "takeaway"}, {name: "Al a carte", id: "alacarte"}];
  selectedTemplate: string;
  success = false;
  leadNotValid = false;
  lead: Lead;
  private leadKey: string;

  constructor(private snackBar: MatSnackBar, private route: ActivatedRoute, private api: ApiService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.leadKey = data.get("lead_key");
      this.api.fetchLead(this.leadKey).then(lead => {
        this.lead = lead;
      }).catch(error => {
        this.leadNotValid = true;
      });
    });
  }

  start() {
    if (this.name.valid && this.userName.valid && this.phone.valid && this.selectedTemplate != null) {
      this.snackBar.dismiss();
      this.sending = true;
      this.api.updateLead(this.name.value, this.userName.value, this.phone.value, this.selectedTemplate, this.leadKey).then(r => {
        console.log(r);
        this.success = true;
      }).finally(() => this.sending = false );
    } else {
      this.snackBar.open("Fyll i formulär och ange start mall", "Stäng", {duration: 5000});
      this.sending = false;
    }
  }

  select(c: any) {
    this.selectedTemplate = c.id;
  }
}
