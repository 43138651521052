import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from "@angular/material/table";
import { Prepaid } from "../../models/prepaid";
import { combineLatest, Subscription } from "rxjs";
import { first } from "rxjs/operators";
import { VenueService } from "../../services/venue.service";
import { ActivatedRoute } from "@angular/router";
import { MatSort } from "@angular/material/sort";
import { LocalFileUtils, MoneyUtils, TimeUtils } from "../../utils/utils";
import { FireService } from "../../services/fire.service";
import * as moment from "moment";
import {SelectionModel} from "@angular/cdk/collections";
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-prepaid-list',
  templateUrl: './prepaid-list.component.html',
  styleUrls: ['./prepaid-list.component.css']
})
export class PrepaidListComponent implements OnInit, OnDestroy {

  displayedColumns = ["select", "identity", "card_name", "card_type", "balance_cent", "name", "discount_program_name", "valid_until_date", "created", "updated"];
  dataSource: MatTableDataSource<Prepaid>;
  venueId: number;
  filter: string;
  selectedRowKey: string;
  cardName: string;
  hideEmpty = false;
  hideValid = false;
  isLoading = false;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selection = new SelectionModel<Prepaid>(true, []);

  private paramSub: Subscription;

  constructor(private venueService: VenueService, private route: ActivatedRoute, private fire: FireService,
              private snackBar: MatSnackBar, private auth: AuthService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.paramSub = combineLatest([
      this.route.paramMap,
      this.route.queryParamMap
    ]
    ).subscribe(res => {
      const param = res[0];
      const query = res[1];
      const q = query.get("q");
      this.venueId = Number(param.get("venue_id"));
      this.fetchPrepaids();
    });
  }

  ngOnDestroy() {
    this.paramSub.unsubscribe();
  }

  hasPerm(perm: string): boolean {
    return this.auth.hasPermission(this.venueId, perm);
  }

  private fetchPrepaids() {
    this.venueService.fetchPrepaids(this.venueId).then(r => {
      this.dataSource = new MatTableDataSource(r as Prepaid[]);
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = (data: Prepaid, filter: string) => {
        const { filterValue, hideEmpty, hideValid } = JSON.parse(filter);
        const textMatch = data.identity.toLowerCase().includes(filterValue) ||
          data.name?.toLowerCase().includes(filterValue) ||
          data.card_name?.toLowerCase().includes(filterValue) ||
          data.card_type?.toLowerCase().includes(filterValue) ||
          data.discount_program_name?.toLowerCase().includes(filterValue);

          const notEmpty = !hideEmpty || (hideEmpty && data.balance_cent > 0);
          const isValid = !data.valid_until_date || moment(data.valid_until_date).isAfter(moment());
          const notValid = hideValid ? !isValid : true;

        return textMatch && notEmpty && notValid;
      };
      this.applyFilter();
    });
  }

  cardFilterChanged() {
    this.applyFilter();
  }

  select(row: Prepaid) {
    this.selectedRowKey = this.selectedRowKey === row.key ? null : row.key;
    this.cardName = row.card_name;
  }

  applyFilter(filterValue: string = '') {
    filterValue = filterValue.trim().toLowerCase();
    this.dataSource.filter = JSON.stringify({filterValue, hideEmpty: this.hideEmpty, hideValid: this.hideValid});
    this.syncSelectionWithFilteredData();
  }

  clearFilters() {
    this.filter = "";
    this.dataSource.filter = "";
    this.selectedRowKey = null;
  }

  refresh() {
    this.fetchPrepaids();
  }

  async exportCSV() {
    const data = this.buildDataArrayWithHeader();
    const config = await this.fire.observeVenueConfig(Number(this.venueId)).pipe(first()).toPromise();
    const date = moment().format("YYYYMMDD-HHmm");
    const filename = `prepaid_saldo_${config.venue.name.replace(/\\s/g, "_")}_${date}.csv`;
    LocalFileUtils.createFileWithBOM(data, filename);
  }

  private buildDataArrayWithHeader() {
    const rows = [];
    let totalBalanceCent = 0;
    for (const row of this.dataSource.data) {
      totalBalanceCent += row.balance_cent;
      rows.push({
        identity: row.identity,
        card_name: row.card_name,
        card_type: row.card_type,
        balance_cent: MoneyUtils.fromCent(row.balance_cent),
        name: row.name,
        discount_program_name: row.discount_program_name,
        valid_until_date: row.valid_until_date ? moment(row.valid_until_date).format("YYYY-MM-DD HH:mm") : "",
        created: moment(row.created).format("YYYY-MM-DD HH:mm"),
        updated: moment(row.updated).format("YYYY-MM-DD HH:mm"),
      });
    }
    rows.push({
      identity: "Summa",
      card_name: "",
      card_type: "",
      balance_cent: MoneyUtils.fromCent(totalBalanceCent),
      name: "",
      discount_program_name: "",
      created: "",
      updated: "",
    });

    const header = [
      {col: 'identity', title: 'Identitet'},
      {col: 'card_name', title: 'Kortnamn'},
      {col: 'card_type', title: 'Typ'},
      {col: 'balance_cent', title: 'Saldo'},
      {col: 'name', title: 'Innehavare'},
      {col: 'discount_program_name', title: 'Rabatt'},
      {col: 'valid_until_date', title: 'Giltigt till'},
      {col: 'created', title: 'Skapad'},
      {col: 'updated', title: 'Uppdaterad'},
    ];
    return LocalFileUtils.compileDataIntoCSV(header, rows);
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.filteredData.forEach(row => {
        if (row.balance_cent !== 0) {
          this.selection.select(row);
        }
      });
    }
  }

  public areAnySelected(): boolean {
    return this.selection.selected.length > 0;
  }

  public getSelectedCount(): string {
    const count = this.selection.selected.length;
    return count > 0 ? `(${count})` : '';
  }

  isSelected(row) {
    const v = this.selection.selected.find(prepaid => prepaid.identity === row.identity);
    return v !== undefined;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.filter(row => row.balance_cent !== 0).length;
    return numSelected === numRows;
  }

  private syncSelectionWithFilteredData() {
    const filteredData = new Set(this.dataSource.filteredData);
    this.selection.selected.forEach(row => {
      if (!filteredData.has(row)) {
        this.selection.deselect(row);
      }
    });
  }

  voidPrepaidCards() {
    const selectedPrepaidCount = this.selection.selected.length;
    const dialogSettings = {
      title: "Töm Kort",
      message: `Vill du tömma ${selectedPrepaidCount} valda kort?`,
      cancelButton: "Avbryt",
      positiveButton: "Töm"
    };

    SimpleDialogComponent.observeSimpleDialog(this.dialog, dialogSettings).subscribe(r=> {
      if (r) {
        this.isLoading = true;
        const prepaid_keys = this.selection.selected.map(prepaid => prepaid.key);
        this.venueService.voidMultiPrepaidCards(this.venueId, prepaid_keys).then(() => {
          this.selection.clear();
          this.isLoading = false;
          this.snackBar.open(`Tömde ${prepaid_keys.length}st kort`, "", {duration: 5000});
          this.refresh();
        }, error => {
          this.isLoading = false;
          this.snackBar.open(error.error.message, "Stäng", {duration: 5000});
        });
      }
    });
  }

}
