import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {VenueService} from "../../../services/venue.service";
import {LoadingService} from "../../../services/loading.service";
import {AuthService} from "../../../services/auth.service";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";

@Component({
  selector: 'app-edit-pin-dialog',
  templateUrl: './edit-pin-dialog.component.html',
  styleUrls: ['./edit-pin-dialog.component.css']
})
export class EditPinDialogComponent implements OnInit {
  pinCode = new UntypedFormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]);
  identity: string;
  venueId?: number;

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<EditPinDialogComponent>, private auth: AuthService, private dialog: MatDialog) {
    this.identity = data.identity;
    this.venueId = data.venueId;
  }

  ngOnInit(): void {
  }

  update() {
    if (this.pinCode.valid) {
      const pin = this.pinCode.value;
      this.venueService.updateStaffPin(this.identity, this.venueId, pin).then( r => {
        console.log("Updated role");
        this.dialogRef.close(true);
      }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
    }
  }

  isHeynowAdmin() {
    return AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }
}
