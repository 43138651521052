import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Bong, BongItem, BongSummary } from '../models/kds-bong';
import {OrderNumberDisplayConfig, VenueConfig} from '../models/venue-config';
import { BongService } from '../services/bong.service';
import { FireService } from '../services/fire.service';
import {ICUtils, NodeUtils, TimeUtils} from '../utils/utils';
import {AuthService} from "../services/auth.service";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {ApiService} from "../services/api.service";
import { Filter, FilterLogic, bongFilterItemAccessor } from '../utils/filter-logic';
import { map, switchMap } from 'rxjs/operators';
import { Observable, Subscription, combineLatest, timer } from 'rxjs';

@Component({
  selector: 'app-orders-frame',
  templateUrl: './orders-frame.component.html',
  styleUrls: ['./orders-frame.component.css']
})
export class OrdersFrameComponent implements OnInit, OnDestroy {
  ACTIVE_ORDERS_VISIBLE_SECONDS_DEFAULT = 60 * 5; // Hide orders older than given seconds
  ACTIVE_ORDERS_VISIBLE_SECONDS = 60 * 5; // Hide orders older than given seconds
  UPDATE_INTERVAL = 1000 * 30; // Update interval in milliseconds (1000 milliseconds = 1 second)
  private sub: Subscription;
  private authSub: Subscription;
  public config: VenueConfig;
  private BUCKET = 'bucket';
  private MENU_LOGO = 'assets.menu_logo';

  venueId: number;
  identity: string;
  activeOrders: BongSummary[];
  inactiveOrders: BongSummary[];
  logoUrl: string;
  venueClosed: boolean;
  private timer$: Observable<number>;
  display?: OrderNumberDisplayConfig;
  leftTitle = "Tillagas";
  rightTitle = "Färdigt";

  private currentFilterDefinition?: string = undefined;
  private currentFilter?: Filter<BongItem, Bong> = undefined;

  constructor(private auth: AuthService, private route: ActivatedRoute, private bongService: BongService,
              private fire: FireService, private firebaseAuth: AngularFireAuth, private api: ApiService) { }

  ngOnInit(): void {
    TimeUtils.refreshAt(5, 0);
    this.timer$ = timer(0, this.UPDATE_INTERVAL);
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.identity = data.get("identity");
      this.checkAuthStatus();
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.authSub?.unsubscribe();
  }

  private startObserving() {
    this.sub?.unsubscribe();

    this.sub = this.fire.observeVenueConfig(Number(this.venueId)).pipe< BongSummary[]>(
      switchMap( (cfg: VenueConfig): Observable<BongSummary[]> => {
        this.config = cfg;
        this.venueClosed = cfg.inhouse?.opened == null;
        const bucket = NodeUtils.get(cfg, this.BUCKET);
        const menuLogo = NodeUtils.get(cfg, this.MENU_LOGO);
        this.logoUrl = bucket + '/' + menuLogo;
        this.leftTitle = cfg?.customer_display?.left_title || this.leftTitle;
        this.rightTitle = cfg?.customer_display?.right_title || this.rightTitle;
        this.display = cfg?.customer_display?.order_number_displays?.find(d => d.identity === this.identity);
        if (this.display && this.currentFilterDefinition !== this.display.kds_filter) {
          try {
            this.currentFilter = FilterLogic.parseFilter(this.display.kds_filter, bongFilterItemAccessor);
            this.currentFilterDefinition = this.display.kds_filter;
            console.log("filter definition", this.display?.kds_filter);
          } catch {
            console.log("Error parsing filter!", this.display.kds_filter);
          }
        }
        if (this.display?.bong_done_timeout_seconds) {
          this.ACTIVE_ORDERS_VISIBLE_SECONDS = this.display.bong_done_timeout_seconds;
        } else {
          this.ACTIVE_ORDERS_VISIBLE_SECONDS = this.ACTIVE_ORDERS_VISIBLE_SECONDS_DEFAULT;
        }

        return combineLatest([this.timer$, this.bongService.observeBongSummary(this.venueId, this.currentFilter, null)])
          .pipe(map(([_timer, res]: [number, BongSummary[]]) => res));
      })
    ).subscribe( (res) => {
      this.bongsUpdated(res);
    });
  }

  private bongsUpdated(res: BongSummary[]) {
    const filter = this.currentFilter;
    const rows = res;

    // console.log ("bongsUpdated", rows);
    // Clear previous state
    this.activeOrders = [];
    this.inactiveOrders = [];

    if (filter) {
      const activeFilter = filter.withFilterTerm({ type: "State", states: new Set(["active", "approved"]), include: true });
      const inactiveFilter =
        filter.withFilterTerm({ type: "State", states: new Set(["active", "approved"]), include: false })
          .withFilterTerm({ type: "OlderThanSeconds", seconds: Number(this.ACTIVE_ORDERS_VISIBLE_SECONDS), include: false });
      const nr = "17";
      for (const row of rows) {
        let activeBongsExist = false;
        let inactiveBongsExist = false;

        for (const bong of row.bongs) {
          const lookup = ICUtils.getICParentLookup(this.config);
          if (activeFilter.isMatch(bong.items, bong, lookup)) {
            activeBongsExist = true;
          }

          if (inactiveFilter.isMatch(bong.items, bong, lookup)) {
            inactiveBongsExist = true;
          }
        }
        // if(nr === row.bongs[0].order_numbers) {
        //   console.log("bongsUpdated", row.bongs[0].order_numbers, activeBongsExist, inactiveBongsExist, row.lastUpdated)
        //   console.log("bongsUpdated", row.bongs[0].items)
        // }
        if (activeBongsExist) {
          this.activeOrders.push(row);
        }

        if (inactiveBongsExist && !activeBongsExist) {
          this.inactiveOrders.push(row);
        }
      }

      // Sort after the filtering
      this.activeOrders.sort((a, b) => a.bongs[0].created.seconds - b.bongs[0].created.seconds);
      this.inactiveOrders.sort((a, b) => b.lastUpdated.seconds - a.lastUpdated.seconds);
    }
  }

  private checkAuthStatus() {
    this.authSub?.unsubscribe();
    this.authSub = this.firebaseAuth.authState.subscribe(user => {
      if (user) {
        //HACK set firebase user here (Auth service needs to be refactored)
        this.auth.firebaseUser = user;
        this.startObserving();
      } else {
        this.sub?.unsubscribe();
        if (this.identity != null) {
          this.loginWithIdentity();
        }
      }
    });
  }

  private loginWithIdentity() {
    console.log("sign in to customer display with identity", this.identity);
    this.api.signCustomerDisplay(this.venueId, this.identity).subscribe(cs => {
      console.log("Sign into customer display successfully, now sign into firebase with custom token");
      console.log(cs);
      this.auth.signInWithCustomToken(cs.token);
    }, err => {
      console.log("loginWithIdentity error", err);
    });
  }
}
