import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { PrepaidData } from 'src/app/models/prepaid-data';
import { VenueService } from 'src/app/services/venue.service';
import {MatDialog} from '@angular/material/dialog';
import { HackUtils } from 'src/app/utils/utils';
import { ReceiptDialogComponent } from '../../pos/receipt-view/receipt-dialog/receipt-dialog.component';
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import { EditPrepaidNoDialogComponent } from '../../dialogs/edit-prepaid-no-dialog/edit-prepaid-no-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-prepaid-details',
  templateUrl: './prepaid-details.component.html',
  styleUrls: ['./prepaid-details.component.css']
})
export class PrepaidDetailsComponent implements OnChanges {

  @Input() venueId: number;
  @Input() key: string;
  @Output() refresh: EventEmitter<void> = new EventEmitter();
  prepaidData: PrepaidData;
  showSpinner = true;
  displayedColumns: string[] = ['pay_date', 'amount'];
  receiptId: number;
  isSuperAdmin = false;

  constructor(private venueService: VenueService, public dialog: MatDialog, private snackBar: MatSnackBar, private auth: AuthService) { }

  ngOnChanges() {
    this.loadDetails();
  }

  loadDetails() {
    this.isSuperAdmin = AuthService.isSuperAdmin(this.auth.authStateSnapshot);
    this.showSpinner = true;
    this.prepaidData = undefined;
    this.venueService.fetchPrepaidData(this.venueId, this.key).then( r => {
      this.prepaidData = r;
    }).finally(() => { this.showSpinner = false; });
  }

  openReceiptDialog(row) {
    this.receiptId = row.receipt_id;
    const dialogRef = this.dialog.open(ReceiptDialogComponent, HackUtils.DLG({
      data: {
        receiptKey: this.receiptId,
        venueId: this.venueId,
        isSubComponent: true
      }
    }));
  }

  voidPrepaid() {
    const ds = {title: "Tömma presentkort?", message: "Är du säker på att du vill tömma presentkortet", cancelButton: "Cancel", positiveButton: "Töm"};
    SimpleDialogComponent.observeSimpleDialog(this.dialog, ds).subscribe( r => {
      if (r) {
        this.showSpinner = true;
        this.venueService.voidPrepaidCard(this.venueId, this.key).then( res => {
          this.prepaidData = res;
          this.refresh.emit();
        }).catch(e => SimpleDialogComponent.showErr(this.dialog, e))
        .finally(() => { this.showSpinner = false; });
      }
    });
  }

  hasPerm(perm: string): boolean {
    return this.auth.hasPermission(this.venueId, perm);
  }

  editPrepaidIdentity() {
    const dialogref = this.dialog.open(EditPrepaidNoDialogComponent, HackUtils.DLG({
      data: {
        dialogTitle: "Uppdatera kort #",
        prepaidData: this.prepaidData,
      },
      disableClose: true
    }));

    dialogref.afterClosed().subscribe( r => {
      if (r) {
        this.showSpinner = true;
        this.venueService.editPrepaidIdentity(this.venueId, this.key, this.prepaidData.prepaid.identity, r).then( res => {
          console.log("edit_prepaid_identity", res);
          this.prepaidData = res;
          this.refresh.emit();
          this.snackBar.open("Uppdaterat", "", {duration: 2000});
        }).catch(e => SimpleDialogComponent.showErr(this.dialog, e)).finally(() => { this.showSpinner = false; });
      } else {
        this.snackBar.open("Avbruten", "", {duration: 2000});
      }
    });
  }

}
