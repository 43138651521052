<div>
  <p>
    <mat-toolbar>
      <span>Kontrollenhet i molnet</span>
      <span class="spacer"></span>
      <button mat-button [matMenuTriggerFor]="menuAction">Actions</button>
      <mat-menu #menuAction="matMenu">
        <button mat-menu-item (click)="refresh()">Refresh</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="open()">Öppna CCU</button>
        <button mat-menu-item (click)="close()">Stäng CCU</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="changeCCU()">Ändra CCU</button>
        <button mat-menu-item (click)="registerNewCCU()">Skapa CCU</button>
      </mat-menu>
    </mat-toolbar>
  </p>
</div>

<mat-spinner style="position: absolute; top: calc(50% - 15px); left: calc(50% - 15px);" [diameter]="30" *ngIf="loading"></mat-spinner>

<div>
  <div *ngIf="idmStatus" class="open-panel" >
    <div class="panel-title">CCU Status</div>
    <div class="panel-body">
      <ngx-json-viewer  [json]="idmStatus"></ngx-json-viewer>
    </div>
  </div>
</div>
