import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Booking } from 'src/app/models/booking';
import { VenueService } from 'src/app/services/venue.service';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.css']
})
export class BookingDetailsComponent implements OnInit, OnDestroy {

  public showSpinner = true;
  public venueId: number;
  public bookingKey: string;
  public booking: Booking;

  private paramSub: Subscription;

  constructor(private venueService: VenueService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.paramSub = this.route.paramMap.subscribe(param => {
      this.venueId = Number(param.get("venue_id"));
      this.bookingKey = param.get("booking_id");
      if (this.bookingKey && this.bookingKey !== "0") {
        this.fetchData();
      }
    });
  }

  ngOnDestroy() {
    this.paramSub.unsubscribe();
  }

  fetchData() {
    this.booking = undefined;
    this.showSpinner = true;
    this.venueService.fetchBookingInfo(this.venueId, this.bookingKey).then(booking => {
      console.log("fetch_booking_info", booking);
      this.booking = booking;
      this.showSpinner = false;
    });
  }

  closeSidePanel() {
    this.router.navigateByUrl(`venue/${this.venueId}/booking-list/0`);
  }

}
