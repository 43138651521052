<mat-expansion-panel [expanded]="true" [disabled]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      CLIENT-RECORDS
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div class="container">
   
      <mat-form-field>
      <mat-label>Filter</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium">
      </mat-form-field>
      
      <table mat-table [dataSource]="dataSource" matSort>
        
        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
          <td mat-cell *matCellDef="let element"> {{element.created|date:'medium'}} </td>
        </ng-container>

        <ng-container matColumnDef="updated">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated </th>
          <td mat-cell *matCellDef="let element"> {{element.updated|date:'medium'}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>
        
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount (SEK)</th>
          <td mat-cell *matCellDef="let element"> {{element.amount/100}} </td>
        </ng-container>
        
        <ng-container matColumnDef="settlement_fee">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Settlement fee (SEK)</th>
          <td mat-cell *matCellDef="let element"> {{element.settlement_fee/100}} </td>
        </ng-container>

        <ng-container matColumnDef="commision">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Commision (SEK)</th>
          <td mat-cell *matCellDef="let element"> {{element.commision/100}} </td>
        </ng-container>

        <ng-container matColumnDef="fee_formula">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> fee formula </th>
          <td mat-cell *matCellDef="let element"> {{element.fee_formula}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

</mat-expansion-panel>