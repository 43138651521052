import firebase from "firebase/compat/app";
import * as moment from 'moment';

export interface Session {
  id: string;
  table: string;
  venue: string;
  venueId: number;
  active: boolean;
  menu_activity: string;
  created: firebase.firestore.Timestamp;
  updated: firebase.firestore.Timestamp;

  //Transient
  activity_cache: SessionMenuActivity[];
}

export interface SessionMenuActivity {
  action: string;
  user_id: number;
  chat_id: number;
  chair_id: number;
  anon_id: string;
  reg: string;
  updated: string;
  profile_pic: string;
  name: string;
  wants_attention: string;

  // Transient
  alias: string;
  updatedMoment: moment.Moment;
}

export function parseMenuActivity(actvity: string): SessionMenuActivity[] {
  return JSON.parse(actvity);
}

export function getMenuActivity(session: Session): SessionMenuActivity[] {
  if (session?.menu_activity) {
    const activity = parseMenuActivity(session.menu_activity);
    session.menu_activity = null;
    session.activity_cache = activity;
    return activity;
  }
  return session?.activity_cache;
}
