<div>
  <p>
    <mat-toolbar>
      <span>{{title}}</span>
      <span class="spacer"></span>
      <app-wiki-info-link title="Lär dig mer" section="support" path="admins"></app-wiki-info-link>
      <button mat-button (click)="addPerson()">
        <mat-icon>person_add</mat-icon> Lägg till
      </button>
    </mat-toolbar>
  </p>
</div>
<div style="padding: 16px;">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;max-width: 125px;font-size:12px;white-space: normal;word-wrap: break-word;"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> First name </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.first_name}} </td>
    </ng-container>

    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Last name </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.last_name}} </td>
    </ng-container>

    <ng-container matColumnDef="roles">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Roles </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.roles}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;">
        <button mat-button [matMenuTriggerFor]="menuReports">Edit <mat-icon>arrow_drop_down</mat-icon></button>
        <mat-menu #menuReports="matMenu">
          <button mat-menu-item (click)="editAdminUser(element)">Redigera profil</button>
          <button mat-menu-item (click)="editRoles(element)">Redigera roll</button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="resendInvite(element)">Skicka inbjudan igen</button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
