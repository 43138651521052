import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import Konva from "konva";
import Stage = Konva.Stage;
import Layer = Konva.Layer;
import {
  FLOORPLAN_SCENE_HEIGHT,
  FLOORPLAN_SCENE_WIDTH,
  FPData, FPLine,
  FPSectionData,
} from "../../../models/floorplan";
import {combineLatest, fromEvent, Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {FireService} from "../../../services/fire.service";
import {OrderService} from "../../../services/order.service";
import {VenueService} from "../../../services/venue.service";
import {PosStateService} from "../../../services/pos-state.service";
import {extractAliasPrefix, fitStageIntoParentContainer} from "../floorplan-common/floorplan-misc";
import {OldFloorplanConverter} from "../floorplan-common/old-floorplan-converter";
import * as _ from "lodash";
import {FPRenderLine, FPRenderSection, FPRenderTable} from "../../../models/floorplan-render-models";
import {FP2Line} from "../../../models/floorplan-editor-models";
import Vector2d = Konva.Vector2d;
import {TableService} from "../../../services/table.service";

@Component({
  selector: 'app-floorplan-v2',
  templateUrl: './floorplan-v2.component.html',
  styleUrls: ['./floorplan-v2.component.css']
})
export class FloorplanV2Component implements OnInit, OnDestroy, AfterViewInit {

  @Input() venueId: number;
  @ViewChild('container', { static: true })
  container: ElementRef<HTMLDivElement>;
  sections: FPSectionData[];
  currentSection: FPRenderSection;

  private sub: Subscription;
  private resizeSub: Subscription;
  private subInfo: Subscription;
  private subSection: Subscription;

  private stage: Stage;
  private layer: Layer;
  private baseData: FPData;
  private selectedTable: FPRenderTable;
  private showAlias?: boolean;

  constructor(private route: ActivatedRoute, private fire: FireService, private orderService: OrderService,
              private venueService: VenueService, private router: Router, private state: PosStateService, private tableService: TableService) { }

  ngOnInit(): void {
    this.subInfo = this.tableService.init(this.venueId);
  }

  ngAfterViewInit(): void {
    this.setupStage();

    setTimeout(() => { this.fitAndUpdateUI(); });
    if (!this.resizeSub) {
      this.resizeSub?.unsubscribe();
      this.resizeSub = fromEvent(window, 'resize').subscribe( evt => {
        this.fitAndUpdateUI();
      });
    }

    this.beginObserve();
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.resizeSub?.unsubscribe();
    this.subInfo?.unsubscribe();
    this.subSection?.unsubscribe();
  }

  private beginObserve() {
    this.sub?.unsubscribe();

    this.sub = combineLatest([
        this.fire.observeVenueConfig(this.venueId),
        this.fire.observeFSFloorplan(this.venueId),
      ]
    ).subscribe(res => {
      const config = res[0];
      const fsfp = res[1];

      let floorplan = null;
      if (fsfp.data_version === 2) {
        floorplan = JSON.parse(fsfp.data) as FPData;
      } else if (fsfp?.data != null){
        floorplan = OldFloorplanConverter.convertOldFormatDataToNew(fsfp.data);
      }

      if (floorplan == null) {
        //this.initWithEmpty();
      } else {
        this.load(floorplan);
      }
      }
    );
  }


  private setupStage() {
    this.stage = new Konva.Stage({
      container: this.container.nativeElement,
      width: FLOORPLAN_SCENE_WIDTH,
      height: FLOORPLAN_SCENE_HEIGHT
    });
    this.layer = new Konva.Layer();
    this.stage.add(this.layer);

    // eslint-disable-next-line one-var
    this.stage.on('mousedown touchstart', (e) => {
      const vec = this.stage.getRelativePointerPosition();
      console.log("Mousedown", vec);

      const table = this.findTappedTable(vec);
      console.log("Table", table);
      this.selectTable(table);
    });
  }

  private load(data: FPData) {
    console.log("Loading data", data);
    this.baseData = data;
    if (data.sections.length > 1) {
      this.sections = data.sections;
    } else {
      this.sections = null;
    }

    const first = this.baseData.sections[0];
    this.setupSection(first);
  }

  private setupSection(section: FPSectionData) {
    this.showAlias = this.shouldShowAlias();
    this.removeShapes();
    this.currentSection = new FPRenderSection(section.id, section.name);
    for (const table of section.tables) {
      const aliasPrefix = extractAliasPrefix(table.name, table.alias);
      const ti = this.tableService?.tableInfos?.get(table.name);
      const fpt = new FPRenderTable(table.name, table.alias, aliasPrefix, table.area, table.type, table, this.showAlias, null, ti);
      this.currentSection.objects.push(fpt);
      fpt.addToLayer(this.layer);
    }
    for (const line of section.lines) {
      this.addCompleteLine(line);
    }
    // return this.selectedSection;
    this.layer.draw();

    this.subSection?.unsubscribe();
    this.subSection = this.tableService.tableInfoSubject.subscribe( ti => {
      const target = this.currentSection?.objects?.find(t => t.name === ti.name);
      if (target) {
        console.log("Got new ti info", ti);
        const showTimerRelated = target.updateFromTableInfo(ti);
        if (showTimerRelated) {
          this.tableService.updateTableWithTimer(ti.name);
        }
      }
    });
  }

  private addCompleteLine(fpl: FPLine) {
    const fp2l = new FPRenderLine("wall", null, fpl.vertices);
    fp2l.addToLayer(this.layer);
    for (const vec of fpl.vertices) {
      //fp2l.addPoint(vec);
    }
    this.currentSection.lines.push(fp2l);
  }

  private removeShapes() {
    const shapes = this.stage.find(node => _.includes(["movable", "vertex", "line", "tail"], node.name()));
    for (const shape of shapes) {
      shape.remove();
    }
  }

  selectSection(sec: FPSectionData) {
    console.log("Selected section", sec.name);
    this.setupSection(sec);
  }

  private findTappedTable(vec: Vector2d): FPRenderTable {
    const pairs = this.currentSection.objects.map( table => {
      const center = table.center;
      const dx = center.x - vec.x;
      const dy = center.y - vec.y;
      const dist = Math.sqrt(dx * dx + dy * dy);
      return {table, dist};
    });
    if (pairs.length === 0) { return; }
    pairs.sort((a, b) => a.dist - b.dist);
    return pairs[0].table;
  }

  private selectTable(table: FPRenderTable) {
    console.log("selected:", table);
    this.deSelectAllTables();
    this.selectedTable = table;
    table.updateSelection(true);
    //this.locationCode = null;
    this.router.navigateByUrl(`venue/${this.venueId}/pos/summary/${table.name}`);
  }

  private deSelectAllTables() {
    for (const table of this.currentSection.objects) {
      table.updateSelection(false);
    }
  }

  private fitAndUpdateUI() {
    fitStageIntoParentContainer(this.container, this.stage);
    const showAlias = this.shouldShowAlias();
    if (this.currentSection && this.showAlias !== showAlias) {
      console.log("Redraw alias change");
      this.showAlias = showAlias;
      for (const obj of this.currentSection.objects) {
        obj.showAlias = this.showAlias;
        obj.textUpdated();
      }
    }
    //console.log(this.stage.scale());
  }

  private shouldShowAlias() {
    return this.stage.scaleX() < 0.58;
  }
}
