<div class="container">

<!--  <div>-->
<!--    <mat-slide-toggle [(ngModel)]="hideOffline" color="primary" (ngModelChange)="toggleOffline()">Hide offline</mat-slide-toggle>-->
<!--    <mat-slide-toggle style="margin-left: 20px" [(ngModel)]="hideOld" color="primary" (ngModelChange)="toggleOffline()">Hide older than 24h</mat-slide-toggle>-->
<!--  </div>-->

  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="terminalName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element"><button mat-flat-button (click)="nameClick(element)">{{element.terminalName}}</button></td>
<!--      <td mat-cell *matCellDef="let element"><mat-icon color="{{element.iconColor}}" style="padding-right: 10px;">{{element.icon}}</mat-icon>{{element.terminalName}}</td>-->
    </ng-container>

    <ng-container matColumnDef="amountCent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cent </th>
      <td mat-cell *matCellDef="let element"> {{element.amountCent}} </td>
    </ng-container>

    <ng-container matColumnDef="bamboraReference">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> bamboraRef </th>
      <td mat-cell *matCellDef="let element"> {{element.bamboraReference}} </td>
    </ng-container>

    <ng-container matColumnDef="externalReference">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ext. </th>
      <td mat-cell *matCellDef="let element"> {{element.externalReference}} </td>
    </ng-container>

    <ng-container matColumnDef="internalReference">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Intern. </th>
      <td mat-cell *matCellDef="let element"> {{element.internalReference}} </td>
    </ng-container>

    <ng-container matColumnDef="receiptKey">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> receipt </th>
      <td mat-cell *matCellDef="let element"> {{element.receiptKey}} </td>
    </ng-container>

    <ng-container matColumnDef="ref">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ref </th>
      <td mat-cell *matCellDef="let element"> {{element.ref}} </td>
    </ng-container>

    <ng-container matColumnDef="serialNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> #SN </th>
      <td mat-cell *matCellDef="let element"> {{element.serialNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
      <td mat-cell *matCellDef="let element"> {{element.status}} </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
      <td mat-cell *matCellDef="let element"> {{element.type}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
      <td mat-cell *matCellDef="let element"> {{element.date.toDate() |date:'medium'}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
