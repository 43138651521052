import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {SettingsComponent} from "../components/settings/settings/settings.component";
import {SimpleDialogComponent} from "../components/simple-dialog/simple-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class SettingsDeactivateGuard implements CanDeactivate<SettingsComponent> {
  constructor(private dialog: MatDialog) {}
  canDeactivate(
    component: SettingsComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (component.hasChanges()) {
      return SimpleDialogComponent.observeSimpleDialog(this.dialog, { title: "Osparade ändringar", message: "Du har ändringar som inte är sparade", positiveButton: "Släng ändringar", cancelButton: "Avbryt" });
    }
    return true;
  }
}
