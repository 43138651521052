<div>
  <p>
    <mat-toolbar>
      <span>Softpay Terminaler</span>
      <span class="spacer"></span>
      <app-wiki-info-link title="Lär dig mer" section="support" path="softpay"></app-wiki-info-link>
      <button mat-button [matMenuTriggerFor]="devices"><mat-icon>add</mat-icon> Lägg till</button>
      <mat-menu #devices="matMenu">
        <div *ngFor="let device of nonTerminalDevices">
          <button mat-menu-item (click)="createSoftpayTerminal(device.key)" matTooltip="{{device.email}}">{{device.name}}</button>
        </div>
      </mat-menu>
    </mat-toolbar>
  </p>
</div>

<div class="container master-detail">
  <div class="master">
    <div>
      <table class="table-cells" mat-table [dataSource]="dataSource" matSort matSortDisableClear="true">
        <ng-container matColumnDef="user_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Namn </th>
          <td mat-cell *matCellDef="let terminal">{{terminal.user_name}}</td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let terminal">{{terminal.state}}</td>
        </ng-container>

        <ng-container matColumnDef="updated">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Uppdaterad </th>
          <td mat-cell *matCellDef="let terminal">{{terminal.updated | date:"medium"}}</td>
        </ng-container>

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td mat-cell *matCellDef="let terminal">{{terminal.id}}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Enhet </th>
          <td mat-cell *matCellDef="let terminal">{{terminal.device.email}}</td>
        </ng-container>

        <ng-container matColumnDef="device_user_email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Enhet </th>
          <td mat-cell *matCellDef="let terminal">{{terminal.device.email}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; let i=index columns: displayedColumns;" [ngClass]="{'grey-row': i % 2 == 1}" (click)="openDetails(row.key)" class="mat-table-clicky"></tr>
      </table>

      <div class="no-data" *ngIf="noData">Finns inga terminaler att lista</div>

    </div>
  </div>
</div>
