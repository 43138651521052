import {Component, OnDestroy, OnInit} from '@angular/core';
import {VenueService} from "../../../services/venue.service";
import {ActivatedRoute} from "@angular/router";
import {PosStateService} from "../../../services/pos-state.service";
import {combineLatest, Subscription} from "rxjs";
import {Order, OrderItem, OrderSummary, OrderSummaryRow} from "../../../models/order";
import {OrderService} from "../../../services/order.service";
import {filter, map} from "rxjs/operators";
import {Session, SessionMenuActivity} from "../../../models/Session";
import {FireService} from "../../../services/fire.service";
import {MenuItemDialogComponent} from "../menu-item-dialog/menu-item-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-new-order',
  templateUrl: './new-order.component.html',
  styleUrls: ['./new-order.component.css']
})
export class NewOrderComponent implements OnInit, OnDestroy {

  table: string;
  venueId: number;
  summary: OrderSummary;
  selectedUser: any;

  private sub: Subscription;
  private selectedUserSub: Subscription;
  private subParams: Subscription;
  private items: OrderItem[];
  private session: Session;
  selectedItem: string;
  selectedItemId: string;
  affectedItem: OrderItem;

  constructor(private venueService: VenueService, private route: ActivatedRoute, private state: PosStateService,
              private orderService: OrderService, private fire: FireService, private dialog: MatDialog) { }

  ngOnInit(): void {
    const data = this.route.snapshot.paramMap;
    this.venueId = Number(data.get("venue_id"));
    this.table = data.get("table");
    this.resetSelectedItem();
    this.beginObservingTable();

    // this.subParams = this.route.paramMap.subscribe(data => {
    //   if (this.state.correctMode(data, "new")) {
    //     console.log("NewOrderComponent new params:", data);
    //     this.venueId = Number(data.get("venue_id"));
    //     this.table = data.get("table");
    //     this.beginObservingTable();
    //   }
    // });
  }

  ngOnDestroy(): void {
    console.log("NewOrderComponent destroyed:");
    this.sub?.unsubscribe();
    this.selectedUserSub?.unsubscribe();
    this.subParams?.unsubscribe();
  }

  private beginObservingTable() {
    console.log("beginObservingTable");
    const order = this.state.getOrderAtTable(this.table, true);
    if (order == null) {
      // order = this.state.createNewOrder(this.table);
      return;
    }

    this.sub?.unsubscribe();
    this.sub = combineLatest([
        order.observeItemsChanges(),
        this.fire.observeActiveSession(this.venueId, this.table),
        this.fire.observeVenueConfig(this.venueId),
      ]
    ).subscribe( res => {
      const itemChange = res[0];
      const sessions = res[1];
      const config = res[2];
      this.items = itemChange.items;
      this.affectedItem = itemChange.added;
      console.log("Observed items", this.items);
      console.log("Affected item", this.affectedItem);
      if (this.selectedItemId !== this.affectedItem?.id) {
        this.resetSelectedItem();
      }
      const session = this.setupSessionAndSelectedUser(sessions);
      this.session = session;
      this.summary = this.orderService.summarizeOrders([order as unknown as Order], this.table, session, true, config);
      setTimeout(() => this.afterRenderSummary(), 100);
    });

    this.selectedUserSub?.unsubscribe();
    this.selectedUserSub = this.state.observeSelectedUserAtTable(this.table).subscribe( user => {
      this.selectedUser = user;
    });
  }

  userSelected(u: SessionMenuActivity) {
    this.state.selectUserAtTable(this.table, u);
  }

  private setupSessionAndSelectedUser(sessions: Session[]): Session {
    let session;
    if (sessions.length === 0) {
      // @ts-ignore
      if (this.session?.temporary && this.session?.table === this.table){
        session = this.session;
      } else {
        console.log("No session found");
        session = this.state.createLocalTemporarySession(this.table);
      }
      this.state.selectDefaultUser(this.table, session);
      //session = null;
    } else {
      session = sessions[0];
      session = this.state.setupLocalTemporarySession(this.table, session);
      this.state.selectDefaultUser(this.table, session);
    }
    return session;
  }

  addNewUser() {
    this.state.addNewUser(this.table, this.session);
  }

  itemClicked(event: MouseEvent, row: OrderSummaryRow) {
    console.log("Item clicked:", row);
    const fi = row.item.groupedItems.orderItems[0];
    this.selectedItemId = fi.id;
    this.selectedItem = row.item.groupedItems.listItemUniqueKey;
    event.preventDefault();
    event.stopPropagation();
    this.state.editableSelected(fi, row.item.groupedItems);
    return false;
  }

  editAddItem(event: MouseEvent, row: OrderSummaryRow) {
    const firstItem = row.item.groupedItems.orderItems[0];
    this.state.addItemCopy(firstItem);
    event.preventDefault(); event.stopPropagation(); return false;
  }

  editSubItem(event: MouseEvent, row: OrderSummaryRow) {
    const items = row.item.groupedItems.orderItems;
    const lastItem = items[items.length - 1];
    this.state.removeItem(lastItem.id);
    if (items.length <= 1) {
      this.resetSelectedItem();
    }
    event.preventDefault(); event.stopPropagation(); return false;
  }

  editItem(event: MouseEvent, row: OrderSummaryRow) {
    const firstItem = row.item.groupedItems.orderItems[0];
    MenuItemDialogComponent.openDialog(this.venueId, firstItem.item, this.dialog, firstItem);
    this.resetSelectedItem();
    event.preventDefault(); event.stopPropagation(); return false;
  }

  private afterRenderSummary() {
    const affectedItem = document.getElementById("affectedItem");
    affectedItem?.scrollIntoView({block: "center"});
  }

  clickOutside() {
    this.resetSelectedItem();
  }

  resetSelectedItem() {
    this.selectedItem = null;
    this.selectedItemId = null;
    this.state.editableSelected(undefined);
  }
}
