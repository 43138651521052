<h3 mat-dialog-title class="item-dlg-title">{{menuItem.pos_name != null ? menuItem.pos_name : menuItem.name}}</h3>

<div mat-dialog-content style="position: relative;" class="item-dialog mat-typography">

  <div class="top-row">
    <div style="width: 100%; box-sizing: border-box; margin-right: 16px;" *ngIf="data.isOpenProduct">
      <mat-form-field appearance="outline" class="comment">
        <mat-label>Pris</mat-label>
        <input matInput type="number" [(ngModel)]="price" tabindex="1">
      </mat-form-field>
    </div>
    <div style="width: 100%;">
      <mat-form-field appearance="outline" class="comment">
        <mat-label>Kommentar</mat-label>
        <input matInput type="text" [(ngModel)]="comment" tabindex="2">
      </mat-form-field>
      <mat-form-field appearance="outline" class="comment">
        <mat-label>Antal</mat-label>
        <input matInput type="text" [(ngModel)]="itemCount" tabindex="2">
      </mat-form-field>
    </div>
  </div>
  <div class="option-list">
    <div class="dlg-item" *ngFor="let o of options" (click)="clickOption(o)" [ngStyle]="getOptionsStyle(o)">
      <div class="dlg-btn-name">
        {{o.opt.value}}
      </div>
      <div class="dlg-price">
        {{o.opt.formatted}}
      </div>
    </div>
  </div>
  <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="working"></mat-spinner>
</div>
<mat-dialog-actions>
  <button [disabled]="working" mat-button mat-dialog-close>Stäng</button>
  <button *ngIf="data?.editItem == null" [disabled]="working" mat-button (click)="addItem()" style="margin-left: auto;">Lägg till</button>
  <button *ngIf="data?.editItem != null" [disabled]="working" mat-button (click)="saveItem()" style="margin-left: auto;">Spara</button>
</mat-dialog-actions>
