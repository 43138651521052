import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Order, OrderSummary, Receipt} from "../../models/order";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, MatSortable} from "@angular/material/sort";
import {Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {FireService} from "../../services/fire.service";
import {OrderService} from "../../services/order.service";
import {AuthService} from 'src/app/services/auth.service';
import {AuthState} from "../../models/signin";

@Component({
  selector: 'app-live-receipt-list',
  templateUrl: './live-receipt-list.component.html',
  styleUrls: ['./live-receipt-list.component.css']
})
export class LiveReceiptListComponent implements OnInit, OnDestroy {
  @Input() table: string;
  @Output() selected = new EventEmitter();
  private receipts: Receipt[];
  hideOffline = false;
  hideOld = true;
  venueId: number;
  selectedOrder: Order;
  summary: OrderSummary;
  private authState: AuthState;
  selectedRow: Receipt | null = null;

  displayedColumns = ["paid_date", "table", "total_amount", "total_tip", "method", "type", "explorer"];
  dataSource: MatTableDataSource<Receipt>;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  private orderObs: Subscription;

  constructor(private route: ActivatedRoute, private fire: FireService, private orderService: OrderService, public authService: AuthService) {
  }

  ngOnInit(): void {
    this.sort.sort(({id: 'paid_date', start: 'desc'}) as MatSortable);
    this.authService.currentAuthState().subscribe(auth => {
      this.authState = auth;
    });
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.beginObserve();
    });
  }

  ngOnDestroy(): void {
    if (this.orderObs) {
      this.orderObs.unsubscribe();
    }
  }

  isSuperAdmin(): boolean {
    return AuthService.isSuperAdmin(this.authState);
  }

  private beginObserve() {
    this.orderObs = this.fire.observePaidReceipts(this.venueId).subscribe(receipts => {
      this.receipts = receipts.map(r => {
        r.total_amount = r.is_refund ? -r.total_amount : r.total_amount;
        return r;
      });
      this.refresh();
    });
  }

  private refresh() {
    this.dataSource = new MatTableDataSource(this.receipts as Receipt[]);
    this.dataSource.sort = this.sort;
  }

  select(receipt: Receipt) {
    this.selectedRow = receipt; 
    console.log(receipt);
    this.selected.emit(receipt);
  }
  
  trackById(item: Receipt): number{
    return item.order_id;
  }
}
