<div>
  <mat-toolbar>
    <span>Myndighetssida</span>
  </mat-toolbar>
</div>
<div class="container" [ngStyle]="{'max-height': isLoggedIn ? 'unset' : 'calc(100% - 98px)'}">
  <div style="max-width: 900px;">
    <div class="section">
      <div class="title">Kassaregistersystem <span class="subtitle">CATO Kassaregister SE v3.0</span></div>
      <p>
        På restaurang <b>{{skvData?.venue.name}}</b> används kassaregistersystemet CATO kassaregister SE v3.0, som
        levereras och driftas av <b>Heynow AB</b>. (följ länk nedan för utförlig beskrivning av systemet)
      </p>
      <p>Heynows kassaregistersystem består av:</p>
      <ul>
        <li>ett kassaregisterprogram som körs på centrala servrar</li>
        <li>en eller fler tunna klienter som används lokalt av personalen</li>
        <li>ett gästgränssitt där gäster kan beställa och betala</li>
        <li>en molnbaserad kontrollenhet som levereras av tredje part (se nedan)</li>
      </ul>
      <div class="link" (click)="toggleParagraph()">
        <span class="spacer"></span>Beskrivning av Kassaregistersystem - CATO Kassaregister SE v3.0
      </div>
      <div *ngIf="isVisible">
        <p>På den här restaurangen används ett kassaregistersystem som utvecklas och driftas av Heynow AB.
          Kassasystemet är ett så kallat molnbaserat system, vilket innebär att restaurangens kassaregister och
          Heynows centrala programvara återfinns på centrala servrar utanför restaurangens lokaler. Inom
          restaurangens lokaler används registreringsenheter, tillika tunna klienter i form av läsplattor,
          mobiltelefoner och betalterminaler för att kommunicera med ovan nämnda centrala servrar via
          internet. Mer specifikt är restaurangens arbetsverktyg för att följa sina kassarutiner tillika följa lagen
          om kassaregister en applikation utvecklad i android av Heynow med vars hjälp man fjärruppdaterar
          restaurangens kassaregister.
        </p>
        <p>Varje restaurang har ett kassaregister knutet exklusivt till restaurangens fysiska adress på ovan
          nämnda centrala servrar samt en eller flera tunna klienter i lokalen som uppdaterar samma
          kassaregister. Varje restaurang har en kontrollenhet kopplad till sitt kassaregister där 100% av
          restaurangens transaktioner, utan undantag registreras. Även denna kontrollenhet är molnbaserad
          och återfinns på en server utanför restaurangens lokaler. Relevanta journaler från kontrollenheten
          kan med fördel exporteras från vilken som helst av de tunna klienter som används inom restaurangen
          och kommer att vara identisk oberoende av val av klient.
        </p>
        <p>Heynows kassasystem öppnar för möjligheten för gästintegration, vilket innebär att restaurangens
          gäster ges möjlighet att beställa och betala via sina mobiltelefoner, om restaurangen har valt denna
          tjänst. Detta innebär i så fall att gästen genom att scanna en qr-kod erbjuds begränsad integration
          med restaurangens kassaregister på samma sätt som restaurangpersonalens
          registreringsenheter/tunna klienter.
        </p>
        <p>En restaurangnota kan uppdateras av en restauranggäst, av restaurangens personal eller genom en
          kombination av de två innan den avslutas i en betaltransaktion. Dessa notor kan vidare innan
          betaltransaktionen äger rum vara såväl öppna som stängda
        </p>
        <p>Betaltransaktionen kan genomföras av restaurangens personal på en betalterminal och benämns då
          (”instore”). En betaltransaktion kan även genomföras i betalinstitutets checkout på gästens
          mobiltelefon och benämns då (”online”). Notor kan även splittas och fördelas mellan flera gäster och
          därvid genomföras som en kombination av instore- och online-betalningar. Det görs i Heynows
          system ingen skillnad på löpnummer på kassakvitton beroende på hur order och betalning har
          genomförts. Löpnummer på kassakvitton korresponderar alltid med motsvarande löpnummer i
          kassaregistret gällande den aktuella transaktionen.
        </p>
        <p>Gäster kan erbjudas kassakvitto som är fysiskt utskrivna på papper eller digitala. Restaurangen har
          egna avtal med samarbetande betalinstitut som sköter transferering av betalmedel från
          kortbetalningar, swish eller dylikt. Heynow är inte involverad i hanteringen av restaurangens
          betalmedel
        </p>
        <p>En mindre andel av Heynows kunder tillhandahåller kontanthantering och tillåter därmed kontant
          betalning av sina gäster. I dessa fall konfigureras restaurangens kassaregister för detta ändamål. Det
          åligger emellertid restaurangen själv att dokumentera och sköta sina rutiner för kontanthantering.
        </p>
        <p>Varje installation av Heynows kassasystem hos ny restaurangkund görs av Heynows personal på plats
          i restauranglokalen varvid detaljkunskap om varje kunds specifika konfiguration och rutiner som regel
          är välkända av Heynow.
        </p>
        <mat-divider></mat-divider>
      </div>
    </div>
    <div class="section">
      <div class="title">Instruktioner</div>
      <ul>
        <li>Exportera journalminne från valfri tunn klient:
          <ul>
            <li>Journalminnet för kassaregistersystemet kan hämtas från valfri tunn klient. Välj Klockan > Rapporter >
              Journalminne och ange där email som journalen skall skickas till.</li>
          </ul>
        </li>
        <li>Exportera Z-dagrapport: kan ej exporteras från tunn klient utan genereras automatiskt och skickas dagligen till
          av kunden definierade e-mailadresser.</li>
        <li>Exportera X-dagrapport: X-rapporten för kassaregistersystemet kan hämtas från valfri tunn klient. 
          Välj Klockan > Rapporter > X-rapport</li>
        <!-- <li>Kassakvittots innehåll: <img src="" alt="bild med förklaring"></li> -->
        <li>Löpnummer på kassakvitton och kassaregister: varje restaurang använder sig av en stigande
          löpnummerserie för samtliga registreringsenheter/tunna klienter samt onlinebetalningar av
          gäster. Löpnummer på kassakvitto korresponderar med transaktioner i kassaregister</li>
      </ul>
    </div>
    <div class="section">
      <div class="title">Datapunkter som rör Heynow AB</div>
      <ul>
        <li>Heynow AB</li>
        <li>559103-2189</li>
        <li>Adress: c/o World Trade Center, Mässans gata 10, 412 51 Göteborg</li>
        <li>Telefon: 010-214 60 77</li>
        <li>E-mail: info@heynow.ai</li>
        <li>Utvecklingsteam: samma adress och kontakt som ovan</li>
        <li>Supportteam: samma adress och kontakt som ovan</li>
      </ul>
    </div>
    <div class="section">
      <div class="title">Datapunkter som rör {{skvData?.venue.name}}</div>
      <ul>
        <li>Bolagsnamn: {{skvData?.venue.full_name}}</li>
        <li>Organisationsnummer: {{skvData?.ccu.org_number}}</li>
        <li>Venue namn: {{skvData?.venue.name}}</li>
        <li>Venue adress: {{skvData?.ccu.street}}, {{skvData?.ccu.postal_code}} {{skvData?.ccu.city}}</li>
        <!-- <li>Venue telefon: ---</li> -->
        <li>Venue E-mail: {{skvData?.ccu.mail_to.join(', ')}}</li>
        <!-- <li>Namn kassaregistersystem i SKVs självdeklaration: ---</li> -->
        <!-- <li>Namn leverantör server för kassaregister: ---</li> -->
        <!-- <li>Fysisk adress server för kassaregister: ---</li> -->
        <!-- <li>Namn leverantör kontrollenhet: ---</li> -->
        <!-- <li>Fysisk adress server för kontrollenhet: ---</li> -->
        <li>Namn/nummer kontrollenhet/-er: {{skvData?.ccu.ccu}}</li>
        <li>Antal tunna klienter med kassaapplikation konfigurerade av Heynow: {{skvData?.clients.length}}</li>
        <!-- <li>Funktionalitet för gästintegration med online-betalning: ---</li> -->
        <!-- <li>Antal fasta och bordsspecifika qr-koder för gästintegration: ---</li> -->
        <!-- <li>Konfigurering i kassaregistersystem för kontanthantering: ---</li> -->
        <!-- <li>Ansvarigt betalinstitut: ---</li> -->
        <li>Antal av Heynow integrerade betalterminaler: {{skvData?.terminals.length}}</li>
      </ul>
    </div>
    <div class="section" style="margin-top: 32px;">
      <span class="title">Schematisk bild/flödesschema heynowsystem på aktuell restaurang</span>
      <div style="overflow-x: scroll;" #container></div>
    </div>
  </div>
</div>