<div>
  <p>
    <mat-toolbar>
      <span>Invoices</span>
      <span class="spacer"></span>
      <button mat-icon-button (click)="createInvoices()">
        <mat-icon>person_add</mat-icon>
      </button>
    </mat-toolbar>
  </p>
</div>
<div style="padding: 16px;">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;max-width: 125px;font-size:12px;white-space: normal;word-wrap: break-word;"> {{element.created | date:"medium"}} </td>
    </ng-container>

    <ng-container matColumnDef="updated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.updated | date:"medium"}} </td>
    </ng-container>

    <ng-container matColumnDef="exported">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Exported </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.exported | date:"medium"}} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.status}} </td>
    </ng-container>

    <ng-container matColumnDef="is_credit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Credit </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.credit}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>