<div class="detail-container">
  <div style="position: relative;">
    <div *ngIf="prepaidData">
      <mat-card>
        <div *ngIf="prepaidData?.prepaid">
          <div class="padding"><span class="bold">Kort #: </span>{{prepaidData.prepaid.identity}}</div>
        </div>
        <div *ngIf="prepaidData?.user">
          <div class="padding"><span class="bold">Namn: </span>{{prepaidData.user.name}} {{prepaidData.user.last_name}}</div>
          <div class="padding"><span class="bold">Email: </span> {{prepaidData.user.email}}</div>
        </div>
        <div *ngIf="prepaidData?.chat">
          <div class="padding"><span class="bold">Messenger: </span><span>{{prepaidData.chat.is_messenger ? "Ja" : "Nej"}}</span></div>
        </div>
        <div *ngIf="prepaidData?.prepaid">
          <div class="padding"><span class="bold">Tillgängligt belopp: </span>{{prepaidData.prepaid.balance_cent | moneySuff: 'cent': 'kr'}}</div>
        </div>
        <div class="action-buttons" style="padding-top: 16px;">
          <button mat-raised-button (click)="editPrepaidIdentity()" *ngIf="isSuperAdmin">Ändra kortnummer <mat-icon style="transform: scale(0.75);">admin_panel_settings</mat-icon></button>
          <button mat-raised-button
            (click)="voidPrepaid()"
            [disabled]="!hasPerm('admins') || prepaidData.prepaid.balance_cent === 0"
            matTooltip="Du saknar behörighet"
            matTooltipDisabled="{{hasPerm('admins')}}"
            aria-label="Töm kort">
            Töm kort
        </button>
        </div>
      </mat-card>
      </div>

    <mat-card *ngIf="prepaidData?.receipts.length > 0" class="receipt-list">
      <div class="bold" style="text-align: center;">Historik</div>
      <table mat-table [dataSource]="prepaidData.receipts" class="clicktable">
        <ng-container matColumnDef="pay_date">
          <th mat-header-cell *matHeaderCellDef> Datum</th>
          <td mat-cell *matCellDef="let receipt"> {{ receipt.pay_date | date:"medium" }} </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef> Belopp</th>
          <td mat-cell *matCellDef="let receipt"> {{ receipt.tx_amount }} kr</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="openReceiptDialog(row)" [class.selected-row]="receiptId === row.receipt_id"></tr>
      </table>
    </mat-card>
  </div>
  <mat-card *ngIf="showSpinner" style="min-height: 50px;">
    <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="showSpinner"></mat-spinner>
  </mat-card>
</div>
