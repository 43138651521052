import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcDate'
})
export class UtcDatePipe implements PipeTransform {

  transform(value: string, format: string = 'short', locale: string = 'sv-SE'): string {
    if (!value) { return ''; }
    const utcDate = new Date(value + 'Z');
    const options: Intl.DateTimeFormatOptions = this.getFormatOptions(format);
    return new Intl.DateTimeFormat(locale, options).format(utcDate);
  }

  private getFormatOptions(format: string): Intl.DateTimeFormatOptions {
    let options: Intl.DateTimeFormatOptions;

    switch (format) {
      case 'short':
        options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', timeZone: 'Europe/Stockholm' };
        break;
      case 'medium':
        options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'Europe/Stockholm' }
        break;
      case 'long':
        options = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZone: 'Europe/Stockholm' };
        break;
      case 'full':
        options = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'long', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'long', timeZone: 'Europe/Stockholm' };
        break;
    }
    return options;
  }

}
