export const pageDesquareSettings = {
  title: {sv: "Inställningar för Desquare displayskärmar"},
  page_settings: {
    root_path: "desquare"
  },
  desc: {sv: "Här anges information om de olika regionerna som visas på skärmarna"},
  properties: [
    {key: "regions", title: {sv: "Regioner"}, desc: {sv: ""}, type: "table", optional: true,
      columns: [
        {key: "id", title: {sv: "Regions-id"}, type: "string", width: 100 },
        {key: "name", title: {sv: "Namn"}, type: "string", width: 200 },
        {key: "filter", title: {sv: "Filter"}, type: "string", width: 200 },
        {key: "type", title: {sv: "Typ"}, type: "string", width: 200 },
      ]
    },
  ]
};
