<div *ngIf="!hideMenuBar">
  <p>
    <mat-toolbar>
      <button mat-icon-button routerLink="../../venue-customers"><mat-icon>arrow_back_ios</mat-icon></button>
      <span>{{pageTitle}}</span>
      <span class="spacer"></span>
      <app-wiki-info-link title="Lär dig mer" section="support" path="venue-customer"></app-wiki-info-link>
      <button mat-button (click)="save()"><mat-icon>save</mat-icon> Spara</button>
    </mat-toolbar>
  </p>
</div>

<app-data-form #dataform [schema]="dataFormSchema" [root]="venueCustomer" (save)="save()"></app-data-form>

<app-wiki-display section="intern-support-wiki" path="venue-customer" [style]="'wiki-doc'" *ngIf="!hideMenuBar"></app-wiki-display>
