import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FPData, FPSectionData } from 'src/app/models/floorplan';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';
import { VenueService } from 'src/app/services/venue.service';

@Component({
  selector: 'app-edit-section-order-dialog',
  templateUrl: './edit-section-order-dialog.component.html',
  styleUrls: ['./edit-section-order-dialog.component.css']
})
export class EditSectionOrderDialogComponent implements OnInit {
  name = new UntypedFormControl('', [Validators.required]);
  title: string;
  button: string;
  sections: FPSectionData[];
  selected: string;
  forward: boolean;
  back: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<EditSectionOrderDialogComponent>, private auth: AuthService) {
    this.title = data.title;
    this.button = data.button;
    this.sections = data.sections;
    this.selected = data.id;
  }

  ngOnInit(): void {
    this.toggleButton();
  }

  moveBack() {
    const i = this.sections.findIndex(section => section.id === this.selected);
    if (i > 0) {
      const temp = this.sections[i];
      this.sections[i] = this.sections[i - 1];
      this.sections[i - 1] = temp;
    }
    this.toggleButton();
  }

  moveForward() {
    const i = this.sections.findIndex(section => section.id === this.selected);
    if (i < this.sections.length - 1) {
      const temp = this.sections[i];
      this.sections[i] = this.sections[i + 1];
      this.sections[i + 1] = temp;
    }
    this.toggleButton();
  }

  selectSection(sec) {
    this.selected = sec.id;
    this.toggleButton();
  }

  toggleButton() {
    const i = this.sections.findIndex(section => section.id === this.selected);
    this.back = i !== 0;
    this.forward = i !== (this.sections.length - 1);
  }

  ok() {
    this.dialogRef.close();
  }
}
