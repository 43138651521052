import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from "rxjs";
import {VenueConfig} from "../../../models/venue-config";
import {
  QueryCustomerRow,
  QuerySalesDataRequest,
  QueryZReportSummaryResponse
} from "../../../models/reporting";
import {VenueService} from "../../../services/venue.service";
import {ActivatedRoute, Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {FireService} from "../../../services/fire.service";
import {MatTableDataSource} from "@angular/material/table";
import {first} from "rxjs/operators";
import {HackUtils, LocalFileUtils} from "../../../utils/utils";
import * as _ from "lodash";
import { SimpleDialogComponent } from '../../simple-dialog/simple-dialog.component';

@Component({
  selector: 'app-report-zsummary',
  templateUrl: './report-zsummary.component.html',
  styleUrls: ['./report-zsummary.component.css']
})
export class ReportZsummaryComponent implements OnInit, OnDestroy {
  private paramSub: Subscription;
  private query: string;
  private config: VenueConfig;
  private response: QueryZReportSummaryResponse;
  private venueIdNum: number;
  private rows: any[];

  constructor(private venueService: VenueService, private route: ActivatedRoute, public datePipe: DatePipe,
              private dialog: MatDialog, private router: Router, private fire: FireService) {
  }

  displayedColumns = ["section", "category", "amount"];

  @Input() venueId: string;
  searchStartDate: Date;
  searchEndDate: Date;
  noData = false;
  initData = true;
  dataSource: MatTableDataSource<QueryCustomerRow>;
  title: string;
  isMobile: boolean;

  ngOnInit(): void {
    this.isMobile = this.isMobile = HackUtils.isMobile() && window.innerWidth <= 768;
    this.paramSub = combineLatest([
        this.route.paramMap,
        this.route.queryParamMap
      ]
    ).subscribe(([param, query]) => {
      const q = query.get("q");
      const venueId = param.get("venue_id");
      this.venueId = venueId;
      this.venueIdNum = Number(venueId);
      this.query = q;
      // if (shouldFetch && this.query !== "search") {
      //   this.setupInitialSort(q);
      //   this.fetchReceipts();
      // }
    });
  }

  ngOnDestroy(): void {
    this.paramSub?.unsubscribe();
  }

  fetch() {
    this.initData = false;
    this.noData = false;
    const request = this.buildRequest();
    this.venueService.queryZReportSummary(request).then(r => {
      console.log(r);
      if (r != null) {
        this.title = `Z-rapport summering för perioden ${r.params.start_date} till ${r.params.end_date}`;
        this.response = r;
        const rows = this.convertToRows(r);
        this.rows = rows;
        console.log(rows);
        this.dataSource = new MatTableDataSource(rows);
        this.noData = rows.length === 0;
      } else {
        this.title = "-";
        this.dataSource = new MatTableDataSource([]);
      }
    }).catch(e => {
      SimpleDialogComponent.showErr(this.dialog, e);
      console.error(e);
    });
  }

  fetchSie(config) {
    const request = this.buildRequest();
    const dates = LocalFileUtils.createDateFileName(request.start_date, request.end_date);
    const filename = `sie_period_${config.venue.name.replace(/\\s/g, "_")}_${dates}.si`;
    this.venueService.queryAndDownloadSieFile(request, filename).subscribe(r => {
      console.log(r);
    });
  }

  private buildRequest() {
    const request = new QuerySalesDataRequest();
    request.venue_id = this.venueId;
    if (this.searchStartDate != null) {
      request.start_date = this.datePipe.transform(this.searchStartDate, 'yyyy-MM-dd');
    }
    if (this.searchEndDate != null) {
      request.end_date = this.datePipe.transform(this.searchEndDate, 'yyyy-MM-dd');
    }
    return request;
  }

  async export(format: string) {
    const config = await this.fire.observeVenueConfig(Number(this.venueId)).pipe(first()).toPromise();
    if (format === "csv") {
      const data = this.buildDataArrayWithHeader();
      console.log(data);
      const dates = LocalFileUtils.createDateFileName(this.response.params.start_date, this.response.params.end_date);
      const filename = `zreport_summary_${config.venue.name.replace(/\\s/g, "_")}_${dates}.csv`;
      LocalFileUtils.createFileWithBOM(data, filename);
    }
    else if (format === "sie") {
      this.fetchSie(config);
    }
  }

  select(row: any) {
    console.log(row);
  }

  private buildDataArrayWithHeader() {
    const data = [];
    const header = [{col: "section", title: "Sektion"}, {col: "category", title: "Kategori"}, {col: "amount", title: "Värde", type: "cent"}];
    return LocalFileUtils.compileDataIntoCSV(header, this.rows);
  }

  private convertToRows(r: QueryZReportSummaryResponse): any[] {
    const rows = [];
    rows.push(...this.buildRows(r.booked_sales, "Försäljning"));
    rows.push(...this.buildRows(r.booked_vat, "Moms"));
    rows.push(...this.buildRows(r.booked_payments, "Betalmedel"));
    rows.push(...this.buildRows(r.ext_prepaid, "Prepaid"));
    return rows;
  }

  private buildRows(cats: any, title: string) {
    const rows: {section?: string, category?: string, amount?: number}[] = [];
    _.forOwn(cats, (value, key) => {
      rows.push({category: key, amount: value});
    });
    if (rows.length > 0) {
      const sr = _.sortBy(rows, ["category"]);
      sr.splice(0, 0, {section: title});
      return sr;
    }
    return [];
  }
}
