<div class="report-tree-container">

  <app-title-bar [title]="title"></app-title-bar>

  <div class="search-fields-root">
    <div class="search-fields">
      <mat-form-field appearance="outline">
        <mat-label>Day or start date</mat-label>
        <input matInput [(ngModel)]="searchStartDate" [matDatepicker]="startPicker">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker [touchUi]="isMobile"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>End date</mat-label>
        <input matInput [(ngModel)]="searchEndDate" [matDatepicker]="endPicker">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker [touchUi]="isMobile"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Artikel</mat-label>
        <mat-select [(ngModel)]="searchItem" #select (keydown)="onKeyDown($event, select)">
          <mat-option *ngFor="let item of menuItems" [value]="item.id" [style.padding-left.px]="item.indent * 8" [disabled]="!item.isLeaf">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-flat-button class="search-button" (click)="updateParams()" color="primary">SÖK</button>

      <button mat-stroked-button class="dropdown-button" #reportsMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="menuOptions"><mat-icon>expand_more</mat-icon></button>
      <mat-menu #menuOptions="matMenu">
        <div style="display: flex;flex-direction: column;">
          <mat-checkbox style="padding: 8px 12px;" color="primary" #menuItems
                        *ngFor="let item of dailyOptions; let i = index;"
                        [(ngModel)]="item.active"
                        (click)="$event.stopPropagation()"
                        (change)="selectedOption(item)">{{ item.title }}</mat-checkbox>
        </div>
      </mat-menu>

    </div>
  </div>

  <div>
    <div class="base-tree-container">
      <div id="chart" *ngIf="chartOptions" style="margin-right: 8px;">
        <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [xaxis]="chartOptions.xaxis"
          [yaxis]="chartOptions.yaxis"
          [dataLabels]="chartOptions.dataLabels"
          [grid]="chartOptions.grid"
          [stroke]="chartOptions.stroke"
          [title]="chartOptions.title"
          [colors]="chartOptions.colors"
        ></apx-chart>
      </div>
    </div>
    <div class="no-data" *ngIf="noData">Perioden innehåller ingen försäljning</div>
    <div class="missing-data" *ngIf="missingData">OBS! Allt data för perioden har inte hämtats, välj ett kortare datumspann.</div>
  </div>
  <div *ngIf="reportData && !noData" style="padding: 16px 8px 0 0;">
    <div class="table-header" *ngIf="fetchedStartDate && fetchedEndDate">
      <span class="table-title">{{fetchedStartDate | date:"yyyy-MM-dd"}} - {{fetchedEndDate | date:"yyyy-MM-dd"}}{{selectedItem}}</span>
      <span class="spacer"></span>
      <button mat-icon-button [matMenuTriggerFor]="menuHandleSection">
        <mat-icon style="transform: scale(0.85); color: #6E8192;">menu</mat-icon>
      </button>
      <mat-menu #menuHandleSection="matMenu">
        <button mat-menu-item (click)="exportTable.export()">Exportera CSV</button>
      </mat-menu>
    </div>
    <app-report-table #reportTable [tableData]="reportData" [venueId]="venueId"></app-report-table>
  </div>
  <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="!reportData"></mat-spinner>

</div>
