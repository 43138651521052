<mat-dialog-content class="item-dialog mat-typography" style="padding: 0;margin-top: -24px;">

  <div class="item-header">
    <div class="item-title">Publish</div>
  </div>

  <div class="item-content">
    <div>
      Last published 2023-01-02 45:22
    </div>
    <div class="mid-spinner">
      <mat-spinner class="add-item-spinner" *ngIf="publishing" [diameter]="32" style="margin-top: 16px;"></mat-spinner>
    </div>
    <div class="panel" style="color: #da2a2a;" *ngIf="errorMessage">
      <div class="panel-title"><mat-icon style="font-size: 22px;">error</mat-icon><div style="font-size: 16px; margin-left: 6px;">Publish failed</div></div>
      <div class="panel-body">{{errorMessage}}</div>
    </div>
    <div class="panel" style="color: #317370;" *ngIf="message">
      <div class="panel-title"><mat-icon style="font-size: 22px;">check_circle</mat-icon><div style="font-size: 16px; margin-left: 6px;">Publish successful</div></div>
      <div class="panel-body">{{message}}</div>
    </div>
  </div>

</mat-dialog-content>
<div mat-dialog-actions>
  <button mat-flat-button mat-dialog-close>Close</button>
  <button [disabled]="publishing" mat-flat-button (click)="publish()" color="primary" style="margin-left: auto;">Publish</button>
</div>

