<div>
	<p>
	  <mat-toolbar>
		<span>Adyen Onboarding</span>
		<span class="spacer"></span>
			<button mat-button (click)="goToOnboarding()">Go to onboarding
				<mat-icon style="padding-left:8px;">open_in_new</mat-icon>
			</button>
	  </mat-toolbar>
	</p>
</div>

<div style="padding: 16px;">
	<table mat-table [dataSource]="setupData" matSort>
	  <ng-container matColumnDef="type">
		<th mat-header-cell *matHeaderCellDef mat-sort-header>Capability </th>
		<td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.get('type')}} </td>
	  </ng-container>
	  <ng-container matColumnDef="enabled">
		<th mat-header-cell *matHeaderCellDef mat-sort-header>Enabled </th>
		<td mat-cell *matCellDef="let element" style="padding: 4px;"> 
			<mat-icon *ngIf="element.get('enabled')" style="color:#0abf53;">check</mat-icon>
			<mat-icon *ngIf="!element.get('enabled')" style="color:#e50000;">close</mat-icon>
		</td>
	  </ng-container>
	  <ng-container matColumnDef="allowed">
		<th mat-header-cell *matHeaderCellDef mat-sort-header>Allowed </th>
		<td mat-cell *matCellDef="let element" style="padding: 4px;">
			<mat-icon *ngIf="element.get('allowed')" style="color:#0abf53;">check</mat-icon>
			<mat-icon *ngIf="!element.get('allowed')" style="color:#e50000;">close</mat-icon>
		</td>
	  </ng-container>
	  <ng-container matColumnDef="verificationStatus">
		<th mat-header-cell *matHeaderCellDef mat-sort-header>Verification Status</th>
		<td mat-cell *matCellDef="let element" style="padding: 4px;">
			<span class="verification-status" [ngClass]="element.get('verificationStatus') == 'valid' ? 'valid' : 'invalid'"> {{element.get('verificationStatus') | titlecase}} </span>
		</td>
	  </ng-container>
	  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
	  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
	</table>
  </div>
