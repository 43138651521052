<div>
  <p>
    <mat-toolbar>
      <span>Enheter {{title}}</span>
      <span class="spacer"></span>
      <button mat-button (click)="toggleDeviceList()">
        <mat-icon>devices</mat-icon> Överblick
      </button>
    </mat-toolbar>
    <mat-card *ngIf="deviceList">
      <mat-card-content class="device-card">
        <mat-table [dataSource]="listSource" class="card-table" matSort>
          <ng-container matColumnDef="deviceName">
            <mat-header-cell *matHeaderCellDef class="device-name-column" mat-sort-header="deviceName">Device
              Name</mat-header-cell>
            <mat-cell *matCellDef="let row" class="device-name-column"> {{row.deviceName}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="version">
            <mat-header-cell *matHeaderCellDef class="version-column"
              mat-sort-header="version">Version</mat-header-cell>
            <mat-cell *matCellDef="let row" class="version-column"> {{row.version}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="ip">
            <mat-header-cell *matHeaderCellDef class="ip-column" mat-sort-header="ip">IP</mat-header-cell>
            <mat-cell *matCellDef="let row" class="ip-column"> {{row.ip}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="model">
            <mat-header-cell *matHeaderCellDef class="model-column" mat-sort-header="model">Model</mat-header-cell>
            <mat-cell *matCellDef="let row" class="model-column"> {{row.model}} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef class="status-column" mat-sort-header="status">Status</mat-header-cell>
            <mat-cell *matCellDef="let row" class="status-column"> {{row.status}} </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="listColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: listColumns;"
            [ngClass]="{'different-network': isDifferentNetwork(row.ip)}"></mat-row>
        </mat-table>
      </mat-card-content>
    </mat-card>
  </p>
</div>


<div class="grid-container">
  <div class="grid-item">
    <div class="tree-container">
      <div *ngFor="let row of rows" [ngClass]="{'flash': row.flash}">
        <div [ngStyle]="{'padding-left.px':row.level*30}">
          <button *ngIf="row.inspectable" (click)=inspectDevice(row.name) style="padding-left: 0px;"
            mat-button><mat-icon *ngIf="row.icon" color="{{row.iconColor}}"
              style="padding-right: 10px;">{{row.icon}}</mat-icon>{{row.title}}</button>
          <div *ngIf="!row.inspectable" class="tree-title">{{row.title}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid-item">
    <mat-card *ngIf="inspected" [ngClass]="{'fixed-card':!isSubVenue}">
      <mat-card-header>
        <mat-card-title><mat-icon color="{{inspected.iconColor}}"
            style="padding-right: 10px;">{{inspected.icon}}</mat-icon>{{inspected.title}}</mat-card-title>
        <mat-card-subtitle>{{inspected.subTitle}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <div>
          <div class="property" *ngFor="let row of inspected.rows">
            <span *ngIf="!row.isLink" class="inspect-key">{{row.key}}:</span>
            <span *ngIf="!row.isLink && row.key !== 'iminVersion'">{{row.value}}</span>
            <span *ngIf="row.key === 'iminVersion'" [ngClass]="{'outdated-version': isVersionOutdated(row.value)}">{{row.value}}</span>
            <span *ngIf="row.isLink" class="inspect-key"><a href="{{row.value}}">{{row.key}}</a></span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<!--<pre>{{deviceDump}}</pre>-->
