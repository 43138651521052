import {Component, isDevMode, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {VenueService} from "../../services/venue.service";
import {MatTableDataSource} from "@angular/material/table";
import {Admin} from "../../models/user";
import {MatSort, MatSortable} from "@angular/material/sort";
import {QueryUserDialogComponent} from "../user-list/query-user-dialog/query-user-dialog.component";
import {HackUtils} from "../../utils/utils";
import {EditRoleDialogComponent} from "../user-list/edit-role-dialog/edit-role-dialog.component";
import {QueryStaffDialogComponent} from "./query-staff-dialog/query-staff-dialog.component";
import {EditPinDialogComponent} from "./edit-pin-dialog/edit-pin-dialog.component";
import {SimpleDialogComponent} from "../simple-dialog/simple-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import {EditStaffDialogComponent} from "./edit-staff-dialog/edit-staff-dialog.component";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-staff-list',
  templateUrl: './staff-list.component.html',
  styleUrls: ['./staff-list.component.css']
})
export class StaffListComponent implements OnInit {

  constructor(public dialog: MatDialog, private route: ActivatedRoute, private venueService: VenueService, private snackBar: MatSnackBar, private auth: AuthService) { }
  venueId: number;
  displayedColumns = ["identity", "first_name", "last_name", "roles", "email", "phone", "actions"];
  dataSource: MatTableDataSource<Admin>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit(): void {
    this.sort.sort(({ id: 'first_name', start: 'asc'}) as MatSortable);
    this.route.paramMap.subscribe(data => {
      const vn = data.get("venue_id");
      this.venueId = Number(vn);
      this.fetchUsers();
    });
  }

  private fetchUsers() {
    this.venueService.fetchStaff(this.venueId).then(r => {
      this.dataSource = new MatTableDataSource(r as Admin[]);
      this.dataSource.sort = this.sort;
      if (this.isHeynowAdmin() && !this.displayedColumns.includes("pin")) {
        //insert next to last
        this.displayedColumns.splice(this.displayedColumns.length - 1, 0, "pin");
      }
    });
  }

  addStaff() {
    const dialogRef = this.dialog.open(QueryStaffDialogComponent, HackUtils.DLG({
      data: { venueId: this.venueId }
    }));
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
      this.fetchUsers();
    });
  }

  openEditPinDialog(indentity: string) {
    const dialogRef = this.dialog.open(EditPinDialogComponent, HackUtils.DLG({
      data: { identity: indentity, venueId: this.venueId }
    }));
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
      this.fetchUsers();
    });
  }

  openEditStaff(staff: Admin) {
    const dialogRef = this.dialog.open(EditStaffDialogComponent, HackUtils.DLG({
      data: { staff, venueId: this.venueId }
    }));
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
      this.fetchUsers();
    });
  }

  changePin(row: any) {
    this.openEditPinDialog(row.identity);
  }

  editStaff(element) {
    this.openEditStaff(element);
  }

  deleteStaff(element) {
    const ds = {title: `Ta bort personal`, message: `Vill du ta bort ${element.first_name} från personallistan`, cancelButton: "Avbryt", positiveButton: "Ta bort"};
    SimpleDialogComponent.observeSimpleDialog(this.dialog, ds).subscribe( r => {
      if (r) {
        this.venueService.removeStaff(element.identity, Number(this.venueId)).then( res => {
          console.log(res);
          //this.fetchUsers();
          const adms = this.dataSource.data.filter(a => a.identity !== element.identity);
          this.dataSource = new MatTableDataSource(adms);
        }).catch(error => this.snackBar.open(`Gick inte att ta bort personal`, "close"));
      }
    });
    }

  isHeynowAdmin() {
    return AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }
}
