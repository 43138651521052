export const pagePinCodes = {
  title: {sv: "PIN-koder"},
  page_settings: {
    root_path: "pin_codes"
  },
  desc: {sv: "Ställ in PIN-koder"},
  properties: [
    {key: "basic", title: {sv: "PIN-kod(er) basic"}, desc: {sv: ""}, type: "string[]", optional: true},
    {key: "refund", title: {sv: "PIN-kod(er) retur"}, desc: {sv: ""}, type: "string[]", optional: true},
    {key: "refund_tip", title: {sv: "PIN-kod(er) retur med dricks"}, desc: {sv: ""}, type: "string[]", optional: true},
    {key: "void", title: {sv: "PIN-kod(er) annullering"}, desc: {sv: ""}, type: "string[]", optional: true},
  ]
};
