export const pageRoomserviceSettings = {
  title: { sv: "Roomservice" },
  page_settings: {
    root_path: "roomservice"
  },
  desc: { sv: "Inställningar för roomservice" },
  properties: [
    { key: "anon_orderable", title: { sv: "Tillåt beställningar utan inlogg" }, desc: { sv: "Förenklar beställning för gäster, kan dock göra det lite svårare att hitta en beställning i t.ex. KDS:en" },
        type: "bool", optional: true
    },
    { key: "table_regex", title: { sv: "Bordsfilter (roomservice)" }, desc: { sv: "" }, default: "roomservice", type: "string", optional: false, hidden: true },
    { key: "menu_filter_tag", title: { sv: "Artikelfiltreringstag (roomservice)" }, desc: { sv: "" }, default: "roomservice", type: "string", optional: false },
    { key: "cooking_time", title: { sv: "Tillagningstid" }, desc: { sv: "Obs! Ändringar görs i appen" }, type: "integer", default: 15, suffix: "min", optional: false, hidden: true },
    { key: "fee_item_id", title: { sv: "Artikel-Id (roomservice-avgift)" }, desc: { sv: 'Artikel-Id från menyn för roomservice-avgift. Skriv "iddrk_" följt av artikel-Id t.ex. iddrk_roomservice-avgift' },
        type: "string", optional: true, hidden: true
    },
    { key: "show_prep_time", title: { sv: "Visa tillagningstid" }, desc: { sv: "" }, type: "bool", optional: true },
    { key: "open_hours", title: { sv: "" }, desc: { sv: "Öppettider" }, type: "table", optional: false,
      columns: [
        { key: "day", title: { sv: "Dag" }, desc: { sv: "Dag" }, type: "integer[]", optional: false },
        { key: "start", title: { sv: "Öppning" }, desc: { sv: "" }, type: "float", optional: false },
        { key: "stop", title: { sv: "Stängning" }, desc: { sv: "" }, type: "float", optional: false },
      ]
    },

    { header: "Gästinformation för Roomservice" },
    { key: "text.title", title: { sv: "Titel för gäst, t.ex. Roomservice" }, type: "string", desc: { sv: "" }, optional: true },
    { key: "text.desc", title: { sv: "Beskrivning" }, type: "string", desc: { sv: "" }, optional: true },
  ],
};

export const pageRoomserviceRoomSettings = {
  title: { sv: "Rum"},
  page_settings: {
    root_anchor: "text_db"
  },
  desc: { sv: "Rum"},
  properties: [
    { key: "text_db", title: { sv: "Rum" }, desc: { sv: "" }, type: "table", optional: false,
      columns: [
        { key: "key", title: { sv: "Nyckel" }, desc: { sv: "" }, type: "string", optional: false },
        { key: "lang", title: { sv: "Språk" }, desc: { sv: "" }, type: "string", optional: true },
        { key: "text", title: { sv: "Text" }, desc: { sv: "" }, type: "string", optional: false },
      ]
    },
  ],
};
