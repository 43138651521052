import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {VenueService} from "../../../../services/venue.service";
import {LoadingService} from "../../../../services/loading.service";
import {AuthService} from "../../../../services/auth.service";

@Component({
  selector: 'app-new-api-token-dialog',
  templateUrl: './new-api-token-dialog.component.html',
  styleUrls: ['./new-api-token-dialog.component.css']
})
export class NewApiTokenDialogComponent implements OnInit {
  email = new UntypedFormControl('', [Validators.email, Validators.required]);
  name = new UntypedFormControl('', [Validators.required]);
  phone = new UntypedFormControl('', []);

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<NewApiTokenDialogComponent>, private auth: AuthService) {
  }

  ngOnInit(): void {
  }

  addUser() {
    if (this.name.valid && this.email.valid) {
      this.venueService.createNewApiToken(this.name.value, this.email.value, this.phone.value).then( r => {
        console.log("Created new api-token...");
        this.dialogRef.close(true);
      });
    }
  }

  isHeynowAdmin() {
    return AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }
}
