export const pageFortnoxSettings = {
  title: { sv: "Fortnox" },
  page_settings: {
      root_path: "fortnox"
  },
  desc: { sv: "Inställningar för Fortnox" },
  properties: [
    {key: "authorization_code", title: {sv: "Behörighetskod"}, desc: {sv: "Används endast för äldre fortnox integrationer"}, type: "string", optional: true, hidden: true},
    {key: "subscriber_id", title: {sv: "Abonnemangsnr i Fortnox"}, desc: {sv: "Används vid flera restauranger med samma organisationsnummer"}, type: "string", optional: true},

    {header: "Bokföring"},
    {key: "bookkeping.active", title: {sv: "Aktivera bokföring"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "bookkeping.cost_center_code", title: {sv: "Kostnadsställe"}, desc: {sv: ""}, type: "string", optional: true},
    {key: "bookkeping.transaction_information", title: {sv: "Transaktionsinformation"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "bookkeping.zreport_as_pdf", title: {sv: "Z-rapport som PDF"}, desc: {sv: ""}, type: "bool", optional: true},

    {header: " "},
    {header: "Order/ samlingsfaktura"},
    {key: "order.active", title: {sv: "Aktivera order"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "order.auto_email", title: {sv: "Mailad orderbekräftelse till kund"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "order.email_bcc", title: {sv: "E-post BCC"}, desc: {sv: "Skickar dold kopia till angiven e-post"}, type: "string", optional: true},

    {header: "Faktura"},
    {key: "invoice.active", title: {sv: "Aktivera faktura"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "invoice.auto_book", title: {sv: "Automatisk bokföring "}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "invoice.auto_email", title: {sv: "Automatisk fakturering till kund"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "invoice.email_bcc", title: {sv: "E-post BCC"}, desc: {sv: "Skickar dold kopia till angiven e-post"}, type: "string", optional: true},
    {key: "invoice.edi_active", title: {sv: "EDI aktivt"}, desc: {sv: "Experimentell aktiverar EDI på faktura och sparar referensen som edi reference"}, type: "bool", optional: true, hidden: true},
    {key: "invoice.default_cost_center", title: {sv: "Standardkostnadsställe faktura"}, desc: {sv: "Används om man vill ange kostnadsställe på faktura-artiklar, kostnadsställe på artikel har högre prioritet"}, type: "string", optional: true, hidden: true},
  ]
};
