import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {combineLatest, Subscription} from "rxjs";
import {VenueService} from "../../../services/venue.service";
import {ActivatedRoute, ParamMap, Params, Router} from "@angular/router";
import Utils from "../../../common/utils";
import {HackUtils, NodeUtils} from "../../../utils/utils";
import {QuerySalesDataRequest, QuerySalesReportResponse} from "../../../models/reporting";
import * as _ from "lodash";
import {MatLegacyMenuTrigger as MatMenuTrigger} from "@angular/material/legacy-menu";
import * as moment from "moment";
import { ReportTableComponent } from '../report-table/report-table.component';
import { MenuService } from 'src/app/services/menu.service';
import { DatePipe } from '@angular/common';
import { SimpleDialogComponent } from '../../simple-dialog/simple-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-report-daily',
  templateUrl: './report-daily.component.html',
  styleUrls: ['./report-daily.component.css']
})
export class ReportDailyComponent implements OnInit, OnDestroy {

  @ViewChild('reportsMenuTrigger') reportsMenuTrigger: MatMenuTrigger;
  @ViewChild('reportTable') exportTable: ReportTableComponent;

  title = "Försäljning per dag";
  searchStartDate: Date;
  searchEndDate: Date;
  searchItem: string;
  selectedItem = "";
  chartOptions: any;
  venueId: number;
  isMobile: boolean;
  reportData: QuerySalesReportResponse;
  noData = false;
  missingData = false;
  dailyOptions = [
    {title: "Visa linje diagram", active: false},
    {title: "Visa inkl.moms", active: false},
  ];
  private RTI = {SHOW_LINE: 0, SHOW_VAT: 1};
  private paramSub: Subscription;
  private q: string;
  private chartType = "bar";
  private groupBy: string;
  private showSalesType: string;
  private dateType = "pay";
  fetchedStartDate: string;
  fetchedEndDate: string;
  menuItems: { id: string, name: string, indent: number }[] = [];

  constructor(private venueService: VenueService, private route: ActivatedRoute, private router: Router,
              private menuService: MenuService, private datePipe: DatePipe, private dialog: MatDialog
  ) {}

  onKeyDown(event: KeyboardEvent, select: any) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      this.searchItem = null;
      select.close();
      event.preventDefault();
    }
  }

  ngOnInit(): void {
    this.isMobile = HackUtils.isMobile() && window.innerWidth <= 768;
    this.paramSub = combineLatest([
        this.route.paramMap,
        this.route.queryParamMap,
      ]
    ).subscribe(([param, query]) => {
      this.parseQueryParams(query);
      this.venueId = Number(param.get("venue_id"));
      if (this.q !== "search") {
        this.fetch();
      }
      this.menuService.getMenuTreeData(this.venueId).subscribe(flatData => {
        this.menuItems = flatData;
      });
    });
  }

  ngOnDestroy(): void {
    this.paramSub?.unsubscribe();
  }

  private fetch() {
    this.resetData(); // Reset data before fetching new data (also retriggers chart and table)
    const request = this.buildQueryRequest();
    this.venueService.querySalesPerDay(request).then(r => {
      this.reportData = r;
      this.missingData = r.data.rows.length > 99;
      this.noData = r.data.rows.length === 0;
      this.buildChartOptions(r);

      if (r.data.rows.length > 0) {
        const dateList = r.data.rows.map(row => new Date(row[0]));
        const minDate = new Date(Math.min(...dateList.map(date => date.getTime())));
        const maxDate = new Date(Math.max(...dateList.map(date => date.getTime())));

        this.fetchedStartDate = String(minDate);
        this.fetchedEndDate = String(maxDate);
      }
    }).catch(e => {
      SimpleDialogComponent.showErr(this.dialog, e);
      console.error(e);
    });
  }

  private createCategories(response: QuerySalesReportResponse) {
    const categories = [];
    for (const row of response.data.rows) {
      categories.push(row[0]);
    }
    return categories.reverse();
  }

  private createSerie(categories: string[], td: QuerySalesReportResponse, incVat: boolean) {
    const serieSales = [];
    for (const date of categories) {
      const v = td.data.rows.find( d => d[0] === date);
      const sa = incVat ? v[2] : v[1];
      serieSales.push(Math.round(sa ?? 0));
    }
    return serieSales;
  }

  private buildChartOptions(r: QuerySalesReportResponse) {
    const categories = this.createCategories(r);
    const series = [];
    const incVat = this.showSalesType === "inc_vat";
    const serieSales = this.createSerie(categories, r, incVat);
    series.push({name: "Sales", data: serieSales});

    const vatType = incVat ? "inkl. moms" : "ex. moms";
    const ot = this.dateType === "pay" ? "Försäljning" : "Beställning";
    let title = `${ot} per dag (${vatType})`;
    this.selectedItem = "";
    if (this.searchItem) {
      const item = this.menuItems.find(i => i.id === this.searchItem);
      title = `${ot} per dag (${vatType})${item ? ` - (${item.name.trim()})` : ''}`;
      this.selectedItem = `, (${item?.name.trim()})` ?? "";
    }

    this.chartOptions = {
      colors: ["#F89A81", "#F8C381", "#56839E", "#5CB181", "#FFDAD1", "#FFEAD1", "#BAD2E1", "#BDE7CF", "#FFBCAB", "#FFD9AB", "#84AAC0", "#8ACDA7", "#D26B50", "#D29850", "#386986", "#399661", "#AF482C", "#AF742C", "#215270", "#207D48"],
      xaxis: {categories,
        labels: {
          formatter: (value: any) => {
            return this.datePipe.transform(value, 'EE, d MMM, y');
          }
        }
      },
      yaxis: {
        show: true,
        labels: {
          formatter(val, index) {
            const v = HackUtils.thousandsSepNumber(val);
            return v;
          }
        }
      },
      series,
      chart: {
        height: 350,
        type: this.chartType,
        zoom: {
          enabled: false
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 200,
          animateGradually: {
            enabled: true,
            delay: 50
          },
          dynamicAnimation: {
            enabled: false,
            speed: 350
          }
        }

      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "straight"
      },
      title: { text: title, align: "left"},
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
    };
  }

  private buildQueryRequest(): QuerySalesDataRequest {
    const query = new QuerySalesDataRequest();
    query.venue_id = this.venueId.toString();
    if (this.searchStartDate != null) {
      query.start_date = Utils.dateToDateKey(this.searchStartDate);
    }
    if (this.searchEndDate != null) {
      query.end_date = Utils.dateToDateKey(this.searchEndDate);
    }
    if (this.searchItem != null) {
      query.item_ids = [this.searchItem];
    }
    return query;
  }

  updateParams() {
    const queryParams: Params = {r: Utils.getRandomInt(500)};
    if (this.searchStartDate != null) {
      queryParams.start_date = Utils.dateToDateKey(this.searchStartDate);
    }
    if (this.searchEndDate != null) {
      queryParams.end_date = Utils.dateToDateKey(this.searchEndDate);
    }
    if (this.searchItem != null) {
      queryParams.item = this.searchItem;
    }
    queryParams.sales_type = this.showSalesType;
    queryParams.group_by = this.groupBy;
    queryParams.chart_type = this.chartType;
    queryParams.date_type = this.dateType;
    this.router.navigate([], { relativeTo: this.route, queryParams });
  }

  private resetData() {
    this.reportData = null;
    this.chartOptions = null;
    this.noData = false;
    this.missingData = false;
  }

  private resetDefaultProps() {
    this.searchStartDate = null;
    this.searchEndDate = null;
    this.groupBy = undefined;
    this.showSalesType = "ex_vat";
  }

  private setupInitialParams(q: string) {
    switch (q) {
      case "week":
        this.searchStartDate = moment().subtract(1, "week").toDate();
        this.searchEndDate = moment().toDate();
        break;
      case "month":
        this.searchStartDate = moment().subtract(1, "month").toDate();
        this.searchEndDate = moment().toDate();
        break;
      case "search":
        break;
    }
  }

  private parseQueryParams(query: ParamMap) {
    this.resetDefaultProps();
    this.q = query.get("q");
    if (this.q) {
      this.setupInitialParams(this.q);
    } else {
      const sd = query.get("start_date");
      const ed = query.get("end_date");
      const item = query.get("item");
      if (sd) {
        this.searchStartDate = Utils.dateKeyToMoment(sd).toDate();
      }
      if (ed) {
        this.searchEndDate = Utils.dateKeyToMoment(ed).toDate();
      }
      this.searchItem = item;
      const d = query.get("sales_type");
      const salesType = d ?? "ex_vat";
      this.showSalesType = salesType;
      this.groupBy = NodeUtils.makeUndefined(query.get("group_by"));
      const ct = query.get("chart_type");
      this.chartType = ct === "line" ? "line" : "bar";
      const dt = query.get("date_type");
      this.dateType = dt === "order" ? "order" : "pay";
    }

    this.dailyOptions[this.RTI.SHOW_VAT].active = this.showSalesType === "inc_vat";
    this.dailyOptions[this.RTI.SHOW_LINE].active = this.chartType === "line";
  }

  selectedOption(item: any) {
    const ct = this.dailyOptions[this.RTI.SHOW_LINE].active ? "line" : "bar";
    if (ct !== this.chartType) {
      this.chartType = ct;
      const copy = _.cloneDeep(this.chartOptions);
      copy.chart.type = ct;
      this.chartOptions = copy;
    } else {
      this.showSalesType = this.dailyOptions[this.RTI.SHOW_VAT].active ? "inc_vat" : "ex_vat";
      this.updateParams();
    }
    this.reportsMenuTrigger.closeMenu();
  }

}
