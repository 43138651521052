import {Component, OnDestroy, OnInit} from '@angular/core';
import {NewTabDialogComponent} from "./new-tab-dialog/new-tab-dialog.component";
import {MenuTab, parseMenuTabSettings} from "../menu-models/MenuChange";
import {FireService} from "../../../services/fire.service";
import {MatDialog} from "@angular/material/dialog";
import {MenuStateService} from "../menu-utils/menu-state.service";
import {AuthService} from "../../../services/auth.service";
import {AuthState} from "../../../models/signin";
import {ActivatedRoute, Router} from "@angular/router";
import {PublishService} from "../../../services/publish.service";
import {AlreadyPublishedError, ValidationError} from "../menu-models/PublishModels";
import {HttpErrorResponse} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import * as moment from 'moment';
import {MatSnackBar} from "@angular/material/snack-bar";
import * as _ from "lodash";
import {Subscription} from "rxjs";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit, OnDestroy {
  tabs: MenuTab[];
  publishing = false;
  message: string;
  errorMessage: SafeHtml;
  errorAt: string;
  venueId: number;
  publishDate: string;
  linkToCollins: string;

  private authState: AuthState;
  private sub1: Subscription;
  private sub2: Subscription;
  private sub3: Subscription;
  private sub4: Subscription;

  columns = [];

  constructor(private authService: AuthService, private route: ActivatedRoute, private fire: FireService,
              private publishService: PublishService, public dialog: MatDialog, private state: MenuStateService,
              private snackBar: MatSnackBar, private router: Router, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.sub1 = this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.sub2?.unsubscribe();
      this.sub2 = this.authService.getLoggedInAuthState().subscribe(auth => {
        this.authState = auth;
        this.loadTabs();
      });

      this.sub3?.unsubscribe();
      this.sub3 = this.fire.observeFSMenu(this.venueId).subscribe( m => {
        if (m?.updated) {
          this.linkToCollins = `${environment.collins_url}/${this.venueId}/`;
          this.publishDate = moment(m.updated.toDate()).format("YYYY-MM-DD HH:mm");
        }
      });
    });

    this.sub4 = this.state.currentColumnsSubject.subscribe(columns => {
      this.columns = columns;
      console.log("Columns changed", columns);
    });
  }

  ngOnDestroy(): void {
    this.sub1?.unsubscribe();
    this.sub2?.unsubscribe();
    this.sub3?.unsubscribe();
    this.sub4?.unsubscribe();
  }

  private loadTabs() {
    this.fire.observeTabs(this.venueId, this.state.menuId).subscribe(tabs => {
      const customTabs = tabs.filter( t => !["drinks", "food", "shop", "options", "pushpages"].includes(t.id));
      this.tabs = _.sortBy(customTabs, [tab => parseMenuTabSettings(tab.settingsJson)?.sort_index ?? 100 , "name"]);
    });
  }

  addTab() {
    // const dialogRef = this.dialog.open(NewTabDialogComponent, {
    //   // minWidth: "800px",
    //   // minHeight: "650px",
    //   data: { venueId: this.venueId}
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   console.log(result);
    // });
    this.router.navigateByUrl(`catalog/${this.venueId}/tab-settings/_new_tab_`);
  }

  publish() {
    this.message = "";
    this.errorMessage = "";
    this.publishing = true;
    this.publishService.publish(this.venueId, this.state.menuId).subscribe( r => {
      this.publishing = false;
      this.message = "Published successfully!";
      console.log("Published successfully!");
    }, error => {
      if (error instanceof ValidationError) {
        this.setErrorMessage(error.message);
        this.errorAt = `Tab: ${error.tab} row: ${error.row}`;
      } else if (error instanceof HttpErrorResponse) {
        this.setErrorMessage(error.error.message);
        this.errorAt = undefined;
      } else if (error instanceof AlreadyPublishedError) {
        this.snackBar.open("No changes. Nothing published.", "", {duration: 3000});
      } else {
        this.setErrorMessage(`Okänt fel:\n${error.message}`);
        this.errorAt = undefined;
        console.log(error);
      }
      this.publishing = false;
    });
  }

  showPageSettings($event: MouseEvent, tab: string) {
    console.log(`Show tab settings: ${tab}`);
    $event.stopPropagation();
    this.router.navigateByUrl(`catalog/${this.venueId}/tab-settings/${tab}`);
  }

  toggleColumn(data) {
    this.state.toggleColumn(data);
  }

  private setErrorMessage(message: string) {
    //split message at \n and add <br> between each line
    console.log(message);
    message = message.split("\n").join("<br>");
    console.log(message);
    this.errorMessage = this.sanitizer.bypassSecurityTrustHtml(message);
  }
}
