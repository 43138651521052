<div class="detail-container-zr">
  <div class="toolbar">
    <button mat-flat-button (click)="downloadSIE()" style="margin-right: 16px;" color="primary">Hämta SIE</button>
    <button mat-flat-button (click)="downloadPDF()" style="margin-right: 16px;" color="primary">Hämta PDF</button>
    <button mat-flat-button [matMenuTriggerFor]="menuPaymentReport" color="primary">Betalrapport</button>
    <mat-menu #menuPaymentReport="matMenu">
      <button mat-menu-item (click)="showPaymentReport()" color="primary">Visa</button>
      <button mat-menu-item (click)="downloadPaymentStaffReportPDF()" color="primary">Hämta PDF</button>
    </mat-menu>
    <button mat-flat-button [matMenuTriggerFor]="menuAdmin" color="primary" style="margin-left: 16px;" *ngIf="isSuperAdmin"><mat-icon>more_horiz</mat-icon></button>
    <mat-menu #menuAdmin="matMenu">
      <button mat-menu-item (click)="sendToFortnox()" color="primary" *ngIf="this.fortnoxConfigured && this.zreport?.fortnox_booking_status != 'success'">Manuell Fortnox-export <mat-icon>admin_panel_settings</mat-icon></button>
      <button mat-menu-item (click)="deleteZReport()" color="primary">Ta bort z-rapport <mat-icon>admin_panel_settings</mat-icon></button>
    </mat-menu>

  </div>
  <div class="detail-body" style="position: relative;">
    <div class="scaled" [innerHTML]="htmlContent"></div>
    <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="showSpinner"></mat-spinner>
  </div>
</div>
