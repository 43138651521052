import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, combineLatest } from 'rxjs';
import {FSVenueConfig, POSTerminalConfig, PrinterConfig, VenueConfig} from "../models/venue-config";
import {map} from "rxjs/operators";
import {TerminalStatus} from "../models/terminal-status";
import {DeviceState, DeviceStateClient, DeviceStatePrinter, DeviceStateTerminal} from "../models/device-state";
import {PrinterStatus} from "../models/printer-status";
import {ClientStatus} from "../models/client-status";
import * as moment from 'moment';
import {FireService} from "./fire.service";

@Injectable({
  providedIn: 'root'
})
export class DeviceStatusService {

  constructor(private fire: FireService) {
  }

  private static buildDeviceState(venueCfg: VenueConfig, terminalStatuses: TerminalStatus[],
                                  printerStatuses: PrinterStatus[], clientStatuses: ClientStatus[]): DeviceState {
    const ds = new DeviceState();
    DeviceStatusService.extractClientsAndAgents(ds, venueCfg, terminalStatuses, printerStatuses, clientStatuses);
    ds.sharedDevices = DeviceStatusService.extractSharedDevices(ds, venueCfg, terminalStatuses, printerStatuses, clientStatuses);
    return ds;
  }

  private static extractClientsAndAgents(ds: DeviceState, venueCfg: VenueConfig, terminalStatuses: TerminalStatus[],
                                         printerStatuses: PrinterStatus[], clientStatuses: ClientStatus[]) {
    const allClients = [];
    const allAgents = [];
    const terminalNames = venueCfg.payment?.terminals?.filter(f => f.email_filter != null && f.disabled !== true).map(f => f.email_filter) ?? [];
    const printerNames = venueCfg.epson_printers?.filter(f => f.email_filter != null && f.disabled !== true).map(f => f.email_filter) ?? [];
    const allClientNames = [...new Set([...terminalNames, ...printerNames])];

    // Build clients
    for (const name of allClientNames) {
      const clientStatus = clientStatuses.find(cs => cs.name === name);
      const client = new DeviceStateClient(name, clientStatus);
      const terminals = venueCfg.payment?.terminals?.filter(f => f.email_filter === name && f.disabled !== true) ?? [];
      client.terminals = terminals.map(t => DeviceStatusService.buildTerminal(t, terminalStatuses) );
      const printers = venueCfg.epson_printers?.filter(f => f.email_filter === name && f.disabled !== true) ?? [];
      client.printers = printers.map(p => DeviceStatusService.buildPrinter(p, printerStatuses) );
      allClients.push(client);
    }

    // Build agents
    const css = clientStatuses
      .filter(cs => allClientNames.indexOf(cs.name) === -1)
      .filter(cs => moment().diff(cs.updated.toDate()) < 7 * 24 * 3600000);
    for (const clientStatus of css) {
      const agent = new DeviceStateClient(clientStatus.name, clientStatus);
      allAgents.push(agent);
    }

    ds.clients = allClients;
    ds.agents = allAgents;
  }

  private static extractSharedDevices(ds: DeviceState, venueCfg: VenueConfig, terminalStatuses: TerminalStatus[],
                                      printerStatuses: PrinterStatus[], clientStatuses: ClientStatus[]): DeviceStatePrinter[] {
    const sharedPrinters = venueCfg.epson_printers?.filter(f => f.email_filter == null) ?? [];
    return sharedPrinters.map(p => DeviceStatusService.buildPrinter(p, printerStatuses) );
  }

  private static buildTerminal(termCfg: POSTerminalConfig, terminalStatuses: TerminalStatus[]): DeviceStateTerminal {
    const ts = terminalStatuses.find( t => t.terminalName === termCfg.name);
    const dst = new DeviceStateTerminal(termCfg, ts);
    return dst;
  }

  private static buildPrinter(printerCfg: PrinterConfig, printerStatuses: PrinterStatus[]): DeviceStatePrinter {
    const pr = printerStatuses.find( t => t.name === printerCfg.name);
    const dsp = new DeviceStatePrinter(printerCfg, pr);
    return dsp;
  }

  observeDeviceStatusForVenue(venueId: number): Observable<DeviceState> {
    return combineLatest([
      this.fire.observeVenueConfig(venueId),
      this.fire.observeTerminalStatus(venueId),
      this.fire.observePrinterStatus(venueId),
      this.fire.observeClientStatus(venueId)]
    ).pipe(
      map<[VenueConfig, TerminalStatus[], PrinterStatus[], ClientStatus[]], DeviceState>(res => {
        return DeviceStatusService.buildDeviceState(res[0], res[1], res[2], res[3]);
      })
    );
  }

}
