import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {QueryReceiptRequest, VenueService} from "../../../services/venue.service";
import {MatSort, MatSortable} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {DatePipe} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import * as moment from 'moment';
import {combineLatest, Subscription} from "rxjs";
import {FireService} from "../../../services/fire.service";
import {VenueConfig} from "../../../models/venue-config";
import {QueryCustomerDataResponse, QueryCustomerRow} from "../../../models/reporting";
import {first} from "rxjs/operators";
import {LocalFileUtils, HackUtils} from "../../../utils/utils";
import { SimpleDialogComponent } from '../../simple-dialog/simple-dialog.component';


@Component({
  selector: 'app-report-customer',
  templateUrl: './report-customer.component.html',
  styleUrls: ['./report-customer.component.css']
})
export class ReportCustomerComponent implements OnInit, OnDestroy {
  private paramSub: Subscription;
  private query: string;
  private config: VenueConfig;
  private response: QueryCustomerDataResponse;
  title = "Försäljning per kund";

  constructor(private venueService: VenueService, private route: ActivatedRoute, public datePipe: DatePipe,
              private dialog: MatDialog, private fire: FireService) {
  }

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns = ["name", "sales", "vat", "orders"];

  @Input() venueId: string;
  venueIdNum: number;
  receiptKey: string;
  searchStartDate: Date;
  searchEndDate: Date;
  filter: string;
  filterList: string[] = [];
  dataSource: MatTableDataSource<QueryCustomerRow>;
  noData = false;
  initData = true;
  isMobile: boolean;

  ngOnInit(): void {
    this.isMobile = this.isMobile = HackUtils.isMobile() && window.innerWidth <= 768;
    this.sort.sort(({ id: 'name', start: 'asc'}) as MatSortable);
    this.paramSub = combineLatest([
        this.route.paramMap,
        this.route.queryParamMap
      ]
    ).subscribe(([param, query]) => {
      const q = query.get("q");
      const venueId = param.get("venue_id");
      this.venueId = venueId;
      this.venueIdNum = Number(venueId);
      this.query = q;
      // if (shouldFetch && this.query !== "search") {
      //   this.setupInitialSort(q);
      //   this.fetchReceipts();
      // }
    });
  }

  ngOnDestroy(): void {
    this.paramSub?.unsubscribe();
  }

  fetch() {
    this.initData = false;
    this.noData = false;
    const request = this.buildRequest();
    this.venueService.queryCustomerData(request).then(r => {
        if (r != null && r.rows != null) {
          this.response = r;
          this.dataSource = new MatTableDataSource(r.rows as QueryCustomerRow[]);
          this.dataSource.sort = this.sort;
          this.noData = r.rows.length === 0;
        } else {
          this.dataSource = new MatTableDataSource([]);
        }
      }).catch(e => {
        SimpleDialogComponent.showErr(this.dialog, e);
        console.error(e);
      });
  }

  private buildRequest() {
    const request = new QueryReceiptRequest();
    request.venue_id = this.venueId;

    if (this.searchStartDate != null) {
      request.start_date = this.datePipe.transform(this.searchStartDate, 'yyyy-MM-dd');
    }
    if (this.searchEndDate != null) {
      request.end_date = this.datePipe.transform(this.searchEndDate, 'yyyy-MM-dd');
    }
    if (this.filter != null) {
      request.filter = this.filter;
    }
    return request;
  }

  clearFilters() {
    this.searchStartDate = undefined;
    this.searchEndDate = undefined;
    this.filter = undefined;
    this.filterList = [];
  }

  async export(format: string) {
    const data = this.buildDataArrayWithHeader();
    console.log(data);
    const config = await this.fire.observeVenueConfig(Number(this.venueId)).pipe(first()).toPromise();
    const dates = LocalFileUtils.createDateFileName(this.response.params.start_date, this.response.params.end_date);
    const filename = `customer_report_${config.venue.name.replace(/\\s/g, "_")}_${dates}.csv`;
    LocalFileUtils.createFileWithBOM(data, filename);
  }

  select(row: any) {
    console.log(row);
  }

  private setupInitialSort(q: string) {
    this.clearFilters();
    switch (q) {
      case "today":
        this.searchStartDate = moment().toDate();
        break;
      case "yesterday":
        this.searchStartDate = moment().subtract(1, "day").toDate();
        break;
      case "all":
        this.searchEndDate = moment().toDate();
        break;
      case "search":
        break;
    }
  }

  private buildDataArrayWithHeader() {
    const data = [];
    const header = [{col: "org_number", title: "org.nummer"}, {col: "name", title: "namn"}, {col: "sales_inc_vat", title: "inkl.moms"}, {col: "vat_amount", title: "moms"}, {col: "orders", title: "antal"}];
    return LocalFileUtils.compileDataIntoCSV(header, this.response.rows);
  }
}
