<div>
  <p>
    <mat-toolbar>
      <span>Venue crons</span>
      <span class="spacer"></span>
      <button mat-icon-button (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </button>
    </mat-toolbar>
  </p>
</div>

<div class="container">

  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="when">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>when</th>
      <td mat-cell *matCellDef="let element">{{element.when}}</td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>type</th>
      <td mat-cell *matCellDef="let element">{{element.type}}</td>
    </ng-container>
    <ng-container matColumnDef="next_run">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>next_run</th>
      <td mat-cell *matCellDef="let element">{{element.next_run}}</td>
    </ng-container>
    <ng-container matColumnDef="last_run">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>last_run</th>
      <td mat-cell *matCellDef="let element">{{element.last_run}}</td>
    </ng-container>
    <ng-container matColumnDef="url_kwargs">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>url_kwargs</th>
      <td mat-cell *matCellDef="let element">{{element.url_kwargs}}</td>
    </ng-container>
    <ng-container matColumnDef="data">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>data</th>
      <td mat-cell *matCellDef="let element">{{element.data}}</td>
    </ng-container>
    <ng-container matColumnDef="run">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>action</th>
      <td mat-cell *matCellDef="let element"><button mat-flat-button color="primary" (click)="runCron(element.key)">RUN</button></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

