<h2 mat-dialog-title>{{title}}</h2>
  <div mat-dialog-content>
    <div class="container">
      <div class="section" *ngFor="let sec of sections" (click)="selectSection(sec)" style="color: primary" [ngClass]="{'highlight':sec.id == selected}">{{sec.name}}</div>
    </div>
    <div class="container">
      <button mat-flat-button (click)="moveBack()" [disabled]="!back" color="primary">&#8592;</button>
      <button mat-flat-button (click)="moveForward()" [disabled]="!forward" color="primary">&#8594;</button>
    </div>
  </div>
<mat-dialog-actions align="end">
  <!-- <button mat-button mat-dialog-close>Avbryt</button> -->
  <button mat-button (click)="ok()">{{button}}</button>
</mat-dialog-actions>
