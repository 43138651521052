import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Params, Router} from "@angular/router";
import {QueryReceiptRequest, VenueService} from "../../../services/venue.service";
import {MatSort, MatSortable} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {DatePipe} from "@angular/common";
import {combineLatest, Subscription} from "rxjs";
import {FireService} from "../../../services/fire.service";
import {QueryStaffDataResponse, QueryStaffRow} from "../../../models/reporting";
import {first} from "rxjs/operators";
import {HackUtils, LocalFileUtils} from "../../../utils/utils";
import * as moment from "moment";
import Utils from 'src/app/common/utils';
import { SimpleDialogComponent } from '../../simple-dialog/simple-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-report-staff',
  templateUrl: './report-staff.component.html',
  styleUrls: ['./report-staff.component.css']
})
export class ReportStaffComponent implements OnInit, OnDestroy {
  private paramSub: Subscription;
  private query: string;
  private response: QueryStaffDataResponse;
  public isMobile: boolean;
  title = "Impact Rapport (beta)";

  constructor(private venueService: VenueService, private route: ActivatedRoute, public datePipe: DatePipe,
              private router: Router, private fire: FireService, private dialog: MatDialog) {
  }

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns = [
    "name",
    "crew_id",
    "sales_vat",
    "direct_sell_count",
    "direct_sell_amount",
    "tip",
    "navigate_to"
  ];

  @Input() venueId: string;
  venueIdNum: number;
  searchStartDate: Date;
  searchEndDate: Date;
  dataSource: MatTableDataSource<QueryStaffRow>;
  noData = false;
  initData = true;

  ngOnInit(): void {
    this.isMobile = HackUtils.isMobile() && window.innerWidth <= 768;
    if (this.isMobile) {
      this.displayedColumns = ["name", "sales_vat", "direct_sell_count_mobile", "direct_sell_amount", "tip", "navigate_to"];
    } else {
      this.displayedColumns = ["name", "crew_id", "sales_vat", "direct_sell_count", "direct_sell_amount", "tip", "navigate_to"];
    }
    this.sort.sort(({ id: 'name', start: 'asc'}) as MatSortable);
    this.paramSub = combineLatest([
        this.route.paramMap,
        this.route.queryParamMap
      ]
    ).subscribe(([param, query]) => {
      this.query = query.get("q");
      const venueId = param.get("venue_id");
      this.venueId = venueId;
      this.venueIdNum = Number(venueId);
      this.parseQueryParams(query);
      if (this.query !== "search") {
        this.fetch();
      }
    });
  }

  ngOnDestroy(): void {
    this.paramSub?.unsubscribe();
  }

  private parseQueryParams(query: ParamMap) {
    this.clearFilters();
    this.query = query.get("q");
    if (this.query) {
      this.setupInitialParams(this.query);
    } else {
      const sd = query.get("start_date");
      const ed = query.get("end_date");
      if (sd) {
        this.searchStartDate = Utils.dateKeyToMoment(sd).toDate();
      }
      if (ed) {
        this.searchEndDate = Utils.dateKeyToMoment(ed).toDate();
      }
    }
  }

  private updateParams() {
    const queryParams: Params = {};
    if (this.searchStartDate != null) {
      queryParams.start_date = Utils.dateToDateKey(this.searchStartDate);
    }
    if (this.searchEndDate != null) {
      queryParams.end_date = Utils.dateToDateKey(this.searchEndDate);
    }
    this.router.navigate([], { relativeTo: this.route, queryParams });
  }

  fetch() {
    this.initData = false;
    this.noData = false;
    this.updateParams();
    const request = this.buildRequest();
    this.venueService.queryStaffSummary(request).then(r => {
      console.log("queryStaffSummary", r);
      if (r != null && r.rows != null) {
        this.response = r;
        this.dataSource = new MatTableDataSource(r.rows as QueryStaffRow[]);
        this.dataSource.sort = this.sort;
        this.noData = r.rows.length === 0;
      } else {
        this.dataSource = new MatTableDataSource([]);
      }
    }).catch(e => {
      SimpleDialogComponent.showErr(this.dialog, e);
      console.error(e);
    });
  }

  private setupInitialParams(q: string) {
    switch (q) {
      case "today":
        this.searchStartDate = moment().toDate();
        this.fetch();
        break;
      case "yesterday":
        this.searchStartDate = moment().subtract(1, "day").toDate();
        this.fetch();
        break;
      case "search":
        break;
    }
  }

  private buildRequest() {
    const request = new QueryReceiptRequest();
    request.venue_id = this.venueId;

    if (this.searchStartDate != null) {
      request.start_date = this.datePipe.transform(this.searchStartDate, 'yyyy-MM-dd');
    }
    if (this.searchEndDate != null) {
      request.end_date = this.datePipe.transform(this.searchEndDate, 'yyyy-MM-dd');
    }
    return request;
  }

  clearFilters() {
    this.searchStartDate = undefined;
    this.searchEndDate = undefined;
    this.dataSource = new MatTableDataSource([]);
  }

  async export(format: string) {
    const data = this.buildDataArrayWithHeader();
    console.log(data);
    const config = await this.fire.observeVenueConfig(Number(this.venueId)).pipe(first()).toPromise();
    const dates = LocalFileUtils.createDateFileName(this.response.params.start_date, this.response.params.end_date);
    const filename = `staff_report_${config.venue.name.replace(/\\s/g, "_")}_${dates}.csv`;
    LocalFileUtils.createFileWithBOM(data, filename);
  }

  select(row: any) {
    console.log(row);
  }

  redirect(row: any, action: string) {
    const queryParams: Params = {};
    if (this.searchStartDate != null) {
      queryParams.start_date = Utils.dateToDateKey(this.searchStartDate);
    }
    if (this.searchEndDate != null) {
      queryParams.end_date = Utils.dateToDateKey(this.searchEndDate);
    }
    if (row.crew_id != null) {
      queryParams.staff_id = row.crew_id;
    }
    switch (action) {
      case "events":
        this.router.navigate([`/venue/${this.venueId}/reports/events`], { queryParams });
        break;
      case "receipts":
        this.router.navigate([`/venue/${this.venueId}/receipt-list`], { queryParams });
        break;
      case "sreport":
        this.router.navigate([`/venue/${this.venueId}/reports/checkins`], { queryParams });
        break;
    }
  }

  private buildDataArrayWithHeader() {
    const data = [];
    const header = [
      {col: "name", title: "namn"},
      {col: "crew_id", title: "personal.id"},
      {col: "tip", title: "dricks"},
      {col: "direct_sell_count", title: "direktförsäljning"},
      {col: "sales", title: "ex.moms"},
      {col: "sales_vat", title: "inkl.moms"}
    ];
    return LocalFileUtils.compileDataIntoCSV(header, this.response.rows);
  }

  openArticleList() {
    const qp = {
      group_by: "seller"
    };
    if (this.searchStartDate != null) {
      // @ts-ignore
      qp.start_date = this.datePipe.transform(this.searchStartDate, 'yyyy-MM-dd');
    }
    if (this.searchEndDate != null) {
      // @ts-ignore
      qp.end_date = this.datePipe.transform(this.searchEndDate, 'yyyy-MM-dd');
    }
    this.router.navigate([`/venue/${this.venueId}/reports/tree`], {queryParams: qp});
  }

}
