import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettlementData } from 'src/app/models/settlement';
import { SettlementService } from 'src/app/services/settlement.service';
import Utils from 'src/app/common/utils';


@Component({
  selector: 'app-new-settlement',
  templateUrl: './new-settlement.component.html',
  styleUrls: ['./new-settlement.component.css']
})
export class NewSettlementComponent implements OnInit {

  displayedColumns = ["name", "z-report-sales", "client-record-sales", "z-report-receipts", "client-records", "transaction-cost", "payout"];
  dataSource: MatTableDataSource<SettlementData>;
  date: Date;

  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  constructor(public settlementService: SettlementService, private snackBar: MatSnackBar) { }

  public fetchSettlementData(): void {
    this.settlementService.getNewSettlements(this.date).then(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
    }).catch(error => this.snackBar.open(`Faild to load settlement data! ${error.error_message}`, "close"));
  }

  public createSettlements(): void {
    const settlements = this.dataSource.filteredData;
    this.settlementService.createSettlements(this.date, settlements)
    .then(r => this.snackBar.open(`Settlements created for date: ${Utils.dateToDateKey(this.date)}`, "", {duration: 2000}))
    // eslint-disable-next-line max-len
    .catch(r => this.snackBar.open(`Faild to create Settlements for date: ${Utils.dateToDateKey(this.date)}!  ${r.error.error_message}`, "close"));
  }


  ngOnInit(): void {
    this.date = new Date();
    this.settlementService.getNewSettlements(new Date(Date.now())).then(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
    });
  }

}

