<div class="receipts-container">

  <div class="generic-mastdet">
    <div class="generic-master">
      <table mat-table [dataSource]="dataSource" matSort class="clicktable table-cells">

        <ng-container matColumnDef="paid_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
          <td mat-cell
              *matCellDef="let element"> {{ element.paid_date |date:'medium' }} </td>
        </ng-container>

        <ng-container matColumnDef="serial_number">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Serienummer</th>
          <td mat-cell *matCellDef="let element">{{ element.serial_number }}</td>
        </ng-container>

        <ng-container matColumnDef="kind">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Typ</th>
          <td mat-cell *matCellDef="let element"> {{ element.kind }} </td>
        </ng-container>

        <ng-container matColumnDef="total_amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Summa</th>
          <td mat-cell *matCellDef="let element"> {{ element.total_amount | moneySuff : null : "kr"}} </td>
        </ng-container>

        <ng-container matColumnDef="total_order">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Orderbelopp</th>
          <td mat-cell *matCellDef="let element"> {{ element.total_order | moneySuff : null : "kr"}} </td>
        </ng-container>

        <ng-container matColumnDef="total_tip">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Dricks</th>
          <td mat-cell *matCellDef="let element"> {{ element.total_tip | moneySuff : null : "kr"}} </td>
        </ng-container>

        <ng-container matColumnDef="total_vat">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Moms</th>
          <td mat-cell *matCellDef="let element"> {{ element.total_vat | number : '1.2' }} </td>
        </ng-container>

        <ng-container matColumnDef="table">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>table</th>
          <td mat-cell *matCellDef="let element">{{ element.table }}</td>
        </ng-container>

        <ng-container matColumnDef="method">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> method</th>
          <td mat-cell *matCellDef="let element"> {{ element.payment_method }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)" [class.selected-row]="selectedRow === row.key"></tr>
      </table>
    </div>
    <div *ngIf="receiptKey" class="generic-detail">
      <div class="lr-detail-container">
        <app-receipt-view [venueId]="+venueId" [receiptKey]="receiptKey" [isSubComponent]="false" [showActionsBar]="true" [refundId]="+refundId"></app-receipt-view>
      </div>
    </div>

  </div>

</div>

