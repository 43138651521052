import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {WorkflowClass} from "../../../../models/workflow";
import {WfStepComponent} from "../wf-step/wf-step.component";
import {DataFormSchema} from "../../../../models/data-form";
import {DataFormComponent} from "../../../data-form/data-form.component";
import {NodeUtils} from "../../../../utils/utils";
import Utils from "../../../../common/utils";

@Component({
  selector: 'app-wf-class',
  templateUrl: './wf-class.component.html',
  styleUrls: ['./wf-class.component.css']
})
export class WfClassComponent implements OnInit, AfterViewInit{

  @Input() wfClass: WorkflowClass;
  @ViewChildren(WfStepComponent) wfSteps: QueryList<WfStepComponent>;
  editSettings = false;
  @ViewChild("dataform") dataForm: DataFormComponent;
  @Output() workflowDelete = new EventEmitter<any>();
  @Output() workflowCopy = new EventEmitter<any>();

  dataFormSchema: DataFormSchema = {
    title: {sv: "WF settings"},
    desc: {sv: ""},
    properties: [
      {key: "active", title: {sv: "Aktivt"}, desc: {sv: "Ange om workflow skall vara aktivt"}, type: "bool", optional: false},
      {key: "name", title: {sv: "Namn"}, desc: {sv: "Workflow namn (spara för att uppdatera)"}, type: "string", optional: false},
      {key: "desc", title: {sv: "Beskrivning"}, desc: {sv: "Fritext som beskriver workflow (frivilligt)"}, type: "string", optional: true},
      {key: "order_filter", title: {sv: "Order filter"}, desc: {sv: "Ange vilka ordrar som skall köra workflow"}, type: "string", optional: true},
      {key: "when", title: {sv: "When"}, desc: {sv: "Under vilka tider skall workflow vara aktivit"}, type: "string", optional: true},
    ]
  };

  ngOnInit(): void {
    this.editSettings = this.wfClass.order_filter == null;
  }

  ngAfterViewInit(): void {
    console.log("------------------------------");
    console.log(this.dataForm);
  }

  // tslint:disable-next-line:no-shadowed-variable
  addNewStep(action: string) {
    const index = this.wfClass.steps.length;
    const uid = Utils.randomString(10);
    const name = `${action}_${uid}`;
    const step = {action, index, active: true, name};
    if (action === "bong") {
      // tslint:disable-next-line:no-string-literal
      step["filter"] = "ic=food|drink";
    }
    this.wfClass.steps.push(step);
  }

  makeCopy(index: number) {
    // Make a copy of the step at index
    //this.collectData();
    console.log("class name:", this.wfClass.name);
    console.log("steps: ", this.wfSteps.length);
    const wfStepComponent = this.wfSteps.get(index);
    const data = wfStepComponent.collectStepData();
    const uid = Utils.randomString(10);
    data.name = `${data.action}_${uid}`;
    console.log("makeCopy", data);
    this.wfClass.steps.splice(index + 1, 0, data);
    this.renumberSteps();
  }

  moveUp(i: number) {
    console.log("moveUp", i);
    if (i > 0) {
      const tmp = this.wfClass.steps[i - 1];
      this.wfClass.steps[i - 1] = this.wfClass.steps[i];
      this.wfClass.steps[i] = tmp;
      this.renumberSteps();
    }
  }

  moveDown(i: number) {
    console.log("moveDown", i);
    if (i < this.wfClass.steps.length - 1) {
      const tmp = this.wfClass.steps[i + 1];
      this.wfClass.steps[i + 1] = this.wfClass.steps[i];
      this.wfClass.steps[i] = tmp;
      this.renumberSteps();
    }
  }

  deleteStep(i: number) {
    console.log("deleteStep", i);
    this.wfClass.steps.splice(i, 1);
    this.renumberSteps();
  }

  collectData() {
    // Loop through all UI elements and collect data
    const steps = {};
    this.wfSteps.forEach((stepComponent: WfStepComponent) => {
      const dataFromStep = stepComponent.collectStepData();
      const name = dataFromStep.name;
      delete dataFromStep.name;
      steps[name] = NodeUtils.clean(dataFromStep);
    });
    const data = this.dataForm?.collectData() ?? this.wfClass;
    console.log("collectData", data);
    return NodeUtils.clean({
      active: data.active,
      name: this._cleanWorkflowName(data.name),
      desc: data.desc,
      order_filter: data.order_filter,
      when: data.when,
      steps
    });
  }

  private _cleanWorkflowName(name) {
    return name.replace(/[^a-z0-9]/gi, "_").toLowerCase();
  }

  private renumberSteps() {
    for (let i = 0; i < this.wfClass.steps.length; i++) {
      this.wfClass.steps[i].index = i;
    }
  }

  deleteWorkflow() {
    this.workflowDelete.emit(this.wfClass.name);
  }

  copyWorkflow() {
    this.workflowCopy.emit(this.wfClass.name);
  }

  closeSettings() {
    const d = this.dataForm.collectData();
    console.log("closeSettings", d);
    this.wfClass.when = d.when;
    this.wfClass.order_filter = d.order_filter;
    this.editSettings = false;
  }

  toggle() {
    this.editSettings = !this.editSettings;
  }
}
