import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { ClientRecord } from '../models/client-record';
import { inject } from '@angular/core';
import { environment } from 'src/environments/environment';

export class AuthorizedHttpService {
  private http = inject(HttpClient);
  private authService = inject(AuthService);

  private baseUrl() {
    return environment.base_url;
  }
  public async get<T>(endpointUrl: string, params?: URLSearchParams): Promise<T> {
    const base_url = this.baseUrl();
    const url = `${base_url}/${endpointUrl}` + (params ? "?" + params : "");
    return this.getOptions().pipe(flatMap(o => this.http.get<T>(url, o) as unknown as Observable<T>)).toPromise();
  }

  public async put<T>(endpointUrl: string, params?: URLSearchParams, headers?: HttpHeaders, body?: any): Promise<T> {
    const base_url = this.baseUrl();
    const url = `${base_url}/${endpointUrl}` + (params ? "?" + params : "");
    return this.getOptions().pipe(flatMap(o => this.http.put<T>(url, body, o) as unknown as Observable<T>)).toPromise();
  }

  public async post<T>(endpointUrl: string, body?: any): Promise<T> {
    const base_url = this.baseUrl();
    const url = `${base_url}/${endpointUrl}`;
    return this.getOptions().pipe(flatMap(o => this.http.post<T>(url, body, o) as unknown as Observable<T>)).toPromise();
  }

  public postO<T>(endpointUrl: string, body?: any): Observable<T> {
    const base_url = this.baseUrl();
    const url = `${base_url}/${endpointUrl}`;
    return this.getOptions().pipe(flatMap(o => this.http.post<T>(url, body, o) as unknown as Observable<T>));
  }

  // eslint-disable-next-line max-len
  public async downloadFile(endpointUrl: string, params?: URLSearchParams, headers?: HttpHeaders): Promise<void> {

    const token = await this.authService.currentJwtToken().toPromise();
    headers = headers || new HttpHeaders();
    headers = headers.set("Authorization", token);
    const base_url = this.baseUrl();
    const url = `${base_url}/${endpointUrl}` + (params ? "?" + params : "");

    return new Promise((resolve, reject) => {
      this.http.get<Blob>(url, { headers, observe: "response", responseType: 'blob' as 'json' }).subscribe(
        (response: HttpResponse<Blob>) => {
          const binaryData = [];
          binaryData.push(response.body);
          const downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: "blob" }));
          const filename = this.getFileName(response);
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
          resolve();
        },
        (error => reject(error))
      );
    });
  }
  public async getClientRecords(): Promise<ClientRecord[]> {
    return this.get<ClientRecord[]>("api/silversmith/get_client_records");
  }
  public downloadFileP(endpointUrl: string, body: any, filename: string): Observable<any> {
    const base_url = this.baseUrl();
    const url = `${base_url}/${endpointUrl}`;
    return this.getBlobOptions().pipe(
      flatMap(o => this.http.post<Blob>(url, body, o)),
      tap((response: HttpResponse<Blob>) => {
        const binaryData = [];
        binaryData.push(response.body);
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: "blob" }));
        // const filename = this.getFileName(response);
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      })
    );
  }

  private getFileName(response: HttpResponse<Blob>): string {
    let filename: string;
    try {
      const contentDisposition: string = response.headers.get("content-disposition");
      filename = contentDisposition.split("filename=")[1];
    } catch (e) {
      filename = "myfile.txt";
    }
    return filename;
  }

  private getOptions(): Observable<any> {
    return this.authService.currentJwtToken().pipe(map(t => {
      const headers = new HttpHeaders({ Authorization: t });
      return { headers, observe: "body", responseType: 'json' };
    }));
  }

  private getBlobOptions(): Observable<any> {
    return this.getOptions().pipe(map(t => {
      t.observe = "response";
      t.responseType = 'blob' as 'json';
      return t;
    }));
  }

}
