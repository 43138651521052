import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";

@Component({
  selector: 'app-embedded-menu',
  templateUrl: './embedded-menu.component.html',
  styleUrls: ['./embedded-menu.component.css']
})
export class EmbeddedMenuComponent implements OnInit {
  venueId: number;
  iframeUrl: SafeUrl;

  constructor(private route: ActivatedRoute, public dialog: MatDialog, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      const url = `${environment.collins_url}/${this.venueId}/`;
      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    });
  }

}
