import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { VenueService } from "src/app/services/venue.service";
import { MatTableDataSource } from "@angular/material/table";
import { MatSort, MatSortable } from "@angular/material/sort";
import { FbBot } from 'src/app/models/fb-bots';

@Component({
  selector: 'app-fb-bot-list',
  templateUrl: './fb-bot-list.component.html',
  styleUrls: ['./fb-bot-list.component.css']
})
export class FbBotListComponent implements OnInit, OnDestroy {

  constructor(public dialog: MatDialog, private venueService: VenueService,
    private router: Router) { }

  displayedColumns = ["display_name", "venue_id", "app_id", "page_id", "action"];
  dataSource: MatTableDataSource<FbBot>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit(): void {
    this.sort.sort(({ id: 'display_name', start: 'asc' }) as MatSortable);
    this.fetchBots();
  }

  ngOnDestroy(): void {
  }

  private fetchBots() {
    this.venueService.fetchFbBots().then(bots => {
      console.log(bots);
      this.dataSource = new MatTableDataSource(bots as FbBot[]);
      this.dataSource.sort = this.sort;
    });
  }

  addBot() {
    this.router.navigate(["/fb-bots/bot", "new:"]);
  }

  editBot(bot: FbBot) {
    this.router.navigate(["/fb-bots/bot", bot.id]);
  }

  duplicateBot(bot: FbBot) {

  }

}
