import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { combineLatest, Subscription } from 'rxjs';
import Utils from 'src/app/common/utils';
import { QueryEventsRequest, Event } from 'src/app/models/reporting';
import { Admin } from 'src/app/models/user';
import { VenueService } from 'src/app/services/venue.service';
import { SimpleDialogComponent } from '../../simple-dialog/simple-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-report-events',
  templateUrl: './report-events.component.html',
  styleUrls: ['./report-events.component.css']
})
export class ReportEventsComponent implements OnInit, OnDestroy {
  private paramSub: Subscription;

  constructor(private venueService: VenueService, private route: ActivatedRoute, public datePipe: DatePipe, private router: Router,
              private dialog: MatDialog
  ) {
  }

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = [
    "date", "staff_name", "staff_identity", "device_user_name", "amount_cent", "table", "action", "desc",
  ];

  title = "Händelser";
  venueId: string;
  staffId: string;
  searchStartDate: Date;
  searchEndDate: Date;
  dataSource: MatTableDataSource<Event>;
  showSpinner = false;
  fullCount: number;
  rowIndex: number;
  selectedRowData: Event;
  jsonData: any;
  staff: Admin[];

  ngOnInit(): void {
    this.paramSub = combineLatest([
        this.route.paramMap,
        this.route.queryParamMap
      ]
    ).subscribe(([param, query]) => {
      const q = query.get("q");
      const venueId = param.get("venue_id");
      this.staffId = query.get("staff_id");
      this.searchStartDate = Date.parse(query.get("start_date")) ? new Date(query.get("start_date")) : null;
      this.searchEndDate = Date.parse(query.get("end_date")) ? new Date(query.get("end_date")) : null;
      this.venueId = venueId;
      this.getStaff();
      this.setupInitialParams(q);
      this.fetchEvents();
    });
  }

  ngOnDestroy(): void {
    this.paramSub?.unsubscribe();
  }

  newQuery() {
    this.paginator.pageIndex = 0;
    this.updateParams();
    this.fetchEvents();
  }

  updateParams() {
    const queryParams: Params = {};
    if (this.searchStartDate != null) {
      queryParams.start_date = Utils.dateToDateKey(this.searchStartDate);
    }
    if (this.searchEndDate != null) {
      queryParams.end_date = Utils.dateToDateKey(this.searchEndDate);
    }
    if (this.staffId != null) {
      queryParams.staff_id = this.staffId;
    }
    this.router.navigate([], { relativeTo: this.route, queryParams });
  }

  fetchEvents() {
    const request = this.buildRequest();
    this.venueService.queryEvents(request).then(r => {
      console.log("Response", r);
      if (r != null && r.rows != null) {
        this.fullCount = r.rows[0]?.full_count != null ? r.rows[0].full_count : 0;
        this.dataSource = new MatTableDataSource(r.rows as Event[]);
        this.dataSource.sort = this.sort;
      } else {
        this.dataSource = new MatTableDataSource([]);
      }
    }).catch(e => {
      SimpleDialogComponent.showErr(this.dialog, e);
      console.error(e);
    });
  }

  private buildRequest() {
    const request = new QueryEventsRequest();
    request.venue_id = this.venueId;
    request.offset = this.getOffset();
    request.limit = this.paginator != null ? this.paginator.pageSize : 30;

    if (this.searchStartDate != null) {
      request.start_date = this.datePipe.transform(this.searchStartDate, 'yyyy-MM-dd');
    }
    if (this.searchEndDate != null) {
      request.end_date = this.datePipe.transform(this.searchEndDate, 'yyyy-MM-dd');
    }
    if (this.staffId != null) {
      request.staff_id = this.staffId;
    }
    return request;
  }

  private getOffset() {
    return this.paginator != null ? this.paginator.pageIndex * this.paginator.pageSize : 0;
  }

  updateEvents() {
    this.rowIndex = -1;
    this.fetchEvents();
  }

  getStaff() {
    this.venueService.fetchStaff(Number(this.venueId)).then(r => {
      this.staff = r;
    });
  }

  getTotalSize() {
    return this.fullCount;
  }

  clearFilters() {
    this.searchStartDate = null;
    this.searchEndDate = null;
    this.staffId = null;
    this.dataSource = new MatTableDataSource([]);
    this.fullCount = 0;
  }

  sortChanged() {
    if (this.venueId) {
      this.dataSource = new MatTableDataSource([] as Event[]);
      this.fetchEvents();
    }
  }

  private setupInitialParams(q: string) {
    switch (q) {
      case "today":
        this.searchStartDate = moment().toDate();
        break;
      case "yesterday":
        this.searchStartDate = moment().subtract(1, "day").toDate();
        break;
      case "search":
        break;
    }
  }

  select(row: any, index: number) {
    if (this.rowIndex === index) {
      this.closeDetailsPanel();
      return;
    }
    this.rowIndex = this.rowIndex === index ? -1 : index;
    this.selectedRowData = row;
    this.jsonData = JSON.parse(row.data_json);
  }

  closeDetailsPanel() {
    this.rowIndex = -1;
    this.jsonData = null;
  }
}
