<div *ngIf="table" class="detail-container">
  <div class="detail-toolbar summary-toolbar">
    <div class="user-icon" *ngFor="let u of summary?.activity">
      <img *ngIf="u.profile_pic" [src]="u.profile_pic" onerror="this.src=''" width="48px" height="48px">
      <mat-icon *ngIf="!u.profile_pic" style="font-size: 32px;padding: 8px;">face</mat-icon>
      <div class="user-alias">{{u.alias}}</div>
    </div>
  </div>
  <div style="height:calc(100% - 0px)">
    <div class="detail-body" style="height:calc(100% - 72px)">
      <div class="small-header">{{table}}</div>
      <div *ngIf="summary">
        <div *ngFor="let row of summary.rows">
          <div *ngIf="row.title" class="summary-title">{{row.title.text}}</div>
          <div *ngIf="row.divider && !row.divider.highlight" class="summary-divider"><div class="summary-divider-line"><div></div></div><div>{{row.divider.text}}</div><div class="summary-divider-line"><div></div></div></div>
          <div *ngIf="row.divider && row.divider.highlight" class="summary-forward-btn" (click)="forward(row)"><div>Fram {{row.divider.text}}</div></div>
          <div *ngIf="row.item" class="summary-item">
            <div style="margin-right: 16px;font-weight: bold;">{{row.item.groupedItems.count()}}</div>
            <div>
              <div>{{row.item.name()}}</div>
              <div *ngIf="row.item.customized()" class="tweak">{{row.item.customized()}}</div>
              <div *ngIf="row.item.comment()" class="tweak">{{row.item.comment()}}</div>
            </div>
            <div style="margin-left: auto;white-space: nowrap;" [ngStyle]="{'color':row.item.priceColor(),'text-decoration': row.item.textDecoration()}">{{row.item.priceFormatted()}}</div>
            <div class="item-user-alias">{{row.item.userName}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="amount-panel">
      <div class="total">{{summary?.price.priceFormatted()}}</div>
      <div class="vat">moms: {{summary?.price.vatFormatted()}}</div>
    </div>
  </div>
<!--  <div class="table-ref" *ngIf="showActions">-->
<!--    <div>Webbkod: <a class="web-code-link" *ngIf="_locationCode" href="{{passeUrl}}{{_locationCode?.code}}" target="_blank">{{_locationCode?.code}}</a></div>-->
<!--  </div>-->
</div>

