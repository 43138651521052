<div>
  <p>
    <mat-toolbar>
      <span>Create invoices</span>
      <span class="spacer"></span>
    </mat-toolbar>
  </p>
</div>
<div style="padding: 16px;">
  <table mat-table [dataSource]="dataSource" matSort>


    <ng-container matColumnDef="venue">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Venue </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.venue}} </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount (kr)</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.amount}} </td>
    </ng-container>

    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Period </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.period}} </td>
    </ng-container>

    <ng-container matColumnDef="checked">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Checked </th>
      <td mat-cell *matCellDef="let row" style="padding: 4px;"><mat-checkbox
      (change)="$event ? selection.toggle(row) : null"
      color="primary"
      [checked]="selection.hasValue()"
      [indeterminate]="selection.hasValue()">
        </mat-checkbox> </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <button mat-flat-button color="primary" [disabled]="selection.selected && this.selection.selected.length === 0" style="margin: 16px;" (click)="createInvoices()">Create invoices</button>
</div>
