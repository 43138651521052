import { Pipe, PipeTransform } from '@angular/core';
import {HackUtils} from "../utils/utils";

@Pipe({
  name: 'amount'
})
export class AmountPipe implements PipeTransform {

  transform(input: any, decimals = 1): unknown {
    if (input == null || Number.isNaN(input)) { return null; }
    let val = input.toFixed(decimals);
    if (val.endsWith(".0")) {
      val = val.substring(0, val.length - 2);
    }
    val = HackUtils.thousandsSepNumber(val).replace(".", ",");
    return `${val}`;
  }

}
