<table mat-table class="clicktable table-cells" [dataSource]="dataSource" matSort matSortDirection="desc" matSortDisableClear="true">
  <ng-container [matColumnDef]="header" *ngFor="let header of displayedColumns; let i = index">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{customTableHeaderName(header) | titlecase}} </th>

    <td mat-cell *matCellDef="let row">
      <ng-container [ngSwitch]="getType(i)">
        <ng-container *ngSwitchCase="'sek'">
          {{ row[header] | moneySuff }}
        </ng-container>
        <ng-container *ngSwitchCase="'count'">
          {{ row[header] | number:'1.0-1'}} st
        </ng-container>
        <ng-container *ngSwitchCase="'date'">
          {{ row[header] | date:'EEEE, d MMMM, y' }}
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{ row[header] }}
        </ng-container>
      </ng-container>
    </td>

    <td mat-footer-cell *matFooterCellDef style="font-weight:bold;">
      <ng-container [ngSwitch]="getType(i)">
        <ng-container *ngSwitchCase="'sek'">
          {{ footers[i] | moneySuff }}
        </ng-container>
        <ng-container *ngSwitchCase="'count'">
          {{ footers[i] | number:'1.0-1'}} st
        </ng-container>
        <ng-container *ngSwitchDefault>
          {{ footers[i] }}
        </ng-container>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
</table>
