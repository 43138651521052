import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {VenueService} from "../../../services/venue.service";
import {LoadingService} from "../../../services/loading.service";
import {AuthService} from "../../../services/auth.service";
import {HackUtils} from "../../../utils/utils";
import {FireService} from "../../../services/fire.service";
import {first} from "rxjs/operators";
import {DiscountProgramConfig, VenueConfig} from "../../../models/venue-config";
import {CreateDiscountCodeRequest} from "../../../models/discount-code";
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-discount-code-dialog',
  templateUrl: './new-discount-code-dialog.component.html',
  styleUrls: ['./new-discount-code-dialog.component.css']
})
export class NewDiscountCodeDialogComponent implements OnInit {
  dp = new UntypedFormControl('', [Validators.required]);
  maxCount = new UntypedFormControl(1, [Validators.required]);
  validDays = new UntypedFormControl(30, [Validators.required]);
  maxUsages = new UntypedFormControl('', []);
  balance = new UntypedFormControl('', []);
  identity: string;
  venueId: number;
  discountPrograms: DiscountProgramConfig[];
  private config: VenueConfig;

  public static openDialog(dialog: MatDialog, venueId: number): MatDialogRef<NewDiscountCodeDialogComponent> {
    const dialogRef = dialog.open(NewDiscountCodeDialogComponent, HackUtils.DLG({data: {venueId}}));
    return dialogRef;
  }

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<NewDiscountCodeDialogComponent>, private auth: AuthService, private fire: FireService,
              private router: Router) {
    this.venueId = data.venueId;
  }

  ngOnInit(): void {
    this.fire.observeVenueConfig(this.venueId).pipe(first()).subscribe(cfg => {
      this.discountPrograms = cfg.discount_programs;
      this.config = cfg;
    });
  }

  createCode() {
    if (this.dp.valid && this.maxCount.valid && this.validDays.valid) {
      const dpId = this.dp.value;
      const maxCount = this.maxCount.value;
      const validDays = this.validDays.value;
      const maxUsages = this.maxUsages.value === "" ? undefined : this.maxUsages.value;
      const balance = this.balance.value === "" ? undefined : this.balance.value * 100;

      const createCode = {discount_program_id: dpId, balance_cent: balance, max_count: maxCount, max_usages: maxUsages, valid_days: validDays} as CreateDiscountCodeRequest;
      console.log(createCode);
      this.venueService.createDiscountCode(this.venueId, createCode).then(res => {
        this.dialogRef.close(res);
      });
    }
  }

  isHeynowAdmin() {
    return AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }

  navigateToDiscountPrograms() {
    this.dialogRef.close();
    this.router.navigateByUrl(`venue/${this.venueId}/settings/discount_programs`);
  }
}
