export const pageCardsSettings = {
  title: { sv: "Kort" },
  page_settings: {
    root_anchor: "cards",
  },
  desc: "Inställningar för kort",
  properties: [
    { key: "cards", title: { sv: "Kort" }, desc: { sv: "" }, type: "table",
      columns: [
        { key: "id", title: { sv: "ID" }, type: "string" },
        { key: "name", title: { sv: "Namn" }, type: "string" },
        { key: "discount_prg", title: { sv: "Rabattprogram" }, type: "string", optional: true },
        { key: "refill_id", title: { sv: "Påfyllnings-ID" }, type: "string", optional: true },
        { key: "type", title: { sv: "Typ" }, type: "string" },
        { key: "card_range", title: { sv: "Kortintervall" }, type: "string", optional: true },
        { key: "validity_months", title: { sv: "Giltlighetstid (månader)" }, type: "integer", optional: true },
        { key: "template", title: { sv: "Digitalmall" }, type: "string", optional: true },
        { key: "magstripe_regex", title: { sv: "Magnetkort regex" }, type: "string", optional: true },
        { key: "void_ic", title: { sv: "Ic för tömmning" }, type: "string", optional: true },
      ]
    },
  ]
};

