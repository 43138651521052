import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { FireService } from "../../services/fire.service";
import { combineLatest, Subscription } from "rxjs";
import {Order, Receipt} from "../../models/order";
import { VenueConfig } from "../../models/venue-config";
import * as moment from 'moment';
import { AuthService } from "../../services/auth.service";
import { VenueService } from 'src/app/services/venue.service';
import { Device } from 'src/app/models/reg-unit';
import {SimpleDialogComponent} from "../simple-dialog/simple-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-live-dashboard',
  templateUrl: './live-dashboard.component.html',
  styleUrls: ['./live-dashboard.component.css']
})
export class LiveDashboardComponent implements OnInit, OnDestroy {
  @Input() venueId: number;
  config: VenueConfig;
  private sub: Subscription;
  unitsNotRegistered: Device[] = [];
  stats = {
    loaded: false,
    total: 0,
    vat: 0,
    order: 0,
    order_exvat: 0,
    tip: 0,
    total_unpaid: 0,
    total_unpaid_exvat: 0,
    expected: 0,
    expected_exvat: 0
  };

  servicesToCheck = [
    { name: "Caspeco", path: 'caspeco', keyExistsWithValue: 'secret' },
    { name: "Fortnox Bookkeeping", path: 'fortnox.bookkeping', criteria: { active: true}},
    { name: "Fortnox Invoice", path: 'fortnox.invoice', criteria: { active: true}},
    { name: "Fortnox Order", path: 'fortnox.order', criteria: { active: true}},
    { name: "Kiosk", path: 'kiosk', printValueIfKeyExists: 'mode' },
    { name: "Microdeb", path: 'microdeb', criteria: { active: true } },
    { name: "Personalkollen", path: 'personalkollen', keyExistsWithValue: 'token' },
    { name: "Softpay", path: 'softpay.settings', criteria: { active: true } },
    { name: "Swish", path: 'swish', criteria: { active: true } },
    { name: "Booking", path: 'booking', criteria: { active: true } },
    { name: "Takeaway Slotting", path: 'takeaway.slotting', criteria: { active: true } },
    { name: "Viva", path: 'viva.settings', criteria: { active: true } },
  ];

  public foundServices = [];

  @ViewChild('canvas', { static: false })
  canvas: ElementRef<HTMLCanvasElement>;
  flash = false;
  animateCoins = false;
  showNewsForSuperAdmin= false;
  isSuperAdmin = false;
  hasCheckedDevices = false;
  isVault = false; // true for chains

  constructor(private route: ActivatedRoute, private fire: FireService,
    private auth: AuthService, private venueService: VenueService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.animateCoins = localStorage.getItem("animateCoins") === "true";
    this.showNewsForSuperAdmin = localStorage.getItem("showNews") === "true";
    if (this.venueId == null) {
      this.route.paramMap.subscribe(data => {
        this.venueId = Number(data.get("venue_id"));
        if (!this.auth.redirectIfMissingPermissions(this.venueId, "dashboard")) {
          return;
        }
        this.beginObserve();
      });
    } else {
      this.beginObserve();
    }
  }

  private checkIfVenueHasUnreggedDevices() {
    if (this.hasCheckedDevices) { return; }
    this.hasCheckedDevices = true;
    this.venueService.fetchDevices(this.venueId).then(r => {
      this.unitsNotRegistered = r.filter(device => device.reg_unit?.state == null || device.reg_unit?.state === "new");
    });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  private beginObserve() {
    this.sub = combineLatest([
      this.fire.observeVenueConfig(this.venueId),
      this.fire.observePaidReceipts(this.venueId),
      this.fire.observeOrders(this.venueId),
    ]
    ).subscribe(res => {
      this.foundServices = [];
      this.isSuperAdmin = AuthService.isSuperAdmin(this.auth.authStateSnapshot);
      const config = res[0];
      this.extractConfigStats(config);
      this.isVault = config.chain != null;
      const receipts = res[1];
      const orders = res[2];
      console.log("CONFIG:", config);
      console.log("RECEIPTS:", receipts);
      this.foundServices = this.checkServices(config, this.servicesToCheck);
      this.sumReceipts(receipts);
      this.sumUnpaidOrders(orders);
      console.log("FOUND_SERVICE", this.foundServices);
      const isProdCCU = config.cash_register?.type === "prod";
      if (isProdCCU && this.hasPerm("devices")) {
        this.checkIfVenueHasUnreggedDevices();
      }
    });
  }

  private sumReceipts(receipts: Receipt[]) {
    // Filter out receipts that are older than 6am today
    const f = moment().startOf("day").add(6, "hour");
    receipts = receipts.filter(r => moment(r.paid_date?.toDate()) > f);

    const prevTotal = this.stats.total;
    let totalAmount = 0;
    let totalVat = 0;
    let totalOrder = 0;
    let totalTip = 0;
    let totalPrepaid = 0;
    for (const receipt of receipts) {
      const pp = receipt.total_prepaid ?? 0;
      if (receipt.is_refund) {
        totalAmount -= receipt.total_amount - pp;
        totalVat -= receipt.total_vat;
        totalOrder -= receipt.total_order - pp;
        totalTip -= receipt.total_tip;
        totalPrepaid -= pp;
      } else {
        totalAmount += receipt.total_amount - pp;
        totalVat += receipt.total_vat;
        totalOrder += receipt.total_order - pp;
        totalTip += receipt.total_tip;
        totalPrepaid += pp;
      }
    }
    this.stats.total = totalAmount;
    this.stats.vat = totalVat;
    this.stats.order = totalOrder;
    this.stats.order_exvat = totalOrder - totalVat;
    this.stats.tip = totalTip;
    this.stats.loaded = true;
    const diff = this.stats.total - prevTotal;
    if (prevTotal > 0 && diff > 0) {
      this.flashAnimate(diff);
    }
  }

  private sumUnpaidOrders(orders: Order[]) {
    const f = moment().startOf("day").add(6, "hour");
    orders = orders.filter(r => moment(r.finalized?.toDate()) > f && r.paid == null && r.canceled == null);

    let totalAmount = 0;
    let totalVat = 0;
    for (const order of orders) {
      if (order.table.startsWith("$")) {
        continue;
      }
      totalAmount += order.total_price;
      totalVat += order.total_vat;
    }
    this.stats.total_unpaid = totalAmount;
    this.stats.total_unpaid_exvat = totalAmount-totalVat;

    this.stats.expected = this.stats.order + this.stats.total_unpaid;
    this.stats.expected_exvat = this.stats.order_exvat + this.stats.total_unpaid_exvat;
  }

  private flashAnimate(diff) {
    this.flash = true;
    setTimeout(() => {
      this.flash = false;
    }, 5000);

    if (this.animateCoins) {
      this.dropCoins(Math.min(diff / 16, 128));
    }
  }

  bamboraCode(): string {
    const d = new Date();
    const y0 = (d.getFullYear() + 1) % 10;
    const y1 = Math.floor(d.getFullYear() / 10 + 1) % 10;
    const m0 = (d.getMonth() + 2) % 10;
    const m1 = Math.floor(((d.getMonth() + 1) / 10) + 1) % 10;
    const d0 = (d.getDate() + 1) % 10;
    const d1 = Math.floor(d.getDate() / 10 + 1) % 10;

    const code = `${y0}${y1}${m0}${m1}${d0}${d1}`;
    return code;
  }

  private extractConfigStats(config: VenueConfig) {
    this.config = config;
  }

  private dropCoins(count) {
    const ctx = this.canvas.nativeElement.getContext('2d');
    let focused = false;
    ctx.canvas.width = 400;
    ctx.canvas.height = window.innerHeight;

    // 440 wide, 40 high, 10 states
    const coin = new Image();
    coin.src = 'https://i.imgur.com/5ZW2MT3.png';
    coin.onload = () => {
      focused = true;
      drawloop();
    };
    const coins = [];
    let frames = 0;
    let coinsLeft = count;

    function drawloop() {
      if (focused) {
        requestAnimationFrame(drawloop);
      }
      frames++;

      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
      if (Math.random() < .3 && coinsLeft > 0) {
        coins.push({
          x: (Math.random() * ctx.canvas.width | 0) - 30,
          y: -50,
          dy: 3,
          s: 0.5 + Math.random(),
          state: Math.random() * 10 | 0
        });
        coinsLeft--;
      }
      let i = coins.length;
      while (i--) {
        const x = coins[i].x;
        const y = coins[i].y;
        const s = coins[i].s;
        const state = coins[i].state;
        coins[i].state = (state > 9) ? 0 : state + 0.1;
        coins[i].dy += 0.3;
        coins[i].y += coins[i].dy;

        ctx.drawImage(coin, 44 * Math.floor(state), 0, 44, 40, x, y, 44 * s, 40 * s);

        if (y > ctx.canvas.height) {
          coins.splice(i, 1);
        }
      }

      if (frames > 100 && coins.length <= 0) {
        focused = false;
      }
    }
  }

  toggleActive() {
    localStorage.setItem("animateCoins", this.animateCoins.toString());
  }

  toggleShowNews() {
    localStorage.setItem("showNews", this.showNewsForSuperAdmin.toString());
  }

  hasPerm(perm: string): boolean {
    return this.auth.hasPermission(this.venueId, perm);
  }

  checkServices(config: any, servicesToCheck: any[]) {
    return servicesToCheck.map(service => {
      const pathKeys = service.path.includes('.') ? service.path.split('.') : [service.path];
      let configService = config;
      for (const key of pathKeys) {
        if (configService === undefined || configService[key] === undefined) {
          return null;
        }
        configService = configService[key];
      }

      if ('criteria' in service) {
        const allCriteriaMatch = Object.keys(service.criteria).every(criteriaKey => {
          const criteriaValue = service.criteria[criteriaKey];
          if (criteriaValue === 'exists') {
            return configService.hasOwnProperty(criteriaKey);
          } else {
            return configService[criteriaKey] === criteriaValue;
          }
        });

        if (allCriteriaMatch) {
          return { name: service.name, status: 'aktiv' };
        }
      }

      if ('printValueIfKeyExists' in service) {
        const keyValue = configService[service.printValueIfKeyExists];
        if (keyValue !== undefined) {
          return { name: service.name, status: keyValue };
        }
      }

      if ('keyExistsWithValue' in service) {
        const keyValue = configService[service.keyExistsWithValue];
        if (keyValue !== undefined && keyValue !== '') {
          return { name: service.name, status: 'aktiv' };
        }
      }

      return null;
    }).filter(result => result !== null)
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  formatText(key: string): string {
    return key.split('.').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  showInfoAboutUnpaid(event) {
    SimpleDialogComponent.openSimpleDialog(this.dialog, {title: "Obetalt", htmlBody: "Obetalt visar en uppskattning av dagens beställningsvärde som ännu inte betalats. Observera att en beställning räknas som obetald tills samtliga artiklar i ordern har betalats. Detta kan leda till viss dubbelräkning under perioder då notor delas. Funktionen är för närvarande i betaversion.", cancelButton: "Stäng"});
    event.preventDefault();
  }
}
