import { Injectable } from '@angular/core';
import {Observable, of} from "rxjs";
import {environment} from "../../environments/environment";
import {flatMap, map} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {QueryReceiptResult} from "../models/query-receipt";
import {GTPQueryResult, IdmInfo, IdmResponse, ImageFetchResult} from "../models/genji-models";

@Injectable({
  providedIn: 'root'
})
export class GenjiService {

  constructor(protected http: HttpClient) { }

  private getOptions(): Observable<any> {
    const headers = new HttpHeaders({Authorization: "123"});
    return of({headers, observe: "body", responseType: 'json'});
  }

  private post<T>(endpointUrl: string, body?: any): Observable<T> {
    const baseUrl = environment.genji_url;
    const url = `${baseUrl}/${endpointUrl}`;
    return this.getOptions().pipe(flatMap(o => this.http.post<T>(url, body, o) as unknown as Observable<T>));
  }

  public fetchImages(venueId?: number, bucketPath?: string): Observable<ImageFetchResult> {
    const body = {venue_id: venueId, bucket_path: bucketPath, action: "fetch_images"};
    return this.post<ImageFetchResult>("facade", body );
  }

  public uploadFile(file: File, venueId?: number, bucketPath?: string): Observable<any> {
    const uploadData = new FormData();
    uploadData.append("image", file, file.name);
    if (venueId) {
      uploadData.append("venue_id", venueId.toString());
    }
    if (bucketPath) {
      uploadData.append("bucket_path", bucketPath);
    }
    uploadData.append("action", "upload_new_image");
    uploadData.append("file_name", file.name);
    const baseUrl = environment.genji_url;
    const url = `${baseUrl}/facade`;
    return this.http.post(url, uploadData);
  }

  public queryGPT3(prompt: string): Observable<GTPQueryResult> {
    const body = {prompt, action: "gpt3"};
    return this.post<GTPQueryResult>("facade", body );
  }

  public ccuStatus(registerId: string): Observable<IdmResponse> {
    const body = {register_id: registerId, action: "ccu_status"};
    return this.post<IdmResponse>("facade", body );
  }

  public ccuOpen(registerId: string): Observable<IdmResponse> {
    const body = {register_id: registerId, action: "ccu_open"};
    return this.post<IdmResponse>("facade", body );
  }

  public ccuClose(registerId: string): Observable<IdmResponse> {
    const body = {register_id: registerId, action: "ccu_close"};
    return this.post<IdmResponse>("facade", body );
  }

  public ccuNew(info: IdmInfo): Observable<IdmResponse> {
    const body = {info, action: "ccu_new"};
    return this.post<IdmResponse>("facade", body );
  }

  public ccuChange(info: IdmInfo): Observable<IdmResponse> {
    const body = {info, action: "ccu_change"};
    return this.post<IdmResponse>("facade", body );
  }

}
