<div>
  <p>
    <mat-toolbar>
      <span>Fb bots</span>
      <span class="spacer"></span>
      <app-wiki-info-link title="Lär dig mer. Fb Bots." section="support" path="fb-bots"></app-wiki-info-link>
      <button mat-button (click)="addBot()">
        <mat-icon>add</mat-icon> Ny bot
      </button>
    </mat-toolbar>
  </p>
</div>
<div style="padding: 16px;">
  <table mat-table [dataSource]="dataSource" matSort matSortDisableClear="true" class="clicktable">

        <ng-container matColumnDef="display_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Visningsnamn </th>
          <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.display_name}} </td>
        </ng-container>

    <ng-container matColumnDef="app_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>App id </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.app_id}} </td>
    </ng-container>

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.id}} </td>
    </ng-container>

    <ng-container matColumnDef="page_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>page id</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.page_id}} </td>
    </ng-container>

    <ng-container matColumnDef="token">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>token</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.token}} </td>
    </ng-container>

    <ng-container matColumnDef="venue_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Venue</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.venue_id}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef style="width:50px"></th>
      <td mat-cell *matCellDef="let element">
        <button mat-button [matMenuTriggerFor]="navigation">Åtgärd <mat-icon>arrow_drop_down</mat-icon></button>
        <mat-menu #navigation>
          <button mat-menu-item (click)="editBot(element)">Redigera</button>
          <!-- <mat-divider></mat-divider>
          <button mat-menu-item (click)="duplicateBot(element)">Skapa kopia</button> -->
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
