<div>
  <p>
    <mat-toolbar>
      <span>Skicka mail till subscriber-lista</span>
      <span class="spacer"></span>
    </mat-toolbar>
  </p>
</div>

<div class="split-container">
  <div style="width: 500px;">
    <app-data-form #dataform [schema]="dataFormSchema" [root]="mailData"></app-data-form>

    <div style="display: flex; flex-direction: row; justify-content: space-between">
      <button mat-raised-button color="primary" type="button" style="margin-left: 20px;" (click)="previewWikiEmail()">Preview</button>
      <button mat-raised-button color="accent" type="submit" style="margin-right: 20px;" (click)="sendWikiEmail()">Send</button>
    </div>

    <div style="margin: 32px 16px;background: #f5f5f5;border-radius: 10px;padding: 16px;font-size: 12px;">
      Mail signatur är:
      <pre>
<b>heynow</b>
https://heynow.ai
support@heynow.ai
+46 (0)10 - 214 60 77
      </pre>
    </div>

  </div>
  <div class="iframe-container">
    <iframe #previewFrame></iframe>
  </div>
</div>

<div *ngIf="sendResult" style="margin: 0 36px 0 36px;border: 1px solid grey; border-radius: 10px; padding: 16px;">{{sendResult}}</div>
