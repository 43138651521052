import {Component, ElementRef, ViewChild} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {VenueService} from "../../../services/venue.service";
import {HeywikiService} from "../../../services/heywiki.service";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {flatMap} from "rxjs/operators";
import {from} from "rxjs";
import {DataFormSchema} from "../../../models/data-form";
import {DataFormComponent} from "../../data-form/data-form.component";

@Component({
  selector: 'app-simple-email-editor',
  templateUrl: './simple-email-editor.component.html',
  styleUrls: ['./simple-email-editor.component.css']
})
export class SimpleEmailEditorComponent {
  dataFormSchema: DataFormSchema = {
    title: {sv: "Api Token settings"},
    desc: {sv: "Ställ in tab"},
    properties: [
      // {key: "email", title: {sv: "E-post"}, desc: {sv: "En eller fler email adresser separerade med komma"}, type: "text", optional: false, visible: true},
      {key: "list_name", title: {sv: "Prenumerationslista"}, desc: {sv: "T.ex. 'newsletter'"}, type: "string", optional: false},
      {key: "sender", title: {sv: "Avsändare"}, type: "string", desc: {sv: "Avsändare (om tom används noreply@heynow.ai)"}, optional: true},
      {key: "subject", title: {sv: "Ämne"}, type: "string", desc: {sv: "Ämnesraden på mailet"}, optional: false},
      {key: "section", title: {sv: "Wiki section"}, type: "string", optional: false},
      {key: "path", title: {sv: "Wiki path"}, type: "string", optional: false},
      {key: "img_style", title: {sv: "Image style"}, type: "enum", enums: [
          {value: "center", title: {sv: "Centrerat"}},
          {value: "fill", title: {sv: "Utfyllt"}},
          {value: "auto", title: {sv: "Auto (bildens originalstorlek)"}},
        ] },
    ]
  };
  mailData = {};

  sendResult;
  preview: SafeHtml;
  @ViewChild('previewFrame') previewFrame: ElementRef;
  @ViewChild("dataform") dataForm: DataFormComponent;

  constructor(private fb: FormBuilder, private venueService: VenueService, private heywiki: HeywikiService, private sanitizer: DomSanitizer) {}

  async sendEmail(preview: boolean = false) {
    const data = this.dataForm.collectData()
    this.sendResult = null;
    this.preview = this.sanitizer.bypassSecurityTrustHtml("<div style='padding:32px;color: grey;'>Fetching...</div>");
    this.heywiki.fetchDoc(data.section, data.path).pipe(flatMap(d =>
      from(this.venueService.sendSimpleEmail(data.email, data.list_name, data.subject, d.html, preview, data.img_style, data.sender))
    )).subscribe(async res => {
      this.loadNewsletterContent(res.html);
    }, e => {
      this.sendResult = e.message;
    });
  }

  async sendWikiEmail() {
    this.sendEmail(false);
    return false;
  }

  async previewWikiEmail() {
    this.sendEmail(true);
    return false;
  }

  loadNewsletterContent(htmlContent: string): void {
    const iframe: HTMLIFrameElement = this.previewFrame.nativeElement;
    const document = iframe.contentDocument || iframe.contentWindow.document;
    document.open();
    document.write(htmlContent);
    document.close();
  }

}
