<div class="tool-bar">
  <mat-slide-toggle class="printer-toggle" [(ngModel)]="showActive" (ngModelChange)="toggleActive()" color="primary">Show active bongs</mat-slide-toggle>
</div>

<div class="bong-container">
  <div class="bong" *ngFor="let bong of bongSummaries">
    <div class="bong-header" [ngStyle]="{'background-color':bong.color()}">
      <div>{{bong.title()}}</div>
      <div>{{bong.subTitle()}}</div>
    </div>
    <div class="bong-content">
      <div *ngIf="bong.orderTags"><div *ngFor="let ot of bong.orderTags">🏷️&nbsp;{{ot.tag}} {{ot.value}}</div></div>
      <div *ngFor="let row of bong.rows">
        <div *ngIf="row.title" class="summary-title">{{row.title}}</div>
        <div *ngIf="row.divider" class="summary-divider"><div class="summary-divider-line"><div></div></div><div>{{row.divider}}</div><div class="summary-divider-line"><div></div></div></div>
        <div *ngIf="row.item" class="summary-item" [ngClass]="{'bong-item-done':row.item.isDone()}">
          <mat-icon *ngIf="row.item.isDone()" style="margin-right: 8px;color:#317370;">done</mat-icon>
          <div style="margin-right: 16px;font-weight: bold;">{{row.item.count()}}</div>
          <div>
            <div>{{row.item.name()}}</div>
            <div *ngIf="row.item.customized()" class="tweak" [ngClass]="{'bong-item-done':row.item.isDone()}">{{row.item.customized()}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

