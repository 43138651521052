import * as moment from "moment";
import {Bong, BongItem} from "../models/kds-bong";
import {ICParentLookup} from "./utils";

interface FilterItemAccessor<itemType, parentType> {
  itemIC?: (i: itemType, p: parentType) => string | null;
  itemMenuTags?: (i: itemType, p: parentType) => string[] | null;
  itemPhase?: (i: itemType, p: parentType) => string | null;
  itemAttributes?: (i: itemType, p: parentType) => string[] | null;
  itemTable?: (i: itemType, p: parentType) => string | null;
  itemArea?: (i: itemType, p: parentType) => string | null;
  itemDevice?: (i: itemType, p: parentType) => string | null;
  itemDeliverType?: (i: itemType, p: parentType) => string | null;
  itemOrderTags?: (i: itemType, p: parentType) => string[] | null;
  itemState: (i: itemType, p: parentType) => string | null;
  itemUpdatedAgeSeconds: (i: itemType, p: parentType) => number | null;
}


export const bongFilterItemAccessor: FilterItemAccessor<BongItem, Bong> = {
  itemIC: (i: BongItem, p: Bong): string | null => {
    return i.ic;
  },

  itemMenuTags: (i: BongItem, p: Bong): string[] | null => {
    return i.tags;
  },

  itemPhase: (i: BongItem, p: Bong): string | null => {
    return "not implemented";
  },

  itemAttributes: (i: BongItem, p: Bong): string[] | null => {
    return i.attributes?.map ( a => a.title );
  },

  itemTable: (i: BongItem, p: Bong): string | null => {
    return p.table;
  },

  itemArea: (i: BongItem, p: Bong): string | null => {
    return p.area;
  },

  itemDevice: (i: BongItem, p: Bong): string | null => {
    return p.device;
  },

  itemDeliverType: (i: BongItem, p: Bong): string | null => {
    return p.deliver_type;
  },

  itemOrderTags: (i: BongItem, p: Bong): string[] | null => {
    return p.orderTags?.map( o => o.tag);
  },
  itemState: (i: BongItem, p: Bong): string | null => {
    return i.state;
  },
  itemUpdatedAgeSeconds: (i: BongItem, p: Bong): number | null => {
    const time = moment().unix();
    let updated = p.updated;
    if(i.updated !== undefined) {
      updated = i.updated;
      //console.log("accessor item seconds", p.order_numbers, i.updated.seconds, p.updated.seconds);
    }
    return time - updated.seconds;
  }
};

export class Filter<itemType, parentType> {
  private expressions: FilterExpression[];
  private accessor: FilterItemAccessor<itemType, parentType>;
  constructor(expressions: FilterExpression[], accessor: FilterItemAccessor<itemType, parentType>) {
    this.expressions = expressions;
    this.accessor = accessor;
  }

  itemMatches<T>(items: any[], props: any, ic: ICParentLookup): T[] {
    const result = [];
    for(const item of items) {
      for(const expr of this.expressions) {
        if(expr.matches(this.accessor, item, props, ic)) {
          result.push(item);
          break;
        }
      }
    }
    return result;
  }

  // Optimized version that only checks *if* a bong qualifies. That's all we care about in customer display
  isMatch(items: any[], props: any, ic: ICParentLookup): boolean {
    for(const item of items) {
      for(const expr of this.expressions) {
        if(expr.matches(this.accessor, item, props, ic)) {
          return true;
        }
      }
    }
    return false;
  }

  // Clone a filter and add a single filter term for each filter expression in this filter
  // This can surely be improved, I don't know TS well enough.
  withFilterTerm(term: FilterTerm): Filter<itemType, parentType> {
    const newExpressions: FilterExpression[] = [];
    for(const expr of this.expressions) {
      const newTerms: FilterTerm[] = [];
      expr.terms.forEach(val => newTerms.push(Object.assign({}, val)));
      newTerms.push(term);
//      console.log("newTerms", newTerms);
      newExpressions.push(new FilterExpression(newTerms));
    }
    return new Filter(newExpressions, this.accessor);
  }
}

export class FilterExpression {
  public terms: FilterTerm[];

  constructor(terms: FilterTerm[]) {
    this.terms = terms;
  }

  matches(accessor: FilterItemAccessor<any, any>, item: any, props: any, ic: ICParentLookup): boolean {
    for(const term of this.terms) {
      let isMatch = false;
      switch (term.type) {
        case 'Empty':
          break;
        case 'IC':
          const itemIc = accessor.itemIC(item, props);
          isMatch = term.ic.some( t => t == "*" || ic.isChildOf(t, itemIc) );
          break;
        case 'Tag':
          const tags = accessor.itemMenuTags(item, props);
          isMatch = term.tags.some( t => tags?.some( t2 => t2 == t));
          break;
        case 'Phase':
          isMatch = term.phases.some( t => t == accessor.itemPhase(item, props));
          break;
        case 'Area':
          isMatch = term.areas.has(accessor.itemArea(item, props));
          break;
        case 'Table':
          isMatch = term.tables.has(accessor.itemTable(item, props));
          break;
        case 'Device':
          isMatch = term.devices.has(accessor.itemDevice(item, props));
          break;
        case 'DeliveryType':
          isMatch = term.deliveryTypes.has(accessor.itemDeliverType(item, props));
          break;
        case 'OrderTag':
          const orderTags = accessor.itemOrderTags(item, props);
          isMatch = term.orderTags?.some( t => orderTags?.some( t2 => t2 == t));
          console.log("ordertags match", isMatch);
          break;
        case 'Attribute':
          const attributes = accessor.itemAttributes(item, props);
          isMatch = term.attributes?.some ( a => attributes?.some(a2 => a2 == a));
          break;
        case 'State':
          isMatch = term.states?.has( accessor.itemState(item, props) );
          break;
        case 'OlderThanSeconds':
          isMatch = accessor.itemUpdatedAgeSeconds(item, props) > term.seconds;
          break;
        default:
          break;
      }
      if( (isMatch && !term.include) || (!isMatch && term.include) ) {
        return false;
      }
    }
    return true;
  }
}

export type FilterTerm =
    | { type: "Empty", include: boolean }
    | { type: "IC", ic: string[], include: boolean }
    | { type: "Tag", tags: string[], include: boolean }
    | { type: "Phase", phases: string[], include: boolean }
    | { type: "Area", areas: Set<string>, include: boolean }
    | { type: "Table", tables: Set<string>, include: boolean }
    | { type: "Device", devices: Set<string>, include: boolean }
    | { type: "DeliveryType", deliveryTypes: Set<string>, include: boolean }
    | { type: "Attribute", attributes: string[], include: boolean }
    | { type: "OrderTag", orderTags: string[], include: boolean }
    | { type: "State", states: Set<string>, include: boolean }
    | { type: "OlderThanSeconds", seconds: number, include: boolean };

const expressionRegex = /[+-][^+-]+/g;
const filterTermRegex = /([+-])([^=]+)=?(.+)?/;

export class FilterLogic {

  static parseFilterExpressions(filterDefinition: string): FilterExpression[] {
    return filterDefinition.split(",").map(expr =>
      FilterLogic.buildFilterExpression(expr)
    );
  }

  static parseFilter<itemType, parentType>(filterDefinition: string, accessor: FilterItemAccessor<itemType, parentType>): Filter<itemType, parentType> {
    const expressions = FilterLogic.parseFilterExpressions(filterDefinition);
    return new Filter(expressions, accessor);
  }


  static buildFilterExpression(filterItemDefinition: string): FilterExpression {
    const exp = !filterItemDefinition.startsWith("-") && !filterItemDefinition.startsWith("+") ? "+" + filterItemDefinition : filterItemDefinition;
    const matches = exp.match(expressionRegex);
    const terms = matches ? matches.map(value => FilterLogic.buildFilterTerm(value)) : [];
    return new FilterExpression(terms);
  }

  static buildFilterTerm(trm: string): FilterTerm {
    const match = filterTermRegex.exec(trm);
    if (!match) {
        return { type: "Empty", include: true };
    }

    if (match.length === 4) {
        const pm = match[1];
        const include = pm === "+";
        const key = match[2];
        const value = match[3] ? match[3] : "";

        switch (key) {
            case "ic": return { type: "IC", ic: value.split("|"), include: include };
            case "tag": return { type: "Tag", tags: value.split("|"), include: include };
            case "phase": return { type: "Phase", phases: value.split("|"), include: include };
            case "area": return { type: "Area", areas: new Set(value.split("|")), include: include };
            case "table": return { type: "Table", tables: new Set(value.replace("_", " ").split("|")), include: include };
            case "device": return { type: "Device", devices: new Set(value.split("|")), include: include };
            case "dt": return { type: "DeliveryType", deliveryTypes: new Set(value.split("|")), include: include };
            case "attribute": return { type: "Attribute", attributes: value.split("|").map(val => val.toLowerCase()), include: include };
            case "ordertag": return { type: "OrderTag", orderTags: value.split("|"), include: include };
            case "state": return { type: "State", states: new Set(value.split("|")), include: include };
            case "olderthanseconds": return { type: "OlderThanSeconds", seconds: Number(value), include: include };
            default: return { type: "Empty", include: true }
        }
    } else {
        return { type: "Empty", include: true };
    }
  }

  static getFilterTermValues(term: FilterTerm): string[] {
    switch (term.type) {
      case "IC": return term.ic;
      case "Tag": return term.tags;
      case "Phase": return term.phases;
      case "Area": return Array.from(term.areas??[]);
      case "Table": return Array.from(term.tables??[]);
      case "Device": return Array.from(term.devices??[]);
      case "DeliveryType": return Array.from(term.deliveryTypes??[]);
      case "Attribute": return term.attributes;
      case "OrderTag": return term.orderTags;
      case "State": return Array.from(term.states??[]);
      case "OlderThanSeconds": return [term.seconds.toString()];
      default: return [];
    }
  }

  static getFilterTermKey(term: FilterTerm): string {
    switch (term.type) {
      case "IC": return "ic";
      case "Tag": return "tag";
      case "Phase": return "phase";
      case "Area": return "area";
      case "Table": return "table";
      case "Device": return "device";
      case "DeliveryType": return "dt";
      case "Attribute": return "attribute";
      case "OrderTag": return "ordertag";
      case "State": return "state";
      case "OlderThanSeconds": return "olderthanseconds";
      default: return "";
    }
  }

  static serializeFilterExpressions(filterExps: FilterExpression[]) {
    console.log("serializeFilterExprdessions", filterExps);
    return filterExps.map(exp => FilterLogic.serializeFilterExpression(exp)).join(",");
  }

  private static serializeFilterExpression(exp: FilterExpression) {
    const parts = [];
    for (let i = 0; i < exp.terms.length; i++){
      const term = exp.terms[i];
      if (i>0 || !term.include) {
        parts.push(term.include ? "+" : "-");
      }
      parts.push(FilterLogic.serializeFilterTerm(term));
    }
    return parts.join("");
  }

  private static serializeFilterTerm(term: FilterTerm) {
    const key = FilterLogic.getFilterTermKey(term);
    const values = FilterLogic.getFilterTermValues(term);
    return key + "=" + values.join("|");
  }

  static setFilterTermValues(term: FilterTerm, values: string[]) {
    switch (term.type) {
      case "IC": term.ic = values; break;
      case "Tag": term.tags = values; break;
      case "Phase": term.phases = values; break;
      case "Area": term.areas = new Set(values); break;
      case "Table": term.tables = new Set(values); break;
      case "Device": term.devices = new Set(values); break;
      case "DeliveryType": term.deliveryTypes = new Set(values); break;
      case "Attribute": term.attributes = values; break;
      case "OrderTag": term.orderTags = values; break;
      default: break;
    }
  }
}
