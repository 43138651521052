import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { VenueService } from 'src/app/services/venue.service';


export interface SendReceiptDialogData {
  venueId: number;
  receiptKey: string;
  title: string;
}

@Component({
  selector: 'app-send-receipt-dialog',
  templateUrl: './send-receipt-dialog.component.html',
  styleUrls: ['./send-receipt-dialog.component.css']
})
export class SendReceiptDialogComponent implements OnInit {
  title: string;
  receiptKey: string;
  venueId: number;
  address: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: SendReceiptDialogData, private venueService: VenueService,
              private dialogRef: MatDialogRef<SendReceiptDialogComponent>, fb: UntypedFormBuilder)
  {
    this.title = data.title;
    this.receiptKey = data.receiptKey;
    this.venueId = data.venueId;
    this.address = fb.group({
      validAddress: [null, Validators.compose([Validators.required, Validators.pattern(/^([+0][0-9\-\s]{9,15}|[A-Za-z](.*)([@]{1})(.{1,})(\.)(.+))$/)])],
    });
  }

  ngOnInit(): void {
  }

  sendReceipt() {
    this.venueService.sendReceipt(this.venueId, this.receiptKey, this.address.value.validAddress);
    this.dialogRef.close();
  }

}
