<h2 mat-dialog-title>{{data.title}}</h2>
<div mat-dialog-content style="position: relative;">
<!--  <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="loadingService.visibility | async"></mat-spinner>-->
  <div>{{data.message}}</div>
  <div *ngIf="data.htmlBody" [innerHTML]="data.htmlBody"></div>
  <div *ngIf="data.showProgress" class="spinner-panel">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</div>
<mat-dialog-actions align="end">
  <button *ngIf="data.cancelButton" mat-button mat-dialog-close (click)="negClicked()">{{data.cancelButton}}</button>
  <button *ngIf="data.positiveButton" mat-button (click)="posClicked()">{{data.positiveButton}}</button>
</mat-dialog-actions>
