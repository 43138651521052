import {
  Floorplan,
  FloorplanArea,
  FloorplanTable,
  FPData,
  FPLine,
  FPSectionData,
  FPTableData, FLOORPLAN_SCENE_HEIGHT,
  FLOORPLAN_SCENE_WIDTH,
  SNAP_SIZE
} from "../../../models/floorplan";
import Utils from "../../../common/utils";
import {Vector2d} from "konva/lib/types";

/**********************
 OLD DATA CONVERSION
 ***********************/

export class OldFloorplanConverter {

  public static convertOldFormatDataToNew(oldData: string): FPData {
    const old = JSON.parse(oldData) as Floorplan;
    const data: FPData = {sections: []};
    console.log(old.area);
    for (const floorName of old.floors){
      const section: FPSectionData = {id: Utils.buildNiceId(floorName), name: floorName, tables: [], lines: []};
      const oldTables = old.tables.filter( t => t.floor === floorName && t.type !== "V" && t.type !== "L" && !t.type.endsWith("B"));
      const newTables = [];
      for (const oldTable of oldTables) {
        const type = "rect";
        const td = OldFloorplanConverter.convertTableData(old.area, oldTable);
        const newTable: FPTableData = {name: oldTable.name, alias: oldTable.alias, area: oldTable.area, stations: oldTable.stations,
          type: td.type, x: td.pos.x, y: td.pos.y, rotation: 0, scaleX: td.scale.x, scaleY: td.scale.y, size: SNAP_SIZE};
        // console.log(oldTable);
        // console.log(newTable);
        newTables.push(newTable);
      }
      const oldVertices = {};
      for (const vert of old.tables.filter( t => t.floor === floorName && t.type === "V")) {
        oldVertices[vert.alias] = OldFloorplanConverter.getPosForOLD(old.area, vert, {x: 0, y: 0});
      }
      const oldLines = old.tables.filter( t => t.floor === floorName && t.type === "L");
      const newLines = [];
      for (const oldLine of oldLines) {
        //console.log(oldLine);
        const newLine: FPLine = {vertices: []};
        const vIndexes = oldLine.area?.split("-") ?? [];
        for (const vIndex of vIndexes) {
          const v = oldVertices[vIndex];
          newLine.vertices.push(v);
        }
        newLines.push(newLine);
      }
      section.tables = newTables;
      section.lines = newLines;
      data.sections.push(section);
    }
    const idsNames = [];
    for (const s of old.stations) {
      idsNames.push({id: s.id, name: s.name});
    }
    data.stations = idsNames;
    return data;
  }

  private static convertTableData(area: FloorplanArea, table: FloorplanTable): {type: string, pos: Vector2d, scale: Vector2d} {
    const type = OldFloorplanConverter.getTypeForType(table.type);
    const scale = OldFloorplanConverter.getScaleForType(table.type);
    const pos = OldFloorplanConverter.getPosForOLD(area, table, {x: scale.x * (SNAP_SIZE / 2), y: scale.y * (SNAP_SIZE / 2)});
    return {type, pos, scale};
  }

  private static getScaleForType(type: string, size = SNAP_SIZE) {
    let wd = 3 * SNAP_SIZE;
    let hd = 3 * SNAP_SIZE;
    let form = "rect";
    switch (type) {
      case "4H":
        wd = 6 * SNAP_SIZE;
        break;
      case "3H":
        wd = 5 * SNAP_SIZE;
        break;
      case "4V":
        hd = 6 * SNAP_SIZE;
        break;
      case "3V":
        hd = 5 * SNAP_SIZE;
        break;
      case "4":
      case "4X":
        wd = 6 * SNAP_SIZE;
        hd = 6 * SNAP_SIZE;
        break;
      case "3":
      case "3X":
        wd = 5 * SNAP_SIZE;
        hd = 5 * SNAP_SIZE;
        break;
      default:
        if (type.startsWith("C")) {
          switch (type) {
            case "C2":
              wd = 3 * SNAP_SIZE;
              break;
            case "C3":
              wd = 5 * SNAP_SIZE;
              break;
            case "C4":
              wd = 7 * SNAP_SIZE;
              break;
            default:
              const k = parseInt(type.replace("C", ""), 10);
              wd *= Math.round(k / 2);
          }
          hd = wd;
          form = "circle";
        }
    }

    const s = {x: wd / size, y: hd / size};
    // console.log(type);
    // console.log(s);
    return s;
  }

  private static getTypeForType(type: string) {
    if (type.startsWith("C")) {
      return "circle";
    }
    return "rect";
  }

  private static getPosForOLD(area: FloorplanArea, table: FloorplanTable, offset: Vector2d): Vector2d {
    const span = area.right - area.left;
    const x = ((table.x - area.left) / span) * FLOORPLAN_SCENE_WIDTH - offset.x;
    const y = ((table.y - area.top) / span) * FLOORPLAN_SCENE_WIDTH - offset.y;
    const pos = Utils.clampVec(Utils.snapVec({x, y}), {x: FLOORPLAN_SCENE_WIDTH, y: FLOORPLAN_SCENE_HEIGHT - SNAP_SIZE});
    return pos;
  }

}
