<div>
  <p>
    <mat-toolbar>
      <span>Venues</span>
      <span class="spacer"></span>
      <button mat-button (click)="addVenue()">
        <mat-icon>add_business</mat-icon> Ny venue
      </button>
    </mat-toolbar>
  </p>
</div>
<div class="outer-container" [style]="viewingMap ? 'height: calc(100% - 138px);' : ''">
  <div class="element-container">
    <mat-form-field class="filter-box" appearance="outline">
      <mat-label>Filter</mat-label>
      <input matInput [ngModel]="filter" (ngModelChange)="onFilterChange($event)" (keyup.enter)="openTopResult($event)" placeholder="Ex. ium">
    </mat-form-field>

    <mat-form-field *ngIf="!isMobile" class="filter-box" appearance="outline">
      <mat-label>Config data</mat-label>
      <input matInput [(ngModel)]="customColumnsInput" (keyup.enter)="applyCustomColumns()" placeholder="pin_codes.basic">
    </mat-form-field>

    <div>
      <mat-slide-toggle [(ngModel)]="viewingMap" color="primary">Map</mat-slide-toggle>
      <mat-slide-toggle [checked]="showInactive" (change)="inactiveChanged()" style="margin-left: 16px;" color="primary">Inactive</mat-slide-toggle>
      <mat-slide-toggle [(ngModel)]="showToday" (change)="salesChanged()" style="margin-left: 16px;" color="primary">Todays sales</mat-slide-toggle>
    </div>
  </div>

  <div *ngIf="showToday" class="open-panel">
    <div class="panel-title">Totalt</div>
    <div class="panel-body">
      <div class="prow"><mat-icon>paid</mat-icon><div class="info"><div>{{grandTotal | moneySuff : null : "kr"}}</div><div>Försäljning idag ink moms</div></div></div>
      <div class="prow"><mat-icon>receipt</mat-icon><div class="info"><div>{{receiptCount | number}}</div><div>Antal kvitton idag</div></div></div>
    </div>
  </div>

  <div *ngIf="viewingMap" class="map-wrapper">
    <google-map height="100%" width="100%" [zoom]="zoom" [center]="center" [options]="options">
      <map-marker #markerElem *ngFor="let marker of markers" [position]="marker.position" [label]="marker.label"
        [title]="marker.title" [options]="marker.options" (mapClick)="openMapInfo(markerElem, marker.info)" >
      </map-marker>
    </google-map>
  </div>

  <div class="element-container">
    <table mat-table [dataSource]="dataSource" [trackBy]="trackById" matSort matsortDisableClear="true" *ngIf="!viewingMap">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name ({{dataSource.data.length}})</th>
        <td mat-cell *matCellDef="let element"><a class="full-width-button" routerLink="/venue/{{element.venue_id}}/dashboard"  mat-button color="primary">{{element.name }}</a></td>
      </ng-container>
      <ng-container matColumnDef="venue_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
        <td mat-cell *matCellDef="let element"> {{element.venue_id}} </td>
      </ng-container>
      <ng-container matColumnDef="sales">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Sales today </th>
        <td mat-cell *matCellDef="let element">{{getSales(element.venue_id) | moneySuff : null : "kr" }}</td>
      </ng-container>
      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
        <td mat-cell *matCellDef="let element"> {{element.created |date:'medium'}} </td>
      </ng-container>

      <ng-container *ngFor="let disCol of customColumns; let colIndex = index" matColumnDef="{{disCol}}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="{{disCol}}">{{disCol}}</th>
        <td mat-cell *matCellDef="let element"> {{stringify(element.custom_values[disCol])}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
