import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {Admin} from "../../models/user";
import {MatSort, MatSortable} from "@angular/material/sort";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {VenueService} from "../../services/venue.service";
import {Ticket} from "../../models/venue-ticket";
import {SimpleDialogComponent} from "../simple-dialog/simple-dialog.component";
import {NodeUtils} from "../../utils/utils";

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.css']
})
export class TicketListComponent implements OnInit {

  venueId?: number;
  displayedColumns = ["venueName", "sender", "to", "kind", "subject", "created"];
  dataSource: MatTableDataSource<Ticket>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  selected: Ticket;
  canResolve: boolean;
  canOpen: boolean;
  active = true;
  traceUrl: string;

  constructor(public dialog: MatDialog, private route: ActivatedRoute, private venueService: VenueService, private router: Router) { }

  identify(index: number, item: Ticket): string {
    return item.key;
  }

  ngOnInit(): void {
    const data = this.route.snapshot.queryParamMap;
    const selectedKey = data.get("key");
    console.log("ngOnInit", selectedKey);
    if (selectedKey) {
      this.loadSelected(selectedKey);
    }

    this.sort.sort(({ id: 'first_name', start: 'asc'}) as MatSortable);
    this.route.paramMap.subscribe(pdata => {
      const vn = pdata.get("venue_id");
      this.venueId = vn ? Number(vn) : undefined;
      this.fetchTickets();
    });
  }

  fetchTickets() {
    this.venueService.queryTickets(this.venueId, this.active).then(r => {
      this.dataSource = new MatTableDataSource(r as Ticket[]);
      this.dataSource.sort = this.sort;
    });
  }

  newTicket() {

  }

  private updateTicketMenuButtons(ticket: Ticket) {
    this.canResolve = ["unread", "opened", "pending"].includes(ticket.state);
    this.canOpen = !this.canResolve;
  }

  private setSelectedTicket(ticket: Ticket) {
    this.updateTicketMenuButtons(ticket);
    this.selected = ticket;
    this.traceUrl = ticket.source.data?.trace_url;
    NodeUtils.remove(ticket, "source.data.trace_url");
  }

  private unselectTicket() {
    this.router.navigateByUrl(`tickets`);
    this.selected = undefined;
    this.traceUrl = undefined;
  }

  selectTicket(ticket: Ticket) {
    this.router.navigateByUrl(`tickets?key=${ticket.key}`);
    this.setSelectedTicket(ticket);
  }

  resolveTicket() {
    this.venueService.resolveTicket(this.selected.key).then(r => {
      this.unselectTicket();
      this.fetchTickets();
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

  closeTicket() {
    this.venueService.closeTicket(this.selected.key).then(r => {
      this.unselectTicket();
      this.fetchTickets();
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

  openTicket() {
    this.venueService.openTicket(this.selected.key).then( ticket => {
      this.setSelectedTicket(ticket);
      this.fetchTickets();
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

  private loadSelected(selectedKey: string) {
    this.venueService.loadTicket(selectedKey).then( ticket => {
      this.setSelectedTicket(ticket);
    });
  }

  toggleActive() {
    this.fetchTickets();
  }
}
