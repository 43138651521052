import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { VenueCustomer } from 'src/app/models/venue-customers';
import { VenueService } from 'src/app/services/venue.service';
import { VenueConfig } from 'src/app/models/venue-config';
import { Subscription, combineLatest } from 'rxjs';
import { FireService } from 'src/app/services/fire.service';
import { SimpleDialogComponent } from 'src/app/components/simple-dialog/simple-dialog.component';
import { DataFormSchema } from 'src/app/models/data-form';
import { DataFormComponent } from 'src/app/components/data-form/data-form.component';

@Component({
  selector: 'app-edit-venue-customer',
  templateUrl: './edit-venue-customer.component.html',
  styleUrls: ['./edit-venue-customer.component.css']
})
export class EditVenueCustomerComponent implements OnInit, OnDestroy {

  public pageTitle = "Redigera konto";
  public venueCustomers: VenueCustomer[];
  public venueCustomer: VenueCustomer = {} as VenueCustomer;
  @Input() venueId: number;
  @ViewChild("dataform") public dataForm: DataFormComponent;
  public dataFormSchema: DataFormSchema = {
    title: { sv: "Account settings" },
    desc: { sv: "Ställ in accounts" },
    properties: [
      { key: "org_number", title: { sv: "Org.nr" }, desc: { sv: "" }, type: "string", optional: false },
      { key: "name", title: { sv: "Namn" }, desc: { sv: "" }, type: "string", optional: false },
      { key: "address", title: { sv: "Adress" }, desc: { sv: "" }, type: "string", optional: true },
      { key: "postal_number", title: { sv: "Postnummer" }, desc: { sv: "" }, type: "string", optional: true },
      { key: "city", title: { sv: "Stad" }, desc: { sv: "" }, type: "string", optional: true },
      { key: "country", title: { sv: "Land" }, desc: { sv: "" }, type: "string", optional: true },
      { key: "email", title: { sv: "E-post" }, desc: { sv: "" }, type: "string", optional: true },
      { key: "phone", title: { sv: "Telefon" }, desc: { sv: "" }, type: "string", optional: true },
      { key: "contact_person", title: { sv: "Kontaktperson" }, desc: { sv: "" }, type: "string", optional: true },
      { key: "price_list", title: { sv: "Prislista" }, desc: { sv: "" }, type: "config_enum", optional: true, config_source: { path: "menu.price_list", key: "name" } },
      { key: "accounting_id", title: { sv: "Kontonummer" }, desc: { sv: "" }, type: "string", optional: true },
    ]
  };

  @Input() accountKey: string;
  private sub: Subscription;
  private config: VenueConfig;
  @Input() hideMenuBar: boolean = false;

  constructor(private route: ActivatedRoute, private dialog: MatDialog,
              private venueService: VenueService, private snackBar: MatSnackBar,
              private router: Router, private fire: FireService) { }

  ngOnInit(): void {
    if (this.accountKey) {
      this.beginObserving();
      return;
    }
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      const vcKey = data.get("key");
      if (vcKey.startsWith("dup:")) {
        const dupKey = vcKey.split(":")[1];
        this.pageTitle = "Nytt konto";
        this.accountKey = null;
        this.beginObserving(dupKey);
      } else if (vcKey.startsWith("new:")) {
        this.pageTitle = "Nytt konto";
        this.venueCustomer = {
          // TODO: Add data
        } as VenueCustomer;
      } else {
        this.accountKey = vcKey;
        this.beginObserving();
      }
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  private beginObserving(key: string = this.accountKey) {
    this.sub = combineLatest([
      this.fire.observeVenueConfig(this.venueId),
      this.venueService.fetchAccount(this.venueId, key),
    ]).subscribe(([cfg, vc]) => {
      this.config = cfg;
      this.venueCustomer = vc;
    });
  }

  private getPriceLists() {
    const priceLists = this.config?.menu?.price_list?.map(pl => pl.name) ?? [];
    console.log("priceLists", priceLists);
    return priceLists;
  }

  public hasChanges(): boolean {
    // TODO: Implement
    return false;
  }

  save() {
    const data = this.dataForm.collectData() as VenueCustomer;
    if (this.accountKey) {
      data.key = this.accountKey;
    }
    console.log("save", data);
    this.venueService.editAccount(this.venueId, data).then(r => {
      console.log("Account saved", r);
      if (!this.accountKey) {
        this.router.navigateByUrl(`/venue/${this.venueId}/venue-customers`);
        this.snackBar.open("Konto skapat", "", { duration: 2000 });
      } else {
        this.snackBar.open("Konto sparat", "", { duration: 2000 });
      }
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

}
