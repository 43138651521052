import {Component, OnDestroy, OnInit} from '@angular/core';
import {VenueService} from "../../../services/venue.service";
import {ActivatedRoute} from "@angular/router";
import {FireService} from "../../../services/fire.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {Subscription} from "rxjs";
import {VenueConfig} from "../../../models/venue-config";
import {VenueCustomer} from "../../../models/venue-customers";
import {NodeUtils} from "../../../utils/utils";
import Utils from "../../../common/utils";
import {HotTableRegisterer} from "@handsontable/angular";
import {ValidationError} from "../../catalog/menu-models/PublishModels";
import {HttpErrorResponse} from "@angular/common/http";
import {diff} from "deep-object-diff";
import * as _ from "lodash";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-venue-customers',
  templateUrl: './venue-customers.component.html',
  styleUrls: ['./venue-customers.component.css']
})
export class VenueCustomersComponent implements OnInit, OnDestroy {
  venueId: number;
  venueCustomers: VenueCustomer[];
  hotSettings = {};
  hotId = "venue-customers-hot-id";
  errorMessage: string;
  loadedWithoutErrors = false;

  private hotRegisterer = new HotTableRegisterer();
  private sub: Subscription;
  private config: VenueConfig;
  private columnList = [
    {data: "key", title: "ID", width: 120},
    {data: "org_number", title: "Org.nummer", width: 120},
    {data: "name", title: "Namn", width: 200},
    {data: "email", title: "E-post", width: 200},
    {data: "phone", title: "Tel", width: 150},
    {data: "contact_person", title: "Kontaktperson", width: 200},
    {data: "address", title: "Adress", width: 200},
    {data: "postal_number", title: "Postnummer", width: 100},
    {data: "city", title: "Stad", width: 100},
    {data: "country", title: "Land", width: 100},
    {data: "accounting_id", title: "Konto id", width: 100},
    {data: "price_list", title: "Prislista", width: 100, type: "dropdown", source: this.getPricelistSource()},
  ];

  constructor(private venueService: VenueService, private route: ActivatedRoute, private fire: FireService, private snackBar: MatSnackBar, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.fetchCustomers();
    });
    this.sub = this.fire.observeVenueConfig(this.venueId).subscribe(cfg => {
      this.config = cfg;
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  private fetchCustomers() {
    const chainPath = undefined;
    this.venueService.fetchVenueCustomers(this.venueId, chainPath, undefined).then(res => {
      this.venueCustomers = res.customers;
      const data = this.prepareDataForHOT();
      this.setupHandsontableSettings(data);
      this.loadedWithoutErrors = true;
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

  private setupHandsontableSettings(data: any) {
    const that: VenueCustomersComponent = this;
    const set = {
      licenseKey: environment.handson_key,
      data,
      startRows: 20,
      autoWrapCol: false,
      autoWrapRow: false,
      colHeaders: true,
      rowHeaders: true,
      hiddenColumns: { columns: [0] },
      manualColumnResize: true,
      columns: this.columnList,
      contextMenu: ["row_above", "row_below", "---------", "remove_row"],
      stretchH: 'all',
      width: '100%',
      height: '100%',
    };
    this.hotSettings = set;
  }

  private prepareDataForHOT() {
    // Prepare rows (values)
    let data;
    if (this.venueCustomers?.length > 0) {
      data = [];
      for (const row of this.venueCustomers) {
        data.push(NodeUtils.deepcopyWithJSON(row));
      }
      for (let i = 0; i < 20; i++) {
        data.push({});
      }
    }
    return data;
  }

  save() {
    if (!this.loadedWithoutErrors) {
      //Make sure we do not try to save if we didnt loaded the data correctly
      console.error("failed to save (load error)");
      return;
    }
    if (!this.hasChanges()) {
      this.snackBar.open("No changes. Nothing saved.", "", {duration: 3000});
      return;
    }
    this.errorMessage = null;
    const customers = this.collectTableData();
    console.log("Data when saving", customers);
    for (const customer of customers) {
      if (customer.org_number == null) {
        this.errorMessage = `Konto saknar org.nummer / personnummer (rad ${customer.rowIndex}).`;
        return;
      }
      if (customer.name == null) {
        this.errorMessage = `Konto saknar namn (rad ${customer.rowIndex})`;
        return;
      }
    }

    //Check for customers with duplicate key => remove key
    const usedKeys = new Set();
    for (const customer of customers) {
      const key = customer.key;
      if (key != null) {
        if (usedKeys.has(key)) {
          delete customer.key;
        } else {
          usedKeys.add(key);
        }
      }
    }

    console.log("Customers", customers);
    this.venueService.updateVenueCustomers(this.venueId, undefined, customers).then(res => {
      this.venueCustomers = res.customers;
      const data = this.prepareDataForHOT();
      const hotInstance = this.hotRegisterer.getInstance(this.hotId);
      hotInstance.loadData(data);
    }).catch(error => this.updateError(error));
  }

  private collectTableData(): VenueCustomer[] {
    const hotInstance = this.hotRegisterer.getInstance(this.hotId);
    const rows = [] as VenueCustomer[];
    for (let i = 0; i < hotInstance.countRows(); i++) {
      const row = {} as VenueCustomer;
      for (const col of this.columnList) {
        const v = hotInstance.getDataAtRowProp(i, col.data);
        if (!Utils.isStringNullOrWhitespace(v)) {
          row[col.data] = v;
        }
      }
      if (!NodeUtils.isNullOrEmpty(row)) {
        row.rowIndex = i + 1;
        rows.push(row);
      }
    }

    //Check for customers that only has key => delete from
    _.remove(rows, c => c.key != null && c.rowIndex != null && Object.keys(c).length === 2);
    console.log("Collected table data", rows);

    return rows;
  }

  closeError() {
    this.errorMessage = null;
  }

  private updateError(error: Error) {
    if (error instanceof ValidationError) {
      this.errorMessage = error.message;
    } else if (error instanceof HttpErrorResponse) {
      this.errorMessage = error.error.message;
    }
  }

  public hasChanges(): boolean {
    if (!this.loadedWithoutErrors) {
      return false;
    }
    const customers = NodeUtils.cleanList(this.collectTableData());
    for (const c of customers) {
      delete c.rowIndex;
    }
    const org = NodeUtils.cleanList(_.cloneDeep(this.venueCustomers));
    for (const c of org) {
      delete c.updated;
      delete c.created;
      delete c.venue_key;
    }

    const d = diff(org, customers);
    console.log("Customers diff", d);
    return !NodeUtils.isNullOrEmptyObject(d);
  }

  private getPricelistSource() {
    return (query, process) => {
      process( this.config?.menu?.price_list?.map(pl => pl.name) ?? [] );
    };
  }

}
