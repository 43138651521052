import {AfterViewInit, Component, Inject, NgZone, OnInit} from '@angular/core';
import {HotTableRegisterer} from "@handsontable/angular";
import {Item, PushPage} from "../../catalog/menu-models/PublishModels";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MenuStateService} from "../../catalog/menu-utils/menu-state.service";
import {FilterTerm} from "../../../utils/filter-logic";

@Component({
  selector: 'app-edit-filter-term-dialog',
  templateUrl: './edit-filter-term-dialog.component.html',
  styleUrls: ['./edit-filter-term-dialog.component.css']
})
export class EditFilterTermDialogComponent implements OnInit, AfterViewInit {
  term: FilterTerm;
  values: string[];
  availableValues: string[];
  filteredValues: string[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: {term: FilterTerm, values: string[], availableValues: string[]},
              public dialogRef: MatDialogRef<EditFilterTermDialogComponent>, private state: MenuStateService, private ngZone: NgZone) {
    this.term = data.term;
    this.values = data.values.slice();
    this.availableValues = data.availableValues;
    this.filterValues();
  }

  private filterValues() {
    this.filteredValues = this.availableValues.filter(v => !this.values.includes(v.trim()));
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  okClicked() {
    //const s = this.collectPackageData();
    this.dialogRef.close(this.values);
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  removeValue(val: string) {
    this.values = this.values.filter(v => v !== val);
    this.filterValues();
  }

  addValue(val: string) {
    this.values.push(val.trim());
    this.filterValues();
  }

  deleteClicked() {
    this.dialogRef.close("--delete--");
  }
}
