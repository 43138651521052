import Konva from "konva";
import Shape = Konva.Shape;
import Utils from "../common/utils";
import Line = Konva.Line;
import * as _ from "lodash";
import Vector2d = Konva.Vector2d;
import {ReplaySubject} from "rxjs";

export const SNAP_SIZE = 16;
export const INIT_SCALE = 4;
export const FLOORPLAN_SCENE_WIDTH = SNAP_SIZE * 64;
export const FLOORPLAN_SCENE_HEIGHT = SNAP_SIZE * 64;

export interface FSFloorplan {
  data: string;
  venue: string;
  venue_id: number;
  data_version: number;
}

export interface Floorplan {
  area: FloorplanArea;
  tables: FloorplanTable[];
  dim: number;
  aliasTemplate: string;
  stations: any[];
  floors: string[];
}

export interface FloorplanArea {
  top: number;
  left: number;
  right: number;
  bottom: number;
}

export interface FloorplanTable {
  floor: string;
  name: string;
  alias: string;
  type: string;
  x: number;
  y: number;
  chairs: number;
  stations: string[];
  area: string;

  // transient
  paintedCenterX: number;
  paintedCenterY: number;
  isButton: boolean;
}

//--------------------

export interface FPVersion {
  key: string;
  user_name: string;
  data: FPData;
  diff: string;
  desc: string;
  date: string;
}

export interface FPData {
  sections: FPSectionData[];
  stations?: FPStationData[];
}

export interface FPSectionData {
  id: string;
  name: string;
  tables: FPTableData[];
  lines: FPLine[];
}

export interface FPTableData {
  name: string;
  alias: string;
  type: string;
  x: number;
  y: number;
  area?: string;
  stations?: string[];
  rotation?: number;
  scaleX?: number;
  scaleY?: number;
  size?: number;
  resource_type?: string;
}

export interface FPLine {
  vertices: FPVertex[];
}

export interface FPVertex {
  x: number;
  y: number;
}

export interface FPStationData {
  id: string;
  name: string;
}
