import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { from, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, flatMap, map } from 'rxjs/operators';
import { LinkUser } from 'src/app/models/user';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-link-account',
  templateUrl: './link-account.component.html',
  styleUrls: ['./link-account.component.css']
})

export class LinkAccountComponent implements OnInit, OnDestroy {

  model: LinkUser;
  error: string;
  submitted: boolean;
  hideP = true;
  hideCP = true;
  linkToken: string;
  getUserSub: Subscription;
  linkAccountSub: Subscription;
  form: UntypedFormGroup = new UntypedFormGroup({});


  constructor(
    public authService: AuthService,
    public loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    public api: ApiService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
    }, { validator: this.checkPasswords });
  }

    public checkPasswords(group: UntypedFormGroup) {
      const pass = group.controls.password.value;
      const confirmPass = group.controls.confirmPassword.value;
      if (pass !== confirmPass) {
        group.controls.confirmPassword.setErrors({ noMatch: true });
        return { noMatch: true };
      }
      return null;
  }
  ngOnDestroy(): void {
    this.getUserSub?.unsubscribe();
    this.linkAccountSub?.unsubscribe();
  }

  ngOnInit(): void {

    this.authService.disableSigninPipe();
    this.getUserSub = this.route.paramMap.pipe(flatMap(data => {
      const linkToken = data.get("link_token");
      return this.api.getUserByLinkToken(linkToken);
    })).pipe(map(u => {
      this.model = u;
      console.log(u);
      if (u.admin_auth_id) {
        this.authService.setupSigninPipe();
        this.router.navigate(["signin"]);
      }
    })).subscribe();
  }
  public link(): void {
    if (this.form.valid) {
      this.linkAccountSub = this.authService.createFirebaseAccount(this.model.email, this.form.controls.password?.value)
      .pipe(catchError(createAccountErr =>
        this.handleFirebaseAccountAlreadyExits(createAccountErr, this.model.email, this.form.controls.password?.value)))
      .pipe(flatMap(token => this.linkAccount(this.model.link_token, token)))
      .pipe(catchError(err => of(this.handleServerError(err))))
      .subscribe(r => console.log("link completed"));
    } else {
      this.form.markAsTouched();
    }
  }

  private linkAccount(linkToken: string, token: string): Observable<LinkUser> {
    return this.api.linkAccount(linkToken, token)
    .pipe(map(result => {
      if (result?.admin_auth_id) {
        this.authService.setupSigninPipe();
        this.router.navigate([""]);
        return result;
      }
      return result;
    }));
  }

  private handleFirebaseAccountAlreadyExits(err: any, email: string, password: string): Observable<string> {
    console.log(err);
    if (err && err?.code === "auth/email-already-in-use") {
      return this.authService.login(email, password)
      .pipe(flatMap(cred => from(cred.user.getIdToken())), catchError(signinErr => {
        console.log(signinErr);
        this.error = signinErr.message;
        return throwError(signinErr);
      }));
    } else {
      return throwError(err);
    }
  }

  private handleServerError(err: any) {
    console.log("Error while linking account");
    console.log(err);
    if (err) {
      if (err?.code) {
        this.error = err.message;
      } else if (err?.status !== 200 && err.status) {
        this.error = "Ooops.. Something went wrong";
      }
    }
    return throwError(err);
  }

}
