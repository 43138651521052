import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {FPVersion} from "../../../models/floorplan";
import {MatSort, MatSortable} from "@angular/material/sort";
import {ActivatedRoute, Router} from "@angular/router";
import {VenueService} from "../../../services/venue.service";
import {FireService} from "../../../services/fire.service";
import {MenuStateService} from "../menu-utils/menu-state.service";
import {MenuVersion} from "../menu-models/MenuChange";

@Component({
  selector: 'app-menu-history-list',
  templateUrl: './menu-history-list.component.html',
  styleUrls: ['./menu-history-list.component.css']
})
export class MenuHistoryListComponent implements OnInit {
  venueId: string;

  displayedColumns = ["user_name", "date", "changes"];
  dataSource: MatTableDataSource<MenuVersion>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  private paths: { anchorPath: string; rootPath: string };

  constructor(private route: ActivatedRoute, private venueService: VenueService,
              private router: Router, private fire: FireService, private state: MenuStateService) { }

  ngOnInit(): void {
    this.sort.sort(({ id: 'date', start: 'desc'}) as MatSortable);

    this.route.paramMap.subscribe(data => {
      this.venueId = data.get("venue_id");
      this.fetchHistory();
    });
  }

  private fetchHistory() {
    this.fire.getMenuVersions(Number(this.venueId), this.state.menuId).subscribe(r => {
      this.dataSource = new MatTableDataSource(r as MenuVersion[]);
      this.dataSource.sort = this.sort;
    });
  }

  select(row: any) {
    this.router.navigateByUrl(`catalog/${this.venueId}/version/${row.hash}`);
  }

}
