import { Injectable } from '@angular/core';
import {combineLatest, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {FireService} from "./fire.service";
import {VenueConfig} from "../models/venue-config";
import {Bong, BongItem, BongSummary, BongSummaryRow, GroupedBongItems} from "../models/kds-bong";
import Utils from "../common/utils";
import { Filter } from '../utils/filter-logic';
import {ICParentLookup, ICUtils} from '../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class BongService {

  constructor(private fire: FireService) { }

  observeBongSummary(venueId: number, filter?: Filter<BongItem, Bong>, showActive?: boolean): Observable<BongSummary[]> {
    return combineLatest([
      this.fire.observeVenueConfig(venueId),
      this.fire.observeKDSBongs(venueId)]
    ).pipe(
      map<[VenueConfig, Bong[]], BongSummary[]>( res => {
        const config = res[0];
        const bongs = res[1];
        return this.summarize(config, bongs, filter, showActive);
      })
    );
  }

  private summarize(config: VenueConfig, bongs: Bong[], filter: Filter<BongItem, Bong>, showActive?: boolean): BongSummary[] {
    return bongs.filter(bong => showActive == null || bong.active === showActive).map( bong => {
      const filteredItems = this.filterItems(bong.items, bong, filter, ICUtils.getICParentLookup(config));
      if (!filteredItems || filteredItems.length === 0) { return null; }

      const bs = new BongSummary();
      bs.bongs = [bong];

      const maxItem = filteredItems.reduce((max, bongItem) => {
        if (bongItem.updated === null) {
            return max;
        }

        if (max === null || bongItem.updated > max.updated) {
            return bongItem;
        }
        return max;
      }, null as BongItem | null);

      bs.lastUpdated = maxItem === null ? bong.updated : maxItem.updated;
      // if(bong.order_numbers == "17") {
      //   console.log("first match", bs.firstMatch);
      //   console.log("first match", bs.lastUpdated);
      //   console.log("unfiltered items", bong.items);
      //   console.log("filtered items", filteredItems);
      // }
      const groupedItems = this.groupItems(filteredItems);
      bs.rows = this.getSummary(groupedItems);
      bs.orderTags = bong.orderTags;
      return bs;
    }).filter( bong => bong != null).sort((a, b) => b.compareValue() - a.compareValue());
  }

  private getSummary(items: GroupedBongItems[]): BongSummaryRow[] {
    const rows = [];

    const itemsGroupedByGroupIndex = Utils.groupBy(items, it => it.groupIndex());
    for (const groupIndex of Object.keys(itemsGroupedByGroupIndex).sort()) {
      const groupedItems = itemsGroupedByGroupIndex[groupIndex];
      const str = groupedItems[0].groupName();
      const divider = new BongSummaryRow(null, null, str);
      rows.push(divider);

      for (const groupedItem of groupedItems) {
        const row = new BongSummaryRow(groupedItem, null, null);
        rows.push(row);
      }
    }

    return rows;
  }

  private groupItems(items: BongItem[]): GroupedBongItems[] {
    const group = [];
    const itemsGroupedByIdPhase = Utils.groupBy(items, it => `${it.id}:${it.phase}`);
    for (const phaseIndex of Object.keys(itemsGroupedByIdPhase).sort()) {
      const gitmes = itemsGroupedByIdPhase[phaseIndex];
      const gpi = new GroupedBongItems(phaseIndex, gitmes);
      group.push(gpi);
    }
    return group;
  }

  private filterItems(items: BongItem[], bong: Bong, filter: Filter<BongItem, Bong>, ic: ICParentLookup): BongItem[] {
    if (filter) {
      return filter.itemMatches(items, bong, ic);
    } else {
      return items;
    }
  }
}
