import {Component, OnInit, ViewChild, isDevMode} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {NewUserDialogComponent} from "./new-user-dialog/new-user-dialog.component";
import {HackUtils} from "../../utils/utils";
import {MatTableDataSource} from "@angular/material/table";
import {Admin} from "../../models/user";
import {ActivatedRoute} from "@angular/router";
import {VenueService} from "../../services/venue.service";
import {MatSort, MatSortable} from "@angular/material/sort";
import {QueryUserDialogComponent} from "./query-user-dialog/query-user-dialog.component";
import {EditRoleDialogComponent} from "./edit-role-dialog/edit-role-dialog.component";
import {MatSnackBar} from "@angular/material/snack-bar";
import { EditAdminUserDialogComponent } from './edit-admin-user-dialog/edit-admin-user-dialog.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  constructor(public dialog: MatDialog, private route: ActivatedRoute, private venueService: VenueService, private snackBar: MatSnackBar) { }
  isDev: boolean;
  private venueId?: number;
  displayedColumns = ["id", "email", "first_name", "last_name", "roles", "actions"];
  dataSource: MatTableDataSource<Admin>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  title: string;

  ngOnInit(): void {
    this.isDev = isDevMode();
    this.sort.sort(({ id: 'first_name', start: 'asc'}) as MatSortable);
    this.route.paramMap.subscribe(data => {
      const vn = data.get("venue_id");
      this.venueId = vn ? Number(vn) : undefined;
      this.title = vn ? "Venue admins" : "Heynow Superadmins";
      this.fetchUsers();
    });
  }

  private fetchUsers() {
    this.venueService.fetchAdminUsers(this.venueId).then(r => {
      console.log("fetchAdminUsers", r);
      this.dataSource = new MatTableDataSource(r as Admin[]);
      this.dataSource.sort = this.sort;
    });
  }

  addPerson() {
    const dialogRef = this.dialog.open(QueryUserDialogComponent, HackUtils.DLG({
      data: { venueId: this.venueId }
    }));
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
      this.fetchUsers();
    });
  }

  openEditRoleDialog(email: string) {
    const dialogRef = this.dialog.open(EditRoleDialogComponent, HackUtils.DLG({
      data: { email, venueId: this.venueId }
    }));
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
      this.fetchUsers();
    });
  }

  editRoles(row: any) {
    this.openEditRoleDialog(row.email);
  }

  editAdminUser(row: any) {
    const dialogRef = this.dialog.open(EditAdminUserDialogComponent, HackUtils.DLG({
      data: { email: row.email, venueId: this.venueId, firstName: row.first_name, lastName: row.last_name}
    }));
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
      this.fetchUsers();
    });
  }

  resendInvite(row: any) {
    this.venueService.resendInvite(row.email, this.venueId).then(r => {
      this.snackBar.open("Invite mail sent", "", {duration: 3000});
    });
  }
}
