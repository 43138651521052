import firebase from "firebase/compat/app";
import Timestamp = firebase.firestore.Timestamp;

export interface MenuChange {
  venueId: number;
  userId: string;
  changeType: string;
  menu: string;
  tab: string;
  row: number;
  colName: string;
  dataType: string;
  oldValue: string;
  newValue: string;
  created?: Timestamp;
}

export interface VenueMenu {
  name: string;
  id: string;
  venueId: number;
  userId: string;
  created?: Timestamp;
}

export interface MenuTab {
  venueId: number;
  menuId: string;
  id: string;
  name: string;
  rows: number[];
  columns: string[];
  updated?: Timestamp;
  rowIndexHead?: number;
  settingsJson?: string;
}

export interface MenuCell {
  venueId: number;
  menuId: string;
  tabId: string;
  userId: string;
  row: number;
  col: string;
  dataType: string;
  value: string;
  updated?: Timestamp;
}

export interface CollectMenuContext {
  menuVersion: MenuVersion;
  tabContexts: CollectTabContext[];
}

export interface CollectTabContext {
  tabVersion: MenuTabVersion;
  menuTab: MenuTab;
  tabCells: MenuCell[];
}

export interface MenuVersion {
  venueId: number;
  menuId: string;
  hash: string;
  menuTabVersionRefs: MenuTabVersionRef[];
  user_email: string;
  user_name: string;
  created?: Timestamp;
  changes?: string;
}

export interface MenuTabVersionRef {
  name: string;
  tabId: string;
  hash: string;
  changes?: string;
}

export interface MenuTabVersion {
  // copy from MenuTab
  venueId: number;
  menuId: string;
  id: string;
  name: string;
  rows: number[];
  columns: string[];
  rowIndexHead?: number;
  settingsJson?: string;

  // cell data: type serialized TabVersionMenuCell[]
  cell_data: string;

  // additional data
  hash: string;
  user_email: string;
  user_name: string;
  created?: Timestamp;
  changes?: string;
}

export interface TabVersionMenuCell {
  userId: string;
  row: number;
  col: string;
  value: string;
  updated: Timestamp;
  changed?: boolean;
}

export interface MenuTabSettings {
  page_image?: string;
  show_glas_col?: boolean;
  show_cogs_col?: boolean;
  show_deadline_col?: boolean;
  sort_index?: number;
  show_bong_name_col?: boolean;
  show_bong_order_col?: boolean;
  show_pos_name_col?: boolean;
  show_resource_col?: boolean;
  show_cc_col?: boolean;
  show_ean_col?: boolean;
}

export function parseMenuTabSettings(settingsJson: string | null): MenuTabSettings {
  return settingsJson != null ? JSON.parse(settingsJson) : {};
}
