import {Component, OnInit, ViewChild} from '@angular/core';
import {MenuTabVersion, MenuTabVersionRef, MenuVersion, parseMenuTabSettings, TabVersionMenuCell} from "../menu-models/MenuChange";
import {ActivatedRoute, Router} from "@angular/router";
import {VenueService} from "../../../services/venue.service";
import {FireService} from "../../../services/fire.service";
import {MenuStateService} from "../menu-utils/menu-state.service";
import {PublishService} from "../../../services/publish.service";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import * as _ from "lodash";
import {first} from "rxjs/operators";
import {VenueConfig} from "../../../models/venue-config";

@Component({
  selector: 'app-menu-version',
  templateUrl: './menu-version.component.html',
  styleUrls: ['./menu-version.component.css']
})
export class MenuVersionComponent implements OnInit {
  venueId: string;
  showSpinner = false;
  menuVersion: MenuVersion;
  tabVersion: MenuTabVersion;
  rows: number[];
  dbMatrix: {};
  tabSettings: any;
  columns: string[];
  selectedRef: MenuTabVersionRef;

  private version: string;
  private config: VenueConfig;

  constructor(private route: ActivatedRoute, private venueService: VenueService,
              private router: Router, private fire: FireService, private state: MenuStateService,
              private publishService: PublishService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = data.get("venue_id");
      this.version = data.get("version");
      this.fetchVersion();
    });
  }

  private fetchVersion() {
    this.fire.observeVenueConfig(Number(this.venueId)).pipe(first()).subscribe(cfg => {
      this.config = cfg;
      this.fire.getMenuVersion(Number(this.venueId), this.state.menuId, this.version).subscribe(r => {
        this.menuVersion = r;
      });
    });
  }

  restoreVersion() {
    SimpleDialogComponent.openSimpleDialog(this.dialog, {title: "Restore version", message: "Denna funktion är dessvärre avstängd tillsvidare."});
    return;
    // this.showSpinner = true;
    // const dialogRef = SimpleDialogComponent.openSimpleDialog(this.dialog, {title: "Restoring version...", showProgress: true, disableClose: true});
    // this.publishService.restore(Number(this.venueId), this.state.menuId).then( r => {
    //   console.log("Restore successfully!");
    // }).catch(e => SimpleDialogComponent.showErr(this.dialog, e)).finally( () => { dialogRef.close(); this.showSpinner = false; } );
  }

  showTab(ref: MenuTabVersionRef) {
    this.fire.getTabVersion(Number(this.venueId), this.state.menuId, ref.tabId, ref.hash).subscribe(tv => {
      this.selectedRef = ref;
      this.tabVersion = tv;
      this.rows = [...Array(this.tabVersion.rows).keys()];
      const dbMatrix = {};
      this.columns = [];
      const cellData = JSON.parse(this.tabVersion.cell_data) as TabVersionMenuCell[];
      cellData.forEach( cell => this.updateDB(dbMatrix, cell));
      this.dbMatrix = dbMatrix;
      this.tabSettings = parseMenuTabSettings(tv.settingsJson);
      if (this.tabVersion.id === "options") {
        this.columns = ["id", "name", "desc", "relPrice", "fixPrice", "mp", "la", "eg", "gl", "wh", "pe", "cr", "ml", "sf", "fi", "nu", "se", "ce", "mu", "lu", "so", "sul", "pork", "pia", "stf", "gel", "soybean", "ci", "on", "ga"];
      } else if (this.tabVersion.id === "pushpages") {
        this.columns = ["id", "title", "title1", "items1", "title2", "items2", "title3", "items3", "title4", "items4"];
      } else {
        this.columns = ["type", "id", "name", "price", "desc", "phase", "attributes", "adjust", "push", "ic", "image", "takeaway", "pickup", "when", "mp", "la", "eg", "gl", "wh", "pe", "cr", "ml", "sf", "fi", "nu", "se", "ce", "mu", "lu", "so", "sul", "pork", "pia", "stf", "gel", "soybean", "ci", "on", "ga", "prop"];
        if (this.tabSettings?.show_glas_col) {
          this.columns.splice(4, 0, "glas");
        }
        if (this.tabSettings?.show_cogs_col) {
          this.columns.splice(4, 0, "cogs");
        }
        if (this.tabSettings?.show_deadline_col) {
          this.columns.splice(14, 0, "deadline");
        }
        if (this.tabSettings?.show_bong_order_col) {
          this.columns.splice(13, 0, "bong_order");
        }
        if (this.tabSettings?.show_bong_name_col) {
          this.columns.splice(13, 0, "bong_name");
        }
        if (this.tabSettings?.show_pos_name_col) {
          this.columns.splice(13, 0, "pos_name");
        }
        if (this.tabSettings?.show_resource_col) {
          this.columns.splice(9, 0, "resource_type");
        }
        if (this.tabSettings?.show_cc_col) {
          this.columns.splice(9, 0, "cc");
        }
        if (this.tabSettings?.show_ean_col) {
          this.columns.splice(9, 0, "ean");
        }
        const plItems = (this.config?.menu?.price_list ?? []).map(pl => pl.name);
        this.columns.splice(3, 0, ...plItems);
        const etItems = (this.config?.menu?.editor_tags ?? []).map(et => et.tag);
        this.columns.push(...etItems);
      }
    });
  }

  private updateDB(dbMatrix: {}, cell: TabVersionMenuCell) {
    if (!(cell.row in dbMatrix)) {
      dbMatrix[cell.row] = {};
    }
    dbMatrix[cell.row][cell.col] = cell;
    // if (!_.includes(this.columns, cell.col)) {
    //   this.columns.push(cell.col);
    // }
  }

  public readFromDB(row: number, col: string): TabVersionMenuCell {
    if (!(row in this.dbMatrix)) {
      return undefined;
    }
    const dbRow = this.dbMatrix[row];
    if (!(col in dbRow)) {
      return undefined;
    }
    return dbRow[col];
  }

  showCellInfo(row: number, col: string) {
    const cell = this.readFromDB(row, col);
    if (cell) {
      console.log(cell);
    }
    return false;
  }
}
