import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {VenueService} from "../../../services/venue.service";
import {LoadingService} from "../../../services/loading.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import { SimpleDialogComponent } from '../../simple-dialog/simple-dialog.component';

@Component({
  selector: 'app-edit-admin-user-dialog',
  templateUrl: './edit-admin-user-dialog.component.html',
  styleUrls: ['./edit-admin-user-dialog.component.css']
})
export class EditAdminUserDialogComponent implements OnInit {
  firstName = new UntypedFormControl('', [Validators.required]);
  lastName = new UntypedFormControl('', [Validators.required]);
  email: string;
  venueId?: number;

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<EditAdminUserDialogComponent>, private dialog: MatDialog) {
    this.email = data.email;
    this.venueId = data.venueId;
    this.firstName.setValue(data.firstName);
    this.lastName.setValue(data.lastName);
  }

  ngOnInit(): void {
  }

  editUser() {
    this.firstName.markAsTouched();
    this.lastName.markAsTouched();
    if (this.firstName.valid && this.lastName.valid) {
      const firstName = this.firstName.value;
      const lastName = this.lastName.value;
      this.venueService.updateAdminUser(firstName, lastName, this.email, this.venueId).then( r => {
        console.log(`Edited user ${this.email}..`);
        this.dialogRef.close(r);
      })
      .catch(e => {
        console.error(`Failed to edit user ${this.email}`, e);
        this.dialogRef.close(false);
        SimpleDialogComponent.showErr(this.dialog, e);
      }
      );
    }
  }

}
