export const pageKdsSettings = {
  title: { sv: "KDS" },
  page_settings: {
    root_path: "kds"
  },
  desc: { sv: "Inställningar för KDS" },
  properties: [
    { key: "ui_version", title: { sv: "UI version" }, desc: { sv: "Välj vilken version av KDS UI som ska användas" }, type: "enum", optional: true,
      values: [
        { key: 1, title: { sv: "Version 1" } },
        { key: 2, title: { sv: "Version 2" } },
      ]
    },
    { key: "filters", title: { sv: "Filter" }, desc: { sv: "Ange filter för bongar, t.ex Namn='Alla' & Filter='ic=*'" }, type: "table",
      columns: [
        { key: "name", title: { sv: "Namn" }, type: "string", width: 150 },
        { key: "filter", title: { sv: "Filter" }, type: "string", width: 200 },
      ]
    },
    { header: "Färgväxling för bongar" },
    { key: "timings.yellow", title: { sv: "Gul bong" }, desc: { sv: "Tid innan bong växlar till gul" }, type: "integer", suffix: "min" },
    { key: "timings.red", title: { sv: "Röd bong" }, desc: { sv: "Tid innan bong växlar till röd" }, type: "integer", suffix: "min" },
  ]
};
