export const pageServingSettings = {
  title: {sv: "Servering"},
  page_settings: {
    root_path: "serving"
  },
  desc: {sv: "Ställ in hur serveringarna ska fungera"},
  properties: [
    {key: "alt_forwarding_filter", title: {sv: "Filter för separat framning av dryck"}, desc: {sv: "Ange ett filter för separat framning av dryck, typiskt 'ic=drink'"}, type: "string", optional: true},
    {key: "phases", title: {sv: "Servering"}, type: "table", desc: {sv: ""},
      columns: [
        {key: "alias", title: {sv: "ID"}, type: "string", width: 150},
        {key: "name", title: {sv: "Namn"}, type: "string", width: 200},
        {key: "phase", title: {sv: "Sorteringsordning"}, type: "integer", width: 150},
        {key: "alias_display", title: {sv: "Förkortning"}, type: "string", width: 150},
        {key: "default_filter", title: {sv: "Förvaldsregel"}, type: "string", width: 150},
      ]
    },
    {key: "item_activation", title: {sv: "Aktivering"}, desc: {sv: "Välj när bong skall aktiveras för artiklar"}, type: "enum", optional: true,
      values: [
        {key: "first_split", title: {sv: "Första servering i dryck och mat (delad)"}},
        {key: "first_phase", title: {sv: "Första servering (inte delad)"}},
        {key: "first_beverage", title: {sv: "Första servering i dryck"}},
      ]
    },
  ]
};
