import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from "rxjs";
import { QuerySalesReportResponse } from 'src/app/models/reporting';
import { QueryReceiptRequest, VenueService } from 'src/app/services/venue.service';
import { HackUtils } from 'src/app/utils/utils';
import { ReportTableComponent } from '../report-table/report-table.component';
import { SimpleDialogComponent } from '../../simple-dialog/simple-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-card-balance',
  templateUrl: './card-balance.component.html',
  styleUrls: ['./card-balance.component.css']
})
export class CardBalanceComponent implements OnInit {
  private paramSub: Subscription;
  private query: string;

  @ViewChild('reportTable') exportTable: ReportTableComponent;
  title = "Kortbalans";
  isMobile: boolean;
  venueId: number;
  filter: string;
  searchStartDate: Date;
  searchEndDate: Date;
  tableData: QuerySalesReportResponse;

  constructor(private venueService: VenueService, private route: ActivatedRoute, public datePipe: DatePipe, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.isMobile = this.isMobile = HackUtils.isMobile() && window.innerWidth <= 768;
    this.paramSub = combineLatest([
        this.route.paramMap,
        this.route.queryParamMap
      ]
    ).subscribe(res => {
      const param = res[0];
      const query = res[1];
      const q = query.get("q");
      const venueId = param.get("venue_id");
      this.venueId = Number(venueId);
      this.query = q;
    });
  }

  fetch() {
    const request = this.buildRequest();
    this.venueService.queryPrepaidTx(request).then(r => {
      this.tableData = r;
    }).catch(e => {
      SimpleDialogComponent.showErr(this.dialog, e);
      console.error(e);
    });
  }

  private buildRequest() {
    const request = new QueryReceiptRequest();
    request.venue_id = String(this.venueId);

    if (this.searchStartDate != null) {
      request.start_date = this.datePipe.transform(this.searchStartDate, 'yyyy-MM-dd');
    }
    if (this.searchEndDate != null) {
      request.end_date = this.datePipe.transform(this.searchEndDate, 'yyyy-MM-dd');
    }
    return request;
  }
}
