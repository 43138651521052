<div>
  <p>
    <mat-toolbar>
      <button mat-icon-button routerLink="/venue/{{venueId}}/settings-history/{{page}}" *ngIf="version!=null"><mat-icon>arrow_back_ios</mat-icon></button>
      <span>Inställningar > {{sc.title.sv}}</span>
      <span class="spacer"></span>

      <button *ngIf="sc.admin_options && sc.admin_options?.length > 0" mat-button [matMenuTriggerFor]="adminOptions"><mat-icon>admin_panel_settings</mat-icon></button>
      <mat-menu #adminOptions="matMenu">
        <button *ngIf="sc.admin_options?.includes('fetch_auth_token')" mat-button (click)="fetchVenueAuthToken()">Publiceringsnyckel</button>
      </mat-menu>

      <button mat-button *ngFor="let action of sc.actions" (click)="runCustomAction(action)">
        <mat-icon>{{action.icon}}</mat-icon> {{action.title.sv}}
      </button>

      <button mat-button routerLink="/venue/{{venueId}}/settings-history/{{page}}">
        <mat-icon>history</mat-icon> Historik
      </button>

      <button mat-button (click)="restoreVersion()" *ngIf="version!=null && !sameAsCurrent">
        <mat-icon>settings_backup_restore</mat-icon> Återställ
      </button>

      <button mat-button (click)="save()" *ngIf="version==null">
        <mat-icon>save</mat-icon> Spara
      </button>
    </mat-toolbar>
  </p>
</div>

<div class="version-panel" *ngIf="fragment">
  <div>VERSION</div>
  <div>{{fragment.key}}</div>
  <div>{{fragment.created}}</div>
  <div>{{fragment.user_name}}</div>
</div>

<div class="diff-panel" *ngIf="sameAsCurrent">
  <b *ngIf="!restored">This is same as current published version</b>
  <b *ngIf="restored">This version has been restored</b>
  <div *ngIf="restored" style="margin-top: 16px;">
    <button mat-flat-button [routerLink]="backLink" color="primary">Return to restored version</button>
  </div>
</div>

<div *ngIf="errorMessage" class="error-panel">
  <pre>{{errorMessage}}</pre>
  <div class="error-close" (click)="closeError()"><mat-icon>close</mat-icon></div>
</div>

<div class="set-container" *ngIf="uiReady" (keydown)="onKeyDown($event)" tabindex="0">
  <div *ngFor="let prop of sc.properties">
    <div *ngIf="isVisible(prop)">
      <div *ngIf="['header'].includes(prop.type)">
        <h2>{{prop.title.sv}}</h2>
      </div>

      <div *ngIf="['html'].includes(prop.type)" class="section">
        <div [innerHTML]="prop.text"></div>
      </div>

      <div *ngIf="['link'].includes(prop.type)">
        <p><a href="{{prop.link}}" target="_blank">{{prop.desc.sv}}</a></p>
      </div>

      <mat-form-field *ngIf="['string','integer'].includes(prop.type)" appearance="outline" class="set-form">
        <mat-label>{{prop.title.sv}}<mat-icon *ngIf="isOnlyForAdmin(prop)" style="padding-left: 8px;;">admin_panel_settings</mat-icon></mat-label>
        <input matInput [type]="getInputType(prop)" [formControl]="formControls[prop.key]">
        <mat-icon matSuffix *ngIf="prop.pwdField" (click)="prop.hidePwd = !prop.hidePwd;">{{prop.hidePwd ? 'visibility_off' : 'visibility'}}</mat-icon>
        <span *ngIf="prop.prefix" matPrefix>{{prop.prefix}}&nbsp;</span>
        <span *ngIf="prop.suffix" matSuffix>{{prop.suffix}}</span>
      </mat-form-field>
      <div *ngIf="['string','integer'].includes(prop.type) && prop.desc" class="prop-desc2">{{prop.desc.sv}}</div>

      <mat-form-field *ngIf="['text_field'].includes(prop.type)" appearance="outline" class="set-form">
        <mat-label>{{prop.title.sv}}</mat-label>
        <textarea matInput [type]="getInputType(prop)" [formControl]="formControls[prop.key]" matInput cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="10"></textarea>
        <span *ngIf="prop.prefix" matPrefix>{{prop.prefix}}&nbsp;</span>
        <span *ngIf="prop.suffix" matSuffix>{{prop.suffix}}</span>
      </mat-form-field>
      <div *ngIf="['text_field'].includes(prop.type) && prop.desc" class="prop-desc2">{{prop.desc.sv}}</div>

      <div class="cb-panel" *ngIf="['bool'].includes(prop.type)" style="display: flex; align-items: center;">
        <mat-checkbox class="set-cb" [(ngModel)]="booleans[prop.key]" color="primary" [disabled]="version != null">{{prop.title.sv}}</mat-checkbox>
        <mat-icon *ngIf="isOnlyForAdmin(prop)">admin_panel_settings</mat-icon>
      </div>
      <div *ngIf="['bool'].includes(prop.type) && prop.desc" class="prop-desc3">{{prop.desc.sv}}</div>

      <div *ngIf="prop.type === 'table' || prop.type?.endsWith('[]')" class="table-prop">
        <div class="prop-title">{{prop.title.sv}}<mat-icon *ngIf="isOnlyForAdmin(prop)">admin_panel_settings</mat-icon></div>
        <div class="prop-desc" *ngIf="prop.desc">{{prop.desc.sv}}</div>
        <hot-table
          width="100%"
          height="auto"
          [hotId]="prop.key"
          [settings]="hotSettings[prop.key]">
        </hot-table>
      </div>

      <div class="header-panel" *ngIf="prop.header">
        {{prop.header}}
      </div>

      <div *ngIf="['enum'].includes(prop.type)">
        <mat-form-field appearance="outline" class="set-form">
          <mat-label>{{prop.title.sv}}<mat-icon *ngIf="isOnlyForAdmin(prop)" style="margin-left: 8px;" matsuffix>admin_panel_settings</mat-icon></mat-label>
          <mat-select [(value)]="enums[prop.key]">
            <mat-option *ngIf="prop.optional" [value]="null">Av</mat-option>
            <mat-option *ngFor="let enum of prop.values" [value]="enum.key">{{enum.title.sv}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="prop-desc2" *ngIf="prop.desc">{{prop.desc.sv}}</div>
      </div>
    </div>

    <div class="link-preview-container" *ngIf="prop.table_links">
      <div class="link-preview" *ngFor="let link of links | keyvalue">
        <h1 style="text-align:center;">{{link.key}}</h1>
        <div style="text-align:center;"><iframe [src]="link.value.safeUrl"></iframe></div>
        <div><a href="{{link.value.url}}">{{link.value.url}}</a></div>
      </div>
    </div>
  </div>

  <div style="margin: 32px 0 0 -16px;" *ngIf="!fragment">
    <app-wiki-display section="settings" [path]="page"></app-wiki-display>
  </div>

</div>

<div class="diff-panel" *ngIf="detailedDiffToCurrent">
  <b>Changes to current published version</b>
  <ngx-json-viewer [json]="detailedDiffToCurrent"></ngx-json-viewer>
</div>

<div class="diff-panel" *ngIf="fragment">
  <b>Changes this version did</b>
  <ngx-json-viewer [json]="fragment.diff"></ngx-json-viewer>
</div>
