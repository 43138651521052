<h2 mat-dialog-title>{{venueName}}</h2>
<div mat-dialog-content style="position: relative;">

  <mat-form-field class="indialog" appearance="outline">
    <mat-label>Titel</mat-label>
    <input matInput [(ngModel)]="ticket.title" required>
  </mat-form-field>

  <mat-form-field class="indialog" appearance="outline">
    <mat-label>Beskrivning</mat-label>
    <textarea [(ngModel)]="ticket.content" name="Comments" matInput placeholder="Beskrivning..." cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="10"></textarea>
  </mat-form-field>

  <mat-form-field class="indialog" appearance="outline">
    <mat-label>Status</mat-label>
    <mat-select [(value)]="ticket.state">
      <mat-option *ngFor="let state of stateValues" [value]="state">{{showState(state).name}} {{showState(state).symbol}}</mat-option>
    </mat-select>
  </mat-form-field>

  <div *ngIf="ticket.created">Skapad: {{getTime(ticket.created)}} ({{ticket.userName}})</div>
  <div *ngIf="ticket.updated">Updaterad: {{getTime(ticket.updated)}} ({{ticket.lastUpdateUserName}})</div>
</div>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Avbryt</button>
  <button mat-button *ngIf="ticket.docId" (click)="updateTicket()">Spara</button>
  <button mat-button *ngIf="!ticket.docId" (click)="createTicket()">Lägg till</button>
</mat-dialog-actions>
