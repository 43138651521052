export const pageLocaleSettings = {
  title: { sv: "Lokalisering (rör ej)" },
  page_settings: {
    root_path: "locale",
  },
  desc: {sv: "Inställningar för restaurangen"},
  properties: [
    {header: "Status"},
    {key: "main_locale", title: {sv: "Grundspråk"}, desc: {sv: 'Språk inställning'}, type: "enum", optional: true,
      values: [
        {key: "sv_SE", title: {sv: "Svenska"}},
        {key: "fi_FI", title: {sv: "Finska"}},
        {key: "da_DK", title: {sv: "Danska"}},
        {key: "no_NO", title: {sv: "Norska"}},
        {key: "en_UK", title: {sv: "Engelska (England)"}},
        {key: "en_US", title: {sv: "Engelska (USA)"}},
        {key: "de_DE", title: {sv: "Tyska"}},
        {key: "fr_FR", title: {sv: "Franska"}},
        {key: "es_ES", title: {sv: "Spanska"}},
        {key: "it_IT", title: {sv: "Italienska"}},
        {key: "pt_PT", title: {sv: "Portugisiska"}},
        {key: "nl_NL", title: {sv: "Holländska"}},
        {key: "pl_PL", title: {sv: "Polska"}},
      ]
    },
    {key: "currency", title: {sv: "Valuta"}, desc: {sv: 'Valutan som alla priser och belopp skall använda'}, type: "enum", optional: true,
      values: [
        {key: "SEK", title: {sv: "Kronor (SEK)"}},
        {key: "EUR", title: {sv: "Euro (EUR)"}},
        {key: "USD", title: {sv: "Dollar (USD)"}},
        {key: "GBP", title: {sv: "Pund (GBP)"}},
        {key: "DKK", title: {sv: "Danska kronor (DKK)"}},
        {key: "NOK", title: {sv: "Norska kronor (NOK)"}},
        {key: "AUD", title: {sv: "Australienska dollar (AUD)"}},
        {key: "CAD", title: {sv: "Kanadensiska dollar (CAD)"}},
        {key: "CHF", title: {sv: "Schweiziska franc (CHF)"}},
        {key: "CNY", title: {sv: "Kinesiska yuan (CNY)"}},
        {key: "HKD", title: {sv: "Hongkong dollar (HKD)"}},
      ]
    },
    {key: "timezone", title: {sv: "Tidszon"}, desc: {sv: 'Tidzon för restaurangen'}, type: "enum", optional: true,
      values: [
        {key: "Europe/Stockholm", title: {sv: "Svensktid (CET/CEST)"}},
        {key: "Europe/Helsinki", title: {sv: "Finsk tid (EET/EEST)"}},
        {key: "Europe/Copenhagen", title: {sv: "Dansk tid (CET/CEST)"}},
        {key: "Europe/Oslo", title: {sv: "Norsk tid (CET/CEST)"}},
        {key: "Europe/London", title: {sv: "Engelsk tid (GMT/BST)"}},
        {key: "Europe/Berlin", title: {sv: "Tysk tid (CET/CEST)"}},
        {key: "Europe/Paris", title: {sv: "Fransk tid (CET/CEST)"}},
        {key: "Europe/Madrid", title: {sv: "Spansk tid (CET/CEST)"}},
        {key: "Europe/Rome", title: {sv: "Italiensk tid (CET/CEST)"}},
        {key: "Europe/Lisbon", title: {sv: "Portugisisk tid (WET/WEST)"}},
        {key: "Europe/Amsterdam", title: {sv: "Holländsk tid (CET/CEST)"}},
        {key: "Europe/Warsaw", title: {sv: "Polsk tid (CET/CEST)"}},
        {key: "America/New_York", title: {sv: "Eastern Time (ET)"}},
        {key: "America/Chicago", title: {sv: "Central Time (CT)"}},
        {key: "America/Denver", title: {sv: "Mountain Time (MT)"}},
        {key: "America/Los_Angeles", title: {sv: "Pacific Time (PT)"}},
        {key: "Asia/Shanghai", title: {sv: "Kinesisk tid (CST)"}},
        {key: "Australia/Sydney", title: {sv: "Australisk tid (AEST)"}},
        {key: "Pacific/Auckland", title: {sv: "Nya Zeeland tid (NZST)"}},
      ]
    },
    {key: "tax_codes", title: {sv: "Skattekoder"}, desc: {sv: "Manuell inmatning skattekoder"}, type: "table",
      columns: [
        {key: "name", title: {sv: "Namn"}, type: "string", width: 200 },
        {key: "code", title: {sv: "Kod"}, type: "string", width: 200 },
        {key: "relative_rate", title: {sv: "Värde"}, type: "float", width: 100 },
      ]
    },

  ]
};
