import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {ApiToken} from "../../../models/api-token";
import {VenueService} from "../../../services/venue.service";
import {QueryUserDialogComponent} from "../../user-list/query-user-dialog/query-user-dialog.component";
import {HackUtils} from "../../../utils/utils";
import {MatDialog} from "@angular/material/dialog";
import {NewApiTokenDialogComponent} from "./new-api-token-dialog/new-api-token-dialog.component";
import {Router} from "@angular/router";

@Component({
  selector: 'app-api-token-list',
  templateUrl: './api-token-list.component.html',
  styleUrls: ['./api-token-list.component.css']
})
export class ApiTokenListComponent implements OnInit {

  dataSource: MatTableDataSource<ApiToken>;
  displayedColumns = ["token", "email", "name", "state"];

  constructor(public dialog: MatDialog, private venueService: VenueService, private router: Router) { }

  ngOnInit(): void {
    this.fetchTokens();
  }

  private fetchTokens() {
    this.venueService.fetchApiTokens().then(r => {
      this.dataSource = new MatTableDataSource(r as ApiToken[]);
      //this.dataSource.sort = this.sort;
    });
  }

  addToken() {
    const dialogRef = this.dialog.open(NewApiTokenDialogComponent, HackUtils.DLG({ data: {}}));
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
      this.fetchTokens();
    });

  }

  select(row: any) {
    const token = row.token;
    this.router.navigateByUrl(`tokens/${token}`);
  }
}
