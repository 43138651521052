<div>
  <mat-toolbar>
    <span>Heynow nyhetsbrev</span>
  </mat-toolbar>
</div>
<div style="padding: 20px;" *ngIf="type=='add' && !hideAdd">
  <p style="margin-left: 20px;">Fyll i din e-postadress nedan så får du nyhetsbrevet skickat till dig.</p>
  <form (ngSubmit)="subscribe()">
    <mat-form-field style="width:300px;">
      <input matInput placeholder="E-postadress" [(ngModel)]="email" name="email" required>
    </mat-form-field>
    <div style="margin-top: 20px;margin-left: 16px;">
      <button mat-raised-button color="primary" type="submit">Prenumerera</button>
    </div>
  </form>
</div>
<div style="padding: 20px;" *ngIf="type=='remove' && unSubbed">
  <p style="margin-left: 20px;font-weight: bold;">Du har avregistrerats från nyhetsbrevet</p>
  <form (ngSubmit)="subscribe()">
    <div style="margin-top: 20px;margin-left: 16px;">
      <button mat-raised-button color="" type="submit">Prenumerera</button>
    </div>
  </form>
</div>
<div style="margin-left: 40px;margin-top: 16px;color: grey;">
  {{message}}
</div>
<div style="margin-left: 40px;margin-top: 40px;font-size: large;color:grey;" *ngIf="success">
  👍&nbsp;Du är prenumerant på nyhetsbrevet.
</div>
