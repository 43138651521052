<div>
  <p>
    <mat-toolbar>
      <button mat-icon-button (click)="goBack()"><mat-icon>arrow_back_ios</mat-icon></button>
      <span>Bildbank</span>
      <span class="spacer"></span>
      <button mat-button (click)="getLink(300)" [disabled]="!selectedImage" *ngIf="venueId==null">
        <mat-icon>link</mat-icon> 300
      </button>
      <button mat-button (click)="getLink(600)" [disabled]="!selectedImage" *ngIf="venueId==null">
        <mat-icon>link</mat-icon> 600
      </button>
      <button mat-button (click)="getLink(800)" [disabled]="!selectedImage" *ngIf="venueId==null">
        <mat-icon>link</mat-icon> 800
      </button>

      <button mat-button (click)="fileInput.click()">
<!--        <input type="file" (change)="onFileChanged($event)">-->
        <input style="display: none" type="file" (change)="onFileChanged($event)" #fileInput>
        <mat-icon>add_photo_alternate</mat-icon> Ladda upp
      </button>
    </mat-toolbar>
  </p>
</div>

<div class="media-container">
  <div *ngFor="let img of images" class="cell-panel" (click)="selectImage(img)" [ngClass]="{'selected':selectedImage?.url === img.url}">
    <img [src]="img.url" class="img-cell" alt="">
    <div>{{img.name}}</div>
  </div>
</div>

