import {Component, OnInit, ViewChild} from '@angular/core';
import {TerminalTx} from "../../../models/terminal-tx";
import {FireService} from "../../../services/fire.service";
import {ActivatedRoute} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {VenueService} from "../../../services/venue.service";
import {VenueCron} from "../../../models/venue-cron";

@Component({
  selector: 'app-venue-crons',
  templateUrl: './venue-crons.component.html',
  styleUrls: ['./venue-crons.component.css']
})
export class VenueCronsComponent implements OnInit {

  venueId: string;
  private crons: VenueCron[];

  constructor(private fire: FireService, private route: ActivatedRoute, public dialog: MatDialog, private venueService: VenueService) { }

  displayedColumns = ["when", "type", "next_run", "last_run", "url_kwargs", "data", "run"];
  dataSource: MatTableDataSource<VenueCron>;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = data.get("venue_id");
      this.fetchData();
    });
  }

  public refresh() {
    this.fetchData();
  }

  private fetchData() {
    this.venueService.fetchVenueCrons(Number(this.venueId)).then(crons => {
      this.crons = crons;
      this.dataSource = new MatTableDataSource(this.crons as VenueCron[]);
      this.dataSource.sort = this.sort;
    });
  }

  runCron(key) {
    console.log("runCron", key);
    this.venueService.runVenueCron(Number(this.venueId), key).then(r => {
      console.log("runCron result", r);
    });
  }
}
