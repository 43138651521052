<div class="{{_style}}" *ngIf="!wikiDocEmpty || isSuperAdmin">
  <div class="panel-title">{{_title}}</div>
  <div class="wiki-panel-body" [innerHtml]="_html"></div>
  <div class="wiki-panel-body" *ngIf="wikiDocEmpty && isSuperAdmin">
    <a href (click)="editWiki()" style="color:grey;">Börja redigera wiki:{{_section}}:{{_path}}</a><br/>
    <i>Tomma wikis är bara synliga super admins</i>
  </div>
</div>
<div *ngIf="_section && !wikiDocEmpty && isSuperAdmin" class="edit-btn">
  <a href (click)="editInHeynowWiki()" style="color:grey;">Redigera</a>
  <a routerLink="/doc/{{_section}}/{{_path}}">Doc page</a>
  <a href (click)="copyInternalLink()" style="color:grey;">Doc link</a>
  <a href (click)="editWiki()" style="color:grey;">old wiki</a>
</div>
