<div>
  <p>
    <mat-toolbar>
      <span>Receipt explorer</span>
      <span class="spacer"></span>
<!--      <button mat-icon-button aria-label="Example icon-button with share icon">-->
<!--        <mat-icon (click)="addPerson()">person_add</mat-icon>-->
<!--      </button>-->
    </mat-toolbar>
  </p>
</div>
<div class="container">
  <div class="tool-header">
    <div style="width: 100%;">
      <mat-form-field class="enter" appearance="outline">
        <mat-label>Query</mat-label>
        <input matInput [(ngModel)]="query" (keyup.enter)="newSearch()" >
      </mat-form-field>
    </div>
    <div style="margin-left: 16px;">
      <button mat-flat-button color="primary" (click)="newSearch()">SEARCH</button>
    </div>
  </div>
  <div class="hint">Use: [urlsafe key], [id], bam:[bambora ref], sn:[serial number] v:[venue name]<button mat-button style="margin-left: 16px;" (click)="showHelp()">ⓘ Help</button></div>

  <div style="margin-bottom: 16px;">
    <mat-slide-toggle class="printer-toggle" [(ngModel)]="raw" (ngModelChange)="toggleRaw()" color="primary">Raw</mat-slide-toggle>
    <button mat-button style="margin-left: 16px;" (click)="showReceipt()">Show receipt</button>
    <button mat-button style="margin-left: 16px;" (click)="fixPrice()">Fix oprice/price</button>
    <button mat-button style="margin-left: 16px;" (click)="copyRecipt()">Copy receipt</button>
    <mat-form-field class="command" appearance="outline" *ngIf="showCommand">
      <mat-label>Commands (enter to execute)</mat-label>
      <input matInput [(ngModel)]="commands" (keyup.enter)="sendCommands()" >
    </mat-form-field>
    <button mat-button style="margin-left: 16px;" (click)="showCommand=true;" *ngIf="!showCommand">Commands</button>
  </div>

  <div class="split-container">
    <div *ngIf="receiptDataRaw || receiptData" class="result" style="width: 700px;">
      <pre *ngIf="receiptDataRaw">{{ receiptDataRaw | json }}</pre>
      <ngx-json-viewer *ngIf="receiptData" [json]="receiptData"></ngx-json-viewer>
    </div>
    <div *ngIf="showReceiptKey" style="margin-left: 16px;">
      <app-receipt-view [receiptKey]="showReceiptKey"></app-receipt-view>
    </div>
    <div *ngIf="oprice">
      <div class="result">
        <div style="font-weight: bold;">FIX PRICE DIFF before/after</div>
        <div *ngIf="opriceDiff">
          <ngx-json-viewer [json]="opriceDiff"></ngx-json-viewer>
          <div style="margin-top: 16px;">
            <button mat-flat-button style="margin-left: 16px;" (click)="commitReceiptChanges()" color="warn">Commit
              changes
            </button>
          </div>
        </div>
        <div *ngIf="!opriceDiff">Everything seems OK. No changes found.</div>
      </div>
      <div class="result">
        <div style="font-weight: bold;">Result</div>
        <ngx-json-viewer *ngIf="oprice" [json]="oprice" [expanded]="false"></ngx-json-viewer>
      </div>
    </div>
    <div *ngIf="copy" style="margin-left: 16px;">
      <div>
        <div style="font-weight: bold;">COPY RECEIPT new/old</div>
        <div>
          <ngx-json-viewer [json]="copyDiff"></ngx-json-viewer>
          <div style="margin-bottom: 16px;">
            <button mat-flat-button style="margin-left: 16px;" (click)="editCopy=true" color="primary">Edit copy
            </button>
            <button *ngIf="editData" mat-flat-button style="margin-left: 25px;" (click)="resetEdits()">Reset edits
            </button>
          </div>
          <div class="result" *ngIf="editCopy">
            <div class="edit-container">

              <div style="font-weight: bold;">Edit</div>
              <form>
                <mat-form-field [style.width.px]=300>
                  <mat-label>Field to edit</mat-label>
                  <input matInput placeholder="Ex. paid_date" [(ngModel)]="editModel.field"
                         [ngModelOptions]="{standalone: true}">
                </mat-form-field>

                <mat-form-field [style.width.px]=300>
                  <mat-label>Data</mat-label>
                  <textarea matInput placeholder="{}" rows="10" [(ngModel)]="editModel.data"
                            [ngModelOptions]="{standalone: true}"></textarea>
                </mat-form-field>
                <button mat-flat-button style="margin-left: 16px;" (click)="submitEdit()" color="primary">Submit edit
                </button>
                <mat-slide-toggle style="margin-left: 16px;" class="printer-toggle" [(ngModel)]="recalculatePrice"
                                  color="primary" [ngModelOptions]="{standalone: true}">Recalculate price
                </mat-slide-toggle>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="result">
        <div style="font-weight: bold;">Result</div>
        <ngx-json-viewer *ngIf="copy" [json]="copy" [expanded]="false"></ngx-json-viewer>
      </div>
      <div *ngIf="editData" class="result">
        <div style="font-weight: bold;">Edits to be commited</div>
        <div *ngFor="let item of getApplicableEdits() | keyvalue">{{item.key}}:
          <ngx-json-viewer [json]="item.value" [expanded]="false"></ngx-json-viewer>
        </div>
      </div>
      <mat-slide-toggle class="printer-toggle" style="margin-left: 16px;" [(ngModel)]="runTasks" color="primary">Run
        tasks
      </mat-slide-toggle>
      <div style="margin-top: 16px;">
        <button mat-flat-button style="margin-left: 16px;" (click)="commitCopyRecipt()" color="warn">Commit copy
        </button>
      </div>
    </div>
  </div>
</div>
