import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {VenueService} from "../../../services/venue.service";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {DatePipe} from "@angular/common";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import * as moment from 'moment';
import {combineLatest, Subscription} from "rxjs";
import {QueryVenueTransactionsRequest} from 'src/app/models/reporting';
import {QueryVenueTransactionsResult, TransactionDetails} from 'src/app/models/venue-transactions';

@Component({
  selector: 'app-venue-transactions',
  templateUrl: './venue-transactions.component.html',
  styleUrls: ['./venue-transactions.component.css']
})
export class VenueTransactionsComponent implements OnInit, OnDestroy {
  private paramSub: Subscription;
  private query: string;

  constructor(private venueService: VenueService, private route: ActivatedRoute, public datePipe: DatePipe, private router: Router) {
  }

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = [
    "correction_of_key", "date", "tx_name", "cashbox_id", "payment_method", "direction", "amount_cent", "device_user_email", "staff_name", "comment"
  ];

  @Input() venueId: string;
  title = "Transaktioner";
  venueIdNum: number;
  transactionKey: string;
  searchStartDate: Date;
  searchEndDate: Date;
  dataSource: MatTableDataSource<QueryVenueTransactionsResult>;
  printOptions: TransactionDetails;
  showSpinner = false;

  ngOnInit(): void {
    this.paramSub = combineLatest([
        this.route.paramMap,
        this.route.queryParamMap
      ]
    ).subscribe(res => {
      const param = res[0];
      const query = res[1];
      const q = query.get("q");
      const venueId = param.get("venue_id");
      const shouldFetch = (this.query !== q && q != null) || this.venueId == null;
      this.venueId = venueId;
      this.venueIdNum = Number(venueId);
      this.query = q;
      if (shouldFetch && this.query !== "search") {
        this.setupInitialSort(q);
        this.fetchTransactions();
      }
      this.transactionKey = undefined;
    });
  }

  ngOnDestroy(): void {
    this.paramSub?.unsubscribe();
  }

  fetchTransactions() {
    const request = this.buildRequest();
    this.venueService.queryVenueTransactions(request).then(r => {
      console.log("queryVenueTransactions", r);
      if (r != null) {
        this.dataSource = new MatTableDataSource(r as QueryVenueTransactionsResult[]);
        this.dataSource.sort = this.sort;
      } else {
        this.dataSource = new MatTableDataSource([]);
      }
      }
    );
  }

  private buildRequest() {
    const request = new QueryVenueTransactionsRequest();
    request.venue_id = this.venueId;

    if (this.searchStartDate != null) {
      request.start_date = this.datePipe.transform(this.searchStartDate, 'yyyy-MM-dd');
    }
    if (this.searchEndDate != null) {
      request.end_date = this.datePipe.transform(this.searchEndDate, 'yyyy-MM-dd');
    }
    return request;
  }

  clearFilters() {
    this.searchStartDate = undefined;
    this.searchEndDate = undefined;
  }

  sortChanged($event: any) {
    console.log($event);
    if (this.venueId) {
      this.dataSource = new MatTableDataSource([] as QueryVenueTransactionsResult[]);
      this.fetchTransactions();
    }
  }

  private setupInitialSort(q: string) {
    this.clearFilters();
    switch (q) {
      case "today":
        this.searchStartDate = moment().toDate();
        break;
      case "yesterday":
        this.searchStartDate = moment().subtract(1, "day").toDate();
        break;
      case "all":
        this.searchEndDate = moment().toDate();
        break;
      case "search":
        break;
    }
  }

  select(row: any) {
    this.showSpinner = true;
    this.router.navigateByUrl(`venue/${this.venueId}/reports/venue-transactions/${row.key}`);
    if (row.key === this.transactionKey) {
      this.showSpinner = false;
      return;
    }
    this.venueService.getTransaction(this.venueId, row.key).then(r => {
      this.printOptions = r;
      console.log("getTransaction", r);
      this.transactionKey = row.key;
      this.showSpinner = false;
    });
  }
}
