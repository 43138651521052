<mat-card class="header-card">
  <mat-card-header>
    <mat-card-title>Klientlista</mat-card-title>
    <span class="spacer"></span>
    <button mat-button (click)="toggleViewVersions()">
      <mat-icon>format_list_numbered_rtl</mat-icon> Versioner
    </button>
    <button mat-button (click)="toggleViewVNames()">
      <mat-icon>devices</mat-icon> Modeller
    </button>
    <mat-card-subtitle>Klienter och agenter för alla venues</mat-card-subtitle>
  </mat-card-header>
</mat-card>
<mat-card *ngIf="toggleViewVersion">
  <mat-card-content class="fixed-card">
    <mat-table [dataSource]="versionSource" class="card-table">
      <ng-container matColumnDef="version">
        <mat-header-cell *matHeaderCellDef> Version ({{ getUniqueVersionCount() }}) </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.version}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="devices">
        <mat-header-cell *matHeaderCellDef> Devices ({{ getTotalDevices() }}) </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.devices}} </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="versionColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: versionColumns;"></mat-row>
    </mat-table>
  </mat-card-content>
</mat-card>
<mat-card *ngIf="toggleViewName">
  <mat-card-content class="fixed-card">
    <mat-table [dataSource]="nameSource" class="card-table">
      <ng-container matColumnDef="deviceName">
        <mat-header-cell *matHeaderCellDef> Modell </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.deviceName}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="devices">
        <mat-header-cell *matHeaderCellDef> Enhet </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.devices}} </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="nameColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: nameColumns;"></mat-row>
    </mat-table>
  </mat-card-content>
</mat-card>

<div class="container">

  <div>
    <mat-slide-toggle
      [checked]="hideOfflineSource | async"
      color="primary"
      (change)="hideOfflineSource.next($event.checked)">Dölj offline
    </mat-slide-toggle>
    <mat-slide-toggle
      style="margin-left: 20px"
      [checked]="hideOldSource | async"
      color="primary"
      (change)="hideOldSource.next($event.checked)">Dölj äldre än 24h
    </mat-slide-toggle>
    <mat-slide-toggle
      style="margin-left: 20px"
      [checked]="showOnlyIminVersionSource | async"
      color="primary"
      (change)="showOnlyIminVersionSource.next($event.checked)">Visa endast iMin-version
    </mat-slide-toggle>
  </div>

  <table mat-table [dataSource]="dataSource" [trackBy]="trackById" matSort>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Användare </th>
      <td mat-cell *matCellDef="let element"><mat-icon color="{{element.iconColor}}" style="padding-right: 10px;">{{element.icon}}</mat-icon>{{element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="venueId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Venue </th>
      <td mat-cell *matCellDef="let element"><a routerLink="/venue/{{element.venueId}}/dashboard">{{element.venueId}}</a></td>
    </ng-container>

    <ng-container matColumnDef="appVersionName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> App-version </th>
      <td mat-cell *matCellDef="let element"> {{element.appVersionName}} </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
      <td mat-cell *matCellDef="let element"> {{element.role}} </td>
    </ng-container>

    <ng-container matColumnDef="pingLastHourAverage">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ping avg. </th>
      <td mat-cell *matCellDef="let element"> {{element.pingLastHourAverage}} </td>
    </ng-container>

    <ng-container matColumnDef="pingLastHourSuccessPercentage">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Net quality </th>
      <td mat-cell *matCellDef="let element"> {{element.pingLastHourSuccessPercentage}}% </td>
    </ng-container>

    <ng-container matColumnDef="updated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Uppdaterad </th>
      <td mat-cell *matCellDef="let element"> {{element.updated.toDate() |date:'medium'}} </td>
    </ng-container>

    <ng-container matColumnDef="deviceName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Enhetsnamn </th>
      <td mat-cell *matCellDef="let element"> {{element.deviceName}} </td>
    </ng-container>

    <ng-container matColumnDef="serialNo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Serienummer </th>
      <td mat-cell *matCellDef="let element"> {{element.serialNo}} </td>
    </ng-container>

    <ng-container matColumnDef="iminVersion">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> iMin-version </th>
      <td mat-cell *matCellDef="let element">
        <span [ngClass]="{'flag-text': isVersionOutdated(element.iminVersion)}">
          {{element.iminVersion}}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="batteryOptimization" *ngIf="showOnlyIminVersionSource">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Batterioptimering </th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element?.batteryOptimization !== null && element?.batteryOptimization !== undefined; else noData">
          <span [ngClass]="{'flag-text': element.batteryOptimization === true}">
            {{ element.batteryOptimization ? 'True' : 'False' }}
          </span>
        </ng-container>
        <ng-template #noData></ng-template>
      </td>
    </ng-container>
    
    <ng-container matColumnDef="payStation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Paystation ({{ countPayStationTrue() }}) </th>
      <td mat-cell *matCellDef="let element"> {{element.payStation}} </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>
