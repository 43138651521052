import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {environment} from "../../environments/environment";
import {flatMap} from "rxjs/operators";
import {HeyWikiDocument} from "../models/genji-models";

@Injectable({
  providedIn: 'root'
})
export class HeywikiService {

  constructor(protected http: HttpClient) { }

  private getOptions(): Observable<any> {
    const headers = new HttpHeaders({Authorization: "123"});
    return of({headers, observe: "body", responseType: 'json'});
  }

  private post<T>(endpointUrl: string, body?: any): Observable<T> {
    const baseUrl = environment.wiki_url;
    const url = `${baseUrl}/${endpointUrl}`;
    return this.getOptions().pipe(flatMap(o => this.http.post<T>(url, body, o) as unknown as Observable<T>));
  }

  public fetchDoc(section: string, path: string): Observable<HeyWikiDocument> {
    const body = {path, section, action: "wiki_get"};
    return this.post<HeyWikiDocument>("facade", body );
  }

  public fetchWikiDocs(): Observable<any> {
    const body = {action: "wiki_all"};
    return this.post<any>("facade", body );
  }

  public previewMarkdown(markdown: string): Observable<HeyWikiDocument> {
    const body = {markdown, action: "wiki_html"};
    return this.post<HeyWikiDocument>("facade", body );
  }

  public editDoc(section: string, path: string, markdown: string, editorName: string, editorEmail: string, publish: boolean): Observable<HeyWikiDocument> {
    const body = {section, path, markdown, action: "wiki_edit", editor_name: editorName, editor_email: editorEmail, publish };
    return this.post<HeyWikiDocument>("facade", body );
  }

}
