import { Injectable } from '@angular/core';
import { Invoice, InvoiceBasis } from '../models/billing';
import { AuthorizedHttpService } from './authorized-http-service';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService extends AuthorizedHttpService {

  public async fetchInvoices(venueId?: number): Promise<Invoice[]> {
    const body = {action: "fetch_invoices", venue_id: venueId};
    return this.post<Invoice[]>("api/adm/exp", body);
  }

  public async createInvoices(venueIds?: number[]): Promise<Invoice[]> {
    const body = {action: "create_invoices", venue_ids: venueIds};
    return this.post<Invoice[]>("api/adm/exp", body);
  }

  public async fetchInvoicingBasis(venueIds?: number[]): Promise<InvoiceBasis[]> {
    const body = {action: "get_invoicing_basis", venue_ids: venueIds};
    return this.post<InvoiceBasis[]>("api/adm/exp", body);
  }
}
