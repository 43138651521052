/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

export interface QueryBasicRequest {
  venue_id: string;
  start_date?: string; // Format "YYYY-MM-DD"
  end_date?: string;
}

export class QuerySalesDataRequest {
  venue_id: string;
  start_date?: string; // Example format "2020-01-01"
  end_date?: string;
  start_time?: string;
  end_time?: string;
  interval?: number;
  show_priced_atr?: boolean;
  group_by?: string;
  filter?: string;
  date_type?: string;
  item_ids?: string[];
  include_cogs?: boolean;
}

export interface QuerySalesTreeNode {
  name: string;
  sales: number;
  sales_inc_vat: number;
  vat: string;
  vat_amount: number;
  item_count: number;
  priced_atr: string;
  children: QuerySalesTreeNode[];
}

export interface QuerySalesTreeDataResponse {
  nodes: QuerySalesTreeNode[];
  params: any;
}

export interface QueryHourlyDataResponse {
  hours: QueryHourlyRow[];
  group_by_datekey?: boolean;
}

export interface QueryHourlyRow {
  datekey?: string;
  hour: string;
  item_count: number;
  sales: number;
  sales_inc_vat: number;
  vat_amount: number;
}

export interface QuerySalesReportResponse {
  data: QuerySalesData;
  params: any;
}

export interface QuerySalesData {
  headers: QueryHeader[];
  rows: any[][];
  footers: number[];
}

export interface QueryHeader {
  id: string;
  name: string;
  type: string;
}

export interface QueryTimeSeriesDataResponse {
  query_result: QueryTSRow[];
  group_by_datekey?: boolean;
}

export interface QueryTSRow {
  datekey?: string;
  tumble: string;
  item_count: number;
  sales: number;
  sales_inc_vat: number;
  vat_amount: number;
}

export interface QueryCustomerDataResponse {
  rows: QueryCustomerRow[];
  params: any;
}

export interface QueryCustomerRow {
  org_num: string;
  name: string;
  sales: number;
  sales_inc_vat: number;
  vat_amount: number;
}

export interface QueryStaffDataResponse {
  rows: QueryStaffRow[];
  params: any;
}

export interface QueryStaffRow {
  name: string;
  crew_id: string;
  sales: number;
  sales_vat: number;
  direct_sell_count: number;
  tip: number;
}

export interface QueryStaffClassicSummaryResponse {
  rows: QueryStaffClassicSummaryRow[];
  params: any;
}

export interface QueryStaffClassicSummaryRow {
  name: string;
  accountable_id: string;
  worked_minutes: number;
  amount_vat: number;
  amount_exvat: number;
  discount: number;
  item_count: number;
  receipt_count: number;
  tip: number;
}

export interface QueryZReportSummaryResponse {
  booked_payments: any;
  booked_sales: any;
  booked_vat: any;
  ext_prepaid: any;
  params: any;
  range: {start: string, stop: string};
}

export class QueryVenueTransactionsRequest {
  venue_id: string;
  start_date?: string; // Example format "2020-01-01"
  end_date?: string;
}

export class QueryEventsRequest {
  venue_id: string;
  start_date?: string; // Format "YYYY-MM-DD"
  end_date?: string;
  event_action?: string;
  table?: string;
  amount_low?: number;
  amount_high?: number;
  device?: string;
  staff_id?: string;
  sort_order?: string;
  limit?: number;
  offset?: number;
}

export interface QueryEventsResponse {
  rows: Event[];
}

export interface Event {
  staff_name: string;
  venue_id: string;
  date: string;
  venue_name: string;
  amount_cent: number;
  staff_identity: string;
  device_user_name: string;
  data_json: string;
  table: string;
  device_user_id: string;
  event_action: string;
  desc: string;
  full_count: number;
}

export interface QueryCheckinsResponse {
  checkin_date: string;
  checkin_id: number;
  checkout_date: string;
  datekey: string;
  staff_id: string;
  staff_name: string;
  venue_id: number;
  worked_minutes: number;
}

export interface QueryDiscountItemsResponse {
  rid: string;
  item: string;
  name: string;
  price_vat: number;
  discount: number;
  datekey: string;
  device: string;
  discounter_id: string;
  order_date: string;
  pay_date: string;
  ic: string;
  receipt_id: string;
}

export interface QueryMenuSalesDataResponse {
  rows: QueryMenuSalesRow[];
  params: any;
}

export interface QueryMenuSalesRow {
  item: string;
  name: string;
  item_count: number;
  item_amount: number;
  ic: string;
  section: string;
  category: string;
  tip: number;
  discount: number;
}

export class QueryMenuSalesDataRequest {
  venue_id: string;
  start_date?: string;
  end_date?: string;
  ic?: string;
}

export class QueryDayTimeArticleListRequest {
  venue_id: string;
  start_date: string;
  end_date: string;
}

export interface QueryDayTimeArticleListResponse {
  data: QueryDayTimeArticleListData;
  params: {start_date: string, end_date: string};
}

export interface QueryDayTimeArticleListData {
  headers: dayTimeArticleHeader[];
  rows: any[];
}

export interface dayTimeArticleHeader {
  id: string;
  name: string;
  type: string;

  // Transient
  title?: string;
}

export interface CombinedDayTimeArticleListData {
  datekey: string;
  time_group: string;
  time_group_so: string;
  name: string;
  ic: string;
  sales: number;
  sales_inc_vat: number;
  vat_amount: number;
  vat: string;
  item_count: number;
  group1: string;
  group2: string;
  group3: string;
  group4: string;
}
