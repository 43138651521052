import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {VenueService} from "../../services/venue.service";
import {MatTableDataSource} from "@angular/material/table";
import {Admin} from "../../models/user";
import {MatSort, MatSortable} from "@angular/material/sort";
import {QueryStaffDialogComponent} from "../staff-list/query-staff-dialog/query-staff-dialog.component";
import {HackUtils} from "../../utils/utils";
import {EditPinDialogComponent} from "../staff-list/edit-pin-dialog/edit-pin-dialog.component";
import {DiscountCode, DiscountCodeResult} from "../../models/discount-code";
import {NewDiscountCodeDialogComponent} from "./new-discount-code-dialog/new-discount-code-dialog.component";

@Component({
  selector: 'app-discount-code-list',
  templateUrl: './discount-code-list.component.html',
  styleUrls: ['./discount-code-list.component.css']
})
export class DiscountCodeListComponent implements OnInit {

  venueId: number;
  result: DiscountCodeResult;
  displayedColumns = ["code", "valid", "program", "times_used", "max_usages", "max_count", "users", "balance_left", "balance_init", "valid_to", "created"];
  dataSource: MatTableDataSource<DiscountCode>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(public dialog: MatDialog, private route: ActivatedRoute, private venueService: VenueService) { }

  ngOnInit(): void {
    this.sort.sort(({ id: 'created', start: 'desc'}) as MatSortable);
    this.route.paramMap.subscribe(data => {
      const vn = data.get("venue_id");
      this.venueId = Number(vn);
      this.fetchDiscountCodes();
    });
  }

  private fetchDiscountCodes() {
    this.venueService.fetchDiscountCodes(this.venueId, this.result?.cursor).then(res => {
      this.dataSource = new MatTableDataSource(res.codes as DiscountCode[]);
      this.dataSource.sort = this.sort;
      this.result = res;
    });
  }

  select(row: any) {}

  nextPage() {
    this.fetchDiscountCodes();
  }

  addCode() {
    const dialogRef = NewDiscountCodeDialogComponent.openDialog(this.dialog, this.venueId);
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
      if (result) {
        this.result = null;
        this.fetchDiscountCodes();
      }
    });

  }
}
