import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute} from "@angular/router";
import {VenueService} from "../../../services/venue.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, MatSortable} from "@angular/material/sort";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {GenjiService} from "../../../services/genji.service";
import {HeywikiService} from "../../../services/heywiki.service";
import {HeyWikiDocument} from "../../../models/genji-models";
import { CreateWikiPageComponent } from '../../dialogs/create-wiki-page/create-wiki-page.component';
import { HackUtils } from 'src/app/utils/utils';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wiki-db',
  templateUrl: './wiki-db.component.html',
  styleUrls: ['./wiki-db.component.css']
})
export class WikiDbComponent implements OnInit{
  listName: string;
  section: string;
  path: string;

  constructor(public dialog: MatDialog, private route: ActivatedRoute, private venueService: VenueService, private snackBar: MatSnackBar,
              private genjiService: GenjiService, private heywiki: HeywikiService) { }
  displayedColumns = ["section", "path", "published"]; //, "updated", "editor_email"];
  dataSource: MatTableDataSource<HeyWikiDocument>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit(): void {
    this.sort.sort(({ id: 'first_name', start: 'asc'}) as MatSortable);
    this.route.paramMap.subscribe(data => {
      this.listName = data.get("listname");
      this.fetchDocs();
    });
  }

  fetchDocs() {
    this.heywiki.fetchWikiDocs().subscribe(r => {
      console.log(r);
      this.dataSource = new MatTableDataSource(r.docs as HeyWikiDocument[]);
      this.dataSource.sort = this.sort;
    }, e => SimpleDialogComponent.showErr(this.dialog, e));
  }

  select(row) {
    console.log(row);
    this.section = row.section;
    this.path = row.path;
  }

  editInHeynowWiki(section: string, path: string, token: string = "") {
    this.venueService.createSSOToken().then(res => {
      const url = environment.base_url;
      window.open(`https://heynow-wiki.web.app/#/edit-wiki/${section}/${path}?t=${res.token}:${url}`, "_blank");
    });
    return false;
  }

  newDoc() {
    const dialogref = this.dialog.open(CreateWikiPageComponent, HackUtils.DLG({ data: { }}));
    dialogref.afterClosed().subscribe(r => {
      if (r) {
        this.editInHeynowWiki(r.section, r.path);
      }
    });
  }
}
