<h2 mat-dialog-title>{{dialogTitle}}</h2>
<div mat-dialog-content style="position: relative;">
  <form [formGroup]="form">
    <div>
      <mat-form-field class="indialog">
        <mat-label>Titel</mat-label>
        <input matInput formControlName="title" required>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="indialog">
        <mat-label>Section</mat-label>
        <input matInput formControlName="section" required>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="indialog">
        <mat-label>Path</mat-label>
        <input matInput formControlName="path">
      </mat-form-field>
    </div>
  </form>
  <div>
    <mat-form-field class="indialog">
      <mat-label>Navigation</mat-label>
      <input matInput [attr.value]="getNav()" disabled="true">
      <button mat-icon-button matSuffix (click)="copyNavToClipboard()" aria-label="Copy to clipboard">
        <mat-icon>content_copy</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="loadingService.visibility | async"></mat-spinner>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Avbryt</button>
  <button mat-button (click)="createNewWikiPage()" [disabled]="isCreating">Skapa</button>
</mat-dialog-actions>
