export const pageBooking = {
  title: {sv: "Bokningsinställningar"},
  page_settings: {
    root_path: "booking"
  },
  desc: {sv: "..."},
  properties: [
    {key: "active", title: {sv: "Aktivt för gäster"}, desc: {sv: "Aktiverar möjligheten för gäster att göra bokningar. Påverkar inte redan existerande bokningar."}, type: "bool", keywords: ["bokning","booking"]},
    {key: "slot_length", title: {sv: "Bokningsintervall"}, desc: {sv: "Definierar längden på varje bokningsintervall som gäster kan välja."}, default: 15, type: "integer", suffix: "min", keywords: ["bokningsintervall"]},
    {key: "days_ahead", title: {sv: "Dagar i bokningskalender"}, desc: {sv: "Anger hur många dagar framåt som skall visas i kalendern för gäster vid beställning."}, type: "integer", suffix: "dagar", keywords: ["dagar"]},
    {key: "run_workflow", title: {sv: "Kör workflow vid bokningar"}, desc: {sv: "Aktivera om workflow skall köras vid bokningar."}, type: "bool", keywords: ["bokning","booking"]},
    {key: "wf_prepare_minutes", title: {sv: "Workflow förberedelsetid"}, desc: {sv: "Anger hur långt i förväg som workflowet skall köras i förhållande till bokningstiden (om det är aktiverat)."}, type: "integer", suffix: "min"},
    // {key: "bong_item_filter", title: {sv: "Utskriftsfilter"}, desc: {sv: "Anger vilka artiklar som skall skrivas ut när man skriver ut en bokning"}, type: "string"},
    {key: "open_hours", title: {sv: "Öppettider för bokningar"}, desc: {sv: "Ställ in tillgängliga tider då gäster kan göra bokningar."}, type: "table", additional_rows: 5,
      columns: [
        {key: "when", title: {sv: "När"}, type: "string", width: 200 },
      ]
    },
    {key: "deviations", title: {sv: "Avvikande öppettider"}, desc: {sv: "Avvikande bokningsöppettider: Kan inte vara både öppet och stängt samtidigt."}, type: "table", additional_rows: 5,
      columns: [
        {key: "when", title: {sv: "När"}, type: "string", width: 200 },
        {key: "open_closed", title: {sv: "Öppet/Stängt"},  type: "enum", source: ["open", "closed"], width: 200 },
      ]
    },

  ]
};
