import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Subscription} from "rxjs";
import {AuthService} from "../../../services/auth.service";
import * as moment from "moment/moment";
import {ActivatedRoute} from "@angular/router";
import {FireService} from "../../../services/fire.service";
import {VenueService} from "../../../services/venue.service";
import {VenueConfig} from "../../../models/venue-config";

@Component({
  selector: 'app-chain-device-status',
  templateUrl: './chain-device-status.component.html',
  styleUrls: ['./chain-device-status.component.css']
})
export class ChainDeviceStatusComponent implements OnInit, OnDestroy {
  venueId: number;
  config: VenueConfig;
  private sub: Subscription;

  constructor(private route: ActivatedRoute, private fire: FireService,
              private auth: AuthService, private venueService: VenueService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      if (!this.auth.redirectIfMissingPermissions(this.venueId, "dashboard")) { return; }
      this.beginObserve();
    });
  }

  private beginObserve() {
    this.sub = combineLatest([
        this.fire.observeVenueConfig(this.venueId),
      ]
    ).subscribe(res => {
      this.config = res[0];
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  protected readonly parseInt = parseInt;
}
