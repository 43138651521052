<div>
  <p>
    <mat-toolbar>
      <span>Bokningskalender</span>
      <span class="spacer"></span>
      <button mat-button [matMenuTriggerFor]="menuSave">Kalender: {{namespace}}</button>
      <mat-menu #menuSave="matMenu">
        <button mat-menu-item (click)="changeNS(ns.id)" *ngFor="let ns of namespaces">{{ns.name}}</button>
      </mat-menu>
      <button mat-button (click)="refresh()">
        <mat-icon>refresh</mat-icon> Uppdatera
      </button>
    </mat-toolbar>
  </p>
</div>

<div class="container" *ngIf="slotMatrix">
  <table class="day-col-table">
    <tr>
      <th class="c1"></th>
      <th class="c2" *ngFor="let date of slotMatrix.dates"><button mat-stroked-button (click)="openSummaryPage(date)"><div>{{date}}</div><div style="margin-top: -16px;">{{getWeekDayName(date)}}</div></button></th>
    </tr>
    <tr *ngFor="let row of slotMatrix.rows">
      <td class="time-cell">{{row.time}}</td>
      <td *ngFor="let cell of row.cells" [ngClass]="{'empty-cell': cell.slot == null}">
        <div *ngIf="cell.slot" class="div-row"><div class="mini-label">{{cell.slot?.left}}</div></div>
        <div *ngFor="let booking of cell.bookings" class="booking">
          <a [routerLink]="" (click)="openBooking(booking)">{{booking?.order_hint?.name}}</a>
          <div *ngFor="let item of booking?.order_hint?.items">{{item.name}}</div>
        </div>
      </td>
    </tr>
  </table>
</div>
<div *ngIf="!slotMatrix">
  <div class="center-empty">Finns inga bokningsbara dagar i kalendern.<br>Kontrollerat att slotting är aktiverat och att du har du öppet i perioden.</div>
</div>

