<div class="report-customer-container">

  <app-title-bar [title]="title"></app-title-bar>

  <div class="search-fields-root">
    <div class="search-fields">
      <mat-form-field appearance="outline">
        <mat-label>Day or start date</mat-label>
        <input matInput [(ngModel)]="searchStartDate" [matDatepicker]="startPicker">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker [touchUi]="isMobile"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>End date</mat-label>
        <input matInput [(ngModel)]="searchEndDate" [matDatepicker]="endPicker">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker [touchUi]="isMobile"></mat-datepicker>
      </mat-form-field>
      <button mat-flat-button class="search-button" (click)="fetch()" color="primary">SÖK</button>
      <button mat-stroked-button class="dropdown-button" [matMenuTriggerFor]="menuHandleSection" *ngIf="true"><mat-icon>expand_more</mat-icon></button>
      <mat-menu #menuHandleSection="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="menuExport">Exportera</button>
      </mat-menu>
      <mat-menu #menuExport="matMenu">
        <button mat-menu-item (click)="export('csv')">CSV-format</button>
        <mat-divider></mat-divider>
      </mat-menu>

    </div>
  </div>

  <div>
    <div class="generic-mastdet">
      <div class="generic-master">
        <table mat-table [dataSource]="dataSource" matSort class="clicktable table-cells" matSortActive="pay_date" matSortDirection="desc" matSortDisableClear="true">

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>namn</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <ng-container matColumnDef="sales">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ex.moms</th>
            <td mat-cell *matCellDef="let element"> {{ element.sales }} </td>
          </ng-container>

  <!--        <ng-container matColumnDef="tip">-->
  <!--          <th mat-header-cell *matHeaderCellDef mat-sort-header>Dricks</th>-->
  <!--          <td mat-cell *matCellDef="let element"> {{ element.tip }} </td>-->
  <!--        </ng-container>-->

          <ng-container matColumnDef="vat">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>moms</th>
            <td mat-cell *matCellDef="let element"> {{ element.vat | number : '1.2' }} </td>
          </ng-container>

          <ng-container matColumnDef="orders">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>antal</th>
            <td mat-cell *matCellDef="let element">{{ element.orders }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></tr>
        </table>

        <div class="no-data" *ngIf="noData">Perioden innehåller inga kundrelaterade försäljningar</div>
        <div class="no-data" *ngIf="initData">Välj period ovan för att söka ut kunddata</div>

      </div>

  <!--    <div class="generic-detail">-->
  <!--      <div *ngIf="receiptKey" class="lr-detail-container"><app-receipt-view [venueId]="venueIdNum" [receiptKey]="receiptKey" [isSubComponent]="true"></app-receipt-view></div>-->
  <!--    </div>-->

    </div>
  </div>
</div>
