<h2 mat-dialog-title>Välj datum på z-rapport</h2>
<div mat-dialog-content style="position: relative;">
  <div>
    <mat-form-field appearance="outline" class="indialog">
      <mat-label>Datum</mat-label>
      <input matInput [(ngModel)]="selectedDate" [matDatepicker]="startPicker">
      <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
      <mat-datepicker #startPicker></mat-datepicker>
    </mat-form-field>
    <div>
      <p>Detta bör endasts användas första gången man gör en z-rapport.</p>
      <p>Datumet som väljs är dagen för z-rapporten dvs all försäljning för vald dag</p>
    </div>
  </div>
  <mat-spinner style="position: absolute; top: calc(50% - 15px); left: calc(50% - 15px);" [diameter]="30" *ngIf="loadingService.visibility | async"></mat-spinner>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Avbryt</button>
  <button mat-button (click)="update()">Ok</button>
</mat-dialog-actions>
