import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-wiki-info-link',
  templateUrl: './wiki-info-link.component.html',
  styleUrls: ['./wiki-info-link.component.css']
})
export class WikiInfoLinkComponent implements OnInit {
  @Input() title: string;
  @Input() section: string;
  @Input() path: string;
  @Input() apperance = "wiki-info-panel";

  constructor() { }

  ngOnInit(): void {
  }

}
