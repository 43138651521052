<div class="heynow-logo-container">
    <img src="assets/images/heynow.png" alt="Heynow.ai" class="heynow-logo-image">
  </div>
 <mat-card class="custom-mat-card">
    <mat-card-title>ADMIN LOGIN</mat-card-title>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="login()" *ngIf="!(loadingService.visibility | async)">
            <p>
                <mat-form-field appearance="outline">
                    <input type="text" matInput id="email" required formControlName="email" placeholder="Email"
                        name="email">
                    <mat-error *ngIf="form.get('email').hasError('email')">Invalid format</mat-error>
                    <mat-error *ngIf="form.get('email').hasError('required')">Required</mat-error>
                </mat-form-field>
            </p>
            <p>
                <mat-form-field appearance="outline">
                    <input [type]="hide ? 'password' : 'text'" matInput id="password" required
                       formControlName="password" placeholder="Password" name="password">
                    <button mat-icon-button type="button" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                        [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('password').hasError('minlength')">Password must have at least 6 characters</mat-error>
                    <mat-error *ngIf="form.get('password').hasError('required')">Required</mat-error>
                </mat-form-field>
            </p>

            <div class="button">
                <button mat-flat-button color="primary" class="submit-btn" type="submit" mat-button [disabled]="!form.valid && form.touched">Sign in</button>
            </div>
            <div class=reset>
                <a href="reset">Forgot password?</a>
            </div>
            <br>
             <mat-error class="error">
                {{error}}
            </mat-error>

        </form>
        <mat-spinner [diameter]="200" *ngIf="loadingService.visibility | async"></mat-spinner>
    </mat-card-content>
</mat-card>