import Konva from "konva";
import {ReplaySubject} from "rxjs";
import {FPLine, FPTableData, INIT_SCALE, SNAP_SIZE} from "./floorplan";
import Utils from "../common/utils";
import * as _ from "lodash";
import Line = Konva.Line;
import Shape = Konva.Shape;
import Vector2d = Konva.Vector2d;
import {TableInfo} from "./table-info";
import * as moment from 'moment';

export class FPRenderSection {
  id: string;
  name: string;
  objects: FPRenderTable[];
  lines: FPRenderLine[];
  constructor(id: string, name: string) {
    this.id = id;
    this.name = name;
    this.objects = [];
    this.lines = [];
  }
}

export class FPRenderTable {
  name: string;
  shapeId: string;

  alias: string;
  aliasPrefix: string;
  floor: string;
  type: string;
  chairs: number;
  stations: string[];
  area: string;
  shape: Shape | Konva.Group;
  bgShape: Shape;
  center: Vector2d;
  showAlias = false;
  data: FPTableData;
  private text: Konva.Text;
  private undoPointSubject: ReplaySubject<number>;
  private tableInfo: TableInfo;
  private moneyText: Konva.Text;
  private moneyRect: Konva.Rect;
  private newText: Konva.Text;
  private newRect: Konva.Rect;
  private browsingText: Konva.Text;
  private browsingRect: Konva.Rect;

  constructor(name: string, alias: string, aliasPrefix: string, area: string, type: string, data: FPTableData,
              showAlias: boolean, undoPointSubject: ReplaySubject<number>, tableInfo: TableInfo) {
    this.name = name;
    this.alias = alias;
    this.aliasPrefix = aliasPrefix;
    this.showAlias = showAlias;
    this.type = type;
    this.area = area;
    this.data = data;
    this.tableInfo = tableInfo;
    this.shapeId = Utils.randomString(10);
    this.shape = this.createTableGroup(data);
    this.undoPointSubject = undoPointSubject;
  }

  public get x() {
    return this.shape.x();
  }

  public get y() {
    return this.shape.y();
  }

  public changeName(name: string, alias: string) {
    this.name = name;
    this.alias = alias;
    this.text.fontSize(this.getFontSize());
    this.text.text(this.getObjectText());
  }

  public textUpdated() {
    this.text.fontSize(this.getFontSize());
    this.text.text(this.getObjectText());
  }

  createTableGroup(data: FPTableData): Konva.Group {
    const sx = data.scaleX ?? INIT_SCALE;
    const sy = data.scaleY ?? INIT_SCALE;
    const group = new Konva.Group({
      x: data.x, y: data.y,
      width: SNAP_SIZE * sx,
      height: SNAP_SIZE * sy,
      rotation: data.rotation ?? 0,
      scaleX: 1,
      scaleY: 1,
      draggable: false,
      name: "movable",
      id: this.shapeId
    });

    const tc = this.getTableColor(this.tableInfo);
    if (this.type === "circle") {
      const circle = new Konva.Ellipse({
        radiusX: group.width() / 2,
        radiusY: group.height() / 2,
        fill: tc,
        name: "gr_circ",
        offsetX: -group.width() / 2,
        offsetY: -group.height() / 2
      });
      this.bgShape = circle;
      group.add(circle);
      this.center = {x: data.x + group.width() / 2, y: data.y + group.height() / 2};
    } else {
      const rect = new Konva.Rect({
        width: group.width(),
        height: group.height(),
        fill: tc,
        name: "gr_rect",
        cornerRadius: [6, 6, 6, 6]
      });
      this.bgShape = rect;
      group.add(rect);
      this.center = {x: data.x + group.width() / 2, y: data.y + group.height() / 2};
    }

    this.text = new Konva.Text({
      text: this.getObjectText(),
      fontSize: this.getFontSize(),
      fontFamily: 'Roboto',
      fill: '#fff',
      width: group.width() * group.scaleX(),
      height: group.height() * group.scaleY(),
      verticalAlign: 'middle',
      align: 'center',
      name: "gr_text",
      offsetY: -2,
      scaleX: 1 / group.scaleX(),
      scaleY: 1 / group.scaleY(),
    });
    group.add(this.text);

    this.createMoneyShape(group);
    this.createNewShape(group);
    this.createBrowsingShape(group);

    return group;
  }

  private createMoneyShape(group: Konva.Group) {
    const mrectw = 50;
    this.moneyRect = new Konva.Rect({
      width: mrectw,
      height: 24,
      fill: "#c22513",
      name: "m_rect",
      cornerRadius: [12, 12, 12, 12],
      offsetY: -group.height() * 0.83,
      offsetX: -group.width() / 2 + mrectw / 2,
      visible: false
    });
    group.add(this.moneyRect);

    this.moneyText = new Konva.Text({
      text: "",
      fontSize: 16,
      fontFamily: 'Roboto',
      fill: '#fff',
      width: mrectw,
      height: 24,
      verticalAlign: 'middle',
      align: 'center',
      name: "m_text",
      offsetY: -group.height() * 0.83 - 1,
      offsetX: -group.width() / 2 + mrectw / 2,
      visible: false
    });
    group.add(this.moneyText);

    this.updateMoney(this.tableInfo);
  }

  private createNewShape(group: Konva.Group) {
    const mrectw = 48;
    const ox = mrectw / 2;
    const oy = 13;
    this.newRect = new Konva.Rect({
      width: mrectw,
      height: 24,
      fill: "#ff61fd",
      name: "n_rect",
      cornerRadius: [12, 12, 12, 12],
      offsetY: oy,
      offsetX: ox,
      visible: false
    });
    group.add(this.newRect);

    this.newText = new Konva.Text({
      text: "NEW",
      fontSize: 16,
      fontFamily: 'Roboto',
      fill: '#fff',
      width: mrectw,
      height: 24,
      verticalAlign: 'middle',
      align: 'center',
      name: "n_text",
      offsetY: oy - 2,
      offsetX: ox,
      visible: false
    });
    group.add(this.newText);

    this.updateNew(this.tableInfo);
  }

  private createBrowsingShape(group: Konva.Group) {
    const mrectw = 48;
    const ox = -group.width() + mrectw / 2;
    const oy = 13;
    this.browsingRect = new Konva.Rect({
      width: mrectw,
      height: 24,
      fill: "#434343",
      name: "n_rect",
      cornerRadius: [12, 12, 12, 12],
      offsetY: oy,
      offsetX: ox,
      visible: false
    });
    group.add(this.browsingRect);

    this.browsingText = new Konva.Text({
      text: "...",
      fontSize: 32,
      fontFamily: 'Roboto',
      fill: '#fff',
      width: mrectw,
      height: 24,
      verticalAlign: 'middle',
      align: 'center',
      name: "n_text",
      offsetY: oy + 2,
      offsetX: ox,
      visible: false
    });
    group.add(this.browsingText);

    this.updateBrowsing(this.tableInfo);
  }

  addToLayer(layer: Konva.Layer) {
    const g = this.shape as Konva.Group;
    g.on('dragend', (e) => {
      g.position({
        x: Math.round(g.x() / SNAP_SIZE) * SNAP_SIZE,
        y: Math.round(g.y() / SNAP_SIZE) * SNAP_SIZE
      });
    });
    layer.add(this.shape);
  }

  removeFromLayer(layer: Konva.Layer) {
    this.shape.remove();
  }

  private getObjectText() {
    if (this.showAlias) {
      return this.alias;
    } else {
      return this.name;
    }
  }

  private getFontSize() {
    if (this.showAlias) {
      return 28;
    } else {
      return 16;
    }
  }

  updateSelection(isSelected: boolean) {
    if (isSelected) {
      this.bgShape.stroke("#f5a623");
      this.bgShape.strokeWidth(4);
    } else {
      this.bgShape.stroke(undefined);
      this.bgShape.strokeWidth(undefined);
    }
  }

  private getTableColor(tableInfo: TableInfo) {
    const color = tableInfo?.active ? "#7ED321" : "#8B572A";
    return color;
  }

  public updateFromTableInfo(tableInfo: TableInfo): boolean {
    this.tableInfo = tableInfo;
    this.updateMoney(tableInfo);
    const showNew = this.updateNew(tableInfo);
    const showBrowsing = this.updateBrowsing(tableInfo);
    this.bgShape.fill(this.getTableColor(tableInfo));
    return showNew || showBrowsing;
  }

  private updateMoney(tableInfo: TableInfo) {
    let showMoney = false;
    if (tableInfo) {
      const p = this.tableInfo?.price?.price;
      if (p) {
        const t = `${p.toFixed(0)}`;
        this.moneyText.text(t);
        showMoney = true;
      }
    }

    this.moneyText.visible(showMoney);
    this.moneyRect.visible(showMoney);
  }

  private updateNew(tableInfo: TableInfo): boolean {
    let showNew = false;
    if (tableInfo?.lastFinalizedDate) {
      const sec = moment().diff(tableInfo.lastFinalizedDate, "seconds");
      showNew = sec < 90;
    }
    this.newText.visible(showNew);
    this.newRect.visible(showNew);
    return showNew;
  }

  private updateBrowsing(tableInfo: TableInfo): boolean {
    let showBrowsing = false;
    if (tableInfo?.lastActivityDate) {
      const sec = moment().diff(tableInfo.lastActivityDate, "seconds");
      showBrowsing = sec < 60;
    }
    this.browsingText.visible(showBrowsing);
    this.browsingRect.visible(showBrowsing);
    return showBrowsing;
  }

}

export class FPRenderLine {
  shapeId: string;
  type: string;
  shape: Line;
  vertices: Vector2d[] = [];
  private layer: Konva.Layer;
  private undoPointSubject: ReplaySubject<number>;

  constructor(type: string, undoPointSubject: ReplaySubject<number>, vertices: Vector2d[]) {
    this.type = type;
    this.shapeId = Utils.randomString(10);
    this.undoPointSubject = undoPointSubject;
    this.vertices = vertices;
  }

  public get x() {
    return this.shape.x();
  }

  public get y() {
    return this.shape.y();
  }

  createLine(): Konva.Line {
    const line = new Konva.Line({
      points: _.flatten(this.vertices.map(p => [p.x, p.y])),
      stroke: "#999999",
      strokeWidth: 7,
      lineCap: 'round',
      lineJoin: 'round',
      name: "line",
    });
    return line;
  }

  addToLayer(layer: Konva.Layer) {
    this.shape = this.createLine();
    this.layer = layer;
    this.layer.add(this.shape);
    this.shape.moveToBottom();
  }

  removeFromLayer() {
    if (this.shape) {
      this.shape.remove();
    }
    for (const vertex of this.vertices) {
    }
  }
}
