import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject, from, Observable, of, Subscription, throwError } from 'rxjs';
import {catchError, filter, first, flatMap, map, tap} from 'rxjs/operators';
import { AuthState } from '../models/signin';
import { ApiService } from './api.service';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from "firebase/compat/app";
import auth = firebase.auth;
import {LinkUser} from "../models/user";

enum Provider {
  GOOGLE = "google",
  APPLE = "apple"
}

@Injectable()
export class AuthService {

  public authStateSub: BehaviorSubject<AuthState> = new BehaviorSubject({user: null, result: "unresolved"});
  private singinPipeSub: Subscription;

  public firebaseUser: firebase.User;
  public working: boolean;
  public authStateSnapshot: AuthState;


  constructor(
    private firebaseAuth: AngularFireAuth,
    private api: ApiService,
    private router: Router,
    private route: ActivatedRoute
    ) {
    this.setupSigninPipe();
  }

  public static isSuperAdmin(authState: AuthState): boolean {
    for (const role of authState?.user?.roles ?? []) {
      if (role.chain_id === "heynow_admin") {
        return true;
      }
    }
    return false;
  }

  public static isInvestor(authState: AuthState): boolean {
    for (const role of authState?.user?.roles ?? []) {
      if (role.chain_id === "heynow_investor") {
        return true;
      }
    }
    return false;
  }

  public static getUserVenues(authState: AuthState): any {
    const venues = [];
    for (const role of authState?.user?.roles ?? []) {
      if (role.venue_id) {
        venues.push({name: role.venue_name, id: role.venue_id, chain_key: role.chain_key});
      }
    }
    return venues;
  }

  public redirectIfMissingPermissions(venueId: string | number, perm: string): boolean {
    if (!this.hasPermission(venueId, perm)) {
      this.redirectToPermissionPage(venueId);
      return false;
    }
    return true;
  }

  public hasPermission(venueId: string | number, perm: string): boolean {
    const nvid = Number(venueId);
    for (const role of this.authStateSnapshot?.user?.roles ?? []) {
      if (role.chain_id === "heynow_admin") { return true; }
      if (role.venue_id === nvid) {
        //console.log("role.permissions:", role.permissions);
        return role.permissions.includes(perm);
      }
    }
  }

  private redirectToPermissionPage(venueId: string | number) {
    const nvid = Number(venueId);
    for (const role of this.authStateSnapshot?.user?.roles ?? []) {
      if (role.venue_id === nvid) {
        if (role.permissions.includes("dashboard")) {
          this.router.navigateByUrl(`venue/${venueId}/dashboard`);
          return;
        }
        if (role.permissions.includes("pos")) {
          this.router.navigateByUrl(`venue/${venueId}/pos/fp/null`);
          return;
        }
      }
    }
  }

  public disableSigninPipe() {
    console.log("disableSigninPipe...");
    this.singinPipeSub?.unsubscribe();
  }

  public setupSigninPipe(): void {
    console.log("setupSigninPipe...");
    this.singinPipeSub = this.firebaseAuth.authState
      .pipe(flatMap(u => this.signinToServer(u)))
      .subscribe(authData => this.postSigninActions(authData), error => console.log(error));
  }

  private postSigninActions(authData: { firebaseUser: firebase.User, serverAuthState: AuthState }): void {
    if (authData.firebaseUser == null || authData.serverAuthState.result !== "valid") {
      console.log("postSigninActions: invalid authData");
      this.authStateSub.next(authData.serverAuthState);
    } else {
      console.log("postSigninActions: valid authData");
      this.authStateSub.next(authData.serverAuthState);
      this.setUserData(authData.serverAuthState, authData.firebaseUser);
      console.log(this.router?.url);
      // TODO this makes sure a new user is redirected to the dashboard page
      // TODO this is not the best way to do this since it will redirect to the dashboard page in other cases also
      if (this.router?.url === "/" && authData.serverAuthState.user.roles.length > 0) {
        const firstRole = authData.serverAuthState.user.roles[0];
        if (firstRole.chain_id == null && firstRole.venue_id != null) {
          //Navigate to dashboard
          this.router.navigateByUrl(`venue/${firstRole.venue_id}/dashboard`);
          return;
        }
      }
      if (this.router?.url === "/signin") {
        this.router.navigate([""]);
      }
    }
  }

  private signinToServer(u: firebase.User): Observable<{ firebaseUser: firebase.User, serverAuthState: AuthState }> {
    console.log(`signinToServer user: ${u?.email}`);
    if (u == null) {
      this.authStateSnapshot = null;
      this.firebaseUser = null;
      return of({ firebaseUser: null, serverAuthState: {user: null, result: "signedOut"} });
    }
    return from(u.getIdToken()).pipe(
      flatMap(t => this.api.setupUserAccount(t)),
      map(serverAuthState => ({ firebaseUser: u, serverAuthState }))
    );
  }

  public login(email: string, password: string, linkToken?: string): Observable<auth.UserCredential> {
    this.working = true;
    return from(this.firebaseAuth.signInWithEmailAndPassword(email, password));
  }

  public loginWithServerActions(email: string, password: string, linkToken?: string): Observable<void> {
    this.working = true;
    return from(this.firebaseAuth.signInWithEmailAndPassword(email, password))
    .pipe(flatMap(u => this.signinToServer(u.user)))
    .pipe(flatMap(authData => of(this.postSigninActions(authData))));
  }

  public currentAuthState(): Observable<AuthState> {
    return this.authStateSub;
  }

  public getLoggedInAuthState(): Observable<AuthState> {
    return this.authStateSub.pipe(filter(aths => aths.user != null), first());
  }

  private setUserData(authState: AuthState, firebaseUser: firebase.User): void {
    this.authStateSnapshot = authState;
    this.firebaseUser = firebaseUser;
  }

  public logout(): Promise<void> {
    this.working = true;
    return this.firebaseAuth.signOut().then(u => {
      console.log("logout:Route to signin");
      this.router.navigate(["signin"]);
      this.authStateSnapshot = null;
      this.firebaseUser = null;
    });
  }

  public currentJwtToken(): Observable<string> {
    return from(this.firebaseUser.getIdToken());
  }

  public async googleAuth(): Promise<void> {
    this.signInWithProvider(Provider.GOOGLE);
  }

  public async appleAuth(): Promise<void> {
    this.signInWithProvider(Provider.APPLE);
  }

  public createFirebaseAccount(email: string, password: string): Observable<string> {
    return from(this.firebaseAuth.createUserWithEmailAndPassword(email, password))
      .pipe(flatMap(cred => from(cred?.user?.getIdToken()).pipe(map(token => {
        return token;
      }))));
  }

  public resetPassword(email: string): Observable<void> {
    return from(this.firebaseAuth.sendPasswordResetEmail(email));
  }

  public confirmPasswordReset(oobCode: string, password: string): Observable<void> {
    return from(this.firebaseAuth.confirmPasswordReset(oobCode, password));
    }

  private getProviderObject(provider: Provider): auth.OAuthProvider | auth.GoogleAuthProvider {
    let p: auth.OAuthProvider | auth.GoogleAuthProvider;
    if (provider === Provider.GOOGLE) {
      p = new auth.GoogleAuthProvider();
    } else if (provider === Provider.APPLE) {
      p = new auth.OAuthProvider('apple.com');
    }
    return p;
  }

  private async signInWithProvider(provider: Provider): Promise<void> {
    try {
      this.working = true;
      const p = this.getProviderObject(provider);
      p.setCustomParameters({ prompt: "select_account" });
      const resp = await this.providerLogin(p);
      this.firebaseUser = resp.user;
      console.log("Signed in", provider);
    } catch (error) {
      console.log("Provider signin faild...Error:", provider, error);
      this.working = false;
    }
  }

  private async providerLogin(provider: auth.AuthProvider): Promise<auth.UserCredential> {
    return await this.firebaseAuth.signInWithPopup(provider) as auth.UserCredential;
  }

  public async createAndLinkFirebaseAccount(leadKey: string, email: string, password: string): Promise<any> {
    this.disableSigninPipe();
    console.log(`Create new account for lead ${email}/${password}...`);
    try {
      const cred = await this.firebaseAuth.createUserWithEmailAndPassword(email, password);
      console.log(`Firebase user created: ${cred}`);
      const token = await cred.user.getIdToken();
      console.log(`Token: ${token}`);
      const result = await this.createAccountForLead(leadKey, token).toPromise();
      console.log(`Account created: ${result}`);
    } catch (err) {
      //await this.failedToCreateAccountTrySignIn(err, email, password);
      console.log("Failed to create account", err);
      throw err;
    }
  }

  public async signInAndLinkFirebaseAccount(leadKey: string, email: string, password: string): Promise<any> {
    this.disableSigninPipe();
    console.log(`Sign in account for lead ${email}/${password}...`);
    try {
      const cred = await this.firebaseAuth.signInWithEmailAndPassword(email, password);
      console.log(`Firebase user signed in: ${cred}`);
      const token = await cred.user.getIdToken();
      console.log(`Token: ${token}`);
      const result = await this.createAccountForLead(leadKey, token).toPromise();
      console.log(`Account created: ${result}`);
    } catch (err) {
      console.log("Failed to login account", err);
      throw err;
      //await this.failedToCreateAccountTrySignIn(err, email, password);
    }
  }

  private createAccountForLead(leadKey: string, token: string): Observable<LinkUser> {
    console.log(`Create account for lead ${leadKey}...`);
    return this.api.createLeadAccount(leadKey, token)
      .pipe(map(result => {
        if (result?.admin_auth_id) {
          this.setupSigninPipe();
          return result;
        }
        return result;
      }));
  }

  private async failedToCreateAccountTrySignIn(err, email: string, password: string) {
    console.log("Failed to create account, trying to sign in...");
    console.log(err);
    if (err?.code === "auth/email-already-in-use") {
      const u = await this.firebaseAuth.signInWithEmailAndPassword(email, password);
      this.signinToServer(u.user);
    }
  }

  public getSignInMethods(email): Promise<any> {
    return this.firebaseAuth.fetchSignInMethodsForEmail(email);
  }

  public signInWithCustomToken(token: string) {
    //convert this.firebaseAuth.signInWithCustomToken(token) to observable
    return from(this.firebaseAuth.signInWithCustomToken(token)).pipe(
      tap(u => {
        console.log("Signed in with custom token", u);
        this.firebaseUser = u.user;
      })
    );
  }
}
