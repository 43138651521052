<div>
  <p>
    <mat-toolbar>
      <span>Wiki DB</span>
      <span class="spacer"></span>
      <app-wiki-info-link title="Lär dig mer" section="support" path="admins"></app-wiki-info-link>
      <button mat-button (click)="newDoc()">
        <mat-icon>create_new_folder</mat-icon> Ny wiki-sida
      </button>
    </mat-toolbar>
  </p>
</div>
<div class="container">
  <div class="list">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="section">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>section</th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;max-width: 125px;font-size:12px;white-space: normal;word-wrap: break-word;"> {{element.section}} </td>
      </ng-container>

      <ng-container matColumnDef="path">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>path</th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;max-width: 125px;font-size:12px;white-space: normal;word-wrap: break-word;"> {{element.path}} </td>
      </ng-container>

      <ng-container matColumnDef="editor_email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>editor_email</th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.editor_email}} </td>
      </ng-container>

      <ng-container matColumnDef="published">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>published</th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.published | utcDate}} </td>
      </ng-container>

      <ng-container matColumnDef="updated">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>updated</th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.updated | utcDate}} </td>
      </ng-container>

      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>created</th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.created | utcDate}} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;">
  <!--        <a href="{{baseUrl}}/list/{{listName}}/remove?t={{element.unsubscribe_token}}" target="_blank">unsubscribe</a>-->
          <!--        <button mat-button [matMenuTriggerFor]="menuReports">Edit</button>-->
          <!--        <mat-menu #menuReports="matMenu">-->
          <!--          <button mat-menu-item (click)="editRoles(element)">Redigera roll</button>-->
          <!--          <mat-divider></mat-divider>-->
          <!--          <button mat-menu-item (click)="resendInvite(element)">Skicka inbjudan igen</button>-->
          <!--        </mat-menu>-->
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></tr>
    </table>
  </div>
  <div *ngIf="section" class="details">
    <app-wiki-display [section]="section" [path]="path"></app-wiki-display>
  </div>
</div>
