import {Component, Inject, NgZone, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MenuStateService} from "../../menu-utils/menu-state.service";
import {Item, PushPage} from "../../menu-models/PublishModels";
import {HotTableRegisterer} from "@handsontable/angular";
import Handsontable from "handsontable";
import CellChange = Handsontable.CellChange;
import ChangeSource = Handsontable.ChangeSource;
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-menu-select-items-dialog',
  templateUrl: './menu-select-items-dialog.component.html',
  styleUrls: ['./menu-select-items-dialog.component.css']
})
export class MenuSelectItemsDialogComponent implements OnInit {

  private hotRegisterer = new HotTableRegisterer();
  hotId = "hotAtr";
  dataset = [{}, {}, {}, {}, {}, {}];
  attributes: any;
  private pushPages: PushPage[];
  private itemsList: Item[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: {items: string, title: string, itemsList: Item[]},
              public dialogRef: MatDialogRef<MenuSelectItemsDialogComponent>, private state: MenuStateService, private ngZone: NgZone) {
    this.itemsList = data.itemsList;
  }

  columns = [];

  hotSettings = {
    licenseKey: environment.handson_key,
    startRows: 15,
    colHeaders: true,
    rowHeaders: true,
    columns: this.getFullColumnList(),
    contextMenu: {
      items: {
        row_above: {},
        row_below: {},
        remove_row: {},
      }
    },
    width: "400px",
    height: "500px",
  };

  ngOnInit(): void {
    setTimeout( () => {
      const hotInstance = this.hotRegisterer.getInstance(this.hotId);
      if (hotInstance) {
        console.log(`AttributeDialog data: ${this.data}`);
        const value = this.data.items;
        const notEmpty = typeof value !== 'undefined' && value;
        if (notEmpty) {
          this.setupTable(hotInstance, value);
        }
        hotInstance.selectCell(0, 0);

        const that = this;
        // eslint-disable-next-line max-len
        Handsontable.hooks.add('afterChange', (changes: CellChange[] | null, source: ChangeSource) => { that.afterChange(changes, source); }, hotInstance);
        // eslint-disable-next-line max-len
        Handsontable.hooks.add('afterRemoveRow', (index: number, amount: number, physicalRows: number[], source?: ChangeSource) => { }, hotInstance);
        Handsontable.hooks.add('afterCreateRow', (index: number, amount: number, source?: ChangeSource) => { }, hotInstance);
      }
    }, 200 );
  }

  private collectPushes() {
    const hotInstance = this.hotRegisterer.getInstance(this.hotId);
    const rows = hotInstance.countRows();
    const pushes = [];
    for (let i = 0; i < rows; i++) {
      const name = hotInstance.getDataAtRowProp(i, "name");
      if (name) {
        pushes.push({name});
      }
    }
    console.log(pushes);
    return pushes;
  }

  private collectAttributeString() {
    const pushes = this.collectPushes();
    return pushes.map(p => p.name).join(", ");
  }

  okClicked() {
    const s = this.collectAttributeString();
    this.dialogRef.close(s);
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  private setupTable(hotInstance: Handsontable, items: string) {
    const ds = [];
    items.split(",").forEach(g => {
      const d = {name: g.trim()};
      ds.push(d);
    });
    const k = Math.max(15 - ds.length, 5);
    for (let i = 0; i < k; i++) {
      ds.push({});
    }
    this.dataset = ds;
    hotInstance.loadData(this.dataset);
  }

  private getFullColumnList() {
    const that: MenuSelectItemsDialogComponent = this;
    const cs = this.columns;

    cs.splice(0, 0, {data: "name", title: "Name", width: 200, type: "dropdown",
      // @ts-ignore
      source(query, process) {
        console.log(`autocomplete ${query}`);
        process(that.itemsList.map(o => o.id));
      },
      strict: false
    });
    return cs;
  }

  private afterChange(changes: CellChange[] | null, source: ChangeSource) {
    // @ts-ignore
    if (source === "observe") {
      return;
    }

    if (changes) {
      console.log("afterChange");
      console.log(changes);
      console.log(source);
    }
  }


}
