//discount_programs : TABLE	id	name	discount1	formula1:FLOAT	discount2	formula2:FLOAT	max

export const pageDiscountPrograms = {
  title: {sv: "Rabattprogram"},
  page_settings: {
    root_anchor: "discount_programs"
  },
  desc: {sv: ""},
  properties: [
    {key: "discount_programs", title: {sv: "Rabattprogram"}, desc: {sv: ""}, type: "table",
      columns: [
        {key: "id", title: {sv: "ID"}, type: "string", width: 100 },
        {key: "name", title: {sv: "Namn"}, type: "string", width: 200 },
        {key: "discount1", title: {sv: "Filter 1"}, type: "string", width: 200 },
        {key: "formula1", title: {sv: "Formel 1"}, type: "float", width: 100 },
        {key: "discount2", title: {sv: "Filter 2"}, type: "string", width: 200 },
        {key: "formula2", title: {sv: "Formel 2"}, type: "float", width: 100 },
        {key: "max", title: {sv: "Max rabatt"}, type: "integer", width: 100 },
        {key: "hide_in_bill", title: {sv: "Dölj i notor"}, desc: {sv: "Avgör om rabattprogrammet är tillgängligt för hela notan"}, type: "bool", optional: true},
        {key: "show_in_item_selection", title: {sv: "Visa i artiklar"}, desc: {sv: "Avgör om rabattprogrammet är tillgängligt för enstaka artiklar"}, type: "bool", optional: true},
      ]
    },
  ]
};
