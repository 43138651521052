<div class="set-container" *ngIf="uiReady" (keydown)="onKeyDown($event)" tabindex="0">
  <div *ngFor="let prop of visibleProperties">

    <mat-form-field *ngIf="['string','integer'].includes(prop.type)" appearance="outline" class="set-form">
      <mat-label>{{prop.title.sv}}<mat-icon *ngIf="prop.super_admin_only && isSuperAdmin" style="margin-left: 8px;" matsuffix>admin_panel_settings</mat-icon></mat-label>
      <input matInput [type]="getInputType(prop)" [formControl]="formControls[prop.key]" [ngStyle]="{'font-family': prop.font || 'inherit'}">
      <mat-icon matSuffix *ngIf="prop.hideable" (click)="prop.hidden = !prop.hidden;">{{prop.hidden ? 'visibility_off' : 'visibility'}}</mat-icon>
      <span *ngIf="prop.prefix" matPrefix>{{prop.prefix}}&nbsp;</span>
      <span *ngIf="prop.suffix" matSuffix>{{prop.suffix}}</span>
    </mat-form-field>
    <div *ngIf="['string','integer'].includes(prop.type) && prop.desc" class="prop-desc2">{{prop.desc.sv}}</div>

    <mat-form-field *ngIf="['text'].includes(prop.type)" appearance="outline" class="set-form-w">
      <mat-label>{{prop.title.sv}}</mat-label>
      <textarea matInput [type]="getInputType(prop)" [formControl]="formControls[prop.key]" matInput cdkTextareaAutosize cdkAutosizeMinRows="8" cdkAutosizeMaxRows="20" style="width: 800px"></textarea>
      <span *ngIf="prop.prefix" matPrefix>{{prop.prefix}}&nbsp;</span>
      <span *ngIf="prop.suffix" matSuffix>{{prop.suffix}}</span>
    </mat-form-field>
    <div *ngIf="['text'].includes(prop.type) && prop.desc" class="prop-desc2">{{prop.desc.sv}}</div>

    <div *ngIf="['json'].includes(prop.type) && !editJsons[prop.key]">
      <div>{{prop.title.sv}}</div>
      <ngx-json-viewer [json]="jsons[prop.key]"></ngx-json-viewer>
      <button mat-button (click)="editJson(prop)">Edit</button>
    </div>
    <mat-form-field *ngIf="['json'].includes(prop.type) && editJsons[prop.key]" appearance="outline" class="set-form-w">
      <mat-label>{{prop.title.sv}}</mat-label>
      <textarea [(ngModel)]="textEditJsons[prop.key]" matInput rows="20"></textarea>
      <div style="display: flex; flex-direction: row;margin-top: 16px;justify-content: space-between; border-top: 1px solid gray;">
        <button mat-button (click)="finishedEditJson(prop)">Finished edit</button>
        <button mat-button (click)="cancelEditJson(prop)">Cancel edit</button>
      </div>
    </mat-form-field>
    <div *ngIf="['json'].includes(prop.type) && prop.desc" class="prop-desc2">{{prop.desc.sv}}</div>

    <div class="cb-panel" *ngIf="['bool'].includes(prop.type)">
      <mat-checkbox class="set-cb" [(ngModel)]="booleans[prop.key]" color="primary">{{prop.title.sv}}</mat-checkbox>
    </div>
    <div *ngIf="['bool'].includes(prop.type) && prop.desc" class="prop-desc3">{{prop.desc.sv}}</div>

    <mat-form-field *ngIf="['date', 'datetime'].includes(prop.type)" appearance="outline" class="set-form">
      <mat-label>{{prop.title.sv}}</mat-label>
      <input matInput [type]="getInputType(prop)" [matDatepicker]="picker" [formControl]="formControls[prop.key]" placeholder="YYYY-MM-DD">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker [touchUi]="isMobile"></mat-datepicker>
    </mat-form-field>
    <div *ngIf="['date','datetime'].includes(prop.type) && prop.desc" class="prop-desc2">{{prop.desc.sv}}</div>

    <div *ngIf="prop.type === 'table' || prop.type?.endsWith('[]')" class="table-prop">
      <div class="prop-title">{{prop.title.sv}}</div>
      <div class="prop-desc" *ngIf="prop.desc">{{prop.desc.sv}}</div>
      <hot-table
        width="100%"
        height="auto"
        [hotId]="namespace+prop.key"
        [settings]="hotSettings[prop.key]">
      </hot-table>
    </div>

    <div *ngIf="['enum'].includes(prop.type)">
      <mat-form-field appearance="outline" class="set-form">
        <mat-label>{{prop.title.sv}}<mat-icon *ngIf="prop.hidden && isSuperAdmin" style="margin-left: 8px;" matsuffix>admin_panel_settings</mat-icon></mat-label>
        <mat-select [(value)]="enums[prop.key]">
          <mat-option *ngIf="prop.optional" [value]="null">Av</mat-option>
          <mat-option *ngFor="let enum of prop.enums" [value]="enum.value">{{enum.title.sv}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="prop-desc2" *ngIf="prop.desc">{{prop.desc.sv}}</div>
    </div>

    <div *ngIf="['enum_source'].includes(prop.type)">
      <mat-form-field appearance="outline" class="set-form">
        <mat-label>{{prop.title.sv}}<mat-icon *ngIf="prop.hidden && isSuperAdmin" style="margin-left: 8px;" matsuffix>admin_panel_settings</mat-icon></mat-label>
        <mat-select [(value)]="sourceEnums[prop.key]">
          <mat-option *ngIf="prop.optional" [value]="null">Av</mat-option>
          <mat-option *ngFor="let source of getEnums(prop.enum_source)" [value]="source.value">{{source.title.sv}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="prop-desc2" *ngIf="prop.desc">{{prop.desc.sv}}</div>
    </div>

    <div *ngIf="['config_enum'].includes(prop.type) && prop.config_source">
      <mat-form-field appearance="outline" class="set-form">
        <mat-label>{{prop.title.sv}}<mat-icon *ngIf="prop.hidden && isSuperAdmin" style="margin-left: 8px;" matsuffix>admin_panel_settings</mat-icon></mat-label>
        <mat-select [(value)]="configEnums[prop.key]">
          <mat-option *ngIf="prop.optional" [value]="null">Av</mat-option>
          <mat-option *ngFor="let enum of fetchFromConfig(prop.config_source.path, prop.config_source.key)" [value]="enum">{{enum}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="prop-desc2" *ngIf="prop.desc">{{prop.desc.sv}}</div>
    </div>

    <mat-form-field *ngIf="['venue_enum'].includes(prop.type)" appearance="outline" class="set-form">
      <mat-label>{{prop.title.sv}}<mat-icon *ngIf="prop.super_admin_only && isSuperAdmin" style="margin-left: 8px;" matsuffix>admin_panel_settings</mat-icon></mat-label>
      <input matInput [type]="getInputType(prop)" [(value)]="venueEnums[prop.key]" [ngStyle]="{'font-family': prop.font || 'inherit'}" [readonly]="prop.readonly">
      <mat-icon matSuffix *ngIf="prop.hideable" (click)="prop.hidden = !prop.hidden;">{{prop.hidden ? 'visibility_off' : 'visibility'}}</mat-icon>
      <span *ngIf="prop.prefix" matPrefix>{{prop.prefix}}&nbsp;</span>
      <span *ngIf="prop.suffix" matSuffix>{{prop.suffix}}</span>
    </mat-form-field>
    <button *ngIf="['venue_picker'].includes(prop.custom_button)" mat-button (click)="openVenuePickerDialog(prop)" style="margin-left: 24px; border: 1px solid;">Change Venue</button>
    <div *ngIf="['venue_enum'].includes(prop.type) && prop.desc" class="prop-desc2">{{prop.desc.sv}}</div>

    <div class="header-panel" *ngIf="prop.header">
      {{prop.header}}
    </div>

  </div>
</div>
