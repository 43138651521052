import {CalculatedPrice} from "./order";
import * as moment from 'moment';

export class TableInfo {
  name: string;
  price: CalculatedPrice;
  active: boolean;
  lastFinalizedDate: moment.Moment;
  lastActivityDate: moment.Moment;

  constructor(name: string) {
    this.name = name;
  }
}

export class HarvestedTableOrderData {
  name: string;
  price: CalculatedPrice;
  lastFinalizedDate: moment.Moment;

  constructor(name: string) {
    this.name = name;
  }
}
