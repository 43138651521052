import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {VenueService} from "../../../services/venue.service";
import {LoadingService} from "../../../services/loading.service";
import {AuthService} from "../../../services/auth.service";
import * as _ from "lodash";
import {VenueConfig} from "../../../models/venue-config";

@Component({
  selector: 'app-add-filter-dialog',
  templateUrl: './add-filter-dialog.component.html',
  styleUrls: ['./add-filter-dialog.component.css']
})
export class AddFilterDialogComponent implements OnInit {

  filter = new UntypedFormControl('', [Validators.required]);
  sub = new UntypedFormControl('', [Validators.required]);
  subTitle: string;
  showQuery = false;
  showDropdown = false;
  paymentMethods: { name: string; value: string }[];
  private config: VenueConfig;

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<AddFilterDialogComponent>, private auth: AuthService) {
    this.config = data.config;
    const defaultPMS = [{value: "terminal", name: "Terminal"}, {value: "swish", name: "Swish"}, {value: "softpay", name: "Softpay"}, {value: "bambora", name: "Bambora checkout"}];
    const dynPMS = Object.entries(this.config?.payment.manual_payment_methods ?? []).map( ([key, pm]) => ({value: key, name: pm.name}));
    this.paymentMethods = [...defaultPMS, ...dynPMS];
  }

  ngOnInit(): void {
  }

  add() {
    if (this.filter.invalid || this.sub.invalid) {
      return;
    }
    const filt = this.filter.value;
    const val = this.sub.value;
    const op = "=";
    const filterQuery = `${filt} ${op} ${val}`;
    this.dialogRef.close(filterQuery);
  }

  isHeynowAdmin() {
    return AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }

  changedFilter() {
    const filt = this.filter.value;
    const titles = {amount: "Amount", serial: "Serial number", method: "Select method", table: "Bord"};
    this.subTitle = titles[filt];

    if (_.includes(["amount", "serial_number", "table"], filt)) {
      this.showQuery = true;
      this.showDropdown = false;
    }
     else {
      this.showQuery = false;
      this.showDropdown = true;
    }
  }
}
