export const pageVivaSettings = {
  title: { sv: "Viva" },
  page_settings: {
    root_path: "viva.settings"
  },
  desc: { sv: "Viva inställningar" },
  properties: [
    { key: "active", title: { sv: "Aktivt" }, desc: { sv: "Anger om viva skall vara aktiverat" }, type: "bool", optional: true },
    { key: "active_online", title: { sv: "Aktivt i checkout" }, desc: { sv: "Anger om viva också skall vara aktiverat i checkouten" }, type: "bool", optional: true },
    { key: "name", title: {sv: "Namn"}, desc: {sv: "Namn som visas på betalningsknapp"}, type: "string", optional: true},
    { key: "dpp_alias", title: {sv: "Kortnamn"}, desc: {sv: "Kortnamn som visas i kassapunkt"}, type: "string", optional: true},
    { key: "merchant_id", title: { sv: "Merchant ID" }, desc: { sv: "Viva merchant id" }, type: "string", optional: true },
    { key: "pricing_tier", title: { sv: "Pricing tier" }, desc: { sv: "Prismodell" }, type: "string", optional: true },
    { key: "source_code", title: { sv: "Source code" }, desc: { sv: "Nyckel till onlinebutiken" }, type: "string", optional: true },
    { key: "pos_client_key", title: { sv: "POS Client ID" }, desc: { sv: "Används för att aktivera terminaler" }, type: "string", optional: true },
    { key: "pos_client_secret", title: { sv: "POS Client secret" }, desc: { sv: "Används för att aktivera terminaler" }, type: "string", optional: true },
    { key: "pos_client_source_id", title: { sv: "POS Source ID" }, desc: { sv: "ID för den Store i Viva som terminalköp ska göras på. Valfritt" }, type: "string", optional: true },
    { key: "pin_code", title: { sv: "PIN-kod" }, desc: { sv: "Viva-terminalen låser känsliga funktioner med denna kod (ominstallation av Viva-terminal behövs vid ändring av PIN-kod)" }, type: "string", optional: true, pwdField: true, hidePwd: true },

  ],
  actions: [
    { key: "start-onboarding", icon:"start", title: { sv: "Skapa konto" }, },
    { key: "setup-store-action", icon:"storefront", title: { sv: "Skapa butik" }, },
  ],
};
