export const pageAdmissionSettings = {
  title: {sv: "Entrébiljetter"},
  page_settings: {
    root_path: "admission"
  },
  desc: {sv: "Här anges information om Entrébiljetterna"},
  properties: [
    {key: "tickets", title: {sv: "Biljetter"}, desc: {sv: ""}, type: "table", optional: true,
      columns: [
        {key: "item_id", title: {sv: "Artikel-id"}, type: "string", width: 100 },
        {key: "name", title: {sv: "Namn"}, type: "string", width: 200 },
        {key: "place", title: {sv: "Plats"}, type: "string", width: 200 },
        {key: "date", title: {sv: "Datum och tid"}, type: "string", width: 200 },
        {key: "start_date", title: {sv: "Startdatum"}, type: "date", width: 100 },
        {key: "start_time", title: {sv: "Starttid"}, type: "time", width: 100 },
        {key: "end_date", title: {sv: "Slutdatum"}, type: "date", width: 100 },
        {key: "end_time", title: {sv: "Sluttid"}, type: "time", width: 100 },
        {key: "more_text", title: {sv: "Länktext"}, type: "string", width: 200 },
        {key: "more_link", title: {sv: "Länk (url)"}, type: "string", width: 200 },
        {key: "logo_link", title: {sv: "Logga (url till bild)"}, type: "string", width: 200 },
        {key: "desc", title: {sv: "Fritext"}, type: "string", width: 300 },
      ]
    },
  ]
};
