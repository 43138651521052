import {Component, Input, Output, OnChanges, OnInit, SimpleChanges, EventEmitter, AfterViewInit} from '@angular/core';
import {HackUtils, NodeUtils} from "../../utils/utils";
import {UntypedFormControl, Validators} from "@angular/forms";
import {DataFormProperty, DataFormSchema} from "../../models/data-form";
import {SettingsPropertyColumn} from "../../models/settings-page";
import {environment} from "../../../environments/environment";
import Utils from "../../common/utils";
import {HotTableRegisterer} from "@handsontable/angular";
import {diff} from "deep-object-diff";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DatePipe} from '@angular/common';
import {FireService} from 'src/app/services/fire.service';
import {ActivatedRoute} from '@angular/router';
import {VenueConfig} from 'src/app/models/venue-config';
import {AuthService} from 'src/app/services/auth.service';
import { VenueService } from 'src/app/services/venue.service';
import { VenuePickerDialogComponent } from '../dialogs/venue-picker-dialog/venue-picker-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as uuid from 'uuid';
import Handsontable from 'handsontable';

@Component({
  selector: 'app-data-form',
  templateUrl: './data-form.component.html',
  styleUrls: ['./data-form.component.css']
})
export class DataFormComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() schema: DataFormSchema;
  @Input() root: any;
  @Output() dataChanged = new EventEmitter<any>();
  @Output() selected = new EventEmitter<any>();
  @Output() deselected = new EventEmitter<any>();
  @Output() save = new EventEmitter<any>();

  hotSettings = {};
  formControls = {};
  booleans = {};
  booleansSource = {};
  enums = {};
  configEnums = {};
  venueEnums = {};
  sourceEnums = {};
  venueCustomers: any[];
  uiReady = false;
  visibleProperties: DataFormProperty[];
  jsons = {};
  textEditJsons = {};
  editJsons = {};
  isMobile: boolean;
  isSuperAdmin = false;
  namespace = "";

  constructor(private snackBar: MatSnackBar, private datePipe: DatePipe, private fire: FireService,
              private route: ActivatedRoute, private auth: AuthService, private venueService: VenueService, private dialog: MatDialog) { }

  private hotRegisterer = new HotTableRegisterer();
  private sourceData: any;
  private venueId: number;
  private config: VenueConfig;

  onKeyDown(event: KeyboardEvent) {
    if ((event.metaKey || event.ctrlKey) && event.key === 's') {
      event.preventDefault();
      this.save.emit();
    }
  }

  ngOnInit(): void {
    this.namespace = uuid.v4();
    this.isMobile = HackUtils.isMobile() && window.innerWidth <= 768;
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.isSuperAdmin = AuthService.isSuperAdmin(this.auth.authStateSnapshot);
      if (this.venueId) {
        this.observeVenueConfig();
        this.fetchVenueCustomers();
      }
    });
  }

  private observeVenueConfig() {
    this.fire.observeVenueConfig(this.venueId).subscribe(cfg => {
      this.config = cfg;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.root != null && this.schema != null) {
      console.log("schema", this.schema);
      console.log("root", this.root);
      this.visibleProperties = this.schema.properties.filter( prop => {
        const visible = prop.visible ?? true;
        const superAdminOnly = prop.super_admin_only ?? false;
        return visible && (!superAdminOnly || this.isSuperAdmin);
      });
      this.sourceData = {};
      this.buildUI();
    }
  }

  ngAfterViewInit(): void {
    this.buildUI();
  }

  private buildUI() {
    const sourceData = {};
    const formProps = this.visibleProperties?.filter( prop => ['string', 'text', 'integer', 'json', 'date', 'datetime'].includes(prop.type));
    if (formProps == null) { return; }
    for (const formProp of formProps) {
      const src = NodeUtils.get(this.root, formProp.key);
      if (src != null) {
        NodeUtils.set(sourceData, formProp.key, src);
      }
      const validators = [];
      if (!formProp.optional) {
        validators.push(Validators.required);
      }
      const state = NodeUtils.get(this.root, formProp.key);
      console.log(`${formProp.title.sv} ${state}`);
      const fc = new UntypedFormControl({value: state, disabled: false}, validators);
      this.formControls[formProp.key] = fc;
      if (formProp.type === "json") {
        console.log(state);
        this.jsons[formProp.key] = JSON.parse(state);
      }
      if (formProp.type === "date") {
        this.formControls[formProp.key].setValue(this.datePipe.transform(state, 'yyyy-MM-dd'));
      }
      if (formProp.type === "datetime") {
        this.formControls[formProp.key].setValue(this.datePipe.transform(state, 'yyyy-MM-dd'));
      }
    }

    const boolProps = this.visibleProperties.filter( prop => prop.type === "bool");
    for (const boolProp of boolProps) {
      const src = NodeUtils.get(this.root, boolProp.key);
      if (src != null) {
        NodeUtils.set(sourceData, boolProp.key, src);
        this.booleans[boolProp.key] = src;
        this.booleansSource[boolProp.key] = src;
      }
    }

    const tableProps = this.visibleProperties.filter( prop => prop.type === "table");
    for (const tableProp of tableProps) {
      let src;
      let table;
      if (tableProp.convert_from_tree) {
        src = this.createSourceForTreeData(tableProp, this.root);
        table = this.convertFromTree(tableProp, this.root);
      } else {
        src = this.createSourceForTableData(tableProp, this.root);
        table = this.root;
      }
      NodeUtils.set(sourceData, tableProp.key, src);
      this.setupHandsontableSettings(tableProp, table);
    }

    const listProps = this.visibleProperties.filter( prop => prop.type?.endsWith("[]") );
    for (const listProp of listProps) {
      const src = NodeUtils.get(this.root, listProp.key);
      const table = this.root;
      NodeUtils.set(sourceData, listProp.key, src);
      this.setupHandsontableSettingsForList(listProp, src);
    }

    const enumProps = this.visibleProperties.filter( prop => prop.type === "enum");
    for (const enumProp of enumProps) {
      const src = NodeUtils.get(this.root, enumProp.key);
      if (src != null) {
        NodeUtils.set(sourceData, enumProp.key, src);
        this.enums[enumProp.key] = src;
      }
    }

    const enumSourceProps = this.visibleProperties.filter( prop => prop.type === "enum_source");
    for (const sourceProp of enumSourceProps) {
      const src = NodeUtils.get(this.root, sourceProp.key);
      if (src != null) {
        NodeUtils.set(sourceData, sourceProp.key, src);
        this.sourceEnums[sourceProp.key] = src;
      }
    }

    const configEnumProps = this.visibleProperties.filter( prop => prop.type === "config_enum");
    for (const configEnumProp of configEnumProps) {
      const src = NodeUtils.get(this.root, configEnumProp.key);
      if (src != null) {
        NodeUtils.set(sourceData, configEnumProp.key, src);
        this.configEnums[configEnumProp.key] = src;
      }
    }

    const venueEnumProps = this.visibleProperties.filter( prop => prop.type === "venue_enum");
    for (const venueEnumProp of venueEnumProps) {
      const src = NodeUtils.get(this.root, venueEnumProp.key);
      if (src != null) {
        NodeUtils.set(sourceData, venueEnumProp.key, src);
        this.venueEnums[venueEnumProp.key] = Number(src);
      }
    }

    console.log("sourceData", sourceData);
    this.sourceData = sourceData;
    this.uiReady = true;
    // this.hasBeenBuilt = true;
  }

  private convertFromTree(prop: DataFormProperty, tree: any): any {
    const keyName = prop.convert_from_tree;
    const table = [];
    const root = NodeUtils.get(tree, prop.key);
    if (root == null ) { return null; }
    for (const [key, child] of Object.entries(root)) {
      child[keyName] = key;
      table.push(child);
    }
    const rr = {};
    rr[prop.key] = table;
    return rr;
  }

  private createSourceForTableData(prop: DataFormProperty, table: {} ) {
    const srcRows = [];
    const inputRows = NodeUtils.get(table, prop.key);
    if (inputRows == null ) { return null; }
    for (const row of inputRows) {
      const srcRow = {};
      for (const col of prop.columns) {
        const srcCell = NodeUtils.get(row, col.key);
        if (srcCell != null) {
          srcRow[col.key] = srcCell;
        }
      }
      if (!NodeUtils.isNullOrEmpty(srcRow)) {
        srcRows.push(srcRow);
      }
    }
    return srcRows;
  }

  private createSourceForTreeData(prop: DataFormProperty, tree: {} ) {
    const srcTree = {};
    const inputRows = NodeUtils.get(tree, prop.key);
    if (inputRows == null ) { return null; }
    for (const [key, row] of Object.entries(inputRows)) {
      const srcRow = {};
      for (const col of prop.columns) {
        const srcCell = NodeUtils.get(row, col.key);
        if (srcCell != null) {
          srcRow[col.key] = srcCell;
        }
      }
      if (!NodeUtils.isNullOrEmpty(srcRow)) {
        srcTree[key] = srcRow;
      }
    }
    return srcTree;
  }

  public hasChanges(): boolean {
    const data = this.collectData();
    if (data == null) { return false; }
    const d = diff(this.sourceData, data);
    console.log("Diif", d);
    return !NodeUtils.isNullOrEmptyObject(d);
  }

  public collectData(): any {
    const data = {};
    for (const prop of this.visibleProperties) {
      if (prop.type === "table") {
        const tableData = this.collectTableData(prop, data);
        console.log("tableData", tableData);
        NodeUtils.setOrRemove(data, prop.key, tableData);
      } else if (prop.type?.endsWith("[]")){
        const listData = this.collectListData(prop, data);
        console.log("listData", listData);
        NodeUtils.setOrRemove(data, prop.key, listData);
      } else if (['string', 'integer', 'json', 'text'].includes(prop.type)){
        const fc = this.formControls[prop.key];
        if (!fc.valid) {
          console.log("Not valid", fc);
          return;
        }
        const v = this.getValueForType(fc.value, prop.type);
        NodeUtils.setOrRemove(data, prop.key, v);
      } else if ( prop.type === "bool"){
        const v = this.booleans[prop.key];
        if (v === true) {
          NodeUtils.set(data, prop.key, v);
        } else if (v === false) {
          const sourceData = this.booleansSource[prop.key];
          if (sourceData == null && prop.optional) {
            NodeUtils.remove(data, prop.key);
          } else {
            NodeUtils.set(data, prop.key, v);
          }
        }
      } else if ( prop.type === "date" ){
        const fc = this.formControls[prop.key];
        if (!fc.valid) {
          console.log("Not valid", fc);
          return;
        }
        const v = this.datePipe.transform(fc.value, 'yyyy-MM-dd');
        NodeUtils.setOrRemove(data, prop.key, v);
      } else if ( prop.type === "datetime") {
        const fc = this.formControls[prop.key];
        if (!fc.valid) {
          console.log("Not valid", fc);
          return;
        }
        const v = this.datePipe.transform(fc.value, 'yyyy-MM-dd HH:mm:ss');
        NodeUtils.setOrRemove(data, prop.key, v);
      } else if (prop.type === "config_enum") {
        const v = this.configEnums[prop.key];
        NodeUtils.setOrRemove(data, prop.key, v);
      } else if (prop.type === "enum") {
        const v = this.enums[prop.key];
        if (v === 0) {
          NodeUtils.set(data, prop.key, v);
        } else {
          NodeUtils.setOrRemove(data, prop.key, v);
        }
      } else if (prop.type === "enum_source"){
        const v = this.sourceEnums[prop.key];
        NodeUtils.setOrRemove(data, prop.key, v);
      } else if (prop.type === "venue_enum") {
        const v = Number(this.venueEnums[prop.key]);
        NodeUtils.setOrRemove(data, prop.key, v);
      }
    }
    return data;
  }

  private collectTableData(prop: DataFormProperty, data: {}) {
    const hotInstance = this.hotRegisterer.getInstance(this.namespace + prop.key);
    if (hotInstance == null) { return null; }
    const rows = [];
    for (let i = 0; i < hotInstance.countRows(); i++) {
      const row = {};
      for (const col of prop.columns) {
        let v = hotInstance.getDataAtRowProp(i, col.key);
        // Ensure cell value is of type boolean if column type is bool (pasted values are interpreted as strings)
        if (typeof v === "string" && !Utils.isStringNullOrWhitespace(v) && col.type === "bool") {
          v = v.toLowerCase() === "true";
        }
        if (!Utils.isStringNullOrWhitespace(v)) {
          // console.log(`row ${i} ${col.key} = ${v}`);
          row[col.key] = v;
        }
      }
      if (!NodeUtils.isNullOrEmpty(row)) {
        // Fill in any provided default values on empty cells for this row
        for (const column of prop.columns) {
          if (Utils.isStringNullOrWhitespace(row[column.key]) && !Utils.isStringNullOrWhitespace(column.default)) {
            row[column.key] = column.default;
          }
        }
        rows.push(row);
      }
    }
    return rows;
  }

  private collectListData(prop: DataFormProperty, data: {}) {
    const hotInstance = this.hotRegisterer.getInstance(this.namespace + prop.key);
    if (hotInstance == null) { return null; }
    const row = hotInstance.getData()[0];
    return row.filter( d => !Utils.isStringNullOrWhitespace(d) );
  }

  getInputType(prop: any) {
    if (prop.type != null) {
      switch (prop.type) {
        case "string":
          if (prop.hideable === true && prop.hidden !== false) {
            return "password";
          }
          return "text";
        case "text":
          return "text";
        case "float":
        case "integer":
        case "venue_enum":
          return "number";
        case "bool":
          return "checkbox";
      }
    }
    return "text";
  }

  getEnums(source: string) {
    if (!source) { return []; }
    switch (source) {
      case "venue_customers":
        return this.venueCustomers;
    }
  }

  setupForHOTType(prop: SettingsPropertyColumn | DataFormProperty, c: any) {
    let type = "text";
    if (prop.type != null) {
      switch (prop.type) {
        case "string":
          type = "text";
          break;
        case "integer":
        case "float":
          type = "numeric";
          break;
        case "bool":
          type = "checkbox";
          c.className = "htCenter";
          break;
        case "enum":
          type = "dropdown";
          // @ts-ignore
          c.source = prop.source;
          c.trimDropdown = false;
          break;
        case "button":
          c.readOnly = true;
          c.renderer = (instance, TD, row, col, prop, value, cellProperties) => {
            TD.innerHTML = `<button class="upsent">Edit</button>`
            return TD;
          }
          type = undefined;
      }
    }
    c.type = type;
  }

  getValueForType(val: string, type: string) {
    if (val != null) {
      switch (type) {
        case "string":
          if (val === "") {
            return undefined;
          }
          return val;
        case "integer":
          if (val === "") {
            return undefined;
          }
          return Number(val);
      }
    }
    return val;
  }

  private setupHandsontableSettings(prop: DataFormProperty, sourceDataRoot: any) {
    const that: DataFormComponent = this;

    // Prepare columns
    const cols = [];
    for (const col of prop.columns) {
      const c = {data: col.key, title: col.title.sv, width: col.width};
      this.setupForHOTType(col, c);
      cols.push(c);
    }

    // Prepare rows (values)
    let data;
    if (sourceDataRoot && !NodeUtils.isNullOrEmpty(sourceDataRoot[prop.key])) {
      data = [];
      const source = sourceDataRoot[prop.key];
      if (source != null) {
        for (const row of source) {
          data.push(NodeUtils.deepcopyWithJSON(row));
        }
      }
      for (let i = 0; i < 3; i++) {
        data.push({});
      }
    } else {
      data = undefined;
    }

    const set = {
      licenseKey: environment.handson_key,
      data,
      startRows: 5,
      autoWrapCol: false,
      autoWrapRow: false,
      colHeaders: true,
      rowHeaders: true,
      // stretchH: 'all',
      manualColumnResize: true,
      columns: cols,
      contextMenu: undefined,
      afterChange: (changes: any) => {
        if (changes != null && changes.length > 0) {
          this.dataChanged.emit();
        }
      },
      afterSelection: () => {
        this.selected.emit();
      },
      afterDeselect: () => {
        this.deselected.emit();
      },
      trimDropdown: false,
      afterOnCellMouseDown: (event, coords, TD) => {
        const c = prop.columns[coords.col];
        const da = data[coords.row];
        if (c?.callback != null && da != null) {
          c.callback(da);
        }
      },
    };

    set.contextMenu = {
      items: {
        insert_row_above: {
          name() {
            return '<b>Infoga rad(er) ovanför ↑↑</b>';
          },
          callback(key, selection, clickEvent) {
            const startRow = selection[0].start.row;
            const endRow = selection[0].end.row;
            const numRows = endRow - startRow + 1;
            that.insertRow(startRow, numRows, this, true);
          }
        },
        insert_row_below: {
          name() {
            return '<b>Infoga rad(er) nedanför ↓↓</b>';
          },
          callback(key, selection, clickEvent) {
            const startRow = selection[0].start.row;
            const endRow = selection[0].end.row;
            const numRows = endRow - startRow + 1;
            that.insertRow(endRow, numRows, this, false);
          }
        },
        separator1: Handsontable.plugins.ContextMenu.SEPARATOR,
        clear_row: {
          name() {
            return 'Rensa rad(er)';
          },
          callback(key, selection, clickEvent) {
            const startRow = selection[0].start.row;
            const endRow = selection[0].end.row;
            const numRows = endRow - startRow + 1;
            that.clearRow(startRow, numRows, this);
          }
        },
        delete_row: {
          name() {
            return 'Ta bort rad(er)';
          },
          callback(key, selection, clickEvent) {
            const startRow = selection[0].start.row;
            const endRow = selection[0].end.row;
            const numRows = endRow - startRow + 1;
            that.deleteRow(startRow, numRows, this);
          }
        },
      }
    };

    this.hotSettings[prop.key] = set;
  }

  private setupHandsontableSettingsForList(prop: DataFormProperty, src: any) {
    const that: DataFormComponent = this;
    if (src != null) {
      for (let i = 0; i < 3; i++) {
        src.push("");
      }
    } else {
      src = ["", "", ""];
    }
    const data = [src];

    const set = {
      licenseKey: environment.handson_key,
      data,
      startRows: 1,
      maxRows: 1,
      autoWrapCol: false,
      autoWrapRow: false,
      colHeaders: true,
      rowHeaders: false,
      manualColumnResize: true,
      contextMenu: undefined,
      colWidths: 200
    };

    set.contextMenu = ["col_right", '---------', 'undo', 'redo'];
    set.colWidths = 200;

    this.hotSettings[prop.key] = set;
  }

  private insertRow(rowIndex: number, numRows: number, hot: Handsontable, above: boolean) {
    for (let i = 0; i < numRows; i++) {
      hot.alter('insert_row', rowIndex + (above ? 0 : 1), 1);
    }
  }

  private clearRow(rowIndex: number, numRows: number, hot: Handsontable) {
    for (let i = 0; i < numRows; i++) {
      const row = rowIndex + i;
      hot.getDataAtRow(row).forEach((cellData, colIndex) => {
        hot.setDataAtCell(row, colIndex, '');
      });
    }
  }

  private deleteRow(rowIndex: number, numRows: number, hot: Handsontable) {
    for (let i = 0; i < numRows; i++) {
      hot.alter('remove_row', rowIndex, 1);
    }
  }

  fetchFromConfig(path: string, value: string) {
    const configEnums = [];
    NodeUtils.get(this.config, path.split('.'))?.forEach((item: any) => {
      configEnums.push(item[value]);
    });
    return configEnums;
  }

  private fetchVenueCustomers() {
    this.venueService.fetchVenueCustomers(this.venueId, undefined, undefined).then(r => {
      const res = r.customers.map(c => {
        return { value: c.key, title: { sv: c.name } };
      });
      this.venueCustomers = res;
    });
  }

  openVenuePickerDialog(prop: DataFormProperty) {
    const dialogRef = this.dialog.open(VenuePickerDialogComponent, HackUtils.DLG({
      data: {
        title: "Välj Venue",
        cancelButton: "Avbryt",
        positiveButton: "Välj",
      }
    }));
    dialogRef.afterClosed().subscribe(res => {
      console.log(prop.key, res);

      if (res) {
        // this.formControls[prop.key].setValue(res);
        this.venueEnums[prop.key] = res;
      }
    });
  }

  updateRoot(data: any) {
    // this.root = data;
    // this.buildUI();
  }

  editJson(prop: DataFormProperty) {
    this.editJsons[prop.key] = true;
    this.textEditJsons[prop.key] = this.formControls[prop.key].value;
  }

  finishedEditJson(prop: DataFormProperty) {
    try {
      const v = this.textEditJsons[prop.key];
      const j = JSON.parse(v);
      this.jsons[prop.key] = j;
      this.formControls[prop.key].value = v;
      this.editJsons[prop.key] = false;
    } catch (e) {
      this.snackBar.open(e.message, "", {duration: 5000});
    }
  }

  cancelEditJson(prop: DataFormProperty) {
    this.editJsons[prop.key] = false;
  }
}
