import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Params, Router} from "@angular/router";
import {QueryReceiptRequest, VenueService} from "../../../services/venue.service";
import {MatSort, MatSortable} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {DatePipe} from "@angular/common";
import {combineLatest, Subscription} from "rxjs";
import {FireService} from "../../../services/fire.service";
import {first} from "rxjs/operators";
import {HackUtils, LocalFileUtils} from "../../../utils/utils";
import {TimeUtils} from "../../../utils/utils";
import * as moment from "moment";
import { QueryDiscountItemsResponse } from 'src/app/models/reporting';
import Utils from 'src/app/common/utils';
import { SimpleDialogComponent } from '../../simple-dialog/simple-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-report-discounted-items',
  templateUrl: './report-discounted-items.component.html',
  styleUrls: ['./report-discounted-items.component.css']
})
export class ReportDiscountedItemsComponent implements OnInit, OnDestroy {

  private paramSub: Subscription;
  private query: string;
  private response: QueryDiscountItemsResponse[];
  public isMobile: boolean;
  title = "Rabatterade artiklar";

  constructor(private venueService: VenueService, private route: ActivatedRoute,
              public datePipe: DatePipe, private fire: FireService, private router: Router,
              private dialog: MatDialog) {
  }

  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns = [
    "order_date",
    "pay_date",
    "name",
    "price_vat",
    "discount",
    "ic",
    "discounter_id",
    "device",
    "receipt_id",
];

  venueId: string;
  searchStartDate: Date;
  searchEndDate: Date;
  dataSource: MatTableDataSource<QueryDiscountItemsResponse>;
  noData = false;
  initData = true;
  rid: string;

  ngOnInit(): void {
    this.isMobile = HackUtils.isMobile() && window.innerWidth <= 768;
    if (this.isMobile) {
      this.displayedColumns = ["order_date", "pay_date", "name", "price_vat", "discount"];
    }
    this.sort.sort(({ id: 'pay_date', start: 'desc'}) as MatSortable);
    this.paramSub = combineLatest([
        this.route.paramMap,
        this.route.queryParamMap
      ]
    ).subscribe(([param, query]) => {
      this.query = query.get("q");
      this.venueId = param.get("venue_id");
      this.parseQueryParams(query);
      if (this.query !== "search") {
        this.fetch();
      }
    });
  }

  ngOnDestroy(): void {
    this.paramSub?.unsubscribe();
  }

  private parseQueryParams(query: ParamMap) {
    this.clearFilters();
    this.query = query.get("q");
    if (this.query) {
      this.setupInitialParams(this.query);
    } else {
      const sd = query.get("start_date");
      const ed = query.get("end_date");
      if (sd) {
        this.searchStartDate = Utils.dateKeyToMoment(sd).toDate();
      }
      if (ed) {
        this.searchEndDate = Utils.dateKeyToMoment(ed).toDate();
      }
    }
  }

  fetch() {
    this.initData = false;
    this.noData = false;
    const request = this.buildRequest();

    this.venueService.queryDiscountedItems(request).then(r => {
      console.log("queryDiscountedItems", r);
      if (r != null) {
        this.response = r;
        this.dataSource = new MatTableDataSource(r as QueryDiscountItemsResponse[]);
        this.dataSource.sort = this.sort;
        this.noData = r.length === 0;
      } else {
        this.dataSource = new MatTableDataSource([]);
      }
    }).catch(e => {
      SimpleDialogComponent.showErr(this.dialog, e);
      console.error(e);
    });
  }

  updateParams() {
    const queryParams: Params = {};
    if (this.searchStartDate != null) {
      queryParams.start_date = Utils.dateToDateKey(this.searchStartDate);
    }
    if (this.searchEndDate != null) {
      queryParams.end_date = Utils.dateToDateKey(this.searchEndDate);
    }
    this.router.navigate([], { relativeTo: this.route, queryParams });
  }

  private buildRequest() {
    const request = new QueryReceiptRequest();
    request.venue_id = this.venueId;

    if (this.searchStartDate != null) {
      request.start_date = this.datePipe.transform(this.searchStartDate, 'yyyy-MM-dd');
    }
    if (this.searchEndDate != null) {
      request.end_date = this.datePipe.transform(this.searchEndDate, 'yyyy-MM-dd');
    }
    return request;
  }

  private setupInitialParams(q: string) {
    switch (q) {
      case "today":
        this.searchStartDate = moment().toDate();
        break;
      case "yesterday":
        this.searchStartDate = moment().subtract(1, "day").toDate();
        break;
      case "search":
        break;
    }
  }

  clearFilters() {
    this.searchStartDate = null;
    this.searchEndDate = null;
    this.dataSource = new MatTableDataSource([]);
    this.setupInitialParams("search");
  }

  select(row: QueryDiscountItemsResponse) {
    this.rid = this.rid === row.rid ? undefined : row.rid;
    console.log(row);
  }

  async export(format: string) {
    const data = this.buildDataArrayWithHeader();
    const config = await this.fire.observeVenueConfig(Number(this.venueId)).pipe(first()).toPromise();
    const dates = LocalFileUtils.createDateFileName(this.searchStartDate?.toISOString(), this.searchEndDate?.toISOString());
    const filename = `discounted_items_report_${config.venue.name.replace(/\\s/g, "_")}_${dates}.csv`;
    LocalFileUtils.createFileWithBOM(data, filename);
  }

  formatWorkTime(minutes): string {
    return TimeUtils.formatTimeFromMinutesToHoursMinutes(minutes);
  }

  private buildDataArrayWithHeader() {
    const header = [
      {col: 'order_date', title: 'Orderdatum'},
      {col: 'pay_date', title: 'Betalt'},
      {col: 'name', title: 'Artikel'},
      {col: 'price_vat', title: 'Ink moms'},
      {col: 'discount', title: 'Rabatt'},
      {col: 'ic', title: 'ic'},
      {col: 'discounter_id', title: 'Rabatterat av'},
      {col: 'device', title: 'Enhet'},
      {col: 'receipt_id', title: 'Kvitto'},
    ];
    return LocalFileUtils.compileDataIntoCSV(header, this.response as any);
  }

}
