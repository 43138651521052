import { Injectable } from '@angular/core';
import { getSettingsPage } from '../components/settings/pages/setting-pages';

interface Page {
  title?: { sv: string;[key: string]: string };
  desc?: { sv: string;[key: string]: string };
  page_settings?: {
    root_path?: string;
  };
  properties?: Property[];
}

interface Property {
  title?: { sv: string;[key: string]: string };
  desc?: { sv: string;[key: string]: string };
}

@Injectable({
  providedIn: 'root'
})
export class SettingSearchService { 
  pages: { [key: string]: Page };

  constructor() {
    this.pages = getSettingsPage() as { [key: string]: Page };
  }

  searchPages(searchTerm: string) {
    let results = [];

    Object.entries(this.pages).forEach(([key, page]) => {
      const routerLink = key;

      let pageMatches = {
        title: this.getStringValue(page.title),
        routerLink: routerLink,
        matches: []
      };

      let pageTitle = this.getStringValue(page.title).toLowerCase();
      let pageDesc = page.desc ? this.getStringValue(page.desc).toLowerCase() : '';

      if (pageTitle.includes(searchTerm.toLowerCase()) || pageDesc.includes(searchTerm.toLowerCase())) {

        pageMatches.matches.push({
          type: 'Page',
          title: this.getStringValue(page.title),
          desc: this.getStringValue(page.desc),
          routerLink
        });
      }

      page.properties.forEach(property => {
        let propertyTitle = this.getStringValue(property.title).toLowerCase();
        let propertyDesc = property.desc ? this.getStringValue(property.desc).toLowerCase() : '';

        if (propertyTitle.includes(searchTerm.toLowerCase()) || propertyDesc.includes(searchTerm.toLowerCase())) {

          pageMatches.matches.push({
            type: 'Property',
            title: this.getStringValue(property.title),
            desc: this.getStringValue(property.desc),
            routerLink
          });
        }
      });


      if (pageMatches.matches.length > 0) {
        results.push(pageMatches);
      }
    });

    return results;
  }

  getStringValue(value: any): string {
    if (typeof value === 'string') {
      return value;
    } else if (value && typeof value === 'object') {
      if ('sv' in value) {
        return value.sv;
      } else {
        console.error('Objektet innehåller inte "sv"-egenskapen:', value);
      }
    } else {
      console.error('Oväntat värde:', value);
    }
    return '';
  }

}