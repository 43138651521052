export const pageKioskSettings = {
  title: { sv: "Kioskläge" },
  page_settings: {
    root_path: "kiosk"
  },
  desc: { sv: "Inställningar för kiosk" },
  properties: [
    { key: "mode", title: { sv: "Lägen" }, desc: { sv: "Välj vilket läge som skall vara aktivt för kiosk" }, type: "enum", optional: true,
      values: [
        { key: "default", title: { sv: "standard" }, width: 150 },
        { key: "monter", title: { sv: "monter" }, width: 200 },
      ]
    },
  ]
};
