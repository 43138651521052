import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {VenueService} from "../../../services/venue.service";
import {LoadingService} from "../../../services/loading.service";
import {UntypedFormControl, Validators} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import { SimpleDialogComponent } from '../../simple-dialog/simple-dialog.component';

@Component({
  selector: 'app-edit-role-dialog',
  templateUrl: './edit-role-dialog.component.html',
  styleUrls: ['./edit-role-dialog.component.css']
})
export class EditRoleDialogComponent implements OnInit {

  role = new UntypedFormControl('', [Validators.required]);
  email: string;
  venueId?: number;

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<EditRoleDialogComponent>, private auth: AuthService, private dialog: MatDialog) {
    this.email = data.email;
    this.venueId = data.venueId;
    console.log("venue id: " + this.venueId);
  }

  ngOnInit(): void {
  }

  update() {
    if (this.role.valid) {
      const roles = this.role.value === "-remove-" ? [] : [this.role.value];
      this.venueService.updateRole(this.email, roles, this.venueId).then( r => {
        console.log("Updated role");
        this.dialogRef.close(this.role?.value ?? false);
      })
      .catch(e => {
        console.error(`Failed to edit user ${this.email}`, e);
        this.dialogRef.close(false);
        SimpleDialogComponent.showErr(this.dialog, e);
      }
      );
    }
  }

  isHeynowAdmin() {
    return AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }
}
