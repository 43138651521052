import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VenuePickerDialogComponent } from 'src/app/components/dialogs/venue-picker-dialog/venue-picker-dialog.component';
import { EditAdminUserDialogComponent } from 'src/app/components/user-list/edit-admin-user-dialog/edit-admin-user-dialog.component';
import { EditRoleDialogComponent } from 'src/app/components/user-list/edit-role-dialog/edit-role-dialog.component';
import { AccountUser } from 'src/app/models/user';
import { VenueMapService } from 'src/app/services/venue-map.service';
import { VenueService } from 'src/app/services/venue.service';
import { HackUtils } from 'src/app/utils/utils';

@Component({
  selector: 'app-admin-account-details',
  templateUrl: './admin-account-details.component.html',
  styleUrls: ['./admin-account-details.component.css']
})
export class AdminAccountDetailsComponent implements OnInit, OnChanges{

  showSpinner = true;
  @Input() admin: AccountUser;
  @Output() refreshUsers = new EventEmitter<void>();
  venues: {venue_id: number, name: string, roles: string[]}[] = [];
  displayedColumns = ["name", "roles", "actions"];

  constructor(public dialog: MatDialog, private venueService: VenueService,
              private snackBar: MatSnackBar, private venueMapService: VenueMapService) { }

  ngOnInit(): void {
    this.loadDetails();
  }

  ngOnChanges() {
    this.loadDetails();
  }

  private loadDetails() {
    this.showSpinner = true;
    this.venueMapService.getVenueConfigs().subscribe(venueConfigs => {
      this.admin.roles_cache?.forEach(v => {
        const vcfgs = venueConfigs.find(c => c.venueId === v.venue_id);
        v.name = vcfgs ? vcfgs.venue.name : "Unknown";
      });
      this.showSpinner = false;
      this.venues = this.admin.roles_cache;
    });
  }

  public openEditRoleDialog(email: string, venueId: number) {
    const dialogRef = this.dialog.open(EditRoleDialogComponent, HackUtils.DLG({
      data: { email, venueId }
    }));
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
      if (result) {
        this.refreshUsers.emit();
      }
    });
  }

  public editRoles(venueId: number) {
    this.openEditRoleDialog(this.admin.email, venueId);
  }

  public editAdminUser() {
    const dialogRef = this.dialog.open(EditAdminUserDialogComponent, HackUtils.DLG({
      data: {
        email: this.admin.email,
        venueId: this.admin.venue_id,
        firstName: this.admin.first_name,
        lastName: this.admin.last_name}
    }));
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
      if (result) {
        this.admin.first_name = result.first_name;
        this.admin.last_name = result.last_name;
        this.refreshUsers.emit();
      }
    });
  }

  public resendInvite() {
    this.venueService.resendInvite(this.admin.email).then(r => {
      this.snackBar.open("Invite email sent", "", {duration: 3000});
    });
  }

  public addVenue() {
    const dialogRef = this.openVenuePickerDialog();
    dialogRef.afterClosed().subscribe(venueId => {
      if (venueId) {
        if (this.admin.roles_cache.find(v => v.venue_id === venueId)) {
          this.snackBar.open("Venue finns redan!", "Ok", {duration: 5000});
          return;
        }
        this.checkUser(this.admin.email, venueId);
      }
    });
  }

  private openVenuePickerDialog() {
    const dialogRef = this.dialog.open(VenuePickerDialogComponent, HackUtils.DLG({
      data: {
        title: "Välj Venue",
        cancelButton: "Avbryt",
        positiveButton: "Välj",
      }
    }));
    return dialogRef;
  }

  private checkUser(email: string, venueId: number) {
    this.venueService.queryEmail(email, venueId).then( r => {
      console.log(`Exists ${r.exists}`);
      if (r.exists) {
        this.openEditRoleDialog(email, venueId);
      }
    });
  }

}
