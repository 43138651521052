<app-header-card *ngIf="venueId" [venueId]="venueId"></app-header-card>
<div>
  <p>
    <mat-toolbar>
      <span>Tickets</span>
      <span class="spacer"></span>
      <mat-slide-toggle class="printer-toggle" [(ngModel)]="active" (ngModelChange)="toggleActive()" color="primary"><span style="font-size: 14px;margin-right: 8px;">Active</span></mat-slide-toggle>
      <button mat-button (click)="fetchTickets()">
        <mat-icon>refresh</mat-icon> Uppdatera
      </button>
<!--      <button mat-icon-button>-->
<!--        <mat-icon (click)="newTicket()">mark_email_unread</mat-icon>-->
<!--      </button>-->
    </mat-toolbar>
  </p>
</div>
<div class="container master-detail">
  <div class="master">
    <div>
      <table mat-table [dataSource]="dataSource" [trackBy]="identify" matSort>

        <ng-container matColumnDef="venueName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Venue </th>
          <td mat-cell *matCellDef="let element" style="padding: 4px;max-width: 125px;font-size:12px;white-space: normal;word-wrap: break-word;"> {{element.venue_name}} </td>
        </ng-container>

        <ng-container matColumnDef="sender">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Sender </th>
          <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.sender}} </td>
        </ng-container>

        <ng-container matColumnDef="to">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> To </th>
          <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.to}} </td>
        </ng-container>

        <ng-container matColumnDef="kind">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Kind </th>
          <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.kind}} </td>
        </ng-container>

        <ng-container matColumnDef="subject">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Subject </th>
          <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.subject}} </td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Created </th>
          <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.created | date:"medium"}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectTicket(row)" class="mat-table-clicky"></tr>
      </table>
    </div>

  </div>
  <div *ngIf="selected" class="detail">
    <div class="subject">{{selected.subject}}</div>
    <div class="sender-date"><div>from: {{selected.sender}}</div><div>{{selected.created | date:"medium"}}</div></div>
    <div class="to">to: {{selected.to}}</div>
    <div>
      <mat-toolbar class="inner-toolbar">
        <button *ngIf="canResolve" mat-flat-button (click)="resolveTicket()" color="primary">Resolve</button>
        <span class="spacer"></span>
        <button *ngIf="canOpen" mat-button (click)="openTicket()" color="primary">Open</button>
        <button *ngIf="canResolve" mat-button (click)="closeTicket()">Close</button>
      </mat-toolbar>
    </div>
    <div class="ticket-body" [innerHTML]="selected.body"></div>
    <div *ngIf="selected.history" class="result" style="margin-bottom: 16px;margin-top: 48px;">
      <div style="font-weight: bold;margin-bottom: 8px;">History</div>
      <table>
        <tr *ngFor="let hist of selected.history">
          <td>{{hist.date | date:"medium"}}</td>
          <td>{{hist.state}}</td>
          <td>{{hist.user_name}}</td>
        </tr>
      </table>
    </div>
    <div class="result">
      <div style="font-weight: bold;margin-bottom: 8px;">Ticket data</div>
      <ngx-json-viewer *ngIf="selected.source.data" [json]="selected.source.data"></ngx-json-viewer>
      <div *ngIf="traceUrl" style="margin-left: 13px; margin-top: 8px;">
        <a [href]="traceUrl" target="_blank">SHOW LOG</a>
      </div>
    </div>
  </div>
  <div *ngIf="!selected" class="detail">
    <div class="center-empty">Ingen ticket vald</div>
  </div>

</div>
