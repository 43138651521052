<div>
  <p>
    <mat-toolbar>
      <span>Fortnox</span>
      <span class="spacer"></span>
      <app-wiki-info-link title="Lär dig mer" section="support" path="fortnox-integration"></app-wiki-info-link>
      <button mat-button [matMenuTriggerFor]="menuAction"><mat-icon>settings</mat-icon> Actions</button>
      <mat-menu #menuAction="matMenu">
        <button mat-menu-item (click)="auth()">Logga in</button>
      </mat-menu>
    </mat-toolbar>
  </p>
</div>

<mat-spinner style="position: absolute; top: calc(50% - 15px); left: calc(50% - 15px);" [diameter]="30" *ngIf="loading"></mat-spinner>

<div>
  <div *ngIf="summary" class="open-panel" >
    <div class="panel-title">Fortnox inställningar</div>
    <div class="panel-body">
      <table>
        <tr *ngIf="summary.token.valid">
          <td>Authorization</td>
          <td *ngIf="summary.token.valid"><mat-icon class="smicon" [style]="okStyle">{{okIcon}}</mat-icon></td>
          <td *ngIf="!summary.token.valid"><mat-icon class="smicon" [style]="badStyle">{{badIcon}}</mat-icon></td>
        </tr>
        <tr *ngIf="!summary.token.valid">
          <td>Fortnox inte behörig</td>
          <td><button mat-stroked-button (click)="auth()"><div>Logga in</div></button></td>
        </tr>
        <tr *ngIf="summary.token.valid">
          <td>Financial year</td>
          <td *ngIf="summary.financial_year?.found">{{summary.financial_year?.financial_year}}<mat-icon class="smicon" [style]="okStyle">{{okIcon}}</mat-icon></td>
          <td *ngIf="!summary.financial_year?.found"><mat-icon class="smicon" [style]="badStyle">{{badIcon}}</mat-icon></td>
        </tr>
        <tr *ngIf="summary.token.valid">
          <td>Voucher serie</td>
          <td *ngIf="summary.voucher_serie?.found">{{summary.voucher_serie?.series_code}}<mat-icon class="smicon" [style]="okStyle">{{okIcon}}</mat-icon></td>
          <td *ngIf="!summary.voucher_serie?.found">{{summary.voucher_serie?.series_code}}<mat-icon class="smicon" [style]="badStyle">{{badIcon}}</mat-icon></td>
        </tr>
        <tr *ngIf="summary.token.valid">
          <td>Voucher serie manual</td>
          <td *ngIf="summary.voucher_serie?.manual">{{summary.voucher_serie?.series_code}}<mat-icon class="smicon" [style]="okStyle">{{okIcon}}</mat-icon></td>
          <td *ngIf="!summary.voucher_serie?.manual">{{summary.voucher_serie?.series_code}}<mat-icon class="smicon" [style]="badStyle">{{badIcon}}</mat-icon></td>
        </tr>
        <tr *ngIf="summary.token.valid && summary.cost_center">
          <td>Cost center</td>
          <td *ngIf="summary.cost_center?.found">{{summary.cost_center?.cost_center_code}}<mat-icon class="smicon" [style]="okStyle">{{okIcon}}</mat-icon></td>
          <td *ngIf="!summary.cost_center?.found"><mat-icon class="smicon" [style]="badStyle">{{badIcon}}</mat-icon></td>
        </tr>
        <tr>
          <td>Auth type</td>
          <td>{{summary.auth_type}}</td>
        </tr>
        <tr *ngIf="summary.date">
          <td>Subscriber</td>
          <td>{{summary.subscriber_id}}</td>
        </tr>
        <tr *ngIf="summary.date">
          <td>Refresh date</td>
          <td>{{summary.date}}</td>
        </tr>
      </table>
    </div>
  </div>
</div>

<div class="open-panel" style="max-width: 800px;" *ngIf="summary?.token?.valid">
  <div class="panel-title">Kontoplan</div>
  <div class="panel-body">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="account">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Account</th>
        <td mat-cell *matCellDef="let element"> {{element.account}} </td>
      </ng-container>

      <ng-container matColumnDef="included">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Included</th>
        <td mat-cell *matCellDef="let element"><mat-icon class="smicon" [style]="element.found_style" *ngIf="element.found_icon">{{element.found_icon}}</mat-icon></td>
      </ng-container>

      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
        <td mat-cell *matCellDef="let element"><mat-icon class="smicon" [style]="element.active_style" *ngIf="element.active_icon">{{element.active_icon}}</mat-icon></td>
      </ng-container>

      <ng-container matColumnDef="cost_center">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Cost center</th>
        <td mat-cell *matCellDef="let element"><span>{{element.cost_center}}</span><mat-icon class="smicon" [style]="element.cc_style" *ngIf="element.cc_icon">{{element.cc_icon}}</mat-icon></td>
      </ng-container>

      <ng-container matColumnDef="transaction_info">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction info</th>
        <td mat-cell *matCellDef="let element"> {{element.transaction_information}}<mat-icon class="smicon" [style]="element.ti_style" *ngIf="element.ti_icon">{{element.ti_icon}}</mat-icon></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
