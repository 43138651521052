import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {Receipt} from "../../../models/order";

@Component({
  selector: 'app-live-receipt-list-activity',
  templateUrl: './live-receipt-list-activity.component.html',
  styleUrls: ['./live-receipt-list-activity.component.css']
})
export class LiveReceiptListActivityComponent implements OnInit, OnDestroy {
  venueId: string;
  receiptKey: string;
  private subParams: Subscription;

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    this.subParams = this.route.paramMap.subscribe(data => {
      this.venueId = data.get("venue_id");
      const rk = data.get("receipt_key");
      if (rk != null && rk !== "0") {
        this.receiptKey = rk;
      }
    });
  }

  ngOnDestroy(): void {
    this.subParams?.unsubscribe();
  }

  receiptSelected(receipt: Receipt) {
    this.router.navigateByUrl(`venue/${this.venueId}/live-receipt-list/${receipt.key}`);
  }
}
