import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {HotTableRegisterer} from "@handsontable/angular";
import {Subscription} from "rxjs";
import {VenueConfig} from "../../../../models/venue-config";
import {DataFormSchema} from "../../../../models/data-form";
import {DataFormComponent} from "../../../data-form/data-form.component";
import {WFStep} from "../../../../models/workflow";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-wf-step',
  templateUrl: './wf-step.component.html',
  styleUrls: ['./wf-step.component.css']
})
export class WfStepComponent implements OnInit, OnChanges{
  @Input() step: WFStep;
  @Output() makeCopy = new EventEmitter<any>();
  @Output() moveUp = new EventEmitter<any>();
  @Output() moveDown = new EventEmitter<any>();
  @Output() deleted = new EventEmitter<any>();


  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  errorMessage: string;
  dataFormSchema: DataFormSchema;

  titleMap = {
    sprint: "Skriva ut bong",
    sync_print: "Skriva ut bong (synkroniserat)",
    bong: "Skapa KDS post",
    sync_bong: "Skapa KDS post (synkroniserat)",
    sync_print_bong: "Skriva ut bong och kds post (synkroniserat)",
    message: "Skicka meddelande till gäst",
  };

  schemaPrint = {key: "sub", title: {sv: "Utskrifter"}, desc: {sv: ""}, type: "table",
    columns: [
      {key: "printer", title: {sv: "Skrivare"}, type: "string", width: 120 },
      {key: "title", title: {sv: "Title"}, type: "string", width: 120 },
      {key: "filter", title: {sv: "Filter"}, type: "string", width: 300 },
      {key: "button", title: {sv: "Edit"}, type: "button", width: 50, callback: (row) => {
        console.log(row.filter);
        const u = this.router.createUrlTree(['../filter-editor'], {relativeTo: this.activatedRoute, queryParams: { filter: row.filter } });
        const url = this.router.serializeUrl(u);
        window.open(url, '_blank');
      } },
      {key: "guidance", title: {sv: "Guidance"}, type: "bool", width: 120},
    ]
  };

  dataFormSchemaPrint: DataFormSchema = {
    title: {sv: "Print settings"},
    desc: {sv: ""},
    properties: [
      this.schemaPrint
    ]
  };

  dataFormSchemaSyncPrint: DataFormSchema = {
    title: {sv: "Print settings"},
    desc: {sv: ""},
    properties: [
      {key: "pool", title: {sv: "Pool"}, desc: {sv: "Poolnamn vid flera synkpooler"}, type: "string", optional: true},
      {key: "timeout", title: {sv: "Pool timeout"}, desc: {sv: "Pool timeout faktor, lägre innebär snabbare timeout, standard är 60."}, type: "integer", optional: true},
      this.schemaPrint
    ]
  };

  dataFormSchemaSyncPrintBong: DataFormSchema = {
    title: {sv: "Print and bong settings"},
    desc: {sv: ""},
    properties: [
      {key: "pool", title: {sv: "Pool"}, desc: {sv: "Poolnamn vid flera synkpooler"}, type: "string", optional: true},
      {key: "timeout", title: {sv: "Pool timeout"}, desc: {sv: "Pool timeout faktor, lägre innebär snabbare timeout, standard är 60."}, type: "integer", optional: true},
      {key: "filter", title: {sv: "KDS Post filter"}, desc: {sv: "Artikelfilter för att välja alla artiklar som skall med på KDS posten"}, type: "string", optional: false},
      this.schemaPrint
    ]
  };

  dataFormSchemaBong: DataFormSchema = {
    title: {sv: "Bong settings"},
    desc: {sv: ""},
    properties: [
      {key: "filter", title: {sv: "Filter"}, desc: {sv: "Artikelfilter för att välja alla artiklar som skall med på KDS posten"}, type: "string", optional: false},
    ]
  };

  dataFormSchemaMessage: DataFormSchema = {
    title: {sv: "Message settings"},
    desc: {sv: ""},
    properties: [
      {key: "text", title: {sv: "Meddelanden"}, desc: {sv: ""}, type: "table",
        columns: [
          {key: "filter", title: {sv: "Filter"}, type: "string", width: 150 },
          {key: "spam", title: {sv: "Spam"}, type: "bool", width: 100, default: false },
          {key: "message", title: {sv: "Meddelande"}, type: "string", width: 300 },
        ]
      }
    ]
  };

  @ViewChild("dataform") dataForm: DataFormComponent;

  // sprint/sync_print: printer	title	filter	guidance
  // message: filter	spam	message

  private hotRegisterer = new HotTableRegisterer();
  private sub: Subscription;
  private config: VenueConfig;
  private columnList = [
    {data: "printer", title: "Skrivare", width: 120},
    {data: "title", title: "Titel", width: 120},
    {data: "filter", title: "Filter", width: 300},
    {data: "guidance", title: "Guidance", width: 120},
  ];

  ngOnChanges(changes: SimpleChanges) {
    console.log("ngOnChanges", changes);
    if (this.step != null) {
      switch (this.step.action) {
        case "sprint":
          this.dataFormSchema = this.dataFormSchemaPrint;
          break;
        case "sync_print":
          this.dataFormSchema = this.dataFormSchemaSyncPrint;
          break;
        case "sync_print_bong":
          this.dataFormSchema = this.dataFormSchemaSyncPrintBong;
          break;
        case "bong":
        case "sync_bong":
          this.dataFormSchema = this.dataFormSchemaBong;
          break;
        case "message":
          this.dataFormSchema = this.dataFormSchemaMessage;
          break;
      }
    }
  }

  ngOnInit(): void {
  }

  setActive(b: boolean) {
    this.step.active = b;
  }

  move(up: boolean) {
    if (up) {
      this.moveUp.emit(this.step.index);
    } else {
      this.moveDown.emit(this.step.index);
    }
  }

  delete() {
    this.deleted.emit(this.step.index);
  }

  copy() {
    this.makeCopy.emit(this.step.index);
  }

  collectStepData(): WFStep {
    const data: WFStep = this.dataForm.collectData();
    data.index = this.step.index;
    data.action = this.step.action;
    data.active = this.step.active;
    data.name = this.step.name;
    console.log("collectStepData", data);
    return data;
  }

}
