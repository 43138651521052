<div class="pos-container">
  <div class="master-detail">
    <div class="master-and-toolbar">
      <div class="pos-toolbar">
        <div class="menu-button bg-orange" (click)="select('floorplan')">BORD</div>
        <div class="menu-button bg-green" (click)="select('menu')">MENY</div>
        <div class="menu-button bg-turquoise" (click)="select('receipts')">KVITTON</div>
      </div>
      <div class="master" [ngClass]="{'mfull':!actionbarButtons,'maction':actionbarButtons}">
        <app-floorplan-v2 *ngIf="master=='floorplan'" [venueId]="venueId"></app-floorplan-v2>
        <app-ordering-pad *ngIf="master=='menu'"></app-ordering-pad>
        <app-live-receipt-list *ngIf="master=='receipts'" (selected)="receiptSelected($event)"></app-live-receipt-list>
      </div>
      <div *ngIf="actionbarButtons" class="master-action-bar">
        <div *ngFor="let action of actionbarButtons; let i = index" [ngClass]="'maction'+(action.checked?'c':(i%2))" class="master-action-button" (click)="masterActionSelected(action)">{{action.title}}</div>
      </div>
    </div>
    <div class="detail">
      <div class="top-detail">
        <div *ngIf="detail=='summary'" class="comp-con">
          <app-order-summary [data]="summaryData"></app-order-summary>
        </div>
        <div *ngIf="detail=='pay'" class="comp-con">
          <app-payment></app-payment>
        </div>
        <div *ngIf="detail=='new'" class="comp-con">
          <app-new-order></app-new-order>
        </div>
        <div *ngIf="detail=='receipt' || detail=='active-bill'" class="comp-con">
          <app-receipt-view></app-receipt-view>
        </div>
      </div>
      <div class="bottom-detail">
        <div *ngFor="let action of actions; let i = index" class="action-button" [ngClass]="'action'+i" (click)="actionSelected(action)">{{action.title}}</div>
      </div>
    </div>
  </div>
</div>
