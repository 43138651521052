import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataFormSchema} from "../../../models/data-form";
import {DataFormComponent} from "../../data-form/data-form.component";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {VenueService} from "../../../services/venue.service";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {LoyaltyCard} from 'src/app/models/loyalty';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-loyalty-editor',
  templateUrl: './loyalty-editor.component.html',
  styleUrls: ['./loyalty-editor.component.css']
})
export class LoyaltyEditorComponent implements OnInit, OnDestroy {

  title = "Lojalitet";
  loyaltyData: LoyaltyCard;
  dataFormSchema: DataFormSchema = {
    title: {sv: "Lojalitetskort"},
    desc: {sv: "Hantera lojalitetskort"},
    properties: [
      {key: "identity", title: {sv: "Kortnummer"}, desc: {sv: ""}, type: "string", optional: false},
      {key: "program_id", title: {sv: "Lojalitetsprogram id"}, desc: {sv: ""}, type: "config_enum", config_source: {path: "loyalty.programs", key: "id"}, optional: false},
      {key: "first_name", title: {sv: "Förnamn"}, desc: {sv: ""}, type: "string", optional: true},
      {key: "last_name", title: {sv: "Efternamn"}, desc: {sv: ""}, type: "string", optional: true},
      {key: "email", title: {sv: "E-post"}, desc: {sv: ""}, type: "string", optional: true},
      {key: "phone", title: {sv: "Telefon"}, desc: {sv: ""}, type: "string", optional: true},
      {key: "birthday", title: {sv: "Födelsedag"}, desc: {sv: ""}, type: "date", optional: true},
      {key: "valid_to", title: {sv: "Giltig till"}, desc: {sv: ""}, type: "datetime", optional: true},
    ]
  };

  @ViewChild("dataform") dataForm: DataFormComponent;
  private venueId: number;
  private cardKey: string;

  constructor(private route: ActivatedRoute, private dialog: MatDialog, private venueService: VenueService, private router: Router, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      const dk = data.get("card_key");
      if (dk.startsWith("new:")) {
        this.title = "Nytt lojalitetskort";
        this.cardKey = null;
        // @ts-ignore
        this.loyaltyData = new Set<LoyaltyCard>();
      } else {
        this.title = "Redigera lojalitetskort";
        this.cardKey = dk;
        this.beginObserving();
      }
    });
  }

  ngOnDestroy(): void {
  }

  private beginObserving() {
    this.venueService.fetchLoyaltyCard(this.venueId, this.cardKey).then(r => {
      this.loyaltyData = r;
    });
  }

  save() {
    const data = this.dataForm.collectData();
    if (this.cardKey) {
      data.card_key = this.cardKey;
    }
    this.venueService.updateLoyaltyCard(this.venueId, data).then(r => {
      this.loyaltyData = r;
    }).finally(() => {
      if (!this.cardKey) {
        this.router.navigateByUrl(`venue/${this.venueId}/loyalty`);
        this.snackBar.open(`Lojalitetskort skapat`, "", {duration: 2000});
      } else {
        this.snackBar.open(`Lojalitetskort uppdaterat`, "", {duration: 2000});
      }
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

}
