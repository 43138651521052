<h2 mat-dialog-title>Add filter</h2>
<div mat-dialog-content style="position: relative;">
  <div>
    <mat-form-field class="indialog" appearance="outline">
      <mat-label>Filter</mat-label>
      <mat-select [formControl]="filter" required (selectionChange)="changedFilter()">
        <mat-option value="amount">Amount</mat-option>
        <mat-option value="serial_number">Serial number</mat-option>
        <mat-option value="method">Betalmetod</mat-option>
        <mat-option value="table">Bord</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="indialog" appearance="outline" *ngIf="showQuery">
      <mat-label>{{subTitle}}</mat-label>
      <input matInput [formControl]="sub" required>
    </mat-form-field>

    <mat-form-field class="indialog" appearance="outline" *ngIf="showDropdown">
      <mat-label>{{subTitle}}</mat-label>
      <mat-select [formControl]="sub" required>
        <mat-option *ngFor="let pm of paymentMethods" [value]="pm.value">{{pm.name}}</mat-option>
      </mat-select>
    </mat-form-field>

  </div>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="add()">Add filter</button>
</mat-dialog-actions>

