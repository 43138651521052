import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subscription, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import utils from 'src/app/common/utils';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  error: string;
  hideP = true;
  hideCP = true;
  linkToken: string;
  getUserSub: Subscription;
  linkAccountSub: Subscription;
  form: UntypedFormGroup = new UntypedFormGroup({});
  sub: Subscription;

  constructor(
    public authService: AuthService,
    public loadingService: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    public api: ApiService,
    private formBuilder: UntypedFormBuilder
  ) {
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.authService.disableSigninPipe();
    this.form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(2)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(2)]]
    }, { validator: this.checkPasswords });
  }

  private checkPasswords(group: UntypedFormGroup) {
    const pass = group.controls.password.value;
    const confirmPass = group.controls.confirmPassword.value;
    if (pass !== confirmPass) {
      group.controls.confirmPassword.setErrors({ noMatch: true });
      return { noMatch: true };
    }
    return {};
  }

  private handleFirebaseError(error: any) {
    this.error = error ? utils.ParseError(error.code) : utils.ParseError();
    return throwError(error);
  }

  public submit() {
    const oobCode = this.route.snapshot.queryParams.oobCode;
    const password = this.form.controls.password.value;
    this.sub = this.authService.confirmPasswordReset(oobCode, password)
    .pipe(catchError(err => this.handleFirebaseError(err)), tap(_ => {
      return of(this.router.navigate(["signin"]));
    })).subscribe();

  }


}
