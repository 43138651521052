import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { VenueService } from "../../../services/venue.service";
import { LoadingService } from "../../../services/loading.service";
import { Venue } from 'src/app/models/venue-list';
import { MatTableDataSource } from '@angular/material/table';
import Utils from 'src/app/common/utils';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-venue-picker-dialog',
  templateUrl: './venue-picker-dialog.component.html',
  styleUrls: ['./venue-picker-dialog.component.css']
})
export class VenuePickerDialogComponent implements OnInit {

  public venueId: number;
  public data: DialogData;
  displayedColumns = ["name", "venue_id"];
  dataSource: MatTableDataSource<Venue>;
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }
  venueKey: string;

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<VenuePickerDialogComponent>) {
    this.data = data;
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource([] as Venue[]);
    this.applyFilterPredicate();
    const customColumns = [];
    const showInactive = true;
    this.venueService.getVenueList(customColumns, showInactive).then(data => {
      console.log("VenuePickerDialogComponent", data);
      this.dataSource.data = data;
    }).catch(e => {
      console.log("Error", e);
    });

  }

  posClicked() {
    this.dialogRef.close(this.venueId);
  }

  negClicked() {
    this.dialogRef.close(false);
  }

  trackById(index: number, item: Venue): string {
    return item.venue_id;
  }

  select(row: any) {
    console.log("select", row);
    this.venueKey = row.venue_key;
    this.venueId = Number(row.venue_id);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private applyFilterPredicate() {
    this.dataSource.filterPredicate = (data: Venue, filter: string) => {
      const venueName = Utils.sanitizeToLowerAlphaNumeric(data.name);
      const sanitizedFilter = Utils.sanitizeToLowerAlphaNumeric(filter);
      return venueName.includes(sanitizedFilter) ||
        data.venue_id.toString().toLowerCase().includes(sanitizedFilter);
    };
  }

  selectTopResult() {
    const venues = this.dataSource.filteredData;
    if (venues.length > 0) {
      this.venueKey = venues[0].venue_key;
      this.venueId = Number(venues[0].venue_id);
      this.posClicked();
    }
  }

}

export interface DialogData {
  title: string;
  message?: string;
  showProgress?: boolean;
  cancelButton?: string;
  positiveButton?: string;
  minWidth?: any;
  maxWidth?: any;
  disableClose?: boolean;
}
