import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NonTerminalDevice, SoftpayTerminal } from 'src/app/models/softpay';
import { VenueService } from 'src/app/services/venue.service';

@Component({
  selector: 'app-softpay-terminals',
  templateUrl: './softpay-terminals.component.html',
  styleUrls: ['./softpay-terminals.component.css']
})
export class SoftpayTerminalsComponent implements OnInit {

  venueId: number;
  terminals: SoftpayTerminal[];
  nonTerminalDevices: NonTerminalDevice[];
  displayedColumns = ["user_name", "device_user_email", "state", "updated"];
  dataSource: MatTableDataSource<SoftpayTerminal>;
  noData = false;

  constructor(private router: Router, private route: ActivatedRoute, private venueService: VenueService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
    });
    this.fetchSoftpayTerminals();
  }

  fetchSoftpayTerminals() {
    this.noData = false;
    this.venueService.fetchSoftpayTerminals(this.venueId).then(r => {
      console.log("Fetch Softpay Terminals", r);
      this.terminals = r.terminals;
      this.nonTerminalDevices = r.non_terminal_devices;
      this.dataSource = new MatTableDataSource(this.terminals);
      this.noData = this.terminals.length === 0;
    });
  }

  createSoftpayTerminal(deviceKey: string) {
    console.log(deviceKey);
    this.venueService.createSoftpayTerminal(this.venueId, deviceKey).then(r => {
      console.log("Created", r);
      this.fetchSoftpayTerminals();
    });
  }

  openDetails(key: string) {
    this.venueService.fetchSoftpayTerminalDetails(this.venueId, key).then(r => {
      console.log("Details", r);
    });
    // this.router.navigateByUrl(`venue/${this.venueId}/softpay-terminals/:${id}`);
    console.log(key);
  }

}
