import {Component, OnInit, ViewChild} from '@angular/core';
import {SettingHistory, SettingsPage} from "../../../models/settings-page";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, MatSortable} from "@angular/material/sort";
import {ActivatedRoute, Router} from "@angular/router";
import {VenueService} from "../../../services/venue.service";
import {getPagePaths, getSettingsPage} from "../../settings/pages/setting-pages";
import {FPVersion} from "../../../models/floorplan";

@Component({
  selector: 'app-floorplan-history-list',
  templateUrl: './floorplan-history-list.component.html',
  styleUrls: ['./floorplan-history-list.component.css']
})
export class FloorplanHistoryListComponent implements OnInit {
  venueId: string;

  displayedColumns = ["user_name", "date", "desc"];
  dataSource: MatTableDataSource<FPVersion>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  private paths: { anchorPath: string; rootPath: string };

  constructor(private route: ActivatedRoute, private venueService: VenueService, private router: Router) { }

  ngOnInit(): void {
    this.sort.sort(({ id: 'date', start: 'desc'}) as MatSortable);

    this.route.paramMap.subscribe(data => {
      this.venueId = data.get("venue_id");
      this.fetchHistory();
    });
  }

  private fetchHistory() {
    this.venueService.fetchFloorplanHistory(Number(this.venueId)).then(r => {
      this.dataSource = new MatTableDataSource(r as FPVersion[]);
      this.dataSource.sort = this.sort;
    });
  }

  select(row: any) {
    this.router.navigateByUrl(`venue/${this.venueId}/floorplan-editor?version=${row.key}`);
  }
}
