import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {VenueService} from "../../../services/venue.service";
import {MatTableDataSource} from "@angular/material/table";
import {Admin} from "../../../models/user";
import {Slot, SlotBookingRef, SlotDay, SlotMatrix, SlotMatrixRow} from "../../../models/slot";
import * as moment from 'moment';
import {NodeUtils, TimeUtils} from "../../../utils/utils";
import Utils from "../../../common/utils";
import {SlotUtils} from "../../../utils/slot-utils";
import {FireService} from "../../../services/fire.service";
import {NamespaceInfo} from "../../../models/venue-config";

@Component({
  selector: 'app-slot-calendar',
  templateUrl: './slot-calendar.component.html',
  styleUrls: ['./slot-calendar.component.css']
})
export class SlotCalendarComponent implements OnInit, OnDestroy {
  venueId: number;
  slotDays: SlotDay[];
  slotMatrix: SlotMatrix;
  namespace: string;
  namespaces: NamespaceInfo[];
  private sub: Subscription;

  constructor(public dialog: MatDialog, private route: ActivatedRoute, private venueService: VenueService, private fire: FireService, private router: Router) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.beginObserving();
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  private beginObserving() {
    this.sub = this.fire.observeVenueConfig(this.venueId).subscribe(cfg => {
      this.namespaces = cfg.takeaway?.slotting?.namespaces;
      if (this.namespaces != null && this.namespaces.length > 0) {
        this.namespace = this.namespaces[0].id;
      } else {
        this.namespace = "dns";
      }
      this.fetchSlots();
    });
  }

  private fetchSlots() {
    this.slotMatrix = undefined;
    this.venueService.fetchSlots(this.venueId, this.namespace).then( slotDays => {
      this.buildSlotDayMatrix(slotDays);
      this.slotDays = slotDays;
    });
  }

  private addDummySlots(into: any[], count: number) {
    for (let i = 0; i < count; i++) {
      into.push({});
    }
  }

  private buildSlotDayMatrix(slotDays: SlotDay[]) {
    if (slotDays == null || slotDays.length === 0) { return; }
    const bounds = SlotUtils.findEarliestAndLatestSlot(slotDays);
    const slotLength = slotDays[0].slot_length;

    // const times = [];
    // const slotCount = (this.getDayMinute(bounds.last.time) - this.getDayMinute(bounds.first.time)) / slotLength;
    // for (let i = 0; i < slotCount; i++) {
    //   times.push(i.toString());
    // }

    const dates = [];
    const cols = [];
    for (const slotDay of slotDays) {
      dates.push(slotDay.date_key);
      const allSlots = [];
      if (slotDay.slots != null && slotDay.slots.length > 0 ) {
        const first = slotDay.slots[0];
        const last = slotDay.slots[slotDay.slots.length - 1];
        const preDummySlots = (TimeUtils.getDayMinute(first.time) - TimeUtils.getDayMinute(bounds.first.time)) / slotLength;
        const postDummySlots = (TimeUtils.getDayMinute(bounds.last.time) - TimeUtils.getDayMinute(last.time)) / slotLength;
        this.addDummySlots(allSlots, preDummySlots);
        allSlots.push(...slotDay.slots);
        this.addDummySlots(allSlots, postDummySlots);
      }
      cols.push({allSlots, slotDay});
    }

    // Convert columns to rows
    const firstMinute = TimeUtils.getDayMinute(bounds.first.time);
    const rows: SlotMatrixRow[] = [];
    const slotCount = ((TimeUtils.getDayMinute(bounds.last.time) - TimeUtils.getDayMinute(bounds.first.time)) / slotLength) + 1;
    for (let i = 0; i < slotCount; i++) {
      const cells = [];
      for (const col of cols) {
        const slot = col.allSlots[i] as Slot;
        if (!NodeUtils.isNullOrEmpty(slot)) {
          const bookings = this.findBookingsForSlot(slot, col.slotDay);
          cells.push({slot, bookings});
        } else {
          cells.push({});
        }
      }
      rows.push({time: TimeUtils.formatTimeFromMinutes(firstMinute + i * slotLength), cells});
    }

    this.slotMatrix = {dates, rows};
  }

  private findBookingsForSlot(slot: Slot, slotDay: SlotDay) {
    if (!NodeUtils.isNullOrEmpty(slot.booked)) {
      const bookings = [];
      for (const book of slot.booked) {
        const bookingRef = slotDay.booking_refs.find( br => br.time === slot.time && br.ref === book.ref && !br.prel);
        if (bookingRef) {
          bookings.push(bookingRef);
        }
      }
      return bookings;
    }
    return undefined;
  }

  getWeekDayName(dateKey: string) {
    const wdi = Utils.dateKeyToMoment(dateKey).isoWeekday();
    return TimeUtils.getWeekDayName(wdi);
  }

  refresh() {
    this.fetchSlots();
  }

  changeNS(ns: string) {
    this.namespace = ns;
    this.fetchSlots();
  }

  openSummaryPage(date: string) {
    this.router.navigateByUrl(`venue/${this.venueId}/slot-day/${this.namespace}/${date}`);
  }

  openBooking(booking: SlotBookingRef) {
    console.log(booking);
    const ps = booking.ext_key.split("=");
    this.router.navigateByUrl(`venue/${this.venueId}/pos/receipt/${ps[1]}`);
  }
}


