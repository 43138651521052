<div class="report-container">

  <app-title-bar [title]="title"></app-title-bar>

  <div class="search-fields-root">
    <div class="search-fields">
      <mat-form-field appearance="outline">
        <mat-label>Day or start date</mat-label>
        <input matInput [(ngModel)]="searchStartDate" [matDatepicker]="startPicker">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker [touchUi]="isMobile"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>End date</mat-label>
        <input matInput [(ngModel)]="searchEndDate" [matDatepicker]="endPicker">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker [touchUi]="isMobile"></mat-datepicker>
      </mat-form-field>
      <button mat-flat-button class="search-button" (click)="updateParams()" color="primary">SÖK</button>
      <button mat-stroked-button class="dropdown-button" [matMenuTriggerFor]="menuHandleSection" *ngIf="true"><mat-icon>expand_more</mat-icon></button>
      <mat-menu #menuHandleSection="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="menuExport">Exportera</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="clearFilters()">Rensa sökning</button>
      </mat-menu>
      <mat-menu #menuExport="matMenu">
        <button mat-menu-item (click)="export('csv')">CSV-format</button>
      </mat-menu>
    </div>
  </div>

  <div class="generic-mastdet">
    <div class="generic-master">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="checkout_date" matSortDirection="desc" class="clicktable table-cells" matSortDisableClear="true">

        <ng-container matColumnDef="checkin_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Incheckning</th>
          <td mat-cell
              *matCellDef="let element">{{ element.checkin_date |date:'medium' }}</td>
        </ng-container>

        <ng-container matColumnDef="checkout_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Utcheckning</th>
          <td mat-cell
              *matCellDef="let element">{{ element.checkout_date |date:'medium' }}</td>
        </ng-container>

        <ng-container matColumnDef="checkin_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Id</th>
          <td mat-cell *matCellDef="let element">{{ element.checkin_id }}</td>
        </ng-container>

        <ng-container matColumnDef="datekey">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
          <td mat-cell *matCellDef="let element">{{ element.datekey }}</td>
        </ng-container>

        <ng-container matColumnDef="staff_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Personal Id</th>
          <td mat-cell *matCellDef="let element">{{ element.staff_id }}</td>
        </ng-container>

        <ng-container matColumnDef="staff_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Namn</th>
          <td mat-cell *matCellDef="let element" style="word-wrap: break-word; max-width: 100px; white-space: normal;">{{ element.staff_name }}</td>
        </ng-container>

        <ng-container matColumnDef="worked_minutes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Arbetstid</th>
          <td mat-cell *matCellDef="let element">{{ formatWorkTime(element.worked_minutes) }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)" [class.selected-row]="checkinId === row.checkin_id"></tr>
      </table>

      <div class="no-data" *ngIf="noData">Inget att rapportera för vald period</div>
      <div class="no-data" *ngIf="initData">Välj period ovan för att söka</div>
    </div>
    <div *ngIf="checkinId">
      <div class="lr-detail-container">
        <app-s-reports [venueId]="venueId" [key]="checkinId"></app-s-reports>
      </div>
    </div>
  </div>
</div>
