import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ApiService} from "../../services/api.service";

@Component({
  selector: 'app-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrls: ['./subscribe.component.css']
})
export class SubscribeComponent {
  email: string;
  type: string;
  message: string;
  hideAdd: boolean = false;
  success: boolean = false;
  unSubbed: boolean = false;
  listName: string;

  constructor(private route: ActivatedRoute, private api: ApiService) {
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.type = data.get("action");
      this.listName = data.get("name");
    });
    this.route.queryParamMap.subscribe(data => {
      const unsubToken = data.get("t");
      if (unsubToken == null) {
        return;
      }
      console.log("Unsubscribing " + unsubToken);
      this.api.unSubscribeNewsletter(unsubToken).then(r => {
        this.unSubbed = true;
        this.email = r.address;
      }).catch(e => {
        this.message = "Något gick fel. Försök igen senare.";
      });
    });
  }

  subscribe() {
    this.message = null;
    if (this.email == null || this.email.length === 0) {
      this.message = "Felaktig e-postadress";
      return;
    }
    console.log("Subscribing to " + this.email + " type " + this.type);
    this.api.subscribeNewsletter(this.email, this.listName).then(r => {
      this.hideAdd = true;
      this.success = true;
      this.unSubbed = false;
    }).catch(e => {
        this.message = "Något gick fel. Försök igen senare.";
    });
  }
}
