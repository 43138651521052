<div class="top-panel">
  <a *ngIf="editConfigUrl" mat-stroked-button href="{{editConfigUrl}}" target="_blank" color="primary">EDIT CONFIG <mat-icon style="font-size: 20px;">open_in_new</mat-icon></a>
  <button (click)="showFullConfig()" mat-stroked-button color="primary">FULL CONFIG</button>
  <button *ngFor="let button of buttons" (click)="selected(button)" mat-stroked-button color="primary">{{button}}</button>
  <button (click)="rawWorkflow()" mat-stroked-button color="primary">RAW WF</button>
</div>
<div class="cfg-container">
  <h1>{{title}}</h1>
  <pre>{{configString}}</pre>
</div>
