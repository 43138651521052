import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import Konva from 'konva';
import { SkvData } from 'src/app/models/skv-data';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-skv',
  templateUrl: './skv.component.html',
  styleUrls: ['./skv.component.css']
})

export class SkvComponent implements OnInit {

  @ViewChild('container', { static: true })
  container: ElementRef<HTMLDivElement>;
  skvData: SkvData;
  venueId: string;
  isVisible = false;
  isLoggedIn = false;
  private stage: Konva.Stage;
  private layer: Konva.Layer;

  constructor(private route: ActivatedRoute, private api: ApiService, public authService: AuthService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = data.get("venue_id");
      this.fetch();
    });
    this.authService.getLoggedInAuthState().subscribe(auth => {
      this.isLoggedIn = auth.result === 'valid' ? true : false;
    });
  }

  fetch() {
    this.api.fetchSkvData(Number(this.venueId)).then(r => {
      this.skvData = r;
      console.log(r);
      this.createKonvaStage();
    });
  }

  toggleParagraph() {
    this.isVisible = !this.isVisible;
  }

  createKonvaStage() {
    this.stage = new Konva.Stage({
      container: this.container.nativeElement,
      width: 800,
      height: 600
    });

    this.layer = new Konva.Layer();
    this.createShapes();
    this.stage.add(this.layer);
    this.layer.draw();
  }

  createShapes() {
    const rectWidth = 40;
    const rectHeight = 30;
    const spacing = rectHeight + 50;
    const borderMargin = 70;

    // Clients
    let xPos = borderMargin;
    let yPos = borderMargin;
    for (const client of this.skvData?.clients) {
      this.createRect(xPos, yPos);
      this.pointArrow(xPos + rectWidth / 2, yPos + rectHeight / 2, this.stage.width() / 2, this.stage.height() / 2);
      this.addText(client.reg_name, xPos, yPos);
      yPos += spacing;
      }

    // Terminals
    xPos = this.stage.width() - rectWidth - borderMargin;
    yPos = borderMargin;
    for (const terminal of this.skvData?.terminals) {
      this.createRect(xPos, yPos);
      this.pointArrow(xPos + rectWidth / 2, yPos + rectHeight / 2, this.stage.width() / 2, this.stage.height() / 2);
      this.addText(terminal.name, xPos, yPos);
      yPos += spacing;
    }

    // Kassaregister
    xPos = this.stage.width() / 2 - rectWidth / 2;
    yPos = this.stage.height() / 2 - rectHeight / 2;
    this.createRect(xPos, yPos);
    this.addText(`Central Programvara \nKassaregister\nCATO Kassaregister SE v3.0`, xPos, yPos);

    const yBottomPadding = 40; // Extra padding to compensate for the long text

    // Guests
    xPos = this.stage.width() / 2 - rectWidth / 2;
    yPos = borderMargin;
    this.createRect(xPos, yPos);
    this.pointArrow(xPos + rectWidth / 2, yPos + rectHeight / 2, this.stage.width() / 2, this.stage.height() / 2);
    this.addText('Gäster', xPos, yPos);

    // Cloud based control unit
    xPos = this.stage.width() / 2 - rectWidth / 2;
    yPos = this.stage.height() - rectHeight - borderMargin;
    this.createRect(xPos, yPos);
    this.pointArrow(xPos + rectWidth / 2, yPos + rectHeight / 2, this.stage.width() / 2, this.stage.height() / 2 + yBottomPadding);
    this.addText(`Molnbaserad kontrollenhet\n${this.skvData.ccu.ccu}`, xPos, yPos);
  }

  createRect(xPos: number, yPos: number, rWidth: number = 40, rHeight: number = 30) {
    const rect = new Konva.Rect({
      x: xPos,
      y: yPos,
      width: rWidth,
      height: rHeight,
      fill: 'lightblue',
      stroke: 'black',
      strokeWidth: 2
    });
    this.layer.add(rect);
  }

  addText(name: string, xPos: number, yPos: number) {
    // const offset = (name.length * 5 - 40) / 2;
    const shapeText = new Konva.Text({
      // x: xPos - offset,
      x: xPos - 60,
      y: yPos + 35,
      width: 160,
      text: name,
      fontSize: 14,
      fontFamily: 'Calibri',
      fill: 'black',
      align: 'center',
      verticalAlign: 'middle',
      wrap: 'word',
    });
    this.layer.add(shapeText);
  }

  pointArrow(xStart: number, yStart: number, xEnd: number, yEnd: number) {
    const xOffset = xStart === xEnd ? 0 : xStart < xEnd ? 40 : -40;
    const yOffset = xStart === xEnd ? yStart < yEnd ? 40 : -40 : 0;
    const arrow = new Konva.Arrow({
      points: [xStart + xOffset, yStart + yOffset, xEnd - xOffset, yEnd - yOffset],
      pointerLength: 0,
      pointerWidth: 0,
      fill: 'black',
      stroke: 'black',
      strokeWidth: 1
    });
    this.layer.add(arrow);
  }
}
