export const pageMewsSettings = {
  title: {sv: "Mews inställningar"},
  page_settings: {
    root_path: "mews"
  },
  desc: {sv: "Inställningar för MEWS integration"},
  properties: [
    {key: "client_token", title: {sv: "Client Token"}, desc: {sv: ""}, type: "string", optional: false},
    {key: "access_token", title: {sv: "Access Token"}, desc: {sv: ""}, type: "string", optional: false},
    {key: "service_id", title: {sv: "Service Id"}, desc: {sv: ""}, type: "string", optional: false},
    {key: "outlet_id", title: {sv: "Outlet Id"}, desc: {sv: "Anges om man avser att skicka alla externa betalningar som outlet bills till mews"}, type: "string", optional: true},
  ]
};
