export const pageManualPaymentMethods = {
  title: {sv: "Betalmetoder"},
  page_settings: {
    root_path: "payment.methods",
  },
  desc: {sv: "Ställ in betalmetoder"},
  properties: [
    {key: "buttons", title: {sv: "Betalmetoder"}, desc: {sv: "Ange vilka betalmedel som skall finnas som knappar i kassan."}, type: "table",
      columns: [
        {key: "key", title: {sv: "Key"}, type: "string", width: 200 },
        {key: "name", title: {sv: "Namn"}, type: "string", width: 200 },
        {key: "print_receipt", title: {sv: "Skriv ut kvitto"}, type: "bool", width: 100 },
        {key: "tip", title: {sv: "Dricks"}, type: "bool", width: 100 },
        {key: "favorite", title: {sv: "Favorit"}, type: "bool", width: 100 },
        {key: "require_pin", title: {sv: "Kräv kod"}, type: "bool", width: 100 },
        {key: "allow_split", title: {sv: "Delbetalning"}, type: "bool", width: 100 },
        {key: "props", title: {sv: "Egenskaper"}, type: "string", width: 200 },
      ]
    },
    {key: "invoice.mail_to", title: {sv: "Automatiskt e-post för fakturaunderlag"}, desc: {sv: "Hit skickas underlag för köp och returer för manuell hantering av faktura (gäller inte faktura integrerad via fortnox.)"}, type: "string[]", optional: true},
  ]
};

export const pageGoyadaSettings = {
  title: {sv: "Goyada"},
  page_settings: {
    root_path: "payment.goyada",
  },
  desc: {sv: "Goyada"},
  properties: [
    {key: "user_name", title: {sv: "Användarnamn"}, desc: {sv: ""}, type: "string", optional: true},
    {key: "password", title: {sv: "Lösenord"}, desc: {sv: ""}, type: "string", pwdField: true, hidePwd: true, optional: true, },
    {key: "shop_id", title: {sv: "Shop ID"}, desc: {sv: ""}, type: "string", optional: true},
    {key: "enable_qr_code", title: {sv: "Visa QR-kod"}, desc: {sv: ""}, type: "bool", optional: true},
  ]
};

export const pageMicrodebSettings = {
  title: {sv: "Microdeb"},
  page_settings: {
    root_path: "payment.microdeb",
  },
  desc: {sv: "Microdeb"},
  properties: [
    {key: "active", title: {sv: "Aktivt"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "terminal_id", title: {sv: "Terminal ID"}, desc: {sv: ""}, type: "string", optional: true},
    {key: "name", title: {sv: "Namn"}, desc: {sv: "Namn som visas i kassan"}, type: "string", optional: true},
    {key: "enable_qr_code", title: {sv: "Visa QR-kod"}, desc: {sv: ""}, type: "bool", optional: true},
  ]
};

export const pageRetain24Settings = {
  title: {sv: "Retain24"},
  page_settings: {
    root_path: "payment.retain24",
  },
  desc: {sv: "Retain24"},
  properties: [
    {key: "active", title: {sv: "Aktivt"}, desc: {sv: ""}, type: "bool", optional: true},
    {key: "name", title: {sv: "Namn"}, desc: {sv: "Namn som visas i kassan"}, type: "string", optional: true},
    {key: "simulation_key", title: {sv: "Användarnyckel"}, desc: {sv: "Nyckel från retain24 som används för att identifiera på vilken venue kortet används"}, type: "string", optional: true},
  ]
};

// export const pageManualPaymentMethods = {
//   title: {sv: "Betalmetoder"},
//   page_settings: {
//     root_path: "payment",
//     root_anchor: "manual_payment_methods"
//   },
//   desc: {sv: "Ställ in betalmetoder"},
//   properties: [
//     {key: "manual_payment_methods", title: {sv: "Betalmetoder"}, desc: {sv: ""}, type: "table", convert_from_tree: "key",
//       columns: [
//         {key: "key", title: {sv: "Key"}, type: "string", width: 200 },
//         {key: "name", title: {sv: "Namn"}, type: "string", width: 200 },
//         {key: "print_receipt", title: {sv: "Skriv ut kvitto"}, type: "bool", width: 100 },
//         {key: "tip", title: {sv: "Dricks"}, type: "bool", width: 100 },
//         {key: "favorite", title: {sv: "Favorit"}, type: "bool", width: 100 },
//         {key: "require_pin", title: {sv: "Kräv kod"}, type: "bool", width: 100 },
//         {key: "allow_split", title: {sv: "Delbetalning"}, type: "bool", width: 100 },
//         {key: "mail_to", title: {sv: "Mail"}, type: "csv", width: 200 },
//       ]
//     },
//   ]
// };
