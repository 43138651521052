<div>
  <p>
    <mat-toolbar>
      <span>Venue messages</span>
      <span class="spacer"></span>
      <button mat-button (click)="refresh()">
        <mat-icon>refresh</mat-icon> Uppdatera
      </button>
      <button mat-button (click)="newMessage()">
        <mat-icon>add</mat-icon> Nytt
      </button>
    </mat-toolbar>
  </p>
</div>

<div class="container">

  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>date</th>
      <td mat-cell *matCellDef="let element">{{element.date}}</td>
    </ng-container>
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>title</th>
      <td mat-cell *matCellDef="let element">{{element.title}}</td>
    </ng-container>
    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>message</th>
      <td mat-cell *matCellDef="let element">{{element.message}}</td>
    </ng-container>
    <ng-container matColumnDef="severity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>severity</th>
      <td mat-cell *matCellDef="let element">{{element.severity}}</td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>action</th>
      <td mat-cell *matCellDef="let element"><button mat-flat-button color="primary" (click)="unpublish(element.key)">UNPUBLISH</button></td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>

