import { Injectable } from '@angular/core';
import {FireService} from "./fire.service";
import {
  AlreadyPublishedError, HasChangesError,
  PublishMenuAllergene,
  PublishMenuRow, PublishMenuRowPrice,
  PublishMenuTab,
  PublishRequest,
  PublishResponse,
  ValidationError
} from "../components/catalog/menu-models/PublishModels";
import {first, flatMap, map, mergeAll, tap, toArray} from "rxjs/operators";
import {empty, from, Observable} from "rxjs";
import {
  CollectMenuContext,
  CollectTabContext,
  MenuCell,
  MenuTab, MenuTabSettings,
  MenuTabVersion, MenuTabVersionRef,
  MenuVersion, parseMenuTabSettings, TabVersionMenuCell
} from "../components/catalog/menu-models/MenuChange";
import {VenueService} from "./venue.service";
import {AuthService} from "./auth.service";
import Utils from "../common/utils";
import * as _ from "lodash";
import firebase from "firebase/compat/app";
import {NodeUtils} from "../utils/utils";
import Timestamp = firebase.firestore.Timestamp;
import {VenueConfig} from "../models/venue-config";

@Injectable({
  providedIn: 'root'
})
export class PublishService {

  private itemDB: {};
  private currentPublishTabs: MenuTab[];

  constructor(private fire: FireService, private venueService: VenueService, private auth: AuthService) { }

  publish(venueId: number, menuId: string) {
    return from(this.publishAsync(venueId, menuId));
  }

  /*
    - Collect data and build MenuVersionContext (ctx) but dont save current state
    - Fetch latest MenuVersion (lmv)
    - Compare lmv vs ctx, if no changes stop => eg nothing has changed
    - Convert MenuCell[] collection to PublishMenuTab[]
    - Send data to server for publish
    - For each current tab (ctx) compare with (lmv), ignore tabs that are same
    - Save each TabVersion for the each changed tab
    - Save MenuVersion from (ctx) containing a list of all current TabVersions (changed and unchanged)
  */
  private async publishAsync(venueId: number, menuId: string): Promise<any> {
    const latestMenuVersion = await this.fire.getLatestMenuVersion(venueId, menuId).toPromise();
    const ctx = await this.collectMenuVersionDataContext(venueId, menuId, latestMenuVersion);
    console.log("Context", ctx);

    // console.log("Latest version", latestMenuVersion);
    // console.log("Live version", ctx.menuVersion);
    if (latestMenuVersion?.hash === ctx.menuVersion.hash) {
      console.log("Latest version is same as current version");
      throw new AlreadyPublishedError();
    }

    //Build publish data
    const config = await this.fire.observeVenueConfig(venueId).pipe(first()).toPromise();
    this.itemDB = {};
    const menuTabs = ctx.tabContexts.map(tctx => tctx.menuTab);
    const publishTabs: PublishMenuTab[] = [];
    for (const tabCtx of ctx.tabContexts) {
      const pubMenuTab = this.buildTabMatrix(tabCtx.menuTab, tabCtx.tabCells, menuTabs, config);
      publishTabs.push(pubMenuTab);
    }
    console.log("Publish data", publishTabs);

    const response = await this.sendDataServer(venueId, menuId, publishTabs).toPromise();

    //Save tabVersions
    const menuTabVersionHashes = latestMenuVersion?.menuTabVersionRefs?.map(ref => ref.hash);
    const changedTabVersions = ctx.tabContexts.map(tc => tc.tabVersion).filter(tv => !_.includes(menuTabVersionHashes, tv.hash));
    for (const tabVersion of changedTabVersions) {
      await this.fire.createTabVersion(tabVersion);
    }

    //Save menuVersion
    await this.fire.createMenuVersion(ctx.menuVersion);
  }

  /*
    Ok version
    - Fetch restoring MenuVersion and TabVersions (rmv)
    - Delete all current tabs and cells
    - Build tabs and cells from version-data

    Maybe better version
    - Fetch restoring MenuVersion (rmv)
    - Collect data and build but dont save current state MenuVersion (cmv)
    - Fetch latest MenuVersion (lmv)
    - Compare hash if not same warn that data will be overwritten
    - Fetch latest TabVersions from (lmv)
    - For each (ctv) that are not same as (ltv), delete tab and cells
    - For each (ctv) that are same as (ltv) [eg. not edited since last publish] but not same as (rtv), delete tab and cells
    - For each (ctv) that are same as (ltv) [eg. not edited since last publish] and same as (rtv), do nothing
  */
  async restore(venueId: number, menuId: string, checkForChanges= false) {
    if (checkForChanges) {
      const latestMenuVersion = await this.fire.getLatestMenuVersion(venueId, menuId).toPromise();
      const ctx = await this.collectMenuVersionDataContext(venueId, menuId, latestMenuVersion);
      if (latestMenuVersion?.hash !== ctx.menuVersion.hash) {
        console.log("Restore will overwrite changes!");
        throw new HasChangesError();
      }
    }

    const restoringMenuVersion = await this.fire.getLatestMenuVersion(venueId, menuId).toPromise();
    if (!restoringMenuVersion) {
      throw new HasChangesError();
    }

    //Delete tabs and cells
    const tabs = await this.fire.getTabs(venueId, menuId).toPromise();
    for (const tab of tabs) {
      const cells = await this.fire.getCells(venueId, menuId, tab.id).toPromise();
      await this.fire.deleteMenuCells(cells);
    }
    await this.fire.deleteMenuTabs(tabs);

    //TODO remove
    // return;

    //Restore tabs and cells
    for (const tvRef of restoringMenuVersion.menuTabVersionRefs) {
      const tabVersion = await this.fire.getTabVersion(venueId, menuId, tvRef.tabId, tvRef.hash).toPromise();
      const cellData = JSON.parse(tabVersion.cell_data);
      await this.fire.restoreMenuCells(venueId, menuId, tabVersion.id, cellData);
      const menuTab = this.buildTabFromVersion(tabVersion);
      await this.fire.updateMenuTab(menuTab);
      console.log(`Restored tab ${menuTab.id}`);
    }
  }

  private async collectMenuVersionDataContext(venueId: number, menuId: string, latest?: MenuVersion): Promise<CollectMenuContext> {
    const menuTabs = await this.fire.getTabs(venueId, menuId).toPromise();
    const tabContexts: CollectTabContext[] = [];
    for (const menuTab of menuTabs) {
      const tabCells = await this.fire.getCells(venueId, menuId, menuTab.id).toPromise();
      const tabVersion = await this.buildMenuTabVersionForCellsAsync(menuTab, tabCells, (latest?.created ?? Timestamp.fromMillis(0)) );
      tabContexts.push({tabVersion, menuTab, tabCells});
    }
    const tabVersions = tabContexts.map(mtc => mtc.tabVersion);
    const menuVersion = await this.buildMenuVersion(tabVersions);
    const menuContext: CollectMenuContext = {menuVersion, tabContexts};
    return menuContext;
  }

  private sendDataServer(venueId: number, menuId: string, tabs: PublishMenuTab[]): Observable<PublishResponse> {
    console.log("Sending data to server...");
    const pr = new PublishRequest();
    pr.name = "MENU";
    pr.tabs = tabs.filter( tab => !["options", "pushpages"].includes(tab.id));
    pr.options = tabs.find( tab => tab.id === "options" );
    pr.pushpages = tabs.find( tab => tab.id === "pushpages" );
    return this.venueService.publishMenu(venueId, menuId, pr).pipe( tap(res => res.tabs = tabs ));
  }

  private async buildMenuTabVersionForCellsAsync(tab: MenuTab, cells: MenuCell[], since: Timestamp): Promise<MenuTabVersion> {
    const cellsStripped = cells.map(cell => NodeUtils.clean({ userId: cell.userId, row: cell.row, col: cell.col, updated: cell.updated, value: cell.value, changed: (cell.updated > since ? true : undefined) }) as TabVersionMenuCell);
    const cellData = JSON.stringify(cellsStripped);
    const cellValues = cells.map(cell => ({value: cell.value, ord: `${String(cell.row).padStart(4, '0')}:${cell.col}`})).filter(vo => vo.value);
    const sortedByOrd = _.sortBy(cellValues, [vo => vo.ord]).map(vo => vo.value);
    const tabHashRawData = sortedByOrd.join(",") + (tab.settingsJson ?? "");
    const hashHex = await Utils.hashStringSHA256Async(tabHashRawData);
    const changedCells = cellsStripped.filter(cell => cell.changed);
    const changes = changedCells.length > 10 ? `changed ${changedCells.length} cells` : changedCells.map(cc => cc.value).join(", ");
    const user = this.getUserInfo();
    const mtv: MenuTabVersion = {
      venueId: tab.venueId,
      menuId: tab.menuId,
      id: tab.id,
      name: tab.name,
      rows: tab.rows,
      columns: tab.columns,
      cell_data: cellData,
      hash: hashHex,
      user_email: user.email,
      user_name: user.name,
      changes
    };
    //return Utils.clean(mtv);
    if (tab.rowIndexHead) {
      mtv.rowIndexHead = tab.rowIndexHead;
    }
    if (tab.settingsJson) {
      mtv.settingsJson = tab.settingsJson;
    }
    return mtv;
  }

  private async buildMenuVersion(tabVersions: MenuTabVersion[]): Promise<MenuVersion> {
    const firstVersion = tabVersions[0];
    const tabVersionIds = tabVersions.map( tv => `${tv.id}:${tv.hash}` );
    const hash = await Utils.hashStringSHA256Async(tabVersionIds.join(","));
    const menuTabVersionRefs = tabVersions.map( tv => NodeUtils.clean({name: tv.name, tabId: tv.id, hash: tv.hash, changes: tv.changes}) as MenuTabVersionRef );
    const user = this.getUserInfo();
    const changes = tabVersions.filter(tv => tv.changes).map(tv => tv.changes).join(", ");
    const mv: MenuVersion = {
      venueId: firstVersion.venueId,
      menuId: firstVersion.menuId,
      hash,
      menuTabVersionRefs,
      user_email: user.email,
      user_name: user.name,
      changes
    };
    return mv;
  }

  private buildTab(tab: MenuTab): Observable<PublishMenuTab> {
    return this.fire.getCells(tab.venueId, tab.menuId, tab.id).pipe(
      map<MenuCell[], PublishMenuTab>( cells => this.buildTabMatrix(tab, cells) )
    );
  }

  private buildTabMatrix(tab: MenuTab, cells: MenuCell[], menuTabs?: MenuTab[], config?: VenueConfig): PublishMenuTab {

    const dbMatrix = {};
    cells.forEach( cell => this.updateDB(dbMatrix, cell));

    const menuTabSettings = parseMenuTabSettings(tab.settingsJson);
    const pubTab = new PublishMenuTab();
    const rows: PublishMenuRow[] = [];
    let index = 1;
    tab.rows.forEach( row => {
      // console.log("INDEX:", index);
      // console.log("ROW:", row);
      const tabRow = this.getRowFromDBMatrix(dbMatrix, row, index, tab, menuTabs, config, menuTabSettings);
      if ( tabRow ) {
        rows.push(tabRow);
      }
      index++;
    });

    // console.log(rows);
    pubTab.rows = rows;
    pubTab.name = tab.name;
    pubTab.id = tab.id;
    if (menuTabSettings.page_image) {
      pubTab.image = menuTabSettings.page_image;
    }
    return pubTab;
  }

  private updateDB(dbMatrix: {}, cell: MenuCell) {
    if (!(cell.row in dbMatrix)) {
      dbMatrix[cell.row] = {};
    }
    dbMatrix[cell.row][cell.col] = cell;
  }

  private getRowFromDBMatrix(dbMatrix: {}, row: number, index: number, tab: MenuTab, menuTabs?: MenuTab[], config?: VenueConfig, menuTabSettings?: MenuTabSettings): PublishMenuRow {
    const matrixRow = dbMatrix[row];
    if ( !matrixRow ) {
      return null;
    }

    const type = this.extractValueFromCell(matrixRow, "type");
    // console.log(type);
    if (type === "OFF") { return null; }

    const id = this.extractValueFromCell(matrixRow, "id");
    const name = this.extractValueFromCell(matrixRow, "name");
    const price = this.extractValueFromCell(matrixRow, "price");

    if (tab.id === "options") {
      if ( id == null && name == null) {
        return null;
      }
      if ( name == null ) {
        throw new ValidationError("Missing Name", tab.name, index);
      }
    } else if (tab.id === "pushpages") {

    } else {
      if (type !== "SEC" && type !== "CAT" && type !== "INC") {
        if ( id == null && name == null) {
          return null;
        }
        const isRef = id?.startsWith("@") ?? false;
        if ( !isRef ) {
          if ( name == null ) {
            throw new ValidationError("Missing Name", tab.name, index);
          }
          if ( id == null ) {
            throw new ValidationError("Missing Id", tab.name, index);
          }
          if ( price == null && type !== "OPN") {
            throw new ValidationError("Article has no price", tab.name, index);
          }
        }
      }
      if (type === "INC") {
        if (!id.startsWith("@") && !menuTabs.map(tb => tb.name).includes(name)) {
          throw new ValidationError(`INC points to wrong tab name: '${name}'`, tab.name, index);
        }
        // TODO check tab exists
      }
    }


    const r = new PublishMenuRow();
    r.index = index;
    r.id = id;
    r.name = name;
    r.tcs = type;
    r.price = price;
    r.ic = this.extractValueFromCell(matrixRow, "ic");

    this.copyValue(matrixRow, r, "desc");
    this.copyValue(matrixRow, r, "attributes");
    this.copyValue(matrixRow, r, "adjust");
    this.copyValue(matrixRow, r, "phase");
    this.copyValue(matrixRow, r, "push");
    this.copyValue(matrixRow, r, "image");
    this.copyValue(matrixRow, r, "ean");
    this.copyValue(matrixRow, r, "when");
    this.copyValue(matrixRow, r, "prop");
    this.copyValue(matrixRow, r, "resource_type");
    this.copyValue(matrixRow, r, "package");

    if (menuTabSettings?.show_glas_col) {
      this.copyValue(matrixRow, r, "glas");
    }
    if (menuTabSettings?.show_cogs_col) {
      this.copyValue(matrixRow, r, "cogs");
    }
    if (menuTabSettings?.show_deadline_col) {
      this.copyValue(matrixRow, r, "deadline");
    }
    if (menuTabSettings?.show_bong_name_col) {
      this.copyValue(matrixRow, r, "bong_name");
    }
    if (menuTabSettings?.show_bong_order_col) {
      const bongOrder = this.extractValueFromCell(matrixRow, "bong_order");
      if (bongOrder) {
        r.bong_order = Number(bongOrder);
      }
    }
    if (menuTabSettings?.show_pos_name_col) {
      this.copyValue(matrixRow, r, "pos_name");
    }
    if (menuTabSettings?.show_resource_col) {
      this.copyValue(matrixRow, r, "resource_type");
    }
    if (menuTabSettings?.show_cc_col) {
      this.copyValue(matrixRow, r, "cc");
    }
    if (menuTabSettings?.show_ean_col) {
      this.copyValue(matrixRow, r, "ean");
    }

    r.allergenes = this.getAllergenes(matrixRow);

    if (tab.id === "options") {
      const relPrice = this.extractValueFromCell(matrixRow, "relPrice");
      const fixPrice = this.extractValueFromCell(matrixRow, "fixPrice");
      if (fixPrice) {
        r.price = fixPrice;
      } else if (relPrice) {
        const sign = relPrice.startsWith("+") || relPrice.startsWith("-") ? "" : "+";
        r.price = sign + relPrice;
      }
    } else if (tab.id === "pushpages") {
      this.copyValue(matrixRow, r, "title");
      this.copyValue(matrixRow, r, "title1");
      this.copyValue(matrixRow, r, "items1");
      this.copyValue(matrixRow, r, "title2");
      this.copyValue(matrixRow, r, "items2");
      this.copyValue(matrixRow, r, "title3");
      this.copyValue(matrixRow, r, "items3");
      this.copyValue(matrixRow, r, "title4");
      this.copyValue(matrixRow, r, "items4");
    } else {
      if (config?.menu?.price_list) {
        const plr = [];
        for (const pl of config.menu.price_list) {
          const value = this.extractValueFromCell(matrixRow, pl.name);
          if (value && !this.isEmptyObject(value) && !this.isEmptyArray(value)) {
            plr.push(new PublishMenuRowPrice(pl.name, value));
          }
        }
        if (!NodeUtils.isNullOrEmpty(plr)) {
          r.prices = plr;
        }
      }
      //Copy tags
      const tags = [];
      this.addTag(matrixRow, tags, "takeaway");
      this.addTag(matrixRow, tags, "pickup");
      this.addTag(matrixRow, tags, "roomservice");
      for (const et of config?.menu?.editor_tags ?? []) {
        this.addTag(matrixRow, tags, et.tag);
      }
      if (tags.length > 0) {
        r.tags = tags;
      }

      if (type !== "SEC" && type !== "CAT") {
        this.addToItemDB(r, tab, index);
      }
    }

    this.copyValue(matrixRow, r, "allergenes");
    //const allergenes = this.getAllergenes(matrixRow);
    // console.log("allergenes", allergenes);
    // if (allergenes) {
    //   r.allergenes = allergenes;
    // }

    return r;
  }

  private getAllergenes(matrixRow: {} ): PublishMenuAllergene[] {
    const allIds = ["mp", "la", "eg", "gl", "wh", "pe", "cr", "ml", "sf", "fi", "nu", "se", "ce", "mu", "lu", "so", "sul",
                    "pork", "pia", "stf", "gel", "soybean", "ci", "on", "ga"];
    const allergenes = [];
    for (const id of allIds) {
      if (id in matrixRow) {
        const c = matrixRow[id];
        if (c.value) {
          allergenes.push(new PublishMenuAllergene(id, c.value));
        }
      }
    }
    if (allergenes.length > 0) {
      return allergenes;
    }
    return undefined;
  }

  private copyValue(matrixRow: {}, r: PublishMenuRow, key: string) {
    const value = this.extractValueFromCell(matrixRow, key);
    if (value && !this.isEmptyObject(value) && !this.isEmptyArray(value)) {
      //console.log(key, value);
      r[key] = value;
    }
  }

  private addTag(matrixRow: {}, arr: any[], tag: string) {
    const value = this.extractValueFromCell(matrixRow, tag);
    if (value === "true") {
      arr.push(tag);
    }
  }

  private extractValueFromCell(matrixRow: {} , key: string): any {
    if (key in matrixRow) {
      const cell = matrixRow[key];
      if (cell.dataType === "number") {
        return Number(cell.value);
      }
      if (cell.dataType === "bool") {
        return Boolean(cell.value);
      } else {
        return cell.value;
      }
    }
    return undefined;
  }

  private generateUniqueId(n: string) {
    let cleaned = n.replace(/[^0-9a-z-A-Z]/g, "").toLowerCase();
    while (cleaned in this.itemDB) {
      cleaned += "1";
    }
    return cleaned;
  }

  private verifiyPostTabBuild(tabs: PublishMenuTab[]) {
    const optionsTab = tabs.find(t => t.id === "options");
    for (const tab of tabs) {
      if (tab.id !== "options") {
        this.verifyTab(tab, optionsTab);
      }
    }
  }

  private verifyTab(tab: PublishMenuTab, optionsTab: PublishMenuTab) {
    for (const row of tab.rows) {
      if (row.attributes) {
        this.verifyOptions(row.attributes, optionsTab, tab, row.index);
      }
    }
  }

  private verifyOptions(attributes: string, optionsTab: PublishMenuTab, tab: PublishMenuTab, index: number) {
    const parts = attributes.split("|");
    for (const part of parts) {
      const refAttributes = part.split(",").filter(atr => atr.startsWith("@"));
      for (const refAtr of refAttributes) {
        const refWithoutAt = refAtr.split(":")[0].replace("@", "");
        const optRow = optionsTab.rows.find( o => o.id === refWithoutAt);
        if (!optRow) {
          throw new ValidationError(`Unknown attribute: ${refWithoutAt}`, tab.name, index);
        }
      }
    }
  }

  private addToItemDB(r: PublishMenuRow, tab: MenuTab, index: number) {
    const itemId = r.id;
    if (itemId.startsWith("@")) { return; }
    if (itemId in this.itemDB) {
      throw new ValidationError(`Found duplicate id: ${itemId}`, tab.name, index);
    }
    this.itemDB[itemId] = r;
  }

  private getUserInfo() {
    const email = this.auth.authStateSnapshot.user.email;
    const name = `${this.auth.authStateSnapshot.user.first_name} ${this.auth.authStateSnapshot.user.last_name}`;
    return {name, email};
  }

  private buildTabFromVersion(tabVersion: MenuTabVersion): MenuTab {
    const menuTab: MenuTab = {
      venueId: tabVersion.venueId,
      menuId: tabVersion.menuId,
      id: tabVersion.id,
      name: tabVersion.name,
      rows: tabVersion.rows,
      columns: tabVersion.columns,
      updated: firebase.firestore.Timestamp.now()
    };
    if (tabVersion.rowIndexHead) {
      menuTab.rowIndexHead = tabVersion.rowIndexHead;
    }
    if (tabVersion.settingsJson) {
      menuTab.settingsJson = tabVersion.settingsJson;
    }
    return menuTab;
  }

  public async deleteTabAndCells(venueId: number, menuId: string, tabId: string) {
    //Delete tabs and cells
    const tab = await this.fire.getMenuTab(venueId, menuId, tabId).toPromise();
    const cells = await this.fire.getCells(venueId, menuId, tabId).toPromise();
    await this.fire.deleteMenuCells(cells);
    await this.fire.deleteMenuTabs([tab]);
  }

  private isEmptyObject(obj: any): boolean {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  private isEmptyArray(arr: any[]): boolean {
    return arr.length === 0;
  }

}
