export const pageCashregSettings = {
  title: {sv: "Kassaregisterinställningar"},
  page_settings: {
    root_path: "cash_register"
  },
  desc: {sv: "Inställningar för kassaregister/kontrollenhet"},
  properties: [
    {key: "display_name", title: {sv: "Visningsnamn"}, desc: {sv: "Namnet på restaurangen (behöver inte anges om det ör liknande bolagsnamnet, syns ovanför bolagsnamnet på kvitto)"}, type: "string", optional: true},
    {key: "bus_name", title: {sv: "Företagsnamn"}, desc: {sv: "Namnet på bolaget som driver verksamheten (skall matcha med organisationsnummret)"}, type: "string"},
    {key: "org_number", title: {sv: "Organisationsnummer"}, desc: {sv: ""}, type: "string"},
    {key: "ccu", title: {sv: "CCU"}, desc: {sv: "Beteckning kontrollenhet (normalt CATO+orgnummer+11) RegisterID KOPIERAS FRÅN INFRASEC SIDAN"}, type: "string"},
    {key: "ccu_store_id", title: {sv: "Store id"}, desc: {sv: "Används endast när man har mer än en enhet på samma org-nummer, skall anges 1, 2, 3, 4 osv. Om inget anges så är default 1."}, type: "string", optional: true},
    {key: "cato", title: {sv: "Kassaversion"}, type: "enum", values: [{key: "CATOV3", title: {sv: "CATO V3"}}, {key: "CATOV2", title: {sv: "V2 - ANVÄND INTE PÅ NYA!"}}] },
    {key: "street", title: {sv: "Gata"}, desc: {sv: ""}, type: "string" },
    {key: "postal_code", title: {sv: "Postnummer"}, desc: {sv: ""}, type: "string" },
    {key: "city", title: {sv: "Stad"}, desc: {sv: ""}, type: "string" },
    {key: "country", title: {sv: "Land"}, desc: {sv: ""}, type: "string" },
    {key: "type", title: {sv: "Typ"}, type: "enum", values: [{key: "dev", title: {sv: "Test"}}, {key: "prod", title: {sv: "Produktion"}}] },
    {key: "zreport_time", title: {sv: "Z-rapport tid"}, desc: {sv: "Tidpunkt för Z-rapport"}, type: "enum", values: [{key: "06:00", title: {sv: "06:00"}}], optional: true },

    {key: "mail_to", title: {sv: "Skicka Z-rapport till"}, desc: {sv: ""}, type: "string[]", optional: true},
    {key: "zreport_summary_mail", title: {sv: "Skicka Z-rapport månadssummering till"}, desc: {sv: ""}, type: "string[]", optional: true},

  ]
};
