<h2 mat-dialog-title>{{dialogTitle}}</h2>
<div mat-dialog-content style="position: relative;">
  <form [formGroup]="form">
    <div>
      <mat-form-field class="indialog">
        <mat-label>Namn</mat-label>
        <input matInput formControlName="name" required>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="indialog">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" required>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="indialog">
        <mat-label>Adress</mat-label>
        <input matInput formControlName="address">
      </mat-form-field>
    </div>
  </form>
  <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="loadingService.visibility | async"></mat-spinner>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Avbryt</button>
  <button mat-button (click)="createNewVenue()" [disabled]="isCreatingVenue">Skapa</button>
</mat-dialog-actions>
