<div>
  <p>
    <mat-toolbar>
      <button mat-icon-button (click)="goBack()"><mat-icon>arrow_back_ios</mat-icon></button>
      <span>{{account?.name}}</span>
      <span class="spacer"></span>
      <button mat-button (click)="refresh()" *ngIf="selctedTabIndex == 1"><mat-icon>refresh</mat-icon> Uppdatera</button>
      <button mat-button (click)="createInvoice()" *ngIf="selctedTabIndex == 1"><mat-icon>add</mat-icon> Skapa faktura</button>
      <button mat-button (click)="refresh()" *ngIf="selctedTabIndex == 2"><mat-icon>refresh</mat-icon> Uppdatera</button>
      <button mat-button (click)="refresh()" *ngIf="selctedTabIndex == 3"><mat-icon>refresh</mat-icon> Uppdatera</button>
      <button mat-button (click)="duplicateCustomer()" *ngIf="selctedTabIndex == 4"><mat-icon>content_copy</mat-icon> Skapa kopia</button>
      <button mat-button (click)="saveInfo()" *ngIf="selctedTabIndex == 4"><mat-icon>save</mat-icon> Spara</button>
    </mat-toolbar>
  </p>
</div>

<mat-tab-group [(selectedIndex)]="selctedTabIndex" (selectedTabChange)="tabChanged($event)" mat-stretch-tabs="false">
  <mat-tab label="Kundöversikt">
    <div class="panels-container123" style="display: flex;">
      <div class="open-panel" style="width: 400px;">
        <div class="panel-title" style="display: flex; justify-content: space-between; align-items: center;">
          {{account?.name}} <mat-icon (click)="goToTab(4)" style="cursor: pointer;">edit</mat-icon></div>
        <div class="panel-body" *ngIf="selctedTabIndex == 0">
          <div class="prow"><mat-icon>assignment</mat-icon>
            <div class="info">
              <div>{{account?.org_number}}</div>
              <div>Org.nummer</div>
            </div>
          </div>

          <div class="prow"><mat-icon>business</mat-icon>
            <div class="info">
              <div>{{account?.name}}</div>
              <div>Namn</div>
            </div>
          </div>
          <div class="prow" *ngIf="account?.address"><mat-icon>location_on</mat-icon>
            <div class="info">
              <div>{{account?.address}}</div>
              <div>Adress</div>
            </div>
          </div>
          <div class="prow" *ngIf="account?.postal_number"><mat-icon>mail_outline</mat-icon>
            <div class="info">
              <div>{{account?.postal_number}}</div>
              <div>Postnummer</div>
            </div>
          </div>
          <div class="prow" *ngIf="account?.city"><mat-icon>location_city</mat-icon>
            <div class="info">
              <div>{{account?.city}}</div>
              <div>Stad</div>
            </div>
          </div>
          <div class="prow" *ngIf="account?.country"><mat-icon>public</mat-icon>
            <div class="info">
              <div>{{account?.country}}</div>
              <div>Land</div>
            </div>
          </div>
          <div class="prow" *ngIf="account?.email"><mat-icon>email</mat-icon>
            <div class="info">
              <div>{{account?.email}}</div>
              <div>E-post</div>
            </div>
          </div>
          <div class="prow" *ngIf="account?.phone"><mat-icon>phone</mat-icon>
            <div class="info">
              <div>{{account?.phone}}</div>
              <div>Telefon</div>
            </div>
          </div>
          <div class="prow" *ngIf="account?.contact_person"><mat-icon>person</mat-icon>
            <div class="info">
              <div>{{account?.contact_person}}</div>
              <div>Kontaktperson</div>
            </div>
          </div>
          <div class="prow" *ngIf="account?.price_list"><mat-icon>attach_money</mat-icon>
            <div class="info">
              <div>{{account?.price_list}}</div>
              <div>Prislista</div>
            </div>
          </div>
          <div class="prow" *ngIf="account?.accounting_id"><mat-icon>account_balance</mat-icon>
            <div class="info">
              <div>{{account?.accounting_id}}</div>
              <div>Kontonummer</div>
            </div>
          </div>
        </div>
      </div>
      <div class="open-panel" *ngIf="selctedTabIndex == 0 && (pendingOrdersCount > 0 || totalOrderAmount > 0)" style="width: 400px;">
        <div class="panel-title bg-orange" style="display: flex; justify-content: space-between; align-items: center;">
          Ordrar att hantera<mat-icon (click)="goToTab(1)" style="cursor: pointer;">notification_important</mat-icon>
        </div>
        <div class="panel-body">
          <div class="prow"><mat-icon>assignment</mat-icon>
            <div class="info">
              <div>{{pendingOrdersCount}} st</div>
              <div>Obetalda ordrar</div>
            </div>
          </div>
          <div class="prow"><mat-icon>paid</mat-icon>
            <div class="info">
              <div>{{totalOrderAmount}} kr</div>
              <div>Belopp att fakturera ink. moms</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
  <mat-tab label="Obetalda ordrar">
    <div style="margin: 16px;padding-top: 16px;">
      <app-pending-list #pendingListComponent [venueId]="venueId.toString()" [accountId]="accountId" state="pending" (ordersCount)="setPendingOrdersCount($event)" (totalAmount)="setTotalOrderAmount($event)"></app-pending-list>
    </div>
  </mat-tab>
  <mat-tab label="Hanterade ordrar">
    <ng-template matTabContent>
      <div style="margin: 16px;padding-top: 16px;">
        <app-pending-list #realizedListComponent [venueId]="venueId.toString()" [accountId]="accountId" state="realized"></app-pending-list>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="Betalt">
    <ng-template matTabContent>
      <div style="margin-top: 16px;padding: 16px;">
        <app-core-receipt-list #receiptListComponent [venueId]="venueId.toString()" [accountId]="accountId"></app-core-receipt-list>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab label="Redigera">
    <ng-template matTabContent>
      <div style="margin-top: 16px;padding: 16px;">
        <app-edit-venue-customer #editVenueCustomerComponent [venueId]="venueId" [accountKey]="accountId" [hideMenuBar]="true"></app-edit-venue-customer>
      </div>
    </ng-template>
  </mat-tab>

</mat-tab-group>