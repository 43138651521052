<div>
  <p>
    <mat-toolbar>
      <button mat-icon-button (click)="goBack()"><mat-icon>arrow_back_ios</mat-icon></button>
      <span>{{title}}</span>
      <span class="spacer"></span>
      <app-wiki-info-link title="Lär dig mer" section="support" path="device"></app-wiki-info-link>
      <button mat-button (click)="save()">
        <mat-icon>save</mat-icon> Spara
      </button>
    </mat-toolbar>
  </p>
</div>

<form [formGroup]="form" class="set-container">
    <div>
      <mat-form-field appearance="outline" class="set-form">
        <mat-label>Namn</mat-label>
        <input matInput formControlName="name" required>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="set-form">
        <mat-label>E-post</mat-label>
        <input matInput formControlName="email" required>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="set-form">
        <mat-label>Adress</mat-label>
        <input matInput formControlName="address">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline" class="set-form" >
        <mat-label>Publiceringsnyckel</mat-label>
        <input matInput [value]="authToken" [disabled]="true">
      </mat-form-field>
      <button mat-icon-button (click)="copyToClipboard('publiceringsnyckel')">
        <mat-icon>file_copy</mat-icon>
      </button>
    </div>    
</form>

<app-wiki-display section="intern-support-wiki" path="venue-info" [style]="'wiki-doc'"></app-wiki-display>
