import { Component, OnInit } from '@angular/core';
import {VenueService} from "../../../services/venue.service";
import {MatDialog} from "@angular/material/dialog";
import {KpiStats} from "../../../models/kpi-stats";

@Component({
  selector: 'app-kpi-dashboard',
  templateUrl: './kpi-dashboard.component.html',
  styleUrls: ['./kpi-dashboard.component.css']
})
export class KpiDashboardComponent implements OnInit {
  stats: KpiStats;

  constructor(private venueService: VenueService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.fetchStats();
  }

  fetchStats() {
    this.venueService.fetchKPIStats().then( res => {
      console.log(res);
      this.stats = res;
    });
  }
}
