export const pageBillingSettings = {
  title: { sv: "Fakturering" },
  page_settings: {
    root_path: "billing",
  },
  desc: "Inställningar för fakturering",
  properties: [
    {key: "email", title: { sv: "E-post" }, desc: {sv:  "" }, type: "string"},
    {key: "address", title: { sv: "Adress" }, desc: {sv:  "" }, type: "string"},
    {key: "address2", title: { sv: "Alternativ adress" }, desc: {sv:  "" }, type: "string", optional: true},
    {key: "zip_code", title: { sv: "Postnummer" }, desc: {sv:  "" }, type: "string"},
    {key: "city", title: { sv: "Postort" }, desc: {sv:  "" }, type: "string"},
    {key: "install_date", title: { sv: "Installationsdatum" }, desc: {sv: "Välj installationsdatum" }, type: "integer", optional: true},
    {key: "services", title: { sv: "Tjänster" }, desc: {sv: "Ange tjänster" }, type: "table",
      columns: [
        {key: "type", title: { sv: "Typ" }, desc: {sv: "Ange typ" }, type: "enum",
          source: ["license", "terminal", "ccu", "install", "monthly", "once", "discount"]},
        {key: "price", title: { sv: "Pris" }, desc: {sv: "Ange pris" }, type: "integer", optional: true},
        {key: "name", title: { sv: "Namn" }, desc: {sv: "Ange namn" }, type: "string", optional: true},
        {key: "desc", title: { sv: "Beskrivning" }, desc: {sv: "Ange beskrivning" }, type: "string", optional: true},
        {key: "from", title: { sv: "Från" }, desc: {sv: "Ange från" }, type: "date", width: "100px"},
        {key: "months", title: { sv: "Månader" }, desc: {sv: "Ange månader" }, type: "integer", optional: true},
        {key: "to", title: { sv: "Till" }, desc: {sv: "Ange till" }, type: "date", optional: true, width: "100px"},
      ]
    },
  ]
};
