import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { VenueService } from 'src/app/services/venue.service';
import { SimpleDialogComponent } from 'src/app/components/simple-dialog/simple-dialog.component';
import { SmartTable, EditSmartTableRequest } from 'src/app/models/smart-table';
import { DataFormSchema } from 'src/app/models/data-form';
import { DataFormComponent } from '../../data-form/data-form.component';

export const CREATE_SMART_TABLE = "Nytt SmartTable\u2122";
export const EDIT_SMART_TABLE = "Redigera SmartTable\u2122";

@Component({
  selector: 'app-edit-smart-table',
  templateUrl: './edit-smart-table.component.html',
  styleUrls: ['./edit-smart-table.component.css']
})
export class EditSmartTableComponent implements OnInit, OnDestroy {

  public pageTitle = EDIT_SMART_TABLE;
  public venueId: number;
  public dataFormSchema: DataFormSchema = {
    title: { sv: "SmartTable settings" },
    desc: { sv: "Ställ in SmartTable" },
    properties: [
      { key: "key", title: { sv: "key" }, desc: { sv: "" }, type: "string", optional: true, visible: false },
      { key: "account_key", title: { sv: "Konto" }, desc: { sv: "" }, type: "enum_source", optional: true, enum_source: "venue_customers" },
      { key: "table_names", title: { sv: "Anslutna bord" }, desc: { sv: "" }, type: "string[]", optional: true },
      { key: "data.custom_collect_data", title: { sv: "Uppgifter vid köp" }, desc: { sv: "" }, type: "table", optional: true,
        columns: [
          { key: "id", title: { sv: "id" }, type: "string", width: 150, optional: false },
          { key: "title", title: { sv: "Titel" }, type: "string", width: 150, optional: false },
          { key: "type", title: { sv: "Typ" }, type: "enum", source: ["text", "number"], width: 150, optional: false },
          { key: "placeholder", title: { sv: "Placeholder" }, type: "string", width: 150, optional: true },
          { key: "required", title: { sv: "Krävs" }, type: "bool", width: 50, optional: false, default: false},
        ],
        convert_from_tree: "data.custom_collect_data",
      },
      { key: "data.invoice_payment", title: { sv: "Fakturabetalning" }, desc: { sv: "" }, type: "enum", optional: false, default: "none",
        enums: [
          { value: "none", title: { sv: "Ingen" } },
          { value: "fortnox_invoice", title: { sv: "Fortnox faktura" } },
          { value: "account_entry", title: { sv: "Konto (samlingsfaktura)" } },
        ]
      },
      { key: "data.menu_filter", title: { sv: "Meny-filter" }, desc: { sv: "Ange ett filter som väljer ut de produkter som skall visas, t.ex. tag=smart1|smart2-tag=ejorder (OBS! ic-filter fungerar inte att använda)" }, type: "string", optional: true },
    ]
  };
  public smartTableData: SmartTable;
  @ViewChild("dataform") public dataForm: DataFormComponent;

  private smartTableKey: string;

  constructor(private route: ActivatedRoute, private dialog: MatDialog, private venueService: VenueService,
    private snackBar: MatSnackBar, private router: Router,) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      const stKey = data.get("key");
      if (stKey.startsWith("dup:")) {
        this.pageTitle = CREATE_SMART_TABLE;
        const dupKey = stKey.split(":")[1];
        this.smartTableKey = null;
        this.beginObserving(dupKey);
      } else if (stKey.startsWith("new:")) {
        this.pageTitle = CREATE_SMART_TABLE;
        Promise.resolve().then(() => {
          this.smartTableKey = null;
          this.smartTableData = {
            account_key: null,
            table_names: null,
          } as SmartTable;
        });
      } else {
        this.smartTableKey = stKey;
        this.beginObserving();
      }
    });
  }

  ngOnDestroy(): void {
  }

  private beginObserving(smartTableKey: string = this.smartTableKey) {
    this.venueService.getSmartTable(this.venueId, smartTableKey).then(st => {
      console.log("getSmartTable", st);
      st.table_names = st.connected_table_names.split(","); // Convert to array
      this.smartTableData = st;
    });
  }

  public hasChanges(): boolean {
    return false; // TODO: hasChanges returns true before saving
    return this.dataForm?.hasChanges() ?? false;
  }

  save() {
    const data = this.dataForm.collectData() as EditSmartTableRequest;
    if (this.smartTableKey) {
      data.key = this.smartTableKey;
    }
    this.venueService.editSmartTable(this.venueId, data).then(r => {
      if (this.smartTableKey) {
        this.snackBar.open("SmartTable\u2122 sparat", "", { duration: 2000 });
      } else {
        this.snackBar.open("SmartTable\u2122 skapat", "", { duration: 2000 });
        this.pageTitle = EDIT_SMART_TABLE;
        this.router.navigate(["/venue", this.venueId, "smart-tables", r.key]);
      }
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }
}
