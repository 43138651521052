import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FireService} from "../../services/fire.service";
import {combineLatest, fromEvent, Subscription} from "rxjs";
import {Floorplan, FloorplanTable} from "../../models/floorplan";
import {Session} from "../../models/Session";
import {Order, OrderSummary} from "../../models/order";
import {OrderService} from "../../services/order.service";
import {VenueService} from "../../services/venue.service";
import {LocationCode} from "../../models/venue-dashboard";
import { environment } from 'src/environments/environment';
import {PosStateService} from "../../services/pos-state.service";

@Component({
  selector: 'app-floorplan',
  templateUrl: './floorplan.component.html',
  styleUrls: ['./floorplan.component.css']
})
export class FloorplanComponent implements OnInit, OnDestroy {
  venueId: number;
  selected: FloorplanTable;
  summary: OrderSummary;
  locationCode: LocationCode;
  @ViewChild('canvas', { static: true })
  canvas: ElementRef<HTMLCanvasElement>;
  private ctx: CanvasRenderingContext2D;
  private sub: Subscription;
  private orderSub: Subscription;
  private resizeSub: Subscription;
  private subParams: Subscription;
  private floorplan: Floorplan;
  private selectedFloorName: string;
  private lines: any[];
  private floorplanWidth: number;
  private floorplanHeight: number;
  private canvasWidth: number;
  private widthRatio: number;
  private sessions: Session[];
  private displayedTables: FloorplanTable[];
  private orders: Order[];
  private unpaidTableNames: Map<string, boolean>;
  private codeCache = {};
  passeUrl: string;
  summaryData: {table: string, venueId: number};

  constructor(private route: ActivatedRoute, private fire: FireService, private orderService: OrderService,
              private venueService: VenueService, private router: Router, private state: PosStateService) { }

  ngOnInit(): void {
    this.passeUrl = environment.passe_url;
    this.subParams = this.route.paramMap.subscribe(data => {
      const venueId = Number(data.get("venue_id"));
      if (venueId !== this.venueId) {
        this.venueId = venueId;
        this.ctx = this.canvas.nativeElement.getContext('2d');
        this.beginObserve();
      }
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.orderSub?.unsubscribe();
    this.resizeSub?.unsubscribe();
    this.subParams?.unsubscribe();
  }

  private beginObserve() {
    this.sub?.unsubscribe();

    this.sub = combineLatest([
      this.fire.observeVenueConfig(this.venueId),
      this.fire.observeFloorplan(this.venueId),
      this.fire.observeActiveSessions(this.venueId),
      this.fire.observeOrders(this.venueId),
      ]
    ).subscribe(res => {
        const config = res[0];
        const floorplan = res[1];
        const sessions = res[2];
        const orders = res[3];
        this.floorplan = floorplan;
        this.sessions = sessions;
        this.orders = orders;
        // console.log("FLOORPLAN:", floorplan);
        // console.log("SESSIONS:", sessions);
        // console.log("ORDERS:", orders);

        this.floorplanWidth = this.floorplan.area.right - this.floorplan.area.left;
        this.floorplanHeight = this.floorplan.area.bottom - this.floorplan.area.top;

        this.selectedFloorName = this.state.getProp("selectedFloorName");
        if (!this.selectedFloorName && this.floorplan.floors?.length > 0) {
          this.selectedFloorName = this.floorplan.floors[0];
        }

        this.unpaidTableNames = this.getUnpaidTableName();
        // console.log(this.unpaidTableNames);

        this.updateFloorplan();
      }
    );
  }

  private findSize() {
    const w = Math.max(document.documentElement["clientWidth"], document.body["scrollWidth"], document.documentElement["scrollWidth"],
      document.body["offsetWidth"], document.documentElement["offsetWidth"]
    );

    this.ctx.canvas.width = w/2;
    this.canvasWidth = this.ctx.canvas.width;
    this.widthRatio = this.canvasWidth / this.floorplanWidth;
    this.ctx.canvas.height = this.floorplanHeight * this.widthRatio;

    if (!this.resizeSub) {
      this.resizeSub?.unsubscribe();
      this.resizeSub = fromEvent(window, 'resize').subscribe( evt => {
        // console.log('event: ', evt);
        this.updateFloorplan();
      });
    }
  }

  private updateFloorplan() {

    this.findSize();

    this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
    console.log("Redraw floor:", this.selectedFloorName);

    const vertices = {};
    this.lines = [];
    this.displayedTables = [];
    const filteredTables = this.floorplan.tables.filter(t => this.selectedFloorName === t.floor || this.selectedFloorName == null );
    for (const t of filteredTables) {
      if (t.type === "V") {
        vertices[t.alias] = t;
      } else if (t.type === "L") {
        this.lines.push(t);
      } else {
        this.drawTable(t);
      }
    }

    for (const table of this.lines) {
      this.drawLines(table, vertices);
    }

    for (const t of filteredTables) {
      if (t.type !== "V" && t.type !== "L") {
        if (this.unpaidTableNames.has(t.name)) {
          this.drawUnpaidIcon(t);
        }
      }
    }
  }

  private drawTable(table: FloorplanTable) {
    const cx = (table.x - this.floorplan.area.left) * this.widthRatio;
    const cy = (table.y - this.floorplan.area.top) * this.widthRatio;
    let wd = this.canvasWidth * this.floorplan.dim;
    let hd = this.canvasWidth * this.floorplan.dim;

    let form = "R";
    let isButton = false;
    const session = this.getSessionForTable(table);
    const sessionOpen = session?.active ?? false;
    switch (table.type) {
      case "4H":
        wd *= 2.0;
        break;
      case "3H":
        wd *= 1.5;
        break;
      case "4V":
        hd *= 2.0;
        break;
      case "3V":
        hd *= 1.5;
        break;
      case "4":
      case "4X":
        hd *= 2.0;
        wd *= 2.0;
        break;
      case "3":
      case "3X":
        hd *= 1.5;
        wd *= 1.5;
        break;
      default:
        if (table.type.endsWith("B")) {
          hd *= 1.2;
          const k = parseInt(table.type.replace("B", ""), 10);
          wd *= k;
          isButton = true;
          // floorLink = table.area
        } else if (table.type.startsWith("C")) {
          const k = parseInt(table.type.replace("C", ""), 10);
          wd *= k / 2;
          form = "C";
        }
    }
    table.isButton = isButton;

    if (isButton) {
      this.ctx.fillStyle = "#4880bb";
    } else if (sessionOpen) {
      this.ctx.fillStyle = "#7ED321";
    } else {
      this.ctx.fillStyle = "#8B572A";
    }

    if ( form === "C") {
      this.ctx.beginPath();
      this.ctx.arc(cx, cy, wd, 0, 2 * Math.PI, false);
      table.paintedCenterX = cx;
      table.paintedCenterY = cy;
      this.ctx.fill();
    } else {
      this.ctx.beginPath();
      this.ctx.rect(cx - wd, cy - hd, 2 * wd, 2 * hd);
      table.paintedCenterX = cx;
      table.paintedCenterY = cy;
      this.ctx.fill();
    }

    // Draw alias
    this.ctx.fillStyle = "#fff";
    this.ctx.font = "14px Arial";
    const tm = this.ctx.measureText(table.alias);
    this.ctx.fillText(table.alias, cx - tm.width / 2, cy + 5 );

    this.displayedTables.push(table);
  }

  drawLines(table: FloorplanTable, vertices) {
    this.ctx.lineCap = "round";
    this.ctx.lineJoin = "round";
    this.ctx.strokeStyle = "#808080";
    this.ctx.lineWidth = 5;
    this.ctx.beginPath();
    let first = true;
    const pairs = [];
    const vIndexes = table.area?.split("-") ?? [];
    for (const vIndex of vIndexes) {
      const v = vertices[vIndex];
      const x = (v.x - this.floorplan.area.left) * this.widthRatio;
      const y = (v.y - this.floorplan.area.top) * this.widthRatio;
      if (first) {
        first = false;
        this.ctx.moveTo(x, y);
      } else {
        this.ctx.lineTo(x, y);
      }
    }
    this.ctx.stroke();
  }

  drawUnpaidIcon(table: FloorplanTable) {
    const r = this.widthRatio * 1.3;
    const of = this.widthRatio * 2;
    this.ctx.beginPath();
    this.ctx.fillStyle = "#fd5050";
    this.ctx.arc(table.paintedCenterX - of, table.paintedCenterY + of, r, 0, 2 * Math.PI, false);
    this.ctx.fill();
  }

  private getSessionForTable(table: FloorplanTable): Session | null {
    return this.sessions.find(s => s.table === table.name);
  }

  onClick(event: MouseEvent) {
    const rect = this.ctx.canvas.getBoundingClientRect();
    const x = event.clientX - rect.left - 16; // 16 is padding
    const y = event.clientY - rect.top - 16; // 16 is padding
    console.log("CLICK: x: " + x + " y: " + y);

    const pairs = this.displayedTables.map( table => {
      const dx = table.paintedCenterX - x;
      const dy = table.paintedCenterY - y;
      const dist = Math.sqrt(dx * dx + dy * dy);
      return {table, dist};
    });
    if (pairs.length === 0) { return; }

    pairs.sort((a, b) => a.dist - b.dist);
    this.tablePressed(pairs[0].table);
  }

  private tablePressed(table: FloorplanTable) {
    if (table.isButton) {
      console.log("clicked:", table);
      this.selectedFloorName = table.area;
      this.state.setProp("selectedFloorName", this.selectedFloorName);
      this.updateFloorplan();
    } else {
      this.tableSelected(table);
    }
  }

  getUnpaidTableName(): Map<string, boolean> {
    const notPaidOrNotCanceld = this.orders.filter(ord => ord.finalized != null && ord.canceled == null && ord.paid == null);
    return new Map(notPaidOrNotCanceld.map(ord => ord.table).map(i => [i, true]));
  }

  private tableSelected(table: FloorplanTable) {
    console.log("selected:", table);
    this.selected = table;
    this.locationCode = null;
    //this.summaryData = {table: this.selected.name, venueId: this.venueId};
    //this.fetchLocationForTable();
    this.router.navigateByUrl(`venue/${this.venueId}/pos/summary/${table.name}`);
  }

  private fetchLocationForTable() {
    if (this.selected.name in this.codeCache) {
      this.locationCode = this.codeCache[this.selected.name];
      return;
    }

    this.venueService.fetchLocationCode(this.venueId, this.selected.name).then(lc => {
      this.locationCode = lc;
      console.log(lc);
      this.codeCache[this.selected.name] = lc;
    });
  }
}
