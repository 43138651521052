export const pageCustomerDisplays = {
  title: {sv: "Kunddisplayer"},
  page_settings: {
    root_path: "customer_display"
  },
  desc: {sv: "Kunddisplayer"},
  properties: [
    {key: "left_title", title: {sv: "Vänster underrubrik"}, desc: {sv: "Underrubrik som visas till vänster"}, type: "string", optional: true, width: 200 },
    {key: "right_title", title: {sv: "Höger underrubrik"}, desc: {sv: "Underrubrik som visas till höger"}, type: "string", optional: true, width: 200 },
    {key: "order_number_displays", title: {sv: "Ordernummer displayer"}, desc: {sv: "Displayer som visar ordernummer vid t.ex. pickup och kassaförsäljning används tillsammans med KDS"}, type: "table",
      columns: [
        {key: "active", title: {sv: "Aktiv"}, type: "bool", width: 60 },
        {key: "identity", title: {sv: "Identitet (unik/hemlig)"}, type: "string", width: 200 },
        {key: "display_name", title: {sv: "Rubrik"}, type: "string", optional: true, width: 200 },
        {key: "bong_done_timeout_seconds", title: {sv: "Timeout (s)"}, desc: {sv: "Hur många sekunder färdiga beställningar visas på skärmen"}, type: "integer", optional: true, width: 100, },
        {key: "kds_filter", title: {sv: "KDS-filter"}, type: "string", width: 200 },
        {key: "hide_logo", title: {sv: "Dölj logotyp"}, type: "bool", width: 100 },
      ],
      table_links: {route: 'orders', table_column: 'identity', type: 'link'}
    },
  ]
};
