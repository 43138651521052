import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {VenueService} from "../../../../services/venue.service";
import {LoadingService} from "../../../../services/loading.service";
import {AuthService} from "../../../../services/auth.service";

@Component({
  selector: 'app-new-chain-dialog',
  templateUrl: './new-chain-dialog.component.html',
  styleUrls: ['./new-chain-dialog.component.css']
})
export class NewChainDialogComponent implements OnInit {
  email = new FormControl('', [Validators.email, Validators.required]);
  name = new FormControl('', [Validators.required]);
  path = new FormControl('', []);

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<NewChainDialogComponent>, private auth: AuthService) {
  }

  ngOnInit(): void {
  }

  addUser() {
    if (this.name.valid && this.path.valid && this.email.valid) {
      this.venueService.createNewChain(this.name.value, this.email.value, this.path.value, "menu").then( r => {
        this.dialogRef.close(true);
      });
    }
  }

}
