export const pageVenueSettings = {
  title: { sv: "Venue" },
  page_settings: {
    root_path: "venue",
  },
  admin_options: ["fetch_auth_token"],
  desc: {sv: "Inställningar för restaurangen"},
  properties: [
    {header: "Status"},
    {key: "state", title: {sv: "Restaurangens status"}, desc: {sv: 'Status för restaurangen'}, type: "enum", optional: true,
      values: [
        {key: "active", title: {sv: "Aktiv"}},
        {key: "inactive", title: {sv: "Inaktiv"}},
        {key: "disabled", title: {sv: "Stängd"}},
      ]
    },
    {header: "Kontaktuppgifter"},
    {key: "full_name", title: {sv: "Fullständigt namn"}, type: "string", desc: {sv: "Företagets fullständiga namn"}},
    {key: "name", title: {sv: "Namn"}, type: "string", desc: {sv: "Restaurangens namn"}},
    {header: "Övrigt", hidden: true},
    {key: "chain_id", title: {sv: "chain id"}, type: "string", desc: {sv: "Chain id för restaurangen"}, optional: false, hidden: true},
  ]
};
