<div>
  <p>
    <mat-toolbar>
      <span>Rabattkoder</span>
      <span class="spacer"></span>
      <button mat-button (click)="addCode()">
        <mat-icon>note_add</mat-icon> Ny rabattkod
      </button>
    </mat-toolbar>
  </p>
</div>
<div style="padding: 16px;">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Kod </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;max-width: 125px;font-size:12px;white-space: normal;word-wrap: break-word;"> {{element.code}} </td>
    </ng-container>

    <ng-container matColumnDef="valid">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Giltig </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.valid}} </td>
    </ng-container>

    <ng-container matColumnDef="program">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Namn </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="times_used">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Antal användningar </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.times_used}} </td>
    </ng-container>

    <ng-container matColumnDef="max_usages">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Max användningar </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.max_usages}} </td>
    </ng-container>

    <ng-container matColumnDef="max_count">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Max användare </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.max_count}} </td>
    </ng-container>

    <ng-container matColumnDef="users">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Antal användare </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.user_count}} </td>
    </ng-container>

    <ng-container matColumnDef="balance_left">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Återstående saldo </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.balance_left}} </td>
    </ng-container>

    <ng-container matColumnDef="balance_init">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Startsaldo </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.balance_init}} </td>
    </ng-container>

    <ng-container matColumnDef="valid_to">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Giltig till </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.valid_to|date:'medium'}} </td>
    </ng-container>

    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Skapad </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.created|date:'medium'}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></tr>
  </table>

  <button *ngIf="result?.more" mat-flat-button (click)="nextPage()" style="margin-top: 32px;">Nästa sida ></button>
</div>
