<div class="transactions-container">

  <app-title-bar [title]="title"></app-title-bar>

  <div>
    <div class="search-fields">
      <mat-form-field appearance="outline">
        <mat-label>Day or start date</mat-label>
        <input matInput [(ngModel)]="searchStartDate" [matDatepicker]="startPicker">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>End date</mat-label>
        <input matInput [(ngModel)]="searchEndDate" [matDatepicker]="endPicker">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>

      <button mat-flat-button class="search-button" (click)="fetchTransactions()" color="primary">SEARCH</button>

      <button mat-stroked-button class="search-button" [matMenuTriggerFor]="menuHandleSection" *ngIf="true"><mat-icon>expand_more</mat-icon></button>
      <mat-menu #menuHandleSection="matMenu">
        <button mat-menu-item (click)="clearFilters()">Rensa sökning</button>
      </mat-menu>

    </div>
  </div>

  <div class="generic-mastdet">
    <div class="generic-master">
      <table mat-table [dataSource]="dataSource" matSort class="clicktable table-cells" (matSortChange)="sortChanged($event)" matSortActive="date" matSortDirection="desc" matSortDisableClear="true">

        <ng-container matColumnDef="correction_of_key">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let element"><mat-icon [ngStyle]="{'color' : element.correction_of_key ? 'red' : 'green', 'vertical-align' : 'bottom'}">sync_alt</mat-icon></td>
        </ng-container>

        <ng-container matColumnDef="cashbox_id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Kassalåda</th>
          <td mat-cell *matCellDef="let element">{{ element.cashbox_id }}</td>
        </ng-container>

        <ng-container matColumnDef="tx_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Namn</th>
          <td mat-cell *matCellDef="let element">{{ element.tx_name }}</td>
        </ng-container>

        <ng-container matColumnDef="comment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Kommentar</th>
          <td mat-cell *matCellDef="let element" [ngStyle]="{'max-width' : '100px'}">{{ element.comment }} </td>
        </ng-container>

        <ng-container matColumnDef="staff_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Personal</th>
          <td mat-cell *matCellDef="let element">{{ element.staff_name }} </td>
        </ng-container>

        <ng-container matColumnDef="payment_method">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Betalmedel</th>
          <td mat-cell *matCellDef="let element">{{ element.payment_method }} </td>
        </ng-container>

        <ng-container matColumnDef="direction">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Typ</th>
          <td mat-cell *matCellDef="let element">{{ element.direction }} </td>
        </ng-container>

        <ng-container matColumnDef="ic">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>IC</th>
          <td mat-cell *matCellDef="let element">{{ element.ic }} </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Datum</th>
          <td mat-cell
          *matCellDef="let element">{{ element.date |date:'medium' }} </td>
        </ng-container>

        <ng-container matColumnDef="device_user_email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>E-post</th>
          <td mat-cell *matCellDef="let element">{{ element.device_user_email }} </td>
        </ng-container>

        <ng-container matColumnDef="amount_cent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Belopp</th>
          <td mat-cell *matCellDef="let element">{{ element.amount_cent | moneySuff: 'cent'}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)" [class.selected-row]="transactionKey === row.key"></tr>
      </table>

    </div>
    <div *ngIf="transactionKey" class="generic-detail">
      <div class="lr-detail-container"><app-dossier-viewer [printInstructions]="printOptions.dossier?.instructions"></app-dossier-viewer></div>
      <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="showSpinner"></mat-spinner>
    </div>

  </div>

</div>
