<div class="report-customer-container">

  <div class="search-fields-root">
    <div class="search-fields">
      <mat-form-field appearance="outline">
        <mat-label>Day or start date</mat-label>
        <input matInput [(ngModel)]="searchStartDate" [matDatepicker]="startPicker">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>End date</mat-label>
        <input matInput [(ngModel)]="searchEndDate" [matDatepicker]="endPicker">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker></mat-datepicker>
      </mat-form-field>
      <!--New-->
      <button mat-flat-button class="search-button" (click)="fetch()" color="primary">SÖK</button>
      <span class="spacer"></span> 
      <button mat-button [matMenuTriggerFor]="menuExport" *ngIf="!isMobile"><mat-icon>download</mat-icon> Exportera</button>
      <button mat-stroked-button *ngIf="isMobile" class="dropdown-button" #reportsMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="menuHandleSection"><mat-icon>expand_more</mat-icon></button>

      <mat-menu #menuHandleSection="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="menuExport">Exportera</button>
      </mat-menu>
      <mat-menu #menuExport="matMenu">
        <button mat-menu-item (click)="export('csv')">CSV-format</button>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="export('sie')">SIE-fil</button>
      </mat-menu>

    </div>
  </div>

  <div class="generic-mastdet">
    <div class="generic-master">
      <div class="stat-title" *ngIf="title">{{title}}</div>
      <table mat-table [dataSource]="dataSource" matSort class="clicktable" matSortActive="pay_date" matSortDirection="desc" matSortDisableClear="true">

        <ng-container matColumnDef="section">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sektion</th>
          <td mat-cell *matCellDef="let element">{{ element.section }}</td>
        </ng-container>

        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Kategori</th>
          <td mat-cell *matCellDef="let element"> {{ element.category }} </td>
        </ng-container>

        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Belopp</th>
          <td mat-cell *matCellDef="let element"> {{ element.amount | moneySuff: "fullcent" }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></tr>
      </table>

      <div class="no-data" *ngIf="noData">Perioden innehåller inget data</div>
      <div class="no-data" *ngIf="initData">Välj period ovan för att söka ut data</div>

    </div>
  </div>

</div>


