<div class="report-tree-container">

  <app-title-bar [title]="title"></app-title-bar>

  <div class="search-fields-root">
    <div class="search-fields">
      <mat-form-field class="date-field" appearance="outline">
        <mat-label>Day or start date</mat-label>
        <input matInput [(ngModel)]="searchStartDate" [matDatepicker]="startPicker">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker [touchUi]="isMobile"></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="date-field" appearance="outline">
        <mat-label>End date</mat-label>
        <input matInput [(ngModel)]="searchEndDate" [matDatepicker]="endPicker">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker [touchUi]="isMobile"></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="time-field" appearance="outline">
        <mat-label>Start time</mat-label>
        <input matInput [(ngModel)]="startTime">
        <mat-icon matSuffix>schedule</mat-icon>
      </mat-form-field>
      <mat-form-field class="time-field" appearance="outline">
        <mat-label>End time</mat-label>
        <input matInput [(ngModel)]="endTime">
        <mat-icon matSuffix>schedule</mat-icon>
      </mat-form-field>
      <mat-form-field class="time-field" appearance="outline">
        <mat-label>{{interval ? interval / 60 + " min": "Interval"}}</mat-label>
        <mat-select [(ngModel)]="interval">
          <mat-option value="300">5 min</mat-option>
          <mat-option value="600">10 min</mat-option>
          <mat-option value="900">15 min</mat-option>
          <mat-option value="1200">20 min</mat-option>
          <mat-option value="1800">30 min</mat-option>
          <mat-option value="3600">60 min</mat-option>
        </mat-select>
        <mat-icon matSuffix>hourglass_empty</mat-icon>
      </mat-form-field>
      <button mat-flat-button class="search-button" (click)="updateParams()" color="primary">SÖK</button>

      <button mat-stroked-button class="dropdown-button" #reportsMenuTrigger="matMenuTrigger" [matMenuTriggerFor]="menuOptions"><mat-icon>expand_more</mat-icon></button>
      <mat-menu #menuOptions="matMenu">
        <div style="display: flex;flex-direction: column;">
          <mat-checkbox style="padding: 8px 12px;" color="primary" #menuItems
                        *ngFor="let item of hourlyOptions; let i = index;"
                        [(ngModel)]="item.active"
                        (click)="$event.stopPropagation()"
                        (change)="selectedOption(item)">{{ item.title }}</mat-checkbox>
        </div>
      </mat-menu>

    </div>
  </div>

  <div>
    <div class="base-tree-container">
      <div id="chart" *ngIf="chartOptions" style="margin-right: 8px;">
        <apx-chart
          [series]="chartOptions.series"
          [chart]="chartOptions.chart"
          [xaxis]="chartOptions.xaxis"
          [yaxis]="chartOptions.yaxis"
          [dataLabels]="chartOptions.dataLabels"
          [grid]="chartOptions.grid"
          [stroke]="chartOptions.stroke"
          [title]="chartOptions.title"
          [colors]="chartOptions.colors"
        ></apx-chart>
      </div>
    </div>
    <div class="no-data" *ngIf="noData">Perioden innehåller ingen försäljning</div>
    <div class="missing-data" *ngIf="missingData">OBS! Allt data för perioden har inte hämtats, välj ett kortare datumspann.</div>
  </div>

</div>
