import {Component, Inject, NgZone, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {VenueService} from "../../services/venue.service";
import {LoadingService} from "../../services/loading.service";
import {AuthService} from "../../services/auth.service";
import {HackUtils} from "../../utils/utils";
import {Observable} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.css']
})
export class SimpleDialogComponent implements OnInit {
  data: DialogSettings;

  public static openSimpleDialog(dialog: MatDialog, settings: DialogSettings): MatDialogRef<SimpleDialogComponent> {
    const dialogRef = dialog.open(SimpleDialogComponent, HackUtils.DLG({data: settings}));
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
      if (settings.callback) {
        settings.callback();
      }
    });
    return dialogRef;
  }

  public static observeSimpleDialog(dialog: MatDialog, settings: DialogSettings): Observable<any> {
    const dialogRef = dialog.open(SimpleDialogComponent, HackUtils.DLG({data: settings}));
    return new Observable(observer => {
      dialogRef.afterClosed().subscribe(result => {
        console.log('Dialog result:', result);
        observer.next(result);
        observer.complete();
      });

      dialogRef.backdropClick().subscribe(() => {
        dialogRef.close(false);
      });
    });
  }

  public static openLoomDialog(dialog: MatDialog, settings: DialogSettings): MatDialogRef<SimpleDialogComponent> {
    const t = `https://www.loom.com/embed/${settings.loomVideoId}`;
    const html = `<div style="position: relative; padding-bottom: 60vh; height: 0;"><span style="color: grey;font-size: 16px;">Loading...</span><iframe src="${t}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>`;
    settings.untrustedHtml = html;
    settings.cancelButton = "Close";
    settings.minWidth = "85vw";
    settings.maxWidth = "95vw";
    return this.openSimpleDialog(dialog, settings);
  }

  public static showErr(dialog: MatDialog, error: any): MatDialogRef<SimpleDialogComponent> {
    console.log(error);
    let message;
    if (error instanceof HttpErrorResponse) {
      message = error?.error?.message;
      if (message == null) {
        message = error.message;
      }
    } else {
      message = error?.message;
    }
    const customMessage = this.getCustomMessage(message);
    const settings = {
      title: customMessage.title,
      message: customMessage.message,
      cancelButton: "Close"
    };
    return this.openSimpleDialog(dialog, settings);
  }


  private static getCustomMessage(message: string): {message: string, title: string} {
    switch (message) {
      case "Too many requests":
        return {title: "Ett fel har inträffat!", message: "Det maximala antalet förfrågningar för en viss tidsperiod har överskridits. Vänligen försök igen senare."};
      default:
        return {title: "Error", message};
    }
  }

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<SimpleDialogComponent>, private auth: AuthService, private sanitizer: DomSanitizer) {
    this.data = data;
    if (this.data.untrustedHtml) {
      this.data.htmlBody = sanitizer.bypassSecurityTrustHtml(this.data.untrustedHtml);
    }
  }

  ngOnInit(): void {
  }

  posClicked() {
    this.dialogRef.close(true);
  }

  negClicked() {
    this.dialogRef.close(false);
  }

}

export interface DialogSettings {
  title: string;
  message?: string;
  htmlBody?: string | SafeHtml;
  untrustedHtml?: any;
  loomVideoId?: string;
  showProgress?: boolean;
  cancelButton?: string;
  positiveButton?: string;
  callback?: () => void;
  minWidth?: any;
  maxWidth?: any;
  disableClose?: boolean;
}
