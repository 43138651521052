import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {PosStateService} from "../../../services/pos-state.service";
import {Subscription} from "rxjs";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {detailedDiff} from "deep-object-diff";
import {getPagePaths} from "../../settings/pages/setting-pages";
import {MatDialog} from "@angular/material/dialog";
import {VenueService} from "../../../services/venue.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {OrderItem, Receipt} from "../../../models/order";
import {EnterGuestAddressDialogComponent} from "../enter-guest-address-dialog/enter-guest-address-dialog.component";
import {HackUtils} from "../../../utils/utils";

@Component({
  selector: 'app-pos',
  templateUrl: './pos.component.html',
  styleUrls: ['./pos.component.css']
})
export class PosComponent implements OnInit, OnDestroy {
  venueId: number;
  table: string;
  mode: string;
  summaryData: {table: string, venueId: number};
  actions: any;
  master: string;
  detail: string;
  private itemChangeSub: Subscription;
  private subParams: Subscription;
  private subCEO: Subscription;
  private subSel: Subscription;
  actionbarButtons: any[];
  private lastSelectedEditableItem: OrderItem;

  constructor(private route: ActivatedRoute, private router: Router, private state: PosStateService, private dialog: MatDialog,
              private venueService: VenueService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.subParams = this.route.paramMap.subscribe(data => {
      this.processRouteChange(data);
    });

    this.subCEO = this.state.observeCurrentEditableOrder().subscribe( ceo => {
      console.log("Editable order updated", ceo);
      if (ceo != null && ceo.items.length > 0) {
        this.onOrderPadAdd();
      }
    });

    this.subSel = this.state.selectedEditableItemSubject.subscribe(orderItem => {
      this.lastSelectedEditableItem = orderItem;
      this.updateActionBarButtons();
    });
  }

  ngOnDestroy(): void {
    this.itemChangeSub?.unsubscribe();
    this.subParams?.unsubscribe();
    this.subCEO?.unsubscribe();
    this.subSel?.unsubscribe();
  }

  private processRouteChange(data: ParamMap) {
    const venueId = Number(data.get("venue_id"));
    if (venueId !== this.venueId) {
      this.state.setVenueId(venueId);
      this.venueId = venueId;
      // TODO
    }

    this.table = this.state.getProp("table", data, null, "null");
    const mode = this.state.getProp("mode", data);
    this.mode = mode;

    const hasNewOrder = this.state.orderAtTable(this.table) != null;
    if (mode === "summary") {
      this.master = "floorplan";
      if (hasNewOrder) {
        this.router.navigateByUrl(`venue/${this.venueId}/pos/new/${this.table}`);
        return;
      }
      this.detail = "summary";
    } else if (mode === "floorplan") {
      this.master = "floorplan";
      this.detail = this.state.getProp("detail");
    } else if (mode === "fp") {
      this.master = this.state.getProp("master", null, "floorplan");
      this.detail = this.state.getProp("detail");
    } else if (mode === "menu") {
      this.master = "menu";
      this.detail = this.state.getProp("detail", null, "detail");
    } else if (mode === "pay") {
      this.master = this.state.getProp("master", null, "floorplan");
      this.detail = "pay";
    } else if (mode === "new") {
      this.master = "menu";
      this.detail = "new";
      this.setupNewOrder();
    } else if (mode === "receipts") {
      this.master = "receipts";
      this.detail = this.state.getProp("detail");
    } else if (mode === "receipt") {
      this.master = this.state.getProp("master", null, "receipts");
      this.detail = "receipt";
    } else if (mode === "active-bill") {
      this.master = this.state.getProp("master", null, "receipts");
      this.detail = "active-bill";
    } else {
      this.master = "floorplan";
      this.detail = hasNewOrder ? "new" : "summary";
    }

    // console.log("this.mode: ", this.mode);
    // console.log("this.master: ", this.master);
    // console.log("this.detail: ", this.detail);
    this.state.setProp("master", this.master);
    this.state.setProp("detail", this.detail);

    this.updateActionButtons();
    this.updateActionBarButtons();
  }

  updateActionButtons() {
    if (this.detail === "summary") {
      this.summaryData = {table: this.table, venueId: this.venueId};
      // this.actions = [{id: "pay", title: "BETALA"}, {id: "edit", title: "ÄNDRA"}, {id: "new", title: "NY"}];
      this.actions = [{id: "pay", title: "BETALA"}, {id: "new", title: "NY"}];
    } else if (this.detail === "pay") {
      this.actions = [{id: "back2summary", title: "ÅTERGÅ"}, {id: null, title: ""}, {id: null, title: ""}];
    } else if (this.detail === "active-bill") {
      this.actions = [{id: "back2pay", title: "ÅTERGÅ"}, {id: "send-payment-link", title: "SKICKA LÄNK"}, {id: null, title: ""}];
    } else if (this.detail === "receipt") {
      this.actions = [{id: null, title: ""}, {id: "send_receipt", title: "SKICKA"}, {id: "refund", title: "RETUR"}];
    } else if (this.detail === "new") {
      const itemCount = this.state.currentOrder()?.items?.length ?? 0;
      if (itemCount === 0) {
        this.actions = [{id: "back2summary", title: "ÅTERGÅ"}, {id: null, title: ""}, {id: null, title: ""}];
      } else {
        this.actions = [{id: "clear", title: "RENSA"}, {id: "send", title: "SKICKA"}];
      }
    } else {
      this.actions = [];
    }
  }

  updateActionBarButtons() {
    if (this.master === "menu" && this.detail === "new" && this.lastSelectedEditableItem) {
      const p = this.lastSelectedEditableItem.phase;
      const wait = this.lastSelectedEditableItem.wait;
      this.actionbarButtons = [{id: "fr", title: "FR", checked: p === -1}, {id: "hr", title: "HR", checked: p === 0}, {id: "ds", title: "DS", checked: p === 1}, {id: "w", title: "W", checked: wait}];
    } else {
      this.actionbarButtons = undefined;
    }
  }

  select(mode: string) {
    this.router.navigateByUrl(`venue/${this.venueId}/pos/${mode}/${this.table}`);
  }

  actionSelected(action: {id: string, title: string}) {
    console.log("Action selected: ", action);
    if (action.id === "pay") {
      this.router.navigateByUrl(`venue/${this.venueId}/pos/pay/${this.table}`);
    } else if (action.id === "new") {
      this.router.navigateByUrl(`venue/${this.venueId}/pos/new/${this.table}`);
    } else if (action.id === "back2summary") {
      this.state.voidOrder(this.table);
      this.router.navigateByUrl(`venue/${this.venueId}/pos/summary/${this.table}`);
    } else if (action.id === "back2pay") {
      this.router.navigateByUrl(`venue/${this.venueId}/pos/pay/${this.table}`);
    } else if (action.id === "send") {
      this.sendOrder();
    } else if (action.id === "clear") {
      this.state.voidOrder(this.table);
      this.router.navigateByUrl(`venue/${this.venueId}/pos/summary/${this.table}`);
    } else if (action.id === "send-payment-link") {
      this.openEnterPhoneNumberDialog();
    }
  }

  masterActionSelected(action: any) {
    console.log("Master action selected: ", action);
    let phase = this.lastSelectedEditableItem.phase;
    let wait = this.lastSelectedEditableItem.wait;
    if (action.id === "fr") {
      phase = -1;
    } else if (action.id === "hr") {
      phase = 0;
    } else if (action.id === "ds") {
      phase = 1;
    } else if (action.id === "w") {
      wait = wait == null || wait === 0 ? 1 : 0;
    }
    this.state.setPhaseWait(phase, wait);
    this.lastSelectedEditableItem = undefined;
    this.updateActionBarButtons();
  }

  private setupNewOrder() {
    console.log("setupNewOrder");
    const order = this.state.getOrderAtTable(this.table, true);
    this.itemChangeSub?.unsubscribe();
    this.itemChangeSub = order?.observeItemsChanges().subscribe( items => {
      this.updateActionButtons();
    });
  }

  private sendOrder() {
    const dialogRef = SimpleDialogComponent.openSimpleDialog(this.dialog, {title: "Ordering...", showProgress: true, cancelButton: "Close"});
    const order = this.state.currentOrder();
    if (order == null) {
      return;
    }
    this.venueService.sendOrder(Number(this.venueId), order).then( res => {
      dialogRef.close();
      this.state.clearCurrentOrder();
      this.router.navigateByUrl(`venue/${this.venueId}/pos/summary/${this.table}`);
    }).catch(err => {
      console.log(err);
      dialogRef.close();
      this.snackBar.open("Some error when sending order...", "", {duration: 3000});
    });
  }

  onOrderPadAdd() {
    if (this.detail !== "new") {
      // console.log("onOrderPadAdd");
      this.router.navigateByUrl(`venue/${this.venueId}/pos/new/${this.table}`);
    }
  }

  receiptSelected(receipt: Receipt) {
    this.router.navigateByUrl(`venue/${this.venueId}/pos/receipt/${receipt.key}`);
  }

  openEnterPhoneNumberDialog() {
    const dialogRef = this.dialog.open(EnterGuestAddressDialogComponent, HackUtils.DLG({
      data: { title: "Skicka betallänk", label: "Ange telefonnummer", placeholder: "0760123123", venueId: this.venueId }
    }));
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
      if (result) {
        const phoneNumber = result.value;
        this.venueService.sendPaymentLink(this.venueId, this.table, phoneNumber).then( res => {
          this.snackBar.open(`Betallänk skickad till ${phoneNumber}`, "Ok", {});
        }).catch(e => SimpleDialogComponent.showErr(this.dialog, e)).finally( () => console.log("ERROR") );
      }
    });
  }

}
