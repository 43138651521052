<h2 mat-dialog-title>Select IC: {{data.name}}</h2>
<div  style="padding: 0px 24px 24px 24px;">
  <div style="display: flex;flex-direction: row;">
    <div>
  <!--    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">-->
  <!--      &lt;!&ndash; This is the tree node template for leaf nodes &ndash;&gt;-->
  <!--      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>-->
  <!--        &lt;!&ndash; use a disabled button to provide padding for tree leaf &ndash;&gt;-->
  <!--        <button mat-icon-button disabled></button>-->
  <!--        {{node.name}}-->
  <!--      </mat-tree-node>-->
  <!--      &lt;!&ndash; This is the tree node template for expandable nodes &ndash;&gt;-->
  <!--      <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>-->
  <!--        <button mat-icon-button matTreeNodeToggle-->
  <!--                [attr.aria-label]="'Toggle ' + node.name">-->
  <!--          <mat-icon class="mat-icon-rtl-mirror">-->
  <!--            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}-->
  <!--          </mat-icon>-->
  <!--        </button>-->
  <!--        {{node.name}}-->
  <!--      </mat-tree-node>-->
  <!--    </mat-tree>-->
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="okClicked()">OK</button>
  </div>
</div>
