import { Component, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { first } from 'rxjs/operators';
import { QuerySalesReportResponse } from 'src/app/models/reporting';
import { FireService } from 'src/app/services/fire.service';
import { LocalFileUtils } from 'src/app/utils/utils';

@Component({
  selector: 'app-report-table',
  templateUrl: './report-table.component.html',
  styleUrls: ['./report-table.component.css']
})
export class ReportTableComponent implements OnInit, OnChanges {
  @Input() tableData!: QuerySalesReportResponse;
  @Input() venueId!: string;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  displayedColumns: string[];
  dataSource: MatTableDataSource<any>;
  footers?: number[];

  constructor(private fire: FireService) { }

  ngOnInit(): void {
    this.sort.sort(({ id: this.tableData?.data.headers[0].name, start: 'desc'}) as MatSortable);
    this.displayedColumns = this.tableData.data.headers.map(h => h.name);
    this.dataSource = new MatTableDataSource(this.mapTableData().rows);
    this.dataSource.sortingDataAccessor = (data, headerId) => {
      switch (headerId) {
        case 'datum':
          return new Date(data[headerId]);
          default:
            return data[headerId];
          }
        };
    this.dataSource.sort = this.sort;
    this.footers = this.tableData.data.footers;
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource(this.mapTableData().rows);
    this.dataSource.sort = this.sort;
    this.footers = this.tableData.data.footers;
  }

  getType(index: number): string {
    if (!this.tableData || !this.tableData.data || !this.tableData.data.headers || !this.tableData.data.headers[index]) {
      return '';
    }
    return this.tableData.data.headers[index].type.toLowerCase() || '';
  }

  private mapTableData() {
    const headers = this.tableData.data.headers.map(h => {
      return {col: h.name, title: h.name};
    });
    const rows = this.tableData.data.rows.map(r => {
      const row = {};
      r.forEach((v, i) => {
        row[headers[i].col] = v;
      });
      return row;
    });
    const footers = {};
    this.tableData.data.footers.forEach((f, i) => {
      footers[headers[i].col] = f;
    });
    return {headers, rows, footers};
  }

  customTableHeaderName(name: string) {
    switch (name.toLocaleLowerCase()) {
      case 'item_count':
        return 'Antal';
      default:
        return name;
    }
  }

  public async export() {
    const data = this.buildDataArrayWithHeader();
    const config = await this.fire.observeVenueConfig(Number(this.venueId)).pipe(first()).toPromise();
    const dates = LocalFileUtils.createDateFileName(this.tableData.params.start_date, this.tableData.params.end_date);
    const filename = `customer_report_${config.venue.name.replace(/\\s/g, "_")}_${dates}.csv`;
    LocalFileUtils.createFileWithBOM(data, filename);
  }

  private buildDataArrayWithHeader() {
    const mappedTableData = this.mapTableData();
    mappedTableData.rows.push(mappedTableData.footers);
    return LocalFileUtils.compileDataIntoCSV(mappedTableData.headers, mappedTableData.rows);
  }

}
