import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HeywikiService} from "../../../services/heywiki.service";
import {HeyWikiDocument, WikiEditor} from "../../../models/genji-models";
import {AuthService} from "../../../services/auth.service";
import {User} from "../../../models/user";
import {environment} from "../../../../environments/environment";
import {FireService} from "../../../services/fire.service";
import firebase from "firebase/compat/app";
import * as moment from 'moment';
import {interval, Subscription} from "rxjs";

@Component({
  selector: 'app-wiki-document-editor',
  templateUrl: './wiki-document-editor.component.html',
  styleUrls: ['./wiki-document-editor.component.css']
})
export class WikiDocumentEditorComponent implements OnInit, OnDestroy {
  markdown: string;
  section: string;
  path: string;
  previewHtml = "- Inget innehåll -";
  wikiDoc: HeyWikiDocument;
  private user: User;
  isProd: boolean;
  previewStyle = "wiki-panel";
  editors: WikiEditor[];
  private allEditors: WikiEditor[];
  private sub: Subscription;
  private timerSub: Subscription;

  constructor(private route: ActivatedRoute, private heywiki: HeywikiService, public authService: AuthService, private fire: FireService) { }

  ngOnInit(): void {
    this.isProd = environment.is_live;
    this.route.paramMap.subscribe(data => {
      this.section = data.get("section");
      this.path = data.get("path");
      if (this.section && this.path) {
        this.setup();
      }
    });
    this.authService.getLoggedInAuthState().subscribe(auth => {
      this.user = auth.user;
      this.inidicateEditor(this.user);
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
    this.timerSub?.unsubscribe();
    this.leaveAsEditor();
  }

  private setup() {
    this.heywiki.fetchDoc(this.section, this.path).subscribe(d => {
      this.wikiDoc = d;
      this.markdown = d.markdown;
      if (d.html) {
        this.previewHtml = d.html;
      }
    });

    this.sub?.unsubscribe();
    const compoundKey = this.section + ":" + this.path;
    this.sub = this.fire.observeWikiEditors(compoundKey).subscribe(editors => {
      console.log("editors", editors);
      this.allEditors = editors;
      this.filterEditors();
    });

    this.timerSub?.unsubscribe();
    this.timerSub = interval(60000).subscribe( table => {
      this.filterEditors();
    });
  }

  private filterEditors() {
    const editors = [];
    for (const editor of this.allEditors) {
      const updated = moment(editor.updated.toDate());
      const age = moment().diff(updated, 'minutes');
      console.log("age", age);
      if (age < 30 || editor.email === this.user.email) {
        editor.color = age < 10 ? "chip" : "chip_inactive";
        editors.push(editor);
      }
    }
    this.editors = editors;
  }

  editorWasAlive() {
    this.inidicateEditor(this.user);
    this.filterEditors();
  }

  preview() {
    this.heywiki.previewMarkdown(this.markdown).subscribe(d => {
      this.previewHtml = d.html;
      this.editorWasAlive();
    });
  }

  doSave(publish: boolean) {
    const name = this.user.first_name + " " + this.user.last_name;
    this.heywiki.editDoc(this.section, this.path, this.markdown, name, this.user.email, publish).subscribe(d => {
      this.wikiDoc = d;
      this.markdown = d.markdown;
      if (d.html) {
        this.previewHtml = d.html;
      }
      this.editorWasAlive();
    });
  }

  save() {
    this.doSave(false);
  }

  publish() {
    this.doSave(true);
  }

  changePreviewStyle() {
    this.previewStyle = this.previewStyle === "wiki-doc" ? 'wiki-panel' : 'wiki-doc';
    console.log("changePreviewStyle to " + this.previewStyle);
  }

  private inidicateEditor(user: User) {
    const editor = {
      compound_key: this.section + ":" + this.path,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      section: this.section,
      path: this.path,
      updated: firebase.firestore.Timestamp.now(),
    };
    this.fire.updateWikiEditor(editor).then(r => {
    });
  }

  private leaveAsEditor() {
    if (this.user) {
      const compoundKey = this.section + ":" + this.path;
      this.fire.deleteEditor(this.user.email, compoundKey).then(r => {
        console.log("removed editor for document", r);
      });
    }
  }

}
