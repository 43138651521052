import {AfterViewInit, Component, Inject, NgZone, OnInit} from '@angular/core';
import {HotTableRegisterer} from "@handsontable/angular";
import {Item, PushPage} from "../menu-models/PublishModels";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MenuStateService} from "../menu-utils/menu-state.service";
import {environment} from "../../../../environments/environment";
import Handsontable from "handsontable";
import {CellChange, ChangeSource} from "handsontable/common";

@Component({
  selector: 'app-menu-package-dialog',
  templateUrl: './menu-package-dialog.component.html',
  styleUrls: ['./menu-package-dialog.component.css']
})
export class MenuPackageDialogComponent implements OnInit, AfterViewInit {
  private hotRegisterer = new HotTableRegisterer();
  hotId = "hotPack";
  dataset = [{}, {}, {}, {}, {}, {}];
  attributes: any;
  private pushPages: PushPage[];
  private itemsList: Item[];
  private packdata: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {name: string, venueId: number, packdata: string, itemsList: Item[]},
              public dialogRef: MatDialogRef<MenuPackageDialogComponent>, private state: MenuStateService, private ngZone: NgZone) {
    this.itemsList = data.itemsList;
    this.packdata = data.packdata;
  }

  columns = [];

  hotSettings = {
    licenseKey: environment.handson_key,
    startRows: 15,
    colHeaders: true,
    rowHeaders: true,
    columns: this.getFullColumnList(),
    contextMenu: {
      items: {
        row_above: {},
        row_below: {},
        remove_row: {},
      }
    },
    width: "800px",
    height: "500px",
  };

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout( () => {
      const hotInstance = this.hotRegisterer.getInstance(this.hotId);
      if (hotInstance) {
        this.setupTable(hotInstance, this.packdata);
        hotInstance.selectCell(0, 0);

        const that = this;
        // tslint:disable-next-line:max-line-length
        Handsontable.hooks.add('afterChange', (changes: CellChange[] | null, source: ChangeSource) => {
          that.afterChange(changes, source);
        }, hotInstance);
        Handsontable.hooks.add('afterCreateRow', (index: number, amount: number, source?: ChangeSource) => {
        }, hotInstance);
        // tslint:disable-next-line:max-line-length
        Handsontable.hooks.add('afterRemoveRow', (index: number, amount: number, physicalRows: number[], source?: ChangeSource) => {
        }, hotInstance);
      }
    }, 200 );
  }

  private collectPackageItems(): string[] {
    const hotInstance = this.hotRegisterer.getInstance(this.hotId);
    const rows = hotInstance.countRows();
    const packageItems = [];
    for (let i = 0; i < rows; i++) {
      const item = hotInstance.getDataAtRowProp(i, "item");
      const price = hotInstance.getDataAtRowProp(i, "price");
      const obr = [];
      const showOrder = hotInstance.getDataAtRowProp(i, "showOrder");
      if (showOrder) {
        obr.push("o");
      }
      const showBong = hotInstance.getDataAtRowProp(i, "showBong");
      if (showBong) {
        obr.push("b");
      }
      const showReceipt = hotInstance.getDataAtRowProp(i, "showReceipt");
      if (showReceipt) {
        obr.push("r");
      }
      if (item && price) {
        packageItems.push(`${item},${price},${obr.join("")}`);
      }
    }
    console.log(packageItems);
    return packageItems;
  }

  private collectPackageData() {
    const items = this.collectPackageItems();
    return items.join("|");
  }

  okClicked() {
    const s = this.collectPackageData();
    this.dialogRef.close(s);
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  private setupTable(hotInstance: Handsontable, items: string) {
    console.log(`items: ${items}`);
    const ds = [];
    items.split("|").forEach(pd => {
      const pp = pd.split(",");
      if (pp.length === 3) {
        const d = {item: pp[0].trim(), price: pp[1], showOrder: pp[2].includes("o"), showBong: pp[2].includes("b"), showReceipt: pp[2].includes("r")};
        ds.push(d);
      }
    });
    const k = Math.max(15 - ds.length, 5);
    for (let i = 0; i < k; i++) {
      ds.push({});
    }
    this.dataset = ds;
    hotInstance.loadData(this.dataset);
  }

  private getFullColumnList() {
    const that: MenuPackageDialogComponent = this;
    const cs = [];
    const itemsCol = {data: "item", title: "Artikel", width: 200, type: "dropdown",
      // @ts-ignore
      source(query, process) {
        console.log(`autocomplete ${query}`);
        //process(that.pushPages.map(o => o.id));
        process(that.itemsList.map(o => o.id));
      },
      strict: false
    };
    cs.push(itemsCol);
    cs.push({data: "price", title: "Pris", width: 100, type: "numeric"});
    cs.push({data: "showOrder", title: "Order", width: 100, type: "checkbox"});
    cs.push({data: "showBong", title: "Bong", width: 100, type: "checkbox"});
    cs.push({data: "showReceipt", title: "Kvitto", width: 100, type: "checkbox"});
    return cs;
  }

  private afterChange(changes: CellChange[] | null, source: ChangeSource) {
    // @ts-ignore
    if (source === "observe") {
      return;
    }

    if (changes) {
      console.log("afterChange");
      console.log(changes);
      console.log(source);
    }
  }

}
