export const pageFoodcourtSettings = {
  title: { sv: "Foodcourt" },
  page_settings: {
    root_path: "foodcourt"
  },
  desc: { sv: "Inställningar för foodcourt" },
  properties: [
    { key: "anon_orderable", title: { sv: "Tillåt beställningar utan inlogg" }, desc: { sv: "Förenklar beställning för gäster, kan dock göra det lite svårare att hitta en beställning i t.ex. KDS:en" },
        type: "bool", optional: true
    },
    { key: "table_regex", title: { sv: "Bordsfilter (foodcourt)" }, desc: { sv: "" }, default: "foodcourt", type: "enum", optional: false, hidden: true,
      values: [
        { key: "foodcourt", title: { sv: "Foodcourt" } },
      ]
    },
    { key: "menu_filter_tag", title: { sv: "Artikelfiltreringstag (foodcourt)" }, desc: { sv: "" }, default: "foodcourt", type: "string", optional: false },

    { header: "Gästinformation för Foodcourt" },
    { key: "text.title", title: { sv: "Titel för gäst, t.ex. Foodcourt" }, type: "string", desc: { sv: "" }, optional: true },
    { key: "text.desc", title: { sv: "Beskrivning" }, type: "string", desc: { sv: "" }, optional: true },
  ],
};
