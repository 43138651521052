import {Component, Inject, NgZone, OnInit} from '@angular/core';
import {HotTableRegisterer} from "@handsontable/angular";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MenuStateService} from "../menu-utils/menu-state.service";
import Handsontable from "handsontable";
import CellChange = Handsontable.CellChange;
import ChangeSource = Handsontable.ChangeSource;
import {Option} from "../menu-models/PublishModels";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-menu-attribute-dialog',
  templateUrl: './menu-attribute-dialog.component.html',
  styleUrls: ['./menu-attribute-dialog.component.css']
})
export class MenuAttributeDialogComponent implements OnInit {
  private hotRegisterer = new HotTableRegisterer();
  hotId = "hotAtr";
  dataset = [{}, {}, {}, {}, {}, {}];
  attributes: any;
  private options: Option[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: {attributes: string, name: string, venueId: number},
              public dialogRef: MatDialogRef<MenuAttributeDialogComponent>,
              private state: MenuStateService, private ngZone: NgZone) {
  }

  columns = [
    {data: "relPrice", title: "Price<br><br>(+/-)", width: 50, type: "text" },
    {data: "fixPrice", title: "Price<br><br>(base)", width: 50, type: "numeric" },
  ];

  hotSettings = {
    licenseKey: environment.handson_key,
    startRows: 15,
    colHeaders: true,
    rowHeaders: true,
    columns: this.getFullColumnList(),
    // nestedHeaders: [
    //   ['', {label: 'Price', colspan: 2}],
    //   ['Name', '+/-', 'base'],
    // ],
    contextMenu: {
      items: {
        row_above: {},
        row_below: {},
        remove_row: {},
      }
    },
    width: "440px",
    height: "500px",
  };

  ngOnInit(): void {
    this.state.getOptions(this.data.venueId, this.state.menuId).subscribe( r => {
      this.options = r;
      setTimeout( () => {
        const hotInstance = this.hotRegisterer.getInstance(this.hotId);
        if (hotInstance) {
          console.log(`AttributeDialog data: ${this.data}`);
          const value = this.data.attributes;
          const notEmpty = typeof value !== 'undefined' && value;
          if (notEmpty) {
            this.setupTable(hotInstance, value);
          }
          hotInstance.selectCell(0, 0);

          const that = this;
          // eslint-disable-next-line max-len
          Handsontable.hooks.add('afterChange', (changes: CellChange[] | null, source: ChangeSource) => { that.afterChange(changes, source); }, hotInstance);
          // eslint-disable-next-line max-len
          Handsontable.hooks.add('afterCreateRow', (index: number, amount: number, source?: ChangeSource) => { that.renderAttributes(); }, hotInstance);
          // eslint-disable-next-line max-len
          Handsontable.hooks.add('afterRemoveRow', (index: number, amount: number, physicalRows: number[], source?: ChangeSource) => { that.renderAttributes(); }, hotInstance);
          this.renderAttributes();
        }
      }, 200 );
    });
  }

  private collectAttributeGroups() {
    const hotInstance = this.hotRegisterer.getInstance(this.hotId);
    const rows = hotInstance.countRows();
    const atrGroups = [];
    let atrGroup = null;
    let key = null;
    for (let i = 0; i < rows; i++) {
      const name = hotInstance.getDataAtRowProp(i, "name");
      const relPrice = hotInstance.getDataAtRowProp(i, "relPrice");
      const fixPrice = hotInstance.getDataAtRowProp(i, "fixPrice");
      if (name) {
        if (atrGroup == null) {
          atrGroup = [];
          key = `atr${i}`;
        }
        atrGroup.push({key, name, relPrice, fixPrice});
      } else {
        if (atrGroup) {
          atrGroups.push(atrGroup);
          atrGroup = null;
        }
      }
    }
    if (atrGroup != null) {
      atrGroups.push(atrGroup);
      atrGroup = null;
    }
    console.log(atrGroups);
    return atrGroups;
  }

  private collectAttributeString() {
    const atrGroups = this.collectAttributeGroups();
    const s = atrGroups.map( group => group.map( atr => {
      let a = atr.name;
      if (atr.fixPrice) {
        a += ":" + atr.fixPrice;
      } else if (atr.relPrice) {
        const sign = atr.relPrice.startsWith("+") || atr.relPrice.startsWith("-") ? "" : "+";
        a += ":" + sign + atr.relPrice;
      }
      return a;
    }).join(",") ).join("|");
    return s;
  }

  okClicked() {
    const s = this.collectAttributeString();
    this.dialogRef.close(s);
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  private setupTable(hotInstance: Handsontable, attributes: string) {
    const ds = [];
    attributes.split("|").forEach(g => {
      for (const s of g.split(",")) {
        const vps = s.split(":");
        const d = {name: vps[0], relPrice: undefined, fixPrice: undefined};
        if (vps.length === 2) {
          const p = vps[1];
          if (p.startsWith("+") || p.startsWith("-")) {
            d.relPrice = p;
          } else {
            d.fixPrice = Number(p);
          }
        }
        ds.push(d);
      }
      ds.push({});
    });
    ds.push({}); ds.push({}); ds.push({}); ds.push({}); ds.push({});
    this.dataset = ds;
    hotInstance.loadData(this.dataset);
  }

  private getFullColumnList() {
    const that: MenuAttributeDialogComponent = this;
    const cs = this.columns;

    cs.splice(0, 0, {data: "name", title: "Name", width: 200, type: "autocomplete",
      // @ts-ignore
      source(query, process) {
        console.log(`autocomplete ${query}`);
        process(that.options.map(o => "@" + o.id));
      },
      strict: false
    });
    return cs;
  }

  private afterChange(changes: CellChange[] | null, source: ChangeSource) {
    // @ts-ignore
    if (source === "observe") {
      return;
    }

    if (changes) {
      console.log("afterChange");
      console.log(changes);
      console.log(source);
      this.renderAttributes();
    }
  }

  private renderAttributes() {
    console.log("renderAttributes...");
    const atrGroups = this.collectAttributeGroups();
    const ats = atrGroups.map( group => {
      const options = group.map( atr => {
        let option: Option;
        if (atr.name.startsWith("@")) {
          option = this.findOption(atr.name);
        }
        const name = option ? option.name : atr.name;
        const fp = atr.fixPrice ? atr.fixPrice : option?.fixPrice;
        const rp = atr.relPrice ? atr.relPrice : option?.relPrice;
        const key = atr.key;
        let formatted;
        if (fp) {
          formatted = `${fp} kr`;
        } else if (rp) {
          const sign = rp.startsWith("+") || rp.startsWith("-") ? "" : "+";
          formatted = `${sign}${rp} kr`;
        }
        return {name, key, formatted, desc: option?.desc};
      });
      return {options};
    });

    this.ngZone.run(() => {
      this.attributes = ats;
    });
  }

  private findOption(name: string): Option {
    const s = name.substring(1);
    return this.options?.find(o => o.id === s);
  }

  getTitle(option: any) {
    console.log(option);
    if (option.name == "_forced_") {
      return "Forced";
    }
    return option.name;
  }

  getAttributeOptions(attribute: any) {
    var nonForcedOptions = attribute.options.filter(o => o.name != "_forced_");
    return nonForcedOptions;
  }
}
