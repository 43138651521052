import { Component, OnDestroy, OnInit } from '@angular/core';
import {VenueService} from "../../../services/venue.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Subscription} from "rxjs";
import {Zreport} from "../../../models/zreport";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-xreport',
  templateUrl: './xreport.component.html',
  styleUrls: ['./xreport.component.css']
})
export class XreportComponent implements OnInit, OnDestroy{
  private paramSub: Subscription;
  venueId: string;
  htmlContent: string | SafeHtml;
  showSpinner = true;
  errorMessage: string | null = null;

  constructor(private venueService: VenueService, private route: ActivatedRoute, private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.paramSub = this.route.paramMap.subscribe(data => {
      this.venueId = data.get("venue_id");
      this.refresh();
    });
  }

  ngOnDestroy(): void {
    this.paramSub?.unsubscribe();
  }

  refresh() {
    this.showSpinner = true;
    this.htmlContent = undefined;
    this.venueService.fetchXReportHtml(Number(this.venueId)).then(r => {
      this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(r.html);
    }).catch(err =>{
      this.errorMessage ="Oj, något gick fel!\nVi har uppmärksammat felet och arbetar redan på att lösa det.\nDu behöver inte göra något just nu. Vänligen avvakta en stund, och försök igen lite senare.";
      console.error('Error: ', err);
    }).finally(() => { this.showSpinner = false; });
  }
}
