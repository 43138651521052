<h2 mat-dialog-title class="top-bar"><div style="margin-right: auto;">Attributes {{data.name}}</div><app-wiki-info-link title="Mer om attribut" [section]="'support'"  [path]="'meny-attribut'"></app-wiki-info-link></h2>
<div style="padding: 0px 24px 24px 24px;">
  <div style="display: flex;flex-direction: row;">
    <div>
      <hot-table
        [hotId]="hotId"
        [settings]="hotSettings"
      >
      </hot-table>
    </div>
    <div class="right-hand-container">
      <div style="font-size: 16px;font-weight: bold;">Preview</div>
      <div *ngIf="attributes" style="margin-top: 16px;">
        <div *ngFor="let attribute of attributes; let atri = index; let lastatr = last;">
          <mat-radio-group *ngIf="attribute.options.length>1">
            <div *ngIf="attribute.options[0].name=='_forced_'" class="small-bold">Tvingat val</div>
            <div style="margin-top: 8px;font-size: 16px;" *ngFor="let option of getAttributeOptions(attribute); let opti = index; let lastopt = last;">
              <mat-radio-button [value]="option.key" color="primary">
                <div><span class="atr-name">{{option.name}}</span><span> {{option.formatted}}</span></div>
                <div class="item-opt-desc" *ngIf="option.desc">{{option.desc}}</div>
                <div class="item-contains-sm" *ngIf="option.contains">INNEHÅLLER <span *ngFor="let con of option.contains; let last = last;">{{con.name.toUpperCase()}}{{con.del ? "*" : ""}}{{last ? "" : ", "}}</span></div>
              </mat-radio-button>
            </div>
          </mat-radio-group>
          <mat-checkbox *ngIf="attribute.options.length===1;" class="item-opt-cb-cont" color="primary">
            <div><span class="atr-name">{{attribute.options[0].name}}</span><span style="font-size: 16px;"> {{attribute.options[0].formatted}}</span></div>
            <div class="item-opt-desc" *ngIf="attribute.options[0].desc">{{attribute.options[0].desc}}</div>
            <div class="item-contains-sm" *ngIf="attribute.options[0].contains">INNEHÅLLER <span *ngFor="let con of attribute.options[0].contains; let last = last;">{{con.name.toUpperCase()}}{{con.del ? "*" : ""}}{{last ? "" : ", "}}</span></div>
          </mat-checkbox>
          <div class="item-separator" style="margin-top: 15px"></div>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button (click)="cancelClicked()">Cancel</button>
    <button mat-flat-button (click)="okClicked()" color="primary">OK</button>
  </div>
</div>
