import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Settlement } from 'src/app/models/settlement';
import { SettlementService } from 'src/app/services/settlement.service';
import { SilversmithApiError } from 'src/app/models/http';

@Component({
  selector: 'app-settlements',
  templateUrl: './settlements.component.html',
  styleUrls: ['./settlements.component.css']
})
export class SettlementsComponent implements OnInit {

  displayedColumns = [
    "name",
    "state",
    "date-key",
    "payout",
    "z-diff",
    "client-records",
    "client-records-sales",
    "transaction-cost",
    "profit"
  ];
  dataSource: MatTableDataSource<Settlement>;
  date: Date;

  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  constructor(public settlementService: SettlementService, private snackBar: MatSnackBar) { }

  public getSettlements(): void {
    this.settlementService.getSettlements(this.date).then(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
    });
  }

  public getPO3File(): void {
    const date = this.date;
    this.settlementService.getPO3File(date).then(data => {
      this.settlementService.refreshSettlementState(date).then(r => {
        this.snackBar.open("PO3 file downloaded", "", {duration: 3000});
        console.log(r);
      }).catch(e => {
        const error = e.error as SilversmithApiError;
        this.snackBar.open(`Faild to refresh settlement state! ${error.error_message}`, "close");
        console.log(error);
      });
    }).catch(e => {
      console.log(e);
      const error = e.error;
      this.snackBar.open(`Faild to fetch PO3 file!`, "close");
      console.log(error);
    });
  }


  ngOnInit(): void {
    this.date = new Date();
    this.settlementService.getSettlements(new Date(Date.now())).then(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sort = this.sort;
    });
  }
}
