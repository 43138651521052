import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import utils from 'src/app/common/utils';
import { AuthState } from 'src/app/models/signin';
import { AuthService } from 'src/app/services/auth.service';
import { LoadingService } from 'src/app/services/loading.service';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css']
})
export class SignInComponent implements OnInit, OnDestroy {
  hide = true;
  error: string;
  isDev = true;
  form: UntypedFormGroup = new UntypedFormGroup({});
  signinSub: Subscription;

  constructor(public authService: AuthService, public loadingService: LoadingService, private formBuilder: UntypedFormBuilder) {
  }
  ngOnInit(): void {

    this.form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      email: ['', [Validators.required, Validators.email]]});

    this.authService.currentAuthState().pipe(map(authState => this.handleAuthState(authState)));
  }
  ngOnDestroy(): void {
    this.signinSub?.unsubscribe();
  }

private handleAuthState(state: AuthState) {
  if (state == null) {
    return;
  }
  if (state.result === "invalid") {
    this.form.setErrors({invalid: true});
    this.error = utils.ParseError();
  } else if (state.result === "unlinked") {
    this.form.setErrors({unlinked: true});
    this.error = utils.ParseError("unlinked");
  } else if (state.result === "valid") {
    this.error = "";
  }

}

  login() {
    this.authService.disableSigninPipe();
    this.signinSub = this.authService.loginWithServerActions(this.form.controls.email?.value, this.form.controls.password?.value)
    .pipe(catchError(err => this.handleFirebaseError(err))).subscribe();
  }

  private handleFirebaseError(error: any) {
    this.error = error ? utils.ParseError(error.code) : utils.ParseError();
    return throwError(error);
  }

  logout() {
    this.authService.logout();
  }

}
