<h2 mat-dialog-title>Redigera paket: {{data.name}} </h2>
<div style="padding: 0px 24px 24px 24px;">
  <div>Aktiv kryssruta visar artikelnamn istället för paketnamn på order, bong eller kvitto.</div>
  <div>(Använd helst samma värde på alla artiklar)</div>
  <div style="display: flex;flex-direction: row;">
    <div>
      <hot-table
        [hotId]="hotId"
        [settings]="hotSettings"
      >
      </hot-table>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-flat-button (click)="cancelClicked()">Cancel</button>
    <button mat-flat-button (click)="okClicked()" color="primary">OK</button>
  </div>
</div>
