<div>
  <p>
    <mat-toolbar>
      <span>Filter builder</span>
      <span class="spacer"></span>
<!--      <button mat-icon-button>-->
<!--        <mat-icon (click)="refresh()">refresh</mat-icon>-->
<!--      </button>-->
    </mat-toolbar>
  </p>
</div>

<div class="container">
  <div>
    <mat-form-field class="indialog" appearance="outline">
      <mat-label>Ask AI (fritext)</mat-label>
      <input matInput [(ngModel)]="askAI" (keyup.enter)="sendQuestion()" >
    </mat-form-field>
<!--    <button mat-flat-button color="primary" (click)=sendQuestion()>Submit</button>-->
  </div>
  <div>
    <mat-form-field class="indialog" appearance="outline">
      <mat-label>Filter</mat-label>
      <input matInput [(ngModel)]="filter" (keyup.enter)="runFilter()" >
    </mat-form-field>
    <button mat-flat-button color="primary" (click)=runFilter()>Run</button>
  </div>

  <div>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>name</th>
        <td mat-cell *matCellDef="let element">{{element.name}}</td>
      </ng-container>
      <ng-container matColumnDef="ic">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ic</th>
        <td mat-cell *matCellDef="let element">{{element.ic}}</td>
      </ng-container>
      <ng-container matColumnDef="tags">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>tags</th>
        <td mat-cell *matCellDef="let element">{{element.tags}}</td>
      </ng-container>
      <ng-container matColumnDef="phase">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>phase</th>
        <td mat-cell *matCellDef="let element">{{element.phaseid}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

</div>
