import {FLOORPLAN_SCENE_HEIGHT, FLOORPLAN_SCENE_WIDTH} from "../../../models/floorplan";
import Konva from "konva";
import Stage = Konva.Stage;
import {ElementRef} from "@angular/core";
import * as _ from "lodash";

let fitIsZeroCounter = 0;

export function fitStageIntoParentContainer(container: ElementRef<HTMLDivElement>, stage: Stage) {
  const w = Math.min(container.nativeElement.offsetWidth, FLOORPLAN_SCENE_WIDTH);
  const h = Math.min(container.nativeElement.offsetHeight, FLOORPLAN_SCENE_HEIGHT);
  console.log(`${w}x${h}`);
  if (w == null || w === 0) {
    if (fitIsZeroCounter < 100) {
      console.log("Fit is zero");
      fitIsZeroCounter++;
      setTimeout(() => {fitStageIntoParentContainer(container, stage); }, 100);
      return;
    }
  }

  const s = w > h ? h : w;
  const scale = s / FLOORPLAN_SCENE_WIDTH;
  console.log(`Size = ${s}, scale = ${scale}`);
  let ox = 0;
  if (h < w) {
    ox = (w - h) / (2 * scale);
  }
  stage.offsetX(-ox);
  stage.width(w);
  stage.height(h);
  stage.scale({ x: scale, y: scale });
}

export function splitIntoNameAndAddress(name: string) {
  const parts = name.split(" ");
  const pre = parts.slice(0, -1).join(" ");
  const lastPart = parts[parts.length - 1];
  const post = /^\d+$/.test(lastPart) ? Number(lastPart) : String(lastPart);
  return { name: pre, address: post };
}

export function extractAliasPrefix(name: string, alias: string) {
  const na = splitIntoNameAndAddress(name);
  const index = alias.lastIndexOf(na.address.toString());
  if (index < 0) {
    return alias;
  } else if (index === 0) {
    return null;
  } else {
    return alias.substring(0, index);
  }
}

export function getConstrainedPosition(pos: { x: number; y: number }, shape: Konva.Shape | Konva.Group, layer: Konva.Layer) {
  const groupWidth = shape.getClientRect().width;
  const groupHeight = shape.getClientRect().height;
  const w = Math.min(layer.getStage().width(), FLOORPLAN_SCENE_WIDTH);
  const h = Math.min(layer.getStage().height(), FLOORPLAN_SCENE_HEIGHT);
  const s = Math.min(w, h);
  const scale = s / FLOORPLAN_SCENE_WIDTH;
  let ox = 0;
  if (h < w) {
    ox = (w - h) / (2 * scale);
  }
  const newX = Math.max(0 + ox * scale, Math.min(pos.x, s - groupWidth + ox * scale));
  const newY = Math.max(0, Math.min(pos.y, s - groupHeight));
  return {
    x: newX, y: newY
  };
}
