import { Component, OnInit } from '@angular/core';
import {VenueService} from "../../../services/venue.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NodeUtils} from "../../../utils/utils";
import {ActivatedRoute, Router} from "@angular/router";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {DomSanitizer} from "@angular/platform-browser";
import {detailedDiff, diff} from "deep-object-diff";

@Component({
  selector: 'app-receipt-explorer',
  templateUrl: './receipt-explorer.component.html',
  styleUrls: ['./receipt-explorer.component.css']
})
export class ReceiptExplorerComponent implements OnInit {
  query: string;
  receiptData: any;
  receiptDataRaw: any;
  raw = false;
  showReceiptKey: any;
  oprice: any;
  opriceDiff: any;
  private result: any;
  showCommand = false;
  commands: string;
  copy: any;
  copyDiff: any;
  editCopy: boolean;
  editModel: any = {};
  editData: any;
  runTasks: boolean;
  recalculatePrice = true;

  constructor(private venueService: VenueService, private snackBar: MatSnackBar, private route: ActivatedRoute,
              private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    const data = this.route.snapshot.queryParamMap;
    this.query = data.get("query");
    console.log("ngOnInit", this.query);
    if (this.query) {
      this.search();
    }
  }

  search() {
    this.venueService.exploreReceipt(this.query).then( res => {
      this.receiptData = undefined;
      this.receiptDataRaw = undefined;
      this.oprice = undefined;
      this.opriceDiff = undefined;
      this.showReceiptKey = undefined;
      this.result = res;
      if (res == null) {
        this.snackBar.open(`No receipt found`, "", {duration: 2000});
        return;
      }
      this.update();
      console.log(res);
    });
  }

  private update() {
    if (this.raw) {
      this.receiptDataRaw = this.result;
      this.receiptData = undefined;
    } else {
      const res = NodeUtils.deepcopyWithJSON(this.result);
      NodeUtils.cleanupTree(res);
      this.receiptData = res;
      this.receiptDataRaw = undefined;
    }
  }

  toggleRaw() {
    this.update();
  }

  showReceipt() {
    this.showReceiptKey = this.result.key;
  }

  showHelp() {
    SimpleDialogComponent.openLoomDialog(this.dialog, {title: "Receipt explorer guide", loomVideoId: "30d2649d75a3425ab6eba91fd56d3f0f"});
  }

  newSearch() {
    this.router.navigateByUrl(`receipt-explorer?query=${this.query}`);
    this.search();
  }

  fixPrice() {
    this.doFixPrice(false);
  }

  commitReceiptChanges() {
    if (this.commands) {
      this.doSendCommands(true);
    } else {
      this.doFixPrice(true);
    }
  }

  copyRecipt() {
    this.doCopyRecipt(true, false, this.recalculatePrice, {});
  }

  commitCopyRecipt() {
    this.doCopyRecipt(false, this.runTasks, this.recalculatePrice, this.editData);
  }

  resetEdits() {
    this.editData = null;
    this.doCopyRecipt(true, false, this.recalculatePrice, {});
  }

  submitEdit() {
    if (this.editModel.field == null) { return; }
    if (!this.editData) { this.editData = {}; }

    this.editData[this.editModel.field] = this.editModel.data;
    this.doCopyRecipt(true, false, this.recalculatePrice, this.editData);
  }

  getApplicableEdits() {
    const applicable = {};
    for (const key in this.editData) {
      if (key in this.copy.new_receipt) {
        applicable[key] = this.editData[key];
      }
    }
    return applicable;
  }

  doCopyRecipt(dryRun: boolean, runTasks: boolean, recalculatePrice: boolean, fieldsToEdit: any) {
    fieldsToEdit = fieldsToEdit || {};
    this.venueService.copyReceipt(this.result.key, dryRun, runTasks, recalculatePrice, fieldsToEdit).then(res => {
      console.log(res);
      const d = diff(res.original_receipt, res.new_receipt);
      if (!NodeUtils.isNullOrEmptyObject(d)) {
        this.copyDiff = detailedDiff(res.original_receipt, res.new_receipt);
      }
      NodeUtils.cleanupTree(res);
      this.copy = NodeUtils.deepcopyWithJSON(res);
    });
  }

  doFixPrice(commit: boolean) {
    this.venueService.fixReceipt(this.result.key, commit).then( res => {
      if (commit) {
        this.showCommand = false;
        this.commands = undefined;
        this.search();
      } else {
        this.showUncomittedDiff(res);
      }
    });
  }

  sendCommands() {
    this.doSendCommands(false);
  }

  doSendCommands(commit: boolean) {
    this.venueService.fixReceiptCommands(this.result.key, this.commands, commit).then( res => {
      if (commit) {
        this.search();
      } else {
        this.showUncomittedDiff(res);
      }
    });
  }

  private showUncomittedDiff(res) {
    console.log(res);
    const d = diff(res.before_fix, res.after_fix);
    if (!NodeUtils.isNullOrEmptyObject(d)) {
      this.opriceDiff = detailedDiff(res.before_fix, res.after_fix);
    }
    NodeUtils.cleanupTree(res);
    this.oprice = NodeUtils.deepcopyWithJSON(res);
  }
}
