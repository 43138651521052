<div>
  <p>
    <mat-toolbar>
      <span>Heynow KPI dashboard</span>
      <span class="spacer"></span>
      <!--      <button mat-icon-button aria-label="Example icon-button with share icon">-->
      <!--        <mat-icon (click)="addPerson()">person_add</mat-icon>-->
      <!--      </button>-->
    </mat-toolbar>
  </p>
</div>

<div *ngIf="stats" class="dash-container">
  <div class="d1">

    <div class="open-panel">
      <div class="panel-title">Signade kunder</div>
      <div class="panel-body">
        <div class="prow"><mat-icon>child_care</mat-icon><div class="info"><div>{{stats.venues.new | number}}</div><div>Nya kunder ( {{stats.venues.new_contracts_cent | moneySuff: "cent"}} )</div></div></div>
        <div class="prow"><mat-icon>loop</mat-icon><div class="info"><div>{{stats.venues.active | number}}</div><div>Aktiva kunder</div></div></div>
        <div class="prow"><mat-icon>engineering</mat-icon><div class="info"><div>{{stats.venues.passive | number}}</div><div>Ej ännu onboardade</div></div></div>
      </div>
    </div>

    <div class="open-panel">
      <div class="panel-title">Fasta månadsintäkter aktiva kunder</div>
      <div class="panel-body">
        <div class="prow"><mat-icon>account_balance</mat-icon><div class="info"><div>{{stats.fixed.license_revenue_cent | moneySuff: "cent"}}</div><div>Intäkter Heynow licens</div></div></div>
        <div class="prow"><mat-icon>exposure_plus_1</mat-icon><div class="info"><div>{{stats.fixed.addon_revenue_cent | moneySuff: "cent"}}</div><div>Intäkter från add ons</div></div></div>
        <div class="prow"><mat-icon>credit_score</mat-icon><div class="info"><div>{{stats.fixed.terminal_revenue_cent | moneySuff: "cent"}}</div><div>Inäkter för terminalhyra (antal terminaler: {{stats.fixed.number_of_terminals}})</div></div></div>
        <div class="prow"><mat-icon>functions</mat-icon><div class="info"><div>{{stats.fixed.total_revenue_cent | moneySuff: "cent"}}</div><div>Totalt fasta intäkter</div></div></div>
      </div>
    </div>

    <div class="open-panel">
      <div class="panel-title">Kundernas omsättning (denna månad)</div>
      <div class="panel-body">
        <div class="prow"><mat-icon>credit_score</mat-icon><div class="info"><div>{{stats.sales.transaction_amount_total_terminal | moneySuff:1}}</div><div>Terminal</div></div></div>
        <div class="prow"><mat-icon>shopping_cart</mat-icon><div class="info"><div>{{stats.sales.transaction_amount_online_bambora | moneySuff:1}}</div><div>Online (kort)</div></div></div>
        <div class="prow"><mat-icon>smartphone</mat-icon><div class="info"><div>{{stats.sales.transaction_amount_total_swish | moneySuff:1}}</div><div>Swish</div></div></div>
        <div class="prow"><mat-icon>functions</mat-icon><div class="info"><div>{{stats.sales.transaction_amount_total | moneySuff:1}}</div><div>Total omsättning</div></div></div>
      </div>
    </div>

    <div class="open-panel">
      <div class="panel-title">Cashflow (exkl. extraordinära)</div>
      <div class="panel-body">
        <div class="prow"><mat-icon>business</mat-icon><div class="info"><div>{{stats.costs.total | moneySuff:1}}</div><div>Löpande kostnader</div></div></div>
        <div class="prow"><mat-icon>assignment_return</mat-icon><div class="info"><div>{{stats.kickback.total | moneySuff:1}}</div><div>Estimerad kickback</div></div></div>
        <div class="prow"><mat-icon>monetization_on</mat-icon><div class="info"><div>{{stats.total_revenue_cent | moneySuff:"cent"}}</div><div>Estimerade totala intäkter (fasta + kickback)</div></div></div>
        <div class="prow"><mat-icon>sports_score</mat-icon><div class="info"><div>{{stats.total_profit_cent | moneySuff:"cent"}}</div><div>Estimerade resultat</div></div></div>
      </div>
    </div>

  </div>
</div>
