import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataFormComponent} from "../../data-form/data-form.component";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import {AuthState} from "../../../models/signin";
import {Subscription} from "rxjs";
import {FireService} from "../../../services/fire.service";
import {MenuStateService} from "../menu-utils/menu-state.service";
import {DataFormSchema} from "../../../models/data-form";
import {MenuTab, parseMenuTabSettings} from "../menu-models/MenuChange";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {PublishService} from "../../../services/publish.service";
import * as _ from "lodash";

@Component({
  selector: 'app-menu-tab-settings',
  templateUrl: './menu-tab-settings.component.html',
  styleUrls: ['./menu-tab-settings.component.css']
})
export class MenuTabSettingsComponent implements OnInit, OnDestroy {
  tabName: string;
  tab: MenuTab;
  tabData: any;

  dataFormSchema: DataFormSchema = {
    title: {sv: "Tab settings"},
    desc: {sv: "Ställ in tab"},
    properties: [
      {key: "name", title: {sv: "Namn"}, desc: {sv: "Namn på tabben (tänk på att namnet kan vara kopplat till en INC som också behöver ändras om du byter namn)"}, type: "string", optional: false, visible: false},
      {key: "page_image", title: {sv: "Toppbild"}, desc: {sv: "Denna bild syns längst upp i gästmenyn"}, type: "string", optional: true},
      {key: "show_glas_col", title: {sv: "Visa pris för glas"}, desc: {sv: "Visar en priskolumn där man enkelt kan redigera priset för ett glas, t.ex. bra när man säljer vin på både flaska och glas."}, type: "bool", optional: true},
      {key: "show_deadline_col", title: {sv: "Visa deadline"}, desc: {sv: "Visar en kolumn där man kan sätta deadline/period en artikel är möjlig att beställa. Används vid förbokning."}, type: "bool", optional: true},
      {key: "sort_index", title: {sv: "Sorteringsindex"}, desc: {sv: "Anger sorteringsordning för tabben i navigeringen."}, type: "integer", optional: true, visible: false},
      {key: "show_bong_name_col", title: {sv: "Visa bongnamn"}, desc: {sv: "Anger vilka namn som skall visas på bongar"}, type: "bool", optional: true},
      {key: "show_bong_order_col", title: {sv: "Visa bongordning"}, desc: {sv: "Anger var på bongen namnet skall visas"}, type: "bool", optional: true},
      {key: "show_pos_name_col", title: {sv: "Visa internnamn"}, desc: {sv: "Anger ett internt artikelnamn för kassan"}, type: "bool", optional: true},
      {key: "show_ean_col", title: {sv: "Visa EAN-kolumn"}, desc: {sv: "Visa kolumn där man kan mata in EAN-koder. Visas i 'details'"}, type: "bool", optional: true},
      {key: "show_resource_col", title: {sv: "Visa Resurskolumn"}, desc: {sv: "Visa kolumn för resurstyp, exempelvis Biljardbord. Visas i 'details'"}, type: "bool", optional: true},
      {key: "show_cc_col", title: {sv: "Visa Kostnadsställe"}, desc: {sv: "Visa kolumn för kostnadsställe, visas i 'details'"}, type: "bool", optional: true},
      {key: "show_cogs_col", title: {sv: "Visa inköpspris"}, desc: {sv: "Visar en priskolumn där man ange COGS (cost of goods sold, inköpspris)"}, type: "bool", optional: true},
    ]
  };

  @ViewChild("dataform") dataForm: DataFormComponent;
  private venueId: number;
  private tabId: string;
  private authState: AuthState;
  private tabSub: Subscription;

  constructor(private route: ActivatedRoute, private authService: AuthService, private fire: FireService, private state: MenuStateService,
              private router: Router, private dialog: MatDialog, private publish: PublishService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.tabId = data.get("tab_id");
      console.log(`this.venueId = ${this.venueId}, this.tabId = ${this.tabId}`);
      this.beginObserving();
    });
    // this.authService.currentAuthState().pipe(first()).subscribe(auth => {
    //   this.authState = auth;
    // });
  }

  ngOnDestroy(): void {
    this.tabSub?.unsubscribe();
  }

  private beginObserving() {
    this.tabSub?.unsubscribe();

    if (this.tabId !== "_new_tab_") {
      this.tabSub = this.fire.observeTab(this.venueId, this.state.menuId, this.tabId).subscribe(tab => {
        console.log("OBSERVED TAB", tab);
        if (!tab) { console.log("NO tab data...."); return; }
        this.tab = tab;
        this.tabData = parseMenuTabSettings(tab.settingsJson);
        if (this.isTabPage()) {
          this.tabName = this.tab.id === "food" ? "Mat" : "Dryck";
        } else {
          this.tabName = tab.name;
        }
        this.tabData.name = tab.name;
        this.updateSchema();
        console.log(this.tabData);
      });
    } else {
      // @ts-ignore
      this.tab = {venueId: this.venueId, menuId: null};
      this.tabData = {sort_index: 100};
      this.tabName = "Ny tab";
      this.updateSchema();
    }
  }

  private updateSchema() {
    _.set(this.dataFormSchema, "properties[0].visible", !this.isTabPage());
    _.set(this.dataFormSchema, "properties[1].visible", this.isTabPage());
    _.set(this.dataFormSchema, "properties[4].visible", !this.isTabPage());
  }

  save() {
    console.log("Save...");
    const data = this.dataForm.collectData();
    if (this.tabId === "_new_tab_") {
      if (data?.name == null) {
        console.log("NO NiNJA PRESENT!");
      } else {
        this.createTab(data);
      }
    } else {
      console.log(data);
      if (!this.isTabPage()) {
        this.tab.name = data.name;
      }
      this.tab.settingsJson = JSON.stringify(data);
      this.fire.updateMenuTab(this.tab).then(r => {
        console.log("Saved tab");
        this.dataForm.updateRoot(data);
        this.router.navigateByUrl(`catalog/${this.venueId}/tab/${this.tab.id}`);
      });
    }
  }

  createTab(data) {
    const tabId = data.name.replace(/[^a-z0-9]/gi, '_').toLowerCase();
    console.log(`Create tab ${tabId}`);
    console.log(data);
    this.state.createTab(this.venueId, this.state.menuId, tabId, data.name, JSON.stringify(data)).subscribe( r => {
      console.log(r);
      this.router.navigateByUrl(`catalog/${this.venueId}/tab/${tabId}`);
    });
  }

  public hasChanges(): boolean {
    if (this.tabId === "_new_tab_") { return false; }
    return this.dataForm?.hasChanges() ?? false;
  }

  public isTabPage() {
    return ["food", "drinks"].includes(this.tab?.id);
  }

  deleteTab() {
    const ds = {title: "Radera tab", message: `Vill du radera "${this.tab.name}" och allt dess innehåll?`, cancelButton: "Avbryt", positiveButton: "Radera"};
    SimpleDialogComponent.observeSimpleDialog(this.dialog, ds).subscribe( r => {
      if (r) {
        this.publish.deleteTabAndCells(this.venueId, this.state.menuId, this.tab.id).then(dr => {
          this.router.navigateByUrl(`catalog/${this.venueId}/tab/food`);
        });
      }
    });
  }
}
