import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-print-job-list-activity',
  templateUrl: './print-job-list-activity.component.html',
  styleUrls: ['./print-job-list-activity.component.css']
})
export class PrintJobListActivityComponent implements OnInit {
  venueId: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = data.get("venue_id");
    });
  }

}
