import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { VenueService } from 'src/app/services/venue.service';

@Component({
  selector: 'app-s-reports',
  templateUrl: './s-reports.component.html',
  styleUrls: ['./s-reports.component.css']
})
export class SReportsComponent implements OnInit {
  @Input() venueId: string;
  checkinId: number;
  @Input() set key(checkinId: number) {
    this.checkinId = checkinId;
    this.fetchCheckinReport();
  }
  htmlContent: string | SafeHtml;
  showSpinner = true;

  constructor(private venueService: VenueService, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  fetchCheckinReport() {
    this.showSpinner = true;
    this.htmlContent = undefined;
    this.venueService.getCheckinReport(this.venueId, Number(this.checkinId)).then(r => {
      console.log("checkinReport ", r);
      this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(r.html);
    }).finally(() => { this.showSpinner = false; });
  }
}
