<div>
  <p>
    <mat-toolbar>
      <span>Tab settings > {{tabName}}</span>
      <span class="spacer"></span>
      <button mat-button (click)="deleteTab()" *ngIf="!isTabPage()">
        <mat-icon>delete</mat-icon> Radera
      </button>
      <button mat-button (click)="save()">
        <mat-icon>save</mat-icon> Spara
      </button>
    </mat-toolbar>
  </p>
</div>

<app-data-form #dataform [schema]="dataFormSchema" [root]="tabData" (save)="save()"></app-data-form>

<div style="padding: 16px;" *ngIf="tabData">
</div>
