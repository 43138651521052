import { Injectable } from '@angular/core';
import {AddMenuItemParams, EditableOrder} from "../models/editable-order";
import {BehaviorSubject, Observable, ReplaySubject} from "rxjs";
import {ParamMap} from "@angular/router";
import {MenuItem, MenuStructure} from "../models/menu-structure";
import * as uuid from 'uuid';
import {getMenuActivity, Session, SessionMenuActivity} from "../models/Session";
import {filter} from "rxjs/operators";
import {MatSnackBar} from "@angular/material/snack-bar";
import {GroupedOrderItems, OrderItem} from "../models/order";

@Injectable({
  providedIn: 'root'
})
export class PosStateService {
  private venueId: number;
  private currentEditableOrderSubject: BehaviorSubject<EditableOrder> = new BehaviorSubject(null);
  selectedEditableItemSubject: ReplaySubject<OrderItem> = new ReplaySubject(1);
  private selectedOrderItem: OrderItem;
  private selectedGroupedOrderItems: GroupedOrderItems;

  constructor(private snackBar: MatSnackBar) { }

  private state = {};
  private tableContexts = {};

  setProp(key: string, value: any) {
    this.state[key] = value;
  }

  private getCTX(table: string): TableContext {
    let ctx = this.tableContexts[table];
    if (ctx == null) {
      ctx = new TableContext(table);
      this.tableContexts[table] = ctx;
    }
    return ctx;
  }

  setVenueId(venueId: number) {
    if (venueId !== this.venueId) {
      console.log("Resetting state");
      this.state = {};
      this.tableContexts = {};
    }
    this.venueId = venueId;
  }

  orderAtTable(table: string) {
    const ctx = this.getCTX(table);
    return ctx.order;
  }

  currentOrder(): EditableOrder {
    const table = this.currentTable();
    const order = this.orderAtTable(table);
    return order;
  }

  currentTable(): string {
    const table = this.getProp("table");
    if (table) {
      return table;
    }
    return "Matsal 1";
  }

  getOrderAtTable(table: string, createNew = false) {
    const ctx = this.getCTX(table);
    return ctx.getOrder(createNew);
  }

  addItemCopy(originalOrderItem: OrderItem) {
    const table = this.currentTable();
    const order = this.getOrderAtTable(table);
    if (order && originalOrderItem) {
      order.addMenuItemCopy(originalOrderItem);
      this.currentEditableOrderSubject.next(order);
    }
  }

  getCurrentTableUser() {
    const table = this.currentTable();
    if (table == null) {
      this.snackBar.open("No table selected", "", {duration: 3000});
      return null;
    }
    const user = this.getSelectedUserAtTable(table);
    if (user == null) {
      this.snackBar.open("No guest selected", "", {duration: 3000});
      return null;
    }
    return {table, user};
  }

  addOrEditItem(menuItem: MenuItem, count: number, params?: AddMenuItemParams) {
    const {table, user} = this.getCurrentTableUser();
    const order = this.getOrderAtTable(table, true);
    const oi = order.addMenuItem(menuItem, count, user, params);
    this.currentEditableOrderSubject.next(order);
    return oi;
  }

  removeItem(itemId: string) {
    const table = this.currentTable();
    const ctx = this.getCTX(table);
    ctx.order?.removeItem(itemId);
    this.currentEditableOrderSubject.next(ctx.order);
  }

  setPhaseWait(phase: number, wait: number) {
    if (this.selectedOrderItem == null) {
      console.log("No item selected...");
      return;
    }

    const table = this.currentTable();
    const ctx = this.getCTX(table);
    const items = ctx.order.items.filter(it => it.id === this.selectedOrderItem.id);
    for (const item of items) {
      item.phase = phase;
      item.wait = wait;
      console.log("Setting phase/wait: ", item);
    }
    ctx.notifyChanges();
  }

  getProp(key: string, data?: ParamMap, defaultValue?: any, nullValue?: any): any {
    const prop = data?.get(key);
    // console.log(`PROP: ${key}`, prop);
    if (prop != null && prop !== nullValue) {
      this.setProp(key, prop);
      return prop;
    }
    const v = this.state[key];
    return v == null ? defaultValue : v;
  }

  clearCurrentOrder() {
    const table = this.currentTable();
    const ctx = this.getCTX(table);
    ctx.clearCurrentOrder();
    this.currentEditableOrderSubject.next(ctx.order);
  }

  voidOrder(table: string) {
    console.log("Void order at table", table);
    const ctx = this.getCTX(table);
    ctx.clearCurrentOrder();
    this.currentEditableOrderSubject.next(ctx.order);
  }

  selectUserAtTable(table: string, u: SessionMenuActivity) {
    const ctx = this.getCTX(table);
    ctx.selectUser(u);
  }

  getSelectedUserAtTable(table: string): {id: string, name: string} {
    const ctx = this.getCTX(table);
    return ctx.selectedUser;
  }

  observeSelectedUserAtTable(table: string): Observable<any> {
    const ctx = this.getCTX(table);
    return ctx.selectedUserSubject.pipe(filter( u => u != null));
  }

  selectDefaultUser(table: string, session: Session) {
    const ctx = this.getCTX(table);
    ctx.selectDefaultUser(session);
  }

  createLocalTemporarySession(table: string) {
    console.log("CREATING LOCAL TEMP SESSION...");
    const ctx = this.getCTX(table);
    if (ctx.localUsers.length === 0) {
      ctx.createNewUser();
    }
    const session = {
      id: uuid.v4(),
      table,
      active: true,
      activity_cache: ctx.localUsers,
      temporary: true
    };
    return session;
  }

  setupLocalTemporarySession(table: string, session: Session) {
    const ctx = this.getCTX(table);
    const activity = getMenuActivity(session);
    // const localCopy = NodeUtils.deepcopyWithJSON(activity);
    // ctx.localUsers = localCopy;
    ctx.localUsers = session.activity_cache;
    return session;
  }

  addNewUser(table: string, session: Session) {
    const ctx = this.getCTX(table);
    const user = ctx.createNewUser(session);
    ctx.selectUser(user);
  }

  observeCurrentEditableOrder(): Observable<EditableOrder> {
    return this.currentEditableOrderSubject;
  }

  correctMode(data: ParamMap, mode: string) {
    const cm = data.get("mode");
    return cm === mode;
  }

  editableSelected(orderItem: OrderItem, groupedOrderItems?: GroupedOrderItems) {
    this.selectedOrderItem = orderItem;
    this.selectedGroupedOrderItems = groupedOrderItems;
    this.selectedEditableItemSubject.next(orderItem);
  }
}

export class TableContext {
  table: string;
  order: EditableOrder;
  selectedUser: any;
  selectedUserSubject: BehaviorSubject<any>;
  localUsers: SessionMenuActivity[];

  constructor(table: string) {
    this.table = table;
    this.selectedUserSubject = new BehaviorSubject<any>(null);
    this.localUsers = [];
  }

  selectUser(u: SessionMenuActivity) {
    this.selectedUser = {id: u.user_id ?? u.anon_id, name: u.name};
    this.selectedUserSubject.next(this.selectedUser);
    console.log("Selected user:", this.selectedUser);
  }

  selectDefaultUser(session: Session) {
    const activity = getMenuActivity(session);
    if (this.selectedUser != null) {
      // TODO check that user is in activity
      return;
    }
    if (activity != null && activity.length > 0) {
      this.selectUser(activity[0]);
      return;
    }
    console.warn("Empty session and no user...");
  }

  createNewUser(session?: Session): SessionMenuActivity {
    const newName = this.buildNewName(session);
    if (newName == null) {
      return;
    }
    console.log("Creating new user...", newName);
    const newUser = {
      anon_id: uuid.v4(),
      name: newName.name,
      alias: newName.alias
    } as SessionMenuActivity;
    this.localUsers.push(newUser);
    return newUser;
  }

  private buildNewName(session: Session): { name: string; alias: string } {
    const activity = getMenuActivity(session);
    //const sessionNames = activity?.map( sma => sma.name ) ?? [];
    const localNames = this.localUsers.map( sma => sma.name );
    //const guestNames = [...sessionNames, ...localNames];
    let name;
    let alias;
    for (let i = 0; i < 20; i++) {
      alias = String.fromCharCode(65 + i);
      name = `Gäst ${alias}`;
      if (!localNames.includes(name)) {
        return {name, alias};
      }
    }
    return null;
  }

  getOrder(createNew: boolean) {
    if (createNew && this.order == null) {
      console.log("Newing up EditableOrder:", this.table);
      this.order = new EditableOrder(this.table);
    }
    return this.order;
  }

  clearCurrentOrder() {
    console.log("Clearing order and users on table:", this.table);
    this.order = undefined;
    this.localUsers = [];
    this.selectedUser = null;
  }

  notifyChanges() {
    this.order.notifyItemChanges();
  }
}

