<div>
  <p>
    <mat-toolbar>
      <span>Registreringsenheter</span>
      <span class="spacer"></span>
      <button mat-button (click)="createNew()">1. Skapa nya</button>
      <button mat-button [matMenuTriggerFor]="menuE1">2. Uppdatera valda</button>
      <button mat-button (click)="exportCSV()">3. Exportera valda</button>
      <button mat-button [matMenuTriggerFor]="menuE2">4. Efter export</button>
      <mat-menu #menuE1="matMenu">
        <button mat-menu-item (click)="update('invalid')">Markera valda som INVALID</button>
        <button mat-menu-item (click)="update('new')">Markera valda som NYA</button>
      </mat-menu>
      <mat-menu #menuE2="matMenu">
        <button mat-menu-item (click)="update('registered')">Markera valda som REGISTRERADE</button>
        <button mat-menu-item (click)="update('deregistered')">Markera valda som AVREGISTRERADE</button>
      </mat-menu>
    </mat-toolbar>
  </p>
</div>


<div style="padding: 16px;">
  <div class="filterPanel">
    <mat-radio-group [(ngModel)]="selectedFilter" (ngModelChange)="changeFilter()">
      <mat-radio-button [value]="filter.value" class="rab" *ngFor="let filter of filters" color="primary">Visa {{filter.title}}</mat-radio-button>
    </mat-radio-group>
  </div>

  <table mat-table [dataSource]="dataSource" matSort [trackBy]="trackItem">

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      color="primary"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      color="primary"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="reg_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Namn</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.reg_name}} </td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> State </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.state}} </td>
    </ng-container>

    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Skapad</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.created | date:"short"}} </td>
    </ng-container>

    <ng-container matColumnDef="updated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Uppdaterad</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.updated | date:"short"}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></tr>
  </table>
</div>

