<h2 mat-dialog-title>Nytt meddlande till alla kunder</h2>
<div mat-dialog-content style="position: relative;">
  <div>
    <mat-form-field class="indialog" appearance="outline">
      <mat-label>Titel</mat-label>
      <input matInput [formControl]="title" required>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="indialog" appearance="outline">
      <mat-label>Meddelande</mat-label>
      <textarea [formControl]="message" name="Comments" matInput placeholder="Meddelande..." rows="10"></textarea>
    </mat-form-field>
  </div>
  <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="loadingService.visibility | async"></mat-spinner>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Avbryt</button>
  <button mat-button (click)="sendMessage()">Skicka</button>
</mat-dialog-actions>
