<div>
  <p>
    <mat-toolbar>
      <span>API Tokens</span>
      <span class="spacer"></span>
      <button mat-button (click)="addToken()">
        <mat-icon>add</mat-icon> Ny
      </button>
    </mat-toolbar>
  </p>
</div>
<div style="padding: 16px;">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="token">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Token</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;max-width: 125px;font-size:12px;white-space: normal;word-wrap: break-word;"> {{element.token}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> State </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.state}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row)"></tr>
  </table>
</div>

