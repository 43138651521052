import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LoadingService } from "../../../services/loading.service";
import { AuthService } from "../../../services/auth.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-wiki-page',
  templateUrl: './create-wiki-page.component.html',
  styleUrls: ['./create-wiki-page.component.css']
})
export class CreateWikiPageComponent implements OnInit {
  dialogTitle?: string = "Skapa ny wiki-sida";

  form: FormGroup = new FormGroup({
    title: new FormControl('', [Validators.required]),
    section: new FormControl('', [Validators.required]),
    path: new FormControl('', [Validators.required])
  });
  isCreating = false;

  constructor(@Inject(MAT_DIALOG_DATA) data, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<CreateWikiPageComponent>, private auth: AuthService,
              private snackBar: MatSnackBar) {
    this.dialogTitle = data.dialogTitle ?? this.dialogTitle;
  }

  ngOnInit(): void {
  }

  createNewWikiPage() {
    if (this.isCreating) { return; }

    this.isCreating = true;

    if (this.form.valid) {
      console.log("createNewWikiPage", this.form.value);
      this.dialogRef.close(this.form.value);
      this.copyNavToClipboard();
      this.dialogRef.afterClosed().subscribe(result => {
        return result;
      });
    } else {
      this.isCreating = false;
    }
  }

  getNav(): string {
    const title = this.form.get('title')?.value || '';
    const section = this.form.get('section')?.value || '';
    const path = this.form.get('path')?.value || '';
    return `[${title}](/doc/${section}/${path})`;
  }

  copyNavToClipboard(): void {
    navigator.clipboard.writeText(this.getNav()).then(() => {
      this.snackBar.open('Kopierat!', '', {
        duration: 3000,
      });
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }

  isHeynowAdmin() {
    return AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }

}
