import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {ActivatedRoute} from "@angular/router";
import {FireService} from "../../../services/fire.service";
import {OrderService} from "../../../services/order.service";
import {VenueService} from "../../../services/venue.service";
import {combineLatest, Subscription} from "rxjs";

import {
  CustomPOSMenuPage,
  CustomPOSMenuPageItem,
  findCategoriesWithItem, findMenuItem, findMenuPage,
  itemIsOrderable, MenuCategory,
  MenuHeaderButton, MenuItem,
  MenuPage, MenuPageLink,
  MenuSection,
  MenuStructure, sectionHasAnyOrderableItem
} from "../../../models/menu-structure";
import {VenueConfig} from "../../../models/venue-config";
import {ArrayUtils} from "../../../utils/utils";
import {PosStateService} from "../../../services/pos-state.service";
import {MenuItemDialogComponent} from "../menu-item-dialog/menu-item-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MenuService} from "../../../services/menu.service";
import {collectAttributesList, prepareDefaultAttributeValues} from "../../../models/editable-order";

@Component({
  selector: 'app-ordering-pad',
  templateUrl: './ordering-pad.component.html',
  styleUrls: ['./ordering-pad.component.css']
})
export class OrderingPadComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  private subOrderChanges: Subscription;
  private subParams: Subscription;
  private config: VenueConfig;

  venueId: number;
  menu: MenuStructure;
  headerButtons: MenuHeaderButton[];
  selectedPage: MenuPage;
  private customPages: CustomPOSMenuPage[];
  itemCount = 1;

  constructor(private route: ActivatedRoute, private fire: FireService, private orderService: OrderService,
              private venueService: VenueService, private state: PosStateService, private dialog: MatDialog,
              private menuService: MenuService) { }

  ngOnInit(): void {
    this.subParams = this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.beginObserve();
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.subOrderChanges?.unsubscribe();
    this.subParams?.unsubscribe();
  }

  private beginObserve() {
    this.sub?.unsubscribe();
    this.subOrderChanges?.unsubscribe();

    this.sub = combineLatest([
        this.fire.observeVenueConfig(this.venueId),
        this.fire.observeMenuStructure(this.venueId),
        this.fire.observeCustomMenuPages(this.venueId)
      ]
    ).subscribe(res => {
        const config = res[0];
        const menu = res[1];
        const customPages = res[2];
        this.config = config;
        this.menu = menu;
        this.customPages = customPages;
        console.log("MENU:", menu);
        console.log("Pages:", customPages);
        this.processMenu();
        this.restoreLastSelectedPage();
      }
    );

    this.subOrderChanges = this.state.observeCurrentEditableOrder().subscribe( order => {
      this.updateCount();
    });
  }

  private processMenu() {
    this.buildDefaultHeaderList();
  }

  private buildDefaultHeaderList() {
    this.headerButtons = [];
    if (this.menu.food) {
      this.headerButtons.push( this.buildHeaderButton("Mat", this.menu.food) );
    }
    if (this.menu.drinks) {
      this.headerButtons.push( this.buildHeaderButton("Dryck", this.menu.drinks) );
    }
    if (this.customPages) {
      for (const cp of this.customPages) {
        this.headerButtons.push( this.buildCustomPageHeaderButton(cp) );
      }
    }
  }

  private buildHeaderButton(name: string, sections: MenuSection[]): MenuHeaderButton {
    const pageId = `header:${name}`;
    const objects = sections.filter(s => sectionHasAnyOrderableItem(s)).map( s => this.getPageForSection(s, pageId) );
    const page = new MenuPage(name, ArrayUtils.flatten(objects), pageId);
    const button = new MenuHeaderButton(name, page, "#568dcd");
    // console.log("Build button", button);
    return button;
  }

  private buildCustomPageHeaderButton(cp: CustomPOSMenuPage): MenuHeaderButton {
    const objects = [];
    for (const mpi of cp.items) {
      if (mpi.pageId) {
        const fpo = this.getMenuPage(mpi.pageId);
        if (fpo) {
          const fp = JSON.parse(JSON.stringify(fpo));
          fp.color = "#" + mpi.customColour.substring(3);
          objects.push(fp);
        }
      }
      if (mpi.menuItemId) {
        const mio = findMenuItem(mpi.menuItemId, this.menu);
        if (mio) {
          const mi = JSON.parse(JSON.stringify(mio));
          mi.color = "#" + mpi.customColour.substring(3);
          objects.push(mi);
        }
      }
    }
    const pageId = `custom_page_header:${cp.pageTitle}`;
    const page = new MenuPage(cp.pageTitle, objects, pageId);
    const button = new MenuHeaderButton(cp.pageTitle, page, "#568dcd");
    return button;
  }

  clickHeader(btn: MenuHeaderButton) {
    console.log("Selected header:", btn);
    this.selectMenuPage(btn.page);
    this.updateCount();
  }

  clickObject(btn: any) {
    if (btn instanceof MenuPage) {
      console.log("Selected page:", btn);
      this.selectMenuPage(btn);
      this.updateCount();
    } else if (btn instanceof MenuPageLink){
      console.log("Selected back link:", btn);
      this.selectMenuPage(this.getMenuPage(btn.pageIdLink));
    } else {
      if (btn.pageId) {
        console.log("Selected page in custom-page:", btn.pageId);
        this.selectMenuPage(this.getMenuPage(btn.pageId));
      } else {
        const menuItem = btn as MenuItem;
        console.log("Selected item:", menuItem);
        if (menuItem.pc === "open") {
          MenuItemDialogComponent.openOpenPriceDialog(this.venueId, btn.id, this.dialog);
        } else {
          const attributeValues = prepareDefaultAttributeValues(menuItem);
          const attributes = collectAttributesList(menuItem, attributeValues);
          this.state.addOrEditItem(btn, Number(this.itemCount ?? 1), {attributes});
          this.itemCount = 1;
        }
      }
    }
  }

  optionClickObject(btn: any, ) {
    if (btn instanceof MenuPage) {
    } else if (btn instanceof MenuPageLink){
    } else {
      console.log("Unselected item:", btn);
      MenuItemDialogComponent.openDialog(this.venueId, btn.id, this.dialog);
    }
    return false;
  }

  private getPageForSection(section: MenuSection, parentPageId: string): (MenuPage|MenuItem)[] {
    const categoriesWithItem = findCategoriesWithItem(section) as any[];
    // console.log(`${categoriesWithItem.length} ${section.name}`);
    if (categoriesWithItem.length === 0) {
      return null;
    }
    const pageId = `section:${section.id}`;
    const back = new MenuPageLink(`< ${section.name}`, `link_section:${section.id}`, parentPageId);
    if (section.name === "root") {
      if (categoriesWithItem.length === 1) {
        return categoriesWithItem[0].items.filter( item => itemIsOrderable(item) );
      }
      const categories = categoriesWithItem.map( cat => this.getPageForCategory(cat, parentPageId) );
      return categories;
    } else if (categoriesWithItem.length === 1) {
      const items = categoriesWithItem[0].items.filter( item => itemIsOrderable(item) );
      items.splice( 0, 0, back);
      const page = new MenuPage(section.name, items, pageId);
      return [page];
    } else {
      const categories = categoriesWithItem.map( cat => this.getPageForCategory(cat, pageId) );
      categories.splice( 0, 0, back as any);
      const page = new MenuPage(section.name, categories, pageId);
      return [page];
    }
  }

  private getPageForCategory(category: MenuCategory, parentPageId: string): MenuPage {
    const items = category.items.filter( item => itemIsOrderable(item) );
    const pageId = `category:${category.id}`;
    const back = new MenuPageLink(`< ${category.name}`, `link_section:${category.id}`, parentPageId);
    items.splice( 0, 0, back as any);
    const page = new MenuPage(category.name, items, pageId);
    return page;
  }

  private getMenuPage(pageId: string): MenuPage | undefined {
    for (const btn of this.headerButtons) {
      const mp = findMenuPage(btn.page, pageId);
      if (mp) {
        return mp;
      }
    }
  }

  getButtonStyle(btn: any) {
    if (btn.pageId) {
      return btn.color && { 'background-color': btn.color, 'border-color': btn.color};
    } else {
      return btn.color && { color: btn.color, 'border-color': btn.color};
    }
  }

  private updateCount() {
    const order = this.state.currentOrder();
    const page = this.selectedPage;
    if (order && page) {
      for (const obj of page.objects) {
        obj.count = order.getItemCount(obj.id);
      }
    }
  }

  private restoreLastSelectedPage() {
    const selectedPageId = this.state.getProp("selectedPageId", null);
    if (selectedPageId) {
      const page = this.getMenuPage(selectedPageId);
      if (page && this.selectedPage !== page) {
        this.selectedPage = page;
        this.updateCount();
      }
    }
  }

  private selectMenuPage(page: MenuPage) {
    this.selectedPage = page;
    this.state.setProp("selectedPageId", page.pageId);
  }
}
