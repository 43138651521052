<div class="pad-container">
  <div class="pad-btn" *ngFor="let btn of headerButtons" (click)="clickHeader(btn)" [ngStyle]="{'background-color': btn.color, 'border-color': btn.color}">
    <div class="pad-btn-name">
      {{btn.name}}
    </div>
  </div>
  <div style="position: absolute;bottom: 68px;right:336px;">
    <input type="number" style="width: 80px;font-size: 20px;height: 36px;" [(ngModel)]="itemCount">
  </div>
</div>
<div class="pad-container">
  <div [ngClass]="{'pad-btn':btn.pageId,'pad-item':btn.id}" *ngFor="let btn of selectedPage?.objects" (click)="clickObject(btn)" (contextmenu)="optionClickObject(btn)" [ngStyle]="getButtonStyle(btn)">
    <div class="pad-btn-name">
      {{btn.pos_name != null ? btn.pos_name : btn.name}}
    </div>
    <div class="btn-not">
      {{btn.count}}
    </div>
    <div class="btn-attr" *ngIf="btn.attributes">⬤</div>
  </div>
</div>

