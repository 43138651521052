import {Component, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {VenueService} from "../../../services/venue.service";
import {MatSortable} from "@angular/material/sort";
import {ActivatedRoute, Router} from "@angular/router";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {PosStateService} from "../../../services/pos-state.service";
import {OrderService} from "../../../services/order.service";
import {combineLatest, Subscription} from "rxjs";
import {CalculatedPrice} from "../../../models/order";
import {MoneyUtils, NodeUtils} from "../../../utils/utils";
import Utils from "../../../common/utils";
import {FireService} from "../../../services/fire.service";
import {environment} from "../../../../environments/environment";

interface PaymentButton {
  id: string;
  title: string;
  color?: string;
  data?: any;
}

interface PaymentBill {
  amount: CalculatedPrice;
  buttons: PaymentButton[];
}

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit, OnDestroy {

  table: string;
  venueId: number;
  showPaymentSpinner = false;
  tableButtons: any[];
  activeBill: PaymentBill;
  private orderSub: Subscription;
  private subParams: Subscription;

  constructor(private venueService: VenueService, private route: ActivatedRoute, private router: Router,
              private dialog: MatDialog, private state: PosStateService, private orderService: OrderService,
              private fire: FireService) { }

  ngOnInit(): void {
    this.subParams = this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.table = this.state.getProp("table", data, null, "null");
      this.beginObservingTable();
    });
  }

  ngOnDestroy(): void {
    this.subParams?.unsubscribe();
  }

  private beginObservingTable() {
    this.orderSub?.unsubscribe();

    this.orderSub = combineLatest([
        this.orderService.observeOrderSummary(this.venueId, this.table),
        this.fire.observeVenueConfig(this.venueId),
      ]
    ).subscribe(res => {
      const summary = res[0];
      const config = res[1];
      const isProdCCU = config.cash_register.type === "prod";
      const buttons = [];
      if (!isProdCCU) {
        buttons.push({id: "terminal", title: "Dummy", color: "green", data: {terminal: "dummy"}});
      }
      buttons.push({id: "active-bill-info", title: "Info / Betallänk"});
      const activeBill = {
        amount: summary.price,
        buttons
      };
      this.activeBill = activeBill;
    });

    this.tableButtons = [
      {id: "refresh", title: "Refresh"}, {id: "void", title: "Annullera"}, {id: "unpaid", title: "Smitnota"}, {id: "probe", title: "Analys"},
      {id: "webcode", title: "Webbkod"}, {id: "close", title: "Stäng bord"}
    ];
  }

  refreshTable() {
    this.showPaymentSpinner = true;
    this.venueService.refreshTable(this.venueId, this.table).then(r => {
      console.log(r);
      this.router.navigateByUrl(`venue/${this.venueId}/pos/summary/${this.table}`);
    }).finally( () => this.showPaymentSpinner = false);
  }

  voidTable() {
    const ds = {title: "Void table?", message: "Are you sure you want to void all unpaid orders on this table?", cancelButton: "Cancel", positiveButton: "Void orders"};
    SimpleDialogComponent.observeSimpleDialog(this.dialog, ds).subscribe( r => {
      if (r) {
        this.showPaymentSpinner = true;
        this.venueService.voidTable(this.venueId, this.table).then( s => {
          console.log(s);
          this.router.navigateByUrl(`venue/${this.venueId}/pos/summary/${this.table}`);
        }).finally( () => this.showPaymentSpinner = false);
      }
    });
  }

  smitnota() {
    const ds = {title: "Mark as smitnota?", message: "Are you sure you want to mark this table as 'smitnota', it will send reminders to all unpaid guests",
      showProgress: false, cancelButton: "Cancel", positiveButton: "Mark as smitnota"};
    SimpleDialogComponent.observeSimpleDialog(this.dialog, ds).subscribe( r => {
      if (r) {
        this.showPaymentSpinner = true;
        this.venueService.markUnpaid(this.venueId, this.table).then( s => {
          console.log(s);
          SimpleDialogComponent.openSimpleDialog(this.dialog, {title: "Moved to: " + s.table, message: s.message, cancelButton: "Close"});
        }).catch(e => SimpleDialogComponent.showErr(this.dialog, e)).finally( () => this.showPaymentSpinner = false);
      }
    });
  }

  payWithTerminal(action: PaymentButton) {
    this.showPaymentSpinner = true;
    this.venueService.executeActiveBill(this.venueId, this.table).then( res => {
      console.log(res);
      const cent = MoneyUtils.toCent(this.activeBill.amount.price);
      const ext = Utils.randomString(6);
      const term = {ProtocolVersion: "020700", Result: 0, Status: 515, BaseAmount: cent, TotalAmount: cent, ExtraAmount: 0, ExternalReference: ext};
      return this.venueService.payBill(res.receipt_key, JSON.stringify(term)).then( payres => {
        console.log(payres);
        this.router.navigateByUrl(`venue/${this.venueId}/pos/summary/${this.table}`);
      });
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e)).finally( () => this.showPaymentSpinner = false);
  }

  showLocationCode() {
    this.showPaymentSpinner = true;
    this.venueService.fetchLocationCode(this.venueId, this.table).then( res => {
      let html;
      if (res != null) {
        if (environment.production) {
          const passeUrl = environment.passe_url;
          html = `<div>Webbkod: <a class="web-code-link" href="${passeUrl}${res.code}" target="_blank">${res.code}</a></div>`;
        } else {
          const url = `${environment.collins_url}/${this.venueId}/menu/food`;
          html = `<div>Webbkod: <a class="web-code-link" href="${url}?table=${res.code}" target="_blank">${res.code}</a></div>`;
        }
      } else {
        html = `<div>Bordet saknar tyvärr webkod.</div>`;
      }
      SimpleDialogComponent.openSimpleDialog(this.dialog, {title: this.table, htmlBody: html, cancelButton: "Close"});
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e)).finally( () => this.showPaymentSpinner = false);
  }

  closeSession() {
    this.showPaymentSpinner = true;
    this.venueService.closeSession(this.venueId, this.table).then( res => {
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e)).finally( () => this.showPaymentSpinner = false);
  }

  actionSelected(action: PaymentButton) {
    console.log("Action selected: ", action);
    if (action.id === "void") {
      this.voidTable();
    } else if (action.id === "refresh") {
      this.refreshTable();
    } else if (action.id === "unpaid") {
      this.smitnota();
    } else if (action.id === "probe") {
      this.router.navigateByUrl(`venue/${this.venueId}/probe/${this.table}`);
    } else if (action.id === "webcode") {
      this.showLocationCode();
    } else if (action.id === "active-bill-info") {
      this.router.navigateByUrl(`venue/${this.venueId}/pos/active-bill/${this.table}`);
    } else if (action.id === "close") {
      this.closeSession();
    } else if (action.id === "terminal") {
      this.payWithTerminal(action);
    }
  }

}
