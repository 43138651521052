import {Component, Input, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {VenueService} from "../../../services/venue.service";
import {DatePipe} from "@angular/common";
import {AuthService} from "../../../services/auth.service";
import {MatSort} from "@angular/material/sort";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatTableDataSource} from "@angular/material/table";
import {CoreReceiptResult} from "../../../models/query-receipt";

@Component({
  selector: 'app-core-receipt-list',
  templateUrl: './core-receipt-list.component.html',
  styleUrls: ['./core-receipt-list.component.css']
})
export class CoreReceiptListComponent {
  private paramSub: Subscription;

  constructor(private venueService: VenueService, public datePipe: DatePipe, public auth: AuthService) {
  }

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns = ["paid_date", "serial_number", "kind", "total_amount", "total_order", "total_tip", "total_vat", "table", "method"];

  @Input() venueId: string;
  @Input() accountId: string;

  receiptKey: string;
  dataSource: MatTableDataSource<CoreReceiptResult>;
  selectedRow: string;
  refundId: string;

  ngOnInit(): void {
    this.fetchReceipts();
  }

  ngOnDestroy(): void {
    this.paramSub?.unsubscribe();
  }

  fetchReceipts() {
    this.venueService.fetchAccountReceipts(this.venueId, this.accountId).then(r => {
        console.log("fetchAccountReceipts", r);
        this.dataSource = new MatTableDataSource(r.receipts as CoreReceiptResult[]);
        this.dataSource.sort = this.sort;
      }
    );
  }

  select(row: any) {
    console.log(row);
    this.selectedRow = this.selectedRow === row.key ? null : row.key;
    this.refundId = row.refund_of_id;
    this.receiptKey = row.key;
  }

  isSuperAdmin() {
    return AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }

  refresh() {
    this.fetchReceipts();
  }
}
