<div>
  <p>
    <mat-toolbar>
      <span>Redigera slottar</span>
      <span class="spacer"></span>
      <button mat-button [matMenuTriggerFor]="menuSave">Kalender: {{namespace}}</button>
      <mat-menu #menuSave="matMenu">
        <button mat-menu-item (click)="changeNS(ns.id)" *ngFor="let ns of namespaces">{{ns.name}}</button>
      </mat-menu>
      <button mat-button (click)="save()">
        <mat-icon>save</mat-icon> Spara
      </button>
    </mat-toolbar>
  </p>
</div>

<div *ngIf="errorMessage" class="error-panel" style="margin-bottom: 32px;">
  <pre>{{errorMessage}}</pre>
  <div class="error-close" (click)="closeError()"><mat-icon>close</mat-icon></div>
</div>

<div class="vc-container" *ngIf="slotData">
  <hot-table
    height="auto"
    [hotId]="hotId"
    [settings]="hotSettings">
  </hot-table>
</div>
