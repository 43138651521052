import {Component, OnDestroy, OnInit} from '@angular/core';
import {FireService} from "../../../services/fire.service";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {VenueService} from "../../../services/venue.service";

@Component({
  selector: 'app-config-view',
  templateUrl: './config-view.component.html',
  styleUrls: ['./config-view.component.css']
})
export class ConfigViewComponent implements OnInit, OnDestroy {
  configString: string;
  venueId: number;
  buttons: string[];
  title: string;
  private sub: Subscription;
  private config: any;
  editConfigUrl: string;

  constructor(private route: ActivatedRoute, private fire: FireService, private venueService: VenueService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.sub = this.fire.observeVenueConfig(this.venueId).subscribe(cfg => {
        this.config = cfg;
        this.buttons = Object.keys(cfg).sort();
        const aicfg = cfg.admin_info?.find( i => i.name === "Config");
        if (aicfg) {
          this.editConfigUrl = aicfg.value;
        }
      });
    });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  selected(button: string) {
    const cfg = this.config[button];
    this.title = button;
    this.configString = JSON.stringify(cfg, undefined, 4);
  }

  rawWorkflow() {
    this.title = "Raw workflow";
    this.venueService.rawWorkflow(this.venueId).then(cfg => {
      console.log("raw workflow:", cfg);
      this.configString = JSON.stringify(cfg, undefined, 4);
    });
  }
  
  showFullConfig(): void {
    this.title = 'Full Configuration';
    this.configString = JSON.stringify(this.config, null, 4);
  }
}
