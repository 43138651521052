import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {VenueService} from "../../../services/venue.service";
import {LoadingService} from "../../../services/loading.service";
import {AuthService} from "../../../services/auth.service";
import Utils from "../../../common/utils";

@Component({
  selector: 'app-select-date-dialog',
  templateUrl: './select-date-dialog.component.html',
  styleUrls: ['./select-date-dialog.component.css']
})
export class SelectDateDialogComponent {
  identity: string;
  venueId?: number;
  selectedDate: any;

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<SelectDateDialogComponent>, private auth: AuthService, private dialog: MatDialog) {
    this.identity = data.identity;
    this.venueId = data.venueId;
  }

  ngOnInit(): void {
  }

  update() {
    this.dialogRef.close(Utils.dateToDateKey(this.selectedDate));
  }

  isHeynowAdmin() {
    return AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }

}
