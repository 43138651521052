<div class="report-container">

  <app-title-bar [title]="title"></app-title-bar>

  <div class="search-fields-root">
    <div class="search-fields">
      <mat-form-field appearance="outline">
        <mat-label>Day or start date</mat-label>
        <input matInput [(ngModel)]="searchStartDate" [matDatepicker]="startPicker">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker [touchUi]="isMobile"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>End date</mat-label>
        <input matInput [(ngModel)]="searchEndDate" [matDatepicker]="endPicker">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker [touchUi]="isMobile"></mat-datepicker>
      </mat-form-field>
      <button mat-flat-button class="search-button" (click)="fetch()" color="primary">SÖK</button>
      <button mat-stroked-button class="dropdown-button" [matMenuTriggerFor]="menuHandleSection" *ngIf="true"><mat-icon>expand_more</mat-icon></button>
      <mat-menu #menuHandleSection="matMenu">
        <button mat-menu-item [matMenuTriggerFor]="menuExport">Exportera</button>
      </mat-menu>
      <mat-menu #menuExport="matMenu">
        <button mat-menu-item (click)="exportTable.export()">CSV-format</button>
        <mat-divider></mat-divider>
      </mat-menu>
    </div>
  </div>
  <div>
    <div class="generic-mastdet">
      <div class="generic-master">
        <app-report-table *ngIf="tableData" #reportTable [tableData]="tableData" [venueId]="venueId"></app-report-table>
      </div>
    </div>
  </div>
</div>
