import { Injectable } from '@angular/core';
import {BehaviorSubject, combineLatest, from, Observable, of} from "rxjs";
import {FireService} from "../../../services/fire.service";
import {first, flatMap, map} from "rxjs/operators";
import {MenuCell, MenuTab} from "../menu-models/MenuChange";
import {Item, Option, PushPage} from "../menu-models/PublishModels";

@Injectable({
  providedIn: 'root'
})
export class MenuStateService {

  constructor(private fire: FireService) { }

  menuId = "alacarte";
  currentFilterState = "info";
  currentFilterSubject = new BehaviorSubject<string>("info");
  currentColumnsSubject = new BehaviorSubject<any[]>([]);
  toggleColumnSubject = new BehaviorSubject<any>(null);

  selectNewFilter(columnFilter: string) {
    this.currentFilterState = columnFilter;
    this.currentFilterSubject.next(columnFilter);
  }

  createTab(venueId: number, menuId: string, tabId: string, tabName: string, settingsJson?: string): Observable<any> {
    return this.fire.getTab(venueId, menuId, tabId).pipe(
      flatMap( d => {
        if (!d.exists) {
          return this.createNewTab(venueId, menuId, tabId, tabName, settingsJson);
        } else {
          return of(false);
        }
      })
    );
  }

  private createNewTab(venueId: number, menuId: string, tabId: string, tabName: string, settingsJson?: string) {
    console.log(`Creating new tab (${tabId}): ${tabName}`);
    const menuTab: MenuTab = {
      id: tabId,
      menuId,
      name: tabName,
      rows: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
      venueId,
      columns: ["type", "id", "name", "price", "desc", "adjust", "attributes", "push", "when", "ic", "phase"],
      rowIndexHead: 20
    };
    if (settingsJson) {
      menuTab.settingsJson = settingsJson;
    }
    return from(this.fire.updateMenuTab(menuTab));
  }

  getProductRefs(venueId: number, menuId: string): Observable<string[]> {
    return this.fire.getNamedCells(venueId, menuId, "id").pipe(
      map<MenuCell[], string[]>( cells => cells.map(cell => cell.value) )
    );
  }

  getOptionRefs(venueId: number, menuId: string): Observable<string[]> {
    return this.fire.getNamedCellsForTab(venueId, menuId, "options", "id").pipe(
      map<MenuCell[], string[]>( cells => cells.map(cell => "@" + cell.value) )
    );
  }

  getOptions(venueId: number, menuId: string): Observable<Option[]> {
    return this.fire.getCells(venueId, menuId, "options").pipe(
      map<MenuCell[], Option[]>( cells => this.buildOptionsFromCells(cells) )
    );
  }

  getItems(venueId: number, menuId: string, tab: MenuTab): Observable<Item[]> {
    return this.fire.getCells(venueId, menuId, tab.id).pipe(
      map<MenuCell[], PushPage[]>( cells => this.buildItemList(cells, tab.rows) )
    );
  }

  getPushPages(venueId: number, menuId: string): Observable<PushPage[]> {
    return combineLatest([
      this.fire.observeTab(venueId, menuId, "pushpages"),
      this.fire.getCells(venueId, menuId, "pushpages")]
    ).pipe(
      map<[MenuTab, MenuCell[]], PushPage[]>( res => this.buildPushPages(res[1], res[0].rows) ),
      first()
    );
  }

  private collectRows(cells: MenuCell[], tabRows?: number[]): any[] {
    const rows = {};
    for (const cell of cells) {
      if (!(tabRows?.includes(cell.row) ?? true)) {
        //console.log("Row not included", cell);
        continue;
      }
      let row = rows[cell.row];
      if (!row) {
        row = {};
        rows[cell.row] = row;
      }
      row[cell.col] = cell.value;
    }

    const arr = [];
    for (const key in rows) {
      if (rows.hasOwnProperty(key)) {
        arr.push(rows[key]);
      }
    }
    return arr;
  }

  private buildOptionsFromCells(cells: MenuCell[]): Option[] {
    const arr = this.collectRows(cells);
    console.log(arr);
    return arr;
  }

  private buildPushPages(cells: MenuCell[], tabRows: number[]): PushPage[] {
    const arr = this.collectRows(cells, tabRows);
    console.log(arr);
    return arr;
  }

  private buildItemList(cells: MenuCell[], tabRows: number[]): Item[] {
    const nonValidTypes = ["POS", "OPN", "CAT", "SEC", "OFF", "KG"];
    const arr = this.collectRows(cells, tabRows).filter( itm => !nonValidTypes.includes(itm.type));
    return arr;
  }

  setColumns(columnsData: any[]) {
    this.currentColumnsSubject.next(columnsData);
  }

  toggleColumn(data) {
    this.toggleColumnSubject.next(data);
  }
}
