import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {VenueService} from "../../../services/venue.service";
import {MenuService} from "../../../services/menu.service";
import {MenuItem, MenuItemAttribute, MenuItemOption} from "../../../models/menu-structure";
import {PosStateService} from "../../../services/pos-state.service";
import {ItemAttribute, OrderItem} from "../../../models/order";
import {collectAttributesList, prepareDefaultAttributeValues} from "../../../models/editable-order";

@Component({
  selector: 'app-menu-item-dialog',
  templateUrl: './menu-item-dialog.component.html',
  styleUrls: ['./menu-item-dialog.component.css']
})
export class MenuItemDialogComponent implements OnInit {

  options: {attr: MenuItemAttribute, opt: MenuItemOption}[] = [];
  working = false;
  menuItem: MenuItem;
  comment: string;
  price: string;
  attributeValues = {};
  data: {venueId: number, itemId: string, isOpenProduct: boolean, editItem?: OrderItem};
  itemCount = 1;

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<MenuItemDialogComponent>,
              private venueService: VenueService, private menuService: MenuService, private state: PosStateService) {
    this.data = data;
  }

  public static openDialog(venueId: number, itemId: string, dialog: MatDialog, editItem?: OrderItem): MatDialogRef<MenuItemDialogComponent> {
    const data = {venueId, itemId, isOpenProduct: false, editItem};
    const dialogRef = dialog.open(MenuItemDialogComponent, {
      minWidth: "460px",
      width: "55vw",
      position: {top: "160px"},
      data,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
    });
    return dialogRef;
  }

  public static openOpenPriceDialog(venueId: number, itemId: string, dialog: MatDialog): MatDialogRef<MenuItemDialogComponent> {
    const dialogRef = dialog.open(MenuItemDialogComponent, {
      minWidth: "460px",
      width: "55vw",
      position: {top: "160px"},
      data: {venueId, itemId, isOpenProduct: true},
      autoFocus: true
    });
    dialogRef.afterClosed().subscribe(result => {
    });
    return dialogRef;
  }

  ngOnInit(): void {
    this.beginObserve();
  }

  private beginObserve() {
    this.menuService.observeMenuItem(this.data.venueId, this.data.itemId).subscribe( mi => {
      console.log(mi);
      this.menuItem = mi;
      if (this.data.editItem) {
        this.comment = this.data.editItem.comment;
      }
      this.buildOptionsList();
    });
  }

  addItem() {
    const attributes = collectAttributesList(this.menuItem, this.attributeValues);
    const params = {
      comment: this.comment,
      attributes,
      price: undefined,
      editedOrderItem: undefined
    };

    if (this.data.isOpenProduct) {
      if (this.price == null) {
        this.dialogRef.close();
        return;
      } else {
        params.price = Number(this.price);
      }
    }

    if (this.data.editItem != null) {
      params.editedOrderItem = this.data.editItem;
      console.log("SAVE ITEM PARAMS", params);
    } else {
      console.log("ADDED ITEM PARAMS", params);
    }
    const orderItem = this.state.addOrEditItem(this.menuItem, 1, params);
    this.dialogRef.close();
  }

  saveItem() {
    this.addItem();
  }

  clickOption(o: {attr: MenuItemAttribute, opt: MenuItemOption}) {
    if (o.attr.options.length === 1) {
      if (this.attributeValues[o.attr.id]) {
        this.attributeValues[o.attr.id] = undefined;
      } else {
        this.attributeValues[o.attr.id] = o.opt.key;
      }
    } else {
      this.attributeValues[o.attr.id] = o.opt.key;
    }
  }

  private buildOptionsList() {
    if (this.menuItem.attributes == null) { return; }

    const colors = ["#568dcd", "#eca938", "#85592f"];
    let i = 0;
    for (const attr of this.menuItem.attributes) {
      // @ts-ignore
      attr.color = colors[i % 3];
      i++;
      for (const opt of attr.options) {
        this.options.push({attr, opt});
      }
    }

    this.attributeValues = prepareDefaultAttributeValues(this.menuItem, this.data.editItem);
  }

  getOptionsStyle(o: { attr: MenuItemAttribute; opt: MenuItemOption }) {
    // @ts-ignore
    const c = o.attr.color;
    if (c) {
      const selected = this.attributeValues[o.attr.id] === o.opt.key;
      if (selected) {
        return { 'background-color': c, 'border-color': c, color: '#fff'};
      } else {
        return { 'border-color': c, color: c};
      }
    }
  }

}
