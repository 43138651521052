import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { VenueService } from "../../services/venue.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { Booking, BookingItem, BookingRequest, BookingResponse } from 'src/app/models/booking';
import { HackUtils } from 'src/app/utils/utils';

@Component({
  selector: 'app-booking-list',
  templateUrl: './booking-list.component.html',
  styleUrls: ['./booking-list.component.css']
})
export class BookingListComponent implements OnInit, OnDestroy {
  private paramSub: Subscription;

  displayedColumns = ["pickup_date", "booking_ref", "account_name", "user_name", "amount_cent", "order_items_hint", "phone", "state", "created",];
  dataSource: MatTableDataSource<BookingItem> = new MatTableDataSource<BookingItem>([]);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  venueId: number;
  bookingKey: string;
  filter: string;
  cursor: string;
  cursors: string[];
  count: number;
  pageSize = 50;
  currentPageIndex = 0;
  isMobile: boolean;
  searchStartDate: Date = new Date();
  searchEndDate: Date = new Date(new Date().setDate(new Date().getDate() + 30));
  actionButtons: { title: string, action: (booking: Booking) => void }[] = [
    { title: "Rensa sökning", action: () => this.clearFilters() },
  ];

  constructor(private venueService: VenueService,
    private route: ActivatedRoute, private router: Router,
    public datePipe: DatePipe) {

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource([]);
    this.isMobile = this.isMobile = HackUtils.isMobile() && window.innerWidth <= 768;
    this.cursors = [];
    this.sort.sort(({ id: 'pickup_date', start: 'asc' }) as MatSortable);
    this.paramSub = this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.bookingKey = data.get("booking_id") || "0";
      this.queryBookings(this.currentPageIndex);
      if (this.bookingKey && this.bookingKey !== "0") {
        this.select(this.bookingKey);
      }
    });
  }

  ngOnDestroy(): void {
    this.paramSub?.unsubscribe();
  }

  queryBookings(pageIndex: number) {
    const request = this.buildRequest();
    this.venueService.queryBookings(this.venueId, request).then(r => {
      console.log("query_bookings", r as BookingResponse);
      this.currentPageIndex = pageIndex;
      this.count = r.count;
      if (this.cursors.length === 0 || this.cursors[this.cursors.length - 1] !== r.next_cursor) {
        this.cursors.push(r.next_cursor);
      }
      if (r != null) {
        this.dataSource = new MatTableDataSource(r.bookings as BookingItem[]);
        this.dataSource.sort = this.sort;
      } else {
        this.dataSource = new MatTableDataSource([]);
      }
    });
  }

  pageChanged($event: PageEvent) {
    const pageIndex = $event.pageIndex;
    const canFetch: boolean = Math.abs(this.currentPageIndex - pageIndex) === 1;

    if (this.currentPageIndex > pageIndex && canFetch) {
      this.cursors.pop();
      this.cursors.pop();
    }
    if (this.pageSize !== $event.pageSize) {
      this.pageSize = $event.pageSize;
      this.resetPage();
    } else {
      this.cursor = this.cursors[this.cursors.length - 1];
    }
    if (canFetch) {
      this.queryBookings(pageIndex);
    }
  }

  resetPage() {
    this.cursor = undefined;
    this.cursors = [];
    this.currentPageIndex = 0;
    this.paginator.firstPage();
    this.queryBookings(this.currentPageIndex);
  }

  clearFilters() {
    this.searchStartDate = null;
    this.searchEndDate = null;
  }

  private buildRequest() {
    const request = {} as BookingRequest;
    request.venue_id = this.venueId;
    // request.cursor = this.cursor;

    if (this.searchStartDate != null) {
      request.start_date = this.datePipe.transform(this.searchStartDate, 'yyyy-MM-dd');
    }
    if (this.searchEndDate != null) {
      request.end_date = this.datePipe.transform(this.searchEndDate, 'yyyy-MM-dd');
    }
    if (this.pageSize != null) {
      // request.page_size = this.pageSize;
    }
    return request;
  }

  select(bookingKey: string) {
    this.bookingKey = bookingKey;
    this.router.navigateByUrl(`venue/${this.venueId}/booking-list/${this.bookingKey}`);
  }

}
