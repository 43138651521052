<div class="booking-container">
  <div>
    <p>
      <mat-toolbar>
        <span>Bokningar</span>
        <span class="spacer"></span>
      </mat-toolbar>
    </p>
  </div>

  <div class="search-fields-root">
    <div class="search-fields">
      <mat-form-field appearance="outline">
        <mat-label>Day or start date</mat-label>
        <input matInput [(ngModel)]="searchStartDate" [matDatepicker]="startPicker">
        <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker [touchUi]="isMobile"></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>End date</mat-label>
        <input matInput [(ngModel)]="searchEndDate" [matDatepicker]="endPicker">
        <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
        <mat-datepicker #endPicker [touchUi]="isMobile"></mat-datepicker>
      </mat-form-field>
      <button mat-flat-button class="search-button" (click)="queryBookings(currentPageIndex)"
        color="primary">SEARCH</button>
      <button mat-stroked-button class="dropdown-button" [matMenuTriggerFor]="menuHandleSection"><mat-icon>expand_more</mat-icon></button>
      <mat-menu #menuHandleSection="matMenu">
        <button mat-menu-item *ngFor="let actionButton of actionButtons"
          (click)="actionButton.action()">{{actionButton.title}}</button>
      </mat-menu>

    </div>
  </div>

  <div class="generic-mastdet">
    <div class="generic-master">
      <table mat-table [dataSource]="dataSource" matSort matSortDisableClear="true" class="clicktable table-cells">

        <ng-container matColumnDef="amount_cent">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Summa</th>
          <td mat-cell *matCellDef="let element" style="white-space: nowrap;">{{ element.amount_cent | moneySuff: "cent"
            }}</td>
        </ng-container>

        <ng-container matColumnDef="booking_ref">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Bokningsreferens</th>
          <td mat-cell *matCellDef="let element">{{ element.booking_ref }}</td>
        </ng-container>

        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Skapad</th>
          <td mat-cell *matCellDef="let element" style="white-space: nowrap;">{{ element.created | date:"short" }}</td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefon</th>
          <td mat-cell *matCellDef="let element">{{ element.phone?.name }}</td>
        </ng-container>

        <ng-container matColumnDef="pickup_date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Leveransdatum</th>
          <td mat-cell *matCellDef="let element" style="white-space: nowrap;">{{ element.pickup_date | date:"short" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Orderstatus</th>
          <td mat-cell *matCellDef="let element">{{ element.state }}</td>
        </ng-container>

        <ng-container matColumnDef="user_key">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Användarnyckel</th>
          <td mat-cell *matCellDef="let element">{{ element.user_key }}</td>
        </ng-container>

        <ng-container matColumnDef="user_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Beställare</th>
          <td mat-cell *matCellDef="let element">{{ element.user_name }}</td>
        </ng-container>

        <ng-container matColumnDef="key">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Key</th>
          <td mat-cell *matCellDef="let element">{{ element.key }}</td>
        </ng-container>

        <ng-container matColumnDef="order_items">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Order artiklar</th>
          <td mat-cell *matCellDef="let element">{{ element.order_items }}</td>
        </ng-container>

        <ng-container matColumnDef="order_items_hint">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Orderinfo</th>
          <td mat-cell *matCellDef="let element" style="white-space: nowrap;">
            <div *ngFor="let hint of element.order_items_hint | slice:0:5">{{ hint.name }}</div>
            <div *ngIf="element.order_items_hint.length > 5">...</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="chat_key">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Chat key</th>
          <td mat-cell *matCellDef="let element">{{ element.chat_key }}</td>
        </ng-container>

        <ng-container matColumnDef="account_name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Konto</th>
          <td mat-cell *matCellDef="let element" style="white-space: nowrap;">{{ element.account_name }}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="select(row.key)"
          [ngClass]="{'highlight': bookingKey === row.key}"></tr>
      </table>
      <!-- <mat-paginator *ngIf="dataSource" [length]="count" [pageSize]="50"
                     [pageSizeOptions]="[20, 30, 50, 100, 500, 2000]"
                     (page)="pageChanged($event)"
                     [pageIndex]="currentPageIndex"
                     >
      </mat-paginator> -->
    </div>

    <div *ngIf="bookingKey && bookingKey !== '0'" class="generic-detail">
      <div class="lr-detail-container">
        <app-booking-details></app-booking-details>
      </div>
    </div>
  </div>
</div>
