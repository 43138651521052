import * as moment from 'moment';
import {OrderItem} from "../models/order";
import Konva from "konva";
import Vector2d = Konva.Vector2d;
import * as _ from "lodash";
import {from, Observable} from "rxjs";
import firebase from "firebase/compat/app";

export default class Utils {

  static momentToDateKey(m: moment.Moment): string {
    return m.format("YYYY-MM-DD");
  }

  static dateToDateKey(date: Date): string {
    return moment(date).format("YYYY-MM-DD");
  }

  static dateKeyToMoment(dateKey: string): moment.Moment {
    return moment(dateKey, 'YYYY-MM-DD');
  }

  static fsTimestampToMoment(timestamp: firebase.firestore.Timestamp): moment.Moment {
    return moment(timestamp.toDate());
  }

  static isStringLink(value: any): boolean {
    if (typeof value === "string") {
      return value.startsWith("http");
    }
    return false;
  }

  static isStringNullOrWhitespace(value: any): boolean {
    if (value == null) { return true; }
    if (typeof value === "string") {
      return value.trim().length === 0;
    }
    return false;
  }

  static groupBy(xs, f) {
    return xs.reduce((r, v, i, a, k = f(v)) => ((r[k] || (r[k] = [])).push(v), r), {});
  }

  static formatAppDate(date: moment.Moment): string {
    if (date == null) {
      return "null";
    }

    const dayDiff = moment().diff(date, "day");
    if (dayDiff === 0) {
      return date.format("HH:mm");
    } else if (dayDiff === 1) {
      return "Igår " + date.format("HH:mm");
    } else if (dayDiff === -1) {
      return "Imorgon " + date.format("HH:mm");
    } else {
      return date.format("MM-DD HH:mm");
    }

  }

  static formatCent(cents: number) {
    const v = cents / 100;
    return this.format(v);
  }
  static format(amount: number) {
    return `${amount.toLocaleString("sv-SE", { maximumFractionDigits: 2, minimumFractionDigits: 2 })} kr`;
  }
  static appendActionToObject(o: any, action: string): any {
    o.action = action;
    return o;
  }

  static randomString(length: number): string {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const len = chars.length;
    const arr = [];
    for (let i = 0; i < length; i++ ) {
      arr.push(chars.charAt(Math.floor(Math.random() * len)));
    }
    return arr.join("");
  }

  static ParseError(errorCode?: string): string {

    let message: string;

    switch (errorCode) {
      case "auth/wrong-password":
        message = "Invalid login credentials.";
        break;
      case "auth/network-request-failed":
        message = "Please check your internet connection";
        break;
      case "auth/too-many-requests":
        message =
          "We have detected too many requests from your device. Take a break please!";
        break;
      case "auth/user-disabled":
        message =
          "Your account has been disabled or deleted. Please contact the system administrator.";
        break;
      case "auth/requires-recent-login":
        message = "Please login again and try again!";
        break;
      case "auth/email-already-exists":
        message = "Something went wrong";
        break;
      case "auth/user-not-found.":
        message = "Something went wrong.";
        break;
      case "auth/phone-number-already-exists":
        message = "The phone number is already in use by an existing user.";
        break;
      case "auth/invalid-phone-number":
        message = "The phone number is not a valid phone number!";
        break;
      case "auth/invalid-email  ":
        message = "The email address is not a valid email address!";
        break;
      case "auth/cannot-delete-own-user-account":
        message = "You cannot delete your own user account.";
        break;
      case "unlinked":
        message = "The current account is not activated. Please complete the registration";
        break;
      case "auth/invalid-action-code":
      message = "The reset link is invalid. This can happen if the link is malformed, expired, or has already been used.";
      break;
      default:
        message = "Oops! Something went wrong. Try again later.";
        break;
    }

    return message;
  }

  static snapValue(value: number, snap = 16): number {
    return Math.round(value / snap) * snap;
  }

  static snapVec(vec: Vector2d, snap = 16): Vector2d {
    return {x: Math.round(vec.x / snap) * snap, y: Math.round(vec.y / snap) * snap};
  }

  static clampVec(vec: Vector2d, bounds: Vector2d): Vector2d {
    return {x: _.clamp(vec.x, 0, bounds.x), y: _.clamp(vec.y, 0, bounds.y)};
  }

  static buildNiceId(uglyId: string): string {
    const niceId = uglyId.replace(/[^a-zA-Z0-9-_]/g, '').toLowerCase();
    return niceId;
  }

  static async hashStringSHA256Async(str: string): Promise<string> {
    const encoder = new TextEncoder();
    const data = encoder.encode(str);
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  }

  static hashStringSHA256(str: string): Observable<string> {
    return from(this.hashStringSHA256Async(str));
  }

  static snakeCaseToTitle(str: string): string {
    return Utils.capitalizeFirstLetter(str.replace("_", " "));
  }

  static capitalizeFirstLetter(str) {
    return str[0].toUpperCase() + str.slice(1);
  }

  static getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }

  static zeroPadInt(num, places) {
    const zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  }

  static sanitizeToLowerAlphaNumeric(str: string): string {
    return str.toLowerCase().replace(/\W/g, '');
  }

}
