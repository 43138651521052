export interface MenuObject {
  name: string;
}

export class MenuPage implements MenuObject {
  constructor(public name: string, public objects: any[], public pageId: string) {
  }

  color: string;
}

export class MenuHeaderButton implements MenuObject {
  constructor(public name: string, public page: MenuPage, public color: string) {
  }
}

export class MenuPageLink implements MenuObject {
  constructor(public name: string, public pageId: string, public pageIdLink: string) {
  }
}

export interface MenuStructure {
  pages: MenuStructurePage[];
  food: MenuSection[];
  drinks: MenuSection[];
}

export interface MenuStructurePage {
  id: string;
  name: string;
  pic: string;
  sections: MenuSection[];
  index: number;
}

export interface MenuSection {
  id: string;
  name: string;
  desc: string;
  pic: string;
  categories: MenuCategory[];
  index: number;

  mhide?: boolean;
}

export interface MenuCategory {
  id: string;
  type: number;
  name: string;
  desc: string;
  pic: string;
  class: string;
  items: MenuItem[];
  index: number;
  phase: number;

  mhide?: boolean;
  whide?: boolean;
}

export interface MenuItem {
  id: string;
  name: string;
  price: string;
  desc: string;
  formatted: string;
  class: string;
  ic: string;
  alc: boolean;
  pic: string;
  vat: number;
  phase: number;
  wait: number;
  use_scale: boolean;
  tare: number;
  pc: string;
  hidden: boolean;
  when: WhenFilter[];
  pushes: MenuItemPush[];
  form: "f"|"d";

  attributes: MenuItemAttribute[];
  contains: MenuItemContain[];
  adjusts: MenuItemAdjust[];
  tags: string[];
  mhide?: boolean;
  whide?: boolean;
  color: string;
  count: number;
  bong_name: string;
  pos_name: string;
}

export interface MenuItemAttribute {
  id: string;
  options: MenuItemOption[];
  hidden: boolean;
}

export interface MenuItemOption {
  price: number;
  price_diff: string;
  key: string;
  formatted: string;
  value: string;
  desc: string;
  default: boolean;
  contains: MenuItemContain[];
}

export interface MenuItemContain {
  del: boolean;
  id: string;
  name: string;
  info: string;
}

export interface MenuItemAdjust {
  id: string;
  name: string;
}

export interface WhenFilter {
  // [1,2,3], bt30: "11:00", et30: "13:00", bd: "20180307", ed: "20180407"
  bt30: string;
  et30: string;
  wds: number[];
  bd: string;
  ed: string;
}

export interface MenuItemPush {
  index: number;
  title: string;
  groups: MenuItemPushGroup[];
}

export interface MenuItemPushGroup {
  title: string;
  items: string[];

  // Resolved in run time
  menuItems: MenuItem[];
}

export interface MenuAddendum {
  items: MenuAddendumItem[];
}

export interface MenuAddendumItem {
  id: string;
  name: string;
  inventory_count: number;
  is_inventoried: boolean;
  out_of_stock: boolean;
  updated: string;
}

export interface CustomPOSMenuPage {
  menuPageData: string;
  pageTitle: string;
  venueId: number;

  items: CustomPOSMenuPageItem[];
}

export interface CustomPOSMenuPageItem {
  menuItemId: string;
  pageId: string;
  customColour: string;
}

export function sectionHasAnyOrderableItem(section: MenuSection): boolean {
  return section.categories.find(c => categoryHasAnyOrderableItem(c)) != null;
}

export function categoryHasAnyOrderableItem(category: MenuCategory): boolean {
  return category.items.find(i => itemIsOrderable(i)) != null;
}

export function itemIsOrderable(item: MenuItem): boolean {
  const b = item.price != null && item.name != null;
  // console.log(`itemIsOrderable: ${item.name} = ${b}`);
  return b;
}

export function findCategoriesWithItem(section: MenuSection): MenuCategory[] {
  return section.categories.filter(c => categoryHasAnyOrderableItem(c) );
}

export function findMenuPage(page: MenuPage, pageId: string): MenuPage {
  if (page.pageId === pageId) {
    return page;
  }

  for (const pageObjects of page.objects?.filter(obj => obj instanceof MenuPage )) {
    const mp = findMenuPage(pageObjects, pageId);
    if (mp) {
      return mp;
    }
  }
}

function findMenuItemInPage(itemId: string, sections: MenuSection[]): MenuItem {
  for (const section of sections) {
    for (const cat of section.categories) {
      const item = cat.items.find( c => c.id === itemId );
      if (item) {
        return item;
      }
    }
  }
}

export function findMenuItem(itemId: string, menuStructure: MenuStructure): MenuItem {
  let item;
  if (menuStructure.food) {
    item = findMenuItemInPage(itemId, menuStructure.food);
  }
  if (item == null && menuStructure.drinks) {
    item = findMenuItemInPage(itemId, menuStructure.drinks);
  }
  return item;
}
