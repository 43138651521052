<div>
  <p>
    <mat-toolbar>
      <span>Lojalitetskort</span>
      <span class="spacer"></span>
      <!-- <app-wiki-info-link title="Lär dig mer." section="support" path="loyalty"></app-wiki-info-link> -->
      <button mat-button (click)="gotoImport()">
        <mat-icon>upload</mat-icon> Import
      </button>
      <button mat-button (click)="addCard()">
        <mat-icon>note_add</mat-icon> Nytt kort
      </button>
    </mat-toolbar>
  </p>
</div>
<div style="padding: 16px;">
  <table mat-table [dataSource]="dataSource" matSort class="clicktable">

    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Skapad </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px; white-space: nowrap;"> {{element.created | date:"yyyy-MM-dd"}} </td>
    </ng-container>

    <ng-container matColumnDef="identity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Kortnummer </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px; max-width: 125px;font-size:12px;white-space: normal;word-wrap: break-word;"> {{element.identity}} </td>
    </ng-container>

    <ng-container matColumnDef="program_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Lojalitetsprogram </th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.program_name}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>E-post</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="first_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Förnamn</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.first_name}} </td>
    </ng-container>

    <ng-container matColumnDef="last_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Efternamn</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.last_name}} </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefon</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.phone}} </td>
    </ng-container>

    <ng-container matColumnDef="birthday">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Födelsedag</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px; white-space: nowrap;"> {{element.birthday | date:"yyyy-MM-dd"}} </td>
    </ng-container>

    <ng-container matColumnDef="valid_to">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Giltig till</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px; white-space: nowrap;"> {{element.valid_to | date:"yyyy-MM-dd"}} </td>
    </ng-container>

    <ng-container matColumnDef="updated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Uppdaterad</th>
      <td mat-cell *matCellDef="let element" style="padding: 4px; white-space: nowrap;"> {{element.updated | date:"yyyy-MM-dd"}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef style="width:50px"></th>
      <td mat-cell *matCellDef="let element">
        <button mat-button [matMenuTriggerFor]="navigation">Åtgärd <mat-icon>arrow_drop_down</mat-icon></button>
        <mat-menu #navigation>
          <button mat-menu-item (click)="update(element)">Redigera</button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
