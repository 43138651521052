import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, ReplaySubject, Subscription} from "rxjs";
import {FireService} from "./fire.service";
import {getMenuActivity, Session, SessionMenuActivity} from "../models/Session";
import {Order} from "../models/order";
import * as _ from "lodash";
import {HarvestedTableOrderData, TableInfo} from "../models/table-info";
import {OrderService} from "./order.service";
import * as moment from 'moment';
import {delay} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TableService {

  tableInfos: Map<string, TableInfo>;
  tableData: Map<string, TableInfo>;
  tableInfoSubject: ReplaySubject<TableInfo> = new ReplaySubject(1);
  timerSubject: BehaviorSubject<string>;

  private sessions: Map<string, Session>;
  private orders: Map<string, Order[]>;
  private venueId: number;
  private timerSub: Subscription;

  constructor(private fire: FireService, private orderService: OrderService) {
  }

  public init(venueId: number): Subscription {
    if (this.venueId !== venueId) {
      console.log("Initializing Table service for venue:", venueId);
      this.sessions = new Map();
      this.orders = new Map();
      this.tableInfos = new Map();
      this.venueId = venueId;
    }
    this.setupTimerPipe();

    return combineLatest([
        this.fire.observeActiveSessions(venueId),
        this.fire.observeOrders(venueId),
      ]
    ).subscribe(res => {
        const sessions = res[0];
        const orders = res[1];

        // Get data from updated orders
        const updatedTablesSet = this.addAndUpdateOrders(orders);
        for (const table of updatedTablesSet.values()) {
          const td = this.harvestTableOrderData(table);
          this.updateTableInfoWithHarvestData(td);
        }

        // Extract data from updated sessions
        const untouched = new Set<string>();
        for (const s of this.sessions.keys()) { untouched.add(s); }
        for (const session of sessions) {
          untouched.delete(session.table);
          if (this.updateSession(session)) {
            updatedTablesSet.add(session.table);
            this.updateTableInfoWithSessionData(session);
          }
        }
        // Session removed?
        for (const ut of untouched.values()) {
          console.log("Session removed:", ut);
          this.sessions.delete(ut);
          updatedTablesSet.add(ut);
          this.updateTableInfoMissingSessionData(ut);
        }

        // Emit changes
        for (const table of updatedTablesSet.values()) {
          const ti = this.tableInfos.get(table);
          this.tableInfoSubject.next(ti);
        }
      }
    );

  }

  private updateSession(session: Session): boolean {
    if (!this.isPhysicalTable(session.table)) {
      return false;
    }
    const current = this.sessions.get(session.table);
    if (current?.updated?.isEqual(session.updated)) {
      // Same
      return false;
    }

    this.sessions.set(session.table, session);
    return true;
  }

  private isPhysicalTable(table: string) {
    if (table == null) {
      return false;
    }
    if (table.startsWith("SNOTA")) {
      return false;
    }
    const re = new RegExp('P[0-9][0-9]:[0-9][0-9]');
    if (re.test(table)) {
      return false;
    }
    return true;
  }

  private updateOrder(order: Order): boolean {
    if (!this.isPhysicalTable(order.table)) {
      return false;
    }

    // if (!order.table.startsWith("Matsal 18")) {
    //   return false;
    // }

    const currentOrders = this.orders.get(order.table);
    if (currentOrders) {
      const sameId = currentOrders.find(o => o.id === order.id);
      if (sameId?.updated?.isEqual(order.updated)) {
        return false;
      }
    }

    order.items = JSON.parse(order.items_json);
    for (const item of order.items) {
      this.orderService.prepItem(item, order);
    }
    if (currentOrders) {
      const index = _.findIndex(currentOrders, ord => ord.id === order.id);
      if (index >= 0) {
        currentOrders.splice(index, 1, order);
      } else {
        currentOrders.push(order);
      }
    } else {
      this.orders.set(order.table, [order]);
    }

    return true;
  }

  private harvestTableOrderData(table: string): HarvestedTableOrderData {
    const harv = new HarvestedTableOrderData(table);
    const tableOrders = this.orders.get(table);
    const filteredOrders = tableOrders.filter(ord => !ord.canceled && !ord.paid );
    // console.log("*************************************************");
    // console.log(filteredOrders);
    // console.log("*************************************************");
    if (filteredOrders.length > 0) {
      harv.price = this.orderService.calculatePrice(filteredOrders);
      harv.lastFinalizedDate = this.findLatestDate(filteredOrders);
      // const summary = this.orderService.summarizeOrdersByTimeAndPhase(filteredOrders, table, null, false);
      // ti.summary = summary;
    } else {
      harv.price = undefined;
      harv.lastFinalizedDate = null;
    }
    return harv;
  }

  private addAndUpdateOrders(orders: Order[]): Set<string> {
    const updateTables = new Set<string>();
    for (const order of orders) {
      if (this.updateOrder(order)) {
        updateTables.add(order.table);
      }
    }
    return updateTables;
  }

  private updateTableInfoWithHarvestData(harv: HarvestedTableOrderData) {
    let ti = this.tableInfos.get(harv.name);
    if (ti == null) {
      ti = new TableInfo(harv.name);
      this.tableInfos.set(harv.name, ti);
    }
    ti.price = harv.price;
    ti.lastFinalizedDate = harv.lastFinalizedDate;
  }

  private updateTableInfoWithSessionData(session: Session) {
    let ti = this.tableInfos.get(session.table);
    if (ti == null) {
      ti = new TableInfo(session.table);
      this.tableInfos.set(session.table, ti);
    }
    ti.active = session.active;
    const activities = getMenuActivity(session);
    ti.lastActivityDate = this.findLatestActivityDate(activities);
  }

  private updateTableInfoMissingSessionData(table: string) {
    const ti = this.tableInfos.get(table);
    if (ti) {
      ti.active = false;
    }
  }

  private findLatestDate(orders: Order[]) {
    let newest: moment.Moment;
    for (const order of orders) {
      const m = moment(order.finalized.toDate());
      if (newest == null || newest < m) {
        newest = m;
      }
    }
    return newest;
  }

  private findLatestActivityDate(activities: SessionMenuActivity[]): moment.Moment {
    let newest: moment.Moment;
    for (const activity of activities) {
      if (activity.action === "anon") { continue; }
      const m = moment(activity.updated + "Z");
      activity.updatedMoment = m;
      if (newest == null || newest < m) {
        newest = m;
      }
    }
    return newest;
  }

  updateTableWithTimer(name: string) {
    console.log("Added new table to timer pipe", name);
    this.timerSubject.next(name);
  }

  private setupTimerPipe() {
    this.timerSub?.unsubscribe();
    this.timerSubject = new BehaviorSubject("");
    console.log("Set up new timer pipe");

    this.timerSub = this.timerSubject.pipe(delay(20000)).subscribe( table => {
      if (table !== "") {
        console.log("Timer pipe got new table", table);
        const ti = this.tableInfos.get(table);
        this.tableInfoSubject.next(ti);
      }
    });
  }
}
