<mat-toolbar color="primary" class="mobile-toolbar">
  <button mat-icon-button (click)="toggle()">
    <mat-icon>menu</mat-icon>
  </button>
  <span *ngIf="config">{{config?.venue.name}}: Catalog</span>


  <div class="misc-controls">
    <mat-button-toggle-group name="fontStyle" style="font-size: 14px;" (change)="onFilterChange()" [(ngModel)]="columnFilter">
      <mat-button-toggle *ngFor="let btn of filterButtons" [value]="btn.value">{{btn.name}}</mat-button-toggle>
    </mat-button-toggle-group>
  </div>
</mat-toolbar>
