import {Component, OnDestroy, OnInit} from '@angular/core';
import {IdmInfo, IdmResponse} from "../../../models/genji-models";
import {GenjiService} from "../../../services/genji.service";
import {ActivatedRoute} from "@angular/router";
import {FireService} from "../../../services/fire.service";
import {Subscription} from "rxjs";
import {VenueConfig} from "../../../models/venue-config";

@Component({
  selector: 'app-venue-ccu',
  templateUrl: './venue-ccu.component.html',
  styleUrls: ['./venue-ccu.component.css']
})
export class VenueCcuComponent implements OnInit, OnDestroy {
  loading = false;
  idmStatus: IdmResponse;
  private venueId: number;
  private sub: Subscription;
  private config: VenueConfig;

  constructor(private route: ActivatedRoute, private genji: GenjiService, private fire: FireService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      // Customer opens page
      this.venueId = Number(data.get("venue_id"));
      this.sub?.unsubscribe();
      this.sub = this.fire.observeVenueConfig(this.venueId).subscribe(c => {
        this.config = c;
        this.refresh();
      });
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  refresh() {
    this.loading = true;
    const registerId = this.config.cash_register.ccu;
    this.genji.ccuStatus(registerId).subscribe(r => {
      console.log(r);
      this.idmStatus = r;
      this.loading = false;
    });
  }

  open() {
    this.loading = true;
    const registerId = this.config.cash_register.ccu;
    this.genji.ccuOpen(registerId).subscribe(r => {
      console.log(r);
      this.idmStatus = r;
      this.loading = false;
    });
  }

  close() {
    this.loading = true;
    const registerId = this.config.cash_register.ccu;
    this.genji.ccuClose(registerId).subscribe(r => {
      console.log(r);
      this.idmStatus = r;
      this.loading = false;
    });
  }

  registerNewCCU() {
    const info = this.buildRequest();
    this.genji.ccuNew(info).subscribe(r => {
      console.log(r);
      this.idmStatus = r;
    });
  }

  changeCCU() {
    const registerId = this.config.cash_register.ccu;
    const info = this.buildRequest(registerId);
    this.genji.ccuChange(info).subscribe(r => {
      console.log(r);
      this.idmStatus = r;
    });
  }

  private buildRequest(registerId?: string) {
    const bus_name = this.config.cash_register.bus_name;
    let org_number = this.config.cash_register.org_number;
    org_number = org_number.replace("-", "");
    const street = this.config.cash_register.street;
    const postal_code = this.config.cash_register.postal_code;
    const city = this.config.cash_register.city;
    //const ccu = this.config.cash_register.ccu;
    let storeId = this.config.cash_register.ccu_store_id;
    if (storeId == null) {
      storeId = "1";
    }
    const OperationLocationAddress = "Mässans gata 10";
    const OperationLocationCity = "Göteborg";
    const OperationLocationCompany = "Heynow AB";
    const OperationLocationZipcode = "41251";

    const info: IdmInfo = {
      PartnerAuthority: {
        PartnerName: "Caprico Coding AB",
        PartnerCode: "CA",
        POSAuthorityCode: "TO"
      },
      OrganizationChain: {
        ChainName: bus_name,
        ChainCode: org_number
      },
      StoreInfo: {
        StoreID: storeId,
        StoreName: bus_name,
        Email: undefined,
        Cellphone: undefined,
        Address: street,
        Zipcode: postal_code,
        City: city
      },
      CompanyInfo: {
        OrganizationNumber: org_number,
        Company: bus_name,
        Address: street,
        Zipcode: postal_code,
        City: city
      },
      RegisterInfo: {
        RegisterID: registerId,
        RegisterMake: "CATO Kassaregister",
        RegisterModel: "CATO Kassaregister SE v3.0",
        LocalAlias: "",
        CounterNumber: "1",
        Address: street,
        Zipcode: postal_code,
        City: city
      },
      JournalLocation: {
        Company: bus_name,
        Address: street,
        Zipcode: postal_code,
        City: city
      },
      OperationLocation: {
        Company: OperationLocationCompany,
        Address: OperationLocationAddress,
        Zipcode: OperationLocationZipcode,
        City: OperationLocationCity
      },
    };
    return info;
  }
}
