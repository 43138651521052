<mat-expansion-panel [expanded]="true" [disabled]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      NEW SETTLEMENTS
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div class="container">
   
      <mat-form-field>
      <mat-label>Choose a date</mat-label>
      
      <input matInput [(ngModel)]="date" [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker ></mat-datepicker>
      </mat-form-field>
      <button mat-flat-button color="primary" (click)=fetchSettlementData()>Fetch data</button>
      
      <table mat-table [dataSource]="dataSource" matSort>
        
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name  </th>
          <td mat-cell *matCellDef="let element"> {{element.venue}} </td>
        </ng-container>

        <ng-container matColumnDef="z-report-sales">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Z-report-sales (SEK)</th>
          <td mat-cell *matCellDef="let element"> {{element.z_report_sales}} </td>
        </ng-container>

        <ng-container matColumnDef="client-record-sales">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Client record sales (SEK)</th>
          <td mat-cell *matCellDef="let element"> {{element.client_record_sales}} </td>
        </ng-container>
        
        <ng-container matColumnDef="z-report-receipts">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Z-report receipts #</th>
          <td mat-cell *matCellDef="let element"> {{element.z_report_receipt_count}} </td>
        </ng-container>
        
        <ng-container matColumnDef="client-records">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Client records #</th>
          <td mat-cell *matCellDef="let element"> {{element.client_record_count}} </td>
        </ng-container>

        <ng-container matColumnDef="transaction-cost">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction cost (SEK)</th>
          <td mat-cell *matCellDef="let element"> {{element.transaction_cost}} </td>
        </ng-container>

        <ng-container matColumnDef="payout">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Payout (SEK)</th>
          <td mat-cell *matCellDef="let element"> {{element.payout}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <button mat-flat-button color="primary" class="settle-button" (click)="createSettlements()">Create settlements</button>
    </div>

</mat-expansion-panel>