import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {getPagePaths, getSettingsPage} from "../pages/setting-pages";
import {SettingHistory, SettingsPage} from "../../../models/settings-page";
import {MatTableDataSource} from "@angular/material/table";
import {Admin} from "../../../models/user";
import {MatSort, MatSortable} from "@angular/material/sort";
import {VenueService} from "../../../services/venue.service";

@Component({
  selector: 'app-settings-history-list',
  templateUrl: './settings-history-list.component.html',
  styleUrls: ['./settings-history-list.component.css']
})
export class SettingsHistoryListComponent implements OnInit {
  venueId: string;
  page: string;
  sc: SettingsPage;

  displayedColumns = ["user_name", "date", "desc"];
  dataSource: MatTableDataSource<SettingHistory>;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  private paths: { anchorPath: string; rootPath: string };
  backLink: string;

  constructor(private route: ActivatedRoute, private venueService: VenueService, private router: Router) { }

  ngOnInit(): void {
    this.sort.sort(({ id: 'date', start: 'desc'}) as MatSortable);

    this.route.paramMap.subscribe(data => {
      this.venueId = data.get("venue_id");
      this.page = data.get("page");
      this.sc = getSettingsPage(this.page);
      this.paths = getPagePaths(this.sc.page_settings);
      if (this.page === "workflow") {
        this.backLink = `/venue/${this.venueId}/workflow-editor`;
      } else {
        this.backLink = `/venue/${this.venueId}/settings/${this.page}`;
      }

      if (this.sc == null) {
        console.log(`Unknown settings page: ${this.page}`);
        return;
      }
      this.fetchHistory();
    });
  }

  private fetchHistory() {
    this.venueService.fetchSettingsHistory(Number(this.venueId), this.paths.rootPath).then(r => {
      this.dataSource = new MatTableDataSource(r as SettingHistory[]);
      this.dataSource.sort = this.sort;
    });
  }

  select(row: any) {
    this.router.navigateByUrl(`venue/${this.venueId}/settings/${this.page}?version=${row.key}`);
  }
}
