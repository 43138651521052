export const pagePersonalkollen = {
  title: {sv: "Personalkollen"},
  page_settings: {
    root_path: "personalkollen"
  },
  desc: {sv: "Inställningar för personalkollen"},
  properties: [
    {key: "token", title: {sv: "Token"}, desc: {sv: ""}, type: "string", optional: false},
    {key: "counter_name", title: {sv: "Namn på kassa"}, desc: {sv: ""}, type: "string", optional: true},
  ]
};
