export const pageClusteringSettings = {
  title: { sv: "Clustering" },
  page_settings: {
    root_path: "clustering",
  },
  desc: "Inställningar för clustering",
  properties: [
    { key: "parent", title: { sv: "Parent" }, desc: { sv: "" }, type: "string" },
    { key: "child", title: { sv: "Child" }, desc: { sv: "" }, type: "string" },
    { key: "push", title: { sv: "Push" }, desc: { sv: "" }, type: "bool" },
    { key: "auto_single_parent", title: { sv: "Auto single parent" }, desc: { sv: "" }, type: "bool" },
  ]
};
