import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subscription } from "rxjs";
import { FSVenueConfig, VenueConfig } from "../models/venue-config";
import { FireService } from "./fire.service";
import { ObservableUtils } from '../utils/utils';

@Injectable({
  providedIn: 'root'
})
export class VenueMapService {
  private venueConfigs: ReplaySubject<VenueConfig[]> = new ReplaySubject<VenueConfig[]>(1);
  private venueObsSubject: Subscription;
  constructor(
    private fire: FireService,
  ) {
    this.getVenueConfigs(); // kick off the subscription
  }

  public getVenueConfigs(): Observable<VenueConfig[]> {
    if (!this.venueObsSubject) {
      console.log("Creating venueObsSubject");
      const venueConfigs$ = this.fire.observeVenueConfigChanges();
      this.venueObsSubject = ObservableUtils.projectedChanges(venueConfigs$, (input: FSVenueConfig) => {
        return VenueMapService.toVenueConfig(input);
      }).subscribe((configs) => {
        this.venueConfigs.next(configs);
      });
    }
    return this.venueConfigs;
  }

  private static toVenueConfig(input: FSVenueConfig): VenueConfig | null {
    if (!input) { return null; }
    const cfg = JSON.parse(input.data);
    if (!cfg) { return null; }
    cfg.venueId = input.venue_id;
    return cfg;
  }

}
