<div class="receipts-container">
  <div >
    <table mat-table [dataSource]="dataSource" matSort>
      "paid_date", "table", "total_amount", "total_tip", "method", "type", "explorer"

      <ng-container matColumnDef="paid_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Paid </th>
        <td mat-cell *matCellDef="let element" style="padding: 4px; width: 150px;"> {{element.paid_date.toDate() |date:'medium'}} </td>
      </ng-container>

      <ng-container matColumnDef="table">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Table </th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.table}} </td>
      </ng-container>

      <ng-container matColumnDef="total_amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Amount </th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.total_amount | moneySuff : null : "kr"}}</td>
      </ng-container>

      <ng-container matColumnDef="total_tip">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Tip </th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.total_tip | moneySuff : null : "kr"}} </td>
      </ng-container>

      <ng-container matColumnDef="method">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Method </th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.method}} </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;"> {{element.type}} </td>
      </ng-container>

      <ng-container matColumnDef="explorer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Explore </th>
        <td mat-cell *matCellDef="let element" style="padding: 4px;"> <a *ngIf="isSuperAdmin()" style="text-decoration: none;" href='/receipt-explorer?query={{element.key}}' title="Open in receipt explorer" >👀</a> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns; trackBy: trackById;" (click)="select(row)" [ngClass]="{'selected': selectedRow?.order_id === row.order_id}"></tr>
    </table>
  </div>
</div>
