import {Component, Inject, NgZone, OnInit} from '@angular/core';
import {PublishService} from "../../../services/publish.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MenuStateService} from "../menu-utils/menu-state.service";

@Component({
  selector: 'app-menu-publish-dialog',
  templateUrl: './menu-publish-dialog.component.html',
  styleUrls: ['./menu-publish-dialog.component.css']
})
export class MenuPublishDialogComponent implements OnInit {
  publishing = false;
  message: string;
  errorMessage: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {venueId: number}, private publishService: PublishService,
              public dialogRef: MatDialogRef<MenuPublishDialogComponent>, private state: MenuStateService, private ngZone: NgZone) {}

  ngOnInit(): void {
  }

  publish() {
    this.message = "";
    this.errorMessage = "";
    this.publishing = true;
    this.publishService.publish(this.data.venueId, this.state.menuId).subscribe( r => {
      this.publishing = false;
      this.message = "Published successfully!";
      console.log("Published successfully!");
    }, error => {
      this.errorMessage = error;
      this.publishing = false;
      console.log(error);
    });

  }
}
