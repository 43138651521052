export const pageResourceSettings = {
  title: {sv: "Resurser"},
  page_settings: {
    root_path: "resources",
  },
  desc: {sv: "Justera inställningar för resurser"},
  properties: [
    {key: "types", title: {sv: "Resurser"}, desc: {sv: ""}, type: "table",
      columns: [
        {key: "name", title: {sv: "Namn"}, type: "string", optional: false },
        {key: "time_unit", title: {sv: "Tidsenhet"}, type: "enum", optional: false,
          source: [ "minute", "hour", "day", "week", "month", "year" ]
        },
        {key: "charge_increment", title: {sv: "Debiteringsintervall"}, type: "integer", optional: false },
        {key: "rounding", title: {sv: "Avrundning"}, type: "enum", optional: false,
          source: [ "up", "down", "nearest" ],
        },
        {key: "default_rent_duration", title: {sv: "Standard hyreslängd"}, type: "integer", optional: false },
        {key: "minimum_rent_duration", title: {sv: "Minsta hyreslängd"}, type: "integer", optional: false },
        {key: "minor_time_increment", title: {sv: "Tidssteg 1"}, type: "integer", optional: false },
        {key: "major_time_increment", title: {sv: "Tidssteg 2"}, type: "integer", optional: false },
        {key: "icon", title: {sv: "Ikon"}, type: "string", optional: true },
      ]
    },
  ]
};
