<h2 mat-dialog-title>Välj PIN-kod {{identity | pnumber}}</h2>
<div mat-dialog-content style="position: relative;">
  <div>
    <mat-form-field class="indialog">
      <mat-label>PIN (4 numbers)</mat-label>
      <input matInput placeholder="1234" [formControl]="pinCode">
    </mat-form-field>
  </div>
  <mat-spinner style="position: absolute; top: calc(50% - 15px); left: calc(50% - 15px);" [diameter]="30" *ngIf="loadingService.visibility | async"></mat-spinner>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="update()">Save</button>
</mat-dialog-actions>
