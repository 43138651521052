import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-venue-user-list',
  templateUrl: './venue-user-list.component.html',
  styleUrls: ['./venue-user-list.component.css']
})
export class VenueUserListComponent implements OnInit {
  venueId: number;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
    });
  }

}
