import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {VenueTicket} from 'src/app/models/venue-ticket';
import {AuthService} from 'src/app/services/auth.service';
import {FireService} from 'src/app/services/fire.service';
import firebase from "firebase/compat/app";
import Timestamp = firebase.firestore.Timestamp;
import { TicketService } from 'src/app/services/ticket.service';
import { VenueMapService } from 'src/app/services/venue-map.service';
import { TimeUtils } from 'src/app/utils/utils';
import * as moment from 'moment';

@Component({
  selector: 'app-single-ticket-dialog',
  templateUrl: './single-ticket-dialog.component.html',
  styleUrls: ['./single-ticket-dialog.component.css']
})
export class SingleTicketDialogComponent implements OnInit {
  ticket: VenueTicket;
  states = this.ticketService.getStates();
  stateValues = Object.values(this.states);
  venueName = "";

  constructor(@Inject(MAT_DIALOG_DATA) data: VenueTicket, private dialogRef: MatDialogRef<SingleTicketDialogComponent>, private auth: AuthService,
              private fire: FireService, private ticketService: TicketService, private venueMapService: VenueMapService) {
    this.ticket = {...data};
  }

  ngOnInit(): void {
    this.venueMapService.getVenueConfigs().subscribe(cfgs => {
      this.venueName = cfgs.find(v => v.venueId === this.ticket.venueId)?.venue.name || "";
    });
  }

  createTicket() {
    if (!this.ticket.title) { return; }
    this.ticketService.createTicket(this.ticket);
    this.dialogRef.close(true);
  }

  updateTicket() {
    if (!this.ticket.title) { return; }
    this.ticketService.updateTicket(this.ticket);
    this.dialogRef.close(true);
  }

  deleteTicket() {

  }

  archiveTicket() {
    this.ticket.state = this.states.ARCHIVED;
    this.fire.updateVenueTicket(this.ticket);
    this.dialogRef.close(true);
  }

  isHeynowAdmin() {
    return AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }

  showState(state: string) {
    return this.ticketService.showState(state);
  }

  getTime(date: Timestamp) {
    const timestamp = date;
    const unixTimestamp = timestamp.seconds + timestamp.nanoseconds / 1000000000;
    const momentObj = moment.unix(unixTimestamp);
    return TimeUtils.getRelativeDayStringTimeDate(momentObj);
  }

}
