import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FireService} from "../../services/fire.service";
import {OrderService} from "../../services/order.service";
import {VenueService} from "../../services/venue.service";
import {FloorplanTable} from "../../models/floorplan";

@Component({
  selector: 'app-table-probe',
  templateUrl: './table-probe.component.html',
  styleUrls: ['./table-probe.component.css']
})
export class TableProbeComponent implements OnInit {
  venueId: number;
  table: string;
  summaryData: { venueId: number; table: string };

  constructor(private route: ActivatedRoute, private fire: FireService, private orderService: OrderService,
              private venueService: VenueService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.table = data.get("table");
      this.beginObserve();
    });
  }

  private beginObserve() {
    this.summaryData = {table: this.table, venueId: this.venueId};

  }
}
