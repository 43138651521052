/* eslint-disable max-len */
import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DeviceState, DeviceStateClient, DeviceStateStatus, DeviceStateTerminal, DeviceStatusIconAndColor} from "../../models/device-state";
import {DeviceStatusService} from "../../services/device-status.service";
import {ActivatedRoute, Router} from '@angular/router';
import * as moment from 'moment';
import Utils from '../../common/utils';
import {Subscription} from "rxjs";
import firebase from "firebase/compat/app";
import {AuthService} from "../../services/auth.service";
import {environment} from "../../../environments/environment";
import { ClientStatus } from 'src/app/models/client-status';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

class Inspected {
  title: string;
  subTitle: string;
  rows: string[];
  icon: string;
  iconColor: string;
}

@Component({
  selector: 'app-device-status',
  templateUrl: './device-status.component.html',
  styleUrls: ['./device-status.component.css']
})
export class DeviceStatusComponent implements OnInit, OnDestroy {
  @Input() venueId: string;
  @Input() title: string = "";
  @Input() isSubVenue: boolean = false;
  deviceDump: string;
  rows: any;
  inspected: Inspected;
  private currentState: DeviceState;
  private selectedDevice: string;
  private sub: Subscription;

  @ViewChild(MatSort) sort: MatSort;
  deviceList = false;
  listSource = new MatTableDataSource<{ deviceList: string; devices: any; }>();
  listColumns: string[] = ['deviceName', 'version', 'ip', 'model', 'status'];

  constructor(
    private deviceStatusService: DeviceStatusService,
    private route: ActivatedRoute,
    private auth: AuthService,
    ) { }

  ngOnInit(): void {
    if (this.venueId == null) {
      this.route.paramMap.subscribe(data => {
        this.venueId = data.get("venue_id");
        console.log(`venueId = ${this.venueId}`);
        if (!this.auth.redirectIfMissingPermissions(this.venueId, "devices")) {
          return;
        }
        this.beginObserving();
      });
    } else {
      this.beginObserving();
    }
  }

  private prepareDeviceListData() {
    const clientsData = [];
    const terminalsData = [];
    const printersData = [];
    const agentsData = [];

    if (this.currentState?.clients) {
      for (const client of this.currentState.clients) {
        if (client.clientStatus) {
          clientsData.push({
            deviceName: client.clientStatus.name || 'N/A',
            ip: client.clientStatus.ipAddress || 'N/A',
            version: client.clientStatus.appVersionName || 'N/A',
            model: client.clientStatus.deviceName || 'N/A',
            status: client.clientStatus.status || 'N/A',
          });
        }

        client.terminals?.forEach(terminal => {
          terminalsData.push({
            deviceName: terminal.terminalStatus.terminalName || 'N/A',
            ip: terminal.config.ip || 'N/A',
            version: 'Terminal',
            model: terminal.config.model || 'N/A',
            status: terminal.terminalStatus?.status || 'N/A',
          });
        });

        client.printers?.forEach(printer => {
          let ip = 'N/A';
          let version ='N/A';
          console.log("Printer: ", printer);
          if (printer.printerStatus.driver === '' || printer.printerStatus.driver === 'Epson') {
            ip = printer.printerStatus.ipAddress || 'N/A';
            version = 'Epson';
          } else if (printer.printerStatus.driver === 'EscPos') {
            ip = printer.printerStatus.target?.replace(/^TCP:/, '') || 'N/A';
            version = 'EscPos';
          } else if (printer.printerStatus.driver === 'iMin') {
            ip = printer.printerStatus.target?.replace(/^TCP:/, '') || 'N/A';
            version = 'iMin';
          }

          printersData.push({
            deviceName: printer.printerStatus.name || 'N/A',
            ip,
            version,
            model: printer.printerStatus.model || 'N/A',
            status: printer.printerStatus?.status || 'N/A',
          });
        });

      }
    }

    this.currentState?.agents?.forEach(agent => {
      agentsData.push({
        deviceName: agent.clientStatus.name || 'N/A',
        ip: agent.clientStatus?.ipAddress || 'N/A',
        version: agent.clientStatus?.appVersionName || 'N/A',
        model: agent.clientStatus?.deviceName || 'N/A',
        status: agent.clientStatus?.status || 'N/A',
      });
    });

    const deviceData = [...clientsData, ...agentsData, ...printersData, ...terminalsData];
    this.listSource.data = deviceData;

  }

  isDifferentNetwork(ip: string): boolean {
    const ips = this.listSource.data.map(device => this.getIpAddressFromDevice(device));
    const commonPrefix = this.findCommonIpPrefix(ips);
    //console.log("COMMON_PREFIX: ", commonPrefix);
    this.listSource.sort = this.sort;
    if (ip.startsWith('TCP:')) {
      return false;
    }
    return !ip.startsWith(commonPrefix);
  }
  getIpAddressFromDevice(device: any): string {

    if (device.ip) {
      return device.ip;
    } else if (device.target) {
      const match = device.target.match(/(?:TCP:)?(\d+\.\d+\.\d+\.\d+)|(?:TCP:.+)/);
      return match ? match[1] || match[0] : 'N/A';
    } else if (device.ipAddress) {
      return device.ipAddress;
    }
    return 'N/A';
  }


  findCommonIpPrefix(ips: string[]): string {
    const prefixCounts = new Map<string, number>();

    ips.forEach(ip => {
      const prefixParts = ip.split('.', 4);
      if (prefixParts.length >= 3) {
        const prefix = `${prefixParts[0]}.${prefixParts[1]}.${prefixParts[2]}`;
        const count = prefixCounts.get(prefix) || 0;
        prefixCounts.set(prefix, count + 1);
      }
    });

    let commonPrefix = '';
    let maxCount = 0;

    prefixCounts.forEach((count, prefix) => {
      if (count > maxCount) {
        commonPrefix = prefix;
        maxCount = count;
      }
    });

    return commonPrefix;
  }

  toggleDeviceList(){
    this.deviceList = !this.deviceList;
    if (this.deviceList) {
      this.prepareDeviceListData();
    }
    if (this.inspected){
      this.inspected = null;
    }

  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  private beginObserving() {
    console.log(`beginObserving...${Number(this.venueId)}`);
    const that = this;
    this.sub = this.deviceStatusService.observeDeviceStatusForVenue(Number(this.venueId)).subscribe({
      next(response) {
        console.log(response);
        that.update(response);
      },
      error(err) {
        console.error('Error: ' + err);
      },
      complete() {
        console.log('Completed');
      }
    });
  }

  update(ds: DeviceState) {
    this.currentState = ds;
    const clients = [];
    for (const client of ds.clients) {
      const children = [];
      if (client.terminals.length > 0) {
        children.push({name: "Terminals", children: client.terminals.map(terminal => ({device: terminal, inspectable: true, name: terminal.name}))});
      }
      if (client.printers.length > 0) {
        children.push({name: "Printers", children: client.printers.map(printer => ({device: printer, inspectable: true, name: printer.name}))});
      }
      clients.push({device: client, inspectable: true, name: client.name, children});
    }
    const shared = ds.sharedDevices.map(shd => ({device: shd, inspectable: true, name: shd.name}));
    const agents = ds.agents.map(agent => ({device: agent, inspectable: true, name: agent.name}));
    const clientList = [{name: "Clients", children: clients}];
    if (shared.length > 0) {
      clientList.push({name: "Shared", children: [{name: "Printers", children: shared}]});
    }
    if (agents.length > 0) {
      clientList.push({name: "Agents", children: agents});
    }
    const rows = dumpTreeAsRows({name: "Devices", children: clientList});
    this.rows = rows;

    if (this.selectedDevice) {
      this.inspectDevice(this.selectedDevice);
    }
  }

  inspectDevice(name: string) {
    if (this.deviceList){
      this.deviceList = false;
    }
    this.selectedDevice = name;
    this.inspected = new Inspected();
    const row = this.rows.find(r => r.name === name);
    const device = row?.device;
    if (device) {
      this.inspected.title = name;
      this.inspected.icon = row.icon;
      this.inspected.iconColor = row.iconColor;
      const status = device?.clientStatus ?? device?.terminalStatus ?? device?.printerStatus;
      const rows = [];
      for (const key of Object.keys(status).sort()) {
        if ( ["data", "name", "parentId", "id", "type", "venueId"].indexOf(key) > -1) { continue; }
        if (!AuthService.isSuperAdmin(this.auth.authStateSnapshot) && ["bugfenderUrl"].indexOf(key) > -1) { continue; }
        let value = status[key];
        if (value instanceof firebase.firestore.Timestamp) {
          value = moment(value.toDate()).format("YYYY-MM-DD HH:mm:ss");
        }
        rows.push({key, value, isLink: Utils.isStringLink(value)});
      }
      if (AuthService.isSuperAdmin(this.auth.authStateSnapshot) && device instanceof DeviceStateClient) {
        const clientStatus: ClientStatus = device?.clientStatus;
        if(clientStatus != null) {
          rows.push({ key: "userConfig", value: `${environment.james_url}/venue/${this.venueId}/devices/${clientStatus.parentId}`, isLink: true });
        }
      }
      if (device instanceof DeviceStateTerminal) {
        console.log(device.config);
        rows.push({key: "ip", value: device.config.ip});
        rows.push({key: "port", value: device.config.port});
        rows.push({key: "alias", value: device.config.alias});
        rows.push({key: "remote", value: device.config.remote});
        rows.push({key: "print_receipts", value: device.config.print_receipts});
        rows.push({key: "model", value: device.config.model});
      }

      this.inspected.rows = rows;
    }
  }

  isVersionOutdated(version: string): boolean {
    if (!version) {
      return false;
    }
    const lastPart = version.split('_').pop();
    if (!lastPart) {
      return true;
    }
    const normalizedDate = lastPart.length === 6 ? '20' + lastPart : lastPart;
    const referenceDate = '20231024';
    return normalizedDate <= referenceDate;
  }

}

function dumpTree(node, indent: string = "  ") {
  const n = indent + `${indent}${node.name}`;
  console.log(n);
  let s = n + "\n";
  for (const child of node.children ?? []) {
    const c = dumpTree(child, indent + "  ");
    s += c;
  }
  return s;
}


function dumpTreeAsRows(node, level: number = 0) {
  const device = node.device;
  const status = device?.getStatus() ?? null;
  const iac = DeviceStatusIconAndColor(status);

  const title = device ? `${node.name} (${device.getStatus()})` : node.name;
  const flash = device ? device.recentlyUpdated() : false;
  level = level > 3 ? 3 : level;
  const rows = [{flash, name: node.name, title, level, inspectable: node.inspectable ?? false, icon: iac.icon, iconColor: iac.color, device}];
  for (const child of node.children ?? []) {
    const childRows = dumpTreeAsRows(child, level + 1);
    rows.push(...childRows);
  }
  return rows;
}
function showDeviceList() {
  throw new Error('Function not implemented.');
}

