<div class="tool-bar">
  <mat-slide-toggle class="printer-toggle" [(ngModel)]="printerToggles[printer.name]" (ngModelChange)="togglePrinter()" color="primary" *ngFor="let printer of printers">{{printer.name}} <span *ngIf="isSuperAdmin">{{getAveragePrintTimeForPrinter(printer.name)}}</span></mat-slide-toggle>
</div>

<div class="bong-container">
  <div class="bong" *ngFor="let bong of printJobs; trackBy: identify">
    <div class="bong-header" [ngStyle]="{'background-color':bong.color}">
      <div>{{bong.printer_name}}<span style="float: right;" *ngIf="isSuperAdmin">{{printTime(bong.created, bong.printed, bong.skipped)}}</span></div>
    </div>
    <div class="bong-content" *ngIf="bong.printJobData">
      <div *ngFor="let row of bong.printJobData.rows">
        {{row.text}}
      </div>
    </div>
    <div class="bong-content" *ngIf="bong.printInstructions">
      <div *ngFor="let inst of bong.printInstructions" [ngClass]="inst.tclass">
        <div *ngIf="inst.temp == 'div'">
          <hr>
        </div>
        <div *ngIf="inst.temp == 'center'" class="dos-center">
          {{inst.text.center}}
        </div>
        <div *ngIf="inst.temp == 'row'">
          {{inst.text.left}} {{inst.text.center}} {{inst.text.right}}
        </div>
      </div>
    </div>   
  </div>
</div>
