import { Component, OnInit } from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {VenueService} from "../../../services/venue.service";
import {ApiService} from "../../../services/api.service";

@Component({
  selector: 'app-landing-start',
  templateUrl: './landing-start.component.html',
  styleUrls: ['./landing-start.component.css']
})
export class LandingStartComponent implements OnInit {
  email = new UntypedFormControl('', [Validators.email, Validators.required]);
  sending = false;
  success = false;
  checked = false;

  constructor(private snackBar: MatSnackBar, private api: ApiService) { }

  ngOnInit(): void {
  }

  createAccount() {
    if (this.email.valid && this.checked) {
      this.snackBar.dismiss();
      this.sending = true;
      this.api.newLead(this.email.value).then(r => {
        console.log(r);
        this.success = true;
      }).finally(() => this.sending = false );
    } else {
      if (!this.email.valid) {
        this.snackBar.open("Kontrollera angiven email, ser inte helt korrekt ut.", "Stäng", {duration: 5000});
      } else {
        this.snackBar.open("Läs igenom och godkänn de allmänna villkoren", "Stäng", {duration: 5000});
      }
      this.sending = false;
    }
  }
}
