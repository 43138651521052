import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {VenueService} from "../../../../services/venue.service";
import {LoadingService} from "../../../../services/loading.service";
import {AuthService} from "../../../../services/auth.service";

@Component({
  selector: 'app-new-message-dialog',
  templateUrl: './new-message-dialog.component.html',
  styleUrls: ['./new-message-dialog.component.css']
})
export class NewMessageDialogComponent implements OnInit {
  title = new UntypedFormControl('', [Validators.required]);
  message = new UntypedFormControl('', [Validators.required]);

  constructor(@Inject(MAT_DIALOG_DATA) data, private venueService: VenueService, public loadingService: LoadingService,
              private dialogRef: MatDialogRef<NewMessageDialogComponent>, private auth: AuthService) {
  }

  ngOnInit(): void {
  }

  sendMessage() {
    if (this.title.valid && this.message.valid) {
      this.venueService.createNewVenueMessage(this.title.value, this.message.value).then( r => {
        console.log("Created new message...");
        this.dialogRef.close(true);
      });
    }
  }

  isHeynowAdmin() {
    return AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }

}
