import {Slot, SlotDay} from "../models/slot";

export class SlotUtils {

  static findEarliestAndLatestSlot(slotDays: SlotDay[]): { first: Slot, last: Slot } {
    let foundFirst: Slot = null;
    let foundLast: Slot = null;
    for (const slotDay of slotDays) {
      if (slotDay.slots == null || slotDay.slots.length === 0 ) { continue; }
      const first = slotDay.slots[0];
      const last = slotDay.slots[slotDay.slots.length - 1];
      if (foundFirst == null || first.time < foundFirst.time) {
        foundFirst = first;
      }
      if (foundLast == null || last.time > foundLast.time) {
        foundLast = last;
      }
    }
    return {first: foundFirst, last: foundLast};
  }

}
