import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthState} from "../../../models/signin";
import {MediaObserver} from "@angular/flex-layout";
import {AuthService} from "../../../services/auth.service";
import {LoadingService} from "../../../services/loading.service";
import {MatSidenav} from "@angular/material/sidenav";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {Router} from "@angular/router";

@Component({
  selector: 'app-menu-dash',
  templateUrl: './menu-dash.component.html',
  styleUrls: ['./menu-dash.component.css']
})
export class MenuDashComponent implements OnInit {
  private authState: AuthState;
  navMode = 'side';
  opened = false;
  isMobile = false;
  public appitems: Array<any> = [];

  constructor(private observableMedia: MediaObserver, public authService: AuthService, public loadingService: LoadingService,
              breakpointObserver: BreakpointObserver, router: Router) {
    breakpointObserver.observe([Breakpoints.Handset]).subscribe(result => {
      if (result.matches) {
        this.opened = false;
        this.navMode = 'over';
        this.isMobile = true;
      } else {
        this.opened = true;
        this.navMode = 'side';
        this.isMobile = false;
      }
    });
  }

  ngOnInit() {
    this.authService.getLoggedInAuthState().subscribe(auth => {
      this.authState = auth;
      this.setupNavigation();
    });
  }

  private setupNavigation() {
    const menu = [];

    const isMenuAdmin = true; //AuthService.isSuperAdmin(this.authState);
    if (isMenuAdmin) {
      menu.push(
        {
          label: "Support",
          icon: 'support_agent',
          items: [
            {
              label: 'Venues',
              link: '/venues',
              icon: 'corporate_fare',
              activeIcon: 'corporate_fare'
            },
            {
              label: 'Clients',
              link: '/clients',
              icon: 'tablet_mac',
              activeIcon: 'tablet_mac'
            },
          ]
        }
      );

      menu.push(
        {
          label: "Admin",
          icon: 'settings',
          items: [
            {
              label: 'Admins',
              link: '/admins',
              icon: 'people',
              activeIcon: 'people'
            },
          ]
        }
      );
    }

    this.appitems = menu;
  }

}
