export const pageBoothSettings = {
  title: { sv: "Booth" },
  page_settings: {
    root_path: "booth"
  },
  desc: { sv: "Inställningar för Booth" },
  properties: [
    { key: "booths", title: { sv: "Booths" }, desc: { sv: "" }, type: "table",
      columns: [
        { key: "name", title: { sv: "Namn" }, type: "string" },
        { key: "area", title: { sv: "Area" }, type: "string" },
        { key: "filter", title: { sv: "Filter" }, type: "string" },
        { key: "email", title: { sv: "E-post" }, type: "string" },
        { key: "your_order_text", title: { sv: "\"Din order\"" }, type: "string", optional: true },
        { key: "thank_you_text", title: { sv: "\"Tack\" text" }, type: "string", optional: true },
      ]
    },
    { key: "active_hours", title: { sv: "Öppettider" }, desc: { sv: "Tidformat exempel 12 = 12:00, 12.5 = 12:30, 12.75 = 12:45 etc. Dagformat mån = 0, tis = 1 ... sön = 6" }, type: "table",
      columns: [
        { key: "day", title: { sv: "Dag" }, type: "integer[]", width: 150 },
        { key: "start", title: { sv: "Öppning" }, type: "integer", width: 150 },
        { key: "stop", title: { sv: "Stängning" }, type: "integer", width: 150 },
      ]
    },
    { key: "background", title: { sv: "Bakgrund" }, desc: { sv: "" }, type: "string", optional: true },
    { key: "venue_logo", title: { sv: "Venue logga" }, desc: { sv: "" }, type: "string", optional: true },
  ]
};
