<div class="heynow-logo-container">
    <img src="assets/images/heynow.png" alt="Heynow.ai" class="heynow-logo-image">
  </div>
<mat-card class="custom-mat-card">
    <mat-card-title>RESET PASSWORD</mat-card-title>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="resetPassword()" *ngIf="!(loadingService.visibility | async)">
            <p>
                <mat-form-field>
                    <input type="text" matInput id="email" required formControlName="email" placeholder="Email"
                        name="email">
                    <mat-error *ngIf="form.get('email').hasError('email')">Invalid format</mat-error>
                    <mat-error *ngIf="form.get('email').hasError('required')">Required</mat-error>
                </mat-form-field>
            </p>

            <div class="button">
                <button mat-flat-button color="primary" class="submit-btn" type="submit" mat-button [disabled]="!form.valid && form.touched">Send reset mail</button>
            </div>
            <br>
             <mat-error class="error" *ngIf="!emailSent">
                {{error}}
            </mat-error>
            <div *ngIf="emailSent">A reset email is sent. Please check your mail box.</div>
            


        </form>
        <mat-spinner [diameter]="200" *ngIf="loadingService.visibility | async"></mat-spinner>
    </mat-card-content>
</mat-card>