export const pageSlotting = {
  title: {sv: "Slottider"},
  page_settings: {
    root_path: "takeaway.slotting"
  },
  desc: {sv: "Justera slottids inställningar"},
  properties: [
    {key: "active", title: {sv: "Aktivt för gäster"}, desc: {sv: "Slår på/av bokingsmöjlighet för gäster, påverkar inte redan lagda bokningar"}, type: "bool"},
    {key: "bong_prepare_minutes", title: {sv: "Förberedningstid"}, desc: {sv: "Hur långt i förväg skall bongen printas"}, default: 60, type: "integer", suffix: "min"},
    {key: "pre_allocate_days", title: {sv: "Förallokering"}, desc: {sv: "Antal dagar framåt som skall förallokeras automatiskt"}, default: 5, type: "integer", suffix: "dagar"},
    {key: "allocate", title: {sv: "Allokeringsmatris"}, desc: {sv: "Inställning av allokering av slottar"}, type: "table",
      columns: [
        {key: "when", title: {sv: "När"}, type: "string", width: 200 },
        {key: "capacity", title: {sv: "Kapacitet"}, type: "integer", width: 100 },
      ]
    },
    {key: "allocation_crons", title: {sv: "Allokeringstidpunkter"}, desc: {sv: "Ange vid vilka tider som allokering av nya slottar skall ske (ex mån-sön 23:30 eller fre 20:00)"}, type: "table",
      columns: [
        {key: "when", title: {sv: "När"}, type: "string", width: 200 },
      ]
    },
    {key: "items_capacity", title: {sv: "Kapacitetsförbrukning"}, desc: {sv: "Inställning av hur mycket kapacitet som förbrukas för produktsortimentet"}, type: "table",
      columns: [
        {key: "filter", title: {sv: "Filter"}, type: "string", width: 200 },
        {key: "capacity", title: {sv: "Kapacitet"}, type: "integer", width: 100 },
      ]
    },
    {key: "slot_length", title: {sv: "Slot-längd"}, desc: {sv: "Ange hur lång en slot är i minuter, t.ex. 15 min"}, default: 15, type: "integer", suffix: "min"},
    {key: "over_booking_cap", title: {sv: "Överbokningskapacitet"}, desc: {sv: "Hur mycket kapacitet går det att överboka"}, default: 0, type: "integer", suffix: "cap"},
    {key: "min_split_slot_allocation", title: {sv: "Minsta splitbokningskapacitet"}, desc: {sv: "Minsta kapacitet måste finnas kvar i en slot för att en splittad bokning skall bokas in"}, default: 0, type: "integer", suffix: "slots"},
    {key: "namespaces", title: {sv: "Kalender partitioner"}, desc: {sv: "Ange namn och id för kalendrar"}, type: "table",
      columns: [
        {key: "id", title: {sv: "Id"}, type: "string", width: 100 },
        {key: "name", title: {sv: "Namn"}, type: "string", width: 300 },
      ]
    },
    {key: "bong_summaries", title: {sv: "Summeringsbongar"}, desc: {sv: "Ange när summeringsbongar skall skrivas ut och på vilken skrivare"}, type: "table",
      columns: [
        {key: "when", title: {sv: "När"}, type: "string", width: 200 },
        {key: "printer", title: {sv: "Skrivare"}, type: "string", width: 200 },
      ]
    },

  ]
};
