<div class="wf_panel" [class]="{'disabled':!wfClass.active}">
  <div class="wf_title">
    <span>{{wfClass.title}} workflow</span>
    <span><a style="font-size: 14px;color: #317370;" [matMenuTriggerFor]="menuClass">edit</a></span>
  </div>
  <div class="wf_content">
    <div style="margin: -16px;" *ngIf="editSettings">
      <app-data-form #dataform [schema]="dataFormSchema" [root]="wfClass"></app-data-form>
    </div>
    <div *ngIf="!editSettings" class="settings-panel">
      <span *ngIf="wfClass.order_filter" class="smallthing" (click)="toggle()">Order filter: {{wfClass.order_filter}}</span>
      <span *ngIf="wfClass.when" class="smallthing" (click)="toggle()">When: {{wfClass.when}}</span>
      <div class="desc">{{wfClass.desc}}</div>
    </div>
    <app-wf-step *ngFor="let step of wfClass.steps; let i = index" [step]="step" (makeCopy)="makeCopy(i)" (moveUp)="moveUp(i)" (moveDown)="moveDown(i)" (deleted)="deleteStep(i)"></app-wf-step>
  </div>

  <button mat-button [matMenuTriggerFor]="menuSave" class="add-but">+ Lägg till steg</button>
  <mat-menu #menuSave="matMenu">
    <div class="menu-header">standard (alla ordrar)</div>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="addNewStep('sprint')">+ Skriva ut bong</button>
    <button mat-menu-item (click)="addNewStep('bong')">+ Skapa KDS post</button>
    <button mat-menu-item (click)="addNewStep('message')">+ Skicka meddelande till gäst</button>
    <div class="menu-header">synk. (endast party=multi)</div>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="addNewStep('sync_print')">+ Skriva ut bong (synkroniserat)</button>
    <button mat-menu-item (click)="addNewStep('sync_bong')">+ Skapa KDS post (synkroniserat)</button>
    <button mat-menu-item (click)="addNewStep('sync_print_bong')">+ Både bong och KDS post (synkroniserat)</button>
  </mat-menu>

  <mat-menu #menuClass="matMenu">
    <button mat-menu-item (click)="editSettings=true;" *ngIf="!editSettings">Expandera inställningar</button>
    <button mat-menu-item (click)="closeSettings()" *ngIf="editSettings">Stäng inställningar</button>
    <button mat-menu-item (click)="copyWorkflow()">Skapa kopia</button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="deleteWorkflow()">Ta bort</button>
  </mat-menu>

</div>

