<div>
  <p>
    <mat-toolbar>
      <button mat-icon-button routerLink="../../devices"><mat-icon>arrow_back_ios</mat-icon></button>
      <span>Underlag för anmälan av kassaregister/ registreringsenhet</span>
      <span class="spacer"></span>
      <button mat-button [matMenuTriggerFor]="menuSave"><mat-icon>settings</mat-icon></button>
      <mat-menu #menuSave="matMenu">
        <button mat-menu-item (click)="unreg()">Markera som AV-anmäld</button>
        <button mat-menu-item (click)="reg()">Markera som anmäld</button>
      </mat-menu>
    </mat-toolbar>
  </p>
</div>

<div class="reg-panel" *ngIf="deviceData">
  <b>Status anmälan</b>
  <table style="width: 400px;margin-bottom: 32px;" *ngIf="deviceData.reg_unit">
    <tr *ngIf="deviceData.reg_unit?.state">
      <td>Angiven status</td>
      <td>{{deviceData.reg_unit?.state_sv}}</td>
    </tr>
    <tr *ngIf="deviceData.reg_unit?.created && deviceData.reg_unit?.state==='registered'">
      <td>Registreringsdatum</td>
      <td>{{deviceData.reg_unit?.created | date:"medium"}}</td>
    </tr>
    <tr>
      <td>Enhetsnamn</td>
      <td>{{deviceData.email}}</td>
    </tr>
    <tr *ngIf="deviceData.reg_unit.reg_name && deviceData.reg_unit?.state==='registered'">
      <td>Tillverkningsnummer</td>
      <td>{{deviceData.reg_unit?.reg_name}}</td>
    </tr>
    <tr *ngIf="deviceData.reg_unit?.ccu_key && deviceData.reg_unit?.state==='registered'">
      <td>CCU</td>
      <td>{{deviceData.reg_unit?.ccu_key}}</td>
    </tr>
  </table>
  <table style="width: 400px;margin-bottom: 32px;" *ngIf="!deviceData.reg_unit">
    <tr>
      <td>Angiven status</td>
      <td>Ej anmäld</td>
    </tr>
  </table>

  <div *ngIf="deviceData.reg_unit?.state!=='registered'">
    <b>Underlag för anmälan</b>
    <table style="margin-top:8px;width: 600px;border:1px solid #d0d0d0;">
      <tr>
        <td class="cell"><i>Tillverkningsnummer</i></td>
        <td class="cell">{{deviceData.reg_unit.reg_name}}</td>
        <td class="cell"><button mat-stroked-button (click)="copyTextToClipboard(deviceData.reg_unit.reg_name)">Kopiera</button></td>
      </tr>
      <tr *ngIf="cashRegister">
        <td class="cell"><i>Bolagsnamn</i></td>
        <td class="cell">{{cashRegister.bus_name}}</td>
        <td class="cell"><button mat-stroked-button (click)="copyTextToClipboard(cashRegister.bus_name)">Kopiera</button></td>
      </tr>
      <tr *ngIf="cashRegister">
        <td class="cell"><i>Postnummer</i></td>
        <td class="cell">{{cashRegister.postal_code}}</td>
        <td class="cell"><button mat-stroked-button (click)="copyTextToClipboard(cashRegister.postal_code)">Kopiera</button></td>
      </tr>
      <tr *ngIf="cashRegister">
        <td class="cell"><i>Gata</i></td>
        <td class="cell">{{cashRegister.street}}</td>
        <td class="cell"><button mat-stroked-button (click)="copyTextToClipboard(cashRegister.street)">Kopiera</button></td>
      </tr>
      <tr *ngIf="cashRegister">
        <td class="cell"><i>Stad</i></td>
        <td class="cell">{{cashRegister.city}}</td>
        <td class="cell"><button mat-stroked-button (click)="copyTextToClipboard(cashRegister.city)">Kopiera</button></td>
      </tr>
    </table>

    <div style="margin-top: 40px;">
      <b>Efter du slutfört anmälan hos skatteverket</b>
    </div>
    <div style="margin-top: 8px;margin-bottom: 8px;">
      <button mat-flat-button (click)="reg()" color="primary">Markera enhet som anmäld</button>
    </div>
  </div>
</div>

<app-wiki-display *ngIf="deviceData" section="support" path="registerdevice" [style]="'wiki-doc'"></app-wiki-display>
