<h2 mat-dialog-title>Add new tab</h2>
<div mat-dialog-content style="position: relative;">
  <div>
    <mat-form-field class="unset">
      <mat-label>Tab name</mat-label>
      <input matInput placeholder="starters" [(ngModel)]="tabName" required>
      <!--      <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>-->
    </mat-form-field>
  </div>
  <!--  <mat-spinner style="position: absolute; top: calc(50% - 15px); left: calc(50% - 15px);" [diameter]="30" *ngIf="loadingService.visibility | async"></mat-spinner>-->
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="createTab()">Create</button>
</mat-dialog-actions>
