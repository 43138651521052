export const pageBongTemplate = {
  title: {sv: "Bongmall"},
  page_settings: {
    root_path: "bong_template"
  },
  desc: {sv: "Justera utseende på bongutskrifter"},
  properties: [
    {key: "content", title: {sv: "Bong content"}, desc: {sv: ""}, type: "table",
      columns: [
        {key: "kind", title: {sv: "kind"}, type: "string", width: 100 },
        {key: "row", title: {sv: "row"}, type: "string", width: 100 },
        {key: "left", title: {sv: "left"}, type: "string", width: 200 },
        {key: "center", title: {sv: "center"}, type: "string", width: 200 },
        {key: "right", title: {sv: "right"}, type: "string", width: 200 },
        {key: "layout", title: {sv: "layout"}, type: "string", width: 100 },
        {key: "if", title: {sv: "if"}, type: "string", width: 100 },
      ]
    },
    {key: "wait", title: {sv: "Bong wait"}, desc: {sv: ""}, type: "table",
      columns: [
        {key: "kind", title: {sv: "kind"}, type: "string", width: 100 },
        {key: "row", title: {sv: "row"}, type: "string", width: 100 },
        {key: "left", title: {sv: "left"}, type: "string", width: 200 },
        {key: "center", title: {sv: "center"}, type: "string", width: 200 },
        {key: "right", title: {sv: "right"}, type: "string", width: 200 },
        {key: "layout", title: {sv: "layout"}, type: "string", width: 100 },
        {key: "if", title: {sv: "if"}, type: "string", width: 100 },
      ]
    },
    {key: "group", title: {sv: "Bong group"}, desc: {sv: ""}, type: "table",
      columns: [
        {key: "kind", title: {sv: "kind"}, type: "string", width: 100 },
        {key: "row", title: {sv: "row"}, type: "string", width: 100 },
        {key: "left", title: {sv: "left"}, type: "string", width: 200 },
        {key: "center", title: {sv: "center"}, type: "string", width: 200 },
        {key: "right", title: {sv: "right"}, type: "string", width: 200 },
        {key: "layout", title: {sv: "layout"}, type: "string", width: 100 },
        {key: "if", title: {sv: "if"}, type: "string", width: 100 },
      ]
    },
    {key: "item", title: {sv: "Bong item"}, desc: {sv: ""}, type: "table",
      columns: [
        {key: "kind", title: {sv: "kind"}, type: "string", width: 100 },
        {key: "row", title: {sv: "row"}, type: "string", width: 100 },
        {key: "left", title: {sv: "left"}, type: "string", width: 200 },
        {key: "center", title: {sv: "center"}, type: "string", width: 200 },
        {key: "right", title: {sv: "right"}, type: "string", width: 200 },
        {key: "layout", title: {sv: "layout"}, type: "string", width: 100 },
        {key: "if", title: {sv: "if"}, type: "string", width: 100 },
      ]
    },
    {key: "child_item", title: {sv: "Bong child item"}, desc: {sv: ""}, type: "table",
      columns: [
        {key: "kind", title: {sv: "kind"}, type: "string", width: 100 },
        {key: "row", title: {sv: "row"}, type: "string", width: 100 },
        {key: "left", title: {sv: "left"}, type: "string", width: 200 },
        {key: "center", title: {sv: "center"}, type: "string", width: 200 },
        {key: "right", title: {sv: "right"}, type: "string", width: 200 },
        {key: "layout", title: {sv: "layout"}, type: "string", width: 100 },
        {key: "if", title: {sv: "if"}, type: "string", width: 100 },
      ]
    },
    {key: "goes_with", title: {sv: "Bong goes with"}, desc: {sv: "Mall för 'gär med' rubrik"}, type: "table",
      columns: [
        {key: "kind", title: {sv: "kind"}, type: "string", width: 100 },
        {key: "row", title: {sv: "row"}, type: "string", width: 100 },
        {key: "left", title: {sv: "left"}, type: "string", width: 200 },
        {key: "center", title: {sv: "center"}, type: "string", width: 200 },
        {key: "right", title: {sv: "right"}, type: "string", width: 200 },
        {key: "layout", title: {sv: "layout"}, type: "string", width: 100 },
        {key: "if", title: {sv: "if"}, type: "string", width: 100 },
      ]
    },
    {key: "goes_with_item", title: {sv: "Goes with item"}, desc: {sv: "Mall för 'går med' artiklar."}, type: "table",
      columns: [
        {key: "kind", title: {sv: "kind"}, type: "string", width: 100 },
        {key: "row", title: {sv: "row"}, type: "string", width: 100 },
        {key: "left", title: {sv: "left"}, type: "string", width: 200 },
        {key: "center", title: {sv: "center"}, type: "string", width: 200 },
        {key: "right", title: {sv: "right"}, type: "string", width: 200 },
        {key: "layout", title: {sv: "layout"}, type: "string", width: 100 },
        {key: "if", title: {sv: "if"}, type: "string", width: 100 },
      ]
    },
    {key: "items_customized", title: {sv: "Bong item customized template"}, desc: {sv: "Bong instructions to render customizations (attributes) on separate rows (optional)"}, type: "table", optional: true,
      columns: [
        {key: "kind", title: {sv: "kind"}, type: "string", width: 100 },
        {key: "row", title: {sv: "row"}, type: "string", width: 100 },
        {key: "left", title: {sv: "left"}, type: "string", width: 200 },
        {key: "center", title: {sv: "center"}, type: "string", width: 200 },
        {key: "right", title: {sv: "right"}, type: "string", width: 200 },
        {key: "layout", title: {sv: "layout"}, type: "string", width: 100 },
        {key: "if", title: {sv: "if"}, type: "string", width: 100 },
      ]
    },
    {key: "collected_values", title: {sv: "Bong template for custom values"}, desc: {sv: "Bong instructions to render custom collected values on separate rows (optional)"}, type: "table", optional: true,
      columns: [
        {key: "kind", title: {sv: "kind"}, type: "string", width: 100 },
        {key: "row", title: {sv: "row"}, type: "string", width: 100 },
        {key: "left", title: {sv: "left"}, type: "string", width: 200 },
        {key: "center", title: {sv: "center"}, type: "string", width: 200 },
        {key: "right", title: {sv: "right"}, type: "string", width: 200 },
        {key: "layout", title: {sv: "layout"}, type: "string", width: 100 },
        {key: "if", title: {sv: "if"}, type: "string", width: 100 },
      ]
    },
  ]
};
