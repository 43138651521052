import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AuthService } from './services/auth.service';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { MultilevelMenuService, NgMaterialMultilevelMenuModule } from 'ng-material-multilevel-menu';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatDatepickerModule } from "@angular/material/datepicker";
import { DateAdapter, MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { CustomHttpInterceptor } from './http-interceptor';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { VenueListComponent } from './components/venue-list/venue-list.component';
import { ZreportsComponent } from './components/zreports/zreports.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { HeaderCardComponent } from './components/header-card/header-card.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { ClientRecordsComponent } from './components/client-records/client-records.component';
import { NewSettlementComponent } from './components/new-settlement/new-settlement.component';
import { SettlementsComponent } from './components/settlements/settlements.component';
import { BankTransactionsComponent } from './components/bank-transactions/bank-transactions.component';
import { PayoutBatchesComponent } from './components/payout-batches/payout-batches.component';
import { MainContentComponent } from './components/main-content/main-content.component';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DeviceStatusComponent } from './components/device-status/device-status.component';
import { ClientListComponent } from './components/client-list/client-list.component';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from "@angular/material/legacy-slide-toggle";
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ReceiptListComponent } from './components/receipt-list/receipt-list.component';
import {DatePipe, registerLocaleData} from "@angular/common";
import { TermtxsComponent } from './components/debug/termtxs/termtxs.component';
import { PrintJobListComponent } from './components/print-job-list/print-job-list.component';
import { EmbeddedMenuComponent } from './components/embedded-menu/embedded-menu.component';
import { ConfigViewComponent } from './components/debug/config-view/config-view.component';
import { FloorplanComponent } from './components/floorplan/floorplan.component';
import { KdsBongListComponent } from './components/kds-bong-list/kds-bong-list.component';
import { EmbeddedPdfsComponent } from './components/embedded-pdfs/embedded-pdfs.component';
import { OrderListComponent } from './components/order-list/order-list.component';
import { UserListComponent } from './components/user-list/user-list.component';
import { NewUserDialogComponent } from './components/user-list/new-user-dialog/new-user-dialog.component';
import {MatDialogModule} from "@angular/material/dialog";
import { QueryUserDialogComponent } from './components/user-list/query-user-dialog/query-user-dialog.component';
import {MatLegacySelectModule as MatSelectModule} from "@angular/material/legacy-select";
import { EditRoleDialogComponent } from './components/user-list/edit-role-dialog/edit-role-dialog.component';
import { LinkAccountComponent } from './components/link-account/link-account.component';
import { VenueUserListComponent } from './components/user-list/venue-user-list/venue-user-list.component';
import { LiveDashboardComponent } from './components/live-dashboard/live-dashboard.component';
import {MatLegacyMenuModule as MatMenuModule} from "@angular/material/legacy-menu";
import { EventListComponent } from './components/event-list/event-list.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { StaffListComponent } from './components/staff-list/staff-list.component';
import { QueryStaffDialogComponent } from './components/staff-list/query-staff-dialog/query-staff-dialog.component';
import { NewStaffDialogComponent } from './components/staff-list/new-staff-dialog/new-staff-dialog.component';
import { EditPinDialogComponent } from './components/staff-list/edit-pin-dialog/edit-pin-dialog.component';
import { MenuDashComponent } from './components/catalog/menu-dash/menu-dash.component';
import { MenuTabComponent } from './components/catalog/menu-tab/menu-tab.component';
import { HotTableModule } from '@handsontable/angular';
import { MenuNavComponent } from './components/catalog/menu-nav/menu-nav.component';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import { MenuSidebarComponent } from './components/catalog/menu-sidebar/menu-sidebar.component';
import { NewTabDialogComponent } from './components/catalog/menu-sidebar/new-tab-dialog/new-tab-dialog.component';
import { MenuOptionsTabComponent } from './components/catalog/menu-options-tab/menu-options-tab.component';
import { MenuAttributeDialogComponent } from './components/catalog/menu-attribute-dialog/menu-attribute-dialog.component';
import {MatLegacyRadioModule as MatRadioModule} from "@angular/material/legacy-radio";
import { MenuPushpagesTabComponent } from './components/catalog/menu-pushpages-tab/menu-pushpages-tab.component';
import { MenuSelectItemsDialogComponent } from './components/catalog/menu-pushpages-tab/menu-select-items-dialog/menu-select-items-dialog.component';
import { MenuPushSelectDialogComponent } from './components/catalog/menu-push-select-dialog/menu-push-select-dialog.component';
import { MenuPublishDialogComponent } from './components/catalog/menu-publish-dialog/menu-publish-dialog.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { TicketComponent } from './components/ticket/ticket.component';
import {MatLegacyCheckboxModule as MatCheckboxModule} from "@angular/material/legacy-checkbox";
import { MenuIcDialogComponent } from './components/catalog/menu-tab/menu-ic-dialog/menu-ic-dialog.component';
import { TableProbeComponent } from './components/table-probe/table-probe.component';
import { OrderSummaryComponent } from './components/order-summary/order-summary.component';
import { OrderListActivityComponent } from './components/order-list/order-list-activity/order-list-activity.component';
import { EventListActivityComponent } from './components/event-list/event-list-activity/event-list-activity.component';
import { PrintJobListActivityComponent } from './components/print-job-list/print-job-list-activity/print-job-list-activity.component';
import { OrderingPadComponent } from './components/pos/ordering-pad/ordering-pad.component';
import { MenuItemDialogComponent } from './components/pos/menu-item-dialog/menu-item-dialog.component';
import { SettingsComponent } from './components/settings/settings/settings.component';
import { SimpleDialogComponent } from './components/simple-dialog/simple-dialog.component';
import { SettingsHistoryListComponent } from './components/settings/settings-history-list/settings-history-list.component';
import { PosComponent } from './components/pos/pos/pos.component';
import { FloorplanActivityComponent } from './components/floorplan/floorplan-activity/floorplan-activity.component';
import { PaymentComponent } from './components/pos/payment/payment.component';
import { NewOrderComponent } from './components/pos/new-order/new-order.component';
import { DiscountCodeListComponent } from './components/discount-code-list/discount-code-list.component';
import { ReceiptViewComponent } from './components/pos/receipt-view/receipt-view.component';
import { DossierViewerComponent } from './components/dossier-viewer/dossier-viewer.component';
import { LiveReceiptListComponent } from './components/live-receipt-list/live-receipt-list.component';
import { LiveReceiptListActivityComponent } from './components/live-receipt-list/live-receipt-list-activity/live-receipt-list-activity.component';
import { ReceiptExplorerComponent } from './components/admin/receipt-explorer/receipt-explorer.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { BillingComponent } from './components/billing/billing.component';
import { NewDiscountCodeDialogComponent } from './components/discount-code-list/new-discount-code-dialog/new-discount-code-dialog.component';
import { KpiDashboardComponent } from './components/kpi/kpi-dashboard/kpi-dashboard.component';
import { MoneySuffPipe } from './pipes/money-suff.pipe';
import { TicketListComponent } from './components/ticket-list/ticket-list.component';
import { SlotCalendarComponent } from './components/booking/slot-calendar/slot-calendar.component';
import { FloorplanEditorComponent } from './components/floorplan-editor/floorplan-editor.component';
import { EditSectionDialogComponent } from './components/floorplan-editor/edit-section-dialog/edit-section-dialog.component';
import { FloorplanHistoryListComponent } from './components/floorplan-editor/floorplan-history-list/floorplan-history-list.component';
import { MenuHistoryListComponent } from './components/catalog/menu-history-list/menu-history-list.component';
import { MenuVersionComponent } from './components/catalog/menu-version/menu-version.component';
import { VenueCustomersComponent } from './components/settings/venue-customers/venue-customers.component';
import { AddFilterDialogComponent } from './components/receipt-list/add-filter-dialog/add-filter-dialog.component';
import { FloorplanV2Component } from './components/floorplan/floorplan-v2/floorplan-v2.component';
import { ImageBankComponent } from './components/image-bank/image-bank.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { VenueComponent } from './components/venue/venue.component';
import { MenuTabSettingsComponent } from './components/catalog/menu-tab-settings/menu-tab-settings.component';
import { DataFormComponent } from './components/data-form/data-form.component';
import { PrepaidListComponent } from './components/prepaid-list/prepaid-list.component';
import {NgApexchartsModule} from "ng-apexcharts";
import { ReportDashComponent } from './components/reports/report-dash/report-dash.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { ReportTreeComponent } from './components/reports/report-tree/report-tree.component';
import { ReportHourlyComponent } from './components/reports/report-hourly/report-hourly.component';
import { ReportCustomerComponent } from './components/reports/report-customer/report-customer.component';
import { ReportStaffComponent } from './components/reports/report-staff/report-staff.component';
import { ReportZsummaryComponent } from './components/reports/report-zsummary/report-zsummary.component';
import { AmountPipe } from './pipes/amount.pipe';
import { ZreportInfoComponent } from './components/zreports/zreport-info/zreport-info.component';
import { EditSlotsComponent } from './components/booking/edit-slots/edit-slots.component';
import { ApiTokenListComponent } from './components/admin/api-token-list/api-token-list.component';
import { NewApiTokenDialogComponent } from './components/admin/api-token-list/new-api-token-dialog/new-api-token-dialog.component';
import { EnterGuestAddressDialogComponent } from './components/pos/enter-guest-address-dialog/enter-guest-address-dialog.component';
import { PnumberPipe } from './pipes/pnumber.pipe';
import { EditStaffDialogComponent } from './components/staff-list/edit-staff-dialog/edit-staff-dialog.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { SlotDayComponent } from './components/booking/slot-day/slot-day.component';
import { FortnoxComponent } from './components/integration/fortnox/fortnox.component';
import { EditSectionOrderDialogComponent } from './components/floorplan-editor/edit-section-order-dialog/edit-section-order-dialog.component';
import { TokenEndpointSettingsComponent } from './components/admin/api-token-list/token-endpoint-settings/token-endpoint-settings.component';
import { LandingComponent } from './components/start-page/landing/landing.component';
import { LandingStartComponent } from './components/start-page/landing-start/landing-start.component';
import { LandingNextComponent } from './components/start-page/landing-next/landing-next.component';
import { LandingGoComponent } from './components/start-page/landing-go/landing-go.component';
import { BreezeFrameComponent } from './components/breeze-frame/breeze-frame.component';
import { VenueCronsComponent } from './components/debug/venue-crons/venue-crons.component';
import { VenueMessageListComponent } from './components/admin/venue-message-list/venue-message-list.component';
import { NewMessageDialogComponent } from './components/admin/venue-message-list/new-message-dialog/new-message-dialog.component';
import { AdyenComponent } from './components/integration/adyen/adyen.component';
import { VoucherListComponent } from './components/voucher-list/voucher-list.component';
import { CustomDateAdapter } from './utils/utils';
import { PrepaidDetailsComponent } from './components/prepaid-list/prepaid-details/prepaid-details.component';
import { ReceiptDialogComponent } from './components/pos/receipt-view/receipt-dialog/receipt-dialog.component';
import localeSv from '@angular/common/locales/sv';
import { ReportTimeseriesComponent } from './components/reports/report-timeseries/report-timeseries.component';
import { ReportTableComponent } from './components/reports/report-table/report-table.component';
import { CardBalanceComponent } from './components/reports/card-balance/card-balance.component';
import { ReportDailyComponent } from './components/reports/report-daily/report-daily.component';
import { SendReceiptDialogComponent } from './components/pos/receipt-view/send-receipt-dialog/send-receipt-dialog.component';
import { SkvComponent } from './components/skv/skv.component';
import { RegunitListComponent } from './components/admin/regunit-list/regunit-list.component';
import { VenueTransactionsComponent } from './components/reports/venue-transactions/venue-transactions.component';
import { ReportEventsComponent } from './components/reports/report-events/report-events.component';
import { WikiDocumentEditorComponent } from './components/heywiki/wiki-document-editor/wiki-document-editor.component';
import { WikiDisplayComponent } from './components/heywiki/wiki-display/wiki-display.component';
import { WikiDocDisplayComponent } from './components/heywiki/wiki-doc-display/wiki-doc-display.component';
import { WikiImagesComponent } from './components/heywiki/wiki-images/wiki-images.component';
import { WikiInfoLinkComponent } from './components/heywiki/wiki-info-link/wiki-info-link.component';
import { ReportClassicStaffSummaryComponent } from './components/reports/report-classic-staff-summary/report-classic-staff-summary.component';
import { FilterBuilderComponent } from './components/debug/filter-builder/filter-builder.component';
import { VenueCcuComponent } from './components/integration/venue-ccu/venue-ccu.component';
import { TitleBarComponent } from './components/reports/common-reports/title-bar/title-bar.component';
import { DeviceListComponent } from './components/device/device-list/device-list.component';
import { DeviceEditorComponent } from './components/device/device-editor/device-editor.component';
import { ReportCheckinsComponent } from './components/reports/report-checkins/report-checkins.component';
import { SReportsComponent } from './components/reports/s-reports/s-reports.component';
import { ReportDiscountedItemsComponent } from './components/reports/report-discounted-items/report-discounted-items.component';
import { RegTemplateComponent } from './components/device/reg-template/reg-template.component';
import { SingleTicketDialogComponent } from './components/admin/single-ticket-dialog/single-ticket-dialog.component';
import { MiniTicketsComponent } from './components/admin/mini-tickets/mini-tickets.component';
import { OrdersFrameComponent } from './orders-frame/orders-frame.component';
import { SoftpayTerminalsComponent } from './components/softpay/softpay-terminals/softpay-terminals.component';
import { SoftpayTerminalDetailsComponent } from './components/softpay/softpay-terminal-details/softpay-terminal-details.component';
import { XreportComponent } from './components/zreports/xreport/xreport.component';
import { LoyaltyListComponent } from './components/loyalty/loyalty-list/loyalty-list.component';
import { LoyaltyEditorComponent } from './components/loyalty/loyalty-editor/loyalty-editor.component';
import { LoyaltyCardImportComponent } from './components/loyalty/loyalty-card-import/loyalty-card-import.component';
import { SuperDeviceListComponent } from './components/device/super-device-list/super-device-list.component';
import { VenueMapService } from './services/venue-map.service';
import { FbBotListComponent } from './components/admin/fb-bots/fb-bot-list/fb-bot-list.component';
import { EditFbBotComponent } from './components/admin/fb-bots/edit-fb-bot/edit-fb-bot.component';
import { VenuePickerDialogComponent } from './components/dialogs/venue-picker-dialog/venue-picker-dialog.component';
import { ChainListComponent } from './components/admin/chain-list/chain-list.component';
import { NewChainDialogComponent } from './components/admin/chain-list/new-chain-dialog/new-chain-dialog.component';
import { ChainSettingsComponent } from './components/admin/chain-list/chain-settings/chain-settings.component';
import { WorkflowEditorComponent } from './components/settings/workflow-editor/workflow-editor.component';
import { WfClassComponent } from './components/settings/workflow-editor/wf-class/wf-class.component';
import { WfStepComponent } from './components/settings/workflow-editor/wf-step/wf-step.component';
import {MatTabsModule} from "@angular/material/tabs";
import { MenuPackageDialogComponent } from './components/catalog/menu-package-dialog/menu-package-dialog.component';
import { SimpleEmailEditorComponent } from './components/admin/simple-email-editor/simple-email-editor.component';
import { CreateVenueDialogComponent } from './components/dialogs/create-venue-dialog/create-venue-dialog.component';
import { VenueEditorComponent } from './components/settings/venue-editor/venue-editor.component';
import { BookingListComponent } from './components/booking-list/booking-list.component';
import { BookingDetailsComponent } from './components/booking-list/booking-details/booking-details.component';
import { EditVenueCustomerComponent } from './components/settings/venue-customers/edit-venue-customer/edit-venue-customer.component';
import { VenueCustomersListComponent } from './components/settings/venue-customers/venue-customers-list/venue-customers-list.component';
import { EditSmartTableComponent } from './components/smart-tables/edit-smart-table/edit-smart-table.component';
import { SmartTableListComponent } from './components/smart-tables/smart-table-list/smart-table-list.component';
import { UtcDatePipe } from './pipes/utc-date';
import { EditPrepaidNoDialogComponent } from './components/dialogs/edit-prepaid-no-dialog/edit-prepaid-no-dialog.component';
import { SubscribeComponent } from './components/subscribe/subscribe.component';
import { SubscriberListComponent } from './components/admin/subscriber-list/subscriber-list.component';
import { WikiDbComponent } from './components/admin/wiki-db/wiki-db.component';
import { FilterEditorComponent } from './components/filter-editor/filter-editor.component';
import { EditFilterTermDialogComponent } from './components/filter-editor/edit-filter-term-dialog/edit-filter-term-dialog.component';
import { IcTreeViewComponent } from './components/ic-tree-view/ic-tree-view.component';
import { CreateWikiPageComponent } from './components/dialogs/create-wiki-page/create-wiki-page.component';
import { ReportMenuSalesComponent } from './components/reports/report-menu-sales/report-menu-sales.component';
import { ReportSalesDatetimeComponent } from './components/reports/report-sales-datetime/report-sales-datetime.component';
import { ChainDeviceStatusComponent } from './components/device-status/chain-device-status/chain-device-status.component';
import { SelectDateDialogComponent } from './components/zreports/select-date-dialog/select-date-dialog.component';
import { EditAdminUserDialogComponent } from './components/user-list/edit-admin-user-dialog/edit-admin-user-dialog.component';
import { AdminAccountsListComponent } from './components/admin/admin-accounts-list/admin-accounts-list.component';
import { AdminAccountDetailsComponent } from './components/admin/admin-accounts-list/admin-account-details/admin-account-details.component';
import { AccountComponent } from './components/account/account.component';
import { PendingListComponent } from './components/account/pending-list/pending-list.component';
import { InvoicePrepareDialogComponent } from './components/dialogs/invoice-prepare-dialog/invoice-prepare-dialog.component';
import {MatLegacyAutocompleteModule} from "@angular/material/legacy-autocomplete";
import { CoreReceiptListComponent } from './components/receipt-list/core-receipt-list/core-receipt-list.component';
import { OpeningHoursComponent } from './components/opening-hours/opening-hours.component';

registerLocaleData(localeSv, 'sv');

@NgModule({
  declarations: [
    AppComponent,
    ZreportsComponent,
    MainContentComponent,
    ToolbarComponent,
    HeaderCardComponent,
    SignInComponent,
    ClientRecordsComponent,
    NewSettlementComponent,
    SettlementsComponent,
    BankTransactionsComponent,
    PayoutBatchesComponent,
    VenueListComponent,
    DeviceStatusComponent,
    DashboardComponent,
    ClientListComponent,
    ReceiptListComponent,
    TermtxsComponent,
    PrintJobListComponent,
    EmbeddedMenuComponent,
    ConfigViewComponent,
    FloorplanComponent,
    KdsBongListComponent,
    EmbeddedPdfsComponent,
    OrderListComponent,
    UserListComponent,
    NewUserDialogComponent,
    QueryUserDialogComponent,
    EditRoleDialogComponent,
    LinkAccountComponent,
    VenueUserListComponent,
    LiveDashboardComponent,
    EventListComponent,
    MyAccountComponent,
    StaffListComponent,
    QueryStaffDialogComponent,
    NewStaffDialogComponent,
    EditPinDialogComponent,
    MenuDashComponent,
    MenuTabComponent,
    MenuNavComponent,
    MenuSidebarComponent,
    NewTabDialogComponent,
    MenuOptionsTabComponent,
    MenuAttributeDialogComponent,
    MenuPushpagesTabComponent,
    MenuSelectItemsDialogComponent,
    MenuPushSelectDialogComponent,
    MenuPublishDialogComponent,
    TicketComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    MenuIcDialogComponent,
    TableProbeComponent,
    OrderSummaryComponent,
    OrderListActivityComponent,
    EventListActivityComponent,
    PrintJobListActivityComponent,
    OrderingPadComponent,
    MenuItemDialogComponent,
    SettingsComponent,
    SimpleDialogComponent,
    SettingsHistoryListComponent,
    PosComponent,
    FloorplanActivityComponent,
    PaymentComponent,
    NewOrderComponent,
    DiscountCodeListComponent,
    ReceiptViewComponent,
    DossierViewerComponent,
    LiveReceiptListComponent,
    LiveReceiptListActivityComponent,
    ReceiptExplorerComponent,
    ChangePasswordComponent,
    InvoicesComponent,
    BillingComponent,
    NewDiscountCodeDialogComponent,
    KpiDashboardComponent,
    MoneySuffPipe,
    TicketListComponent,
    SlotCalendarComponent,
    FloorplanEditorComponent,
    EditSectionDialogComponent,
    FloorplanHistoryListComponent,
    MenuHistoryListComponent,
    MenuVersionComponent,
    VenueCustomersComponent,
    AddFilterDialogComponent,
    FloorplanV2Component,
    ImageBankComponent,
    VenueComponent,
    MenuTabSettingsComponent,
    DataFormComponent,
    PrepaidListComponent,
    ReportDashComponent,
    ReportTreeComponent,
    ReportHourlyComponent,
    ReportCustomerComponent,
    ReportStaffComponent,
    ReportZsummaryComponent,
    AmountPipe,
    ZreportInfoComponent,
    EditSlotsComponent,
    ApiTokenListComponent,
    NewApiTokenDialogComponent,
    EnterGuestAddressDialogComponent,
    PnumberPipe,
    EditStaffDialogComponent,
    SlotDayComponent,
    FortnoxComponent,
    EditSectionOrderDialogComponent,
    TokenEndpointSettingsComponent,
    LandingComponent,
    LandingStartComponent,
    LandingNextComponent,
    LandingGoComponent,
    BreezeFrameComponent,
    VenueCronsComponent,
    VenueMessageListComponent,
    NewMessageDialogComponent,
    AdyenComponent,
    TokenEndpointSettingsComponent,
    VoucherListComponent,
    PrepaidDetailsComponent,
    ReceiptDialogComponent,
    ReportTimeseriesComponent,
    ReportTableComponent,
    CardBalanceComponent,
    ReportDailyComponent,
    SendReceiptDialogComponent,
    SkvComponent,
    RegunitListComponent,
    VenueTransactionsComponent,
    ReportEventsComponent,
    WikiDocumentEditorComponent,
    WikiDisplayComponent,
    WikiDocDisplayComponent,
    WikiImagesComponent,
    WikiInfoLinkComponent,
    ReportClassicStaffSummaryComponent,
    FilterBuilderComponent,
    VenueCcuComponent,
    TitleBarComponent,
    DeviceListComponent,
    DeviceEditorComponent,
    ReportCheckinsComponent,
    SReportsComponent,
    ReportDiscountedItemsComponent,
    RegTemplateComponent,
    SingleTicketDialogComponent,
    MiniTicketsComponent,
    OrdersFrameComponent,
    SoftpayTerminalsComponent,
    SoftpayTerminalDetailsComponent,
    XreportComponent,
    LoyaltyListComponent,
    LoyaltyEditorComponent,
    LoyaltyCardImportComponent,
    FbBotListComponent,
    EditFbBotComponent,
    SuperDeviceListComponent,
    VenuePickerDialogComponent,
    ChainListComponent,
    NewChainDialogComponent,
    ChainSettingsComponent,
    MenuPackageDialogComponent,
    WorkflowEditorComponent,
    WfClassComponent,
    WfStepComponent,
    SimpleEmailEditorComponent,
    CreateVenueDialogComponent,
    VenueEditorComponent,
    BookingListComponent,
    BookingDetailsComponent,
    VenueCustomersListComponent,
    EditVenueCustomerComponent,
    EditSmartTableComponent,
    SmartTableListComponent,
    UtcDatePipe,
    EditPrepaidNoDialogComponent,
    SubscribeComponent,
    SubscriberListComponent,
    WikiDbComponent,
    IcTreeViewComponent,
    FilterEditorComponent,
    EditFilterTermDialogComponent,
    CreateWikiPageComponent,
    ReportMenuSalesComponent,
    ReportSalesDatetimeComponent,
    ChainDeviceStatusComponent,
    SelectDateDialogComponent,
    EditAdminUserDialogComponent,
    AdminAccountsListComponent,
    AdminAccountDetailsComponent,
    AccountComponent,
    PendingListComponent,
    OpeningHoursComponent,
    InvoicePrepareDialogComponent,
    CoreReceiptListComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    HotTableModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatExpansionModule,
    MatListModule,
    MatButtonModule,
    HttpClientModule,
    MatTableModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    NgMaterialMultilevelMenuModule,
    FontAwesomeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatMenuModule,
    MatButtonToggleModule,
    MatRadioModule,
    MatCheckboxModule,
    NgxJsonViewerModule,
    ClipboardModule,
    NgApexchartsModule,
    TreeModule,
    GoogleMapsModule,
    MatTabsModule,
    MatLegacyAutocompleteModule,
  ],
  exports: [MainContentComponent],
  providers: [AuthService, VenueMapService, DatePipe, AngularFirestore, NgMaterialMultilevelMenuModule, {
    provide: HTTP_INTERCEPTORS,
    useClass: CustomHttpInterceptor,
    multi: true
  },
  { provide: LOCALE_ID, useValue: 'sv' },
  { provide: MAT_DATE_LOCALE, useValue: 'sv-SE' },
  { provide: DateAdapter, useClass: CustomDateAdapter },
  { provide: MultilevelMenuService, useClass: MultilevelMenuService}
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
