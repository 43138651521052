export const pageDiscountSettings = {
  title: {sv: "Inställningar för Rabatter"},
  page_settings: {
    root_path: "discount"
  },
  desc: {sv: "Inställningar för rabatter"},
  properties: [
    {key: "hide_fixed_discounts", title: {sv: "Dölj fasta rabatter"}, desc: {sv: "Döljer de inbyggda procentsats-rabatterna i kassan"}, type: "bool", optional: true, width: 200 },
  ]
};
