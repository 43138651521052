import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { SmartTable } from 'src/app/models/smart-table';
import { VenueService } from 'src/app/services/venue.service';
import { SimpleDialogComponent } from '../../simple-dialog/simple-dialog.component';
import { HackUtils } from 'src/app/utils/utils';

@Component({
  selector: 'app-smart-table-list',
  templateUrl: './smart-table-list.component.html',
  styleUrls: ['./smart-table-list.component.css']
})
export class SmartTableListComponent implements OnInit, OnDestroy {

  venueId: number;
  displayedColumns = ["created", "state", "account_name", "connected_table_names", "updated", "action",];
  dataSource: MatTableDataSource<SmartTable>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private venueService: VenueService, private route: ActivatedRoute, private router: Router,
              private dialog: MatDialog) { }

  ngOnInit() {
    this.sort.sort(({ id: 'created', start: 'desc' }) as MatSortable);
    this.route.paramMap.subscribe(data => {
      const vn = data.get("venue_id");
      this.venueId = Number(vn);
      this.fetchSmartTables();
    });
  }

  ngOnDestroy() {
  }

  fetchSmartTables() {
    this.venueService.fetchSmartTables(this.venueId).then((res) => {
      console.log("fetchSmartTables", res);
      this.dataSource = new MatTableDataSource(res as SmartTable[]);
      this.dataSource.sort = this.sort;
    });
  }

  addSmartTable() {
    this.router.navigateByUrl(`venue/${this.venueId}/smart-tables/new:`);
  }

  editSmartTable(row: SmartTable) {
    this.router.navigateByUrl(`venue/${this.venueId}/smart-tables/${row.key}`);
  }

  duplicateSmartTable(row: SmartTable) {
    this.router.navigateByUrl(`venue/${this.venueId}/smart-tables/dup:${row.key}`);
  }

  deleteSmartTable(row: SmartTable) {
    const dialogref = this.dialog.open(SimpleDialogComponent, HackUtils.DLG({
      data: {
        title: "Ta bort SmartTable!",
        message: "Är du säker på att du vill ta bort valt SmartTable?",
        positiveButton: "Bekräfta",
        cancelButton: "Avbryt",
      }
    }));

    dialogref.afterClosed().subscribe(result => {
      if (result) {
        this.venueService.deleteSmartTable(this.venueId, row.key).then((res) => {
          console.log("deleteSmartTable", res);
          this.fetchSmartTables();
        });
      }
    });
  }

}
