import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VenueService } from 'src/app/services/venue.service';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SimpleDialogComponent } from '../../simple-dialog/simple-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-venue-editor',
  templateUrl: './venue-editor.component.html',
  styleUrls: ['./venue-editor.component.css']
})
export class VenueEditorComponent implements OnInit {

  private venueId: number;

  form: FormGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    address: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email])
  });
  title = "Venue info";
  authToken: string;

  constructor(private venueService: VenueService, private route: ActivatedRoute, private location: Location, private snackBar: MatSnackBar, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      const vn = data.get("venue_id");
      this.venueId = Number(vn);
      this.fetchVenueInfo();
      this.fetchVenueAuthToken();
    });
  }

  fetchVenueInfo() {
    this.venueService.getVenueInfo(this.venueId).then(venue => {
      this.form.setValue({
        name: venue.name,
        address: venue.address,
        email: venue.email
      });
    });
  }

  fetchVenueAuthToken() {
    this.venueService.fetchVenueAuthToken(Number(this.venueId)).then( token => {
      this.authToken = token.token;
      console.log("Publish token", token);
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

  save() {
    if (!this.form.valid) {
      this.snackBar.open(`Formuläret är inte korrekt ifyllt`, "", {duration: 2000});
      return;
    }
    this.venueService.updateVenue(this.venueId, this.form.value).then(r => {
      console.log("Updated venue:", r);
    }).finally(() => {
      this.snackBar.open(`Sparat`, "", {duration: 2000});
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

  goBack() {
    this.location.back();
  }

  copyToClipboard(copyType: string) {
    let textToCopy: any; 
    if (copyType === "publiceringsnyckel") {
      const name = this.form.get('name').value;
      const authToken = this.authToken;
      textToCopy = `${name}\n${authToken}`;
    } else {
      textToCopy = copyType;
    }
  
    navigator.clipboard.writeText(textToCopy).then(() => {
      this.snackBar.open('Kopierat till urklipp!', "", {duration: 2000});
    }, (err) => {
      this.snackBar.open('Misslyckades att kopiera!', "", {duration: 2000});
    });
  }

}
