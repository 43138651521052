import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {ApiToken} from "../../../models/api-token";
import {MatDialog} from "@angular/material/dialog";
import {VenueService} from "../../../services/venue.service";
import {Router} from "@angular/router";
import {HNChain} from "../../../models/chain";
import {NewApiTokenDialogComponent} from "../api-token-list/new-api-token-dialog/new-api-token-dialog.component";
import {HackUtils} from "../../../utils/utils";
import {NewChainDialogComponent} from "./new-chain-dialog/new-chain-dialog.component";

@Component({
  selector: 'app-chain-list',
  templateUrl: './chain-list.component.html',
  styleUrls: ['./chain-list.component.css']
})
export class ChainListComponent implements OnInit {

  dataSource: MatTableDataSource<HNChain>;
  displayedColumns = ["path", "name", "chain_types"];

  constructor(public dialog: MatDialog, private venueService: VenueService, private router: Router) { }

  ngOnInit(): void {
    this.fetchChains();
  }

  private fetchChains() {
    this.venueService.fetchChains().then(r => {
      this.dataSource = new MatTableDataSource(r as HNChain[]);
      //this.dataSource.sort = this.sort;
    });
  }

  addChain() {
    const dialogRef = this.dialog.open(NewChainDialogComponent, HackUtils.DLG({ data: {}}));
    dialogRef.afterClosed().subscribe(result => {
      console.log('Dialog result:', result);
      this.fetchChains();
    });

  }

  select(row) {
    const chain_key = row.chain_key;
    this.router.navigateByUrl(`chains/${chain_key}`);
  }
}
