import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DataFormSchema} from "../../../models/data-form";
import {DataFormComponent} from "../../data-form/data-form.component";
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {Device} from "../../../models/reg-unit";
import {VenueService} from "../../../services/venue.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import { Location } from '@angular/common';

export const CREATE_DEVICE = "Skapa ny enhet";
export const EDIT_DEVICE = "Redigera enhet";

@Component({
  selector: 'app-device-editor',
  templateUrl: './device-editor.component.html',
  styleUrls: ['./device-editor.component.css']
})
export class DeviceEditorComponent implements OnInit, OnDestroy {

  dataFormSchema: DataFormSchema = {
    title: {sv: "Tab settings"},
    desc: {sv: "Ställ in tab"},
    properties: [
      {key: "email", title: {sv: "E-post"}, desc: {sv: ""}, type: "string", optional: false, font: "monospace"},
      {key: "password", title: {sv: "Lösenord"}, desc: {sv: ""}, type: "string", optional: false, hideable: true, hidden: true},
      {key: "name", title: {sv: "Namn"}, desc: {sv: ""}, type: "string", optional: false},
      {key: "last_name", title: {sv: "Gruppnamn"}, desc: {sv: ""}, type: "string", optional: false},
      {key: "alias", title: {sv: "Kortnamn"}, desc: {sv: ""}, type: "string", optional: true},
      {key: "role", title: {sv: "Roll"}, desc: {sv: ""}, type: "enum", optional: false, super_admin_only: true,
        enums: [
          {value: 100, title: {sv: "100 (Heynow)"}},
          {value: 10, title: {sv: "10 (Användare)"}},
          {value: 0, title: {sv: "0 (Inaktiv)"}},
        ]},
      // {key: "config", title: {sv: "Inställningar"}, desc: {sv: ""}, type: "json", optional: false},
      {key: "easy", title: {sv: "Inställningar"}, desc: {sv: ""}, type: "text", optional: true},
    ]
  };
  deviceData: Device;
  title = EDIT_DEVICE;

  @ViewChild("dataform") dataForm: DataFormComponent;
  private venueId: number;
  private deviceKey: string;
  isSuperAdmin: boolean;

  constructor(private route: ActivatedRoute, private auth: AuthService, private dialog: MatDialog,
              private venueService: VenueService, private snackBar: MatSnackBar, private location: Location) { }

  ngOnInit(): void {
    this.isSuperAdmin = AuthService.isSuperAdmin(this.auth.authStateSnapshot);
    this.route.paramMap.subscribe(data => {
      const vn = data.get("venue_id");
      this.venueId = vn ? Number(vn): undefined;
      const dk = data.get("device_key");
      if (dk.startsWith("dup:")) {
        this.deviceKey = null;
        this.title = CREATE_DEVICE;
        const dupKey = dk.split(":")[1];
        this.venueService.fetchDevice(this.venueId, dupKey).then(r => {
          this.deviceData = r;
        });
      } else if (dk.startsWith("new:")) {
        const role = Number(this.route.queryParams["_value"]["role"]);
        this.deviceKey = null;
        this.title = CREATE_DEVICE;
        Promise.resolve().then(() => {
          this.deviceData = {
            key: null,
            email: "",
            password: "",
            name: "",
            last_name: "",
            alias: "",
            config: "{}",
          } as Device;
          if (role) {
            this.deviceData.role = role;
          }
        });
      } else {
        this.deviceKey = dk;
        this.beginObserving();
      }
    });
  }

  ngOnDestroy(): void {
  }

  private beginObserving() {
    this.venueService.fetchDevice(this.venueId, this.deviceKey).then(r => {
      this.deviceData = r;
    });
  }

  save() {
    const data = this.dataForm.collectData();
    if (this.deviceKey) {
      data.key = this.deviceKey;
    }
    console.log("data", data);
    this.venueService.updateDevice(this.venueId, data).then(r => {
      this.deviceData = r;
    }).finally(() => {
      if (!this.deviceKey) {
        this.goBack();
        this.snackBar.open(`Enhet skapad`, "", {duration: 2000});
      } else {
        this.snackBar.open(`Enhet uppdaterad`, "", {duration: 2000});
      }
    }).catch(e => SimpleDialogComponent.showErr(this.dialog, e));
  }

  goBack() {
    this.location.back();
  }

}
