import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {VenueService} from "../../../services/venue.service";
import {Zreport} from "../../../models/zreport";
import { AuthService } from 'src/app/services/auth.service';
import { FireService } from 'src/app/services/fire.service';
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-zreport-info',
  templateUrl: './zreport-info.component.html',
  styleUrls: ['./zreport-info.component.css']
})
export class ZreportInfoComponent implements OnInit {

  zreport: Zreport;
  @Input() set zReport(zreport: Zreport) {
    this.zreport = zreport;
    console.log(this.zreport);
    this.loadHtml();
  }
  venueId: string;
  @Input() set VenueId(venue_id: string) {
    this.venueId = venue_id;
    this.loadHtml();
  }
  htmlContent: string | SafeHtml;
  showSpinner = true;
  fortnoxConfigured = false;

  constructor(
    private sanitizer: DomSanitizer,
    private venueService: VenueService,
    private auth: AuthService,
    private fire: FireService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  loadHtml() {
    if(this.venueId && this.zreport) {
      this.showSpinner = true;
      this.htmlContent = undefined;
      this.fire.observeVenueConfig(Number(this.venueId)).subscribe(cfg => {
        console.log(cfg);
        this.fortnoxConfigured = cfg.fortnox?.bookkeping?.active === true;
        console.log("fortnoxConfigured", this.fortnoxConfigured);
        this.venueService.fetchZReportHtml(Number(this.venueId), this.zreport.key).then(r => {
          this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(r.html);
          // Hack to force angular to update the view

        }).finally(() => { this.showSpinner = false; });
      });
    }
  }

  downloadSIE() {
    const fn = `sie_${this.zreport.org_number}_${this.zreport.serial_number}.si`;
    this.venueService.downloadSIE(Number(this.venueId), this.zreport.key, fn).subscribe(r => {
      console.log(r);
    });
  }

  downloadPDF() {
    const fn = `zreport_${this.zreport.org_number}_${this.zreport.serial_number}.pdf`;
    this.venueService.downloadPDF(Number(this.venueId), this.zreport.key, fn).subscribe(r => {
      console.log(r);
    });
  }

  sendToFortnox() {
    this.showSpinner = true;
    console.log("sendToFortnox", this.zreport.key);
    this.venueService.postZReportToFortnox(this.venueId, this.zreport.key).then(res => {
      console.log("sendToFortnoxResult", res);
      this.venueService.fetchZReport(Number(this.venueId), this.zreport.key).then(r => {
        this.zreport = r;
        this.loadHtml();
      });
    }).finally(() => { this.showSpinner = false; });
  }

  isSuperAdmin() {
    return AuthService.isSuperAdmin(this.auth.authStateSnapshot);
  }

  showPaymentReport() {
    this.showSpinner = true;
    this.htmlContent = undefined;
    this.venueService.fetchPaymentStaffReportHtml(Number(this.venueId), this.zreport.key).then(r => {
      this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(r.html);
    }).finally(() => { this.showSpinner = false; });
  }

  downloadPaymentStaffReportPDF() {
    const fn = `payment_staff_${this.zreport.org_number}_${this.zreport.serial_number}.pdf`;
    this.venueService.downloadPaymentStaffReportPDF(Number(this.venueId), this.zreport.key, fn).subscribe(r => {
      console.log(r);
    });
  }

  deleteZReport() {
    const ds = {title: "Ta bort z-rapport?", message: "Varning denna åtgärd går inte att återställa", cancelButton: "Avbryt", positiveButton: "Ta bort z-rapport"};
    SimpleDialogComponent.observeSimpleDialog(this.dialog, ds).subscribe( r => {
      if (r) {
        console.log("deleteZReport...");
        this.venueService.deleteZReport(Number(this.venueId), this.zreport.key).then(r => {
          this.zreport = undefined;
          this.htmlContent = this.sanitizer.bypassSecurityTrustHtml("Z-rapport borttagen");
        });
      }
    });
  }
}
