import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../services/auth.service";
import {FireService} from "../../../services/fire.service";
import {MenuStateService} from "../../catalog/menu-utils/menu-state.service";
import {MatDialog} from "@angular/material/dialog";
import {PublishService} from "../../../services/publish.service";
import {VenueService} from "../../../services/venue.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Device} from "../../../models/reg-unit";
import {Location} from '@angular/common';
import {CashRegisterConfig} from "../../../models/venue-config";

@Component({
  selector: 'app-reg-template',
  templateUrl: './reg-template.component.html',
  styleUrls: ['./reg-template.component.css']
})
export class RegTemplateComponent implements OnInit {
  private venueId: number;
  private deviceKey: string;
  public deviceData: Device;
  public cashRegister: CashRegisterConfig;

  constructor(private location: Location, private route: ActivatedRoute, private authService: AuthService, private fire: FireService, private state: MenuStateService,
              private router: Router, private dialog: MatDialog, private publish: PublishService, private venueService: VenueService, private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      const dk = data.get("device_key");
      this.deviceKey = dk;
      this.venueService.fetchDevice(this.venueId, this.deviceKey).then(r => {
        this.deviceData = r;
        console.log(r);
      });
      this.fire.observeVenueConfig(this.venueId).subscribe(r => {
        this.cashRegister = r.cash_register;
      });
    });
  }

  unreg() {
    this.venueService.setDeviceRequnitState(this.venueId, this.deviceData, "deregistered").then(r => {
      console.log(r);
      this.location.back();
    });
  }

  reg() {
    this.venueService.setDeviceRequnitState(this.venueId, this.deviceData, "registered").then(r => {
      console.log(r);
      this.location.back();
    });
  }

  copyTextToClipboard(text: string) {
    const eventHandler = (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', text);
      e.preventDefault();
      document.removeEventListener('copy', eventHandler);
    };
    document.addEventListener('copy', eventHandler);
    document.execCommand('copy');
    this.snackBar.open(`Kopierade: ${text}`, "", {duration: 2000});
  }

}
