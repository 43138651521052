export const pageAssetsSettings = {
  title: { sv: "Logotyper" },
  page_settings: {
    root_path: "assets",
  },
  desc: "Inställningar för logotyper",
  properties: [
    { key: "menu_logo", title: { sv: "Menylogo" }, desc: { sv: "Ange URL till menylogga" }, type: "string" },
    { key: "receipt_logo", title: { sv: "Kvitto-logo" }, desc: { sv: "Ange URL till kvittologga" }, type: "string", optional: true },
    { key: "share_img", title: { sv: "Delningsbild" }, desc: { sv: "Ange URL till delningsbild" }, type: "string" },
  ]
};

export const pageBucketSettings = {
  title: { sv: "Bucket" },
  page_settings: {
    root_anchor: "bucket",
  },
  desc: "Inställningar för bucket",
  properties: [
    { key: "bucket", title: { sv: "Bucket" }, desc: { sv: "Glöm inte att publicera menyn igen." }, type: "string" },
  ]
};

export const pageContactPhoneSettings = {
  title: { sv: "Telefon" },
  page_settings: {
    root_anchor: "official_contact_phone",
  },
  desc: "Telefonnummer för venue",
  properties: [
    { key: "official_contact_phone", title: { sv: "Telefon" }, desc: { sv: "Ange telefonnummer" }, type: "string" },
  ]
};

export const pageContactEmailSettings = {
  title: { sv: "E-post" },
  page_settings: {
    root_anchor: "official_contact_email",
  },
  desc: "E-post för venue",
  properties: [
    { key: "official_contact_email", title: { sv: "E-post" }, desc: { sv: "Ange e-post" }, type: "string", optional: true },
  ]
};
