export const pageReceiptSettings = {
  title: { sv: "Kvitton" },
  page_settings: {
    root_path: "receipt",
  },
  desc: {sv: "Inställningar för kvitto"},
  properties: [
    {key: "hide_phone_number", title: {sv: "Dölj telefonnummer"}, type: "bool", desc: {sv: "Kryssa i om du inte vill se telefonnummer på kvitton"}, optional: true},
    {key: "show_email", title: {sv: "Visa e-postadress"}, type: "bool", desc: {sv: "Kryssa i om du vill se e-postadress på kvitton"}, optional: true},
    {key: "extra_text", title: {sv: "Fritext"}, desc: {sv: "Text som visas på kvitton"}, type: "text_field", optional: true},
  ]
};
