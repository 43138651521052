<app-header-card [venueId]="venueId"></app-header-card>

<div *ngIf="venueDashboard" class="dashboard-container">
  <mat-card class="dashboard-section">
    <mat-card-content>
      <div>
        <div>
          <div class="property" *ngFor="let row of infoValues">
            <span *ngIf="!row.isLink" class="inspect-key">{{row.key}}:</span><span *ngIf="!row.isLink">{{row.value}}</span>
            <span *ngIf="row.isLink" class="inspect-key"><a href="{{row.value}}" target="_blank">{{row.key}}</a></span>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card *ngIf="venueDashboard.cash_register" class="dashboard-section">
    <mat-panel-title><h3 style="margin-left: 8px;">CASH REGISTER</h3></mat-panel-title>
    <mat-card-content>
      <div>
        <div class="property" *ngFor="let row of cashRegValues"><span class="inspect-key">{{row.key}}:</span><span>{{row.value}}</span></div>
      </div>

      <div class="property" style="margin-top: 30px;">Mail to:</div>
      <div class="property" *ngFor="let mail of venueDashboard.cash_register.mail_to">
          <div  class="tree-title"><a href="mailto:{{mail}}">{{mail}}</a></div>
      </div>

    </mat-card-content>
  </mat-card>

</div>
