<h2 mat-dialog-title>Add new api token</h2>
<div mat-dialog-content style="position: relative;">
  <div>
    <mat-form-field class="indialog" appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput [formControl]="email" required>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="indialog" appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput [formControl]="name" required>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="indialog" appearance="outline">
      <mat-label>Phone</mat-label>
      <input matInput [formControl]="phone" required>
    </mat-form-field>
  </div>
  <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="loadingService.visibility | async"></mat-spinner>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button (click)="addUser()">Add</button>
</mat-dialog-actions>
