<mat-expansion-panel [expanded]="true" [disabled]="true">
  <mat-expansion-panel-header>
    <mat-panel-title>
      SETTLEMENTS
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div class="container">
   
      <mat-form-field>
      <mat-label>Choose a date</mat-label>
      
      <input matInput [(ngModel)]="date" [matDatepicker]="picker">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker ></mat-datepicker>
      </mat-form-field>
      <button mat-flat-button color="primary" (click)=getSettlements()>Fetch data</button>
      
      <table mat-table [dataSource]="dataSource" matSort>
        
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name  </th>
          <td mat-cell *matCellDef="let element"> {{element.venue}} </td>
        </ng-container>

        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
          <td mat-cell *matCellDef="let element"> {{element.state}} </td>
        </ng-container>

        <ng-container matColumnDef="date-key">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
          <td mat-cell *matCellDef="let element"> {{element.date_key}} </td>
        </ng-container>
        
        <ng-container matColumnDef="payout">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Payout</th>
          <td mat-cell *matCellDef="let element"> {{element.payout}} </td>
        </ng-container>
        
        <ng-container matColumnDef="z-diff">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Z-diff</th>
          <td mat-cell *matCellDef="let element"> {{element.z_diff}} </td>
        </ng-container>

        <ng-container matColumnDef="client-records">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Client records</th>
          <td mat-cell *matCellDef="let element"> {{element.client_record_count}} </td>
        </ng-container>

        <ng-container matColumnDef="client-records-sales">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Client records sales</th>
          <td mat-cell *matCellDef="let element"> {{element.client_record_sales}} </td>
        </ng-container>

        <ng-container matColumnDef="transaction-cost">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Transaction cost</th>
          <td mat-cell *matCellDef="let element"> {{element.transaction_cost}} </td>
        </ng-container>

        <ng-container matColumnDef="profit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Profit</th>
          <td mat-cell *matCellDef="let element"> {{element.profit}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <button mat-flat-button color="primary" class="PO3-button" (click)="getPO3File()">Download PO3 file</button>
    </div>

</mat-expansion-panel>