<p>
  <mat-toolbar>
    <span>SmartTable&#8482;</span>
    <span class="spacer"></span>
    <app-wiki-info-link title="Lär dig mer. SmartTable&#8482;" section="support" path="smart-tables"></app-wiki-info-link>
    <button mat-button (click)="addSmartTable()">
      <mat-icon>add</mat-icon> Nytt
    </button>
  </mat-toolbar>
</p>

<div style="padding: 16px;">
  <table mat-table [dataSource]="dataSource" matSort matSortDisableClear="true" class="clicktable table-cells">

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.state }}</td>
    </ng-container>

    <ng-container matColumnDef="account_name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Konto</th>
      <td mat-cell *matCellDef="let element">{{ element.account_name }}</td>
    </ng-container>

    <ng-container matColumnDef="connected_table_names">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Kopplade bord</th>
      <td mat-cell *matCellDef="let element">{{ element.connected_table_names }}</td>
    </ng-container>

    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Skapad</th>
      <td mat-cell *matCellDef="let element">{{ element.created | utcDate:"short" }}</td>
    </ng-container>

    <ng-container matColumnDef="updated">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Uppdaterad</th>
      <td mat-cell *matCellDef="let element">{{ element.updated | utcDate:"short" }}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef style="width:50px"></th>
      <td mat-cell *matCellDef="let element">
        <button mat-button [matMenuTriggerFor]="navigation">Åtgärd <mat-icon>arrow_drop_down</mat-icon></button>
        <mat-menu #navigation>
          <button mat-menu-item (click)="editSmartTable(element)">Redigera</button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="duplicateSmartTable(element)">Skapa kopia</button>
          <mat-divider></mat-divider>
          <button mat-menu-item (click)="deleteSmartTable(element)">Ta bort</button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
