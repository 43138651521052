// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  is_live: false,
  base_url: "https://butlier.eu.ngrok.io",
  collins_url: "http://localhost:4201",
  genji_url: "https://genji-server.appspot.com",
  //genji_url: "http://localhost:5001",
  wiki_url: "https://genji-server.appspot.com",
  //wiki_url: "http://localhost:5001",
  //wiki_url: "https://genji-beta-server.appspot.com",
  passe_url: "https://hey.hn/",
  handson_key: "382a1-60e14-afac5-04a26-48025",
  james_url: "https://hnadmin.eu.ngrok.io",
  firebase: {
    apiKey: "AIzaSyC8XqWqG6AXjo0omULidBODzX2Wm1NdRy4",
    authDomain: "brilliant-venue-dev.firebaseapp.com",
    databaseURL: "https://brilliant-venue-dev.firebaseio.com",
    projectId: "brilliant-venue-dev",
    storageBucket: "brilliant-venue-dev.appspot.com",
    messagingSenderId: "479704904439",
    appId: "1:479704904439:web:57f5147a8d94b514b119f2"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
