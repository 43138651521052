<h2 mat-dialog-title>Skapa Fortnox-faktura</h2>
<mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Fakturareferens</mat-label>
      <input type="text"
             matInput
             formControlName="invoice_reference"
             [matAutocomplete]="auto1">
      <mat-autocomplete #auto1="matAutocomplete">
        <mat-option *ngFor="let option of filteredInvoiceReferences | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Elektronisk referens</mat-label>
      <input type="text"
             matInput
             formControlName="electronic_reference"
             [matAutocomplete]="auto2">
      <mat-autocomplete #auto2="matAutocomplete">
        <mat-option *ngFor="let option of filteredElectronicReferences | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-radio-group formControlName="group_by">
      <mat-radio-button value="none" color="primary">Ingen gruppering</mat-radio-button>
      <mat-radio-button value="order" color="primary">Gruppera per beställning</mat-radio-button>
    </mat-radio-group>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>AVBRYT</button>
  <button mat-button color="primary" (click)="preview()">Förhandsgranska</button>
  <button mat-button color="primary" (click)="createInvoice()">Skapa faktura</button>
</mat-dialog-actions>
