<h2 mat-dialog-title>Skapa rabattkod</h2>
<div *ngIf="discountPrograms && discountPrograms?.length > 0" mat-dialog-content style="position: relative;">
  <div>
    <mat-form-field class="indialog">
      <mat-label>Rabattprogram</mat-label>
      <mat-select [formControl]="dp" required>
        <mat-option *ngFor="let dp of discountPrograms" value="{{dp.id}}">{{dp.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="indialog">
      <mat-label>Antal användare</mat-label>
      <input matInput type="number" [formControl]="maxCount" required>
      <mat-hint align="start">Antal användare av denna kod</mat-hint>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="indialog">
      <mat-label>Antal giltiga dagar</mat-label>
      <input matInput type="number" [formControl]="validDays" required>
      <mat-hint align="start">Antal dagar som koden kommer att vara giltig</mat-hint>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="indialog">
      <mat-label>Max användningar</mat-label>
      <input matInput type="number" [formControl]="maxUsages">
      <mat-hint align="start">(Valfritt) Ange max användning av kod utslaget över alla användare</mat-hint>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="indialog">
      <mat-label>Maxbelopp (Startsaldo)</mat-label>
      <input matInput type="number" [formControl]="balance">
      <mat-hint align="start">(Valfritt) det maximala beloppet som koden kan ge i rabatt, för alla användare och användningar.</mat-hint>
    </mat-form-field>
  </div>
  <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="loadingService.visibility | async"></mat-spinner>
</div>
<div *ngIf="!discountPrograms || discountPrograms.length === 0" mat-dialog-content style="position: relative;">
  <div class="indialog">För att skapa rabattkoder måste du först lägga till minst ett <a href [routerLink]="" (click)="navigateToDiscountPrograms()">rabattprogram</a></div>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Avbryt</button>
  <button mat-button (click)="createCode()" [disabled]="!discountPrograms || discountPrograms.length === 0">Skapa</button>
</mat-dialog-actions>
