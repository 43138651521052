<div>
  <p>
    <mat-toolbar>
      <span>X-rapport</span>
      <span class="spacer"></span>
      <button mat-button (click)="refresh()">
        <mat-icon>refresh</mat-icon> Uppdatera
      </button>
    </mat-toolbar>
  </p>
</div>

<div class="detail-body" style="position: relative; max-width: 600px">
  <div *ngIf="errorMessage" class="error-message">
    <p>{{ errorMessage }}</p>
  </div>
  <div class="scaled" [innerHTML]="htmlContent"></div>
  <mat-spinner style="position: absolute; top: calc(50% - 25px); left: calc(50% - 25px);" [diameter]="50" *ngIf="showSpinner"></mat-spinner>
</div>

