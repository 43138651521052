import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payout-batches',
  templateUrl: './payout-batches.component.html',
  styleUrls: ['./payout-batches.component.css']
})
export class PayoutBatchesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
