import {Component, Inject, NgZone, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MenuStateService} from "../../menu-utils/menu-state.service";

@Component({
  selector: 'app-new-tab-dialog',
  templateUrl: './new-tab-dialog.component.html',
  styleUrls: ['./new-tab-dialog.component.css']
})
export class NewTabDialogComponent implements OnInit {
  tabName: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data, public dialogRef: MatDialogRef<NewTabDialogComponent>, private state: MenuStateService,
              private ngZone: NgZone) { }

  ngOnInit(): void {
  }

  createTab() {
    const tabId = this.tabName.replace(/[^a-z0-9]/gi, '_').toLowerCase();
    this.state.createTab(this.data.venueId, this.state.menuId, tabId, this.tabName).subscribe( r => {
      console.log(r);
      this.dialogRef.close();
    });
  }
}
