import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {FireService} from "../../services/fire.service";
import {VenueConfig} from "../../models/venue-config";
import {GenjiService} from "../../services/genji.service";
import {ImageResource} from "../../models/genji-models";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Clipboard} from '@angular/cdk/clipboard';
import {Location} from '@angular/common';
import {MatDialog} from "@angular/material/dialog";
import {SimpleDialogComponent} from "../simple-dialog/simple-dialog.component";
import {VenueService} from "../../services/venue.service";

@Component({
  selector: 'app-image-bank',
  templateUrl: './image-bank.component.html',
  styleUrls: ['./image-bank.component.css']
})
export class ImageBankComponent implements OnInit, OnDestroy {
  @Input() bucketPath: string;

  venueId: number;
  images: ImageResource[];
  selectedImage: ImageResource;

  private sub: Subscription;
  private config: VenueConfig;

  constructor(private route: ActivatedRoute, private fire: FireService, private genji: GenjiService,
              private clipboard: Clipboard, private snackBar: MatSnackBar, private location: Location,
              private dialog: MatDialog, private venueService: VenueService) { }

  ngOnInit(): void {
    if (this.bucketPath) {
      this.fetchImages();
      return;
    }
    this.venueId = Number(this.route.snapshot.paramMap.get("venue_id"));
    this.sub = this.fire.observeVenueConfig(this.venueId).subscribe(cfg => {
      this.config = cfg;
      this.fetchImages();
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  private fetchImages() {
    this.genji.fetchImages(this.venueId, this.bucketPath).subscribe( imageResult => {
      console.log(imageResult);
      this.images = imageResult.images;
    });
  }

  addImage() {

  }

  onFileChanged($event: Event) {
      // @ts-ignore
    const file = event.target.files[0];
    console.log(file);
    this.genji.uploadFile(file, this.venueId, this.bucketPath).subscribe( imageResult => {
      if (imageResult) {
        this.images.splice(0, 0, imageResult);
        console.log(imageResult);
      }
    }, error => {
      const dialogRef = SimpleDialogComponent.showErr(this.dialog, error);
    });
  }

  selectImage(img: ImageResource) {
    if (this.venueId) {
      this.selectedImage = img;
      this.clipboard.copy(img.name);
      this.snackBar.open(`Kopierade bild: ${img.name}`, "", {duration: 3000});
    } else {
      this.selectedImage = img;
    }
  }

  goBack() {
    this.location.back();
  }

  getLink(number: number) {
    if (environment.production && !environment.is_live) {
      //beta
      this.snackBar.open("ERROR: Funktionen är avstängd i beta (long story), hämta länken i prod istället 😁.", "", {duration: 5000});
      return;
    }
    this.venueService.fetchDynamicImageLink(this.selectedImage.url, number).then( data => {
      console.log(data);
      const url = data.serving_url;
      // const url = img.url.replace(/ /g, "%20");
      // console.log(url);
      const markdown = `![${this.selectedImage.name}](${url})`;
      console.log(markdown);
      this.clipboard.copy(markdown);
      this.snackBar.open(`Kopierade markdowb med bredd ${number}px`, "", {duration: 3000});
    }, error => {
      const dialogRef = SimpleDialogComponent.showErr(this.dialog, error);
    });
  }
}
