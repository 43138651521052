import Utils from "../common/utils";
import firebase from "firebase/compat/app";
import {SessionMenuActivity} from "./Session";

export interface Order {
  id: string;
  client_id: string;
  order_number: number;
  venue_id: number;
  user_name: string;
  user_id: string;
  table: string;
  items_json: string;
  paid_refs_json: string;
  inactive_refs_json: string;
  chat_id: number;
  session_id: string;
  active: boolean;
  state: string;
  dp: string;

  total_price: number;
  total_vat: number;

  vat_map: {};

  created: firebase.firestore.Timestamp;
  updated: firebase.firestore.Timestamp;
  finalized: firebase.firestore.Timestamp;
  processed: firebase.firestore.Timestamp;
  rejected: firebase.firestore.Timestamp;
  deliverd: firebase.firestore.Timestamp;
  paid: firebase.firestore.Timestamp;
  canceled: firebase.firestore.Timestamp;

  user_phone: string;

  // transient
  items: OrderItem[];
  paidRefs: PaidRef[];
}

export interface OrderItem {
  rid: string;
  id: string;
  name: string;
  price: number;
  vat_amount: number;
  vat: number;
  item: string;
  oprice: number;
  attributes: ItemAttribute[];
  comment: string;
  count: number;
  customized: string;
  adjustments: ItemAdjustment[];

  wait: number;
  phase: number;
  form: string;
  type: number;

  bong_name: string;
  pos_name: string;
  cat: string;
  sec: string;
  anonName: string;
  fixed_price: number;
  parts: number;
  share: number;
  orch: string;
  prepaid_key: string;
  dp: string;
  weight: number;
  price_per_kg: number;
  ic: string;
  tags: string[];

  user_id: string;

  // transient
  userId: string;
  cachedIsPaid: boolean;
  orderKey: string;
}

export interface ItemAttribute {
  id: string;
  value: string;
  price: number;
  title: string;
  priceDiff: string;
}

export interface ItemAdjustment {
  type: string;
  id: string;
  name: string;
}

export class GroupedOrderItems {
  listItemUniqueKey: string;
  orderItems: OrderItem[];

  constructor(listItemUniqueKey: string, orderItems: OrderItem[]) {
    this.listItemUniqueKey = listItemUniqueKey;
    this.orderItems = orderItems;
  }

  count() {
    return this.orderItems.reduce((v, it) => v + it.count ?? 1, 0);
  }
}

export class OrderSummary {
  activity: SessionMenuActivity[];
  table: string;
  rows: OrderSummaryRow[];
  price: CalculatedPrice;
}

export class OrderSummaryRow {
  item: OrderSummaryRowItem;
  title: OrderSummaryRowTitle;
  divider: OrderSummaryRowDivider;
  constructor(item?: OrderSummaryRowItem, title?: OrderSummaryRowTitle, divider?: OrderSummaryRowDivider) {
    this.item = item;
    this.title = title;
    this.divider = divider;
  }
}

export class OrderSummaryRowItem {
  groupedItems: GroupedOrderItems;
  userName: string;
  isPaid: boolean;

  constructor(groupedItems: GroupedOrderItems, userName: string, isPaid: boolean) {
    this.groupedItems = groupedItems;
    this.userName = userName;
    this.isPaid = isPaid;
  }

  name(): string {
    const item = this.groupedItems.orderItems[0];
    if(item.pos_name != null) {return item.pos_name;}
    return item.name;
  }

  priceFormatted(): string {
    return Utils.format(this.groupedItems.orderItems[0].price);
  }

  priceColor(): string {
    if (this.groupedItems.orderItems[0].cachedIsPaid) {
      return "green";
    }
    return this.groupedItems.orderItems[0].oprice ? "red" : "inherit";
  }

  comment(): string {
    return this.groupedItems.orderItems[0].comment;
  }

  customized(): string {
    // const cust = this.groupedItems.orderItems[0].customized;
    // if (cust != null) { return cust; }
    return this.groupedItems.orderItems[0].customized;
  }

  textDecoration(): string {
    return this.groupedItems.orderItems[0].cachedIsPaid ? "line-through" : "none";
  }

  count() {
    return this.groupedItems.orderItems.reduce((v, it) => v + it.count ?? 1, 0);
  }

}

export class OrderSummaryRowTitle {
  text: string;
  constructor(text: string) {
    this.text = text;
  }
}

export class OrderSummaryRowDivider {
  text: string;
  highlight: boolean;
  phase: number;
  itemRefs: ItemRef[];
  constructor(text: string, highlight: boolean, phase: number, itemRefs: ItemRef[]) {
    this.text = text;
    this.highlight = highlight;
    this.phase = phase;
    this.itemRefs = itemRefs;
  }
}

export class CalculatedPrice {
  price: number;
  vatAmount: number;
  discount: number;

  constructor(price: number, vatAmount: number, discount: number) {
    this.price = price;
    this.vatAmount = vatAmount;
    this.discount = discount;
  }

  priceFormatted(): string {
    return Utils.format(this.price);
  }

  vatFormatted(): string {
    return Utils.format(this.vatAmount);
  }

}

export class OrderItemForwardGroup {
  form: "food" | "beverage" | "merch";
  phase: number;
  orch: string;
}

export interface PaidRef {
  date: string;
  rid: string;
  receipt_key: string;
}

export interface Receipt {
  id: number;
  key: string;
  total_amount: number;
  venue_id: number;
  order_id: number;
  full_count: number;
  user_id: number;
  chat_id: number;
  is_refund: boolean;
  refund_of: string;

  user_name: string;
  table: string;
  items_json: string;

  total_order: number;
  total_tip: number;
  total_vat: number;
  total_shipping: number;
  total_prepaid: number;
  total_discount: number;
  vat_map: any;

  bill_type: string;

  paid_date: firebase.firestore.Timestamp;
  created: firebase.firestore.Timestamp;
  updated: firebase.firestore.Timestamp;

  manual: string;
  discount_program: string;
  refunded_refs_json: string;
  cashbox_id: string;

}

export interface PatchResult {
  error: string;
  message: string;
}

export interface ItemRef {
  order_key: string;
  rid: string;
  name: string;
}

export function itemForwarded(orderItem: OrderItem) {
  return orderItem.orch == null || orderItem.orch === "" || orderItem.orch === "f";
}
