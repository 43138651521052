export const pagePrinters = {
  title: {sv: "Skrivare"},
  page_settings: {
    root_anchor: "epson_printers",
  },
  desc: {sv: "Inställningar för Skrivare"},
  properties: [
    {key: "epson_printers", title: {sv: "Skrivare"}, type: "table",
      columns: [
        {key: "disabled", title: {sv: "Inaktiverad"}, type: "bool", optional: true },
        {key: "name", title: {sv: "Namn"}, type: "string" },
        {key: "lang", title: {sv: "Språk"}, type: "integer" },
        {key: "target", title: {sv: "Adress"}, type: "string" },
        {key: "defaultPrinter", title: {sv: "Förvald"}, type: "bool" },
        {key: "email_filter", title: {sv: "E-post"}, type: "string" },
        {key: "exclusive", title: {sv: "Exklusiv"}, type: "bool" },
        {key: "alt_names", title: {sv: "Alt. namn"}, type: "string" },
        {key: "model", title: {sv: "Modell"}, type: "enum", width: 100, source: ["TM20", "TM88", "TMU220", "Swift2"] },
        {key: "driver", title: {sv: "Drivrutin"}, type: "enum", width: 100, source: ["Epson", "EscPos", "iMin"] },
        {key: "comment", title: {sv: "Kommentar"}, type: "string" },
      ],
    },
  ]
};
