import {Component, Inject, NgZone, OnInit} from '@angular/core';
import {HotTableRegisterer} from "@handsontable/angular";
import {Item, PushPage} from "../menu-models/PublishModels";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MenuStateService} from "../menu-utils/menu-state.service";
import Handsontable from "handsontable";
import ChangeSource = Handsontable.ChangeSource;
import CellChange = Handsontable.CellChange;
import {FireService} from "../../../services/fire.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-menu-push-select-dialog',
  templateUrl: './menu-push-select-dialog.component.html',
  styleUrls: ['./menu-push-select-dialog.component.css']
})
export class MenuPushSelectDialogComponent implements OnInit {

  private hotRegisterer = new HotTableRegisterer();
  hotId = "hotAtr";
  dataset = [{}, {}, {}, {}, {}, {}];
  attributes: any;
  private pushPages: PushPage[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: {push: string, name: string, venueId: number},
              public dialogRef: MatDialogRef<MenuPushSelectDialogComponent>, private state: MenuStateService, private ngZone: NgZone) {}

  columns = [
    {data: "included", title: "Inkluderade<br>i priset", width: 100, type: "checkbox" },
  ];

  hotSettings = {
    licenseKey: environment.handson_key,
    startRows: 15,
    colHeaders: true,
    rowHeaders: true,
    columns: this.getFullColumnList(),
    contextMenu: {
      items: {
        row_above: {},
        row_below: {},
        remove_row: {},
      }
    },
    width: "400px",
    height: "500px",
  };

  ngOnInit(): void {
    this.state.getPushPages(this.data.venueId, this.state.menuId).subscribe( r => {
      this.pushPages = r;
      setTimeout( () => {
        const hotInstance = this.hotRegisterer.getInstance(this.hotId);
        if (hotInstance) {
          console.log(`AttributeDialog data: ${this.data}`);
          const value = this.data.push;
          const notEmpty = typeof value !== 'undefined' && value;
          if (notEmpty) {
            this.setupTable(hotInstance, value);
          }
          hotInstance.selectCell(0, 0);

          const that = this;
          // eslint-disable-next-line max-len
          Handsontable.hooks.add('afterChange', (changes: CellChange[] | null, source: ChangeSource) => { that.afterChange(changes, source); }, hotInstance);
          Handsontable.hooks.add('afterCreateRow', (index: number, amount: number, source?: ChangeSource) => { }, hotInstance);
          // eslint-disable-next-line max-len
          Handsontable.hooks.add('afterRemoveRow', (index: number, amount: number, physicalRows: number[], source?: ChangeSource) => { }, hotInstance);
        }
      }, 200 );
    });
  }

  private collectPushes() {
    const hotInstance = this.hotRegisterer.getInstance(this.hotId);
    const rows = hotInstance.countRows();
    const pushes = [];
    for (let i = 0; i < rows; i++) {
      const name = hotInstance.getDataAtRowProp(i, "name");
      const included = hotInstance.getDataAtRowProp(i, "included");
      if (name) {
        pushes.push({name,included});
      }
    }
    console.log(pushes);
    return pushes;
  }

  private collectAttributeString() {
    const pushes = this.collectPushes();
    return pushes.map(p => {
      let s = p.name;
      s += p.included ? "|included" : "";
      return s;
    }).join(", ");
  }

  okClicked() {
    const s = this.collectAttributeString();
    this.dialogRef.close(s);
  }

  cancelClicked() {
    this.dialogRef.close();
  }

  private setupTable(hotInstance: Handsontable, items: string) {
    const ds = [];
    items.split(",").forEach(g => {
      const vps = g.split("|");
      if (vps.length > 1) {
        const d = {name: vps[0].trim(), included: vps[1].trim() === "included"};
        ds.push(d);
      } else {
        const d = {name: g.trim()};
        ds.push(d);
      }
    });
    const k = Math.max(15 - ds.length, 5);
    for (let i = 0; i < k; i++) {
      ds.push({});
    }
    this.dataset = ds;
    hotInstance.loadData(this.dataset);
  }

  private getFullColumnList() {
    const that: MenuPushSelectDialogComponent = this;
    const cs = this.columns;

    cs.splice(0, 0, {data: "name", title: "Name", width: 200, type: "dropdown",
      // @ts-ignore
      source(query, process) {
        console.log(`autocomplete ${query}`);
        process(that.pushPages.map(o => o.id));
      },
      strict: false
    });
    return cs;
  }

  private afterChange(changes: CellChange[] | null, source: ChangeSource) {
    // @ts-ignore
    if (source === "observe") {
      return;
    }

    if (changes) {
      console.log("afterChange");
      console.log(changes);
      console.log(source);
    }
  }


}
