import {Component, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {VenueService} from "../../../services/venue.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort, MatSortable} from "@angular/material/sort";
import {Device} from "../../../models/reg-unit";
import {SimpleDialogComponent} from "../../simple-dialog/simple-dialog.component";
import {AuthService} from "../../../services/auth.service";
import { BehaviorSubject, Subscription, switchMap } from 'rxjs';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.css']
})
export class DeviceListComponent implements OnInit {

  private sub: Subscription = new Subscription(); 

  constructor(public dialog: MatDialog, private route: ActivatedRoute, private venueService: VenueService, private snackBar: MatSnackBar,
              private router: Router, private auth: AuthService) { }
  venueId: number;
  isSuperAdmin: boolean;

  displayedColumns = ["email", "name", "last_name", "created", "regunit_date", "regunit_name", "action"];
  dataSource: MatTableDataSource<Device>;
  showInactive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  ngOnInit(): void {
    this.isSuperAdmin = AuthService.isSuperAdmin(this.auth.authStateSnapshot);
    if (this.isSuperAdmin) {
      this.displayedColumns = ["email", "name", "last_name", "created", "regunit_date", "regunit_name", "role", "action"];
    }
    this.sort.sort(({ id: 'email', start: 'asc'}) as MatSortable);
    this.sub = this.route.paramMap.pipe(
      switchMap(data => {
        const vn = data.get("venue_id");
        this.venueId = Number(vn);
        return this.showInactive;
      })
    ).subscribe(showInactive => {
      this.fetchUsers();
      this.applyFilter(showInactive);
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private fetchUsers() {
    this.venueService.fetchDevices(this.venueId).then(r => {
      this.dataSource = new MatTableDataSource(r as Device[]);
      this.dataSource.sort = this.sort;
      this.applyFilter(this.showInactive.value);
    });
  }

  private applyFilter(showInactive: boolean) {
    if (!this.dataSource) {
      return;
    }
    this.dataSource.filterPredicate = (data: Device, filter: string) => {
      if (!showInactive && data.role === 0) {
        return false;
      }
      return true;
    };
    this.dataSource.filter = '' + Math.random();
  }

  hasPerm(perm: string): boolean {
    return this.auth.hasPermission(this.venueId, perm);
  }

  addDevice() {
    this.router.navigateByUrl(`venue/${this.venueId}/devices/new:`);
  }

  select(row) {
    this.router.navigateByUrl(`venue/${this.venueId}/devices/${row.key}`);
  }

  newDevice(device: Device) {
    this.router.navigateByUrl(`venue/${this.venueId}/devices/new`);
  }

  duplicateDevice(device: Device) {
    this.router.navigateByUrl(`venue/${this.venueId}/devices/dup:${device.key}`);
  }

  register(device: Device) {
    this.router.navigateByUrl(`venue/${this.venueId}/register-device/${device.key}`);
  }

  revokeAccess(element) {
    console.log("revokeAccess", element);
    const ds = {title: `Upphäv åtkomst: ${element.email}`, message: `Upphäv åtkomst till systemet och kräv ny inloggning. Detta kan behöva göras t.ex. om enhet blivit stulen eller man på annat sätt anser att enheten inte längre skall ha tillgång. Efter åtkomst är borttagen, behöver man byta lösenord på enheten och logga in igen. (Det kan ta upp till 1 timma innan enheten garanterat förlorat åtkomst)`, cancelButton: "Avbryt", positiveButton: "Ta bort åtkomst"};
    SimpleDialogComponent.observeSimpleDialog(this.dialog, ds).subscribe( r => {
      if (r) {
        this.venueService.revokeDeviceAccess(this.venueId, element.key).then(r2 => {
          this.snackBar.open("Enheten har förlorat åtkomst, var god och byt lösenord och logga in på nytt.", "OK", {});
        });
      }
    });
  }
}
