<app-toolbar (tog)="sideNav.toggle()"></app-toolbar>
<div fxLayout="column" fxLayoutAlign="end start">
  <mat-sidenav-container>

    <mat-sidenav #sideNav align="start" mode="{{navMode}}" opened="true">
      <ng-material-multilevel-menu [configuration]='config' [items]='appitems' class="no-wrap"></ng-material-multilevel-menu>
    </mat-sidenav>

    <div style="height: 100%;">
      <router-outlet>
        <mat-progress-bar mode="indeterminate" *ngIf="loadingService.visibility | async"></mat-progress-bar>
        <div *ngIf="!(loadingService.visibility | async)" style="height: 4px;background-color: #f5f5f5;"></div>
      </router-outlet>
    </div>

  </mat-sidenav-container>
</div>
