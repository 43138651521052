export const pageSoftpaySettings = {
  title: { sv: "Softpay" },
  page_settings: {
    root_path: "softpay.settings"
  },
  desc: { sv: "Softpay-inställningar" },
  properties: [
    { key: "active", title: { sv: "Aktivt" }, desc: { sv: "Anger om softpay skall vara aktiverat" }, type: "bool", optional: true },
    { key: "name", title: {sv: "Namn"}, desc: {sv: "Namn som visas på betalningsknapp"}, type: "string", optional: true},
    { key: "dpp_alias", title: {sv: "Kortnamn"}, desc: {sv: "Kortnamn som visas i kassapunkt"}, type: "string", optional: true},
    { key: "TOF", title: { sv: "TOF nummer" }, desc: { sv: "Nummer för integration med softpay" }, type: "string", optional: true },
    { key: "user_name", title: { sv: "Användarnamn" }, desc: { sv: "" }, type: "string", optional: true },
    { key: "password", title: { sv: "Lösenord" }, desc: { sv: "" }, type: "string", pwdField: true, hidePwd: true, optional: true },
  ]
};
