import { Component, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { ActivatedRoute } from '@angular/router';
import Utils from 'src/app/common/utils';
import { AdyenSetup } from 'src/app/models/adyen-legal-entity';
import { VenueService } from 'src/app/services/venue.service';

@Component({
  selector: 'app-adyen',
  templateUrl: './adyen.component.html',
  styleUrls: ['./adyen.component.css']
})
export class AdyenComponent implements OnInit {
  venueId: number;
  displayedColumns = ["type", "enabled", "allowed", "verificationStatus"];
  setupData: MatTableDataSource<AdyenSetup>;


  constructor(private venueService: VenueService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe( data => {
      this.venueId = Number(data.get("venue_id"));
    });
    this.venueService.getAdyenSetup(this.venueId).then(r => {
      this.updateStatus(r);
    });
  }

  goToOnboarding() {
    this.venueService.getAdyenSetup(this.venueId).then(r => {
      window.open(r.hosted_link.url, "_blank");
      this.updateStatus(r);
    });
  }

  updateStatus(r: AdyenSetup) {
    const capabilities = [];
    for (const [key, value] of Object.entries(r.account_holder.capabilities)) {
      const capMap = new Map();
      let sentence = key.replace(/([A-Z])/g, ' $1');
      sentence = Utils.capitalizeFirstLetter(sentence);
      capMap.set("type", sentence);

      for (const [k, v] of Object.entries(value)) {
        capMap.set(k, v);
      }
      capabilities.push(capMap);
    }
    this.setupData = new MatTableDataSource(capabilities);
  }
}
