import {Component, OnDestroy, OnInit} from '@angular/core';
import {GenjiService} from "../../../services/genji.service";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {MenuItem, MenuStructure} from "../../../models/menu-structure";
import {MatTableDataSource} from "@angular/material/table";
import {FireService} from "../../../services/fire.service";
import {VenueService} from "../../../services/venue.service";

@Component({
  selector: 'app-filter-builder',
  templateUrl: './filter-builder.component.html',
  styleUrls: ['./filter-builder.component.css']
})
export class FilterBuilderComponent implements OnInit, OnDestroy {
  askAI: string;
  filter: string;

  private subMenu: Subscription;
  private subParams: Subscription;
  private venueId: number;
  private menu: MenuStructure;

  displayedColumns = ["name", "ic", "tags", "phase"];
  dataSource: MatTableDataSource<MenuItem>;

  constructor(private route: ActivatedRoute, private genji: GenjiService, private fire: FireService, private venueService: VenueService) { }

  ngOnInit(): void {
    this.subParams = this.route.paramMap.subscribe(data => {
      this.venueId = Number(data.get("venue_id"));
      this.beginObserve();
    });
  }

  ngOnDestroy(): void {
    this.subMenu?.unsubscribe();
    this.subParams?.unsubscribe();
  }

  private beginObserve() {
    this.fire.observeMenuStructure(this.venueId).subscribe(menu => {
      this.menu = menu;
    });
  }

  sendQuestion() {

    const prefix = `
Filter in Heynow is used to filter out articles. Articles have several properties that can be filtered on:
ic, is the item class of the article, there are several item classes such as food, drink, beer, starter, wine, main, etc
area, indicates which area the article is ordered in
table, indicates which table the article is ordered for
phase, indicates which course (phase) the article belongs to, these are normally DR for drinks, FR for starters, HR for main courses and DS for Dessert, but there are other courses as well.
tag, is a list of tags that an article can be tagged with, these are arbitrary, such as printable, asian, pizza, takeaway, etc.

The "+" sign or no sign in front of a expression means these must be present, the "-" sign indicates that these must not be present.
Filter expressions can be combined with a comma, which then means or.

Q: Filter all food articles
A: ic=food

Q: Filter food and drinks articles
A: This is a human asking to get both food and drinks which is a logic expression food or drinks, so the answer is: ic=food|drink

Q: Filter beer with the tag canned
A: Since the filter needs both ic=beer and tag=canned we need to use the + sign to combine the expression, so the answer is: ic=beer+tag=canned

Q: Filter starters without the tag fish
A: Since the filter needs phase=FR without the tag=canned we need to use the - sign to combine the expression, so the answer is: phase=FR-tag=fish

Q: Filter starters and desserts from area bar
A: This is a human asking to get both starters and desserts which is a logic expression starters or desserts, and must be from area=bar, we use plus sign. so the answer is: phase=FR|DS+area=bar

Q: Filter food with tag fish or drinks
A: When there are or expressions we combine expressions with , so the answer is: ic=food+tag=fish,ic=drink

Q: Filter beer and wine from table Bar1 or from area Dining but not with tag fish
A: When there are or expressions we combine expressions with , so the answer is: ic=beer|wine+table=Bar1,area=Dining-tag=fish

Q: Filter beer or wine from table Bar1
A: In this case the human normally writes beer and wine, but means the correct beer or wine, so the answer is: ic=beer|wine+table=Bar1

Q: Filter wine from bar1 and wine from bar2
A: This can be formulated like this: filter wine from bar1 and bar2, and therefore the answer is: ic=wine+table=Bar1|Bar2

Q: Filter all but beer from all areas but Matsal
A: Since we want all but beer and all but area Matsal we need to negate both expressions, so the ansewer is: -ic=beer-area=Matsal

Q: Filter all wine from all areas
A: Since we are asking for all areas, we can leave that expression out, so the answer is: ic=wine

Q: Filter all startes with tag noprint or any orders from area=Outside
A: This can be formulated like this: filter starters with tag noprint or orders from area Outside, so the answer is: phase=FR+tag=noprint,area=Outside

Q: Filter wine and beer from table Bar1 but only wine from Bar2
A: This can be formulated as wine and beer from table Bar1 or wine from Bar2, the answer is: ic=wine|beer+table=Bar1,ic=wine+table=Bar2

Q: All öl från area Matsal
A: This is the same type of questions but in swedish, so translated it is: all beer from area Matsal, the answer is: ic=beer+area=Matsal

Q: Öl och vin med taggen noprint
A: This is the same type of question but in swedish, so translated it is: beer and wine with tag noprint, the answer is: ic=beer|wine+tag=noprint

Q: all mat som är i fasen förrätt
A: This is the same type of question but in swedish, so translated it is: all food that is in phase starter, the answer is: ic=food+phase=FR

Q: skriv ett filter som tar med mat men inte dryck
A:  ic=food-ic=drink

Q:
`;

    this.genji.queryGPT3(prefix + this.askAI + "\nA:").subscribe((result) => {
        console.log(result);
        this.filter = result.choices[0].text;
      }
    );
  }

  runFilter() {
    this.venueService.simulateFilter(this.venueId, this.filter, {}).then((result) => {
      this.dataSource = new MatTableDataSource(result);
    });
  }
}
